import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import TextArea from 'components/TextArea';
import {BuilderContext} from 'contextes/builder';
import {AVAILABLE_LANGUAGES} from 'helpers/languages';
import React, {useContext, useState} from 'react';
import LanguageSelector from 'scenes/PokeBuilder/components/TranslationManager/components/LanguageSelector';

// translation files
import arTranslations from 'shared/front/translations/ar.json';
import csTranslations from 'shared/front/translations/cs.json';
import daTranslations from 'shared/front/translations/da.json';
import deTranslations from 'shared/front/translations/de.json';
import enTranslations from 'shared/front/translations/en.json';
import esTranslations from 'shared/front/translations/es.json';
import fiTranslations from 'shared/front/translations/fi.json';
import frTranslations from 'shared/front/translations/fr.json';
import huTranslations from 'shared/front/translations/hu.json';
import idTranslations from 'shared/front/translations/id.json';
import itTranslations from 'shared/front/translations/it.json';
import jpTranslations from 'shared/front/translations/jp.json';
import loTranslations from 'shared/front/translations/lo.json';
import nlTranslations from 'shared/front/translations/nl.json';
import noTranslations from 'shared/front/translations/no.json';
import plTranslations from 'shared/front/translations/pl.json';
import ptTranslations from 'shared/front/translations/pt.json';
import roTranslations from 'shared/front/translations/ro.json';
import ruTranslations from 'shared/front/translations/ru.json';
import skTranslations from 'shared/front/translations/sk.json';
import sqTranslations from 'shared/front/translations/sq.json';
import srTranslations from 'shared/front/translations/sr.json';
import svTranslations from 'shared/front/translations/sv.json';
import thTranslations from 'shared/front/translations/th.json';
import trTranslations from 'shared/front/translations/tr.json';
import ukTranslations from 'shared/front/translations/uk.json';
import viTranslations from 'shared/front/translations/vi.json';
import zhTranslations from 'shared/front/translations/zh.json';
import './_Styles.scss';

const propTypes = {};

const getInitialLanguages = (translations) => {
  if (!translations) return [];

  const languageCodes = new Set();

  // Extract language codes from the translations object
  // where translations is an object with language codes as keys
  Object.keys(translations).forEach((languageCode) => {
    languageCodes.add(languageCode);
  });

  return Array.from(languageCodes)
    .map((code) => AVAILABLE_LANGUAGES.find((lang) => lang.code === code))
    .filter(Boolean);
};

const translationsMap = {
  AR: arTranslations,
  CS: csTranslations,
  DA: daTranslations,
  DE: deTranslations,
  EN: enTranslations,
  ES: esTranslations,
  FI: fiTranslations,
  FR: frTranslations,
  HU: huTranslations,
  ID: idTranslations,
  IT: itTranslations,
  JP: jpTranslations,
  LO: loTranslations,
  NL: nlTranslations,
  NO: noTranslations,
  PL: plTranslations,
  PT: ptTranslations,
  RO: roTranslations,
  RU: ruTranslations,
  SK: skTranslations,
  SQ: sqTranslations,
  SR: srTranslations,
  SV: svTranslations,
  TH: thTranslations,
  TR: trTranslations,
  UK: ukTranslations,
  VI: viTranslations,
  ZH: zhTranslations,
};

const SimpleInput = ({value, placeholder, onChange}) => {
  const handleChange = (value) => {
    onChange({
      value: value,
    });
  };

  return (
    <TextArea
      value={value}
      onChange={({target}) => handleChange(target.value)}
      placeholder={placeholder}
      className="title-content-input"
    />
  );
};

const ChangelogTranslations = () => {
  const {
    project,
    setProject,
    isTranslating,
    selectedLanguage,
    setSelectedLanguage,
  } = useContext(BuilderContext);

  const [languages, setLanguages] = useState(
    getInitialLanguages(project?.changelogSettings?.translations)
  );

  const {changelogSettings} = project;
  const {translations = {}} = changelogSettings || {};

  const getDefaultMessage = (key) => {
    const message = translationsMap[selectedLanguage].others[key];
    return message?.replace(/{{projectName}}/g, project?.name || 'Project');
  };

  const updateTranslations = ({key, value}) => {
    const updateObj = {
      ...translations,
      [selectedLanguage]: {
        ...translations[selectedLanguage],
        [key]: value,
      },
    };

    setProject({
      ...project,
      changelogSettings: {...changelogSettings, translations: updateObj},
    });
  };

  const handleDeleteLanguage = (code) => {
    const updatedTranslations = {...translations};
    delete updatedTranslations[code];

    setProject({
      ...project,
      changelogSettings: {
        ...changelogSettings,
        translations: updatedTranslations,
      },
    });
  };

  const currentTranslations = translations[selectedLanguage];

  return (
    <div className="changelog-translation-manager-wrapper">
      <div className="changelog-translation-manager">
        <div className="changelog-translation-manager-header">
          <div className="changelog-translation-manager-title">
            Manage translations
          </div>
        </div>
        {isTranslating ? (
          <div className="loader-wrapper">
            <DefaultLoader />
            Translating...
          </div>
        ) : (
          <>
            <LanguageSelector
              selectedLanguage={selectedLanguage}
              onAddLanguage={setSelectedLanguage}
              onSelectLanguage={setSelectedLanguage}
              onRemoveLanguage={handleDeleteLanguage}
              languages={languages}
              setLanguages={setLanguages}
              hideDefaultLanguage
            />
            {selectedLanguage != null && (
              <div
                className="changelog-translation-manager-blocks"
                key={selectedLanguage}>
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">Trigger</div>
                  <div className="translation-manager-block-content">
                    <SimpleInput
                      value={currentTranslations?.welcomeMessage}
                      placeholder={getDefaultMessage('welcomeMessage')}
                      onChange={({value}) => {
                        updateTranslations({
                          key: 'welcomeMessage',
                          value,
                        });
                      }}
                    />
                  </div>
                </div>
                <Divider />
                <div className="translation-manager-subtitle subtitle-3">
                  Page titles
                </div>
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">
                    Posts feed title
                  </div>
                  <div className="translation-manager-block-content">
                    <SimpleInput
                      value={currentTranslations?.navbarFeedTitle}
                      placeholder="Feed"
                      onChange={({value}) => {
                        updateTranslations({
                          key: 'navbarFeedTitle',
                          value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">
                    Feedback title
                  </div>
                  <div className="translation-manager-block-content">
                    <SimpleInput
                      value={currentTranslations?.upcomingTabButtonGiveFeedback}
                      placeholder={getDefaultMessage(
                        'upcomingTabButtonGiveFeedback'
                      )}
                      onChange={({value}) => {
                        updateTranslations({
                          key: 'upcomingTabButtonGiveFeedback',
                          value,
                        });
                      }}
                    />
                  </div>
                </div>
                <Divider />
                <div className="translation-manager-subtitle subtitle-3">
                  Feedback page
                </div>
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">Title</div>
                  <div className="translation-manager-block-content">
                    <SimpleInput
                      value={currentTranslations?.widgetFeedbackTopTitle}
                      placeholder={getDefaultMessage('widgetFeedbackTopTitle')}
                      onChange={({value}) => {
                        updateTranslations({
                          key: 'widgetFeedbackTopTitle',
                          value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">
                    Paragraph
                  </div>
                  <div className="translation-manager-block-content">
                    <SimpleInput
                      value={currentTranslations?.widgetFeedbackTopParagraph}
                      placeholder={getDefaultMessage(
                        'widgetFeedbackTopParagraph'
                      )}
                      onChange={({value}) => {
                        updateTranslations({
                          key: 'widgetFeedbackTopParagraph',
                          value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">
                    Call to action
                  </div>
                  <div className="translation-manager-block-content">
                    <SimpleInput
                      value={currentTranslations?.cardSubmitRequestBtnSubmit}
                      placeholder={getDefaultMessage(
                        'cardSubmitRequestBtnSubmit'
                      )}
                      onChange={({value}) => {
                        updateTranslations({
                          key: 'cardSubmitRequestBtnSubmit',
                          value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ChangelogTranslations.propTypes = propTypes;

export default ChangelogTranslations;
