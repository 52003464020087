import classNames from 'classnames';
import propTypes, {func, string} from 'prop-types';
import React, {Fragment, useEffect, useState} from 'react';
import './_Styles.scss';

const Tab = ({
  isActive,
  label,
  extraLabel,
  onClick: onClickProps,
  className = '',
  iconLeft,
  disabled,
}) => {
  const onClick = () => {
    onClickProps(label);
  };

  return (
    <li
      className={classNames(`tab-list-item ${className}`, {
        'tab-list-active': isActive,
      })}
      onClick={() => {
        if (disabled !== true) {
          onClick();
        }
      }}>
      {iconLeft && (
        <div className="left-icon-wrapper">
          <i className={iconLeft} />
        </div>
      )}
      <div className="text-wrapper">
        <div className="label-wrapper">{label}</div>
        {extraLabel}
      </div>
    </li>
  );
};

Tab.propTypes = {
  activeTab: string.isRequired,
  label: string.isRequired,
  onClick: func.isRequired,
};

const Tabs = ({children, defaultTab, onTabChange}) => {
  const [activeTab, setActiveTab] = useState(
    defaultTab != null ? defaultTab : children[0].props.label
  );

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    onTabChange(activeTab);
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  return (
    <div className="tabs">
      <ol className="tab-list">
        {children
          .filter((c) => c)
          .map(({props = {}} = {}, index) => {
            const {label, labelId, extraLabel, className, disabled, iconLeft} =
              props;

            const isActiveTab =
              labelId != null ? labelId === activeTab : label === activeTab;

            if (!label) {
              return <Fragment key={index}></Fragment>;
            }

            return (
              <Tab
                className={className}
                isActive={isActiveTab}
                key={label}
                label={label}
                extraLabel={extraLabel}
                onClick={() =>
                  onClickTabItem(labelId != null ? labelId : label)
                }
                iconLeft={iconLeft}
                disabled={disabled}
              />
            );
          })}
      </ol>
      <div className="tab-content">
        {children
          .filter((c) => c)
          .map(({props}) => {
            const {label, labelId} = props;

            const isActiveTab =
              labelId != null ? labelId === activeTab : label === activeTab;

            if (isActiveTab !== true) return undefined;
            return props.children;
          })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: propTypes.instanceOf(Array).isRequired,
  defaultTab: string,
  onTabChange: func,
};

Tabs.defaultProps = {
  defaultTab: null,
  onTabChange: () => {},
};

export default Tabs;
