import React, {useContext} from 'react';
import {BLOCK_MEDIA, BLOCK_STEPPER, BLOCK_USER} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {BlockMedia, MEDIA_POSITION_TOP} from '../BlockMedia';
import {BlockStepper, STEPPER_TYPE_LINE} from './../BlockStepper';
import {BlockUser} from './../BlockUser';
import './_styles.scss';

const LayoutTop = () => {
  const {currentStep, isHint} = useContext(PokeStateContext);

  const blockMedia = currentStep.blocks.find((b) => b.type === BLOCK_MEDIA);
  const shouldShowBlockMedia =
    blockMedia != null && blockMedia?.style?.position === MEDIA_POSITION_TOP;
  const shouldShowBlockUser =
    currentStep.blocks.findIndex((b) => b.type === BLOCK_USER) >= 0;
  const blockStepper = currentStep.blocks.find((b) => b.type === BLOCK_STEPPER);
  const blockStepperValue = blockStepper?.style?.type;
  const shouldShowBlockStepper =
    blockStepper != null && blockStepper.style.position === 'top';

  if (
    shouldShowBlockMedia === false &&
    shouldShowBlockUser === false &&
    shouldShowBlockStepper === false
  ) {
    return <></>;
  }

  return (
    <div
      className="poke-layout-top"
      style={{
        gap: `${currentStep?.style?.gap ?? 16}px`,
        paddingTop: `${currentStep?.style?.paddingTop ?? (isHint ? 16 : 24)}px`,
        paddingLeft: `${
          currentStep?.style?.paddingLeft ?? (isHint ? 16 : 24)
        }px`,
        paddingRight: `${
          currentStep?.style?.paddingRight ?? (isHint ? 16 : 24)
        }px`,
      }}>
      {shouldShowBlockMedia && <BlockMedia />}
      {/* Stepper : Placed before user when type is line */}
      {shouldShowBlockStepper && blockStepperValue === STEPPER_TYPE_LINE && (
        <BlockStepper />
      )}
      {/* User */}
      {shouldShowBlockUser === true && <BlockUser />}
      {/* Stepper : Placed after user for other types */}
      {shouldShowBlockStepper && blockStepperValue !== STEPPER_TYPE_LINE && (
        <BlockStepper />
      )}
    </div>
  );
};

export default LayoutTop;
