import classnames from 'classnames';
import InputGroup from 'components/Input';
import React, {useState} from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import './_Styles.scss';

const MIN_PADDING = 0;
const MAX_PADDING = 1000;

const PaddingItem = ({value, onChange}) => {
  const {
    paddingTop = 0,
    paddingBottom = 0,
    paddingLeft = 0,
    paddingRight = 0,
  } = value;

  const [modeExtended, setModeExtended] = useState(
    paddingLeft !== paddingRight || paddingTop !== paddingBottom
  );

  return (
    <SectionItem
      title={
        <>
          Padding
          <i
            className={classnames(
              'isax isax-element-3',
              modeExtended === true && 'active'
            )}
            onClick={() => setModeExtended(!modeExtended)}></i>
        </>
      }
      sectionItemClassName={classnames(
        'padding-item',
        modeExtended === true && 'extended'
      )}>
      <InputGroup
        small
        value={paddingLeft}
        type="number"
        labelTextLeft={
          <div className="icon-wrapper">
            <i className="isax isax-slider-horizontal p-l" />
          </div>
        }
        min={MIN_PADDING}
        max={MAX_PADDING}
        onChange={(e) =>
          onChange({
            paddingLeft: parseInt(e.target.value, 10),
            paddingTop,
            paddingBottom,
            ...(modeExtended === false && {
              paddingRight: parseInt(e.target.value, 10),
            }),
          })
        }
      />
      {modeExtended && (
        <InputGroup
          small
          value={paddingRight}
          type="number"
          labelTextLeft={
            <div className="icon-wrapper">
              <i className="isax isax-slider-horizontal p-r" />
            </div>
          }
          min={MIN_PADDING}
          max={MAX_PADDING}
          onChange={(e) =>
            onChange({
              paddingRight: parseInt(e.target.value, 10),
              paddingTop,
              paddingBottom,
            })
          }
        />
      )}
      <InputGroup
        small
        value={paddingTop}
        type="number"
        labelTextLeft={
          <div className="icon-wrapper">
            <i className="isax isax-slider-vertical p-t" />
          </div>
        }
        min={MIN_PADDING}
        max={MAX_PADDING}
        onChange={(e) =>
          onChange({
            paddingTop: parseInt(e.target.value, 10),
            paddingLeft,
            paddingRight,
            ...(modeExtended === false && {
              paddingBottom: parseInt(e.target.value, 10),
            }),
          })
        }
      />
      {modeExtended === true && (
        <InputGroup
          small
          value={paddingBottom}
          type="number"
          labelTextLeft={
            <div className="icon-wrapper">
              <i className="isax isax-slider-vertical p-b" />
            </div>
          }
          min={MIN_PADDING}
          max={MAX_PADDING}
          onChange={(e) =>
            onChange({
              paddingBottom: parseInt(e.target.value, 10),
              paddingLeft,
              paddingRight,
            })
          }
        />
      )}
    </SectionItem>
  );
};

export default PaddingItem;
