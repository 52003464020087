import {
  BLOCK_CHECKLIST_HEADER,
  BLOCK_CHECKLIST_HEADER_DESCRIPTION,
  BLOCK_CHECKLIST_HEADER_PROGRESS,
  BLOCK_CHECKLIST_HEADER_TITLE,
  BLOCK_CHECKLIST_TASK_CHECKBOX,
  BLOCK_CHECKLIST_TASK_ITEM,
  BLOCK_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_RESOURCE_CENTER_ACTION,
  BLOCK_RESOURCE_CENTER_ACTION_CTA,
  BLOCK_RESOURCE_CENTER_ACTION_PARAGRAPH,
  BLOCK_RESOURCE_CENTER_ACTION_TITLE,
} from '../../../Poke/constants/blocks';
import {RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT} from '../../constants';

export const overrideChecklistStyle = (blocks = [], checklist) => {
  if (checklist == null) {
    return checklist;
  }

  const actionBlock = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_ACTION
  );
  const actionTitleBlock = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_ACTION_TITLE
  );
  const actionParagraphBlock = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_ACTION_PARAGRAPH
  );
  const actionCtaBlock = blocks.find(
    (b) =>
      b.type === BLOCK_RESOURCE_CENTER_ACTION_CTA &&
      b?.style?.type === RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT
  );

  const updatedChecklist = {
    ...checklist,
    style: {
      ...checklist.style,
      borderColor: actionBlock?.style?.borderColor,
    },
    steps: checklist.steps.map((step) => {
      return {
        ...step,
        style: {
          ...step.style,
          borderColor: actionBlock?.style?.borderColor,
          backgroundColor: actionBlock?.style?.backgroundColor,
          borderRadius: actionBlock?.style?.borderRadius,
          shadow: actionBlock?.style?.shadow,
        },
        blocks: step.blocks?.map((block) => {
          if (block.type === BLOCK_CHECKLIST_HEADER) {
            return {
              ...block,
              style: {
                ...block.style,
                backgroundColor: actionBlock?.style?.backgroundColor,
              },
            };
          }
          if (block.type === BLOCK_CHECKLIST_HEADER_TITLE) {
            return {
              ...block,
              style: {
                ...block.style,
                fontColor: actionTitleBlock?.style?.fontColor,
                fontFamily: actionTitleBlock?.style?.fontFamily,
                fontSize: actionTitleBlock?.style?.fontSize,
                fontWeight: actionTitleBlock?.style?.fontWeight,
              },
            };
          }
          if (block.type === BLOCK_CHECKLIST_HEADER_DESCRIPTION) {
            return {
              ...block,
              style: {
                ...block.style,
                fontColor: actionParagraphBlock?.style?.fontColor,
                fontFamily: actionParagraphBlock?.style?.fontFamily,
                fontSize: actionParagraphBlock?.style?.fontSize,
                fontWeight: actionParagraphBlock?.style?.fontWeight,
              },
            };
          }
          if (block.type === BLOCK_CHECKLIST_HEADER_PROGRESS) {
            return {
              ...block,
              style: {
                ...block.style,
                primaryColor: actionTitleBlock?.style?.fontColor,
                secondaryColor: `${actionTitleBlock?.style?.fontColor?.slice(
                  0,
                  7
                )}4D`,
              },
            };
          }
          if (block.type === BLOCK_CHECKLIST_TASK_ITEM_TITLE) {
            return {
              ...block,
              style: {
                ...block.style,
                fontFamily: actionTitleBlock?.style?.fontFamily,
                fontColor: actionTitleBlock?.style?.fontColor,
                completedFontColor: actionTitleBlock?.style?.completedFontColor,
                completedStrikethrough:
                  actionTitleBlock?.style?.completedStrikethrough,
              },
            };
          }
          if (block.type === BLOCK_CHECKLIST_TASK_ITEM_DESCRIPTION) {
            return {
              ...block,
              style: {
                ...block.style,
                fontFamily: actionParagraphBlock?.style?.fontFamily,
                fontColor: actionParagraphBlock?.style?.fontColor,
              },
            };
          }
          if (block.type === BLOCK_CHECKLIST_TASK_ITEM_PRIMARY_CTA) {
            return {
              ...block,
              style: {
                ...block.style,
                fontFamily: actionCtaBlock?.style?.textFontFamily,
                primaryColor: actionCtaBlock?.style?.textBackgroundColor,
                fontColor: actionCtaBlock?.style?.textFontColor,
                borderRadius: actionCtaBlock?.style?.textBorderRadius,
                shadow: actionCtaBlock?.style?.textShadow,
                borderColor: actionCtaBlock?.style?.textBorderColor,
                paddingTop: actionCtaBlock?.style?.textPaddingTop,
                paddingBottom: actionCtaBlock?.style?.textPaddingBottom,
                paddingLeft: actionCtaBlock?.style?.textPaddingLeft,
                paddingRight: actionCtaBlock?.style?.textPaddingRight,
              },
            };
          }
          if (block.type === BLOCK_CHECKLIST_TASK_ITEM_SECONDARY_CTA) {
            return {
              ...block,
              style: {
                ...block.style,
                fontFamily: actionCtaBlock?.style?.textFontFamily,
                primaryColor: actionCtaBlock?.style?.textFontColor,
                fontColor: actionCtaBlock?.style?.textBackgroundColor,
                borderRadius: actionCtaBlock?.style?.textBorderRadius,
                shadow: actionCtaBlock?.style?.textShadow,
                borderColor: actionCtaBlock?.style?.textBackgroundColor,
                paddingTop: actionCtaBlock?.style?.textPaddingTop,
                paddingBottom: actionCtaBlock?.style?.textPaddingBottom,
                paddingLeft: actionCtaBlock?.style?.textPaddingLeft,
                paddingRight: actionCtaBlock?.style?.textPaddingRight,
              },
            };
          }
          if (block.type === BLOCK_CHECKLIST_TASK_CHECKBOX) {
            return {
              ...block,
              style: {
                ...block.style,
                completedBackgroundColor: actionTitleBlock?.style?.fontColor,
                incompleteBackgroundColor: `${actionTitleBlock?.style?.fontColor?.slice(
                  0,
                  7
                )}4D`,
                completedBorderColor: actionBlock?.style?.backgroundColor,
                incompleteBorderColor: actionBlock?.style?.backgroundColor,
                iconColor: actionBlock?.style?.backgroundColor,
              },
            };
          }
          if (block.type === BLOCK_CHECKLIST_TASK_ITEM) {
            return {
              ...block,
              style: {
                ...block.style,
                borderColor: null,
              },
            };
          }
          return block;
        }),
      };
    }),
  };

  return updatedChecklist;
};
