import {uptProject} from 'actions/general';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS_CRISP} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {crispWidgetService} from 'services';
import {Swaler} from 'swaler';
import CrispLogo from '../imgs/crisp.jpeg';
import './_Styles.scss';

const logger = new Swaler('SettingsIntegrationsCrisp');

const Crisp = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSetupCrisp = async () => {
    setIsLoading(true);
    try {
      const crisp = await crispWidgetService.setup();

      updateProject({
        ...project,
        crisp,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to setup crisp integration, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  const hasCrispSetup = project.crisp != null;

  return (
    <div className="settings-card integration-crisp">
      <div className="logo-action-wrapper">
        <img className="logo crisp-logo" src={CrispLogo} alt="crisp-logo" />
        {hasCrispSetup === false ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isLoading}
            onClick={handleSetupCrisp}>
            Enable
          </Button>
        ) : (
          <Button
            thin
            iconLeft="isax isax-setting-2"
            onClick={() => history.push(ROUTE_SETTINGS_INTEGRATIONS_CRISP)}>
            Configure
          </Button>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Crisp
          {hasCrispSetup === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Enabled
            </div>
          )}
        </div>
        <div className="body-3">
          Allow operator to launch tours and see Jimo activity of users in Crisp
          conversations.
        </div>
      </div>
    </div>
  );
};

export default Crisp;
