// Types
export const MISC_SET_HIDE_MOBILE_OVERLAY = 'MISC_SET_HIDE_MOBILE_OVERLAY';
export const MISC_SET_HIDE_TRIAL_ENDED_MODAL =
  'MISC_SET_HIDE_TRIAL_ENDED_MODAL';
export const MISC_SET_COLLAPSE_TRACKING_ONBOARDING =
  'MISC_SET_COLLAPSE_TRACKING_ONBOARDING';
export const MISC_SET_INVITATION_CODE = 'MISC_SET_INVITATION_CODE';
export const MISC_SET_DEFAULT_VIEW_LAYOUT = 'MISC_SET_DEFAULT_VIEW_LAYOUT';
export const MISC_SET_NAVBAR_SPACES_EXPANDED =
  'MISC_SET_NAVBAR_SPACES_EXPANDED';

// Methods
export const setHideMobileOverlay = (isHidden = true) => ({
  type: MISC_SET_HIDE_MOBILE_OVERLAY,
  isHidden,
});
export const setHideTrialEndedModal = (isHidden = true) => ({
  type: MISC_SET_HIDE_TRIAL_ENDED_MODAL,
  isHidden,
});
export const setCollapseTrackerOnboarding = (isCollapsed) => ({
  type: MISC_SET_COLLAPSE_TRACKING_ONBOARDING,
  isCollapsed,
});
export const setInvitationCode = (code) => ({
  type: MISC_SET_INVITATION_CODE,
  code,
});
export const setDefaultViewLayout = (layout) => ({
  type: MISC_SET_DEFAULT_VIEW_LAYOUT,
  layout,
});
export const setNavbarSpacesExpanded = (isExpanded) => ({
  type: MISC_SET_NAVBAR_SPACES_EXPANDED,
  isExpanded,
});
