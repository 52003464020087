import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {webhookService} from 'services';
import {Swaler} from 'swaler';

const logger = new Swaler('useWebhookDelete');

export const useWebhookDelete = ({onDelete} = {}) => {
  const [deleting, setDeleting] = useState(false);

  const deleteWebhook = async (webhookId) => {
    setDeleting(true);
    try {
      await webhookService.deleteWebhook(webhookId);
      onDelete(webhookId);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('deleting webhook failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setDeleting(false);
    }
  };

  return {
    deleting,
    deleteWebhook,
  };
};
