import {uptProject} from 'actions/general';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import Toggle from 'components/Toggle';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {amplitudeService} from 'services';
import {
  F_AMPLITUDE_DATA_AMPLITUDE_2_JIMO,
  F_AMPLITUDE_DATA_JIMO_2_AMPLITUDE,
} from 'services/amplitude';
import {Swaler} from 'swaler';
import './_Styles.scss';
import HelpSection from './components/HelpSection';

const logger = new Swaler('AmplitudeSettings');

const AmplitudeSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const project = useSelector(() => generalSelector.getProject());

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdateSetup = async (data) => {
    setIsUpdating(true);
    try {
      const updatedAmplitude = await amplitudeService.update(data);

      updateProject({
        ...project,
        amplitude: updatedAmplitude,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to update setup amplitude integration, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveSetup = async () => {
    setIsLoading(true);
    try {
      await amplitudeService.remove(project.uid);

      updateProject({
        ...project,
        amplitude: null,
      });
      history.push(ROUTE_SETTINGS_INTEGRATIONS);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to setup amplitude integration, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading === true)
    return (
      <SettingsBody className="amplitude-settings is-loading">
        <DefaultLoader />
      </SettingsBody>
    );

  const hasDataJimoToAmplitude = hasFlag(
    F_AMPLITUDE_DATA_JIMO_2_AMPLITUDE,
    project.amplitude.flags
  );
  const hasDataAmplitudeToJimo = hasFlag(
    F_AMPLITUDE_DATA_AMPLITUDE_2_JIMO,
    project.amplitude.flags
  );

  return (
    <SettingsBody className="amplitude-settings">
      <div className="amplitude-settings-header">
        <div className="left-side">
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={() => {
              history.push(ROUTE_SETTINGS_INTEGRATIONS);
            }}
          />
          <div className="title-3">Amplitude Integration</div>
        </div>
      </div>
      <div className="amplitude-settings-content">
        <div className="left-side">
          <div className="settings-card settings-jimo-to-amplitude">
            <div className="left-side">
              <div className="title-wrapper subtitle-3">
                Jimo <i className="isax isax-arrow-right-1"></i> Amplitude
              </div>
              <div className="body-3 n-700">
                Send Jimo related events to Amplitude
              </div>
            </div>
            <div className="right-side">
              <Toggle
                checked={hasDataJimoToAmplitude}
                onClick={() => {
                  handleUpdateSetup({
                    flags:
                      hasDataJimoToAmplitude === true
                        ? removeFlag(
                            F_AMPLITUDE_DATA_JIMO_2_AMPLITUDE,
                            project.amplitude.flags
                          )
                        : addFlag(
                            F_AMPLITUDE_DATA_JIMO_2_AMPLITUDE,
                            project.amplitude.flags
                          ),
                  });
                }}
              />
            </div>
          </div>
          <div className="settings-card settings-amplitude-to-jimo">
            <div className="left-side">
              <div className="title-wrapper subtitle-3">
                Amplitude <i className="isax isax-arrow-right-1"></i> Jimo
              </div>
              <div className="body-3 n-700">
                Send Amplitude related events to Jimo
              </div>
            </div>
            <div className="right-side">
              <Toggle
                checked={hasDataAmplitudeToJimo}
                onClick={() => {
                  handleUpdateSetup({
                    flags:
                      hasDataAmplitudeToJimo === true
                        ? removeFlag(
                            F_AMPLITUDE_DATA_AMPLITUDE_2_JIMO,
                            project.amplitude.flags
                          )
                        : addFlag(
                            F_AMPLITUDE_DATA_AMPLITUDE_2_JIMO,
                            project.amplitude.flags
                          ),
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="right-side">
          <HelpSection
            isDisconnecting={isLoading}
            onDisconnect={handleRemoveSetup}
          />
        </div>
      </div>
    </SettingsBody>
  );
};

export default AmplitudeSettings;
