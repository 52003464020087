import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import {BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW} from 'services/steps';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import './_Styles.scss';

const ResourceCenterTriggerArrow = () => {
  const {updateBlock: uptBlock, selectedStep: step} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW, updateObj);
  };

  const block = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW
  );

  if (block == null) {
    return <></>;
  }

  const {style = {}} = block;

  return (
    <div className="block-settings resource-center-trigger-arrow">
      <Section title="Layout">
        <PaddingItem
          value={{
            paddingTop: style.paddingTop,
            paddingBottom: style.paddingBottom,
            paddingLeft: style.paddingLeft,
            paddingRight: style.paddingRight,
          }}
          onChange={(value) => updateBlock({style: {...style, ...value}})}
        />
      </Section>
      <Divider />
      <Section title="Style">
        <ColorItem
          title="Icon color"
          value={style.iconColor}
          onChange={(value) =>
            updateBlock({
              style: {...style, iconColor: value},
            })
          }
        />
        <RadiusItem
          title="Border radius"
          value={style.borderRadius}
          onChange={(value) =>
            updateBlock({
              style: {...style, borderRadius: value},
            })
          }
        />
        <ColorItem
          title="Background"
          value={style.backgroundColor}
          onChange={(value) =>
            updateBlock({
              style: {...style, backgroundColor: value},
            })
          }
        />
        <ColorItem
          title="Border"
          value={style.borderColor}
          onChange={(value) =>
            updateBlock({
              style: {...style, borderColor: value},
            })
          }
        />
        <FontSizeItem
          title="Icon size"
          value={style.fontSize}
          onChange={(value) =>
            updateBlock({
              style: {...style, fontSize: value},
            })
          }
        />
      </Section>
      <Divider />
    </div>
  );
};

export default ResourceCenterTriggerArrow;
