import ColorItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/ColorItem';
import FontFamilyItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/FontFamilyItem';

const ChangelogTextStyle = ({style, setStyle}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      ...style,
      ...newStyle,
    });
  };

  return (
    <>
      <ColorItem
        title="Title color"
        value={style.titleFontColor}
        onChange={(value) => updateStyle({titleFontColor: value})}
      />
      <FontFamilyItem
        title="Title font family"
        value={style.titleFontFamily}
        onChange={(value) => updateStyle({titleFontFamily: value})}
      />
      <ColorItem
        title="Body color"
        value={style.bodyFontColor}
        onChange={(value) => updateStyle({bodyFontColor: value})}
      />
      <FontFamilyItem
        title="Body font family"
        value={style.bodyFontFamily}
        onChange={(value) => updateStyle({bodyFontFamily: value})}
      />
    </>
  );
};

export default ChangelogTextStyle;
