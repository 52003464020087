import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  BLOCK_RESOURCE_CENTER_ACTION,
  BLOCK_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
  BLOCK_RESOURCE_CENTER_GROUP,
} from '../../../Poke/constants/blocks';
import {PokeContext, PokeStateContext} from '../../../Poke/context';
import ResourceCenterAction from '../ResourceCenterAction';
import ResourceCenterEmbeddedChecklist from '../ResourceCenterEmbeddedChecklist';
import ResourceCenterGroup from '../ResourceCenterGroup';
import ResourceCenterMinimize from '../ResourceCenterMinimize';
import ResourceCenterSubtitle from '../ResourceCenterSubtitle';
import ResourceCenterTitle from '../ResourceCenterTitle';
import './_Styles.scss';

const ResourceCenterLayout = () => {
  const {poke} = useContext(PokeContext);
  const {step, blocks, JimoLabel, inBuilder, selectedBlock} =
    useContext(PokeStateContext);
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        setScrollPosition(contentRef.current.scrollTop);
      }
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', handleScroll);
      return () => contentElement.removeEventListener('scroll', handleScroll);
    }
  }, []);

  // Calculate transform progress (0-1) based on scroll position up to 60px
  const progress = Math.min(scrollPosition / 60, 1);

  const rootContainerBlocks = blocks
    .filter(
      (b) =>
        ([BLOCK_RESOURCE_CENTER_GROUP, BLOCK_RESOURCE_CENTER_ACTION].includes(
          b.type
        ) &&
          b.parentBlockId == null) ||
        b.type === BLOCK_RESOURCE_CENTER_EMBEDDED_CHECKLIST
    )
    .sort((a, b) => {
      const valueA = a.value;
      const valueB = b.value;
      // eslint-disable-next-line no-unused-vars
      const [_, indexA] = valueA.split('|-|');
      // eslint-disable-next-line no-unused-vars
      const [__, indexB] = valueB.split('|-|');

      return indexA - indexB;
    });

  const {style: pokeStyle} = poke;
  const {style} = step;

  const {backgroundColor, borderRadius} = pokeStyle ?? {};
  const {gap, paddingTop, paddingBottom, paddingLeft, paddingRight} =
    style ?? {};

  return (
    <div className="resource-center-layout">
      <div
        className="resource-center-header"
        style={{
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          backgroundColor,
          borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
          borderBottom:
            progress === 1
              ? `1px solid rgba(0, 0, 0, 0.1)`
              : `1px solid transparent`,
        }}>
        <div
          className="resource-center-header-content"
          style={{
            transform: `
              translateY(${46 * (1 - progress)}px) 
              scale(${1.4 - 0.4 * progress})
            `,
          }}>
          <ResourceCenterSubtitle />
          <ResourceCenterTitle />
        </div>
        <div className="resource-center-header-actions">
          <ResourceCenterMinimize />
        </div>
      </div>

      <div
        className="resource-center-content"
        ref={contentRef}
        style={{
          gap,
          paddingBottom: paddingBottom + (JimoLabel != null ? 42 : 0),
          paddingLeft,
          paddingRight,
        }}>
        {rootContainerBlocks.map((block) => {
          if (block.type === BLOCK_RESOURCE_CENTER_ACTION) {
            return <ResourceCenterAction key={block.uid} action={block} />;
          }
          if (block.type === BLOCK_RESOURCE_CENTER_GROUP) {
            return <ResourceCenterGroup key={block.uid} group={block} />;
          }
          if (block.type === BLOCK_RESOURCE_CENTER_EMBEDDED_CHECKLIST) {
            if (
              inBuilder === true &&
              selectedBlock !== BLOCK_RESOURCE_CENTER_EMBEDDED_CHECKLIST
            ) {
              return null;
            }

            return <ResourceCenterEmbeddedChecklist key={block.uid} />;
          }
          return null;
        })}
      </div>

      {JimoLabel != null && (
        <div className="jimo-label-wrapper">
          <JimoLabel />
        </div>
      )}
    </div>
  );
};

export default ResourceCenterLayout;
