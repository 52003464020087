import {F_STEP_DISCOVERY_STEP_REQUIRED} from '../..';
import {hasFlag} from '../../../../helpers/bitwise';
import {
  BLOCK_CHOICE,
  BLOCK_FLAG_CHOICE_CAN_MULTIPLE,
  BLOCK_NPS,
  BLOCK_OPEN_QUESTION,
  BLOCK_OPINION,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_SLIDER,
} from '../../constants/blocks';
export const shouldSubmitResponse = (currentStep) => {
  const interactivityBlock = currentStep.blocks.find((b) =>
    [
      BLOCK_CHOICE,
      BLOCK_NPS,
      BLOCK_OPEN_QUESTION,
      BLOCK_OPINION,
      BLOCK_SLIDER,
    ].includes(b.type)
  );
  const hasCta = currentStep.blocks.some((b) =>
    [BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA].includes(b.type)
  );

  if (interactivityBlock == null) {
    return false;
  }
  // Open question & Slider : always need to be submitted by CTA
  if (
    [BLOCK_OPEN_QUESTION, BLOCK_SLIDER, BLOCK_OPINION].includes(
      interactivityBlock.type
    )
  ) {
    return true;
  }
  // Choices : need to be submitted by CTA when multiple choices enabled
  if (
    interactivityBlock.type === BLOCK_CHOICE &&
    (hasFlag(BLOCK_FLAG_CHOICE_CAN_MULTIPLE, interactivityBlock.flags) ===
      true ||
      hasCta === true)
  ) {
    return true;
  }
  if (interactivityBlock.type === BLOCK_NPS && hasCta === true) {
    return true;
  }
  return false;
};

export const requiredInteractivityBlock = (currentStep) => {
  const hasInteractivityBlock = currentStep.blocks.find((b) =>
    [
      BLOCK_CHOICE,
      BLOCK_NPS,
      BLOCK_OPEN_QUESTION,
      BLOCK_OPINION,
      BLOCK_SLIDER,
    ].includes(b.type)
  );

  return (
    hasInteractivityBlock != null &&
    hasFlag(F_STEP_DISCOVERY_STEP_REQUIRED, currentStep?.stepFlags)
  );
};
