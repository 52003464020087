import {Icon} from '@iconify/react';
import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import {useContext, useEffect, useRef, useState} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {DropdownAddAction} from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/TriggerActions';
import {
  BLOCK_TYPE_RESOURCE_CENTER_ACTION,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
} from 'services/steps';
import {
  RESOURCE_CENTER_ACTION_CTA_TYPE_ICON,
  RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT,
  RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
  RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
} from 'shared/front/components/ResourceCenter/constants';
import {v4 as uuidv4} from 'uuid';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import FontWeightItem from '../../components/sectionItems/FontWeightItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import ShadowItem from '../../components/sectionItems/ShadowItem';
import ButtonAction from '../Button/components/ButtonAction';
import ResourceCenterIconPicker from '../ResourceCenterActionIcon/components/ResourceCenterIconPicker';
import './_Styles.scss';
import DropdownTypeResourceCenterActionPicker from './components/DropdownTypePicker';

const ResourceCenterActionCta = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedBlockType,
    updateStep,
    selectedSubItemId,
  } = useContext(BuilderContext);

  const [newlyAddedAction, setNewlyAddedAction] = useState(null);

  const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 300); // delay focus input of 300ms to match the animation and prevent whole page from scrolling
    return () => clearTimeout(timeout);
  }, []);

  const updateBlock = (updateObj, all = false) => {
    if (all !== true && block?.parentBlockId != null) {
      uptBlock(selectedBlockType, updateObj, {
        parentBlockId: block.parentBlockId,
      });
    } else {
      uptBlock(selectedBlockType, updateObj);
    }
  };

  const block = step?.blocks.find(
    (b) => b.type === selectedBlockType && b.parentBlockId === selectedSubItemId
  );
  const actionBlock = step?.blocks.find(
    (b) =>
      b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION &&
      b.uid === block.parentBlockId
  );

  const handleAddAction = (type, opts = {}) => {
    const newAction = {
      ...opts,
      uid: uuidv4(),
      type,
    };

    updateStep(step.uid, {
      blocks: step.blocks.map((block) =>
        block.uid === actionBlock.uid
          ? {
              ...block,
              actions: [...(actionBlock.actions || []), newAction],
            }
          : block
      ),
    });
    setNewlyAddedAction(newAction);
  };

  if (block == null) {
    return <></>;
  }

  const {actions = []} = actionBlock;
  const {style, value, file} = block;
  const {iconSource, iconName, iconUrl} = style;

  const icon =
    iconSource === RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR ? (
      <Icon icon={`ph:${iconName}`} />
    ) : iconSource === RESOURCE_CENTER_ICON_SOURCE_TWEMOJI ? (
      <Icon icon={`twemoji:${iconName}`} />
    ) : null;

  const authorizedActions = [
    STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
    STEP_CONDITION_ACTION_TYPE_OPEN_POST,
    STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
    STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  ].filter((a) => actions.map((action) => action.type).includes(a) !== true);

  const isIcon = style.type === RESOURCE_CENTER_ACTION_CTA_TYPE_ICON;
  const isText = style.type === RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT;
  const isNestedAction = actionBlock?.parentBlockId != null;

  return (
    <div className="block-settings resource-center-action-cta">
      <Section title="Type">
        <div className="section-item resource-center-action-type-picker">
          <DropdownTypeResourceCenterActionPicker
            value={style.type}
            onChange={(value) =>
              updateBlock({
                style: {type: value},
              })
            }
            omit={isNestedAction ? [RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT] : []}
          />
        </div>
      </Section>
      <Divider />
      <Section
        title="Actions"
        description="CTA action will match parent block Action">
        <SectionItem title="Actions" contentClassName="action-picker">
          <div className="actions-wrapper">
            {actions.map((action) => {
              return (
                <ButtonAction
                  key={action.uid}
                  action={action}
                  setAction={(updatedAction) => {
                    updateStep(step.uid, {
                      blocks: step.blocks.map((block) =>
                        block.uid === actionBlock.uid
                          ? {
                              ...block,
                              actions: actions.map((_action) =>
                                _action.uid === action.uid
                                  ? updatedAction
                                  : _action
                              ),
                            }
                          : block
                      ),
                    });
                  }}
                  onDelete={() => {
                    updateStep(step.uid, {
                      blocks: step.blocks.map((block) =>
                        block.uid === actionBlock.uid
                          ? {
                              ...block,
                              actions: actions.filter(
                                (_action) => _action.uid !== action.uid
                              ),
                            }
                          : block
                      ),
                    });
                  }}
                  defaultOpen={newlyAddedAction?.uid === action.uid}
                />
              );
            })}
            <DropdownAddAction
              className="add-action-element-button"
              authorizedActions={authorizedActions}
              usedActionTypes={actions.map((action) => action.type)}
              onAddAction={handleAddAction}
              position="left top"
              offsetY={-4}
              offsetX={8}
              trigger={
                <ClickableInput
                  className="add-action-element-placeholder-btn"
                  inputProps={{placeholder: 'Add action...'}}
                  leftLabel={
                    <div className="action-icon-wrapper">
                      <i className="isax isax-flash-15" />
                    </div>
                  }
                />
              }
            />
          </div>
        </SectionItem>
      </Section>
      <Divider />
      {isText && (
        <>
          <Section title="Content">
            <SectionItem title="Text">
              <InputGroup
                className="button-content-input"
                value={value}
                onChange={({target}) =>
                  updateBlock({
                    value: target.value,
                  })
                }
                ref={inputRef}
              />
            </SectionItem>
          </Section>
          <Divider />
        </>
      )}
      {isIcon && (
        <>
          <Section title="Content">
            <SectionItem title="Icon" sectionItemClassName="item-icon-picker">
              <PopupSetting
                className="picker-icon-popup"
                trigger={
                  <ClickableInput
                    value={
                      [
                        RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
                        RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
                      ].includes(iconSource)
                        ? iconName
                            ?.split('-')
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(' ')
                        : iconUrl != null
                        ? 'Custom Icon'
                        : null
                    }
                    leftLabel={
                      <span className="preview-icon">
                        {[
                          RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
                          RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
                        ].includes(iconSource) ? (
                          icon
                        ) : iconUrl ? (
                          <img src={iconUrl} alt="" />
                        ) : (
                          <></>
                        )}
                      </span>
                    }
                    inputProps={{
                      placeholder: 'Select an icon',
                    }}
                  />
                }
                title="Icon"
                content={
                  <ResourceCenterIconPicker
                    style={style}
                    file={file}
                    type={BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA}
                    onChange={({style: updatedStyle, file}) => {
                      updateBlock({
                        style: {
                          ...updatedStyle,
                        },
                        file,
                      });
                    }}
                  />
                }
              />
            </SectionItem>
          </Section>
          <Divider />
        </>
      )}
      <Section
        title={
          <>
            <i className="isax isax-main-component5 p-500" />
            Style
          </>
        }
        titleExtra={
          <>
            <div className="body-4 n-700">For every</div>
            <div className="block-group subtitle-4 n-800">
              <div className="icon-wrapper">
                <i className="isax isax-arrow-right-2" />
              </div>
              {isText ? 'Text CTA' : 'Icon CTA'}
            </div>
          </>
        }>
        {isText && (
          <ResourceCenterActionCtaTextStyle
            style={style}
            updateStyle={(value) => updateBlock(value, true)}
          />
        )}
        {isIcon && (
          <ResourceCenterActionCtaIconStyle
            style={style}
            updateStyle={(value) => updateBlock(value, true)}
            hideIconColor={iconSource === RESOURCE_CENTER_ICON_SOURCE_TWEMOJI}
          />
        )}
      </Section>
      <Divider />
    </div>
  );
};

export const ResourceCenterActionCtaIconStyle = ({
  style,
  updateStyle,
  hideIconColor = false,
}) => {
  return (
    <>
      <PaddingItem
        value={{
          paddingTop: style.iconPaddingTop,
          paddingBottom: style.iconPaddingBottom,
          paddingLeft: style.iconPaddingLeft,
          paddingRight: style.iconPaddingRight,
        }}
        onChange={(value) =>
          updateStyle({
            style: {
              iconPaddingTop: value.paddingTop,
              iconPaddingBottom: value.paddingBottom,
              iconPaddingLeft: value.paddingLeft,
              iconPaddingRight: value.paddingRight,
            },
          })
        }
      />
      <RadiusItem
        value={style.iconBorderRadius}
        onChange={(value) =>
          updateStyle({
            style: {
              iconBorderRadius: value,
            },
          })
        }
      />
      <FontSizeItem
        title="Icon size"
        value={style.iconFontSize}
        onChange={(value) =>
          updateStyle({
            style: {iconFontSize: value},
          })
        }
      />
      {!hideIconColor && (
        <ColorItem
          title="Icon Color"
          value={style.iconColor}
          onChange={(value) =>
            updateStyle({
              style: {iconColor: value},
            })
          }
        />
      )}
      <ColorItem
        title="Background"
        value={style.iconBackgroundColor}
        onChange={(value) =>
          updateStyle({
            style: {iconBackgroundColor: value},
          })
        }
      />
      <ColorItem
        title="Border"
        value={style.iconBorderColor}
        onChange={(value) =>
          updateStyle({
            style: {iconBorderColor: value},
          })
        }
        colorPickerProps={{erasable: true}}
      />
      <ShadowItem
        value={style.iconShadow}
        onChange={(value) =>
          updateStyle({
            style: {iconShadow: value},
          })
        }
      />
    </>
  );
};

export const ResourceCenterActionCtaTextStyle = ({style, updateStyle}) => {
  return (
    <>
      <FontFamilyItem
        value={style.textFontFamily}
        onChange={(value) =>
          updateStyle({
            style: {textFontFamily: value},
          })
        }
      />
      <SectionItem title="Font size">
        <PixelPicker
          small
          value={style.textFontSize}
          min={8}
          max={42}
          onChange={(value) => {
            updateStyle({
              style: {textFontSize: value},
            });
          }}
        />
      </SectionItem>
      <FontWeightItem
        value={style.textFontWeight}
        onChange={(value) =>
          updateStyle({
            style: {textFontWeight: value},
          })
        }
      />
      <PaddingItem
        value={{
          paddingTop: style.textPaddingTop,
          paddingBottom: style.textPaddingBottom,
          paddingLeft: style.textPaddingLeft,
          paddingRight: style.textPaddingRight,
        }}
        onChange={(value) =>
          updateStyle({
            style: {
              textPaddingTop: value.paddingTop,
              textPaddingBottom: value.paddingBottom,
              textPaddingLeft: value.paddingLeft,
              textPaddingRight: value.paddingRight,
            },
          })
        }
      />
      <RadiusItem
        value={style.textBorderRadius}
        onChange={(value) =>
          updateStyle({
            style: {textBorderRadius: value},
          })
        }
      />
      <SectionItem title="Text color">
        <ColorPickerInput
          inputProps={{small: true}}
          title="Text color"
          value={style.textFontColor}
          onChange={(value) =>
            updateStyle({
              style: {textFontColor: value},
            })
          }
        />
      </SectionItem>
      <SectionItem title="Background">
        <ColorPickerInput
          inputProps={{small: true}}
          title={'Background'}
          value={style.textBackgroundColor}
          onChange={(value) =>
            updateStyle({
              style: {textBackgroundColor: value},
            })
          }
          erasable
        />
      </SectionItem>
      <SectionItem title="Border">
        <ColorPickerInput
          inputProps={{small: true}}
          title="Border color"
          value={style.textBorderColor}
          onChange={(value) =>
            updateStyle({
              style: {textBorderColor: value},
            })
          }
          erasable
        />
      </SectionItem>
      <ShadowItem
        value={style.textShadow}
        onChange={(value) =>
          updateStyle({
            style: {textShadow: value},
          })
        }
      />
    </>
  );
};

export default ResourceCenterActionCta;
