import {
  BLOCK_TYPE_RESOURCE_CENTER_ACTION,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_PARAGRAPH,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE,
} from 'services/steps';
import {getDefaultBlockFromType} from '../BlockManager/utils';

export const getDefaultResourceCenterActionBlocks = (evolution, newBlockId) => {
  const step = evolution?.steps[0];
  const existingBlocks = step?.blocks || [];
  const blocks = [];

  const existingActionTitleBlock = existingBlocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE
  );
  const existingActionParagraphBlock = existingBlocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION_PARAGRAPH
  );
  const existingActionIconBlock = existingBlocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON
  );
  const existingActionTextCtaBlock = existingBlocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA
  );

  blocks.push({
    ...getDefaultBlockFromType(
      BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE,
      evolution?.theme
    ),
    ...(existingActionTitleBlock && {style: existingActionTitleBlock.style}),
    parentBlockId: newBlockId,
  });

  blocks.push({
    ...getDefaultBlockFromType(
      BLOCK_TYPE_RESOURCE_CENTER_ACTION_PARAGRAPH,
      evolution?.theme
    ),
    ...(existingActionParagraphBlock && {
      style: existingActionParagraphBlock.style,
    }),
    parentBlockId: newBlockId,
  });

  blocks.push({
    ...getDefaultBlockFromType(
      BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON,
      evolution?.theme
    ),
    ...(existingActionIconBlock && {
      style: {
        ...existingActionIconBlock.style,
        iconName: getDefaultBlockFromType(
          BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON,
          evolution?.theme
        ).style.iconName,
        iconSource: getDefaultBlockFromType(
          BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON,
          evolution?.theme
        ).style.iconSource,
        file: null,
      },
    }),
    parentBlockId: newBlockId,
  });

  blocks.push({
    ...getDefaultBlockFromType(
      BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA,
      evolution?.theme
    ),
    ...(existingActionTextCtaBlock && {
      style: {
        ...existingActionTextCtaBlock.style,
        iconName: getDefaultBlockFromType(
          BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA,
          evolution?.theme
        ).style.iconName,
        iconSource: getDefaultBlockFromType(
          BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA,
          evolution?.theme
        ).style.iconSource,
        file: null,
      },
    }),
    parentBlockId: newBlockId,
  });

  return blocks;
};

export const getDefaultResourceCenterGroupBlocks = (evolution, newBlockId) => {
  const step = evolution?.steps[0];
  const existingBlocks = step?.blocks || [];
  const blocks = [];

  const existingGroupTitleBlock = existingBlocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE
  );
  const existingGroupActionBlock = existingBlocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION
  );

  blocks.push({
    ...getDefaultBlockFromType(
      BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE,
      evolution?.theme
    ),
    ...(existingGroupTitleBlock && {
      style: existingGroupTitleBlock.style,
    }),
    parentBlockId: newBlockId,
  });

  const newActionBlock1 = {
    ...getDefaultBlockFromType(
      BLOCK_TYPE_RESOURCE_CENTER_ACTION,
      evolution?.theme
    ),
    ...(existingGroupActionBlock && {
      style: existingGroupActionBlock.style,
    }),
    parentBlockId: newBlockId,
    value: `Block #1|-|0`,
  };

  const newActionBlock2 = {
    ...getDefaultBlockFromType(
      BLOCK_TYPE_RESOURCE_CENTER_ACTION,
      evolution?.theme
    ),
    ...(existingGroupActionBlock && {
      style: existingGroupActionBlock.style,
    }),
    parentBlockId: newBlockId,
    value: `Block #2|-|1`,
  };

  blocks.push(newActionBlock1);
  blocks.push(newActionBlock2);
  blocks.push(
    ...getDefaultResourceCenterActionBlocks(evolution, newActionBlock1.uid)
  );
  blocks.push(
    ...getDefaultResourceCenterActionBlocks(evolution, newActionBlock2.uid)
  );

  return blocks;
};
