import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import {BuilderContext} from 'contextes/builder';
import {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {
  RESOURCE_CENTER_GROUP_TYPE_GRID,
  RESOURCE_CENTER_GROUP_TYPE_STACK,
} from 'shared/front/components/ResourceCenter/constants';
import GapItem from '../../components/sectionItems/GapItem';
import './_Styles.scss';

const ResourceCenterGroup = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedBlockType,
    selectedSubItemId,
  } = useContext(BuilderContext);

  const block = step?.blocks.find(
    (b) => b.type === selectedBlockType && b.uid === selectedSubItemId
  );

  const updateBlock = (updateObj, all = false) => {
    if (all !== true) {
      uptBlock(selectedBlockType, updateObj, {
        blockId: block.uid,
      });
    } else {
      uptBlock(selectedBlockType, updateObj);
    }
  };

  if (block == null) {
    return <></>;
  }

  const {style} = block;

  return (
    <div className="block-settings resource-center-group">
      <Section>
        <SectionItem title="Type">
          <RadioGroup
            options={[
              {label: 'Stack', value: RESOURCE_CENTER_GROUP_TYPE_STACK},
              {label: 'Grid', value: RESOURCE_CENTER_GROUP_TYPE_GRID},
            ]}
            value={style.type}
            onSelect={(value) => updateBlock({style: {...style, type: value}})}
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section
        title={
          <>
            <i className="isax isax-main-component5 p-500" />
            Style
          </>
        }
        titleExtra={
          <>
            <div className="body-4 n-700">For every</div>
            <div className="block-group subtitle-4 n-800">
              <div className="icon-wrapper">
                <i className="isax isax-document" />
              </div>
              Group
            </div>
          </>
        }>
        <ResourceCenterGroupStyle
          style={style}
          updateStyle={(updateObj) => updateBlock(updateObj, true)}
        />
      </Section>
      <Divider />
    </div>
  );
};

export const ResourceCenterGroupStyle = ({style, updateStyle}) => {
  return (
    <>
      <GapItem
        value={style.titleGap ?? 16}
        onChange={(value) =>
          updateStyle({
            style: {titleGap: value},
          })
        }
        title="Title Gap"
      />
      <GapItem
        value={style.elementGap ?? 16}
        onChange={(value) =>
          updateStyle({
            style: {elementGap: value},
          })
        }
        title="Element Gap"
      />
    </>
  );
};

export default ResourceCenterGroup;
