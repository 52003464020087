import Divider from 'components/Divider';
import {func, object} from 'prop-types';
import {HINT_TYPE_HIDDEN} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Hint';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';
import HintAlignment from 'scenes/PokeBuilder/components/BlockEditor/components/modals/HintPosition/components/HintAlignment';
import ZIndex from 'scenes/PokeBuilder/components/BlockEditor/components/modals/ZIndex';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {ElementSelector} from 'scenes/SuccessTracker/components/Event/components/EventConditions/components/EditDropdown/components/ElementSelector';
import {BLOCK_TYPE_HINT} from 'services/steps';
import './_Styles.scss';

const propTypes = {
  evolution: object,
  setEvolution: func,
};

const HintElementEditor = ({evolution, setEvolution, messenger, image}) => {
  const step = evolution.steps[0];
  const block = step.blocks.find((b) => b.type === BLOCK_TYPE_HINT);
  const {style = {}} = block || {};
  const {offsetX, offsetY, type} = style;

  const updateBlock = (updateObj) => {
    setEvolution({
      ...evolution,
      steps: evolution.steps.map((step) => {
        return {
          ...step,
          blocks: step.blocks.map((block) => {
            if (block.type === BLOCK_TYPE_HINT) {
              return {...block, ...updateObj};
            }
            return block;
          }),
        };
      }),
    });
  };

  return (
    <div className="block-settings hint-element-editor">
      <div className="section-item target-picker">
        <ElementSelector
          data={{
            querySelector: evolution?.boostedQueryselector,
            querySelectors: evolution?.querySelectors,
            file: evolution?.file,
            querySelectorManual: evolution?.querySelectorManual,
          }}
          onChange={(data) => {
            setEvolution({
              ...evolution,
              boostedQueryselector: data.querySelector,
              file: data.file,
              querySelectorManual: data.querySelectorManual,
            });
          }}
          withElementsCount
          onSelectElement={messenger.sendSelectTargetElement}
          image={image}
        />
      </div>
      <Divider />
      {type !== HINT_TYPE_HIDDEN && (
        <>
          <HintAlignment
            style={style}
            onChange={(style) =>
              updateBlock({
                style: {
                  ...style,
                },
              })
            }
          />
          <SectionItem title="Offset X">
            <PixelPicker
              value={offsetX}
              min={-400}
              max={400}
              label=""
              onChange={(value) =>
                updateBlock({
                  style: {
                    ...style,
                    offsetX: value,
                  },
                })
              }
            />
          </SectionItem>
          <SectionItem title="Offset Y">
            <PixelPicker
              value={offsetY}
              min={-400}
              max={400}
              label=""
              onChange={(value) =>
                updateBlock({
                  style: {
                    ...style,
                    offsetY: value,
                  },
                })
              }
            />
          </SectionItem>
        </>
      )}
      <div className="section-item z-index">
        <div className="section-item-title">Z-index</div>
        <div className="section-item-content">
          <PopupSetting
            trigger={
              <ClickableInput
                className="position-z-index"
                value={
                  evolution.boostedZIndex != null
                    ? `${evolution.boostedZIndex}`
                    : 'Auto'
                }
                leftLabel={
                  <div className="icon-wrapper">
                    <i className="icon-duplicate" />
                  </div>
                }
              />
            }
            title="Z-index"
            content={
              <ZIndex
                value={evolution.boostedZIndex}
                onChange={(value) =>
                  setEvolution({
                    ...evolution,
                    boostedZIndex: value,
                  })
                }
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

HintElementEditor.propTypes = propTypes;

export default HintElementEditor;
