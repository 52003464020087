import classNames from 'classnames';
import React, {useContext, useState} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {
  BLOCK_RESOURCE_CENTER_ACTION,
  BLOCK_RESOURCE_CENTER_ACTION_CTA,
  BLOCK_RESOURCE_CENTER_ACTION_ICON,
  BLOCK_RESOURCE_CENTER_ACTION_MEDIA,
  BLOCK_RESOURCE_CENTER_ACTION_PARAGRAPH,
  BLOCK_RESOURCE_CENTER_ACTION_TITLE,
} from '../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../Poke/context';
import {
  RESOURCE_CENTER_ACTION_CTA_TYPE_ICON,
  RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT,
} from '../../constants';
import './_Styles.scss';
import ResourceCenterActionCta from './components/ResourceCenterActionCta';
import ResourceCenterActionIcon from './components/ResourceCenterActionIcon';
import ResourceCenterActionMedia from './components/ResourceCenterActionMedia';
import ResourceCenterActionParagraph from './components/ResourceCenterActionParagraph';
import ResourceCenterActionTitle from './components/ResourceCenterActionTitle';

const ResourceCenterAction = ({
  action,
  isInStack = false,
  isInGrid = false,
}) => {
  const {
    onBlockSelected,
    onContainerSelected,
    selectedContainerId,
    selectedBlock,
    inBuilder,
    blocks,
    onTriggerActions,
    onActionClick,
  } = useContext(PokeStateContext);

  const [isHovered, setIsHovered] = useState(false);

  const actionBlocks = blocks.filter((b) => b.parentBlockId === action.uid);
  const titleBlock = actionBlocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_ACTION_TITLE
  );
  const paragraphBlock = actionBlocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_ACTION_PARAGRAPH
  );
  const mediaBlock = actionBlocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_ACTION_MEDIA
  );
  const ctaBlock = actionBlocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_ACTION_CTA
  );
  const iconBlock = actionBlocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_ACTION_ICON
  );

  const hasTextCta =
    ctaBlock != null &&
    ctaBlock?.style?.type === RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT;

  const {style} = action;
  const {
    backgroundColor,
    borderColor,
    borderRadius = 0,
    paddingTop = 0,
    paddingBottom = 0,
    paddingLeft = 0,
    paddingRight = 0,
    gap = 0,
    shadow,
    hoverBackgroundColor,
    hoverBorderColor,
    hoverShadow,
    hoverScale,
  } = style;

  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = color != null ? `${x}px ${y}px ${blur}px ${color}` : 'none';

  const {
    x: hoverX,
    y: hoverY,
    blur: hoverBlur,
    color: hoverColor,
  } = hoverShadow ?? {};
  const hoverBoxShadow =
    hoverColor != null
      ? `${hoverX}px ${hoverY}px ${hoverBlur}px ${hoverColor}`
      : 'none';

  return (
    <div
      className={classNames('block-resource-center-action-wrapper', {
        'poke-block-clickable': inBuilder === true,
        selected:
          selectedBlock === BLOCK_RESOURCE_CENTER_ACTION &&
          selectedContainerId === action?.uid,
      })}
      onClick={(e) => {
        e.stopPropagation();

        if (inBuilder === true) {
          onBlockSelected(BLOCK_RESOURCE_CENTER_ACTION);
          onContainerSelected(action.uid);
          return;
        }

        if (hasTextCta !== true) {
          onTriggerActions(action.actions || []);
          onActionClick({block: action});
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div
        className={classNames('block-resource-center-action', {
          'has-text-cta': hasTextCta === true,
        })}
        style={{
          // if border or hovered border is set, set default border to avoid weird effect
          // when border is set to 0
          ...(borderColor != null || hoverBorderColor != null
            ? {border: `1px solid transparent`}
            : {}),

          ...(borderColor != null ? {border: `1px solid ${borderColor}`} : {}),
          ...(backgroundColor != null ? {backgroundColor} : {}),
          borderRadius,
          boxShadow,
          ...(isInStack === true || isInGrid === true
            ? {paddingTop, paddingBottom, paddingLeft, paddingRight}
            : {}),

          ...(isHovered === true
            ? {
                ...(hoverBackgroundColor != null
                  ? {backgroundColor: hoverBackgroundColor}
                  : {}),
                ...(hoverBorderColor != null
                  ? {border: `1px solid ${hoverBorderColor}`}
                  : {}),
                ...(hoverShadow != null ? {boxShadow: hoverBoxShadow} : {}),
                ...(hoverScale != null
                  ? {transform: `scale(${hoverScale})`}
                  : {}),
              }
            : {}),
        }}>
        <div
          className={classNames('action-content', {
            'is-stack': isInStack === true,
            'is-grid': isInGrid === true,
            'is-standalone': isInStack !== true && isInGrid !== true,
          })}
          style={{
            ...(isInStack === true || isInGrid === true ? {gap} : {}),
          }}>
          {isInStack === true ? (
            // Stack layout
            <div className="action-content-body" style={{gap}}>
              {iconBlock != null && (
                <ResourceCenterActionIcon block={iconBlock} />
              )}
              <div className="action-content-body-text">
                {titleBlock != null && (
                  <ResourceCenterActionTitle block={titleBlock} />
                )}
                {paragraphBlock != null && (
                  <ResourceCenterActionParagraph block={paragraphBlock} />
                )}
              </div>
              {ctaBlock != null &&
                ctaBlock?.style?.type ===
                  RESOURCE_CENTER_ACTION_CTA_TYPE_ICON && (
                  <ResourceCenterActionCta block={ctaBlock} />
                )}
            </div>
          ) : isInGrid === true ? (
            // Grid layout
            <div className="action-content-body" style={{gap}}>
              {(iconBlock != null ||
                (ctaBlock != null &&
                  ctaBlock?.style?.type ===
                    RESOURCE_CENTER_ACTION_CTA_TYPE_ICON)) && (
                <div className="action-content-body-header">
                  {iconBlock != null && (
                    <ResourceCenterActionIcon block={iconBlock} />
                  )}
                  {ctaBlock != null &&
                    ctaBlock?.style?.type ===
                      RESOURCE_CENTER_ACTION_CTA_TYPE_ICON && (
                      <ResourceCenterActionCta block={ctaBlock} />
                    )}
                </div>
              )}
              <div className="action-content-body-text">
                {titleBlock != null && (
                  <ResourceCenterActionTitle block={titleBlock} />
                )}
                {paragraphBlock != null && (
                  <ResourceCenterActionParagraph block={paragraphBlock} />
                )}
              </div>
            </div>
          ) : (
            // Default layout
            <>
              {mediaBlock != null && (
                <ResourceCenterActionMedia block={mediaBlock} />
              )}
              <div
                className="action-content-body-wrapper"
                style={{
                  gap,
                  paddingTop,
                  paddingBottom,
                  paddingLeft,
                  paddingRight,
                }}>
                <div className="action-content-body" style={{gap}}>
                  {iconBlock != null && (
                    <ResourceCenterActionIcon block={iconBlock} />
                  )}
                  <div className="action-content-body-text">
                    {titleBlock != null && (
                      <ResourceCenterActionTitle block={titleBlock} />
                    )}
                    {paragraphBlock != null && (
                      <ResourceCenterActionParagraph block={paragraphBlock} />
                    )}
                  </div>
                  {ctaBlock != null &&
                    ctaBlock?.style?.type ===
                      RESOURCE_CENTER_ACTION_CTA_TYPE_ICON && (
                      <ResourceCenterActionCta block={ctaBlock} />
                    )}
                </div>
                {ctaBlock != null &&
                  ctaBlock?.style?.type ===
                    RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT && (
                    <ResourceCenterActionCta block={ctaBlock} />
                  )}
              </div>
            </>
          )}
        </div>
      </div>
      <ClickableBlockOverlay />
    </div>
  );
};

export default ResourceCenterAction;
