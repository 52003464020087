import Alert from 'components/Alert';
import {crispHelpers} from 'helpers';
import React from 'react';
import './_Styles.scss';

const FullSyncSection = ({salesforce, isSalesforceSyncing}) => {
  const {fullSyncData} = salesforce || {};
  const {status, totalContacts, totalMatches} = fullSyncData || {};
  const isRunning = status === 'RUNNING' || isSalesforceSyncing;

  const alertIcon = isRunning
    ? 'isax isax-refresh'
    : status === 'SUCCESS'
    ? 'isax isax-tick-circle'
    : status === 'FAILED'
    ? 'isax isax-danger'
    : 'isax isax-refresh-circle';
  const alertTitle = isRunning
    ? 'Full sync running'
    : status == null
    ? 'Launch your first synchronization'
    : status === 'FAILED'
    ? 'Full sync failed'
    : `Full sync completed`;
  const alertContent = isRunning ? (
    `Scanned ${totalContacts} contacts so far, found ${totalMatches} matches. Be patient, this process can take time based on the number of contacts in Salesforce.`
  ) : status == null ? (
    'Click on Synchronize to launch your first synchronization.'
  ) : status === 'FAILED' ? (
    <>
      Try to resync. If this error persists,{' '}
      <span
        onClick={() =>
          crispHelpers.startCrispThread(
            "I'm having issues with the Salesforce integration while trying to synchronize my data. Can you help me ? :-)"
          )
        }>
        let's chat!
      </span>
    </>
  ) : (
    `${totalContacts} contacts found, ${totalMatches} match in Jimo`
  );

  return (
    <Alert
      info={status == null}
      danger={status === 'FAILED'}
      warning={isRunning}
      success={status === 'SUCCESS'}
      icon={alertIcon}
      title={alertTitle}
      className="salesforce-full-sync-section">
      {alertContent}
    </Alert>
  );
};

export default FullSyncSection;
