import classNames from 'classnames';
import React, {useContext} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {
  BLOCK_RESOURCE_CENTER_ACTION,
  BLOCK_RESOURCE_CENTER_GROUP,
  BLOCK_RESOURCE_CENTER_GROUP_TITLE,
} from '../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../Poke/context';
import {
  RESOURCE_CENTER_GROUP_TYPE_GRID,
  RESOURCE_CENTER_GROUP_TYPE_STACK,
} from '../../constants';
import ResourceCenterAction from '../ResourceCenterAction';
import './_Styles.scss';
import ResourceCenterGroupTitle from './components/ResourceCenterGroupTitle';

const ResourceCenterGroup = ({group}) => {
  const {
    onBlockSelected,
    onContainerSelected,
    selectedContainerId,
    selectedBlock,
    inBuilder,
    blocks,
  } = useContext(PokeStateContext);

  const groupBlocks = blocks.filter((b) => b.parentBlockId === group.uid);
  const titleBlock = groupBlocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_GROUP_TITLE
  );
  const actionsBlocks = groupBlocks
    .filter((b) => b.type === BLOCK_RESOURCE_CENTER_ACTION)
    .sort((a, b) => {
      const valueA = a.value;
      const valueB = b.value;
      // eslint-disable-next-line no-unused-vars
      const [_, indexA] = valueA.split('|-|');
      // eslint-disable-next-line no-unused-vars
      const [__, indexB] = valueB.split('|-|');

      return indexA - indexB;
    });

  const {style} = group;
  const {titleGap, elementGap, type} = style ?? {};

  return (
    <div
      className={classNames('resource-center-group', {
        'poke-block-clickable': inBuilder === true,
        selected:
          selectedBlock === BLOCK_RESOURCE_CENTER_GROUP &&
          selectedContainerId === group.uid,
        'in-builder': inBuilder === true,
        'is-stack': type === RESOURCE_CENTER_GROUP_TYPE_STACK,
        'is-grid': type === RESOURCE_CENTER_GROUP_TYPE_GRID,
      })}
      style={{
        gap: titleGap,
      }}
      onClick={(e) => {
        e.stopPropagation();

        if (inBuilder === true) {
          onBlockSelected(BLOCK_RESOURCE_CENTER_GROUP);
          onContainerSelected(group.uid);
          return;
        }
      }}>
      {titleBlock != null && <ResourceCenterGroupTitle block={titleBlock} />}
      <div className="resource-center-group-content" style={{gap: elementGap}}>
        {actionsBlocks.map((action) => {
          return (
            <ResourceCenterAction
              key={action.uid}
              action={action}
              isInStack={type === RESOURCE_CENTER_GROUP_TYPE_STACK}
              isInGrid={type === RESOURCE_CENTER_GROUP_TYPE_GRID}
            />
          );
        })}
      </div>
      <ClickableBlockOverlay />
    </div>
  );
};

export default ResourceCenterGroup;
