import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS} from 'services/steps';
import {
  RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_COUNT,
  RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_PERCENTAGE,
} from 'shared/front/components/ResourceCenter/constants';
import ColorItem from '../../components/sectionItems/ColorItem';
import './_Styles.scss';

const ResourceCenterTriggerChecklistProgress = () => {
  const {updateBlock: uptBlock, selectedStep: step} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS, updateObj);
  };

  const block = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS
  );

  if (block == null) {
    return <></>;
  }

  const {style = {}} = block;

  return (
    <div className="block-settings resource-center-trigger-checklist-progress">
      <Section title="Style">
        <SectionItem title="Type">
          <RadioGroup
            options={[
              {
                label: 'Count',
                value: RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_COUNT,
              },
              {
                label: 'Percentage',
                value:
                  RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_PERCENTAGE,
              },
            ]}
            value={style.type}
            onSelect={(value) =>
              updateBlock({
                style: {...style, type: value},
              })
            }
          />
        </SectionItem>
        <ColorItem
          title="Color"
          value={style.color}
          onChange={(value) =>
            updateBlock({
              style: {...style, color: value},
            })
          }
        />
      </Section>
      <Divider />
    </div>
  );
};

export default ResourceCenterTriggerChecklistProgress;
