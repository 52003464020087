import classnames from 'classnames';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import DefaultLoader from 'components/Loader';
import {ModalConfirmV2} from 'components/Modal';
import TextArea from 'components/TextArea';
import {toastDanger, toastInfo} from 'components/Toaster';
import Toggle from 'components/Toggle';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {useEffect, useRef, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {useHistory, useRouteMatch} from 'react-router-dom/cjs/react-router-dom';
import {
  ROUTE_USERS_IMPORTS,
  ROUTE_USERS_IMPORT_CSV_WITH_ID,
} from 'router/routes.const';
import {
  dataImportService,
  fileService,
  projectService,
  segmentService,
} from 'services';
import {
  DATA_IMPORT_STATUS_DONE,
  DATA_IMPORT_STATUS_DRAFT,
  DATA_IMPORT_STATUS_ERROR,
  DATA_IMPORT_STATUS_IN_PROGRESS,
} from 'services/data-import';
import {Swaler} from 'swaler';
import './_styles.scss';
import {IdentifyField} from './components/IdentifyField';
import {MapField} from './components/MapField';

const IDENTIFY_FIELD_ID = 'ID';
const IDENTIFY_FIELD_EMAIL = 'EMAIL';
const OTHER_FIELD_USERNAME = 'USERNAME';

const logger = new Swaler('Import/CSV');

export const ImportCSV = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const {importId} = match.params;

  const refInputFile = useRef(null);

  const [filePicked, setFilePicked] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [dataImport, setDataImport] = useState(null);
  const [collapsedSections, setCollapsedSections] = useState([]);
  const [projectIdentifiers, setProjectIdentifiers] = useState([]);
  const [projectAttributes, setProjectAttributes] = useState([]);
  const [projectEmails, setProjectEmails] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const isSectionCollapsed = (sectionId) =>
    collapsedSections.includes(sectionId);
  const triggerCollapseSection = (sectionId) =>
    isSectionCollapsed(sectionId)
      ? setCollapsedSections(collapsedSections.filter((id) => id !== sectionId))
      : setCollapsedSections(collapsedSections.concat(sectionId));
  const submitCsvFile = async () => {
    setIsSubmitting(true);
    try {
      const uploadedFile = await fileService.uploadCsvFile({
        file: filePicked,
      });
      setCsvFile(uploadedFile);
      createDataImport(uploadedFile);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to upload csv file', code);
      toastDanger([title, message], {actions});
      setIsSubmitting(false);
      return null;
    }
  };
  const createDataImport = async (file) => {
    setIsSubmitting(true);
    try {
      const dataImport = await dataImportService.createImportUsersCsv({
        name,
        description,
        fileId: file.uid,
      });

      setDataImport(dataImport);
      history.push(ROUTE_USERS_IMPORT_CSV_WITH_ID(dataImport.uid));
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to create data import for csv user import ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsSubmitting(false);
    }
  };
  const updateDataImport = async () => {
    setIsSubmitting(true);
    try {
      const dataImportUpdated = await dataImportService.updateImportUsersCsv(
        importId,
        {
          name,
          description,
        }
      );

      setDataImport({
        ...dataImport,
        ...dataImportUpdated,
      });
      setCollapsedSections([1, 3]);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to update data import for csv user import ', code);
      toastDanger([title, message], {actions});
      return null;
    } finally {
      setIsSubmitting(false);
    }
  };
  const updateDataImportIdentification = async () => {
    const {
      enableAddUser,
      enableUpdateUser,
      jimoIdentifier,
      csvIdentifier,
      createSegment,
    } = dataImport.config;

    setIsSubmitting(true);
    try {
      const dataImportUpdated = await dataImportService.updateImportUsersCsv(
        importId,
        {
          enableAddUser,
          enableUpdateUser,
          jimoIdentifier,
          csvIdentifier,
          createSegment,
        }
      );

      setDataImport({
        ...dataImport,
        ...dataImportUpdated,
      });
      setCollapsedSections([1, 2]);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(
        'Failed to update csv user data import config for identification ',
        code
      );
      toastDanger([title, message], {actions});
      return null;
    } finally {
      setIsSubmitting(false);
    }
  };
  const deleteDataImport = async () => {
    setIsDeleting(true);
    try {
      await dataImportService.deleteImportUsersCsv(importId);
      history.push(ROUTE_USERS_IMPORTS);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to delete data import ', code);
      toastDanger([title, message], {actions});
      return null;
    } finally {
      setIsDeleting(false);
    }
  };
  const fetchDataImport = async () => {
    setIsFetching(true);
    try {
      const dataImport = await dataImportService.fetchImportCsvUsers(importId);

      setDataImport(dataImport);
      setCsvFile(dataImport.file);
      setName(dataImport.name);
      setDescription(dataImport.description);
      setCollapsedSections(
        dataImport?.config?.csvIdentifier == null
          ? [1, 3]
          : dataImport.status === DATA_IMPORT_STATUS_DONE
          ? [1, 2, 3]
          : [1, 2]
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to fetch data import ', code);
      toastDanger([title, message], {actions});
      return null;
    } finally {
      setIsFetching(false);
    }
  };
  const fetchProjectIdentifiers = async () => {
    try {
      const identifiers = await projectService.fetchIdentifyTokens();

      setProjectIdentifiers(identifiers);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to fetch project identifiers ', code);
      toastDanger([title, message], {actions});
      return null;
    } finally {
    }
  };
  const fetchProjectEmails = async () => {
    try {
      const emails = await projectService.fetchProjectEmails();

      setProjectEmails(emails);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to fetch project emails ', code);
      toastDanger([title, message], {actions});
      return null;
    } finally {
    }
  };
  const fetchProjectAttributes = async () => {
    try {
      const attributes = await segmentService.getSegmentCustomAttributes();

      setProjectAttributes(attributes);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to fetch project attributes ', code);
      toastDanger([title, message], {actions});
      return null;
    } finally {
    }
  };
  const updateImportConfig = (updatedProperties) => {
    setDataImport({
      ...dataImport,
      config: {
        ...dataImport.config,
        ...updatedProperties,
      },
    });
  };
  const updateMappedFields = (mappedFields) => {
    setDataImport({
      ...dataImport,
      config: {
        ...dataImport.config,
        properties: mappedFields,
      },
    });
  };
  const performImport = async () => {
    const {properties} = dataImport.config;

    setIsSubmitting(true);
    try {
      const dataImportUpdated = await dataImportService.updateImportUsersCsv(
        importId,
        {
          properties,
        }
      );

      setDataImport({
        ...dataImport,
        ...dataImportUpdated,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(
        'Failed to update csv user data import config for identification ',
        code
      );
      toastDanger([title, message], {actions});
      return null;
    }
    try {
      await dataImportService.launchImportCsvUsers(importId);
      toastInfo([
        `${dataImport.name} has started!`,
        'It might take some time depending on the amount of data. This page will be refreshed automatically every 5 seconds.',
      ]);
      history.push(ROUTE_USERS_IMPORTS);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to perform import of csv user', code);
      toastDanger([title, message], {actions});
      return null;
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (dataImport == null) return;
    if (dataImport.config.enableAddUser === true)
      updateImportConfig({
        jimoIdentifier: IDENTIFY_FIELD_ID,
      });
  }, [dataImport?.config?.enableAddUser]);

  useEffect(() => {
    if (importId != null) {
      fetchDataImport();
    }
  }, [importId]);

  useEffect(() => {
    async function init() {
      await Promise.all([
        fetchProjectIdentifiers(),
        fetchProjectAttributes(),
        fetchProjectEmails(),
      ]);
      setIsInitializing(false);
    }

    init();
  }, []);

  const {config, status} = dataImport ?? {};
  const {
    jimoIdentifier,
    csvIdentifier,
    enableAddUser,
    enableUpdateUser,
    createSegment = false,
    properties = [],
    totalUserAdded,
    totalUserUpdated,
    totalUserErrored,
  } = config ?? {};
  const fileName =
    csvFile != null
      ? csvFile.originalName
      : filePicked != null
      ? filePicked.name
      : '';
  const sectionIdentificationEnabled = dataImport != null;
  const sectionMapFieldsEnabled = dataImport != null && csvIdentifier != null;
  const hasCsvExtension =
    fileName.split('.')[fileName.split('.').length - 1] === 'csv';

  return (
    <div className="s-import-csv">
      <div className="header">
        <div className="header-title title-3">Import from CSV</div>
        <div className="actions-wrapper">
          <Button
            thin
            iconLeft={dataImport != null ? 'icon-arrow-left' : null}
            onClick={() => {
              history.push(ROUTE_USERS_IMPORTS);
            }}>
            {dataImport != null ? 'Go back to imports' : 'Cancel'}
          </Button>
          {dataImport != null && (
            <Button
              iconRight="isax isax-trash"
              thin
              danger
              onClick={() => setShowModalDelete(true)}>
              Delete
            </Button>
          )}
        </div>
      </div>

      {isInitializing === true || isFetching === true ? (
        <div className="loader-wrapper">
          <DefaultLoader width="21px" />
        </div>
      ) : (
        <>
          {/* Alert In Progress */}
          {status === DATA_IMPORT_STATUS_IN_PROGRESS && (
            <Alert
              className="alert-in-progress"
              warning
              title="Your import is still in progress"
              icon="isax isax-refresh">
              It might take some time depending on the amount of users to
              process.
            </Alert>
          )}
          {/* Alert Error */}
          {status === DATA_IMPORT_STATUS_ERROR && (
            <Alert
              className="alert-error"
              danger
              title="An error has occurred"
              icon="isax isax-chart-fail">
              The import of your CSV failed with the following error{' '}
              {dataImport.config.error}. <br /> Please contact our support!
            </Alert>
          )}
          {/* Alert Done */}
          {status === DATA_IMPORT_STATUS_DONE && (
            <Alert
              className="alert-done"
              success
              title="Import done"
              icon="isax isax-chart-success">
              Your import has been completed{' '}
              <strong>{dayjs(dataImport.config.endedAt).fromNow()}</strong>.
              <br />
              <br />
              <strong>{totalUserAdded} users</strong> were added.
              <br />
              <strong>{totalUserUpdated} users</strong> were updated.
              <br />
              <strong>{totalUserErrored} users</strong> couldn't been process.
            </Alert>
          )}
          {/* Section Import CSV file */}
          <section className="section-1">
            <div
              className="section-header"
              onClick={() => triggerCollapseSection(1)}>
              <span className="number subtitle-2">1</span>
              <div className="header-texts-wrapper">
                <div className="header-title subtitle-2">Import CSV file</div>
              </div>
            </div>
            <AnimateHeight
              height={isSectionCollapsed(1) ? 0 : 'auto'}
              duration={500}>
              <div className="section-body">
                <input
                  style={{display: 'none'}}
                  ref={refInputFile}
                  type="file"
                  name=""
                  id=""
                  onChange={({target}) => {
                    setFilePicked(target.files[0] ?? null);
                  }}
                />
                {csvFile != null || filePicked != null ? (
                  <div className="input-csv-wrapper">
                    <Input
                      iconLeft="isax isax-document-upload"
                      disabled={hasCsvExtension === true}
                      readOnly
                      value={fileName}
                      danger={hasCsvExtension === false}
                      helper={
                        hasCsvExtension === false
                          ? 'Only CSV file are allowed'
                          : ''
                      }
                    />
                    {dataImport == null && (
                      <div className="actions-wrapper">
                        <Button
                          iconLeft="isax isax-document-upload"
                          thin
                          onClick={() => {
                            refInputFile.current.click();
                          }}>
                          Replace
                        </Button>
                        <Button
                          iconOnly
                          danger
                          thin
                          onClick={() => setFilePicked(null)}>
                          <i className="isax isax-trash"></i>
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <Button
                    iconLeft="isax isax-document-upload"
                    className="btn-choose-csv"
                    onClick={() => refInputFile.current.click()}>
                    Choose CSV file
                  </Button>
                )}
                <Input
                  legend="Import name"
                  legendClassName="subtitle-3"
                  placeholder="Paying users"
                  value={name}
                  onChange={({target}) => setName(target.value)}></Input>
                <TextArea
                  legend="Description (optional)"
                  legendClassName="subtitle-3"
                  placeholder="Import paying users to Jimo"
                  value={description}
                  onChange={({target}) =>
                    setDescription(target.value)
                  }></TextArea>
                <Button
                  className="btn-next"
                  iconRight={dataImport == null ? 'icon-arrow-right' : null}
                  disabled={
                    name.length === 0 ||
                    (dataImport == null &&
                      (filePicked == null ||
                        (filePicked != null && hasCsvExtension === false)))
                  }
                  loading={isSubmitting}
                  primary
                  onClick={() => {
                    if (dataImport == null) {
                      submitCsvFile();
                    } else {
                      updateDataImport();
                    }
                  }}>
                  {dataImport == null ? 'Next' : 'Save'}
                </Button>
              </div>
            </AnimateHeight>
          </section>

          {/* Section Identification */}
          <section
            className={classnames('section-2', {
              'is-disabled': sectionIdentificationEnabled === false,
            })}>
            <div
              className="section-header"
              onClick={() => triggerCollapseSection(2)}>
              <span className="number subtitle-2">2</span>
              <div className="header-texts-wrapper">
                <div className="header-title subtitle-2">Identification</div>
                <div className="header-subtitle">
                  Fields that will be used to match users between your CSV file
                  and Jimo.
                </div>
              </div>
            </div>
            <AnimateHeight
              height={
                sectionIdentificationEnabled === false
                  ? 0
                  : isSectionCollapsed(2)
                  ? 0
                  : 'auto'
              }
              duration={500}>
              <div className="section-body">
                <div className="toggle-wrapper">
                  <div className="label-wrapper">
                    <div className="subtitle-3">Add new users</div>
                    <div className="body-3">
                      If a user from the CSV can't be matched, it will be
                      created.
                    </div>
                  </div>
                  <Toggle
                    disabled={status !== DATA_IMPORT_STATUS_DRAFT}
                    checked={enableAddUser}
                    onChange={() =>
                      updateImportConfig({
                        enableAddUser: !enableAddUser,
                      })
                    }
                  />
                </div>
                <div className="toggle-wrapper">
                  <div className="label-wrapper">
                    <div className="subtitle-3">Update existing users</div>
                    <div className="body-3">
                      If a user from the CSV is matched, it will be updated.
                    </div>
                  </div>
                  <Toggle
                    disabled={status !== DATA_IMPORT_STATUS_DRAFT}
                    checked={enableUpdateUser}
                    onChange={() =>
                      updateImportConfig({
                        enableUpdateUser: !enableUpdateUser,
                      })
                    }
                  />
                </div>
                <div className="toggle-wrapper">
                  <div className="label-wrapper">
                    <div className="subtitle-3">Create a segment</div>
                    <div className="body-3">
                      Create a segment with all user identified in your CSV
                    </div>
                  </div>
                  <Toggle
                    disabled={status !== DATA_IMPORT_STATUS_DRAFT}
                    checked={createSegment}
                    onChange={() =>
                      updateImportConfig({
                        createSegment: !createSegment,
                      })
                    }
                  />
                </div>
                {enableAddUser === true && projectIdentifiers.length > 0 && (
                  <Alert
                    info
                    icon="isax isax-info-circle5"
                    title="You are already identifying users with Jimo in your app.">
                    Make sure to select the same type of ID used to identify
                    users in your app. <br />
                    You can find bellow a list of existing identifiers used in
                    your app.
                  </Alert>
                )}
                <div className="identify-fields">
                  <IdentifyField
                    isJimo
                    isDisabled={
                      enableAddUser === true ||
                      status !== DATA_IMPORT_STATUS_DRAFT
                    }
                    options={[
                      {
                        label: 'Id used in your app (identify)',
                        value: IDENTIFY_FIELD_ID,
                      },
                      {label: 'Email', value: IDENTIFY_FIELD_EMAIL},
                    ]}
                    previewItems={
                      jimoIdentifier === IDENTIFY_FIELD_ID
                        ? projectIdentifiers
                        : projectEmails
                    }
                    value={jimoIdentifier}
                    onChange={(value) =>
                      updateImportConfig({jimoIdentifier: value.value})
                    }
                  />
                  <i className="icon-forward"></i>
                  <IdentifyField
                    isDisabled={status !== DATA_IMPORT_STATUS_DRAFT}
                    placeholder="Identifier column in your csv file"
                    options={properties.map((p) => ({
                      value: p.id,
                      label: p.name,
                    }))}
                    previewItems={
                      csvIdentifier != null
                        ? properties.find((p) => p.id === csvIdentifier)
                            ?.values ?? []
                        : []
                    }
                    value={csvIdentifier}
                    onChange={(value) => {
                      updateImportConfig({csvIdentifier: value.value});
                    }}
                  />
                </div>
                {status === DATA_IMPORT_STATUS_DRAFT && (
                  <Button
                    className="btn-next"
                    iconRight="icon-arrow-right"
                    disabled={
                      (enableAddUser === false && enableUpdateUser === false) ||
                      csvIdentifier == null
                    }
                    onClick={updateDataImportIdentification}
                    loading={isSubmitting}
                    primary>
                    Next
                  </Button>
                )}
              </div>
            </AnimateHeight>
          </section>

          {/* Section Map Fields */}
          <section
            className={classnames('section-3', {
              'is-disabled': sectionMapFieldsEnabled === false,
            })}>
            <div
              className="section-header"
              onClick={() => triggerCollapseSection(3)}>
              <span className="number subtitle-2">3</span>
              <div className="header-texts-wrapper">
                <div className="header-title subtitle-2">Map fields</div>
                <div className="header-subtitle">
                  Fields to include while creating or updating users
                </div>
              </div>
            </div>
            <AnimateHeight
              height={
                sectionMapFieldsEnabled === false
                  ? 0
                  : isSectionCollapsed(3)
                  ? 0
                  : 'auto'
              }
              duration={500}>
              <div className="section-body">
                <div className="fields-wrapper">
                  {properties.map((mappedField) => {
                    return (
                      <MapField
                        isMapped={mappedField.isMapped}
                        name={mappedField.name}
                        rowsFound={mappedField.rowsFound}
                        isDisabled={
                          mappedField.id === csvIdentifier ||
                          status !== DATA_IMPORT_STATUS_DRAFT
                        }
                        options={
                          mappedField.id === csvIdentifier
                            ? [
                                {
                                  label: 'Field use for Identification',
                                  value: null,
                                },
                              ]
                            : [
                                {
                                  label: <>Add as a new attribute</>,
                                  value: null,
                                },
                                ...(jimoIdentifier !== IDENTIFY_FIELD_ID
                                  ? [
                                      {
                                        label: 'Id',
                                        value: IDENTIFY_FIELD_ID,
                                      },
                                    ]
                                  : []),
                                ...(jimoIdentifier !== IDENTIFY_FIELD_EMAIL
                                  ? [
                                      {
                                        label: 'Email',
                                        value: IDENTIFY_FIELD_EMAIL,
                                      },
                                    ]
                                  : []),
                                {
                                  label: 'Username',
                                  value: OTHER_FIELD_USERNAME,
                                },
                                ...projectAttributes.map((attribute) => ({
                                  label: (
                                    <>
                                      {attribute.name}
                                      <span className="label-custom-attribute body-4">
                                        custom attribute
                                      </span>
                                    </>
                                  ),
                                  value: attribute.uid,
                                })),
                              ].filter((option) =>
                                option.value == null
                                  ? true
                                  : mappedField.attributeId === option.value
                                  ? true
                                  : properties.find(
                                      (property) =>
                                        property.attributeId === option.value
                                    ) == null
                              )
                        }
                        value={
                          mappedField.id === csvIdentifier
                            ? null
                            : mappedField.attributeId
                        }
                        onSelect={(isMapped) =>
                          updateMappedFields(
                            properties.map((mf) =>
                              mf.id === mappedField.id ? {...mf, isMapped} : mf
                            )
                          )
                        }
                        previewItems={mappedField.values}
                        onChangeMapping={(value) => {
                          updateMappedFields(
                            properties.map((mf) =>
                              mf.id === mappedField.id
                                ? {...mf, attributeId: value.value}
                                : mf
                            )
                          );
                        }}
                      />
                    );
                  })}
                </div>
                {status === DATA_IMPORT_STATUS_DRAFT && (
                  <Button
                    className="btn-next"
                    primary
                    onClick={performImport}
                    loading={isSubmitting}>
                    Import
                  </Button>
                )}
              </div>
            </AnimateHeight>
          </section>
        </>
      )}

      <ModalConfirmV2
        isOpen={showModalDelete}
        title="Delete import"
        closable={false}
        onRequestClose={() => setShowModalDelete(false)}
        onConfirm={deleteDataImport}
        onCancel={() => setShowModalDelete(false)}
        isConfirming={isDeleting}
        cancelText="Close"
        confirmText="Delete"
        cancelBtnProps={{
          cta: false,
        }}
        confirmBtnProps={{
          danger: true,
          primary: true,
          cta: false,
        }}>
        <div className="content n-700">
          If you confirm, the CSV file associated to this import and the import
          itself will be deleted.
        </div>
      </ModalConfirmV2>
    </div>
  );
};
