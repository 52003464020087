import Button from 'components/Button';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ROUTE_SPACES} from 'router/routes.const';
import ModalCreatePoke from 'scenes/Pushes/components/ModalCreatePoke';
import ModalAddExperience from '../ModalAddExperience';
import './_Styles.scss';
import DropdownSpaceActions from './components/DropdownSpaceActions';

const SpaceHeader = ({
  space,
  refetchAll = () => {},
  refetchSpace = () => {},
}) => {
  const history = useHistory();

  const [showModalAddExperience, setShowModalAddExperience] = useState(false);
  const [showModalCreateExperience, setShowModalCreateExperience] =
    useState(false);

  return (
    <div className="space-header">
      <div className="infos">
        <Button
          className="back-btn"
          iconOnly
          iconLeft="icon-chevron-left"
          onClick={async () => {
            history.push(ROUTE_SPACES);
          }}
        />
        <div className="icon-wrapper" style={{backgroundColor: space?.color}}>
          {space?.icon}
        </div>
        <div className="space-title-wrapper">
          <div className="space-title subtitle-3 n-800">{space?.name}</div>
          <div className="space-description body-3 n-500">
            {space?.description}
          </div>
        </div>
      </div>
      <div className="space-actions">
        <DropdownSpaceActions space={space} refetchSpace={refetchSpace} />
        <Button
          thin
          iconLeft="icon-plus"
          onClick={() => {
            setShowModalCreateExperience(true);
          }}>
          Create new
        </Button>
        <Button
          primary
          thin
          iconLeft="icon-plus"
          onClick={() => {
            setShowModalAddExperience(true);
          }}>
          Add experience
        </Button>
      </div>

      {showModalAddExperience && (
        <ModalAddExperience
          isOpen={showModalAddExperience}
          onRequestClose={() => {
            setShowModalAddExperience(false);
            refetchAll();
          }}
          space={space}
        />
      )}
      {showModalCreateExperience && (
        <ModalCreatePoke
          isOpen={showModalCreateExperience}
          onRequestClose={() => setShowModalCreateExperience(false)}
          spaceId={space?.uid}
        />
      )}
    </div>
  );
};

export default SpaceHeader;
