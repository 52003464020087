import Axios from 'axios';
import {Environment} from 'conf/env';

export const SALESFORCE_OBJECT_TYPE_CONTACT = 'CONTACT';
export const SALESFORCE_OBJECT_TYPE_LEAD = 'LEAD';
export const SALESFORCE_OBJECT_TYPE_ACCOUNT = 'ACCOUNT';
export const SALESFORCE_OBJECT_TYPE_OPPORTUNITY = 'OPPORTUNITY';

// Endpoint
const EP_SALESFORCE_GET_GENERATE_URL = '/salesforce/generate-url';
const EP_SALESFORCE_POST_EXCHANGE_CODE = '/salesforce/exchange-code';
const EP_SALESFORCE_GET = (salesforceId) => `/salesforce/${salesforceId}`;
const EP_SALESFORCE_UPDATE = (salesforceId) => `/salesforce/${salesforceId}`;
const EP_SALESFORCE_REVOKE_TOKEN = (salesforceId) =>
  `/salesforce/revoke/${salesforceId}`;
const EP_SALESFORCE_FULL_SYNC = `/salesforce/sync`;

const EP_SALESFORCE_PROPERTIES_GET = (salesforceId) =>
  `/salesforce/api/${salesforceId}/properties`;

const salesforceBaseUrl = Environment.SALESFORCE_KARABOR_BASE_URL;

// Method
export const generateUrl = (projectId) => {
  return Axios.post(
    `${salesforceBaseUrl}${EP_SALESFORCE_GET_GENERATE_URL}`,
    null,
    {
      params: {projectId},
    }
  ).then((response) => response.data);
};

export const exchangeCodeForToken = (projectId, {code, state}) => {
  return Axios.post(
    `${salesforceBaseUrl}${EP_SALESFORCE_POST_EXCHANGE_CODE}`,
    {
      code,
      state,
    },
    {params: {projectId}}
  ).then((response) => response.data);
};

export const getSalesforce = (salesforceId) =>
  Axios.get(`${salesforceBaseUrl}${EP_SALESFORCE_GET(salesforceId)}`).then(
    (response) => response.data
  );

export const updateSalesforce = (salesforceId, data) =>
  Axios.put(
    `${salesforceBaseUrl}${EP_SALESFORCE_UPDATE(salesforceId)}`,
    data
  ).then((response) => response.data);

export const revokeToken = (salesforceId) =>
  Axios.post(
    `${salesforceBaseUrl}${EP_SALESFORCE_REVOKE_TOKEN(salesforceId)}`
  ).then((response) => response.data);

export const runFullSync = (salesforceId) => {
  return Axios.post(`${salesforceBaseUrl}${EP_SALESFORCE_FULL_SYNC}`, {
    salesforceId,
  }).then((response) => response.data);
};
// API endpoint
export const getProperties = (salesforceId) => {
  return Axios.get(
    `${salesforceBaseUrl}${EP_SALESFORCE_PROPERTIES_GET(salesforceId)}`
  ).then((response) => response.data);
};
