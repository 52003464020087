import classNames from 'classnames';
import Button from 'components/Button';
import {VOTE_CONGRATS} from 'constants';
import dayjs from 'dayjs';
import {buildLinkToEvolution, hasUsernameGenerated} from 'helpers/utils';
import {CircularProgressbar} from 'react-circular-progressbar';
import {useHistory} from 'react-router-dom';
import './_Styles.scss';

export const CONTEXT_PORTAL_STANDALONE = 'PORTAL_STANDALONE';
export const CONTEXT_PORTAL_WIDGET = 'PORTAL_WIDGET';

export const EVENT_TYPE_EVOLUTION_SEEN = 'EVOLUTION_SEEN';

const TimelineItem = ({
  jimer,
  item,
  items = [],
  isSubEvent = false,
  setSurveyView = () => {},
}) => {
  const history = useHistory();

  const username =
    hasUsernameGenerated(jimer.username) && jimer.externalUsername != null
      ? jimer.externalUsername
      : jimer.username;

  let icon, actions, extra, title, subtitle, time;

  time = dayjs(item.createdAt).format('HH:mm');

  if (item.type === 'PORTAL_WIDGET') {
    icon = (
      <div className={classNames('icon-wrapper changelog')}>
        <i className="isax isax-slider-vertical-1" />
      </div>
    );

    title = (
      <span>
        Viewed {item.subEvents.length > 1 ? `${item.subEvents.length} ` : ''}
        Changelog post{item.subEvents.length > 1 ? 's' : ''}
      </span>
    );

    subtitle = (
      <span
        title={item.subEvents
          .map((subEvent) => subEvent.evolution.title)
          .join(', ')}>
        {item.subEvents.map((subEvent) => subEvent.evolution.title).join(', ')}
      </span>
    );
  } else if (item.type === 'TOUR') {
    const tour = item.subEvents.find(
      (subEvent) => subEvent.type === 'TOUR_STARTED'
    );

    if (tour != null) {
      const tourCompleted = items.find(
        (item) =>
          item.subEvents != null &&
          item.subEvents.some((s) => s.type === 'TOUR_COMPLETED')
      );

      const seenTourStepsCount = items
        .filter((item) => item.subEvents != null)
        .reduce((uniqueStepIds, item) => {
          // Find all TOUR_STEP_SEEN events with this evolution ID
          const validStepEvents = item.subEvents.filter(
            (s) =>
              s.type === 'TOUR_STEP_SEEN' &&
              s.evolution.uid === tour.evolution.uid &&
              s.stepId && // Ensure stepId exists
              // Check if within 30 minutes of tour start
              dayjs(s.createdAt).diff(dayjs(tour.createdAt), 'minute') <= 30 &&
              dayjs(s.createdAt).diff(dayjs(tour.createdAt), 'minute') >= 0
          );

          // Add unique stepIds to the set
          validStepEvents.forEach((step) => {
            uniqueStepIds.add(step.stepId);
          });

          return uniqueStepIds;
        }, new Set()).size;

      if (tourCompleted != null) {
        icon = (
          <div className={classNames('icon-wrapper adoption')}>
            <i className="isax isax-routing-25" />
          </div>
        );

        title = <span>Completed all tour steps</span>;
        subtitle = tour.evolution.title;

        actions = (
          <div className="tour-progress-wrapper">
            <div className="circular-progress-wrapper">
              <CircularProgressbar
                value={1}
                maxValue={1}
                strokeWidth={6}
                styles={{
                  path: {
                    // Path color
                    stroke: `rgba(3, 165, 39)`,
                    // Customize transition animation
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                  },
                  // Customize the circle behind the path, i.e. the "total progress"
                  trail: {
                    // Trail color
                    stroke: 'rgba(33, 93, 137, 0.06)',
                  },
                  text: {
                    // Text color
                    fill: '#03a527',
                    // Text size
                    fontSize: '26px',
                    dominantBaseline: 'middle',
                    textAnchor: 'middle',
                  },
                }}
                text=" "
              />
              <div className="icon-tick-overlay">
                <i className="icon-tick" />
              </div>
            </div>
          </div>
        );
      } else if (tour.evolution.tourSteps?.length > 1) {
        icon = (
          <div className={classNames('icon-wrapper adoption')}>
            <i className="isax isax-routing-25" />
          </div>
        );

        title = (
          <span>
            completed {seenTourStepsCount} out of{' '}
            {tour.evolution.tourSteps.length} steps of the tour
          </span>
        );

        subtitle = (
          <span
            className="evolution-title"
            onClick={() => {
              const url = buildLinkToEvolution(tour.evolution);
              history.push(url);
            }}>
            {tour.evolution?.title}
          </span>
        );

        actions = (
          <div className="tour-progress-wrapper">
            <div className="circular-progress-wrapper">
              <CircularProgressbar
                value={seenTourStepsCount}
                maxValue={tour.evolution.tourSteps.length}
                strokeWidth={6}
                styles={{
                  path: {
                    // Path color
                    stroke: `rgba(3, 165, 39)`,
                    // Customize transition animation
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                  },
                  // Customize the circle behind the path, i.e. the "total progress"
                  trail: {
                    // Trail color
                    stroke: 'rgba(33, 93, 137, 0.06)',
                  },
                  text: {
                    // Text color
                    fill: '#03a527',
                    // Text size
                    fontSize: '26px',
                    dominantBaseline: 'middle',
                    textAnchor: 'middle',
                  },
                }}
                text={`${seenTourStepsCount}/${tour.evolution.tourSteps.length}`}
              />
            </div>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  } else if (item.activityType === 'comment') {
    icon = icon || (
      <div className={classNames('icon-wrapper changelog')}>
        <i className="icon-comment-o" />
      </div>
    );

    title = (
      <span>
        <span className="username">{username}</span> commented on
      </span>
    );

    subtitle = (
      <span
        className="evolution-title"
        onClick={() => {
          const url = buildLinkToEvolution(item.evolution);
          history.push(url);
        }}>
        {item.evolution?.title}
      </span>
    );

    extra = (
      <div
        className="comment"
        onClick={() => {
          const url = buildLinkToEvolution(item.evolution, {
            toComments: true,
          });
          history.push(url);
        }}>
        "{item.message}"
      </div>
    );
  } else if (item.activityType === 'vote') {
    if (item.value === VOTE_CONGRATS) {
      icon = icon || (
        <div className={classNames('icon-wrapper changelog')}>
          <i className="icon-thumbs-up" />
        </div>
      );

      title = (
        <span>
          <span className="username">{username}</span> liked
        </span>
      );

      subtitle = (
        <span
          className="evolution-title"
          onClick={() => {
            const url = buildLinkToEvolution(item.evolution);
            history.push(url);
          }}>
          {item.evolution?.title}
        </span>
      );
    }
  } else if (item.type === 'CREATE_JIMER') {
    icon = icon || (
      <div className={classNames('icon-wrapper user')}>
        <i className="isax isax-profile-circle" />
      </div>
    );

    title = (
      <span>
        <span className="username">{username}</span> was created!
      </span>
    );
    subtitle = '🎉';
  } else if (item.type === 'SURVEY_STARTED') {
    icon = (
      <div className={classNames('icon-wrapper survey')}>
        <i className="isax isax-note-25" />
      </div>
    );

    title = <span>Started a survey</span>;
    subtitle = item.evolution.title;

    if (item.evolution.surveys?.length > 0) {
      actions = (
        <Button
          cta
          thin
          className="show-surveys"
          onClick={() => setSurveyView(item.evolution.surveys[0].uid)}>
          View responses
        </Button>
      );
    }
  } else if (item.type === 'BANNER_SEEN') {
    icon = (
      <div className={classNames('icon-wrapper adoption')}>
        <i className="icon-banner-default" />
      </div>
    );

    title = <span>Viewed a banner</span>;
    subtitle = item.evolution.title;
  } else if (item.type === 'CHECKLIST_STARTED') {
    icon = (
      <div className={classNames('icon-wrapper adoption')}>
        <i className="isax isax-task-square5" />
      </div>
    );

    title = <span>Started a checklist</span>;
    subtitle = item.evolution.title;
  } else if (item.type === 'CHECKLIST_COMPLETED') {
    icon = (
      <div className={classNames('icon-wrapper adoption')}>
        <i className="isax isax-task-square5" />
      </div>
    );

    title = <span>Completed a checklist</span>;
    subtitle = item.evolution.title;
  } else if (item.type === 'CHECKLIST_TASK_COMPLETED') {
    icon = (
      <div className={classNames('icon-wrapper adoption')}>
        <i className="isax isax-task-square5" />
      </div>
    );

    const taskName = item.block?.value?.split('|-|')[0];
    title = <span>Completed a checklist task</span>;
    subtitle = (
      <>
        {item.evolution.title} &bull; {taskName}
      </>
    );
  } else if (item.type === 'RESOURCE_CENTER_OPENED') {
    icon = (
      <div className={classNames('icon-wrapper adoption')}>
        <i className="isax isax-directbox-notif5" />
      </div>
    );

    title = <span>Opened the resource center</span>;
    subtitle = item.evolution.title;
  } else if (item.type === 'RESOURCE_CENTER_ACTION_CLICKED') {
    icon = (
      <div className={classNames('icon-wrapper adoption')}>
        <i className="isax isax-directbox-notif5" />
      </div>
    );

    title = <span>Clicked a resource center action</span>;
    subtitle = item.evolution.title;
  } else if (item.type === 'HINT_SEEN') {
    icon = (
      <div className={classNames('icon-wrapper adoption')}>
        <i className="isax isax-notification-15" />
      </div>
    );

    title = <span>Viewed a hint</span>;
    subtitle = item.evolution.title;
  } else {
    return <></>;
  }

  return (
    <div className="timeline-item-wrapper">
      <div className={classNames('timeline-item')}>
        <div className="item-infos">
          {icon}
          <div className="item-content-wrapper">
            <div className="item-title body-4 n-700">{title}</div>
            <div className="item-subtitle subtitle-4 n-800">{subtitle}</div>
          </div>
          {isSubEvent !== true && (
            <div className="actions-wrapper">{actions}</div>
          )}
          {time != null && <div className="time body-4 n-700">{time}</div>}
        </div>
        {extra != null && <div className="extra">{extra}</div>}
      </div>
    </div>
  );
};

export default TimelineItem;
