import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useEffect} from 'react';
import {
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_RESOURCE_CENTER,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_NAVIGATION,
  F_BOOST_SLOT_TOOLTIP,
} from 'services/evolution';
import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHECKLIST_DISMISS,
  BLOCK_TYPE_CHECKLIST_HEADER,
  BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
  BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_LIST,
  BLOCK_TYPE_CHECKLIST_TRIGGER,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CONCEPT,
  BLOCK_TYPE_CURSOR,
  BLOCK_TYPE_DISMISS_CROSS,
  BLOCK_TYPE_HINT,
  BLOCK_TYPE_HOTSPOT,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_MEDIA,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_PARAGRAPH,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_MINIMIZE,
  BLOCK_TYPE_RESOURCE_CENTER_SUBTITLE,
  BLOCK_TYPE_RESOURCE_CENTER_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TARGET,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  BLOCK_TYPE_VISUAL_CUE,
  STEP_TYPE_CHECKLIST,
} from 'services/steps';
import './_Styles.scss';
import Animation from './blocks/Animation';
import Button from './blocks/Button';
import ChecklistSettings from './blocks/Checklist';
import ChecklistDismiss from './blocks/ChecklistDismiss';
import ChecklistHeader from './blocks/ChecklistHeader';
import ChecklistItemButton from './blocks/ChecklistItemButton';
import ChecklistItemCheckbox from './blocks/ChecklistItemCheckbox';
import ChecklistList from './blocks/ChecklistList';
import ChecklistListItem from './blocks/ChecklistListItem';
import ChecklistProgress from './blocks/ChecklistProgress';
import ChecklistTrigger from './blocks/ChecklistTrigger';
import Concept from './blocks/Concept';
import Cursor from './blocks/Cursor';
import DismissCross from './blocks/DismissCross';
import Group from './blocks/Group';
import Hint, {HINT_TYPE_BUTTON} from './blocks/Hint';
import Hotspot from './blocks/Hotspot';
import Media from './blocks/Media';
import MultipleChoice from './blocks/MultipleChoice';
import Navigation from './blocks/Navigation';
import Nps from './blocks/Nps';
import OpenQuestion from './blocks/OpenQuestion';
import OpinionScale from './blocks/OpinionScale';
import Paragraph from './blocks/Paragraph';
import PinnedElement from './blocks/PinnedElement';
import Profile from './blocks/Profile';
import ResourceCenter from './blocks/ResourceCenter';
import ResourceCenterAction from './blocks/ResourceCenterAction';
import ResourceCenterActionCta from './blocks/ResourceCenterActionCta';
import ResourceCenterActionIcon from './blocks/ResourceCenterActionIcon';
import ResourceCenterActionMedia from './blocks/ResourceCenterActionMedia';
import ResourceCenterEmbeddedChecklist from './blocks/ResourceCenterEmbeddedChecklist';
import ResourceCenterGroup from './blocks/ResourceCenterGroup';
import ResourceCenterMinimize from './blocks/ResourceCenterMinimize';
import ResourceCenterTrigger from './blocks/ResourceCenterTrigger';
import ResourceCenterTriggerArrow from './blocks/ResourceCenterTriggerArrow';
import ResourceCenterTriggerChecklistProgress from './blocks/ResourceCenterTriggerChecklistProgress';
import ResourceCenterTriggerIcon from './blocks/ResourceCenterTriggerIcon';
import ResourceCenterTriggerText from './blocks/ResourceCenterTriggerText';
import Slider from './blocks/Slider';
import Stepper from './blocks/Stepper';
import Tag from './blocks/Tag';
import Title from './blocks/Title';
import VisualCue from './blocks/VisualCue';

const propTypes = {};
const defaultProps = {};

const BlockEditor = () => {
  const {
    selectedStepId,
    selectedStep,
    selectedBlockType,
    selectedSubItemId,
    controlledEvolution,
    setSelectedBlockType,
  } = useContext(BuilderContext);

  const isNavigationStep = hasFlag(
    F_BOOST_SLOT_NAVIGATION,
    controlledEvolution?.boostFlags
  );

  const isResourceCenter =
    controlledEvolution?.type === EVOLUTION_TYPE_RESOURCE_CENTER;
  const isChecklist = controlledEvolution?.type === EVOLUTION_TYPE_CHECKLIST;
  const isChecklistStep = selectedStep?.type === STEP_TYPE_CHECKLIST;

  const isHotspot = controlledEvolution?.boostFlags === F_BOOST_SLOT_DOT;
  const isTooltip = controlledEvolution?.boostFlags === F_BOOST_SLOT_TOOLTIP;

  useEffect(() => {
    if (selectedStep == null) {
      return;
    }

    const {blocks = []} = selectedStep;
    const selectedBlock = blocks.find(
      (block) => block.type === selectedBlockType
    );

    const haveHotspotBlock =
      isHotspot && selectedBlockType === BLOCK_TYPE_HOTSPOT;

    const havePinnedElementBlock =
      (isHotspot || isTooltip) && selectedBlockType === BLOCK_TYPE_TARGET;

    const hintBlock = blocks.find((block) => block.type === BLOCK_TYPE_HINT);

    if (
      selectedBlock == null &&
      haveHotspotBlock !== true &&
      havePinnedElementBlock !== true
    ) {
      if (hintBlock?.style?.type === HINT_TYPE_BUTTON) {
        setSelectedBlockType(BLOCK_TYPE_HINT);
      } else {
        setSelectedBlockType(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStep?.uid]);

  if ((selectedStepId == null || selectedStep == null) && !isNavigationStep) {
    return <></>;
  }

  return (
    <div
      className="poke-block-editor-wrapper"
      key={[selectedStepId, selectedSubItemId]}>
      {selectedBlockType == null &&
        (isResourceCenter ? (
          <ResourceCenter />
        ) : isChecklistStep ? (
          <ChecklistSettings />
        ) : !isNavigationStep ? (
          <Group updateStepStyle={isChecklist === true} />
        ) : (
          <></>
        ))}
      {[
        BLOCK_TYPE_TITLE,
        BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
      ].includes(selectedBlockType) && <Title />}
      {[
        BLOCK_TYPE_BODY,
        BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
      ].includes(selectedBlockType) && <Paragraph />}
      {[
        BLOCK_TYPE_PRIMARY_CTA,
        BLOCK_TYPE_SECONDARY_CTA,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
      ].includes(selectedBlockType) &&
        (isChecklistStep ? <ChecklistItemButton /> : <Button />)}
      {selectedBlockType === BLOCK_TYPE_LABEL && <Tag />}
      {[BLOCK_TYPE_MEDIA, BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA].includes(
        selectedBlockType
      ) && <Media />}
      {selectedBlockType === BLOCK_TYPE_STEPPER && <Stepper />}
      {selectedBlockType === BLOCK_TYPE_USER && <Profile />}
      {selectedBlockType === BLOCK_TYPE_CHOICE && <MultipleChoice />}
      {selectedBlockType === BLOCK_TYPE_SLIDER && <Slider />}
      {selectedBlockType === BLOCK_TYPE_NPS && <Nps />}
      {selectedBlockType === BLOCK_TYPE_OPINION && <OpinionScale />}
      {selectedBlockType === BLOCK_TYPE_ANIMATION && <Animation />}
      {selectedBlockType === BLOCK_TYPE_OPEN_QUESTION && <OpenQuestion />}
      {selectedBlockType === BLOCK_TYPE_CONCEPT && <Concept />}
      {selectedBlockType === BLOCK_TYPE_HOTSPOT && <Hotspot />}
      {selectedBlockType === BLOCK_TYPE_HINT && <Hint />}
      {isNavigationStep && <Navigation />}
      {selectedBlockType === BLOCK_TYPE_TARGET && <PinnedElement />}
      {selectedBlockType === BLOCK_TYPE_DISMISS_CROSS && <DismissCross />}
      {selectedBlockType === BLOCK_TYPE_CURSOR && <Cursor />}
      {selectedBlockType === BLOCK_TYPE_VISUAL_CUE && <VisualCue />}
      {/* Checklist */}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_HEADER && <ChecklistHeader />}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_TASK_LIST && (
        <ChecklistList />
      )}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_TASK_ITEM && (
        <ChecklistListItem />
      )}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX && (
        <ChecklistItemCheckbox />
      )}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_TRIGGER && (
        <ChecklistTrigger />
      )}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS && (
        <ChecklistProgress />
      )}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_DISMISS && (
        <ChecklistDismiss />
      )}
      {/* Resource Center */}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_MINIMIZE && (
        <ResourceCenterMinimize />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_TITLE && <Title />}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_SUBTITLE && <Title />}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_ACTION && (
        <ResourceCenterAction />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_GROUP && (
        <ResourceCenterGroup />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE && (
        <Title />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_ACTION_PARAGRAPH && (
        <Title />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_ACTION_MEDIA && (
        <ResourceCenterActionMedia />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON && (
        <ResourceCenterActionIcon />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA && (
        <ResourceCenterActionCta />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE && (
        <Title />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_TRIGGER && (
        <ResourceCenterTrigger />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON && (
        <ResourceCenterTriggerIcon />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT && (
        <ResourceCenterTriggerText />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW && (
        <ResourceCenterTriggerArrow />
      )}
      {selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST && (
        <ResourceCenterEmbeddedChecklist />
      )}
      {selectedBlockType ===
        BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS && (
        <ResourceCenterTriggerChecklistProgress />
      )}
    </div>
  );
};

BlockEditor.propTypes = propTypes;
BlockEditor.defaultProps = defaultProps;

export default BlockEditor;
