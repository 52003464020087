import Button from 'components/Button';
import InputGroup from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {evolutionService, spaceService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ModalAddExperience');

const ModalAddExperienceToSpace = ({
  isOpen,
  onRequestClose = () => {},
  experience,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState(experience.space);
  const [search, setSearch] = useState('');

  const {isLoading, data: spaces} = useQuery({
    queryKey: 'spaces',
    queryFn: () => spaceService.getSpaces(),
  });

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      await evolutionService.updateEvolutionSpace(
        experience.uid,
        selectedSpace
      );
      toastSuccess('Experience added to space');
      onRequestClose();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to add experiences to space with error', code);
      return toastDanger([title, message], {actions});
    } finally {
      setIsSubmitting(false);
    }
  };

  const filteredSpaces = spaces?.filter((space) =>
    space.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onClick={(e) => e.stopPropagation()}
      className="modal-add-experience-to-space"
      title={`Add experience to a space`}
      footer={
        <>
          <Button thin onClick={onRequestClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            primary
            thin
            onClick={handleConfirm}
            loading={isSubmitting}
            disabled={
              selectedSpace == null ||
              selectedSpace?.uid === experience.space?.uid
            }>
            Save
          </Button>
        </>
      }>
      <div className="list-spaces-wrapper">
        <div className="search-wrapper">
          <InputGroup
            labelTextLeft={<i className="icon-search"></i>}
            placeholder="Search for a space"
            muted
            value={search}
            onChange={({target}) => setSearch(target.value)}
          />
        </div>
        <div className="spaces-wrapper">
          {filteredSpaces?.map((space) => {
            const {icon, color, name} = space;

            return (
              <div
                className="space-card"
                key={space.uid}
                onClick={() => setSelectedSpace(space)}>
                <div className="icon-wrapper" style={{backgroundColor: color}}>
                  {icon}
                </div>
                <div className="space-card-content">
                  <div className="space-card-name subtitle-4 n-800">{name}</div>
                </div>
                <div className="checkbox-wrapper">
                  {space.uid === selectedSpace?.uid ? (
                    <i className="icon-checkbox" />
                  ) : (
                    <i className="icon-checkbox-o" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddExperienceToSpace;
