import classNames from 'classnames';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import InputGroup from 'components/Input';
import {object} from 'prop-types';
import React, {useState} from 'react';
import {HubspotIcon} from 'scenes/Settings/scenes/Integrations/Hubspot/components/HubspotSettings/components/SettingsSetup';
import {SalesforceLogo} from 'scenes/Settings/scenes/Integrations/Salesforce';
import SegmentioIcon from '../../../../../../components/SegmentAttributesEditor/icons/segmentio-icon-dark.svg';
import {JimerSection} from '../Segments';
import './_Styles.scss';
import {flattenAttributes} from './utils';

const getAttributeTypeIcon = (type) => {
  if (type === 'jimo') {
    return <i className="icon-jimo" />;
  } else if (type === 'segmentio') {
    return (
      <img
        className="icon-segmentio"
        src={SegmentioIcon}
        alt="segmentio-icon"
      />
    );
  } else if (type === 'hubspot') {
    return <HubspotIcon />;
  } else if (type === 'salesforce') {
    return <SalesforceLogo />;
  }
  return null;
};

const propTypes = {
  jimer: object,
};

const Attributes = ({jimer = {}}) => {
  const {attributes = [], segments = []} = jimer;

  const [search, setSearch] = useState('');

  const matchesSearch = React.useCallback(
    (text) => {
      if (!search) return true;
      return text.toLowerCase().includes(search.toLowerCase());
    },
    [search]
  );

  const flattenedAttributes = React.useMemo(
    () => flattenAttributes(attributes),
    [attributes]
  );

  const filteredAttributes = React.useMemo(() => {
    return flattenedAttributes.filter((attribute) =>
      attribute.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [flattenedAttributes, search]);

  const email =
    jimer?.personalEmail != null ? jimer.personalEmail : jimer.externalEmail;

  return (
    <JimerSection
      className="jimer-attributes"
      title={
        <>
          Attributes
          <a
            href="https://help.usejimo.com/help-center/v/for-developers/for-developers/sdk-guides"
            target="_blank"
            rel="noopener noreferrer">
            <i className="isax isax-book-1" />
            Adding user attributes in Jimo
          </a>
        </>
      }>
      <div className="jimer-attributes-wrapper">
        <div className="search-wrapper">
          <InputGroup
            labelTextLeft={<i className="icon-search n-500" />}
            placeholder="Search attribute..."
            muted
            value={search}
            onChange={({target}) => setSearch(target.value)}
          />
        </div>
        <div className="jimer-attributes-table">
          <div className="top body-3 n-500">
            <div>Name</div>
            <div>Value</div>
            <div>Source</div>
          </div>
          <div className="jimer-attributes-list">
            {((jimer.identifyToken || jimer.externalUid) &&
              matchesSearch('Jimer ID')) ||
            (email && matchesSearch('Email')) ||
            filteredAttributes.length > 0 ? (
              <>
                {(jimer.identifyToken || jimer.externalUid) &&
                  matchesSearch('Jimer ID') && (
                    <div className="jimer-attribute">
                      <div
                        className="jimer-attribute-name n-700"
                        title="Jimer ID">
                        <span>Jimer ID</span>
                      </div>
                      <div
                        className="jimer-attribute-value body-2 n-800"
                        title={jimer.identifyToken || jimer.externalUid}>
                        {jimer.identifyToken || jimer.externalUid}
                      </div>
                      <div className="jimer-attribute-source body-2 n-800">
                        <div className="jimer-attribute-icon">
                          <i className="icon-jimo" />
                        </div>
                        Native
                      </div>
                    </div>
                  )}
                {email && matchesSearch('Email') && (
                  <div className="jimer-attribute">
                    <div className="jimer-attribute-name n-700" title="Email">
                      Email
                    </div>
                    <div
                      className="jimer-attribute-value body-2 n-800"
                      title={email}>
                      {email}
                    </div>
                    <div className="jimer-attribute-source body-2 n-800">
                      <div className="jimer-attribute-icon">
                        <i className="icon-jimo" />
                      </div>
                      Native
                    </div>
                  </div>
                )}

                {filteredAttributes
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((attribute, index) => {
                    const type = attribute._attributeType;
                    const icon = getAttributeTypeIcon(type);

                    let displayValue;
                    if (typeof attribute.value === 'boolean') {
                      displayValue = attribute.value ? 'true' : 'false';
                    } else if (
                      attribute.value === null ||
                      attribute.value === undefined
                    ) {
                      displayValue = '';
                    } else if (typeof attribute.value === 'object') {
                      displayValue = JSON.stringify(attribute.value);
                    } else {
                      displayValue = String(attribute.value);
                    }

                    return (
                      <div
                        className="jimer-attribute"
                        key={`${attribute.name}-${index}`}>
                        <div
                          className="jimer-attribute-name n-700"
                          title={attribute.name}>
                          <span>{attribute.name}</span>
                        </div>
                        <div
                          className="jimer-attribute-value body-2 n-800"
                          title={displayValue}>
                          {displayValue}
                        </div>
                        <div className="jimer-attribute-source body-2 n-800">
                          <div
                            className={classNames(
                              'jimer-attribute-icon',
                              type
                            )}>
                            {icon}
                          </div>
                          {attribute.source}
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : (
              <div className="jimer-attributes-empty-state">
                <EmptyStateBlock
                  img={EmptyStateImgs.EmptyResults}
                  title="No results found"
                  description="Refine your filters to find what you're looking for"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </JimerSection>
  );
};

Attributes.propTypes = propTypes;

export default Attributes;
