import Button from 'components/Button';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {object} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import HintPosition from 'scenes/PokeBuilder/components/BlockEditor/components/modals/HintPosition';
import Position from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Position';
import EffectsSection from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/EffectsSection';
import Section from 'scenes/PokeBuilder/components/Section';
import {ElementSelector} from 'scenes/SuccessTracker/components/Event/components/EventConditions/components/EditDropdown/components/ElementSelector';
import {BLOCK_TYPE_HINT} from 'services/steps';
import {F_SLOT_HINT} from 'shared/front/components/Poke/constants/poke';
import './_Styles.scss';

const propTypes = {
  targetPosition: object,
};

const defaultProps = {
  targetPosition: null,
};

const ElementTargeting = ({targetPosition}) => {
  const {
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    messenger,
    setIsEditingTargetElement,
    selectedStepId,
    selectedBlockType,
    selectedStep,
    inConcept,
    selectingElementTriggerConditionId,
  } = useContext(BuilderContext);

  const [hide, setHide] = useState(false);
  const [style, setStyle] = useState(null);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const evolutionRef = useRef(evolution);

  useEffect(() => {
    evolutionRef.current = evolution;
  }, [evolution]);

  useEffect(() => {
    messenger.sendTogglePositionPreviewMode({enabled: true});

    return () => {
      messenger.sendTogglePositionPreviewMode({
        enabled: false,
        evolution: evolutionRef.current,
      });
    };
  }, []);

  useEffect(() => {
    messenger?.sendBuilderState({
      poke: evolution,
      selectedStepId,
      selectedBlockType,
      selectedStep,
      inConcept,
    });
  }, [
    messenger,
    evolution,
    selectedStepId,
    selectedBlockType,
    selectedStep,
    inConcept,
  ]);

  useEffect(() => {
    if (hide === true) {
      setTimeout(() => {
        setHide(false);
      }, 200);
    }
  }, [hide]);

  const width = windowSize.current[0];
  const spaceLeft = targetPosition?.x;
  const spaceRight = width - spaceLeft - targetPosition?.width;
  const divWidth = 306;

  useEffect(() => {
    if (hide !== true && style == null) {
      if (spaceLeft > spaceRight) {
        setStyle({
          left: 0,
          marginLeft: Math.max(30, spaceLeft - 400 - divWidth),
        });
      } else {
        setStyle({
          right: 0,
          marginRight: Math.max(30, spaceRight - 400 - divWidth),
        });
      }
    }
  }, [spaceLeft, spaceRight, style, hide]);

  const onCancel = () => {
    messenger.sendSelectTargetElement();
    setHide(true);
  };

  const onConfirm = () => {
    setIsEditingTargetElement(false);
  };

  if (evolution == null || hide === true || style == null) {
    return <></>;
  }

  const isHint = hasFlag(F_SLOT_HINT, evolution.boostFlags);

  const triggerConditionId = selectingElementTriggerConditionId;
  const selectedTrigger = selectedStep?.triggers?.find((trigger) =>
    trigger.conditions?.find((c) => c.uid === triggerConditionId)
  );
  const selectedCondition = selectedTrigger?.conditions?.find(
    (c) => c.uid === triggerConditionId
  );

  const hintBlock = selectedStep?.blocks?.find(
    (b) => b.type === BLOCK_TYPE_HINT
  );

  const effectStyle = isHint === true ? hintBlock?.style : evolution?.style;

  return (
    <div className="element-targeting-wrapper">
      <div className="block-settings content-settings">
        <Section>
          {selectingElementTriggerConditionId != null ? (
            <ElementSelector
              data={{
                querySelector: selectedCondition?.querySelector,
                querySelectors: evolution?.querySelectors,
                file: selectedCondition?.file,
                querySelectorManual: selectedCondition?.querySelectorManual,
              }}
              onChange={(data) => {
                setEvolution({
                  ...evolution,
                  steps: evolution.steps.map((step) => {
                    if (step.uid === selectedStepId) {
                      return {
                        ...step,
                        triggers: step.triggers.map((trigger) => {
                          if (trigger.uid === selectedTrigger.uid) {
                            return {
                              ...trigger,
                              actions: trigger.actions.map((action) => {
                                if (action.uid === triggerConditionId) {
                                  return {
                                    ...action,
                                    querySelector: data.querySelector,
                                    querySelectorManual:
                                      data.querySelectorManual,
                                    file: data.file,
                                  };
                                }
                                return action;
                              }),
                            };
                          }
                          return trigger;
                        }),
                      };
                    }
                    return step;
                  }),
                });
              }}
              onSelectElement={messenger.sendSelectTargetElement}
            />
          ) : isHint ? (
            <HintPosition evolution={evolution} setEvolution={setEvolution} />
          ) : (
            <Position evolution={evolution} setEvolution={setEvolution} />
          )}
        </Section>
        <Divider />
        <EffectsSection
          style={effectStyle}
          onChange={(newStyle) => {
            if (isHint === true) {
              setEvolution({
                ...evolution,
                steps: evolution.steps.map((step) => {
                  if (step.uid === selectedStepId) {
                    return {
                      ...step,
                      blocks: step.blocks.map((block) => {
                        if (block.type === BLOCK_TYPE_HINT) {
                          return {
                            ...block,
                            style: {...block.style, ...newStyle},
                          };
                        }
                        return block;
                      }),
                    };
                  }
                  return step;
                }),
              });
            } else {
              setEvolution({
                ...evolution,
                style: {
                  ...evolution.style,
                  ...newStyle,
                },
              });
            }
          }}
        />
      </div>

      <Divider />
      <div className="footer-wrapper">
        <Button cta onClick={onCancel}>
          Cancel
        </Button>
        <Button
          cta
          className="continue-btn"
          primary
          onClick={onConfirm}
          disabled={false}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

ElementTargeting.propTypes = propTypes;
ElementTargeting.defaultProps = defaultProps;

export default ElementTargeting;
