import {uptProject} from 'actions/general';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS_POSTHOG} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {posthogService} from 'services';
import {PLAN_GROWTH_ID, PLAN_SCALE_ID} from 'services/subscription';
import {Swaler} from 'swaler';
import PosthogLogo from '../imgs/posthog.svg';
import './_Styles.scss';

const logger = new Swaler('SettingsIntegrationsPosthog');

const Posthog = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {update} = useUpdateSubscription();

  const project = useSelector((state) => generalSelector.getProject(state));
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSetupPosthog = async () => {
    if ([PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(subscription.plan) === false) {
      return update({
        planId: PLAN_GROWTH_ID,
        title: 'Enable PostHog',
        description: 'Unlock PostHog integration with our Growth plan',
      });
    }

    setIsLoading(true);
    try {
      const posthog = await posthogService.setup(project.uid);

      updateProject({
        ...project,
        posthog,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to setup posthog integration, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  const hasPosthogSetup = project.posthog != null;

  return (
    <div className="settings-card integration-posthog">
      <div className="logo-action-wrapper">
        <img
          className="logo posthog-logo"
          src={PosthogLogo}
          alt="posthog-logo"
        />
        {hasPosthogSetup === false ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isLoading}
            onClick={handleSetupPosthog}>
            Enable
          </Button>
        ) : (
          <Button
            thin
            iconLeft="isax isax-setting-2"
            onClick={() => history.push(ROUTE_SETTINGS_INTEGRATIONS_POSTHOG)}>
            Configure
          </Button>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Posthog
          {hasPosthogSetup === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Enabled
            </div>
          )}
        </div>
        <div className="body-3">
          Send Jimo analytics in PostHog and personalize user experiences in
          Jimo based on Posthog events.
        </div>
      </div>
    </div>
  );
};

export default Posthog;
