import classNames from 'classnames';
import React, {useContext} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {BLOCK_RESOURCE_CENTER_MINIMIZE} from '../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../Poke/context';
import './_Styles.scss';

const ResourceCenterMinimize = () => {
  const {
    selectedBlock,
    inBuilder,
    onBlockSelected,
    onContainerSelected,
    blocks,
    setIsMinimized,
    isMinimized,
  } = useContext(PokeStateContext);

  const block = blocks.find((b) => b.type === BLOCK_RESOURCE_CENTER_MINIMIZE);

  const {style} = block;
  const {backgroundColor, borderColor, borderRadius, iconColor, shadow} = style;

  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = color != null ? `${x}px ${y}px ${blur}px ${color}` : 'none';

  return (
    <div
      className={classNames('block-resource-center-minimize', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_RESOURCE_CENTER_MINIMIZE,
        'in-builder': inBuilder === true,
      })}
      onClick={(e) => {
        e.stopPropagation();

        if (inBuilder === true) {
          onBlockSelected(BLOCK_RESOURCE_CENTER_MINIMIZE);
          onContainerSelected(null);
          return;
        }

        setIsMinimized(!isMinimized);
      }}
      style={{
        ...(backgroundColor != null ? {backgroundColor} : {}),
        ...(borderColor != null ? {border: `1px solid ${borderColor}`} : {}),
        borderRadius,
        boxShadow,
      }}>
      <i className="icon-chevron-bottom" style={{color: iconColor}} />
      <ClickableBlockOverlay />
    </div>
  );
};

export default ResourceCenterMinimize;
