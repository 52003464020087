import classNames from 'classnames';
import './_Styles.scss';

/**
 * Renders a single item in the navigator list.
 */
const NavigatorItem = ({
  type,
  iconClassName,
  iconWrapperClassName = '',
  name,
  deletable,
  level,
  taskItemId,
  className,
  isSelected,
  onSelect,
  onDelete,
  dragHandleProps,
}) => {
  const handleClick = () => {
    onSelect(type, taskItemId);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete?.(type, taskItemId);
  };

  return (
    <div
      className={classNames('navigator-item', className, `level-${level}`, {
        selected: isSelected,
      })}
      onClick={handleClick}>
      {dragHandleProps && (
        // Move dragHandleProps to the top-level NavigatorItem if we want the whole block to be draggable
        <div className="drag-handle" {...dragHandleProps}>
          <i className="icon-menu-vertical" />
          <i className="icon-menu-vertical" />
        </div>
      )}
      <div className={classNames('icon-wrapper', iconWrapperClassName)}>
        <i className={iconClassName} />
      </div>
      <span className="name">{name}</span>
      {deletable && (
        <div className="delete-icon-wrapper" onClick={handleDelete}>
          <i className="icon-close" />
        </div>
      )}
    </div>
  );
};

export default NavigatorItem;
