import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {createContext, useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {EVOLUTION_TYPE_SURVEY, EVOLUTION_TYPE_TOUR} from 'services/evolution';
import {F_EXTRA_DISABLE_RUN_JAVASCRIPT_TRIGGERS} from 'services/project';
import {
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  STEP_CONDITION_ACTION_TYPE_SKIP_TASK,
  STEP_CONDITION_ACTION_TYPE_SNOOZE,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import Action from '../Action';
import {getSharedSnooze} from '../EditActionDropdown/components/SettingsSnooze';
import {TriggerContext} from '../Trigger';
import './_Styles.scss';

export const EventConditionsEditorContext = createContext();

export const ActionIcon = ({type}) => {
  let icon, iconClassName;

  switch (type) {
    case STEP_CONDITION_ACTION_TYPE_GO_TO_STEP:
      icon = 'isax isax-arrow-right';
      iconClassName = 'go-to-step';
      break;
    case STEP_CONDITION_ACTION_TYPE_DISMISS:
      icon = 'isax isax-close-square';
      iconClassName = 'dismiss';
      break;
    case STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE:
      icon = 'isax isax-routing';
      iconClassName = 'launch-experience';
      break;
    case STEP_CONDITION_ACTION_TYPE_OPEN_POST:
      icon = 'isax isax-slider-vertical-1';
      iconClassName = 'open-post';
      break;
    case STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO:
      icon = 'isax isax-send-2';
      iconClassName = 'navigate-to';
      break;
    case STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE:
      icon = 'isax isax-document-code';
      iconClassName = 'run-js-code';
      break;
    case STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW:
      icon = 'isax isax-calendar-search';
      iconClassName = 'book-interview';
      break;
    default:
      break;
  }

  return (
    <div className={classNames('action-icon', iconClassName)}>
      <i className={icon} />
    </div>
  );
};

const DropdownMenuItem = ({
  iconClassName,
  icon,
  title,
  subtitle,
  onClick,
  disabled,
}) => {
  return (
    <div
      className={classNames('dropdown-menu-item-wrapper', {
        disabled,
      })}>
      <div className="dropdown-menu-item" onClick={onClick}>
        <div className={classNames('icon-wrapper', iconClassName)}>{icon}</div>
        <div className="content">
          <div className="title body-3 n-800">{title}</div>
          <div className="subtitle body-4 n-700">
            {disabled && <i className="isax isax-info-circle" />}
            {subtitle}
          </div>
        </div>
      </div>
    </div>
  );
};

export const DropdownAddAction = ({
  authorizedActions = [],
  disabledActions = [],
  onAddAction,
  isSurvey = false,
  isBanner = false,
  ...rest
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));
  const {selectedStep, evolution} = useContext(BuilderContext);

  const [showDropdown, setShowDropdown] = useState(false);

  if (authorizedActions.length > 0) {
    const hasDisabledSnooze =
      disabledActions.includes(STEP_CONDITION_ACTION_TYPE_SNOOZE) === true;
    return (
      <Dropdown
        open={showDropdown}
        className="add-action-condition"
        position="bottom left"
        repositionOnResize={true}
        offsetY={-64}
        offsetX={8}
        onOpen={() => setShowDropdown(true)}
        onClose={() => setShowDropdown(false)}
        trigger={
          <Button
            tertiary
            className="add-action-element-btn"
            onClick={() => setShowDropdown(true)}
            thin
            iconLeft="icon-plus">
            Add action
          </Button>
        }
        {...rest}>
        <div className="actions-list">
          {authorizedActions.includes(STEP_CONDITION_ACTION_TYPE_GO_TO_STEP) ===
            true && (
            <DropdownMenuItem
              iconClassName="go-to-step"
              icon={
                isBanner ? (
                  <i className="isax isax-tick-circle" />
                ) : (
                  <i className="isax isax-arrow-right" />
                )
              }
              title={isBanner ? 'Close banner' : 'Go to step'}
              subtitle={
                isBanner
                  ? 'Close the current banner'
                  : 'Navigate to another step'
              }
              onClick={() => {
                onAddAction(STEP_CONDITION_ACTION_TYPE_GO_TO_STEP, {
                  value: 'next-step',
                });
                setShowDropdown(false);
              }}
            />
          )}
          {authorizedActions.includes(STEP_CONDITION_ACTION_TYPE_DISMISS) ===
            true && (
            <DropdownMenuItem
              iconClassName="dismiss"
              icon={<i className="isax isax-close-square" />}
              title={isSurvey ? 'Finish survey' : 'Dismiss experience'}
              subtitle={
                isSurvey
                  ? 'Ends the current survey'
                  : 'Dismiss the current experience'
              }
              onClick={() => {
                onAddAction(STEP_CONDITION_ACTION_TYPE_DISMISS, {evolution});
                setShowDropdown(false);
              }}
            />
          )}
          {authorizedActions.includes(
            STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE
          ) === true && (
            <DropdownMenuItem
              iconClassName="launch-experience"
              icon={<i className="isax isax-routing" />}
              title="Launch experience"
              subtitle="Start another Jimo experience"
              onClick={() => {
                onAddAction(STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE);
                setShowDropdown(false);
              }}
            />
          )}
          {authorizedActions.includes(STEP_CONDITION_ACTION_TYPE_OPEN_POST) ===
            true && (
            <DropdownMenuItem
              iconClassName="open-post"
              icon={<i className="isax isax-slider-vertical-1" />}
              title="Open Post"
              subtitle="Open a changelog post"
              onClick={() => {
                onAddAction(STEP_CONDITION_ACTION_TYPE_OPEN_POST);
                setShowDropdown(false);
              }}
            />
          )}
          {authorizedActions.includes(
            STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO
          ) === true && (
            <DropdownMenuItem
              iconClassName="navigate-to"
              icon={<i className="isax isax-send-2" />}
              title="Navigate to"
              subtitle="Send user to a specific page URL"
              onClick={() => {
                onAddAction(STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO);
                setShowDropdown(false);
              }}
            />
          )}
          {authorizedActions.includes(
            STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE
          ) === true &&
            hasFlag(
              F_EXTRA_DISABLE_RUN_JAVASCRIPT_TRIGGERS,
              project.extraFlags
            ) === false && (
              <DropdownMenuItem
                iconClassName="run-js-code"
                icon={<i className="isax isax-document-code" />}
                title="Run JavaScript code"
                subtitle="Execute custom JavaScript code"
                onClick={() => {
                  onAddAction(STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE);
                  setShowDropdown(false);
                }}
              />
            )}
          {authorizedActions.includes(STEP_CONDITION_ACTION_TYPE_SKIP_TASK) ===
            true && (
            <DropdownMenuItem
              iconClassName="skip-task"
              icon={<i className="isax isax-arrow-square-left" />}
              title="Skip task"
              subtitle="Mark a task as completed"
              onClick={() => {
                onAddAction(STEP_CONDITION_ACTION_TYPE_SKIP_TASK);
                setShowDropdown(false);
              }}
            />
          )}
          {(authorizedActions.includes(STEP_CONDITION_ACTION_TYPE_SNOOZE) ===
            true ||
            hasDisabledSnooze === true) && (
            <DropdownMenuItem
              disabled={hasDisabledSnooze === true}
              iconClassName="snooze"
              icon={<i className="isax isax-pause-circle" />}
              title={isSurvey ? 'Snooze survey' : 'Snooze experience'}
              subtitle={
                hasDisabledSnooze === true
                  ? 'Only applicable in first step'
                  : isSurvey
                  ? 'Hide and reshow survey later'
                  : 'Hide and reshow experience later'
              }
              onClick={() => {
                const sharedSnooze = getSharedSnooze(selectedStep);
                onAddAction(STEP_CONDITION_ACTION_TYPE_SNOOZE, {
                  value: sharedSnooze?.value,
                  evolution,
                });
                setShowDropdown(false);
              }}
            />
          )}
          {/* {omit.includes(STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW) === false && (
          <DropdownMenuItem
            iconClassName="book-interview"
            icon={<i className="isax isax-calendar-search" />}
            title="Book Interview"
            subtitle="Book an interview"
            onClick={() => {
              onAddAction(STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW);
              setShowDropdown(false);
            }}
          />
        )} */}
        </div>
      </Dropdown>
    );
  }

  return <></>;
};

const TriggerActions = () => {
  const {evolution, selectedStep, updateStep} = useContext(BuilderContext);
  const {trigger, setTrigger, isExpanded, preventAddAction} =
    useContext(TriggerContext);
  const {actions} = trigger;

  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;
  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;

  const [newlyAddedAction, setNewlyAddedAction] = useState(null);

  const steps = isSurvey
    ? evolution.steps.filter((s) => s.removed !== true)
    : evolution.tourSteps
        .map((ts) =>
          ts.steps.map((s) => ({
            ...s,
            tourStepIndex: ts.tourStepInfo.split(';')[0],
          }))
        )
        .flat()
        .filter((s) => s.removed !== true);
  steps.sort(
    (
      {tourStepIndex: tourStepIndexA, indexOrder: indexOrderA},
      {tourStepIndex: tourStepIndexB, indexOrder: indexOrderB}
    ) => {
      // first sort on tourStepIndex and then on indexOrder
      if (tourStepIndexA < tourStepIndexB) {
        return -1;
      }
      if (tourStepIndexA > tourStepIndexB) {
        return 1;
      }
      if (indexOrderA < indexOrderB) {
        return -1;
      }
      if (indexOrderA > indexOrderB) {
        return 1;
      }
      return 0;
    }
  );
  const currentStepIndex = steps.findIndex((s) => s.uid === selectedStep.uid);

  // if snooze:
  // for each block in the first step, for each action in block, update snooze value
  const updateSharedAction = (updatedAction) => {
    updateStep(selectedStep.uid, {
      blocks: selectedStep?.blocks?.map((block) => ({
        ...block,
        actions: block?.actions?.map((_action) =>
          _action.type === updatedAction.type
            ? {..._action, value: updatedAction.value}
            : _action
        ),
      })),
      triggers: selectedStep?.triggers?.map((t) => ({
        ...t,
        actions: t.actions.map((ta) =>
          ta.type === updatedAction.type
            ? {...ta, value: updatedAction.value}
            : ta
        ),
      })),
    });
  };

  const onChange = (newActions) => {
    setTrigger({
      ...trigger,
      actions: newActions,
    });
  };

  const handleAddAction = (type, opts = {}) => {
    // handle case of adding a go to step action when step is first step and there is no step to go to
    const newAction = {
      ...opts,
      uid: uuidv4(),
      type,
      ...(type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP && {
        value: 'next-step',
      }),
    };

    onChange([...actions, newAction]);
    setNewlyAddedAction(newAction);
  };

  const isSnoozeAuthorized = (isTour || isSurvey) && currentStepIndex === 0;
  const hasSnoozeHint = (isTour || isSurvey) && currentStepIndex !== 0; // everything BUT step is correct

  const usedActionTypes = actions.map((action) => action.type);

  const disabledActions = [
    ...(hasSnoozeHint === true ? [STEP_CONDITION_ACTION_TYPE_SNOOZE] : []),
  ];

  let authorizedActions = [
    STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
    STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
    STEP_CONDITION_ACTION_TYPE_DISMISS,
    STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
    STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
    ...(isSnoozeAuthorized ? [STEP_CONDITION_ACTION_TYPE_SNOOZE] : []),
  ].filter((a) => actions.map((action) => action.type).includes(a) !== true);

  if (
    usedActionTypes.some((t) =>
      [
        STEP_CONDITION_ACTION_TYPE_DISMISS,
        STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
        STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
        STEP_CONDITION_ACTION_TYPE_DISMISS,
      ].includes(t)
    )
  ) {
    authorizedActions = authorizedActions.filter(
      (a) =>
        [
          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
          STEP_CONDITION_ACTION_TYPE_OPEN_POST,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(a) !== true
    );
  }
  if (
    usedActionTypes.some((t) =>
      [
        STEP_CONDITION_ACTION_TYPE_DISMISS,
        STEP_CONDITION_ACTION_TYPE_SNOOZE,
      ].includes(t)
    )
  ) {
    authorizedActions = authorizedActions.filter(
      (a) =>
        [
          STEP_CONDITION_ACTION_TYPE_DISMISS,
          STEP_CONDITION_ACTION_TYPE_SNOOZE,
        ].includes(a) !== true
    );
  }

  return (
    <div className="trigger-actions">
      {isExpanded !== true && actions.length === 0 && (
        <div className="empty-state-reduced-actions body-3 n-600">
          No actions
        </div>
      )}
      {actions?.map((action, index) => {
        return (
          <>
            <Action
              action={action}
              onChange={(updatedAction) => {
                if (updatedAction.type === STEP_CONDITION_ACTION_TYPE_SNOOZE) {
                  updateSharedAction(updatedAction);
                } else {
                  onChange(
                    actions.map((_action) =>
                      _action.uid === action.uid ? updatedAction : _action
                    )
                  );
                }
              }}
              onDelete={() => {
                onChange(
                  actions.filter((_action) => _action.uid !== action.uid)
                );
              }}
              isExpanded={isExpanded}
              defaultOpen={newlyAddedAction?.uid === action.uid}
              isSurvey={isSurvey}
            />
            {isExpanded !== true && index < actions.length - 1 && <Divider />}
          </>
        );
      })}
      {isExpanded && preventAddAction !== true && (
        <div className="logic-actions">
          <DropdownAddAction
            authorizedActions={authorizedActions}
            disabledActions={disabledActions}
            onAddAction={handleAddAction}
            isSurvey={isSurvey}
          />
        </div>
      )}
    </div>
  );
};

export default TriggerActions;
