import classNames from 'classnames';
import React, {useContext} from 'react';
import {ChangelogBuilderContext} from 'scenes/ChangelogBuilder';
import ChangelogNavigator from '../ChangelogNavigator';
import ChangelogTranslations from '../ChangelogTranslations';
import './_Styles.scss';

export const MODE_ADD_BLOCK = 'MODE_ADD_BLOCK';
export const MODE_NAVIGATOR = 'MODE_NAVIGATOR';
export const MODE_TRANSLATIONS = 'MODE_TRANSLATIONS';
export const MODE_LOGIC = 'MODE_LOGIC';
export const MODE_THEME = 'MODE_THEME';

const propTypes = {};

const defaultProps = {};

const ChangelogBuilderSidebar = () => {
  const {mode, setMode} = useContext(ChangelogBuilderContext);

  return (
    <div className={classNames('changelog-builder-sidebar-wrapper')}>
      <div className="changelog-builder-sidebar">
        <div
          className={classNames('sidebar-item', {
            selected: mode === MODE_NAVIGATOR,
          })}
          onClick={() => {
            setMode(mode === MODE_NAVIGATOR ? null : MODE_NAVIGATOR);
          }}>
          <i
            className={
              [MODE_NAVIGATOR, MODE_ADD_BLOCK].includes(mode) === true
                ? 'isax isax-layer5'
                : 'isax isax-layer'
            }
          />
        </div>
        <div
          className={classNames('sidebar-item', {
            selected: mode === MODE_TRANSLATIONS,
          })}
          onClick={() => {
            setMode(mode === MODE_TRANSLATIONS ? null : MODE_TRANSLATIONS);
          }}>
          <i
            className={
              mode === MODE_TRANSLATIONS
                ? 'isax isax-language-square5'
                : 'isax isax-language-square'
            }
          />
        </div>
      </div>
      <div className="changelog-builder-sidebar-content-wrapper">
        {mode === MODE_NAVIGATOR && <ChangelogNavigator />}
        {mode === MODE_TRANSLATIONS && <ChangelogTranslations />}
      </div>
    </div>
  );
};

ChangelogBuilderSidebar.propTypes = propTypes;
ChangelogBuilderSidebar.defaultProps = defaultProps;

export default ChangelogBuilderSidebar;
