import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import DefaultLoader from 'components/Loader';
import {toastSuccess} from 'components/Toaster';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import {useWebhookDelete} from 'hooks/useWebhookDelete';
import {useWebhookUpdateStatus} from 'hooks/useWebhookUpdateStatus';
import JSONBeautify from 'json-beautify';
import {useEffect, useRef, useState} from 'react';
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import ReactHighlighter from 'react-syntax-highlighter';
import {tomorrowNightEighties} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK,
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_SETUP,
} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {webhookService} from 'services';
import {
  CATEGORIES_ICONS,
  EVENTS,
  STATUS_LIVE,
  STATUS_PAUSED,
  TRIGGER_STATUS_FAIL,
  TRIGGER_STATUS_SUCCESS,
} from 'services/webhooks';
import {Swaler} from 'swaler';
import ModalWebhookDelete from '../../components/ModalWebhookDelete';
import './_styles.scss';

dayjs.extend(calendar);

const FILTER_ALL = 'all';
const FILTER_SUCCEEDED = 'succeeded';
const FILTER_FAILED = 'failed';

const logger = new Swaler('SettingsWebhookDetails');

export const SettingsWebhookDetails = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const {updateStatus} = useWebhookUpdateStatus({
    onUpdated: (updatedWebhook) => {
      setWebhook({...webhook, status: updatedWebhook.status});
    },
  });
  const {deleteWebhook, deleting} = useWebhookDelete({
    onDelete: () => {
      history.push(ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK);
    },
  });

  const {webhookId} = match.params;

  const [filter, setFilter] = useState(FILTER_ALL);
  const [webhook, setWebhook] = useState(null);
  const [webhookToDelete, setWebhookToDelete] = useState(null);
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [retrying, setRetrying] = useState(false);
  const [retried, setRetried] = useState([]);

  const refDropdown = useRef(null);

  const fetchWebhook = async () => {
    const fetchedWebhook = await webhookService.fetchWebhook(webhookId, {
      withTriggers: true,
    });

    setWebhook({...fetchedWebhook, events: fetchedWebhook.events.split(';')});
  };

  const handleRetry = async () => {
    setRetrying(true);
    setRetried(retried.concat(selectedTrigger.uid));
    await webhookService.retryTrigger(webhookId, selectedTrigger.uid);
    setRetrying(false);
  };

  const displayDate = (date) => {
    return dayjs(date).calendar(null, {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'DD/MM/YYYY',
      sameElse: 'DD/MM/YYYY',
    });
  };

  useEffect(() => {
    fetchWebhook();
  }, []);

  const triggersGroupByDate = webhook?.triggers
    .filter((trigger) => {
      if (filter === FILTER_SUCCEEDED) {
        return trigger.status === TRIGGER_STATUS_SUCCESS;
      }
      if (filter === FILTER_FAILED) {
        return trigger.status === TRIGGER_STATUS_FAIL;
      }
      return true;
    })
    .sort(
      (a, b) =>
        new Date(b.triggeredAt).getTime() - new Date(a.triggeredAt).getTime()
    )
    .reduce((acc, trigger) => {
      const date = dayjs(trigger.triggeredAt).startOf('day');
      const groupIndex = acc.findIndex((group) => date.isSame(group.date));

      if (groupIndex === -1) {
        return acc.concat({
          date,
          triggers: [trigger],
        });
      }
      acc[groupIndex].triggers.push(trigger);
      return acc;
    }, []);

  const response =
    selectedTrigger?.response?.data ?? selectedTrigger?.response?.error;
  const selectedTriggerEvent =
    selectedTrigger != null
      ? Object.values(EVENTS).find(
          (event) => event.key === selectedTrigger.event
        )
      : null;

  return (
    <SettingsBody className="s-settings-webhook-details">
      {webhook == null && (
        <div className="webhook-loading">
          <DefaultLoader width="30px" />
        </div>
      )}
      {webhook != null && (
        <>
          <div className="webhook-setup-header">
            <div className="left-wrapper">
              <Button
                onClick={() =>
                  history.push(ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK)
                }>
                <i className="icon-chevron-left"></i>
              </Button>
              <div className="name-wrapper">
                <div className="status-wrapper title-4">
                  {webhook.name}
                  <div
                    className={classnames('webhook-status', {
                      'is-live': webhook.status === STATUS_LIVE,
                    })}>
                    {webhook.status.toLowerCase()}
                  </div>
                </div>
                <div className="body-2 n-600">
                  {webhook.endpoint}{' '}
                  <i
                    className="isax isax-copy"
                    onClick={() => {
                      copy(webhook.endpoint);
                      toastSuccess('Copied!', {toastId: 'webhook-endpoint'});
                    }}></i>
                </div>
              </div>
            </div>
            <div className="right-wrapper">
              <Button
                iconLeft="isax isax-edit"
                thin
                onClick={() =>
                  history.push(
                    ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_SETUP(webhookId)
                  )
                }>
                Edit
              </Button>
              <Dropdown
                innerRef={refDropdown}
                position="bottom right"
                offsetY={4}
                trigger={
                  <Button className="btn-more" thin>
                    <i className="icon-menu-vertical"></i>
                  </Button>
                }>
                <div className="dp-menu">
                  <div
                    className="dp-menu-item body-3"
                    onClick={() => {
                      refDropdown.current.close();
                      updateStatus(
                        webhookId,
                        webhook.status === STATUS_LIVE
                          ? STATUS_PAUSED
                          : STATUS_LIVE
                      );
                    }}>
                    {webhook.status === 'LIVE' ? (
                      <>
                        <i className="isax isax-pause-circle"></i> Pause
                      </>
                    ) : (
                      <>
                        <i className="isax isax-play-circle"></i> Live
                      </>
                    )}
                  </div>
                  <div
                    className="dp-menu-item r-400 body-3 danger"
                    onClick={() => setWebhookToDelete(webhook.uid)}>
                    <i className="isax isax-trash"></i>Delete
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
          <div
            className={classnames('webhook-details', {
              'is-empty': webhook.triggers.length === 0,
            })}>
            {webhook.triggers.length === 0 ? (
              <div className="details-empty">
                <img
                  src="https://assets.usejimo.com/webhook-details-empty.svg"
                  alt="empty details"
                />
                <div className="subtitle-2">No activity yet</div>
                <div className="body-2 n-600">
                  Requests and responses will appear here <br /> once events are
                  triggered.
                </div>
              </div>
            ) : (
              <div className="details-content">
                <div className="details-wrapper">
                  <div className="trigger-list-wrapper">
                    <div className="triggers-filters">
                      <div
                        className={classnames('webhook-filter', {
                          'is-active': filter === FILTER_ALL,
                        })}
                        onClick={() => setFilter(FILTER_ALL)}>
                        All
                      </div>
                      <div
                        className={classnames('webhook-filter', {
                          'is-active': filter === FILTER_SUCCEEDED,
                        })}
                        onClick={() => setFilter(FILTER_SUCCEEDED)}>
                        <i className="isax isax-tick-circle"></i> Succeeded
                      </div>
                      <div
                        className={classnames('webhook-filter', {
                          'is-active': filter === FILTER_FAILED,
                        })}
                        onClick={() => setFilter(FILTER_FAILED)}>
                        <i className="isax isax-close-circle"></i> Failed
                      </div>
                    </div>
                    <div className="trigger-list">
                      {triggersGroupByDate.map((group) => {
                        return (
                          <div
                            key={group.date.toDate().getTime()}
                            className="trigger-section">
                            <div className="section-date subtitle-3">
                              <span className="date-marker"></span>
                              {displayDate(group.date)}
                            </div>
                            <div className="section-triggers">
                              {group.triggers
                                .sort(
                                  (a, b) =>
                                    new Date(b.triggeredAt).getTime() -
                                    new Date(a.triggeredAt).getTime()
                                )
                                .map((trigger) => {
                                  const event = Object.values(EVENTS).find(
                                    (event) => event.key === trigger.event
                                  );

                                  return (
                                    <div
                                      className={classnames('trigger-item', {
                                        'is-active':
                                          selectedTrigger != null &&
                                          selectedTrigger.uid === trigger.uid,
                                      })}
                                      onClick={() => {
                                        setSelectedTrigger(trigger);
                                      }}>
                                      <div
                                        className={classnames(
                                          'trigger-status',
                                          {
                                            'is-fail':
                                              trigger.status ===
                                              TRIGGER_STATUS_FAIL,
                                          }
                                        )}>
                                        {trigger.status ===
                                        TRIGGER_STATUS_SUCCESS ? (
                                          <i className="isax isax-tick-circle5"></i>
                                        ) : (
                                          <i className="isax isax-close-circle5"></i>
                                        )}
                                      </div>
                                      <div className="trigger-icon">
                                        <i
                                          className={
                                            CATEGORIES_ICONS[event.category]
                                          }></i>
                                      </div>
                                      <div className="trigger-name subtitle-4">
                                        {event.name}
                                      </div>
                                      <div className="trigger-time body-3 n-500">
                                        {dayjs(trigger.triggeredAt).format(
                                          'HH:mm'
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={classnames('trigger-details', {
                      'is-empty': selectedTrigger == null,
                    })}>
                    {selectedTrigger == null ? (
                      <>
                        <div className="subtitle-2 n-500">
                          View request and response
                        </div>
                        <div className="body-2 n-400">
                          Click on a webhook trigger to see the details of the
                          request and response
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="trigger-header">
                          <div className="trigger-icon">
                            <i
                              className={
                                CATEGORIES_ICONS[selectedTriggerEvent.category]
                              }></i>
                          </div>
                          <div className="trigger-name-wrapper">
                            <div className="subtitle-4">
                              {selectedTriggerEvent.name}
                            </div>
                            {selectedTrigger.status ===
                            TRIGGER_STATUS_SUCCESS ? (
                              <span className="g-400 body-3">
                                <i className="isax isax-tick-circle5"></i>{' '}
                                Succeed on{' '}
                                {dayjs(webhook.triggeredAt).format(
                                  'D MMM YYYY'
                                )}
                              </span>
                            ) : (
                              <span className="r-400 body-3">
                                <i className="isax isax-close-circle5"></i>{' '}
                                Failed on{' '}
                                {dayjs(webhook.triggeredAt).format(
                                  'D MMM YYYY'
                                )}
                              </span>
                            )}
                          </div>
                          <div className="trigger-retry-wrapper">
                            <div className="trigger-time body-3 n-700">
                              {dayjs(webhook.triggeredAt).format('HH:mm')}
                            </div>
                            {selectedTrigger.status === TRIGGER_STATUS_FAIL && (
                              <Button
                                iconLeft="isax isax-refresh"
                                thin
                                onClick={handleRetry}
                                loading={retrying}
                                disabled={retried.includes(
                                  selectedTrigger.uid
                                )}>
                                Retry
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="trigger-response">
                          <div className="subtitle-3">Response</div>
                          <div className="response-status">
                            <span className="body-3 n-600">
                              HTTP Status code
                            </span>
                            <span className="body-3">
                              {JSON.stringify(
                                selectedTrigger.responseStatusCode
                              ) ?? '-'}
                            </span>
                          </div>
                          {response === '' ? (
                            <div className="response-empty">
                              Response body is empty
                            </div>
                          ) : (
                            <ReactHighlighter
                              language="json"
                              style={tomorrowNightEighties}>
                              {JSONBeautify(
                                selectedTrigger.response.data ??
                                  selectedTrigger.response.error,
                                null,
                                2,
                                20
                              )}
                            </ReactHighlighter>
                          )}
                        </div>
                        <div className="trigger-request">
                          <div className="subtitle-3">Request</div>
                          <ReactHighlighter
                            language="json"
                            style={tomorrowNightEighties}>
                            {JSONBeautify(selectedTrigger.request, null, 2, 20)}
                          </ReactHighlighter>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <ModalWebhookDelete
        isOpen={webhookToDelete != null}
        onConfirm={() => deleteWebhook(webhookId)}
        isConfirming={deleting}
        onRequestClose={() => setWebhookToDelete(null)}
      />
    </SettingsBody>
  );
};
