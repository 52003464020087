export const CHECKLIST_STEP_TYPE_SUCCESS = 'SUCCESS';
export const CHECKLIST_STEP_TYPE_CHECKLIST = 'CHECKLIST';

export const F_STEP_CHECKLIST_STEP_IS_DISMISSIBLE = 16;
export const F_STEP_CHECKLIST_STEP_COMPLETION_ORDERED = 32;

export const getMaxAvailableHeight = (maxHeight = 0, offsetY = 0) => {
  const windowHeight = window.innerHeight;

  const calculatedResourceCenterMaxHeight = windowHeight - (32 + offsetY);

  return Math.min(calculatedResourceCenterMaxHeight, maxHeight);
};
