import {default as classnames, default as classNames} from 'classnames';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {PermissionsSpace} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom';
import {ROUTE_SPACE_WITH_ID} from 'router/routes.const';
import {spaceService} from 'services';
import './_Styles.scss';
import ModalEditSpace from './component/ModalEditSpace';
import SpaceExperiencePreview from './component/SpaceExperiencePreview';
import SpacesHeader from './component/SpacesHeader';

const EmptyStateSpace = 'https://assets.usejimo.com/empty-state/spaces.svg';

const SpaceCardPreview = ({space}) => {
  return (
    <div
      className="space-card-preview"
      style={{background: `${space.color}80`}}>
      {space.previewEvolutions?.length === 0 ? (
        <div className="empty-space-preview subtitle-4 n-600">
          This space is empty
        </div>
      ) : (
        space.previewEvolutions?.map((evolution, index) => {
          return (
            <div
              className={classNames(
                'space-experience-preview-wrapper',
                `index-${index}`
              )}
              key={evolution.uid}>
              <SpaceExperiencePreview experience={evolution} />
            </div>
          );
        })
      )}
    </div>
  );
};

const SpaceCard = ({space}) => {
  const history = useHistory();

  return (
    <div
      className="space-card"
      onClick={() => history.push(ROUTE_SPACE_WITH_ID(space.uid))}>
      <SpaceCardPreview space={space} />
      <div className="space-card-content">
        <div className="space-card-content-header">
          <div className="icon-wrapper" style={{background: space.color}}>
            {space.icon}
          </div>
          <div className="space-card-content-header-info">
            <div className="space-card-name subtitle-2 n-800">{space.name}</div>
            <div className="space-card-content-header-info-count">
              <div className="body-3 n-700">
                {space.evolutions?.length || 0}{' '}
                <span className="body-3 n-700">
                  experience{space.evolutions?.length > 1 ? 's' : ''}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames('space-card-description body-3 n-700', {
            'no-description': !space.description,
          })}>
          {space.description || 'No description'}
        </div>
      </div>
    </div>
  );
};

const Spaces = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const {isLoading, data: spaces} = useQuery({
    queryKey: ['spaces', {relations: ['evolutions', 'previewEvolutions']}],
    queryFn: () =>
      spaceService.getSpaces({
        relations: ['evolutions', 'previewEvolutions'],
      }),
  });

  const isEmpty = isLoading === false && spaces?.length === 0;

  const canCreateSpace = hasPermissions(PermissionsSpace.CREATE_EDIT);

  return (
    <div
      className={classnames('s-spaces', {
        'is-empty': isEmpty,
      })}>
      {isEmpty ? (
        <div className="s-spaces-empty-state">
          <div className="empty-state-content">
            <div className="empty-state-content-text">
              <div className="title-3 n-800">
                Group your experiences with Spaces
              </div>
              <div className="body-2 n-700">
                Organize multiples types of experiences in one place with
                Spaces.
              </div>
            </div>
            {canCreateSpace && (
              <Button
                primary
                iconLeft={'isax isax-add'}
                onClick={() => setShowCreateModal(true)}>
                New Space
              </Button>
            )}
          </div>
          <div className="empty-state-image">
            <img src={EmptyStateSpace} alt="Empty State Space" />
          </div>
        </div>
      ) : (
        <>
          <SpacesHeader />
          {isLoading === true ? (
            <div className="spaces-loader-wrapper">
              <DefaultLoader />
            </div>
          ) : (
            <div className="spaces-wrapper">
              {spaces?.map((space) => (
                <SpaceCard key={space.uid} space={space} />
              ))}
            </div>
          )}
        </>
      )}

      {showCreateModal === true && (
        <ModalEditSpace
          isOpen={showCreateModal}
          onRequestClose={() => setShowCreateModal(false)}
        />
      )}
    </div>
  );
};

export default Spaces;
