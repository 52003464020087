import classnames from 'classnames';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import {Environment} from 'conf/env';
import {
  PermissionsEvent,
  PermissionsPeople,
  PermissionsPoke,
  PermissionsPost,
  PermissionsSettings,
  PermissionsSpace,
  PermissionsSuccessTracker,
} from 'constants/permissions';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {hasPermissions} from 'helpers/permission';
import {useIsExtensionInstalled} from 'helpers/utils';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_BANNERS,
  ROUTE_BANNER_SETTINGS_WITH_ID,
  ROUTE_BANNER_WITH_ID,
  ROUTE_BUILDER_TRACKER,
  ROUTE_CHECKLISTS,
  ROUTE_CHECKLIST_SETTINGS_WITH_ID,
  ROUTE_CHECKLIST_WITH_ID,
  ROUTE_EVENTS,
  ROUTE_FEED,
  ROUTE_FEED_EVOLUTION,
  ROUTE_GET_STARTED,
  ROUTE_GET_STARTED_INSTALL,
  ROUTE_GET_STARTED_TRIAL,
  ROUTE_GET_STARTED_TRY,
  ROUTE_HINTS,
  ROUTE_HINT_SETTINGS_WITH_ID,
  ROUTE_HINT_WITH_ID,
  ROUTE_RESOURCE_CENTERS,
  ROUTE_RESOURCE_CENTER_SETTINGS_WITH_ID,
  ROUTE_RESOURCE_CENTER_WITH_ID,
  ROUTE_SEGMENTS,
  ROUTE_SETTINGS,
  ROUTE_SPACES,
  ROUTE_SPACE_WITH_ID,
  ROUTE_SUCCESS_TRACKER,
  ROUTE_SURVEYS,
  ROUTE_SURVEY_SETTINGS_WITH_ID,
  ROUTE_SURVEY_WITH_ID,
  ROUTE_TOURS,
  ROUTE_TOUR_SETTINGS_WITH_ID,
  ROUTE_TOUR_WITH_ID,
  ROUTE_TRACKER_REPORT,
  ROUTE_USERS,
  ROUTE_USERS_IMPORT_CSV,
  ROUTE_USERS_SEGMENT_WITH_ID,
} from 'router/routes.const';
import {generalSelector, miscSelector} from 'selectors';
import {
  F_EXTRA_FIRST_POKE_ADOPTION,
  F_EXTRA_FIRST_POKE_DISCOVERY,
  F_EXTRA_WENT_LIVE,
  isTrying,
} from 'services/project';
import {F_IS_TRIALING} from 'services/subscription';
// Not importing styles here are they are already imported in the src/_Layouts.scss file
// import './_Styles.scss';
import {miscActions} from 'actions';
import axios from 'axios';
import Tooltip from 'components/Tooltip';
import {useTrialRemaining} from 'hooks/useTrialRemaining';
import AnimateHeight from 'react-animate-height';
import {CircularProgressbar} from 'react-circular-progressbar';
import {useQuery} from 'react-query';
import ModalEditSpace from 'scenes/Spaces/component/ModalEditSpace';
import {Swaler} from 'swaler';
import DropdownContent from './components/DropdownContent';

const logger = new Swaler('Navbar');

const Navbar = ({compact = false}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));
  const user = useSelector((state) => generalSelector.getUser(state));
  const projects = useSelector((state) => generalSelector.getProjects(state));
  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const navbarSpacesExpanded = useSelector((state) =>
    miscSelector.getNavbarSpacesExpanded(state)
  );

  const [showCreateSpaceModal, setShowCreateSpaceModal] = useState(false);
  const [spacesHovered, setSpacesHovered] = useState(false);
  const [disableTransition, setDisableTransition] = useState(false);

  useEffect(() => {
    if (spacesHovered) {
      setDisableTransition(true);

      setTimeout(() => {
        setDisableTransition(false);
      }, 300);
    }
  }, [spacesHovered]);

  const {daysRemaining} = useTrialRemaining();

  const lastOpenChangelog = localStorage.getItem('_JIMO_LAST_OPENED_AT');
  const {data: newContentInChangelog} = useQuery({
    queryKey: 'new-changelog-content',
    placeholderData: [],
    queryFn: () => {
      let date = new Date(0).toISOString();
      if (lastOpenChangelog != null && lastOpenChangelog?.length > 0) {
        try {
          date = new Date(lastOpenChangelog).toISOString();
        } catch (e) {
          logger.error('Error parsing lastOpenChangelog', e);
        }
      }
      axios
        .create()
        .get(
          `https://karabor-undercity.usejimo.com/project/6b86c47f-e5fb-48f8-be74-cbfde54167dd/unread?from=${date}`
        )
        .then((response) => response.data);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });

  const isActive = (paths) => {
    return [].concat(paths).includes(match.path);
  };

  const isActiveSpace = (spaceUid) => {
    return match.url === ROUTE_SPACE_WITH_ID(spaceUid);
  };

  const {isInstalled, check} = useIsExtensionInstalled();

  const classNames = classnames('jimo-navbar', {compact});

  const username =
    user.username.length <= 20
      ? user.username
      : user.username.slice(0, 15).concat('...');
  const gsTryDone = [
    hasFlags(
      [F_EXTRA_FIRST_POKE_ADOPTION, F_EXTRA_FIRST_POKE_DISCOVERY],
      project.extraFlags,
      true
    ),
  ];
  const gsInstallDone = [project.snippetInstalledAt != null];
  const wentLive = hasFlag(F_EXTRA_WENT_LIVE, project.extraFlags);
  const getStartedPercentage = Math.round(
    ([
      true,
      gsTryDone.some((t) => t === true),
      gsInstallDone.every((t) => t === true),
      wentLive,
    ].filter((t) => t === true).length /
      4) *
      100
  );
  const countStepsDone = [
    true,
    gsTryDone.some((t) => t === true),
    gsInstallDone.every((t) => t === true),
    wentLive,
  ].filter((t) => t === true).length;
  const countStepsTotal = 4;

  const pinnedSpaces = project.spaces?.sort(
    (a, b) => a.indexOrder - b.indexOrder
  );

  const canCreateSpace = hasPermissions(PermissionsSpace.CREATE_EDIT);

  useEffect(() => {
    window.addEventListener('focus', check);
    return () => {
      window.removeEventListener('focus', check);
    };
  }, []);

  return (
    <div className={classNames}>
      <div className="menu-wrapper">
        <Menu className="main-menu">
          <>
            <div className="section">
              <Dropdown
                position="right"
                trigger={
                  <div className="navbar-dropdown-trigger">
                    <div className="content-wrapper">
                      {compact === true ? (
                        <div className="content">
                          <div className="nav-project-logo-wrapper">
                            <div
                              className="nav-project-logo"
                              style={
                                project.portalLogoUrl != null
                                  ? {
                                      backgroundImage: `url(${project.portalLogoUrl})`,
                                    }
                                  : {
                                      backgroundColor: '#1260EB',
                                    }
                              }>
                              {project.portalLogoUrl == null && project.name[0]}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="content">
                            <div className="nav-project-logo-wrapper">
                              <div
                                className="nav-project-logo"
                                style={
                                  project.portalLogoUrl != null
                                    ? {
                                        backgroundImage: `url(${project.portalLogoUrl})`,
                                      }
                                    : {
                                        backgroundColor: '#1260EB',
                                      }
                                }>
                                {project.portalLogoUrl == null &&
                                  project.name[0]}
                              </div>
                            </div>
                            <div className="names">
                              <div className="project subtitle-4 n-800">
                                {project.name}
                              </div>
                            </div>
                          </div>
                          <i className="icon-chevron-bottom n-800" />
                        </>
                      )}
                    </div>
                  </div>
                }
                on={['hover']}
                triggerStyle={null}
                contentClassName="navbar-dropdown-content dropdown-content"
                className="menu-dropdown"
                withGlobalBg
                nested>
                <DropdownContent
                  user={user}
                  project={project}
                  projects={projects}
                  projectMember={projectMember}
                  history={history}
                />
              </Dropdown>
              {(hasFlag(F_IS_TRIALING, project.subscription.extraFlags) ||
                getStartedPercentage < 100) && (
                <Link
                  to={{
                    pathname: ROUTE_GET_STARTED,
                    state: {expandGetStarted: true},
                  }}>
                  <MenuItem
                    icon={
                      <div className="icon-wrapper">
                        <div className="circular-progress-wrapper">
                          <CircularProgressbar
                            value={(countStepsDone / countStepsTotal) * 100}
                            maxValue={100}
                            strokeWidth={16}
                            styles={{
                              path: {
                                // Path color
                                stroke: `#1260EB`,
                                // Customize transition animation
                                transition: 'stroke-dashoffset 0.5s ease 0s',
                              },
                              // Customize the circle behind the path, i.e. the "total progress"
                              trail: {
                                // Trail color
                                stroke: '#E5E5E5',
                              },
                            }}
                          />
                        </div>
                      </div>
                    }
                    iconPosition="left"
                    active={isActive([
                      ROUTE_GET_STARTED,
                      ROUTE_GET_STARTED_TRY,
                      ROUTE_GET_STARTED_INSTALL,
                      ROUTE_GET_STARTED_TRIAL,
                    ])}
                    className="navbar-get-started">
                    {compact === false && (
                      <div className="text-icon-wrapper">
                        {isTrying(project.subscription) === true
                          ? `${daysRemaining} days left`
                          : 'Get started'}
                        <div className="progress-label">
                          {countStepsDone} of {countStepsTotal}
                        </div>
                      </div>
                    )}
                  </MenuItem>
                </Link>
              )}
            </div>
          </>

          <Divider />
          <div className="section">
            {hasPermissions(PermissionsPoke.ACCESS) && (
              <Link to={ROUTE_TOURS}>
                <MenuItem
                  className="navbar-item-tour"
                  icon={classnames(
                    'isax',
                    isActive([
                      ROUTE_TOURS,
                      ROUTE_TOUR_WITH_ID(),
                      ROUTE_TOUR_SETTINGS_WITH_ID(),
                    ])
                      ? 'isax-routing-25'
                      : 'isax-routing-2'
                  )}
                  iconPosition="left"
                  active={isActive([
                    ROUTE_TOURS,
                    ROUTE_TOUR_WITH_ID(),
                    ROUTE_TOUR_SETTINGS_WITH_ID(),
                  ])}>
                  {compact === false && 'Tours & Modals'}
                </MenuItem>
              </Link>
            )}
            {hasPermissions(PermissionsPoke.ACCESS) && (
              <Link to={ROUTE_SURVEYS}>
                <MenuItem
                  icon={classnames(
                    'isax',
                    isActive([
                      ROUTE_SURVEYS,
                      ROUTE_SURVEY_WITH_ID(),
                      ROUTE_SURVEY_SETTINGS_WITH_ID(),
                    ])
                      ? 'isax-note-215'
                      : 'isax-note-2'
                  )}
                  iconPosition="left"
                  active={isActive([
                    ROUTE_SURVEYS,
                    ROUTE_SURVEY_WITH_ID(),
                    ROUTE_SURVEY_SETTINGS_WITH_ID(),
                  ])}>
                  {compact === false && 'Surveys'}
                </MenuItem>
              </Link>
            )}
            {hasPermissions(PermissionsPoke.ACCESS) && (
              <Link to={ROUTE_BANNERS}>
                <MenuItem
                  icon={classnames(
                    isActive([
                      ROUTE_BANNERS,
                      ROUTE_BANNER_WITH_ID(),
                      ROUTE_BANNER_SETTINGS_WITH_ID(),
                    ])
                      ? 'icon-banner-active'
                      : 'icon-banner-default'
                  )}
                  iconPosition="left"
                  active={isActive([
                    ROUTE_BANNERS,
                    ROUTE_BANNER_WITH_ID(),
                    ROUTE_BANNER_SETTINGS_WITH_ID(),
                  ])}>
                  {compact === false && 'Banners'}
                </MenuItem>
              </Link>
            )}
            {hasPermissions(PermissionsPoke.ACCESS) && (
              <Link to={ROUTE_HINTS}>
                <MenuItem
                  icon={classnames(
                    isActive([
                      ROUTE_HINTS,
                      ROUTE_HINT_WITH_ID(),
                      ROUTE_HINT_SETTINGS_WITH_ID(),
                    ])
                      ? 'isax isax-notification-15'
                      : 'isax isax-notification-1'
                  )}
                  iconPosition="left"
                  active={isActive([
                    ROUTE_HINTS,
                    ROUTE_HINT_WITH_ID(),
                    ROUTE_HINT_SETTINGS_WITH_ID(),
                  ])}>
                  {compact === false && 'Hints'}
                </MenuItem>
              </Link>
            )}
          </div>
          <Divider />
          <div className="section">
            {hasPermissions(PermissionsPoke.ACCESS) && (
              <Link to={ROUTE_CHECKLISTS}>
                <MenuItem
                  icon={classnames(
                    isActive([
                      ROUTE_CHECKLISTS,
                      ROUTE_CHECKLIST_WITH_ID(),
                      ROUTE_CHECKLIST_SETTINGS_WITH_ID(),
                    ])
                      ? 'isax isax-task-square5'
                      : 'isax isax-task-square'
                  )}
                  iconPosition="left"
                  active={isActive([
                    ROUTE_CHECKLISTS,
                    ROUTE_CHECKLIST_WITH_ID(),
                    ROUTE_CHECKLIST_SETTINGS_WITH_ID(),
                  ])}>
                  {compact === false && 'Checklists'}
                </MenuItem>
              </Link>
            )}
            {hasPermissions(PermissionsPoke.ACCESS) && (
              <Link to={ROUTE_RESOURCE_CENTERS}>
                <MenuItem
                  icon={classnames(
                    isActive([
                      ROUTE_RESOURCE_CENTERS,
                      ROUTE_RESOURCE_CENTER_WITH_ID(),
                      ROUTE_RESOURCE_CENTER_SETTINGS_WITH_ID(),
                    ])
                      ? 'isax isax-directbox-notif5'
                      : 'isax isax-directbox-notif'
                  )}
                  iconPosition="left"
                  active={isActive([
                    ROUTE_RESOURCE_CENTERS,
                    ROUTE_RESOURCE_CENTER_WITH_ID(),
                    ROUTE_RESOURCE_CENTER_SETTINGS_WITH_ID(),
                  ])}>
                  {compact === false && 'Resource Centers'}
                </MenuItem>
              </Link>
            )}
            {hasPermissions(PermissionsPost.ACCESS) && (
              <Link to={ROUTE_FEED}>
                <MenuItem
                  icon={classnames(
                    'isax',
                    isActive([ROUTE_FEED, ROUTE_FEED_EVOLUTION()])
                      ? 'isax-slider-vertical5'
                      : 'isax-slider-vertical-1'
                  )}
                  className="navbar-item-changelog"
                  iconPosition="left"
                  active={isActive([ROUTE_FEED, ROUTE_FEED_EVOLUTION()])}>
                  {compact === false && 'Changelog'}
                </MenuItem>
              </Link>
            )}
          </div>
          {hasPermissions(PermissionsSpace.ACCESS) && (
            <>
              <Divider />
              <div className="section" style={{gap: 0}}>
                <Link to={ROUTE_SPACES}>
                  <MenuItem
                    onMouseEnter={() => setSpacesHovered(true)}
                    onMouseLeave={() => setSpacesHovered(false)}
                    className="menu-item-spaces"
                    icon={
                      spacesHovered === true && compact !== true ? (
                        <div
                          className={classnames('expand-wrapper', {
                            'is-expanded': navbarSpacesExpanded === true,
                          })}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            dispatch(
                              miscActions.setNavbarSpacesExpanded(
                                !navbarSpacesExpanded
                              )
                            );
                          }}>
                          <i
                            className="isax isax-arrow-right-25"
                            style={{
                              transition: disableTransition && 'none',
                            }}
                          />
                        </div>
                      ) : (
                        classnames(
                          isActive([ROUTE_SPACES]) ||
                            (isActive([ROUTE_SPACE_WITH_ID()]) &&
                              (!pinnedSpaces?.some((space) =>
                                isActiveSpace(space.uid)
                              ) ||
                                navbarSpacesExpanded !== true))
                            ? 'isax isax-element-35'
                            : 'isax isax-element-3'
                        )
                      )
                    }
                    iconPosition="left"
                    active={
                      isActive([ROUTE_SPACES]) ||
                      (isActive([ROUTE_SPACE_WITH_ID()]) &&
                        (!pinnedSpaces?.some((space) =>
                          isActiveSpace(space.uid)
                        ) ||
                          navbarSpacesExpanded !== true))
                    }>
                    {compact === false && (
                      <>
                        Spaces
                        {canCreateSpace && (
                          <div
                            className="add-space-wrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              setShowCreateSpaceModal(true);
                            }}>
                            <i className="isax isax-add" />
                          </div>
                        )}
                      </>
                    )}
                  </MenuItem>
                </Link>

                <AnimateHeight
                  height={navbarSpacesExpanded === true ? 'auto' : 0}
                  duration={300}>
                  <div
                    className="space-list-wrapper"
                    style={{
                      marginTop: pinnedSpaces?.length > 0 ? 4 : 0,
                    }}>
                    {pinnedSpaces?.map((space) => (
                      <Link to={ROUTE_SPACE_WITH_ID(space.uid)}>
                        <MenuItem
                          icon={
                            <div className="icon-wrapper space-icon-wrapper">
                              {space.icon}
                            </div>
                          }
                          active={isActiveSpace(space.uid)}>
                          {compact === false && (
                            <div className="space-name-wrapper">
                              {space.name}
                            </div>
                          )}
                        </MenuItem>
                      </Link>
                    ))}
                  </div>
                </AnimateHeight>
              </div>
            </>
          )}
          <Divider />
          <div className="section">
            {(hasPermissions(PermissionsSuccessTracker.ACCESS) ||
              hasPermissions(PermissionsEvent.ACCESS)) && (
              <Link
                to={
                  hasPermissions(PermissionsSuccessTracker.ACCESS)
                    ? ROUTE_SUCCESS_TRACKER
                    : ROUTE_EVENTS
                }>
                <MenuItem
                  icon={classnames(
                    'isax',
                    isActive([
                      ROUTE_SUCCESS_TRACKER,
                      ROUTE_BUILDER_TRACKER(),
                      ROUTE_TRACKER_REPORT(),
                      ROUTE_EVENTS,
                    ])
                      ? 'isax-chart-215'
                      : 'isax-chart-2'
                  )}
                  iconPosition="left"
                  active={isActive([
                    ROUTE_SUCCESS_TRACKER,
                    ROUTE_BUILDER_TRACKER(),
                    ROUTE_TRACKER_REPORT(),
                    ROUTE_EVENTS,
                  ])}
                  className="menu-item-success-tracker">
                  {compact === false && 'Success Tracker'}
                </MenuItem>
              </Link>
            )}
            {hasPermissions(PermissionsPeople.ACCESS) && (
              <Link to={ROUTE_USERS}>
                <MenuItem
                  icon={classnames(
                    'isax',
                    isActive([
                      ROUTE_USERS,
                      ROUTE_SEGMENTS,
                      ROUTE_USERS_SEGMENT_WITH_ID(),
                      ROUTE_USERS_IMPORT_CSV,
                    ])
                      ? 'isax-profile-2user5'
                      : 'isax-profile-2user'
                  )}
                  iconPosition="left"
                  active={isActive([
                    ROUTE_USERS,
                    ROUTE_SEGMENTS,
                    ROUTE_USERS_SEGMENT_WITH_ID(),
                    ROUTE_USERS_IMPORT_CSV,
                  ])}>
                  {compact === false && 'Users & Segments'}
                </MenuItem>
              </Link>
            )}
          </div>
          {hasPermissions(PermissionsSettings.ACCESS) && (
            <>
              <Divider />
              <div className="section">
                {hasPermissions(PermissionsSettings.ACCESS) && (
                  <Link to={ROUTE_SETTINGS}>
                    <MenuItem
                      className="navbar-item-settings"
                      icon={classnames(
                        'isax',
                        isActive([ROUTE_SETTINGS]) === true
                          ? 'isax-setting-25'
                          : 'isax-setting-2'
                      )}
                      iconPosition="left"
                      active={isActive([ROUTE_SETTINGS])}>
                      {compact === false && 'Settings'}
                    </MenuItem>
                  </Link>
                )}
              </div>
            </>
          )}
        </Menu>
        <Menu className="bottom-menu">
          <div className="support-menu-wrapper">
            <Tooltip
              dark
              className="support-menu-tooltip"
              position="top left"
              offsetY={8}
              trigger={
                <div>
                  <MenuItem
                    icon="isax isax-like-1"
                    iconPosition="left"
                    onClick={() =>
                      window.jimo.push([
                        'do',
                        'widget:open',
                        {page: 'feedback'},
                      ])
                    }
                  />
                </div>
              }>
              Give feedback
            </Tooltip>
            <Tooltip
              dark
              className="support-menu-tooltip"
              position="top"
              offsetY={8}
              trigger={
                <div>
                  <MenuItem
                    className={
                      newContentInChangelog?.length > 0
                        ? 'badge-new-content'
                        : ''
                    }
                    icon="isax isax-notification"
                    iconPosition="left"
                    onClick={() => window.jimo.push(['do', 'widget:open'])}
                  />
                </div>
              }>
              What's new?
            </Tooltip>

            <Tooltip
              dark
              className="support-menu-tooltip"
              position="top"
              offsetY={8}
              trigger={
                <a
                  href="https://help.usejimo.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <MenuItem icon="isax isax-book" iconPosition="left" />
                </a>
              }>
              Documentation
            </Tooltip>
            {hasFlag(F_IS_TRIALING, project.subscription.extraFlags) ===
              false &&
              getStartedPercentage === 100 && (
                <Tooltip
                  dark
                  className="support-menu-tooltip"
                  position="top"
                  offsetY={8}
                  trigger={
                    <Link to={ROUTE_GET_STARTED}>
                      <MenuItem
                        icon="icon-question-circle-o"
                        iconPosition="left"
                      />
                    </Link>
                  }>
                  Get started
                </Tooltip>
              )}
          </div>
          <div className="app-version">version {Environment.APP_VERSION}</div>
        </Menu>
      </div>

      {showCreateSpaceModal === true && (
        <ModalEditSpace
          isOpen={showCreateSpaceModal}
          onRequestClose={() => setShowCreateSpaceModal(false)}
        />
      )}
    </div>
  );
};

export default Navbar;
