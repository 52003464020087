import classNames from 'classnames';
import Button from 'components/Button';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import InputGroup from 'components/Input';
import Label from 'components/Label';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useEffect, useRef, useState} from 'react';
import {FixedSizeList} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  PokeAudience,
  PokeDetails,
  PokeEnvironments,
  PokePreview,
} from 'scenes/Pushes/components/PushesTable';
import {evolutionService, spaceService} from 'services';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  F_OPTION_SHOW_ON_PORTAL,
  F_OPTION_V2,
} from 'services/evolution';
import {Swaler} from 'swaler';
import './_Styles.scss';
import Sidebar from './components/Sidebar';

const logger = new Swaler('ModalAddExperience');

export const EXPERIENCES_VALUES_PER_PAGE = 20;

export const ModalAddExperienceContent = ({
  space = null,
  experiencesToAdd = [],
  setExperiencesToAdd = () => {},
}) => {
  const [type, setType] = useState(null);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [values, setValues] = useState({
    data: [],
    skip: 0,
    take: EXPERIENCES_VALUES_PER_PAGE,
    total: 0,
  });
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const cancelCtrl = useRef(null);
  const searchTimerRef = useRef(null);

  // Debounce search input
  useEffect(() => {
    if (searchTimerRef.current) {
      clearTimeout(searchTimerRef.current);
    }

    searchTimerRef.current = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      if (searchTimerRef.current) {
        clearTimeout(searchTimerRef.current);
      }
    };
  }, [search]);

  useEffect(() => {
    const setup = async () => {
      if (cancelCtrl.current != null) {
        cancelCtrl.current.abort();
      }
      setValues({
        data: [],
        skip: 0,
        take: EXPERIENCES_VALUES_PER_PAGE,
        total: 0,
      });
      await fetchEvolutions(0, true);
    };

    setup();
  }, [type, debouncedSearch]);

  const fetchEvolutions = async (pageParam = page, reset = false) => {
    cancelCtrl.current = new AbortController();
    setIsLoading(true);
    const oldPage = page;
    setPage(pageParam);

    try {
      const res = await evolutionService.getEvolutions(
        {
          ...(type != null ? {types: [type]} : {}),
          ...(debouncedSearch != null && debouncedSearch.length > 0
            ? {name: debouncedSearch}
            : {}),
          onlyPokes: true,
          relations: [
            'steps',
            'steps.only-first',
            'segments',
            'analytics',
            'tourSteps',
            'tourSteps.only-first',
            'tags',
            'environments',
            'space',
          ],
          take: EXPERIENCES_VALUES_PER_PAGE,
          skip: pageParam * EXPERIENCES_VALUES_PER_PAGE,
          orderBy: 'space',
        },
        {signal: cancelCtrl.current.signal}
      );

      setValues((prevState) => ({
        ...res,
        ...(reset === false ? {data: [...prevState.data, ...res.data]} : {}),
      }));
      // setViewEvolutionCount(res.total);
      setIsLoading(false);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }

      const {code, title, message, actions} = errorHelpers.parseError(err);

      setPage(oldPage);
      logger.error('Fetch evolutions failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
      setIsLoading(false);
    }
  };

  const sortedPokes = values.data.sort((a, b) => {
    if (a.space == null && b.space != null) {
      return -1;
    }
    if (a.space != null && b.space == null) {
      return 1;
    }
    if (a.space != null && b.space != null) {
      return b.space.uid.localeCompare(a.space.uid);
    }
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  const isItemLoaded = (index) => index < sortedPokes.length;

  const Item = ({index, style, data}) => {
    if (!isItemLoaded(index) && index < values.total) {
      return (
        <div className="experience" key={index} style={style}>
          <DefaultLoader />
        </div>
      );
    }
    const experience = data[index];

    if (experience == null) {
      return <></>;
    }

    const isSelected = experiencesToAdd.includes(experience.uid);

    const {
      uid: experienceId,
      title,
      createdAt,
      optionsFlags,
      onTheFlySegment,
      segments,
      analytics,
      isDraft,
      state,
      lastStepChangeAt,
      expiresAt,
      tags,
      type,
      space: spaceExperience,
    } = experience;

    experience.tourSteps?.forEach((s) => {
      const [tourIndexOrder] = (s.tourStepInfo || '0;0;0').split(';');
      s.tourIndexOrder = parseInt(tourIndexOrder, 10);
    });
    experience.tourSteps?.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);

    const shownOnPortal = hasFlag(F_OPTION_SHOW_ON_PORTAL, optionsFlags);

    const audience =
      onTheFlySegment != null
        ? 'On the fly segmentation'
        : segments?.length > 0
        ? segments.map((s) => s.name).join(', ')
        : 'All users';

    const isLive =
      isDraft !== true && (state === EVOLUTION_STATE_LIVE || state == null);
    const isPaused = isDraft !== true && state === EVOLUTION_STATE_PAUSED;
    const isScheduled =
      isDraft !== true && dayjs(lastStepChangeAt).isAfter(dayjs());
    const isExpired =
      isDraft !== true &&
      expiresAt != null &&
      dayjs(expiresAt).isBefore(dayjs());
    const isOldVersionExperience =
      hasFlag(F_OPTION_V2, experience.optionsFlags) !== true;

    const previewPoke =
      experience?.tourSteps?.filter((ts) =>
        ts.steps?.some((s) => s.removed !== true)
      )[0] || experience;
    const isBoosted =
      hasFlag(F_OPTION_SHOW_ON_PORTAL, experience.optionsFlags) === true;

    previewPoke.steps = previewPoke.steps?.sort(
      (a, b) => a.indexOrder - b.indexOrder
    );

    return (
      <div
        className={classNames('experience', {})}
        key={experienceId}
        style={{
          ...style,
          top: `${parseFloat(style.top)}px`,
          height: `${parseFloat(style.height)}px`,
          left: `${parseFloat(style.left)}px`,
        }}
        onClick={() => {
          if (spaceExperience != null) {
            return;
          }

          if (isSelected) {
            setExperiencesToAdd(
              experiencesToAdd.filter((id) => id !== experienceId)
            );
          } else {
            setExperiencesToAdd([...experiencesToAdd, experienceId]);
          }
        }}>
        <PokePreview
          poke={experience}
          previewPoke={previewPoke}
          isBoosted={isBoosted}
          isOldVersionPoke={isOldVersionExperience}
          isExpired={isExpired}
          isScheduled={isScheduled}
          isLive={isLive}
          isPaused={isPaused}
        />
        <div className="infos">
          <div className="experience-title-wrapper">
            <div className="experience-title subtitle-3 n-800">
              {title} {shownOnPortal && <i className="isax isax-link-2" />}
            </div>
            <div className="created-at body-4 n-600">
              Created {dayjs(createdAt).fromNow()}
            </div>
          </div>
          <PokeDetails tags={tags} />
          <div className="audience-tags-wrapper">
            <PokeAudience
              audience={audience}
              analytics={analytics}
              experienceType={type}
            />
            <PokeEnvironments environments={experience.environments} />
          </div>
        </div>
        <div className="checkbox-wrapper">
          {spaceExperience != null ? (
            <>
              {spaceExperience.uid === space?.uid ? (
                <Label
                  primary
                  size="x-small"
                  type="neutral"
                  iconLeft="isax isax-clipboard-tick">
                  Linked to this space
                </Label>
              ) : (
                <Label
                  primary
                  size="x-small"
                  type="neutral"
                  iconLeft="isax isax-folder-open">
                  Linked to {spaceExperience.name}
                </Label>
              )}
            </>
          ) : (
            <>
              {isSelected ? (
                <i className="icon-checkbox"></i>
              ) : (
                <i className="icon-checkbox-o"></i>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="modal-add-experience-content">
      <Sidebar type={type} onChange={setType} />

      <div className="list-experience-wrapper">
        <div className="search-wrapper">
          <InputGroup
            labelTextLeft={<i className="icon-search"></i>}
            placeholder="Search for an experience"
            muted
            value={search}
            onChange={({target}) => setSearch(target.value)}
          />
        </div>

        <div className="experiences-loader-wrapper">
          {isLoading && values.data?.length === 0 ? (
            <div className="loader-wrapper">
              <DefaultLoader />
            </div>
          ) : values.data.length === 0 ? (
            <div className="no-experiences-wrapper">
              <EmptyStateBlock
                img={EmptyStateImgs.EmptyResults}
                title="No results found"
                description="Refine your filters to find what you're looking for"
              />
            </div>
          ) : (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={values.total}
              loadMoreItems={() => {
                if (values.data.length < values.total && isLoading !== true) {
                  fetchEvolutions(page + 1);
                }
              }}
              threshold={1}>
              {({onItemsRendered, ref}) => (
                <FixedSizeList
                  className="experiences-list"
                  height={500}
                  width="100%"
                  itemCount={values.total}
                  itemSize={132}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  itemData={sortedPokes}>
                  {Item}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </div>
      </div>
    </div>
  );
};

const ModalAddExperience = ({isOpen, onRequestClose, space}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [experiencesToAdd, setExperiencesToAdd] = useState([]);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      await spaceService.addExperienceToSpace(space.uid, {
        experienceIds: experiencesToAdd,
      });
      toastSuccess('Experiences added to space');
      onRequestClose();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to add experiences to space with error', code);
      return toastDanger([title, message], {actions});
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-add-experience"
      animationOnOpen="scale-in-center"
      title={`Add experiences to ${space?.name}`}
      footer={
        <>
          <Button thin onClick={() => onRequestClose()} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            primary
            thin
            onClick={handleConfirm}
            loading={isSubmitting}
            disabled={experiencesToAdd.length === 0}>
            Add {experiencesToAdd.length} experience
            {experiencesToAdd.length > 1 ? 's' : ''}
          </Button>
        </>
      }>
      <ModalAddExperienceContent
        space={space}
        experiencesToAdd={experiencesToAdd}
        setExperiencesToAdd={setExperiencesToAdd}
      />
    </Modal>
  );
};

export default ModalAddExperience;
