import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import ColorPickerInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ColorPickerInput';
import Shadow from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Shadow';
import ColorItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/ColorItem';
import RadiusItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/RadiusItem';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';

const ChecklistStyle = ({style, updateStyle: setStyle}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      style: {
        ...style,
        ...newStyle,
      },
    });
  };

  return (
    <>
      <ColorItem
        title="Background"
        value={style.backgroundColor}
        onChange={(v) => {
          updateStyle({backgroundColor: v});
        }}
      />
      <ColorItem
        title="Border"
        value={style.borderColor}
        onChange={(v) => {
          updateStyle({borderColor: v});
        }}
        colorPickerProps={{
          erasable: true,
        }}
      />
      <SectionItem title="Shadow" contentClassName="shadow-picker">
        <PopupSetting
          trigger={
            <ClickableInput
              value={style.shadow?.color}
              leftLabel={
                <span
                  className="preview-shadow-color"
                  style={{background: style.shadow?.color || '#FFFFFF'}}
                />
              }
              erasable={true}
              onErase={() =>
                updateStyle({
                  shadow: {color: null, opacity: 0, x: 0, y: 0, blur: 0},
                })
              }
            />
          }
          title="Shadow"
          content={
            <Shadow
              value={style.shadow}
              onChange={(value) => updateStyle({shadow: value})}
            />
          }
        />
      </SectionItem>
      <SectionItem title="Overlay">
        <ColorPickerInput
          title="Overlay"
          value={style.overlay}
          onChange={(value) => updateStyle({overlay: value})}
          erasable
          inputProps={{
            onClick: () => {
              if (style.overlay == null) {
                updateStyle({overlay: '#00000029'});
              }
            },
          }}
        />
      </SectionItem>
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) => {
          updateStyle({borderRadius: value});
        }}
        max={50}
      />
    </>
  );
};

export default ChecklistStyle;
