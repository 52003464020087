import {toastDanger} from 'components/Toaster';
import {GlobalContext} from 'contextes/Global';
import {errorHelpers} from 'helpers';
import {useJimoIdentify} from 'helpers/jimoOnJimo';
import {useContext, useState} from 'react';
import {subscriptionService} from 'services';
import {canLaunchTrialExtension, isEligibleToTrial} from 'services/project';
import {
  ADDON_WHITE_LABEL_ID,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
} from 'services/subscription';
import {Swaler} from 'swaler';
import {useUpdatePlan} from './useUpdatePlan';

const logger = new Swaler('useStartTrial');

export const useStartTrial = ({
  onSuccess = () => {},
  onError = () => {},
  showWelcomeModal = true,
} = {}) => {
  const {setModalWelcomePlan} = useContext(GlobalContext);
  const {pushAttributes} = useJimoIdentify({forceIsIdentified: true});

  const {update} = useUpdatePlan();

  const [isStarting, setIsStarting] = useState(false);

  async function start({
    planId,
    mau,
    isTrialExtension = canLaunchTrialExtension(planId),
  }) {
    const addons = [PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(planId)
      ? [ADDON_WHITE_LABEL_ID]
      : [];

    if (planId == null) return;
    if (
      isEligibleToTrial(planId) === false &&
      canLaunchTrialExtension(planId) === false
    )
      return;

    setIsStarting(true);
    try {
      await subscriptionService.createOrUpdateStripeSubscription(
        {plan: planId, mau, addons},
        {
          isTrial: true,
          isTrialExtension,
        }
      );
      await update({planId, mau});
      onSuccess();
      pushAttributes({
        isTrialing: true,
      });
      if (showWelcomeModal === true) {
        setModalWelcomePlan({planId, mau, refreshProjectAfterClose: true});
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Launching trial failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
    } finally {
      setIsStarting(false);
    }
  }

  return {
    start,
    isStarting,
  };
};
