import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import DefaultLoader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import PushesTable from 'scenes/Pushes/components/PushesTable';
import {ViewEditor} from 'scenes/Pushes/components/ViewEditor';
import {PushesContext} from 'scenes/Pushes/context';
import {
  environmentService,
  evolutionService,
  segmentService,
  tagService,
} from 'services';
import {EVOLUTION_STATE_DRAFT} from 'services/evolution';
import {TAG_CONTEXT_VIEW} from 'services/tag';
import {Swaler} from 'swaler';
import './_Styles.scss';
import ExperienceDrawer from './components/ExperienceDrawer';

export const EXPERIENCES_VALUES_PER_PAGE = 20;

const logger = new Swaler('SpaceExperiences');

const SpaceExperiences = ({
  space,
  refetchSpace = () => {},
  shouldRefetchExperiences = false,
  setShouldRefetchExperiences = () => {},
}) => {
  const [view, setView] = useState({
    segments: [],
    tags: [],
    environments: [],
    types: [],
  });

  const [values, setValues] = useState({
    data: [],
    skip: 0,
    take: EXPERIENCES_VALUES_PER_PAGE,
    total: 0,
  });
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [experienceToRefresh, setExperienceToRefresh] = useState(null);
  const [experienceToOpen, setExperienceToOpen] = useState(null);

  const cancelCtrl = useRef(null);

  useEffect(() => {
    const setup = async () => {
      if (cancelCtrl.current != null) {
        cancelCtrl.current.abort();
      }
      setValues({
        data: values.data,
        skip: 0,
        take: EXPERIENCES_VALUES_PER_PAGE,
        total: 0,
      });
      await fetchEvolutions(0, true);
      setShouldRefetchExperiences(false);
    };

    setup();
  }, [view, space?.uid]);

  useEffect(() => {
    const refresh = async () => {
      const refreshedPoke = await evolutionService.getEvolutionById(
        experienceToRefresh,
        {
          relations: ['steps', 'segments', 'analytics', 'tourSteps', 'tags'],
        }
      );

      setValues((values) => ({
        ...values,
        data: values.data.map((e) =>
          e.uid === refreshedPoke.uid ? refreshedPoke : e
        ),
      }));
      setExperienceToRefresh(null);
    };

    if (experienceToRefresh != null) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceToRefresh]);

  useEffect(() => {
    const setup = async () => {
      if (cancelCtrl.current != null) {
        cancelCtrl.current.abort();
      }

      await fetchEvolutions(0, true);
      setShouldRefetchExperiences(false);
    };

    setup();
  }, [shouldRefetchExperiences]);

  // Fetch segments
  const {
    data: segments = [],
    refetch: refetchSegments,
    isRefetching: isRefetchingSegments,
    isLoading: isLoadingSegments,
  } = useQuery({
    queryKey: ['segments'],
    queryFn: () => segmentService.getSegments(),
    refetchOnWindowFocus: false,
  });

  // Fetch tags
  const {
    data: tags = [],
    refetch: refetchTags,
    isRefetching: isRefetchingTags,
    isLoading: isLoadingTags,
  } = useQuery({
    queryKey: ['tags'],
    queryFn: () => tagService.getTags({contexts: [TAG_CONTEXT_VIEW]}),
    refetchOnWindowFocus: false,
  });

  // Fetch environments
  const {
    data: environments = [],
    refetch: refetchEnvironments,
    isRefetching: isRefetchingEnvironments,
    isLoading: isLoadingEnvironments,
  } = useQuery({
    queryKey: ['environments'],
    queryFn: () => environmentService.getEnvironments(),
    refetchOnWindowFocus: false,
  });

  const fetchEvolutions = async (pageParam = page, reset = false) => {
    cancelCtrl.current = new AbortController();
    setIsLoading(true);
    const oldPage = page;
    setPage(pageParam);

    try {
      const res = await evolutionService.getEvolutions(
        {
          // Filters from the selected view
          states: view.states,
          segments: view.segments,
          tags: view.tags,
          environments: view.environments,
          types: view.types,

          // Handle draft a little different
          ...(view.states == null ||
          view.states.length === 0 ||
          view.states.includes(EVOLUTION_STATE_DRAFT)
            ? {}
            : {removeDrafts: true}),
          spaces: [space.uid],
          onlyPokes: true,
          relations: [
            'steps',
            'steps.only-first',
            'segments',
            'analytics',
            'tourSteps',
            'tourSteps.only-first',
            'tags',
            'environments',
          ],
          take: EXPERIENCES_VALUES_PER_PAGE,
          skip: pageParam * EXPERIENCES_VALUES_PER_PAGE,
          orderBy: 'createdAt',
        },
        {signal: cancelCtrl.current.signal}
      );

      setValues((prevState) => ({
        ...res,
        ...(reset === false ? {data: [...prevState.data, ...res.data]} : {}),
      }));
      // setViewEvolutionCount(res.total);
      setIsLoading(false);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }

      const {code, title, message, actions} = errorHelpers.parseError(err);

      setPage(oldPage);
      logger.error('Fetch evolutions failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
      setIsLoading(false);
    }
  };

  const evolutions = values.data;

  const applyChanges = (data) => {
    setView((view) => ({
      ...view,
      ...data,
    }));
  };

  return (
    <PushesContext.Provider
      value={{
        view,
        segments,
        tags,
        environments,
        // viewedPoke,
        // pokeToRefresh,

        setViewId: () => {},
        setViewChanges: () => {},
        setViewEvolutionCount: () => {},
        setPokeToRefresh: () => {},
        refetchViews: () => {},
        refetchSegments,
        refetchTags,
        refetchEnvironments,

        applyChanges,
        resetChanges: () => {},
        hasChanges: () => {},

        handleSave: () => {},
      }}>
      <div className="space-experiences">
        <ViewEditor inSpace />
        {isLoading && values.data?.length === 0 ? (
          <div className="loader-wrapper">
            <DefaultLoader />
          </div>
        ) : values.data.length === 0 ? (
          <EmptyStateBlock
            img={EmptyStateImgs.EmptyResults}
            title={'No results found'}
            description="Refine your filters to find what you're looking for"
          />
        ) : (
          <PushesTable
            pushes={evolutions}
            onPushDelete={(pokeId) => {
              setValues((values) => ({
                ...values,
                data: values.data.filter((poke) => poke.uid !== pokeId),
                total: values.total - 1,
              }));
            }}
            onPushRemoveFromSpace={(pokeId) => {
              setValues((values) => ({
                ...values,
                data: values.data.filter((poke) => poke.uid !== pokeId),
                total: values.total - 1,
              }));
              refetchSpace();
            }}
            onPushUpdateState={(experienceId, state) => {
              setValues((values) => ({
                ...values,
                data: values.data.map((experience) => {
                  if (experience.uid === experienceId) {
                    return {
                      ...experience,
                      state,
                    };
                  }
                  return experience;
                }),
              }));
              setExperienceToRefresh(experienceId);
            }}
            onPushClick={(experienceId) => {
              setExperienceToOpen(experienceId);
            }}
            isLoading={isLoading && values.data.length === 0}
            page={page}
            total={values.total}
            fetchValues={fetchEvolutions}
            paddingSize={72}
            inSpace
          />
        )}
      </div>

      {experienceToOpen != null && (
        <ExperienceDrawer
          isOpen={experienceToOpen != null}
          onRequestClose={() => setExperienceToOpen(null)}
          experience={experienceToOpen}
        />
      )}
    </PushesContext.Provider>
  );
};

export default SpaceExperiences;
