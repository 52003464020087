import {Icon} from '@iconify/react';
import classNames from 'classnames';
import React, {useContext, useEffect} from 'react';
import ClickableBlockOverlay from '../../../../../Poke/components/ClickableBlockOverlay';
import {BLOCK_RESOURCE_CENTER_ACTION_CTA} from '../../../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../../../Poke/context';
import {
  RESOURCE_CENTER_ACTION_CTA_TYPE_ICON,
  RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT,
  RESOURCE_CENTER_ICON_SOURCE_CUSTOM,
  RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
  RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
} from '../../../../constants';
import './_Styles.scss';

const ResourceCenterActionCta = ({block}) => {
  const {
    onBlockSelected,
    selectedBlock,
    inBuilder,
    language,
    addFontFamily,
    onContainerSelected,
    selectedContainerId,
    onTriggerActions,
    blocks,
    onActionClick,
  } = useContext(PokeStateContext);

  const {value, style} = block;
  const {
    type,
    // Icon
    iconPaddingTop,
    iconPaddingBottom,
    iconPaddingLeft,
    iconPaddingRight,
    iconBorderRadius,
    iconFontSize,
    iconColor,
    iconBackgroundColor,
    iconBorderColor,
    iconShadow,
    iconSource,
    iconName,
    iconUrl,

    // Text
    textPaddingTop,
    textPaddingBottom,
    textPaddingLeft,
    textPaddingRight,
    textFontFamily,
    textFontSize,
    textFontWeight,
    textBorderRadius,
    textFontColor,
    textBackgroundColor,
    textBorderColor,
    textShadow,

    ...restStyles
  } = style;

  const isIcon = type === RESOURCE_CENTER_ACTION_CTA_TYPE_ICON;
  const isText = type === RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT;

  const {x, y, blur, color} = isIcon ? iconShadow ?? {} : textShadow ?? {};
  const boxShadow = color != null ? `${x}px ${y}px ${blur}px ${color}` : 'none';

  useEffect(() => {
    if (isText) {
      addFontFamily(textFontFamily);
    }
  }, [isText, textFontFamily]);

  const parentBlock = blocks.find((b) => b.uid === block.parentBlockId);

  const handleClick = () => {
    onTriggerActions(parentBlock?.actions || []);
    onActionClick({block: parentBlock});
  };

  const translation = block.translations?.find((t) => t.language === language);

  const icon =
    iconSource === RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR ? (
      <Icon icon={`ph:${iconName}`} />
    ) : iconSource === RESOURCE_CENTER_ICON_SOURCE_TWEMOJI ? (
      <Icon icon={`twemoji:${iconName}`} />
    ) : iconSource === RESOURCE_CENTER_ICON_SOURCE_CUSTOM ? (
      <img src={iconUrl} alt={iconName} />
    ) : null;

  return (
    <div
      className={classNames('block-resource-center-action-cta', {
        'poke-block-clickable': inBuilder === true,
        selected:
          selectedBlock === BLOCK_RESOURCE_CENTER_ACTION_CTA &&
          selectedContainerId === block.parentBlockId,
      })}
      style={{
        ...restStyles,
        ...(isText
          ? {
              fontFamily: `${textFontFamily}, ui-sans-serif, system-ui, sans-serif`,
              color: textFontColor,
              backgroundColor: textBackgroundColor,
              paddingTop: `${textPaddingTop}px`,
              paddingBottom: `${textPaddingBottom}px`,
              paddingLeft: `${textPaddingLeft}px`,
              paddingRight: `${textPaddingRight}px`,
              borderRadius: textBorderRadius,
              ...(textBorderColor != null && {
                borderColor: textBorderColor,
              }),
              flexGrow: 'fill',
              fontSize: textFontSize,
              fontWeight: textFontWeight,
            }
          : {}),
        ...(isIcon
          ? {
              backgroundColor: iconBackgroundColor,
              paddingTop: `${iconPaddingTop}px`,
              paddingBottom: `${iconPaddingBottom}px`,
              paddingLeft: `${iconPaddingLeft}px`,
              paddingRight: `${iconPaddingRight}px`,
              borderRadius: iconBorderRadius,
              ...(iconBorderColor != null && {
                borderColor: iconBorderColor,
              }),
              flexGrow: 'unset',
            }
          : {}),
        boxShadow,
      }}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();

          onBlockSelected(BLOCK_RESOURCE_CENTER_ACTION_CTA);
          onContainerSelected(block.parentBlockId);
          return;
        }

        if (isText === true) {
          handleClick();
        }
      }}>
      {isIcon ? (
        <div
          className="icon"
          style={{
            height: iconFontSize,
            width: iconFontSize,
            color: iconColor,
          }}>
          {icon}
        </div>
      ) : isText ? (
        translation != null ? (
          translation.value
        ) : (
          value
        )
      ) : null}
      <ClickableBlockOverlay />
    </div>
  );
};

export default ResourceCenterActionCta;
