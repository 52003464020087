import Button from 'components/Button';
import {func, object} from 'prop-types';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import './_Styles.scss';
import TemplatePreview from './components/TemplatePreview';

const propTypes = {
  template: object.isRequired,
  onUseTemplateClick: func,
  onBack: func,
};

const defaultProps = {
  onUseTemplateClick: null,
  onBack: () => {},
};

const TemplateDetails = ({template, onUseTemplateClick, onBack}) => {
  let {experienceType, title} = template;

  let type = null;
  let icon = null;

  if (experienceType === EVOLUTION_TYPE_TOUR) {
    type = 'Tours';
    icon = 'isax isax-routing-2';
  } else if (experienceType === EVOLUTION_TYPE_SURVEY) {
    type = 'Surveys';
    icon = 'isax isax-note-2';
  } else if (experienceType === EVOLUTION_TYPE_BANNER) {
    type = 'Banners';
    icon = 'icon-banner-default';
  } else if (experienceType === EVOLUTION_TYPE_HINT) {
    type = 'Hints';
    icon = 'isax isax-notification-1';
  }

  return (
    <div className="template-details">
      <div className="details-wrapper">
        <div className="content">
          <div className="main-infos">
            <div className="experience-type subtitle-3 b-400">
              <i className={icon} />
              {type}
            </div>
            <div className="template-name title-3 n-800">{title}</div>
          </div>
          <div className="secondary-infos">
            <div className="when-to-wrapper">
              <div className="when-to-label subtitle-3 n-800">
                When to use this?
              </div>
              <div className="when-to body-3 n-700">{template.description}</div>
            </div>
            <div className="use-cases-label body-3 n-700">
              Use cases:{' '}
              <span className="n-800">{template.tags?.join(', ')}</span>
            </div>
          </div>
        </div>
        <div className="btn-actions">
          <Button
            className="back-btn"
            secondary
            iconLeft="icon-chevron-left"
            onClick={() => onBack()}>
            Back
          </Button>
          <Button
            className="use-template-btn"
            primary
            onClick={() => onUseTemplateClick()}>
            Use template
          </Button>
        </div>
      </div>
      <TemplatePreview template={template} />
    </div>
  );
};

TemplateDetails.propTypes = propTypes;
TemplateDetails.defaultProps = defaultProps;

export default TemplateDetails;
