import classNames from 'classnames';
import React, {useContext} from 'react';
import Checklist from '../../../Checklist';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {PokeContext, PokeStateContext} from '../../../Poke/context';
import './_Styles.scss';
import {overrideChecklistStyle} from './utils';
import {BLOCK_RESOURCE_CENTER_EMBEDDED_CHECKLIST} from '../../../Poke/constants/blocks';

const ResourceCenterEmbeddedChecklist = () => {
  const {embeddedChecklist} = useContext(PokeContext);
  const {
    onBlockSelected,
    onContainerSelected,
    selectedBlock,
    inBuilder,
    addFontFamily,
    blocks,
    onTriggerActions,

    completedTasks = [],
    onEmbeddedChecklistCtaClick,
    onEmbeddedChecklistDismissed,
    onEmbeddedChecklistCompleted,
    onEmbeddedChecklistTaskCompleted,
    onEmbeddedChecklistTaskClicked,
    onEmbeddedChecklistTaskSkipped,
    onEmbeddedChecklistClose,
  } = useContext(PokeStateContext);

  if (embeddedChecklist == null) {
    return null;
  }

  const styledChecklist = overrideChecklistStyle(blocks, embeddedChecklist);

  return (
    <div
      className={classNames('block-resource-center-embedded-checklist', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
        'in-builder': inBuilder === true,
      })}
      onClick={(e) => {
        e.stopPropagation();

        if (inBuilder === true) {
          onBlockSelected(BLOCK_RESOURCE_CENTER_EMBEDDED_CHECKLIST);
          onContainerSelected(null);
          return;
        }
      }}>
      <PokeContext.Provider
        value={{
          poke: {
            ...styledChecklist,
            steps: styledChecklist?.steps?.sort(
              (a, b) => a.inderOrder - b.indexOrder
            ),
          },
          currentStepIndex: 0,
        }}>
        <Checklist
          inBuilder={inBuilder}
          addFontFamily={addFontFamily}
          isEmbedded
          completedTasks={completedTasks}
          onChecklistCompleted={onEmbeddedChecklistCompleted}
          onChecklistDismissed={onEmbeddedChecklistDismissed}
          onClose={onEmbeddedChecklistClose}
          onTaskCompleted={onEmbeddedChecklistTaskCompleted}
          onTaskSkipped={onEmbeddedChecklistTaskSkipped}
          onTaskClicked={onEmbeddedChecklistTaskClicked}
          onTriggerActions={onTriggerActions}
          onCtaClick={onEmbeddedChecklistCtaClick}
        />
      </PokeContext.Provider>
      <ClickableBlockOverlay />
    </div>
  );
};

export default ResourceCenterEmbeddedChecklist;
