export const phosphorIcons = [
  {
    "name": "acorn",
    "pascal_name": "Acorn"
  },
  {
    "name": "address-book",
    "pascal_name": "AddressBook"
  },
  {
    "name": "address-book-tabs",
    "pascal_name": "AddressBookTabs"
  },
  {
    "name": "air-traffic-control",
    "pascal_name": "AirTrafficControl"
  },
  {
    "name": "airplane",
    "pascal_name": "Airplane"
  },
  {
    "name": "airplane-in-flight",
    "pascal_name": "AirplaneInFlight"
  },
  {
    "name": "airplane-landing",
    "pascal_name": "AirplaneLanding"
  },
  {
    "name": "airplane-takeoff",
    "pascal_name": "AirplaneTakeoff"
  },
  {
    "name": "airplane-taxiing",
    "pascal_name": "AirplaneTaxiing"
  },
  {
    "name": "airplane-tilt",
    "pascal_name": "AirplaneTilt"
  },
  {
    "name": "airplay",
    "pascal_name": "Airplay"
  },
  {
    "name": "alarm",
    "pascal_name": "Alarm"
  },
  {
    "name": "alien",
    "pascal_name": "Alien"
  },
  {
    "name": "align-bottom",
    "pascal_name": "AlignBottom"
  },
  {
    "name": "align-bottom-simple",
    "pascal_name": "AlignBottomSimple"
  },
  {
    "name": "align-center-horizontal",
    "pascal_name": "AlignCenterHorizontal"
  },
  {
    "name": "align-center-horizontal-simple",
    "pascal_name": "AlignCenterHorizontalSimple"
  },
  {
    "name": "align-center-vertical",
    "pascal_name": "AlignCenterVertical"
  },
  {
    "name": "align-center-vertical-simple",
    "pascal_name": "AlignCenterVerticalSimple"
  },
  {
    "name": "align-left",
    "pascal_name": "AlignLeft"
  },
  {
    "name": "align-left-simple",
    "pascal_name": "AlignLeftSimple"
  },
  {
    "name": "align-right",
    "pascal_name": "AlignRight"
  },
  {
    "name": "align-right-simple",
    "pascal_name": "AlignRightSimple"
  },
  {
    "name": "align-top",
    "pascal_name": "AlignTop"
  },
  {
    "name": "align-top-simple",
    "pascal_name": "AlignTopSimple"
  },
  {
    "name": "amazon-logo",
    "pascal_name": "AmazonLogo"
  },
  {
    "name": "ambulance",
    "pascal_name": "Ambulance"
  },
  {
    "name": "anchor",
    "pascal_name": "Anchor"
  },
  {
    "name": "anchor-simple",
    "pascal_name": "AnchorSimple"
  },
  {
    "name": "android-logo",
    "pascal_name": "AndroidLogo"
  },
  {
    "name": "angle",
    "pascal_name": "Angle"
  },
  {
    "name": "angular-logo",
    "pascal_name": "AngularLogo"
  },
  {
    "name": "aperture",
    "pascal_name": "Aperture"
  },
  {
    "name": "app-store-logo",
    "pascal_name": "AppStoreLogo"
  },
  {
    "name": "app-window",
    "pascal_name": "AppWindow"
  },
  {
    "name": "apple-logo",
    "pascal_name": "AppleLogo"
  },
  {
    "name": "apple-podcasts-logo",
    "pascal_name": "ApplePodcastsLogo"
  },
  {
    "name": "approximate-equals",
    "pascal_name": "ApproximateEquals"
  },
  {
    "name": "archive",
    "pascal_name": "Archive"
  },
  {
    "name": "armchair",
    "pascal_name": "Armchair"
  },
  {
    "name": "arrow-arc-left",
    "pascal_name": "ArrowArcLeft"
  },
  {
    "name": "arrow-arc-right",
    "pascal_name": "ArrowArcRight"
  },
  {
    "name": "arrow-bend-double-up-left",
    "pascal_name": "ArrowBendDoubleUpLeft"
  },
  {
    "name": "arrow-bend-double-up-right",
    "pascal_name": "ArrowBendDoubleUpRight"
  },
  {
    "name": "arrow-bend-down-left",
    "pascal_name": "ArrowBendDownLeft"
  },
  {
    "name": "arrow-bend-down-right",
    "pascal_name": "ArrowBendDownRight"
  },
  {
    "name": "arrow-bend-left-down",
    "pascal_name": "ArrowBendLeftDown"
  },
  {
    "name": "arrow-bend-left-up",
    "pascal_name": "ArrowBendLeftUp"
  },
  {
    "name": "arrow-bend-right-down",
    "pascal_name": "ArrowBendRightDown"
  },
  {
    "name": "arrow-bend-right-up",
    "pascal_name": "ArrowBendRightUp"
  },
  {
    "name": "arrow-bend-up-left",
    "pascal_name": "ArrowBendUpLeft"
  },
  {
    "name": "arrow-bend-up-right",
    "pascal_name": "ArrowBendUpRight"
  },
  {
    "name": "arrow-circle-down",
    "pascal_name": "ArrowCircleDown"
  },
  {
    "name": "arrow-circle-down-left",
    "pascal_name": "ArrowCircleDownLeft"
  },
  {
    "name": "arrow-circle-down-right",
    "pascal_name": "ArrowCircleDownRight"
  },
  {
    "name": "arrow-circle-left",
    "pascal_name": "ArrowCircleLeft"
  },
  {
    "name": "arrow-circle-right",
    "pascal_name": "ArrowCircleRight"
  },
  {
    "name": "arrow-circle-up",
    "pascal_name": "ArrowCircleUp"
  },
  {
    "name": "arrow-circle-up-left",
    "pascal_name": "ArrowCircleUpLeft"
  },
  {
    "name": "arrow-circle-up-right",
    "pascal_name": "ArrowCircleUpRight"
  },
  {
    "name": "arrow-clockwise",
    "pascal_name": "ArrowClockwise"
  },
  {
    "name": "arrow-counter-clockwise",
    "pascal_name": "ArrowCounterClockwise"
  },
  {
    "name": "arrow-down",
    "pascal_name": "ArrowDown"
  },
  {
    "name": "arrow-down-left",
    "pascal_name": "ArrowDownLeft"
  },
  {
    "name": "arrow-down-right",
    "pascal_name": "ArrowDownRight"
  },
  {
    "name": "arrow-elbow-down-left",
    "pascal_name": "ArrowElbowDownLeft"
  },
  {
    "name": "arrow-elbow-down-right",
    "pascal_name": "ArrowElbowDownRight"
  },
  {
    "name": "arrow-elbow-left",
    "pascal_name": "ArrowElbowLeft"
  },
  {
    "name": "arrow-elbow-left-down",
    "pascal_name": "ArrowElbowLeftDown"
  },
  {
    "name": "arrow-elbow-left-up",
    "pascal_name": "ArrowElbowLeftUp"
  },
  {
    "name": "arrow-elbow-right",
    "pascal_name": "ArrowElbowRight"
  },
  {
    "name": "arrow-elbow-right-down",
    "pascal_name": "ArrowElbowRightDown"
  },
  {
    "name": "arrow-elbow-right-up",
    "pascal_name": "ArrowElbowRightUp"
  },
  {
    "name": "arrow-elbow-up-left",
    "pascal_name": "ArrowElbowUpLeft"
  },
  {
    "name": "arrow-elbow-up-right",
    "pascal_name": "ArrowElbowUpRight"
  },
  {
    "name": "arrow-fat-down",
    "pascal_name": "ArrowFatDown"
  },
  {
    "name": "arrow-fat-left",
    "pascal_name": "ArrowFatLeft"
  },
  {
    "name": "arrow-fat-line-down",
    "pascal_name": "ArrowFatLineDown"
  },
  {
    "name": "arrow-fat-line-left",
    "pascal_name": "ArrowFatLineLeft"
  },
  {
    "name": "arrow-fat-line-right",
    "pascal_name": "ArrowFatLineRight"
  },
  {
    "name": "arrow-fat-line-up",
    "pascal_name": "ArrowFatLineUp"
  },
  {
    "name": "arrow-fat-lines-down",
    "pascal_name": "ArrowFatLinesDown"
  },
  {
    "name": "arrow-fat-lines-left",
    "pascal_name": "ArrowFatLinesLeft"
  },
  {
    "name": "arrow-fat-lines-right",
    "pascal_name": "ArrowFatLinesRight"
  },
  {
    "name": "arrow-fat-lines-up",
    "pascal_name": "ArrowFatLinesUp"
  },
  {
    "name": "arrow-fat-right",
    "pascal_name": "ArrowFatRight"
  },
  {
    "name": "arrow-fat-up",
    "pascal_name": "ArrowFatUp"
  },
  {
    "name": "arrow-left",
    "pascal_name": "ArrowLeft"
  },
  {
    "name": "arrow-line-down",
    "pascal_name": "ArrowLineDown"
  },
  {
    "name": "arrow-line-down-left",
    "pascal_name": "ArrowLineDownLeft"
  },
  {
    "name": "arrow-line-down-right",
    "pascal_name": "ArrowLineDownRight"
  },
  {
    "name": "arrow-line-left",
    "pascal_name": "ArrowLineLeft"
  },
  {
    "name": "arrow-line-right",
    "pascal_name": "ArrowLineRight"
  },
  {
    "name": "arrow-line-up",
    "pascal_name": "ArrowLineUp"
  },
  {
    "name": "arrow-line-up-left",
    "pascal_name": "ArrowLineUpLeft"
  },
  {
    "name": "arrow-line-up-right",
    "pascal_name": "ArrowLineUpRight"
  },
  {
    "name": "arrow-right",
    "pascal_name": "ArrowRight"
  },
  {
    "name": "arrow-square-down",
    "pascal_name": "ArrowSquareDown"
  },
  {
    "name": "arrow-square-down-left",
    "pascal_name": "ArrowSquareDownLeft"
  },
  {
    "name": "arrow-square-down-right",
    "pascal_name": "ArrowSquareDownRight"
  },
  {
    "name": "arrow-square-in",
    "pascal_name": "ArrowSquareIn"
  },
  {
    "name": "arrow-square-left",
    "pascal_name": "ArrowSquareLeft"
  },
  {
    "name": "arrow-square-out",
    "pascal_name": "ArrowSquareOut"
  },
  {
    "name": "arrow-square-right",
    "pascal_name": "ArrowSquareRight"
  },
  {
    "name": "arrow-square-up",
    "pascal_name": "ArrowSquareUp"
  },
  {
    "name": "arrow-square-up-left",
    "pascal_name": "ArrowSquareUpLeft"
  },
  {
    "name": "arrow-square-up-right",
    "pascal_name": "ArrowSquareUpRight"
  },
  {
    "name": "arrow-u-down-left",
    "pascal_name": "ArrowUDownLeft"
  },
  {
    "name": "arrow-u-down-right",
    "pascal_name": "ArrowUDownRight"
  },
  {
    "name": "arrow-u-left-down",
    "pascal_name": "ArrowULeftDown"
  },
  {
    "name": "arrow-u-left-up",
    "pascal_name": "ArrowULeftUp"
  },
  {
    "name": "arrow-u-right-down",
    "pascal_name": "ArrowURightDown"
  },
  {
    "name": "arrow-u-right-up",
    "pascal_name": "ArrowURightUp"
  },
  {
    "name": "arrow-u-up-left",
    "pascal_name": "ArrowUUpLeft"
  },
  {
    "name": "arrow-u-up-right",
    "pascal_name": "ArrowUUpRight"
  },
  {
    "name": "arrow-up",
    "pascal_name": "ArrowUp"
  },
  {
    "name": "arrow-up-left",
    "pascal_name": "ArrowUpLeft"
  },
  {
    "name": "arrow-up-right",
    "pascal_name": "ArrowUpRight"
  },
  {
    "name": "arrows-clockwise",
    "pascal_name": "ArrowsClockwise"
  },
  {
    "name": "arrows-counter-clockwise",
    "pascal_name": "ArrowsCounterClockwise"
  },
  {
    "name": "arrows-down-up",
    "pascal_name": "ArrowsDownUp"
  },
  {
    "name": "arrows-horizontal",
    "pascal_name": "ArrowsHorizontal"
  },
  {
    "name": "arrows-in",
    "pascal_name": "ArrowsIn"
  },
  {
    "name": "arrows-in-cardinal",
    "pascal_name": "ArrowsInCardinal"
  },
  {
    "name": "arrows-in-line-horizontal",
    "pascal_name": "ArrowsInLineHorizontal"
  },
  {
    "name": "arrows-in-line-vertical",
    "pascal_name": "ArrowsInLineVertical"
  },
  {
    "name": "arrows-in-simple",
    "pascal_name": "ArrowsInSimple"
  },
  {
    "name": "arrows-left-right",
    "pascal_name": "ArrowsLeftRight"
  },
  {
    "name": "arrows-merge",
    "pascal_name": "ArrowsMerge"
  },
  {
    "name": "arrows-out",
    "pascal_name": "ArrowsOut"
  },
  {
    "name": "arrows-out-cardinal",
    "pascal_name": "ArrowsOutCardinal"
  },
  {
    "name": "arrows-out-line-horizontal",
    "pascal_name": "ArrowsOutLineHorizontal"
  },
  {
    "name": "arrows-out-line-vertical",
    "pascal_name": "ArrowsOutLineVertical"
  },
  {
    "name": "arrows-out-simple",
    "pascal_name": "ArrowsOutSimple"
  },
  {
    "name": "arrows-split",
    "pascal_name": "ArrowsSplit"
  },
  {
    "name": "arrows-vertical",
    "pascal_name": "ArrowsVertical"
  },
  {
    "name": "article",
    "pascal_name": "Article"
  },
  {
    "name": "article-medium",
    "pascal_name": "ArticleMedium"
  },
  {
    "name": "article-ny-times",
    "pascal_name": "ArticleNyTimes"
  },
  {
    "name": "asclepius",
    "pascal_name": "Asclepius",
    "alias": {
      "name": "caduceus",
      "pascal_name": "Caduceus"
    }
  },
  {
    "name": "asterisk",
    "pascal_name": "Asterisk"
  },
  {
    "name": "asterisk-simple",
    "pascal_name": "AsteriskSimple"
  },
  {
    "name": "at",
    "pascal_name": "At"
  },
  {
    "name": "atom",
    "pascal_name": "Atom"
  },
  {
    "name": "avocado",
    "pascal_name": "Avocado"
  },
  {
    "name": "axe",
    "pascal_name": "Axe"
  },
  {
    "name": "baby",
    "pascal_name": "Baby"
  },
  {
    "name": "baby-carriage",
    "pascal_name": "BabyCarriage"
  },
  {
    "name": "backpack",
    "pascal_name": "Backpack"
  },
  {
    "name": "backspace",
    "pascal_name": "Backspace"
  },
  {
    "name": "bag",
    "pascal_name": "Bag"
  },
  {
    "name": "bag-simple",
    "pascal_name": "BagSimple"
  },
  {
    "name": "balloon",
    "pascal_name": "Balloon"
  },
  {
    "name": "bandaids",
    "pascal_name": "Bandaids"
  },
  {
    "name": "bank",
    "pascal_name": "Bank"
  },
  {
    "name": "barbell",
    "pascal_name": "Barbell"
  },
  {
    "name": "barcode",
    "pascal_name": "Barcode"
  },
  {
    "name": "barn",
    "pascal_name": "Barn"
  },
  {
    "name": "barricade",
    "pascal_name": "Barricade"
  },
  {
    "name": "baseball",
    "pascal_name": "Baseball"
  },
  {
    "name": "baseball-cap",
    "pascal_name": "BaseballCap"
  },
  {
    "name": "baseball-helmet",
    "pascal_name": "BaseballHelmet"
  },
  {
    "name": "basket",
    "pascal_name": "Basket"
  },
  {
    "name": "basketball",
    "pascal_name": "Basketball"
  },
  {
    "name": "bathtub",
    "pascal_name": "Bathtub"
  },
  {
    "name": "battery-charging",
    "pascal_name": "BatteryCharging"
  },
  {
    "name": "battery-charging-vertical",
    "pascal_name": "BatteryChargingVertical"
  },
  {
    "name": "battery-empty",
    "pascal_name": "BatteryEmpty"
  },
  {
    "name": "battery-full",
    "pascal_name": "BatteryFull"
  },
  {
    "name": "battery-high",
    "pascal_name": "BatteryHigh"
  },
  {
    "name": "battery-low",
    "pascal_name": "BatteryLow"
  },
  {
    "name": "battery-medium",
    "pascal_name": "BatteryMedium"
  },
  {
    "name": "battery-plus",
    "pascal_name": "BatteryPlus"
  },
  {
    "name": "battery-plus-vertical",
    "pascal_name": "BatteryPlusVertical"
  },
  {
    "name": "battery-vertical-empty",
    "pascal_name": "BatteryVerticalEmpty"
  },
  {
    "name": "battery-vertical-full",
    "pascal_name": "BatteryVerticalFull"
  },
  {
    "name": "battery-vertical-high",
    "pascal_name": "BatteryVerticalHigh"
  },
  {
    "name": "battery-vertical-low",
    "pascal_name": "BatteryVerticalLow"
  },
  {
    "name": "battery-vertical-medium",
    "pascal_name": "BatteryVerticalMedium"
  },
  {
    "name": "battery-warning",
    "pascal_name": "BatteryWarning"
  },
  {
    "name": "battery-warning-vertical",
    "pascal_name": "BatteryWarningVertical"
  },
  {
    "name": "beach-ball",
    "pascal_name": "BeachBall"
  },
  {
    "name": "beanie",
    "pascal_name": "Beanie"
  },
  {
    "name": "bed",
    "pascal_name": "Bed"
  },
  {
    "name": "beer-bottle",
    "pascal_name": "BeerBottle"
  },
  {
    "name": "beer-stein",
    "pascal_name": "BeerStein"
  },
  {
    "name": "behance-logo",
    "pascal_name": "BehanceLogo"
  },
  {
    "name": "bell",
    "pascal_name": "Bell"
  },
  {
    "name": "bell-ringing",
    "pascal_name": "BellRinging"
  },
  {
    "name": "bell-simple",
    "pascal_name": "BellSimple"
  },
  {
    "name": "bell-simple-ringing",
    "pascal_name": "BellSimpleRinging"
  },
  {
    "name": "bell-simple-slash",
    "pascal_name": "BellSimpleSlash"
  },
  {
    "name": "bell-simple-z",
    "pascal_name": "BellSimpleZ"
  },
  {
    "name": "bell-slash",
    "pascal_name": "BellSlash"
  },
  {
    "name": "bell-z",
    "pascal_name": "BellZ"
  },
  {
    "name": "belt",
    "pascal_name": "Belt"
  },
  {
    "name": "bezier-curve",
    "pascal_name": "BezierCurve"
  },
  {
    "name": "bicycle",
    "pascal_name": "Bicycle"
  },
  {
    "name": "binary",
    "pascal_name": "Binary"
  },
  {
    "name": "binoculars",
    "pascal_name": "Binoculars"
  },
  {
    "name": "biohazard",
    "pascal_name": "Biohazard"
  },
  {
    "name": "bird",
    "pascal_name": "Bird"
  },
  {
    "name": "blueprint",
    "pascal_name": "Blueprint"
  },
  {
    "name": "bluetooth",
    "pascal_name": "Bluetooth"
  },
  {
    "name": "bluetooth-connected",
    "pascal_name": "BluetoothConnected"
  },
  {
    "name": "bluetooth-slash",
    "pascal_name": "BluetoothSlash"
  },
  {
    "name": "bluetooth-x",
    "pascal_name": "BluetoothX"
  },
  {
    "name": "boat",
    "pascal_name": "Boat"
  },
  {
    "name": "bomb",
    "pascal_name": "Bomb"
  },
  {
    "name": "bone",
    "pascal_name": "Bone"
  },
  {
    "name": "book",
    "pascal_name": "Book"
  },
  {
    "name": "book-bookmark",
    "pascal_name": "BookBookmark"
  },
  {
    "name": "book-open",
    "pascal_name": "BookOpen"
  },
  {
    "name": "book-open-text",
    "pascal_name": "BookOpenText"
  },
  {
    "name": "book-open-user",
    "pascal_name": "BookOpenUser"
  },
  {
    "name": "bookmark",
    "pascal_name": "Bookmark"
  },
  {
    "name": "bookmark-simple",
    "pascal_name": "BookmarkSimple"
  },
  {
    "name": "bookmarks",
    "pascal_name": "Bookmarks"
  },
  {
    "name": "bookmarks-simple",
    "pascal_name": "BookmarksSimple"
  },
  {
    "name": "books",
    "pascal_name": "Books"
  },
  {
    "name": "boot",
    "pascal_name": "Boot"
  },
  {
    "name": "boules",
    "pascal_name": "Boules"
  },
  {
    "name": "bounding-box",
    "pascal_name": "BoundingBox"
  },
  {
    "name": "bowl-food",
    "pascal_name": "BowlFood"
  },
  {
    "name": "bowl-steam",
    "pascal_name": "BowlSteam"
  },
  {
    "name": "bowling-ball",
    "pascal_name": "BowlingBall"
  },
  {
    "name": "box-arrow-down",
    "pascal_name": "BoxArrowDown",
    "alias": {
      "name": "archive-box",
      "pascal_name": "ArchiveBox"
    }
  },
  {
    "name": "box-arrow-up",
    "pascal_name": "BoxArrowUp"
  },
  {
    "name": "boxing-glove",
    "pascal_name": "BoxingGlove"
  },
  {
    "name": "brackets-angle",
    "pascal_name": "BracketsAngle"
  },
  {
    "name": "brackets-curly",
    "pascal_name": "BracketsCurly"
  },
  {
    "name": "brackets-round",
    "pascal_name": "BracketsRound"
  },
  {
    "name": "brackets-square",
    "pascal_name": "BracketsSquare"
  },
  {
    "name": "brain",
    "pascal_name": "Brain"
  },
  {
    "name": "brandy",
    "pascal_name": "Brandy"
  },
  {
    "name": "bread",
    "pascal_name": "Bread"
  },
  {
    "name": "bridge",
    "pascal_name": "Bridge"
  },
  {
    "name": "briefcase",
    "pascal_name": "Briefcase"
  },
  {
    "name": "briefcase-metal",
    "pascal_name": "BriefcaseMetal"
  },
  {
    "name": "broadcast",
    "pascal_name": "Broadcast"
  },
  {
    "name": "broom",
    "pascal_name": "Broom"
  },
  {
    "name": "browser",
    "pascal_name": "Browser"
  },
  {
    "name": "browsers",
    "pascal_name": "Browsers"
  },
  {
    "name": "bug",
    "pascal_name": "Bug"
  },
  {
    "name": "bug-beetle",
    "pascal_name": "BugBeetle"
  },
  {
    "name": "bug-droid",
    "pascal_name": "BugDroid"
  },
  {
    "name": "building",
    "pascal_name": "Building"
  },
  {
    "name": "building-apartment",
    "pascal_name": "BuildingApartment"
  },
  {
    "name": "building-office",
    "pascal_name": "BuildingOffice"
  },
  {
    "name": "buildings",
    "pascal_name": "Buildings"
  },
  {
    "name": "bulldozer",
    "pascal_name": "Bulldozer"
  },
  {
    "name": "bus",
    "pascal_name": "Bus"
  },
  {
    "name": "butterfly",
    "pascal_name": "Butterfly"
  },
  {
    "name": "cable-car",
    "pascal_name": "CableCar"
  },
  {
    "name": "cactus",
    "pascal_name": "Cactus"
  },
  {
    "name": "cake",
    "pascal_name": "Cake"
  },
  {
    "name": "calculator",
    "pascal_name": "Calculator"
  },
  {
    "name": "calendar",
    "pascal_name": "Calendar"
  },
  {
    "name": "calendar-blank",
    "pascal_name": "CalendarBlank"
  },
  {
    "name": "calendar-check",
    "pascal_name": "CalendarCheck"
  },
  {
    "name": "calendar-dot",
    "pascal_name": "CalendarDot"
  },
  {
    "name": "calendar-dots",
    "pascal_name": "CalendarDots"
  },
  {
    "name": "calendar-heart",
    "pascal_name": "CalendarHeart"
  },
  {
    "name": "calendar-minus",
    "pascal_name": "CalendarMinus"
  },
  {
    "name": "calendar-plus",
    "pascal_name": "CalendarPlus"
  },
  {
    "name": "calendar-slash",
    "pascal_name": "CalendarSlash"
  },
  {
    "name": "calendar-star",
    "pascal_name": "CalendarStar"
  },
  {
    "name": "calendar-x",
    "pascal_name": "CalendarX"
  },
  {
    "name": "call-bell",
    "pascal_name": "CallBell"
  },
  {
    "name": "camera",
    "pascal_name": "Camera"
  },
  {
    "name": "camera-plus",
    "pascal_name": "CameraPlus"
  },
  {
    "name": "camera-rotate",
    "pascal_name": "CameraRotate"
  },
  {
    "name": "camera-slash",
    "pascal_name": "CameraSlash"
  },
  {
    "name": "campfire",
    "pascal_name": "Campfire"
  },
  {
    "name": "car",
    "pascal_name": "Car"
  },
  {
    "name": "car-battery",
    "pascal_name": "CarBattery"
  },
  {
    "name": "car-profile",
    "pascal_name": "CarProfile"
  },
  {
    "name": "car-simple",
    "pascal_name": "CarSimple"
  },
  {
    "name": "cardholder",
    "pascal_name": "Cardholder"
  },
  {
    "name": "cards",
    "pascal_name": "Cards"
  },
  {
    "name": "cards-three",
    "pascal_name": "CardsThree"
  },
  {
    "name": "caret-circle-double-down",
    "pascal_name": "CaretCircleDoubleDown"
  },
  {
    "name": "caret-circle-double-left",
    "pascal_name": "CaretCircleDoubleLeft"
  },
  {
    "name": "caret-circle-double-right",
    "pascal_name": "CaretCircleDoubleRight"
  },
  {
    "name": "caret-circle-double-up",
    "pascal_name": "CaretCircleDoubleUp"
  },
  {
    "name": "caret-circle-down",
    "pascal_name": "CaretCircleDown"
  },
  {
    "name": "caret-circle-left",
    "pascal_name": "CaretCircleLeft"
  },
  {
    "name": "caret-circle-right",
    "pascal_name": "CaretCircleRight"
  },
  {
    "name": "caret-circle-up",
    "pascal_name": "CaretCircleUp"
  },
  {
    "name": "caret-circle-up-down",
    "pascal_name": "CaretCircleUpDown"
  },
  {
    "name": "caret-double-down",
    "pascal_name": "CaretDoubleDown"
  },
  {
    "name": "caret-double-left",
    "pascal_name": "CaretDoubleLeft"
  },
  {
    "name": "caret-double-right",
    "pascal_name": "CaretDoubleRight"
  },
  {
    "name": "caret-double-up",
    "pascal_name": "CaretDoubleUp"
  },
  {
    "name": "caret-down",
    "pascal_name": "CaretDown"
  },
  {
    "name": "caret-left",
    "pascal_name": "CaretLeft"
  },
  {
    "name": "caret-line-down",
    "pascal_name": "CaretLineDown"
  },
  {
    "name": "caret-line-left",
    "pascal_name": "CaretLineLeft"
  },
  {
    "name": "caret-line-right",
    "pascal_name": "CaretLineRight"
  },
  {
    "name": "caret-line-up",
    "pascal_name": "CaretLineUp"
  },
  {
    "name": "caret-right",
    "pascal_name": "CaretRight"
  },
  {
    "name": "caret-up",
    "pascal_name": "CaretUp"
  },
  {
    "name": "caret-up-down",
    "pascal_name": "CaretUpDown"
  },
  {
    "name": "carrot",
    "pascal_name": "Carrot"
  },
  {
    "name": "cash-register",
    "pascal_name": "CashRegister"
  },
  {
    "name": "cassette-tape",
    "pascal_name": "CassetteTape"
  },
  {
    "name": "castle-turret",
    "pascal_name": "CastleTurret"
  },
  {
    "name": "cat",
    "pascal_name": "Cat"
  },
  {
    "name": "cell-signal-full",
    "pascal_name": "CellSignalFull"
  },
  {
    "name": "cell-signal-high",
    "pascal_name": "CellSignalHigh"
  },
  {
    "name": "cell-signal-low",
    "pascal_name": "CellSignalLow"
  },
  {
    "name": "cell-signal-medium",
    "pascal_name": "CellSignalMedium"
  },
  {
    "name": "cell-signal-none",
    "pascal_name": "CellSignalNone"
  },
  {
    "name": "cell-signal-slash",
    "pascal_name": "CellSignalSlash"
  },
  {
    "name": "cell-signal-x",
    "pascal_name": "CellSignalX"
  },
  {
    "name": "cell-tower",
    "pascal_name": "CellTower"
  },
  {
    "name": "certificate",
    "pascal_name": "Certificate"
  },
  {
    "name": "chair",
    "pascal_name": "Chair"
  },
  {
    "name": "chalkboard",
    "pascal_name": "Chalkboard"
  },
  {
    "name": "chalkboard-simple",
    "pascal_name": "ChalkboardSimple"
  },
  {
    "name": "chalkboard-teacher",
    "pascal_name": "ChalkboardTeacher"
  },
  {
    "name": "champagne",
    "pascal_name": "Champagne"
  },
  {
    "name": "charging-station",
    "pascal_name": "ChargingStation"
  },
  {
    "name": "chart-bar",
    "pascal_name": "ChartBar"
  },
  {
    "name": "chart-bar-horizontal",
    "pascal_name": "ChartBarHorizontal"
  },
  {
    "name": "chart-donut",
    "pascal_name": "ChartDonut"
  },
  {
    "name": "chart-line",
    "pascal_name": "ChartLine"
  },
  {
    "name": "chart-line-down",
    "pascal_name": "ChartLineDown"
  },
  {
    "name": "chart-line-up",
    "pascal_name": "ChartLineUp"
  },
  {
    "name": "chart-pie",
    "pascal_name": "ChartPie"
  },
  {
    "name": "chart-pie-slice",
    "pascal_name": "ChartPieSlice"
  },
  {
    "name": "chart-polar",
    "pascal_name": "ChartPolar"
  },
  {
    "name": "chart-scatter",
    "pascal_name": "ChartScatter"
  },
  {
    "name": "chat",
    "pascal_name": "Chat"
  },
  {
    "name": "chat-centered",
    "pascal_name": "ChatCentered"
  },
  {
    "name": "chat-centered-dots",
    "pascal_name": "ChatCenteredDots"
  },
  {
    "name": "chat-centered-slash",
    "pascal_name": "ChatCenteredSlash"
  },
  {
    "name": "chat-centered-text",
    "pascal_name": "ChatCenteredText"
  },
  {
    "name": "chat-circle",
    "pascal_name": "ChatCircle"
  },
  {
    "name": "chat-circle-dots",
    "pascal_name": "ChatCircleDots"
  },
  {
    "name": "chat-circle-slash",
    "pascal_name": "ChatCircleSlash"
  },
  {
    "name": "chat-circle-text",
    "pascal_name": "ChatCircleText"
  },
  {
    "name": "chat-dots",
    "pascal_name": "ChatDots"
  },
  {
    "name": "chat-slash",
    "pascal_name": "ChatSlash"
  },
  {
    "name": "chat-teardrop",
    "pascal_name": "ChatTeardrop"
  },
  {
    "name": "chat-teardrop-dots",
    "pascal_name": "ChatTeardropDots"
  },
  {
    "name": "chat-teardrop-slash",
    "pascal_name": "ChatTeardropSlash"
  },
  {
    "name": "chat-teardrop-text",
    "pascal_name": "ChatTeardropText"
  },
  {
    "name": "chat-text",
    "pascal_name": "ChatText"
  },
  {
    "name": "chats",
    "pascal_name": "Chats"
  },
  {
    "name": "chats-circle",
    "pascal_name": "ChatsCircle"
  },
  {
    "name": "chats-teardrop",
    "pascal_name": "ChatsTeardrop"
  },
  {
    "name": "check",
    "pascal_name": "Check"
  },
  {
    "name": "check-circle",
    "pascal_name": "CheckCircle"
  },
  {
    "name": "check-fat",
    "pascal_name": "CheckFat"
  },
  {
    "name": "check-square",
    "pascal_name": "CheckSquare"
  },
  {
    "name": "check-square-offset",
    "pascal_name": "CheckSquareOffset"
  },
  {
    "name": "checkerboard",
    "pascal_name": "Checkerboard"
  },
  {
    "name": "checks",
    "pascal_name": "Checks"
  },
  {
    "name": "cheers",
    "pascal_name": "Cheers"
  },
  {
    "name": "cheese",
    "pascal_name": "Cheese"
  },
  {
    "name": "chef-hat",
    "pascal_name": "ChefHat"
  },
  {
    "name": "cherries",
    "pascal_name": "Cherries"
  },
  {
    "name": "church",
    "pascal_name": "Church"
  },
  {
    "name": "cigarette",
    "pascal_name": "Cigarette"
  },
  {
    "name": "cigarette-slash",
    "pascal_name": "CigaretteSlash"
  },
  {
    "name": "circle",
    "pascal_name": "Circle"
  },
  {
    "name": "circle-dashed",
    "pascal_name": "CircleDashed"
  },
  {
    "name": "circle-half",
    "pascal_name": "CircleHalf"
  },
  {
    "name": "circle-half-tilt",
    "pascal_name": "CircleHalfTilt"
  },
  {
    "name": "circle-notch",
    "pascal_name": "CircleNotch"
  },
  {
    "name": "circles-four",
    "pascal_name": "CirclesFour"
  },
  {
    "name": "circles-three",
    "pascal_name": "CirclesThree"
  },
  {
    "name": "circles-three-plus",
    "pascal_name": "CirclesThreePlus"
  },
  {
    "name": "circuitry",
    "pascal_name": "Circuitry"
  },
  {
    "name": "city",
    "pascal_name": "City"
  },
  {
    "name": "clipboard",
    "pascal_name": "Clipboard"
  },
  {
    "name": "clipboard-text",
    "pascal_name": "ClipboardText"
  },
  {
    "name": "clock",
    "pascal_name": "Clock"
  },
  {
    "name": "clock-afternoon",
    "pascal_name": "ClockAfternoon"
  },
  {
    "name": "clock-clockwise",
    "pascal_name": "ClockClockwise"
  },
  {
    "name": "clock-countdown",
    "pascal_name": "ClockCountdown"
  },
  {
    "name": "clock-counter-clockwise",
    "pascal_name": "ClockCounterClockwise"
  },
  {
    "name": "clock-user",
    "pascal_name": "ClockUser"
  },
  {
    "name": "closed-captioning",
    "pascal_name": "ClosedCaptioning"
  },
  {
    "name": "cloud",
    "pascal_name": "Cloud"
  },
  {
    "name": "cloud-arrow-down",
    "pascal_name": "CloudArrowDown"
  },
  {
    "name": "cloud-arrow-up",
    "pascal_name": "CloudArrowUp"
  },
  {
    "name": "cloud-check",
    "pascal_name": "CloudCheck"
  },
  {
    "name": "cloud-fog",
    "pascal_name": "CloudFog"
  },
  {
    "name": "cloud-lightning",
    "pascal_name": "CloudLightning"
  },
  {
    "name": "cloud-moon",
    "pascal_name": "CloudMoon"
  },
  {
    "name": "cloud-rain",
    "pascal_name": "CloudRain"
  },
  {
    "name": "cloud-slash",
    "pascal_name": "CloudSlash"
  },
  {
    "name": "cloud-snow",
    "pascal_name": "CloudSnow"
  },
  {
    "name": "cloud-sun",
    "pascal_name": "CloudSun"
  },
  {
    "name": "cloud-warning",
    "pascal_name": "CloudWarning"
  },
  {
    "name": "cloud-x",
    "pascal_name": "CloudX"
  },
  {
    "name": "clover",
    "pascal_name": "Clover"
  },
  {
    "name": "club",
    "pascal_name": "Club"
  },
  {
    "name": "coat-hanger",
    "pascal_name": "CoatHanger"
  },
  {
    "name": "coda-logo",
    "pascal_name": "CodaLogo"
  },
  {
    "name": "code",
    "pascal_name": "Code"
  },
  {
    "name": "code-block",
    "pascal_name": "CodeBlock"
  },
  {
    "name": "code-simple",
    "pascal_name": "CodeSimple"
  },
  {
    "name": "codepen-logo",
    "pascal_name": "CodepenLogo"
  },
  {
    "name": "codesandbox-logo",
    "pascal_name": "CodesandboxLogo"
  },
  {
    "name": "coffee",
    "pascal_name": "Coffee"
  },
  {
    "name": "coffee-bean",
    "pascal_name": "CoffeeBean"
  },
  {
    "name": "coin",
    "pascal_name": "Coin"
  },
  {
    "name": "coin-vertical",
    "pascal_name": "CoinVertical"
  },
  {
    "name": "coins",
    "pascal_name": "Coins"
  },
  {
    "name": "columns",
    "pascal_name": "Columns"
  },
  {
    "name": "columns-plus-left",
    "pascal_name": "ColumnsPlusLeft"
  },
  {
    "name": "columns-plus-right",
    "pascal_name": "ColumnsPlusRight"
  },
  {
    "name": "command",
    "pascal_name": "Command"
  },
  {
    "name": "compass",
    "pascal_name": "Compass"
  },
  {
    "name": "compass-rose",
    "pascal_name": "CompassRose"
  },
  {
    "name": "compass-tool",
    "pascal_name": "CompassTool"
  },
  {
    "name": "computer-tower",
    "pascal_name": "ComputerTower"
  },
  {
    "name": "confetti",
    "pascal_name": "Confetti"
  },
  {
    "name": "contactless-payment",
    "pascal_name": "ContactlessPayment"
  },
  {
    "name": "control",
    "pascal_name": "Control"
  },
  {
    "name": "cookie",
    "pascal_name": "Cookie"
  },
  {
    "name": "cooking-pot",
    "pascal_name": "CookingPot"
  },
  {
    "name": "copy",
    "pascal_name": "Copy"
  },
  {
    "name": "copy-simple",
    "pascal_name": "CopySimple"
  },
  {
    "name": "copyleft",
    "pascal_name": "Copyleft"
  },
  {
    "name": "copyright",
    "pascal_name": "Copyright"
  },
  {
    "name": "corners-in",
    "pascal_name": "CornersIn"
  },
  {
    "name": "corners-out",
    "pascal_name": "CornersOut"
  },
  {
    "name": "couch",
    "pascal_name": "Couch"
  },
  {
    "name": "court-basketball",
    "pascal_name": "CourtBasketball"
  },
  {
    "name": "cow",
    "pascal_name": "Cow"
  },
  {
    "name": "cowboy-hat",
    "pascal_name": "CowboyHat"
  },
  {
    "name": "cpu",
    "pascal_name": "Cpu"
  },
  {
    "name": "crane",
    "pascal_name": "Crane"
  },
  {
    "name": "crane-tower",
    "pascal_name": "CraneTower"
  },
  {
    "name": "credit-card",
    "pascal_name": "CreditCard"
  },
  {
    "name": "cricket",
    "pascal_name": "Cricket"
  },
  {
    "name": "crop",
    "pascal_name": "Crop"
  },
  {
    "name": "cross",
    "pascal_name": "Cross"
  },
  {
    "name": "crosshair",
    "pascal_name": "Crosshair"
  },
  {
    "name": "crosshair-simple",
    "pascal_name": "CrosshairSimple"
  },
  {
    "name": "crown",
    "pascal_name": "Crown"
  },
  {
    "name": "crown-cross",
    "pascal_name": "CrownCross"
  },
  {
    "name": "crown-simple",
    "pascal_name": "CrownSimple"
  },
  {
    "name": "cube",
    "pascal_name": "Cube"
  },
  {
    "name": "cube-focus",
    "pascal_name": "CubeFocus"
  },
  {
    "name": "cube-transparent",
    "pascal_name": "CubeTransparent"
  },
  {
    "name": "currency-btc",
    "pascal_name": "CurrencyBtc"
  },
  {
    "name": "currency-circle-dollar",
    "pascal_name": "CurrencyCircleDollar"
  },
  {
    "name": "currency-cny",
    "pascal_name": "CurrencyCny"
  },
  {
    "name": "currency-dollar",
    "pascal_name": "CurrencyDollar"
  },
  {
    "name": "currency-dollar-simple",
    "pascal_name": "CurrencyDollarSimple"
  },
  {
    "name": "currency-eth",
    "pascal_name": "CurrencyEth"
  },
  {
    "name": "currency-eur",
    "pascal_name": "CurrencyEur"
  },
  {
    "name": "currency-gbp",
    "pascal_name": "CurrencyGbp"
  },
  {
    "name": "currency-inr",
    "pascal_name": "CurrencyInr"
  },
  {
    "name": "currency-jpy",
    "pascal_name": "CurrencyJpy"
  },
  {
    "name": "currency-krw",
    "pascal_name": "CurrencyKrw"
  },
  {
    "name": "currency-kzt",
    "pascal_name": "CurrencyKzt"
  },
  {
    "name": "currency-ngn",
    "pascal_name": "CurrencyNgn"
  },
  {
    "name": "currency-rub",
    "pascal_name": "CurrencyRub"
  },
  {
    "name": "cursor",
    "pascal_name": "Cursor"
  },
  {
    "name": "cursor-click",
    "pascal_name": "CursorClick"
  },
  {
    "name": "cursor-text",
    "pascal_name": "CursorText"
  },
  {
    "name": "cylinder",
    "pascal_name": "Cylinder"
  },
  {
    "name": "database",
    "pascal_name": "Database"
  },
  {
    "name": "desk",
    "pascal_name": "Desk"
  },
  {
    "name": "desktop",
    "pascal_name": "Desktop"
  },
  {
    "name": "desktop-tower",
    "pascal_name": "DesktopTower"
  },
  {
    "name": "detective",
    "pascal_name": "Detective"
  },
  {
    "name": "dev-to-logo",
    "pascal_name": "DevToLogo"
  },
  {
    "name": "device-mobile",
    "pascal_name": "DeviceMobile"
  },
  {
    "name": "device-mobile-camera",
    "pascal_name": "DeviceMobileCamera"
  },
  {
    "name": "device-mobile-slash",
    "pascal_name": "DeviceMobileSlash"
  },
  {
    "name": "device-mobile-speaker",
    "pascal_name": "DeviceMobileSpeaker"
  },
  {
    "name": "device-rotate",
    "pascal_name": "DeviceRotate"
  },
  {
    "name": "device-tablet",
    "pascal_name": "DeviceTablet"
  },
  {
    "name": "device-tablet-camera",
    "pascal_name": "DeviceTabletCamera"
  },
  {
    "name": "device-tablet-speaker",
    "pascal_name": "DeviceTabletSpeaker"
  },
  {
    "name": "devices",
    "pascal_name": "Devices"
  },
  {
    "name": "diamond",
    "pascal_name": "Diamond"
  },
  {
    "name": "diamonds-four",
    "pascal_name": "DiamondsFour"
  },
  {
    "name": "dice-five",
    "pascal_name": "DiceFive"
  },
  {
    "name": "dice-four",
    "pascal_name": "DiceFour"
  },
  {
    "name": "dice-one",
    "pascal_name": "DiceOne"
  },
  {
    "name": "dice-six",
    "pascal_name": "DiceSix"
  },
  {
    "name": "dice-three",
    "pascal_name": "DiceThree"
  },
  {
    "name": "dice-two",
    "pascal_name": "DiceTwo"
  },
  {
    "name": "disc",
    "pascal_name": "Disc"
  },
  {
    "name": "disco-ball",
    "pascal_name": "DiscoBall"
  },
  {
    "name": "discord-logo",
    "pascal_name": "DiscordLogo"
  },
  {
    "name": "divide",
    "pascal_name": "Divide"
  },
  {
    "name": "dna",
    "pascal_name": "Dna"
  },
  {
    "name": "dog",
    "pascal_name": "Dog"
  },
  {
    "name": "door",
    "pascal_name": "Door"
  },
  {
    "name": "door-open",
    "pascal_name": "DoorOpen"
  },
  {
    "name": "dot",
    "pascal_name": "Dot"
  },
  {
    "name": "dot-outline",
    "pascal_name": "DotOutline"
  },
  {
    "name": "dots-nine",
    "pascal_name": "DotsNine"
  },
  {
    "name": "dots-six",
    "pascal_name": "DotsSix"
  },
  {
    "name": "dots-six-vertical",
    "pascal_name": "DotsSixVertical"
  },
  {
    "name": "dots-three",
    "pascal_name": "DotsThree"
  },
  {
    "name": "dots-three-circle",
    "pascal_name": "DotsThreeCircle"
  },
  {
    "name": "dots-three-circle-vertical",
    "pascal_name": "DotsThreeCircleVertical"
  },
  {
    "name": "dots-three-outline",
    "pascal_name": "DotsThreeOutline"
  },
  {
    "name": "dots-three-outline-vertical",
    "pascal_name": "DotsThreeOutlineVertical"
  },
  {
    "name": "dots-three-vertical",
    "pascal_name": "DotsThreeVertical"
  },
  {
    "name": "download",
    "pascal_name": "Download"
  },
  {
    "name": "download-simple",
    "pascal_name": "DownloadSimple"
  },
  {
    "name": "dress",
    "pascal_name": "Dress"
  },
  {
    "name": "dresser",
    "pascal_name": "Dresser"
  },
  {
    "name": "dribbble-logo",
    "pascal_name": "DribbbleLogo"
  },
  {
    "name": "drone",
    "pascal_name": "Drone"
  },
  {
    "name": "drop",
    "pascal_name": "Drop"
  },
  {
    "name": "drop-half",
    "pascal_name": "DropHalf"
  },
  {
    "name": "drop-half-bottom",
    "pascal_name": "DropHalfBottom"
  },
  {
    "name": "drop-simple",
    "pascal_name": "DropSimple"
  },
  {
    "name": "drop-slash",
    "pascal_name": "DropSlash"
  },
  {
    "name": "dropbox-logo",
    "pascal_name": "DropboxLogo"
  },
  {
    "name": "ear",
    "pascal_name": "Ear"
  },
  {
    "name": "ear-slash",
    "pascal_name": "EarSlash"
  },
  {
    "name": "egg",
    "pascal_name": "Egg"
  },
  {
    "name": "egg-crack",
    "pascal_name": "EggCrack"
  },
  {
    "name": "eject",
    "pascal_name": "Eject"
  },
  {
    "name": "eject-simple",
    "pascal_name": "EjectSimple"
  },
  {
    "name": "elevator",
    "pascal_name": "Elevator"
  },
  {
    "name": "empty",
    "pascal_name": "Empty"
  },
  {
    "name": "engine",
    "pascal_name": "Engine"
  },
  {
    "name": "envelope",
    "pascal_name": "Envelope"
  },
  {
    "name": "envelope-open",
    "pascal_name": "EnvelopeOpen"
  },
  {
    "name": "envelope-simple",
    "pascal_name": "EnvelopeSimple"
  },
  {
    "name": "envelope-simple-open",
    "pascal_name": "EnvelopeSimpleOpen"
  },
  {
    "name": "equalizer",
    "pascal_name": "Equalizer"
  },
  {
    "name": "equals",
    "pascal_name": "Equals"
  },
  {
    "name": "eraser",
    "pascal_name": "Eraser"
  },
  {
    "name": "escalator-down",
    "pascal_name": "EscalatorDown"
  },
  {
    "name": "escalator-up",
    "pascal_name": "EscalatorUp"
  },
  {
    "name": "exam",
    "pascal_name": "Exam"
  },
  {
    "name": "exclamation-mark",
    "pascal_name": "ExclamationMark"
  },
  {
    "name": "exclude",
    "pascal_name": "Exclude"
  },
  {
    "name": "exclude-square",
    "pascal_name": "ExcludeSquare"
  },
  {
    "name": "export",
    "pascal_name": "Export"
  },
  {
    "name": "eye",
    "pascal_name": "Eye"
  },
  {
    "name": "eye-closed",
    "pascal_name": "EyeClosed"
  },
  {
    "name": "eye-slash",
    "pascal_name": "EyeSlash"
  },
  {
    "name": "eyedropper",
    "pascal_name": "Eyedropper"
  },
  {
    "name": "eyedropper-sample",
    "pascal_name": "EyedropperSample"
  },
  {
    "name": "eyeglasses",
    "pascal_name": "Eyeglasses"
  },
  {
    "name": "eyes",
    "pascal_name": "Eyes"
  },
  {
    "name": "face-mask",
    "pascal_name": "FaceMask"
  },
  {
    "name": "facebook-logo",
    "pascal_name": "FacebookLogo"
  },
  {
    "name": "factory",
    "pascal_name": "Factory"
  },
  {
    "name": "faders",
    "pascal_name": "Faders"
  },
  {
    "name": "faders-horizontal",
    "pascal_name": "FadersHorizontal"
  },
  {
    "name": "fallout-shelter",
    "pascal_name": "FalloutShelter"
  },
  {
    "name": "fan",
    "pascal_name": "Fan"
  },
  {
    "name": "farm",
    "pascal_name": "Farm"
  },
  {
    "name": "fast-forward",
    "pascal_name": "FastForward"
  },
  {
    "name": "fast-forward-circle",
    "pascal_name": "FastForwardCircle"
  },
  {
    "name": "feather",
    "pascal_name": "Feather"
  },
  {
    "name": "fediverse-logo",
    "pascal_name": "FediverseLogo"
  },
  {
    "name": "figma-logo",
    "pascal_name": "FigmaLogo"
  },
  {
    "name": "file",
    "pascal_name": "File"
  },
  {
    "name": "file-archive",
    "pascal_name": "FileArchive"
  },
  {
    "name": "file-arrow-down",
    "pascal_name": "FileArrowDown"
  },
  {
    "name": "file-arrow-up",
    "pascal_name": "FileArrowUp"
  },
  {
    "name": "file-audio",
    "pascal_name": "FileAudio"
  },
  {
    "name": "file-c",
    "pascal_name": "FileC"
  },
  {
    "name": "file-c-sharp",
    "pascal_name": "FileCSharp"
  },
  {
    "name": "file-cloud",
    "pascal_name": "FileCloud"
  },
  {
    "name": "file-code",
    "pascal_name": "FileCode"
  },
  {
    "name": "file-cpp",
    "pascal_name": "FileCpp"
  },
  {
    "name": "file-css",
    "pascal_name": "FileCss"
  },
  {
    "name": "file-csv",
    "pascal_name": "FileCsv"
  },
  {
    "name": "file-dashed",
    "pascal_name": "FileDashed",
    "alias": {
      "name": "file-dotted",
      "pascal_name": "FileDotted"
    }
  },
  {
    "name": "file-doc",
    "pascal_name": "FileDoc"
  },
  {
    "name": "file-html",
    "pascal_name": "FileHtml"
  },
  {
    "name": "file-image",
    "pascal_name": "FileImage"
  },
  {
    "name": "file-ini",
    "pascal_name": "FileIni"
  },
  {
    "name": "file-jpg",
    "pascal_name": "FileJpg"
  },
  {
    "name": "file-js",
    "pascal_name": "FileJs"
  },
  {
    "name": "file-jsx",
    "pascal_name": "FileJsx"
  },
  {
    "name": "file-lock",
    "pascal_name": "FileLock"
  },
  {
    "name": "file-magnifying-glass",
    "pascal_name": "FileMagnifyingGlass",
    "alias": {
      "name": "file-search",
      "pascal_name": "FileSearch"
    }
  },
  {
    "name": "file-md",
    "pascal_name": "FileMd"
  },
  {
    "name": "file-minus",
    "pascal_name": "FileMinus"
  },
  {
    "name": "file-pdf",
    "pascal_name": "FilePdf"
  },
  {
    "name": "file-plus",
    "pascal_name": "FilePlus"
  },
  {
    "name": "file-png",
    "pascal_name": "FilePng"
  },
  {
    "name": "file-ppt",
    "pascal_name": "FilePpt"
  },
  {
    "name": "file-py",
    "pascal_name": "FilePy"
  },
  {
    "name": "file-rs",
    "pascal_name": "FileRs"
  },
  {
    "name": "file-sql",
    "pascal_name": "FileSql"
  },
  {
    "name": "file-svg",
    "pascal_name": "FileSvg"
  },
  {
    "name": "file-text",
    "pascal_name": "FileText"
  },
  {
    "name": "file-ts",
    "pascal_name": "FileTs"
  },
  {
    "name": "file-tsx",
    "pascal_name": "FileTsx"
  },
  {
    "name": "file-txt",
    "pascal_name": "FileTxt"
  },
  {
    "name": "file-video",
    "pascal_name": "FileVideo"
  },
  {
    "name": "file-vue",
    "pascal_name": "FileVue"
  },
  {
    "name": "file-x",
    "pascal_name": "FileX"
  },
  {
    "name": "file-xls",
    "pascal_name": "FileXls"
  },
  {
    "name": "file-zip",
    "pascal_name": "FileZip"
  },
  {
    "name": "files",
    "pascal_name": "Files"
  },
  {
    "name": "film-reel",
    "pascal_name": "FilmReel"
  },
  {
    "name": "film-script",
    "pascal_name": "FilmScript"
  },
  {
    "name": "film-slate",
    "pascal_name": "FilmSlate"
  },
  {
    "name": "film-strip",
    "pascal_name": "FilmStrip"
  },
  {
    "name": "fingerprint",
    "pascal_name": "Fingerprint"
  },
  {
    "name": "fingerprint-simple",
    "pascal_name": "FingerprintSimple"
  },
  {
    "name": "finn-the-human",
    "pascal_name": "FinnTheHuman"
  },
  {
    "name": "fire",
    "pascal_name": "Fire"
  },
  {
    "name": "fire-extinguisher",
    "pascal_name": "FireExtinguisher"
  },
  {
    "name": "fire-simple",
    "pascal_name": "FireSimple"
  },
  {
    "name": "fire-truck",
    "pascal_name": "FireTruck"
  },
  {
    "name": "first-aid",
    "pascal_name": "FirstAid"
  },
  {
    "name": "first-aid-kit",
    "pascal_name": "FirstAidKit"
  },
  {
    "name": "fish",
    "pascal_name": "Fish"
  },
  {
    "name": "fish-simple",
    "pascal_name": "FishSimple"
  },
  {
    "name": "flag",
    "pascal_name": "Flag"
  },
  {
    "name": "flag-banner",
    "pascal_name": "FlagBanner"
  },
  {
    "name": "flag-banner-fold",
    "pascal_name": "FlagBannerFold"
  },
  {
    "name": "flag-checkered",
    "pascal_name": "FlagCheckered"
  },
  {
    "name": "flag-pennant",
    "pascal_name": "FlagPennant"
  },
  {
    "name": "flame",
    "pascal_name": "Flame"
  },
  {
    "name": "flashlight",
    "pascal_name": "Flashlight"
  },
  {
    "name": "flask",
    "pascal_name": "Flask"
  },
  {
    "name": "flip-horizontal",
    "pascal_name": "FlipHorizontal"
  },
  {
    "name": "flip-vertical",
    "pascal_name": "FlipVertical"
  },
  {
    "name": "floppy-disk",
    "pascal_name": "FloppyDisk"
  },
  {
    "name": "floppy-disk-back",
    "pascal_name": "FloppyDiskBack"
  },
  {
    "name": "flow-arrow",
    "pascal_name": "FlowArrow"
  },
  {
    "name": "flower",
    "pascal_name": "Flower"
  },
  {
    "name": "flower-lotus",
    "pascal_name": "FlowerLotus"
  },
  {
    "name": "flower-tulip",
    "pascal_name": "FlowerTulip"
  },
  {
    "name": "flying-saucer",
    "pascal_name": "FlyingSaucer"
  },
  {
    "name": "folder",
    "pascal_name": "Folder",
    "alias": {
      "name": "folder-notch",
      "pascal_name": "FolderNotch"
    }
  },
  {
    "name": "folder-dashed",
    "pascal_name": "FolderDashed",
    "alias": {
      "name": "folder-dotted",
      "pascal_name": "FolderDotted"
    }
  },
  {
    "name": "folder-lock",
    "pascal_name": "FolderLock"
  },
  {
    "name": "folder-minus",
    "pascal_name": "FolderMinus",
    "alias": {
      "name": "folder-notch-minus",
      "pascal_name": "FolderNotchMinus"
    }
  },
  {
    "name": "folder-open",
    "pascal_name": "FolderOpen",
    "alias": {
      "name": "folder-notch-open",
      "pascal_name": "FolderNotchOpen"
    }
  },
  {
    "name": "folder-plus",
    "pascal_name": "FolderPlus",
    "alias": {
      "name": "folder-notch-plus",
      "pascal_name": "FolderNotchPlus"
    }
  },
  {
    "name": "folder-simple",
    "pascal_name": "FolderSimple"
  },
  {
    "name": "folder-simple-dashed",
    "pascal_name": "FolderSimpleDashed",
    "alias": {
      "name": "folder-simple-dotted",
      "pascal_name": "FolderSimpleDotted"
    }
  },
  {
    "name": "folder-simple-lock",
    "pascal_name": "FolderSimpleLock"
  },
  {
    "name": "folder-simple-minus",
    "pascal_name": "FolderSimpleMinus"
  },
  {
    "name": "folder-simple-plus",
    "pascal_name": "FolderSimplePlus"
  },
  {
    "name": "folder-simple-star",
    "pascal_name": "FolderSimpleStar"
  },
  {
    "name": "folder-simple-user",
    "pascal_name": "FolderSimpleUser"
  },
  {
    "name": "folder-star",
    "pascal_name": "FolderStar"
  },
  {
    "name": "folder-user",
    "pascal_name": "FolderUser"
  },
  {
    "name": "folders",
    "pascal_name": "Folders"
  },
  {
    "name": "football",
    "pascal_name": "Football"
  },
  {
    "name": "football-helmet",
    "pascal_name": "FootballHelmet"
  },
  {
    "name": "footprints",
    "pascal_name": "Footprints"
  },
  {
    "name": "fork-knife",
    "pascal_name": "ForkKnife"
  },
  {
    "name": "four-k",
    "pascal_name": "FourK"
  },
  {
    "name": "frame-corners",
    "pascal_name": "FrameCorners"
  },
  {
    "name": "framer-logo",
    "pascal_name": "FramerLogo"
  },
  {
    "name": "function",
    "pascal_name": "Function"
  },
  {
    "name": "funnel",
    "pascal_name": "Funnel"
  },
  {
    "name": "funnel-simple",
    "pascal_name": "FunnelSimple"
  },
  {
    "name": "funnel-simple-x",
    "pascal_name": "FunnelSimpleX"
  },
  {
    "name": "funnel-x",
    "pascal_name": "FunnelX"
  },
  {
    "name": "game-controller",
    "pascal_name": "GameController"
  },
  {
    "name": "garage",
    "pascal_name": "Garage"
  },
  {
    "name": "gas-can",
    "pascal_name": "GasCan"
  },
  {
    "name": "gas-pump",
    "pascal_name": "GasPump"
  },
  {
    "name": "gauge",
    "pascal_name": "Gauge"
  },
  {
    "name": "gavel",
    "pascal_name": "Gavel"
  },
  {
    "name": "gear",
    "pascal_name": "Gear"
  },
  {
    "name": "gear-fine",
    "pascal_name": "GearFine"
  },
  {
    "name": "gear-six",
    "pascal_name": "GearSix"
  },
  {
    "name": "gender-female",
    "pascal_name": "GenderFemale"
  },
  {
    "name": "gender-intersex",
    "pascal_name": "GenderIntersex"
  },
  {
    "name": "gender-male",
    "pascal_name": "GenderMale"
  },
  {
    "name": "gender-neuter",
    "pascal_name": "GenderNeuter"
  },
  {
    "name": "gender-nonbinary",
    "pascal_name": "GenderNonbinary"
  },
  {
    "name": "gender-transgender",
    "pascal_name": "GenderTransgender"
  },
  {
    "name": "ghost",
    "pascal_name": "Ghost"
  },
  {
    "name": "gif",
    "pascal_name": "Gif"
  },
  {
    "name": "gift",
    "pascal_name": "Gift"
  },
  {
    "name": "git-branch",
    "pascal_name": "GitBranch"
  },
  {
    "name": "git-commit",
    "pascal_name": "GitCommit"
  },
  {
    "name": "git-diff",
    "pascal_name": "GitDiff"
  },
  {
    "name": "git-fork",
    "pascal_name": "GitFork"
  },
  {
    "name": "git-merge",
    "pascal_name": "GitMerge"
  },
  {
    "name": "git-pull-request",
    "pascal_name": "GitPullRequest"
  },
  {
    "name": "github-logo",
    "pascal_name": "GithubLogo"
  },
  {
    "name": "gitlab-logo",
    "pascal_name": "GitlabLogo"
  },
  {
    "name": "gitlab-logo-simple",
    "pascal_name": "GitlabLogoSimple"
  },
  {
    "name": "globe",
    "pascal_name": "Globe"
  },
  {
    "name": "globe-hemisphere-east",
    "pascal_name": "GlobeHemisphereEast"
  },
  {
    "name": "globe-hemisphere-west",
    "pascal_name": "GlobeHemisphereWest"
  },
  {
    "name": "globe-simple",
    "pascal_name": "GlobeSimple"
  },
  {
    "name": "globe-simple-x",
    "pascal_name": "GlobeSimpleX"
  },
  {
    "name": "globe-stand",
    "pascal_name": "GlobeStand"
  },
  {
    "name": "globe-x",
    "pascal_name": "GlobeX"
  },
  {
    "name": "goggles",
    "pascal_name": "Goggles"
  },
  {
    "name": "golf",
    "pascal_name": "Golf"
  },
  {
    "name": "goodreads-logo",
    "pascal_name": "GoodreadsLogo"
  },
  {
    "name": "google-cardboard-logo",
    "pascal_name": "GoogleCardboardLogo"
  },
  {
    "name": "google-chrome-logo",
    "pascal_name": "GoogleChromeLogo"
  },
  {
    "name": "google-drive-logo",
    "pascal_name": "GoogleDriveLogo"
  },
  {
    "name": "google-logo",
    "pascal_name": "GoogleLogo"
  },
  {
    "name": "google-photos-logo",
    "pascal_name": "GooglePhotosLogo"
  },
  {
    "name": "google-play-logo",
    "pascal_name": "GooglePlayLogo"
  },
  {
    "name": "google-podcasts-logo",
    "pascal_name": "GooglePodcastsLogo"
  },
  {
    "name": "gps",
    "pascal_name": "Gps"
  },
  {
    "name": "gps-fix",
    "pascal_name": "GpsFix"
  },
  {
    "name": "gps-slash",
    "pascal_name": "GpsSlash"
  },
  {
    "name": "gradient",
    "pascal_name": "Gradient"
  },
  {
    "name": "graduation-cap",
    "pascal_name": "GraduationCap"
  },
  {
    "name": "grains",
    "pascal_name": "Grains"
  },
  {
    "name": "grains-slash",
    "pascal_name": "GrainsSlash"
  },
  {
    "name": "graph",
    "pascal_name": "Graph"
  },
  {
    "name": "graphics-card",
    "pascal_name": "GraphicsCard"
  },
  {
    "name": "greater-than",
    "pascal_name": "GreaterThan"
  },
  {
    "name": "greater-than-or-equal",
    "pascal_name": "GreaterThanOrEqual"
  },
  {
    "name": "grid-four",
    "pascal_name": "GridFour"
  },
  {
    "name": "grid-nine",
    "pascal_name": "GridNine"
  },
  {
    "name": "guitar",
    "pascal_name": "Guitar"
  },
  {
    "name": "hair-dryer",
    "pascal_name": "HairDryer"
  },
  {
    "name": "hamburger",
    "pascal_name": "Hamburger"
  },
  {
    "name": "hammer",
    "pascal_name": "Hammer"
  },
  {
    "name": "hand",
    "pascal_name": "Hand"
  },
  {
    "name": "hand-arrow-down",
    "pascal_name": "HandArrowDown"
  },
  {
    "name": "hand-arrow-up",
    "pascal_name": "HandArrowUp"
  },
  {
    "name": "hand-coins",
    "pascal_name": "HandCoins"
  },
  {
    "name": "hand-deposit",
    "pascal_name": "HandDeposit"
  },
  {
    "name": "hand-eye",
    "pascal_name": "HandEye"
  },
  {
    "name": "hand-fist",
    "pascal_name": "HandFist"
  },
  {
    "name": "hand-grabbing",
    "pascal_name": "HandGrabbing"
  },
  {
    "name": "hand-heart",
    "pascal_name": "HandHeart"
  },
  {
    "name": "hand-palm",
    "pascal_name": "HandPalm"
  },
  {
    "name": "hand-peace",
    "pascal_name": "HandPeace"
  },
  {
    "name": "hand-pointing",
    "pascal_name": "HandPointing"
  },
  {
    "name": "hand-soap",
    "pascal_name": "HandSoap"
  },
  {
    "name": "hand-swipe-left",
    "pascal_name": "HandSwipeLeft"
  },
  {
    "name": "hand-swipe-right",
    "pascal_name": "HandSwipeRight"
  },
  {
    "name": "hand-tap",
    "pascal_name": "HandTap"
  },
  {
    "name": "hand-waving",
    "pascal_name": "HandWaving"
  },
  {
    "name": "hand-withdraw",
    "pascal_name": "HandWithdraw"
  },
  {
    "name": "handbag",
    "pascal_name": "Handbag"
  },
  {
    "name": "handbag-simple",
    "pascal_name": "HandbagSimple"
  },
  {
    "name": "hands-clapping",
    "pascal_name": "HandsClapping"
  },
  {
    "name": "hands-praying",
    "pascal_name": "HandsPraying"
  },
  {
    "name": "handshake",
    "pascal_name": "Handshake"
  },
  {
    "name": "hard-drive",
    "pascal_name": "HardDrive"
  },
  {
    "name": "hard-drives",
    "pascal_name": "HardDrives"
  },
  {
    "name": "hard-hat",
    "pascal_name": "HardHat"
  },
  {
    "name": "hash",
    "pascal_name": "Hash"
  },
  {
    "name": "hash-straight",
    "pascal_name": "HashStraight"
  },
  {
    "name": "head-circuit",
    "pascal_name": "HeadCircuit"
  },
  {
    "name": "headlights",
    "pascal_name": "Headlights"
  },
  {
    "name": "headphones",
    "pascal_name": "Headphones"
  },
  {
    "name": "headset",
    "pascal_name": "Headset"
  },
  {
    "name": "heart",
    "pascal_name": "Heart"
  },
  {
    "name": "heart-break",
    "pascal_name": "HeartBreak"
  },
  {
    "name": "heart-half",
    "pascal_name": "HeartHalf"
  },
  {
    "name": "heart-straight",
    "pascal_name": "HeartStraight"
  },
  {
    "name": "heart-straight-break",
    "pascal_name": "HeartStraightBreak"
  },
  {
    "name": "heartbeat",
    "pascal_name": "Heartbeat"
  },
  {
    "name": "hexagon",
    "pascal_name": "Hexagon"
  },
  {
    "name": "high-definition",
    "pascal_name": "HighDefinition"
  },
  {
    "name": "high-heel",
    "pascal_name": "HighHeel"
  },
  {
    "name": "highlighter",
    "pascal_name": "Highlighter"
  },
  {
    "name": "highlighter-circle",
    "pascal_name": "HighlighterCircle"
  },
  {
    "name": "hockey",
    "pascal_name": "Hockey"
  },
  {
    "name": "hoodie",
    "pascal_name": "Hoodie"
  },
  {
    "name": "horse",
    "pascal_name": "Horse"
  },
  {
    "name": "hospital",
    "pascal_name": "Hospital"
  },
  {
    "name": "hourglass",
    "pascal_name": "Hourglass"
  },
  {
    "name": "hourglass-high",
    "pascal_name": "HourglassHigh"
  },
  {
    "name": "hourglass-low",
    "pascal_name": "HourglassLow"
  },
  {
    "name": "hourglass-medium",
    "pascal_name": "HourglassMedium"
  },
  {
    "name": "hourglass-simple",
    "pascal_name": "HourglassSimple"
  },
  {
    "name": "hourglass-simple-high",
    "pascal_name": "HourglassSimpleHigh"
  },
  {
    "name": "hourglass-simple-low",
    "pascal_name": "HourglassSimpleLow"
  },
  {
    "name": "hourglass-simple-medium",
    "pascal_name": "HourglassSimpleMedium"
  },
  {
    "name": "house",
    "pascal_name": "House"
  },
  {
    "name": "house-line",
    "pascal_name": "HouseLine"
  },
  {
    "name": "house-simple",
    "pascal_name": "HouseSimple"
  },
  {
    "name": "hurricane",
    "pascal_name": "Hurricane"
  },
  {
    "name": "ice-cream",
    "pascal_name": "IceCream"
  },
  {
    "name": "identification-badge",
    "pascal_name": "IdentificationBadge"
  },
  {
    "name": "identification-card",
    "pascal_name": "IdentificationCard"
  },
  {
    "name": "image",
    "pascal_name": "Image"
  },
  {
    "name": "image-broken",
    "pascal_name": "ImageBroken"
  },
  {
    "name": "image-square",
    "pascal_name": "ImageSquare"
  },
  {
    "name": "images",
    "pascal_name": "Images"
  },
  {
    "name": "images-square",
    "pascal_name": "ImagesSquare"
  },
  {
    "name": "infinity",
    "pascal_name": "Infinity",
    "alias": {
      "name": "lemniscate",
      "pascal_name": "Lemniscate"
    }
  },
  {
    "name": "info",
    "pascal_name": "Info"
  },
  {
    "name": "instagram-logo",
    "pascal_name": "InstagramLogo"
  },
  {
    "name": "intersect",
    "pascal_name": "Intersect"
  },
  {
    "name": "intersect-square",
    "pascal_name": "IntersectSquare"
  },
  {
    "name": "intersect-three",
    "pascal_name": "IntersectThree"
  },
  {
    "name": "intersection",
    "pascal_name": "Intersection"
  },
  {
    "name": "invoice",
    "pascal_name": "Invoice"
  },
  {
    "name": "island",
    "pascal_name": "Island"
  },
  {
    "name": "jar",
    "pascal_name": "Jar"
  },
  {
    "name": "jar-label",
    "pascal_name": "JarLabel"
  },
  {
    "name": "jeep",
    "pascal_name": "Jeep"
  },
  {
    "name": "joystick",
    "pascal_name": "Joystick"
  },
  {
    "name": "kanban",
    "pascal_name": "Kanban"
  },
  {
    "name": "key",
    "pascal_name": "Key"
  },
  {
    "name": "key-return",
    "pascal_name": "KeyReturn"
  },
  {
    "name": "keyboard",
    "pascal_name": "Keyboard"
  },
  {
    "name": "keyhole",
    "pascal_name": "Keyhole"
  },
  {
    "name": "knife",
    "pascal_name": "Knife"
  },
  {
    "name": "ladder",
    "pascal_name": "Ladder"
  },
  {
    "name": "ladder-simple",
    "pascal_name": "LadderSimple"
  },
  {
    "name": "lamp",
    "pascal_name": "Lamp"
  },
  {
    "name": "lamp-pendant",
    "pascal_name": "LampPendant"
  },
  {
    "name": "laptop",
    "pascal_name": "Laptop"
  },
  {
    "name": "lasso",
    "pascal_name": "Lasso"
  },
  {
    "name": "lastfm-logo",
    "pascal_name": "LastfmLogo"
  },
  {
    "name": "layout",
    "pascal_name": "Layout"
  },
  {
    "name": "leaf",
    "pascal_name": "Leaf"
  },
  {
    "name": "lectern",
    "pascal_name": "Lectern"
  },
  {
    "name": "lego",
    "pascal_name": "Lego"
  },
  {
    "name": "lego-smiley",
    "pascal_name": "LegoSmiley"
  },
  {
    "name": "less-than",
    "pascal_name": "LessThan"
  },
  {
    "name": "less-than-or-equal",
    "pascal_name": "LessThanOrEqual"
  },
  {
    "name": "letter-circle-h",
    "pascal_name": "LetterCircleH"
  },
  {
    "name": "letter-circle-p",
    "pascal_name": "LetterCircleP"
  },
  {
    "name": "letter-circle-v",
    "pascal_name": "LetterCircleV"
  },
  {
    "name": "lifebuoy",
    "pascal_name": "Lifebuoy"
  },
  {
    "name": "lightbulb",
    "pascal_name": "Lightbulb"
  },
  {
    "name": "lightbulb-filament",
    "pascal_name": "LightbulbFilament"
  },
  {
    "name": "lighthouse",
    "pascal_name": "Lighthouse"
  },
  {
    "name": "lightning",
    "pascal_name": "Lightning"
  },
  {
    "name": "lightning-a",
    "pascal_name": "LightningA"
  },
  {
    "name": "lightning-slash",
    "pascal_name": "LightningSlash"
  },
  {
    "name": "line-segment",
    "pascal_name": "LineSegment"
  },
  {
    "name": "line-segments",
    "pascal_name": "LineSegments"
  },
  {
    "name": "line-vertical",
    "pascal_name": "LineVertical"
  },
  {
    "name": "link",
    "pascal_name": "Link"
  },
  {
    "name": "link-break",
    "pascal_name": "LinkBreak"
  },
  {
    "name": "link-simple",
    "pascal_name": "LinkSimple"
  },
  {
    "name": "link-simple-break",
    "pascal_name": "LinkSimpleBreak"
  },
  {
    "name": "link-simple-horizontal",
    "pascal_name": "LinkSimpleHorizontal"
  },
  {
    "name": "link-simple-horizontal-break",
    "pascal_name": "LinkSimpleHorizontalBreak"
  },
  {
    "name": "linkedin-logo",
    "pascal_name": "LinkedinLogo"
  },
  {
    "name": "linktree-logo",
    "pascal_name": "LinktreeLogo"
  },
  {
    "name": "linux-logo",
    "pascal_name": "LinuxLogo"
  },
  {
    "name": "list",
    "pascal_name": "List"
  },
  {
    "name": "list-bullets",
    "pascal_name": "ListBullets"
  },
  {
    "name": "list-checks",
    "pascal_name": "ListChecks"
  },
  {
    "name": "list-dashes",
    "pascal_name": "ListDashes"
  },
  {
    "name": "list-heart",
    "pascal_name": "ListHeart"
  },
  {
    "name": "list-magnifying-glass",
    "pascal_name": "ListMagnifyingGlass"
  },
  {
    "name": "list-numbers",
    "pascal_name": "ListNumbers"
  },
  {
    "name": "list-plus",
    "pascal_name": "ListPlus"
  },
  {
    "name": "list-star",
    "pascal_name": "ListStar"
  },
  {
    "name": "lock",
    "pascal_name": "Lock"
  },
  {
    "name": "lock-key",
    "pascal_name": "LockKey"
  },
  {
    "name": "lock-key-open",
    "pascal_name": "LockKeyOpen"
  },
  {
    "name": "lock-laminated",
    "pascal_name": "LockLaminated"
  },
  {
    "name": "lock-laminated-open",
    "pascal_name": "LockLaminatedOpen"
  },
  {
    "name": "lock-open",
    "pascal_name": "LockOpen"
  },
  {
    "name": "lock-simple",
    "pascal_name": "LockSimple"
  },
  {
    "name": "lock-simple-open",
    "pascal_name": "LockSimpleOpen"
  },
  {
    "name": "lockers",
    "pascal_name": "Lockers"
  },
  {
    "name": "log",
    "pascal_name": "Log"
  },
  {
    "name": "magic-wand",
    "pascal_name": "MagicWand"
  },
  {
    "name": "magnet",
    "pascal_name": "Magnet"
  },
  {
    "name": "magnet-straight",
    "pascal_name": "MagnetStraight"
  },
  {
    "name": "magnifying-glass",
    "pascal_name": "MagnifyingGlass"
  },
  {
    "name": "magnifying-glass-minus",
    "pascal_name": "MagnifyingGlassMinus"
  },
  {
    "name": "magnifying-glass-plus",
    "pascal_name": "MagnifyingGlassPlus"
  },
  {
    "name": "mailbox",
    "pascal_name": "Mailbox"
  },
  {
    "name": "map-pin",
    "pascal_name": "MapPin"
  },
  {
    "name": "map-pin-area",
    "pascal_name": "MapPinArea"
  },
  {
    "name": "map-pin-line",
    "pascal_name": "MapPinLine"
  },
  {
    "name": "map-pin-plus",
    "pascal_name": "MapPinPlus"
  },
  {
    "name": "map-pin-simple",
    "pascal_name": "MapPinSimple"
  },
  {
    "name": "map-pin-simple-area",
    "pascal_name": "MapPinSimpleArea"
  },
  {
    "name": "map-pin-simple-line",
    "pascal_name": "MapPinSimpleLine"
  },
  {
    "name": "map-trifold",
    "pascal_name": "MapTrifold"
  },
  {
    "name": "markdown-logo",
    "pascal_name": "MarkdownLogo"
  },
  {
    "name": "marker-circle",
    "pascal_name": "MarkerCircle"
  },
  {
    "name": "martini",
    "pascal_name": "Martini"
  },
  {
    "name": "mask-happy",
    "pascal_name": "MaskHappy"
  },
  {
    "name": "mask-sad",
    "pascal_name": "MaskSad"
  },
  {
    "name": "mastodon-logo",
    "pascal_name": "MastodonLogo"
  },
  {
    "name": "math-operations",
    "pascal_name": "MathOperations"
  },
  {
    "name": "matrix-logo",
    "pascal_name": "MatrixLogo"
  },
  {
    "name": "medal",
    "pascal_name": "Medal"
  },
  {
    "name": "medal-military",
    "pascal_name": "MedalMilitary"
  },
  {
    "name": "medium-logo",
    "pascal_name": "MediumLogo"
  },
  {
    "name": "megaphone",
    "pascal_name": "Megaphone"
  },
  {
    "name": "megaphone-simple",
    "pascal_name": "MegaphoneSimple"
  },
  {
    "name": "member-of",
    "pascal_name": "MemberOf"
  },
  {
    "name": "memory",
    "pascal_name": "Memory"
  },
  {
    "name": "messenger-logo",
    "pascal_name": "MessengerLogo"
  },
  {
    "name": "meta-logo",
    "pascal_name": "MetaLogo"
  },
  {
    "name": "meteor",
    "pascal_name": "Meteor"
  },
  {
    "name": "metronome",
    "pascal_name": "Metronome"
  },
  {
    "name": "microphone",
    "pascal_name": "Microphone"
  },
  {
    "name": "microphone-slash",
    "pascal_name": "MicrophoneSlash"
  },
  {
    "name": "microphone-stage",
    "pascal_name": "MicrophoneStage"
  },
  {
    "name": "microscope",
    "pascal_name": "Microscope"
  },
  {
    "name": "microsoft-excel-logo",
    "pascal_name": "MicrosoftExcelLogo"
  },
  {
    "name": "microsoft-outlook-logo",
    "pascal_name": "MicrosoftOutlookLogo"
  },
  {
    "name": "microsoft-powerpoint-logo",
    "pascal_name": "MicrosoftPowerpointLogo"
  },
  {
    "name": "microsoft-teams-logo",
    "pascal_name": "MicrosoftTeamsLogo"
  },
  {
    "name": "microsoft-word-logo",
    "pascal_name": "MicrosoftWordLogo"
  },
  {
    "name": "minus",
    "pascal_name": "Minus"
  },
  {
    "name": "minus-circle",
    "pascal_name": "MinusCircle"
  },
  {
    "name": "minus-square",
    "pascal_name": "MinusSquare"
  },
  {
    "name": "money",
    "pascal_name": "Money"
  },
  {
    "name": "money-wavy",
    "pascal_name": "MoneyWavy"
  },
  {
    "name": "monitor",
    "pascal_name": "Monitor"
  },
  {
    "name": "monitor-arrow-up",
    "pascal_name": "MonitorArrowUp"
  },
  {
    "name": "monitor-play",
    "pascal_name": "MonitorPlay"
  },
  {
    "name": "moon",
    "pascal_name": "Moon"
  },
  {
    "name": "moon-stars",
    "pascal_name": "MoonStars"
  },
  {
    "name": "moped",
    "pascal_name": "Moped"
  },
  {
    "name": "moped-front",
    "pascal_name": "MopedFront"
  },
  {
    "name": "mosque",
    "pascal_name": "Mosque"
  },
  {
    "name": "motorcycle",
    "pascal_name": "Motorcycle"
  },
  {
    "name": "mountains",
    "pascal_name": "Mountains"
  },
  {
    "name": "mouse",
    "pascal_name": "Mouse"
  },
  {
    "name": "mouse-left-click",
    "pascal_name": "MouseLeftClick"
  },
  {
    "name": "mouse-middle-click",
    "pascal_name": "MouseMiddleClick"
  },
  {
    "name": "mouse-right-click",
    "pascal_name": "MouseRightClick"
  },
  {
    "name": "mouse-scroll",
    "pascal_name": "MouseScroll"
  },
  {
    "name": "mouse-simple",
    "pascal_name": "MouseSimple"
  },
  {
    "name": "music-note",
    "pascal_name": "MusicNote"
  },
  {
    "name": "music-note-simple",
    "pascal_name": "MusicNoteSimple"
  },
  {
    "name": "music-notes",
    "pascal_name": "MusicNotes"
  },
  {
    "name": "music-notes-minus",
    "pascal_name": "MusicNotesMinus"
  },
  {
    "name": "music-notes-plus",
    "pascal_name": "MusicNotesPlus"
  },
  {
    "name": "music-notes-simple",
    "pascal_name": "MusicNotesSimple"
  },
  {
    "name": "navigation-arrow",
    "pascal_name": "NavigationArrow"
  },
  {
    "name": "needle",
    "pascal_name": "Needle"
  },
  {
    "name": "network",
    "pascal_name": "Network"
  },
  {
    "name": "network-slash",
    "pascal_name": "NetworkSlash"
  },
  {
    "name": "network-x",
    "pascal_name": "NetworkX"
  },
  {
    "name": "newspaper",
    "pascal_name": "Newspaper"
  },
  {
    "name": "newspaper-clipping",
    "pascal_name": "NewspaperClipping"
  },
  {
    "name": "not-equals",
    "pascal_name": "NotEquals"
  },
  {
    "name": "not-member-of",
    "pascal_name": "NotMemberOf"
  },
  {
    "name": "not-subset-of",
    "pascal_name": "NotSubsetOf"
  },
  {
    "name": "not-superset-of",
    "pascal_name": "NotSupersetOf"
  },
  {
    "name": "notches",
    "pascal_name": "Notches"
  },
  {
    "name": "note",
    "pascal_name": "Note"
  },
  {
    "name": "note-blank",
    "pascal_name": "NoteBlank"
  },
  {
    "name": "note-pencil",
    "pascal_name": "NotePencil"
  },
  {
    "name": "notebook",
    "pascal_name": "Notebook"
  },
  {
    "name": "notepad",
    "pascal_name": "Notepad"
  },
  {
    "name": "notification",
    "pascal_name": "Notification"
  },
  {
    "name": "notion-logo",
    "pascal_name": "NotionLogo"
  },
  {
    "name": "nuclear-plant",
    "pascal_name": "NuclearPlant"
  },
  {
    "name": "number-circle-eight",
    "pascal_name": "NumberCircleEight"
  },
  {
    "name": "number-circle-five",
    "pascal_name": "NumberCircleFive"
  },
  {
    "name": "number-circle-four",
    "pascal_name": "NumberCircleFour"
  },
  {
    "name": "number-circle-nine",
    "pascal_name": "NumberCircleNine"
  },
  {
    "name": "number-circle-one",
    "pascal_name": "NumberCircleOne"
  },
  {
    "name": "number-circle-seven",
    "pascal_name": "NumberCircleSeven"
  },
  {
    "name": "number-circle-six",
    "pascal_name": "NumberCircleSix"
  },
  {
    "name": "number-circle-three",
    "pascal_name": "NumberCircleThree"
  },
  {
    "name": "number-circle-two",
    "pascal_name": "NumberCircleTwo"
  },
  {
    "name": "number-circle-zero",
    "pascal_name": "NumberCircleZero"
  },
  {
    "name": "number-eight",
    "pascal_name": "NumberEight"
  },
  {
    "name": "number-five",
    "pascal_name": "NumberFive"
  },
  {
    "name": "number-four",
    "pascal_name": "NumberFour"
  },
  {
    "name": "number-nine",
    "pascal_name": "NumberNine"
  },
  {
    "name": "number-one",
    "pascal_name": "NumberOne"
  },
  {
    "name": "number-seven",
    "pascal_name": "NumberSeven"
  },
  {
    "name": "number-six",
    "pascal_name": "NumberSix"
  },
  {
    "name": "number-square-eight",
    "pascal_name": "NumberSquareEight"
  },
  {
    "name": "number-square-five",
    "pascal_name": "NumberSquareFive"
  },
  {
    "name": "number-square-four",
    "pascal_name": "NumberSquareFour"
  },
  {
    "name": "number-square-nine",
    "pascal_name": "NumberSquareNine"
  },
  {
    "name": "number-square-one",
    "pascal_name": "NumberSquareOne"
  },
  {
    "name": "number-square-seven",
    "pascal_name": "NumberSquareSeven"
  },
  {
    "name": "number-square-six",
    "pascal_name": "NumberSquareSix"
  },
  {
    "name": "number-square-three",
    "pascal_name": "NumberSquareThree"
  },
  {
    "name": "number-square-two",
    "pascal_name": "NumberSquareTwo"
  },
  {
    "name": "number-square-zero",
    "pascal_name": "NumberSquareZero"
  },
  {
    "name": "number-three",
    "pascal_name": "NumberThree"
  },
  {
    "name": "number-two",
    "pascal_name": "NumberTwo"
  },
  {
    "name": "number-zero",
    "pascal_name": "NumberZero"
  },
  {
    "name": "numpad",
    "pascal_name": "Numpad"
  },
  {
    "name": "nut",
    "pascal_name": "Nut"
  },
  {
    "name": "ny-times-logo",
    "pascal_name": "NyTimesLogo"
  },
  {
    "name": "octagon",
    "pascal_name": "Octagon"
  },
  {
    "name": "office-chair",
    "pascal_name": "OfficeChair"
  },
  {
    "name": "onigiri",
    "pascal_name": "Onigiri"
  },
  {
    "name": "open-ai-logo",
    "pascal_name": "OpenAiLogo"
  },
  {
    "name": "option",
    "pascal_name": "Option"
  },
  {
    "name": "orange",
    "pascal_name": "Orange"
  },
  {
    "name": "orange-slice",
    "pascal_name": "OrangeSlice"
  },
  {
    "name": "oven",
    "pascal_name": "Oven"
  },
  {
    "name": "package",
    "pascal_name": "Package"
  },
  {
    "name": "paint-brush",
    "pascal_name": "PaintBrush"
  },
  {
    "name": "paint-brush-broad",
    "pascal_name": "PaintBrushBroad"
  },
  {
    "name": "paint-brush-household",
    "pascal_name": "PaintBrushHousehold"
  },
  {
    "name": "paint-bucket",
    "pascal_name": "PaintBucket"
  },
  {
    "name": "paint-roller",
    "pascal_name": "PaintRoller"
  },
  {
    "name": "palette",
    "pascal_name": "Palette"
  },
  {
    "name": "panorama",
    "pascal_name": "Panorama"
  },
  {
    "name": "pants",
    "pascal_name": "Pants"
  },
  {
    "name": "paper-plane",
    "pascal_name": "PaperPlane"
  },
  {
    "name": "paper-plane-right",
    "pascal_name": "PaperPlaneRight"
  },
  {
    "name": "paper-plane-tilt",
    "pascal_name": "PaperPlaneTilt"
  },
  {
    "name": "paperclip",
    "pascal_name": "Paperclip"
  },
  {
    "name": "paperclip-horizontal",
    "pascal_name": "PaperclipHorizontal"
  },
  {
    "name": "parachute",
    "pascal_name": "Parachute"
  },
  {
    "name": "paragraph",
    "pascal_name": "Paragraph"
  },
  {
    "name": "parallelogram",
    "pascal_name": "Parallelogram"
  },
  {
    "name": "park",
    "pascal_name": "Park"
  },
  {
    "name": "password",
    "pascal_name": "Password"
  },
  {
    "name": "path",
    "pascal_name": "Path"
  },
  {
    "name": "patreon-logo",
    "pascal_name": "PatreonLogo"
  },
  {
    "name": "pause",
    "pascal_name": "Pause"
  },
  {
    "name": "pause-circle",
    "pascal_name": "PauseCircle"
  },
  {
    "name": "paw-print",
    "pascal_name": "PawPrint"
  },
  {
    "name": "paypal-logo",
    "pascal_name": "PaypalLogo"
  },
  {
    "name": "peace",
    "pascal_name": "Peace"
  },
  {
    "name": "pen",
    "pascal_name": "Pen"
  },
  {
    "name": "pen-nib",
    "pascal_name": "PenNib"
  },
  {
    "name": "pen-nib-straight",
    "pascal_name": "PenNibStraight"
  },
  {
    "name": "pencil",
    "pascal_name": "Pencil"
  },
  {
    "name": "pencil-circle",
    "pascal_name": "PencilCircle"
  },
  {
    "name": "pencil-line",
    "pascal_name": "PencilLine"
  },
  {
    "name": "pencil-ruler",
    "pascal_name": "PencilRuler"
  },
  {
    "name": "pencil-simple",
    "pascal_name": "PencilSimple"
  },
  {
    "name": "pencil-simple-line",
    "pascal_name": "PencilSimpleLine"
  },
  {
    "name": "pencil-simple-slash",
    "pascal_name": "PencilSimpleSlash"
  },
  {
    "name": "pencil-slash",
    "pascal_name": "PencilSlash"
  },
  {
    "name": "pentagon",
    "pascal_name": "Pentagon"
  },
  {
    "name": "pentagram",
    "pascal_name": "Pentagram"
  },
  {
    "name": "pepper",
    "pascal_name": "Pepper"
  },
  {
    "name": "percent",
    "pascal_name": "Percent"
  },
  {
    "name": "person",
    "pascal_name": "Person"
  },
  {
    "name": "person-arms-spread",
    "pascal_name": "PersonArmsSpread"
  },
  {
    "name": "person-simple",
    "pascal_name": "PersonSimple"
  },
  {
    "name": "person-simple-bike",
    "pascal_name": "PersonSimpleBike"
  },
  {
    "name": "person-simple-circle",
    "pascal_name": "PersonSimpleCircle"
  },
  {
    "name": "person-simple-hike",
    "pascal_name": "PersonSimpleHike"
  },
  {
    "name": "person-simple-run",
    "pascal_name": "PersonSimpleRun"
  },
  {
    "name": "person-simple-ski",
    "pascal_name": "PersonSimpleSki"
  },
  {
    "name": "person-simple-snowboard",
    "pascal_name": "PersonSimpleSnowboard"
  },
  {
    "name": "person-simple-swim",
    "pascal_name": "PersonSimpleSwim"
  },
  {
    "name": "person-simple-tai-chi",
    "pascal_name": "PersonSimpleTaiChi"
  },
  {
    "name": "person-simple-throw",
    "pascal_name": "PersonSimpleThrow"
  },
  {
    "name": "person-simple-walk",
    "pascal_name": "PersonSimpleWalk"
  },
  {
    "name": "perspective",
    "pascal_name": "Perspective"
  },
  {
    "name": "phone",
    "pascal_name": "Phone"
  },
  {
    "name": "phone-call",
    "pascal_name": "PhoneCall"
  },
  {
    "name": "phone-disconnect",
    "pascal_name": "PhoneDisconnect"
  },
  {
    "name": "phone-incoming",
    "pascal_name": "PhoneIncoming"
  },
  {
    "name": "phone-list",
    "pascal_name": "PhoneList"
  },
  {
    "name": "phone-outgoing",
    "pascal_name": "PhoneOutgoing"
  },
  {
    "name": "phone-pause",
    "pascal_name": "PhonePause"
  },
  {
    "name": "phone-plus",
    "pascal_name": "PhonePlus"
  },
  {
    "name": "phone-slash",
    "pascal_name": "PhoneSlash"
  },
  {
    "name": "phone-transfer",
    "pascal_name": "PhoneTransfer"
  },
  {
    "name": "phone-x",
    "pascal_name": "PhoneX"
  },
  {
    "name": "phosphor-logo",
    "pascal_name": "PhosphorLogo"
  },
  {
    "name": "pi",
    "pascal_name": "Pi"
  },
  {
    "name": "piano-keys",
    "pascal_name": "PianoKeys"
  },
  {
    "name": "picnic-table",
    "pascal_name": "PicnicTable"
  },
  {
    "name": "picture-in-picture",
    "pascal_name": "PictureInPicture"
  },
  {
    "name": "piggy-bank",
    "pascal_name": "PiggyBank"
  },
  {
    "name": "pill",
    "pascal_name": "Pill"
  },
  {
    "name": "ping-pong",
    "pascal_name": "PingPong"
  },
  {
    "name": "pint-glass",
    "pascal_name": "PintGlass"
  },
  {
    "name": "pinterest-logo",
    "pascal_name": "PinterestLogo"
  },
  {
    "name": "pinwheel",
    "pascal_name": "Pinwheel"
  },
  {
    "name": "pipe",
    "pascal_name": "Pipe"
  },
  {
    "name": "pipe-wrench",
    "pascal_name": "PipeWrench"
  },
  {
    "name": "pix-logo",
    "pascal_name": "PixLogo"
  },
  {
    "name": "pizza",
    "pascal_name": "Pizza"
  },
  {
    "name": "placeholder",
    "pascal_name": "Placeholder"
  },
  {
    "name": "planet",
    "pascal_name": "Planet"
  },
  {
    "name": "plant",
    "pascal_name": "Plant"
  },
  {
    "name": "play",
    "pascal_name": "Play"
  },
  {
    "name": "play-circle",
    "pascal_name": "PlayCircle"
  },
  {
    "name": "play-pause",
    "pascal_name": "PlayPause"
  },
  {
    "name": "playlist",
    "pascal_name": "Playlist"
  },
  {
    "name": "plug",
    "pascal_name": "Plug"
  },
  {
    "name": "plug-charging",
    "pascal_name": "PlugCharging"
  },
  {
    "name": "plugs",
    "pascal_name": "Plugs"
  },
  {
    "name": "plugs-connected",
    "pascal_name": "PlugsConnected"
  },
  {
    "name": "plus",
    "pascal_name": "Plus"
  },
  {
    "name": "plus-circle",
    "pascal_name": "PlusCircle"
  },
  {
    "name": "plus-minus",
    "pascal_name": "PlusMinus"
  },
  {
    "name": "plus-square",
    "pascal_name": "PlusSquare"
  },
  {
    "name": "poker-chip",
    "pascal_name": "PokerChip"
  },
  {
    "name": "police-car",
    "pascal_name": "PoliceCar"
  },
  {
    "name": "polygon",
    "pascal_name": "Polygon"
  },
  {
    "name": "popcorn",
    "pascal_name": "Popcorn"
  },
  {
    "name": "popsicle",
    "pascal_name": "Popsicle"
  },
  {
    "name": "potted-plant",
    "pascal_name": "PottedPlant"
  },
  {
    "name": "power",
    "pascal_name": "Power"
  },
  {
    "name": "prescription",
    "pascal_name": "Prescription"
  },
  {
    "name": "presentation",
    "pascal_name": "Presentation"
  },
  {
    "name": "presentation-chart",
    "pascal_name": "PresentationChart"
  },
  {
    "name": "printer",
    "pascal_name": "Printer"
  },
  {
    "name": "prohibit",
    "pascal_name": "Prohibit"
  },
  {
    "name": "prohibit-inset",
    "pascal_name": "ProhibitInset"
  },
  {
    "name": "projector-screen",
    "pascal_name": "ProjectorScreen"
  },
  {
    "name": "projector-screen-chart",
    "pascal_name": "ProjectorScreenChart"
  },
  {
    "name": "pulse",
    "pascal_name": "Pulse",
    "alias": {
      "name": "activity",
      "pascal_name": "Activity"
    }
  },
  {
    "name": "push-pin",
    "pascal_name": "PushPin"
  },
  {
    "name": "push-pin-simple",
    "pascal_name": "PushPinSimple"
  },
  {
    "name": "push-pin-simple-slash",
    "pascal_name": "PushPinSimpleSlash"
  },
  {
    "name": "push-pin-slash",
    "pascal_name": "PushPinSlash"
  },
  {
    "name": "puzzle-piece",
    "pascal_name": "PuzzlePiece"
  },
  {
    "name": "qr-code",
    "pascal_name": "QrCode"
  },
  {
    "name": "question",
    "pascal_name": "Question"
  },
  {
    "name": "question-mark",
    "pascal_name": "QuestionMark"
  },
  {
    "name": "queue",
    "pascal_name": "Queue"
  },
  {
    "name": "quotes",
    "pascal_name": "Quotes"
  },
  {
    "name": "rabbit",
    "pascal_name": "Rabbit"
  },
  {
    "name": "racquet",
    "pascal_name": "Racquet"
  },
  {
    "name": "radical",
    "pascal_name": "Radical"
  },
  {
    "name": "radio",
    "pascal_name": "Radio"
  },
  {
    "name": "radio-button",
    "pascal_name": "RadioButton"
  },
  {
    "name": "radioactive",
    "pascal_name": "Radioactive"
  },
  {
    "name": "rainbow",
    "pascal_name": "Rainbow"
  },
  {
    "name": "rainbow-cloud",
    "pascal_name": "RainbowCloud"
  },
  {
    "name": "ranking",
    "pascal_name": "Ranking"
  },
  {
    "name": "read-cv-logo",
    "pascal_name": "ReadCvLogo"
  },
  {
    "name": "receipt",
    "pascal_name": "Receipt"
  },
  {
    "name": "receipt-x",
    "pascal_name": "ReceiptX"
  },
  {
    "name": "record",
    "pascal_name": "Record"
  },
  {
    "name": "rectangle",
    "pascal_name": "Rectangle"
  },
  {
    "name": "rectangle-dashed",
    "pascal_name": "RectangleDashed"
  },
  {
    "name": "recycle",
    "pascal_name": "Recycle"
  },
  {
    "name": "reddit-logo",
    "pascal_name": "RedditLogo"
  },
  {
    "name": "repeat",
    "pascal_name": "Repeat"
  },
  {
    "name": "repeat-once",
    "pascal_name": "RepeatOnce"
  },
  {
    "name": "replit-logo",
    "pascal_name": "ReplitLogo"
  },
  {
    "name": "resize",
    "pascal_name": "Resize"
  },
  {
    "name": "rewind",
    "pascal_name": "Rewind"
  },
  {
    "name": "rewind-circle",
    "pascal_name": "RewindCircle"
  },
  {
    "name": "road-horizon",
    "pascal_name": "RoadHorizon"
  },
  {
    "name": "robot",
    "pascal_name": "Robot"
  },
  {
    "name": "rocket",
    "pascal_name": "Rocket"
  },
  {
    "name": "rocket-launch",
    "pascal_name": "RocketLaunch"
  },
  {
    "name": "rows",
    "pascal_name": "Rows"
  },
  {
    "name": "rows-plus-bottom",
    "pascal_name": "RowsPlusBottom"
  },
  {
    "name": "rows-plus-top",
    "pascal_name": "RowsPlusTop"
  },
  {
    "name": "rss",
    "pascal_name": "Rss"
  },
  {
    "name": "rss-simple",
    "pascal_name": "RssSimple"
  },
  {
    "name": "rug",
    "pascal_name": "Rug"
  },
  {
    "name": "ruler",
    "pascal_name": "Ruler"
  },
  {
    "name": "sailboat",
    "pascal_name": "Sailboat"
  },
  {
    "name": "scales",
    "pascal_name": "Scales"
  },
  {
    "name": "scan",
    "pascal_name": "Scan"
  },
  {
    "name": "scan-smiley",
    "pascal_name": "ScanSmiley"
  },
  {
    "name": "scissors",
    "pascal_name": "Scissors"
  },
  {
    "name": "scooter",
    "pascal_name": "Scooter"
  },
  {
    "name": "screencast",
    "pascal_name": "Screencast"
  },
  {
    "name": "screwdriver",
    "pascal_name": "Screwdriver"
  },
  {
    "name": "scribble",
    "pascal_name": "Scribble"
  },
  {
    "name": "scribble-loop",
    "pascal_name": "ScribbleLoop"
  },
  {
    "name": "scroll",
    "pascal_name": "Scroll"
  },
  {
    "name": "seal",
    "pascal_name": "Seal",
    "alias": {
      "name": "circle-wavy",
      "pascal_name": "CircleWavy"
    }
  },
  {
    "name": "seal-check",
    "pascal_name": "SealCheck",
    "alias": {
      "name": "circle-wavy-check",
      "pascal_name": "CircleWavyCheck"
    }
  },
  {
    "name": "seal-percent",
    "pascal_name": "SealPercent"
  },
  {
    "name": "seal-question",
    "pascal_name": "SealQuestion",
    "alias": {
      "name": "circle-wavy-question",
      "pascal_name": "CircleWavyQuestion"
    }
  },
  {
    "name": "seal-warning",
    "pascal_name": "SealWarning",
    "alias": {
      "name": "circle-wavy-warning",
      "pascal_name": "CircleWavyWarning"
    }
  },
  {
    "name": "seat",
    "pascal_name": "Seat"
  },
  {
    "name": "seatbelt",
    "pascal_name": "Seatbelt"
  },
  {
    "name": "security-camera",
    "pascal_name": "SecurityCamera"
  },
  {
    "name": "selection",
    "pascal_name": "Selection"
  },
  {
    "name": "selection-all",
    "pascal_name": "SelectionAll"
  },
  {
    "name": "selection-background",
    "pascal_name": "SelectionBackground"
  },
  {
    "name": "selection-foreground",
    "pascal_name": "SelectionForeground"
  },
  {
    "name": "selection-inverse",
    "pascal_name": "SelectionInverse"
  },
  {
    "name": "selection-plus",
    "pascal_name": "SelectionPlus"
  },
  {
    "name": "selection-slash",
    "pascal_name": "SelectionSlash"
  },
  {
    "name": "shapes",
    "pascal_name": "Shapes"
  },
  {
    "name": "share",
    "pascal_name": "Share"
  },
  {
    "name": "share-fat",
    "pascal_name": "ShareFat"
  },
  {
    "name": "share-network",
    "pascal_name": "ShareNetwork"
  },
  {
    "name": "shield",
    "pascal_name": "Shield"
  },
  {
    "name": "shield-check",
    "pascal_name": "ShieldCheck"
  },
  {
    "name": "shield-checkered",
    "pascal_name": "ShieldCheckered"
  },
  {
    "name": "shield-chevron",
    "pascal_name": "ShieldChevron"
  },
  {
    "name": "shield-plus",
    "pascal_name": "ShieldPlus"
  },
  {
    "name": "shield-slash",
    "pascal_name": "ShieldSlash"
  },
  {
    "name": "shield-star",
    "pascal_name": "ShieldStar"
  },
  {
    "name": "shield-warning",
    "pascal_name": "ShieldWarning"
  },
  {
    "name": "shipping-container",
    "pascal_name": "ShippingContainer"
  },
  {
    "name": "shirt-folded",
    "pascal_name": "ShirtFolded"
  },
  {
    "name": "shooting-star",
    "pascal_name": "ShootingStar"
  },
  {
    "name": "shopping-bag",
    "pascal_name": "ShoppingBag"
  },
  {
    "name": "shopping-bag-open",
    "pascal_name": "ShoppingBagOpen"
  },
  {
    "name": "shopping-cart",
    "pascal_name": "ShoppingCart"
  },
  {
    "name": "shopping-cart-simple",
    "pascal_name": "ShoppingCartSimple"
  },
  {
    "name": "shovel",
    "pascal_name": "Shovel"
  },
  {
    "name": "shower",
    "pascal_name": "Shower"
  },
  {
    "name": "shrimp",
    "pascal_name": "Shrimp"
  },
  {
    "name": "shuffle",
    "pascal_name": "Shuffle"
  },
  {
    "name": "shuffle-angular",
    "pascal_name": "ShuffleAngular"
  },
  {
    "name": "shuffle-simple",
    "pascal_name": "ShuffleSimple"
  },
  {
    "name": "sidebar",
    "pascal_name": "Sidebar"
  },
  {
    "name": "sidebar-simple",
    "pascal_name": "SidebarSimple"
  },
  {
    "name": "sigma",
    "pascal_name": "Sigma"
  },
  {
    "name": "sign-in",
    "pascal_name": "SignIn"
  },
  {
    "name": "sign-out",
    "pascal_name": "SignOut"
  },
  {
    "name": "signature",
    "pascal_name": "Signature"
  },
  {
    "name": "signpost",
    "pascal_name": "Signpost"
  },
  {
    "name": "sim-card",
    "pascal_name": "SimCard"
  },
  {
    "name": "siren",
    "pascal_name": "Siren"
  },
  {
    "name": "sketch-logo",
    "pascal_name": "SketchLogo"
  },
  {
    "name": "skip-back",
    "pascal_name": "SkipBack"
  },
  {
    "name": "skip-back-circle",
    "pascal_name": "SkipBackCircle"
  },
  {
    "name": "skip-forward",
    "pascal_name": "SkipForward"
  },
  {
    "name": "skip-forward-circle",
    "pascal_name": "SkipForwardCircle"
  },
  {
    "name": "skull",
    "pascal_name": "Skull"
  },
  {
    "name": "skype-logo",
    "pascal_name": "SkypeLogo"
  },
  {
    "name": "slack-logo",
    "pascal_name": "SlackLogo"
  },
  {
    "name": "sliders",
    "pascal_name": "Sliders"
  },
  {
    "name": "sliders-horizontal",
    "pascal_name": "SlidersHorizontal"
  },
  {
    "name": "slideshow",
    "pascal_name": "Slideshow"
  },
  {
    "name": "smiley",
    "pascal_name": "Smiley"
  },
  {
    "name": "smiley-angry",
    "pascal_name": "SmileyAngry"
  },
  {
    "name": "smiley-blank",
    "pascal_name": "SmileyBlank"
  },
  {
    "name": "smiley-meh",
    "pascal_name": "SmileyMeh"
  },
  {
    "name": "smiley-melting",
    "pascal_name": "SmileyMelting"
  },
  {
    "name": "smiley-nervous",
    "pascal_name": "SmileyNervous"
  },
  {
    "name": "smiley-sad",
    "pascal_name": "SmileySad"
  },
  {
    "name": "smiley-sticker",
    "pascal_name": "SmileySticker"
  },
  {
    "name": "smiley-wink",
    "pascal_name": "SmileyWink"
  },
  {
    "name": "smiley-x-eyes",
    "pascal_name": "SmileyXEyes"
  },
  {
    "name": "snapchat-logo",
    "pascal_name": "SnapchatLogo"
  },
  {
    "name": "sneaker",
    "pascal_name": "Sneaker"
  },
  {
    "name": "sneaker-move",
    "pascal_name": "SneakerMove"
  },
  {
    "name": "snowflake",
    "pascal_name": "Snowflake"
  },
  {
    "name": "soccer-ball",
    "pascal_name": "SoccerBall"
  },
  {
    "name": "sock",
    "pascal_name": "Sock"
  },
  {
    "name": "solar-panel",
    "pascal_name": "SolarPanel"
  },
  {
    "name": "solar-roof",
    "pascal_name": "SolarRoof"
  },
  {
    "name": "sort-ascending",
    "pascal_name": "SortAscending"
  },
  {
    "name": "sort-descending",
    "pascal_name": "SortDescending"
  },
  {
    "name": "soundcloud-logo",
    "pascal_name": "SoundcloudLogo"
  },
  {
    "name": "spade",
    "pascal_name": "Spade"
  },
  {
    "name": "sparkle",
    "pascal_name": "Sparkle"
  },
  {
    "name": "speaker-hifi",
    "pascal_name": "SpeakerHifi"
  },
  {
    "name": "speaker-high",
    "pascal_name": "SpeakerHigh"
  },
  {
    "name": "speaker-low",
    "pascal_name": "SpeakerLow"
  },
  {
    "name": "speaker-none",
    "pascal_name": "SpeakerNone"
  },
  {
    "name": "speaker-simple-high",
    "pascal_name": "SpeakerSimpleHigh"
  },
  {
    "name": "speaker-simple-low",
    "pascal_name": "SpeakerSimpleLow"
  },
  {
    "name": "speaker-simple-none",
    "pascal_name": "SpeakerSimpleNone"
  },
  {
    "name": "speaker-simple-slash",
    "pascal_name": "SpeakerSimpleSlash"
  },
  {
    "name": "speaker-simple-x",
    "pascal_name": "SpeakerSimpleX"
  },
  {
    "name": "speaker-slash",
    "pascal_name": "SpeakerSlash"
  },
  {
    "name": "speaker-x",
    "pascal_name": "SpeakerX"
  },
  {
    "name": "speedometer",
    "pascal_name": "Speedometer"
  },
  {
    "name": "sphere",
    "pascal_name": "Sphere"
  },
  {
    "name": "spinner",
    "pascal_name": "Spinner"
  },
  {
    "name": "spinner-ball",
    "pascal_name": "SpinnerBall"
  },
  {
    "name": "spinner-gap",
    "pascal_name": "SpinnerGap"
  },
  {
    "name": "spiral",
    "pascal_name": "Spiral"
  },
  {
    "name": "split-horizontal",
    "pascal_name": "SplitHorizontal"
  },
  {
    "name": "split-vertical",
    "pascal_name": "SplitVertical"
  },
  {
    "name": "spotify-logo",
    "pascal_name": "SpotifyLogo"
  },
  {
    "name": "spray-bottle",
    "pascal_name": "SprayBottle"
  },
  {
    "name": "square",
    "pascal_name": "Square"
  },
  {
    "name": "square-half",
    "pascal_name": "SquareHalf"
  },
  {
    "name": "square-half-bottom",
    "pascal_name": "SquareHalfBottom"
  },
  {
    "name": "square-logo",
    "pascal_name": "SquareLogo"
  },
  {
    "name": "square-split-horizontal",
    "pascal_name": "SquareSplitHorizontal"
  },
  {
    "name": "square-split-vertical",
    "pascal_name": "SquareSplitVertical"
  },
  {
    "name": "squares-four",
    "pascal_name": "SquaresFour"
  },
  {
    "name": "stack",
    "pascal_name": "Stack"
  },
  {
    "name": "stack-minus",
    "pascal_name": "StackMinus"
  },
  {
    "name": "stack-overflow-logo",
    "pascal_name": "StackOverflowLogo"
  },
  {
    "name": "stack-plus",
    "pascal_name": "StackPlus"
  },
  {
    "name": "stack-simple",
    "pascal_name": "StackSimple"
  },
  {
    "name": "stairs",
    "pascal_name": "Stairs"
  },
  {
    "name": "stamp",
    "pascal_name": "Stamp"
  },
  {
    "name": "standard-definition",
    "pascal_name": "StandardDefinition"
  },
  {
    "name": "star",
    "pascal_name": "Star"
  },
  {
    "name": "star-and-crescent",
    "pascal_name": "StarAndCrescent"
  },
  {
    "name": "star-four",
    "pascal_name": "StarFour"
  },
  {
    "name": "star-half",
    "pascal_name": "StarHalf"
  },
  {
    "name": "star-of-david",
    "pascal_name": "StarOfDavid"
  },
  {
    "name": "steam-logo",
    "pascal_name": "SteamLogo"
  },
  {
    "name": "steering-wheel",
    "pascal_name": "SteeringWheel"
  },
  {
    "name": "steps",
    "pascal_name": "Steps"
  },
  {
    "name": "stethoscope",
    "pascal_name": "Stethoscope"
  },
  {
    "name": "sticker",
    "pascal_name": "Sticker"
  },
  {
    "name": "stool",
    "pascal_name": "Stool"
  },
  {
    "name": "stop",
    "pascal_name": "Stop"
  },
  {
    "name": "stop-circle",
    "pascal_name": "StopCircle"
  },
  {
    "name": "storefront",
    "pascal_name": "Storefront"
  },
  {
    "name": "strategy",
    "pascal_name": "Strategy"
  },
  {
    "name": "stripe-logo",
    "pascal_name": "StripeLogo"
  },
  {
    "name": "student",
    "pascal_name": "Student"
  },
  {
    "name": "subset-of",
    "pascal_name": "SubsetOf"
  },
  {
    "name": "subset-proper-of",
    "pascal_name": "SubsetProperOf"
  },
  {
    "name": "subtitles",
    "pascal_name": "Subtitles"
  },
  {
    "name": "subtitles-slash",
    "pascal_name": "SubtitlesSlash"
  },
  {
    "name": "subtract",
    "pascal_name": "Subtract"
  },
  {
    "name": "subtract-square",
    "pascal_name": "SubtractSquare"
  },
  {
    "name": "subway",
    "pascal_name": "Subway"
  },
  {
    "name": "suitcase",
    "pascal_name": "Suitcase"
  },
  {
    "name": "suitcase-rolling",
    "pascal_name": "SuitcaseRolling"
  },
  {
    "name": "suitcase-simple",
    "pascal_name": "SuitcaseSimple"
  },
  {
    "name": "sun",
    "pascal_name": "Sun"
  },
  {
    "name": "sun-dim",
    "pascal_name": "SunDim"
  },
  {
    "name": "sun-horizon",
    "pascal_name": "SunHorizon"
  },
  {
    "name": "sunglasses",
    "pascal_name": "Sunglasses"
  },
  {
    "name": "superset-of",
    "pascal_name": "SupersetOf"
  },
  {
    "name": "superset-proper-of",
    "pascal_name": "SupersetProperOf"
  },
  {
    "name": "swap",
    "pascal_name": "Swap"
  },
  {
    "name": "swatches",
    "pascal_name": "Swatches"
  },
  {
    "name": "swimming-pool",
    "pascal_name": "SwimmingPool"
  },
  {
    "name": "sword",
    "pascal_name": "Sword"
  },
  {
    "name": "synagogue",
    "pascal_name": "Synagogue"
  },
  {
    "name": "syringe",
    "pascal_name": "Syringe"
  },
  {
    "name": "t-shirt",
    "pascal_name": "TShirt"
  },
  {
    "name": "table",
    "pascal_name": "Table"
  },
  {
    "name": "tabs",
    "pascal_name": "Tabs"
  },
  {
    "name": "tag",
    "pascal_name": "Tag"
  },
  {
    "name": "tag-chevron",
    "pascal_name": "TagChevron"
  },
  {
    "name": "tag-simple",
    "pascal_name": "TagSimple"
  },
  {
    "name": "target",
    "pascal_name": "Target"
  },
  {
    "name": "taxi",
    "pascal_name": "Taxi"
  },
  {
    "name": "tea-bag",
    "pascal_name": "TeaBag"
  },
  {
    "name": "telegram-logo",
    "pascal_name": "TelegramLogo"
  },
  {
    "name": "television",
    "pascal_name": "Television"
  },
  {
    "name": "television-simple",
    "pascal_name": "TelevisionSimple"
  },
  {
    "name": "tennis-ball",
    "pascal_name": "TennisBall"
  },
  {
    "name": "tent",
    "pascal_name": "Tent"
  },
  {
    "name": "terminal",
    "pascal_name": "Terminal"
  },
  {
    "name": "terminal-window",
    "pascal_name": "TerminalWindow"
  },
  {
    "name": "test-tube",
    "pascal_name": "TestTube"
  },
  {
    "name": "text-a-underline",
    "pascal_name": "TextAUnderline"
  },
  {
    "name": "text-aa",
    "pascal_name": "TextAa"
  },
  {
    "name": "text-align-center",
    "pascal_name": "TextAlignCenter"
  },
  {
    "name": "text-align-justify",
    "pascal_name": "TextAlignJustify"
  },
  {
    "name": "text-align-left",
    "pascal_name": "TextAlignLeft"
  },
  {
    "name": "text-align-right",
    "pascal_name": "TextAlignRight"
  },
  {
    "name": "text-b",
    "pascal_name": "TextB",
    "alias": {
      "name": "text-bolder",
      "pascal_name": "TextBolder"
    }
  },
  {
    "name": "text-columns",
    "pascal_name": "TextColumns"
  },
  {
    "name": "text-h",
    "pascal_name": "TextH"
  },
  {
    "name": "text-h-five",
    "pascal_name": "TextHFive"
  },
  {
    "name": "text-h-four",
    "pascal_name": "TextHFour"
  },
  {
    "name": "text-h-one",
    "pascal_name": "TextHOne"
  },
  {
    "name": "text-h-six",
    "pascal_name": "TextHSix"
  },
  {
    "name": "text-h-three",
    "pascal_name": "TextHThree"
  },
  {
    "name": "text-h-two",
    "pascal_name": "TextHTwo"
  },
  {
    "name": "text-indent",
    "pascal_name": "TextIndent"
  },
  {
    "name": "text-italic",
    "pascal_name": "TextItalic"
  },
  {
    "name": "text-outdent",
    "pascal_name": "TextOutdent"
  },
  {
    "name": "text-strikethrough",
    "pascal_name": "TextStrikethrough"
  },
  {
    "name": "text-subscript",
    "pascal_name": "TextSubscript"
  },
  {
    "name": "text-superscript",
    "pascal_name": "TextSuperscript"
  },
  {
    "name": "text-t",
    "pascal_name": "TextT"
  },
  {
    "name": "text-t-slash",
    "pascal_name": "TextTSlash"
  },
  {
    "name": "text-underline",
    "pascal_name": "TextUnderline"
  },
  {
    "name": "textbox",
    "pascal_name": "Textbox"
  },
  {
    "name": "thermometer",
    "pascal_name": "Thermometer"
  },
  {
    "name": "thermometer-cold",
    "pascal_name": "ThermometerCold"
  },
  {
    "name": "thermometer-hot",
    "pascal_name": "ThermometerHot"
  },
  {
    "name": "thermometer-simple",
    "pascal_name": "ThermometerSimple"
  },
  {
    "name": "threads-logo",
    "pascal_name": "ThreadsLogo"
  },
  {
    "name": "three-d",
    "pascal_name": "ThreeD"
  },
  {
    "name": "thumbs-down",
    "pascal_name": "ThumbsDown"
  },
  {
    "name": "thumbs-up",
    "pascal_name": "ThumbsUp"
  },
  {
    "name": "ticket",
    "pascal_name": "Ticket"
  },
  {
    "name": "tidal-logo",
    "pascal_name": "TidalLogo"
  },
  {
    "name": "tiktok-logo",
    "pascal_name": "TiktokLogo"
  },
  {
    "name": "tilde",
    "pascal_name": "Tilde"
  },
  {
    "name": "timer",
    "pascal_name": "Timer"
  },
  {
    "name": "tip-jar",
    "pascal_name": "TipJar"
  },
  {
    "name": "tipi",
    "pascal_name": "Tipi"
  },
  {
    "name": "tire",
    "pascal_name": "Tire"
  },
  {
    "name": "toggle-left",
    "pascal_name": "ToggleLeft"
  },
  {
    "name": "toggle-right",
    "pascal_name": "ToggleRight"
  },
  {
    "name": "toilet",
    "pascal_name": "Toilet"
  },
  {
    "name": "toilet-paper",
    "pascal_name": "ToiletPaper"
  },
  {
    "name": "toolbox",
    "pascal_name": "Toolbox"
  },
  {
    "name": "tooth",
    "pascal_name": "Tooth"
  },
  {
    "name": "tornado",
    "pascal_name": "Tornado"
  },
  {
    "name": "tote",
    "pascal_name": "Tote"
  },
  {
    "name": "tote-simple",
    "pascal_name": "ToteSimple"
  },
  {
    "name": "towel",
    "pascal_name": "Towel"
  },
  {
    "name": "tractor",
    "pascal_name": "Tractor"
  },
  {
    "name": "trademark",
    "pascal_name": "Trademark"
  },
  {
    "name": "trademark-registered",
    "pascal_name": "TrademarkRegistered"
  },
  {
    "name": "traffic-cone",
    "pascal_name": "TrafficCone"
  },
  {
    "name": "traffic-sign",
    "pascal_name": "TrafficSign"
  },
  {
    "name": "traffic-signal",
    "pascal_name": "TrafficSignal"
  },
  {
    "name": "train",
    "pascal_name": "Train"
  },
  {
    "name": "train-regional",
    "pascal_name": "TrainRegional"
  },
  {
    "name": "train-simple",
    "pascal_name": "TrainSimple"
  },
  {
    "name": "tram",
    "pascal_name": "Tram"
  },
  {
    "name": "translate",
    "pascal_name": "Translate"
  },
  {
    "name": "trash",
    "pascal_name": "Trash"
  },
  {
    "name": "trash-simple",
    "pascal_name": "TrashSimple"
  },
  {
    "name": "tray",
    "pascal_name": "Tray"
  },
  {
    "name": "tray-arrow-down",
    "pascal_name": "TrayArrowDown",
    "alias": {
      "name": "archive-tray",
      "pascal_name": "ArchiveTray"
    }
  },
  {
    "name": "tray-arrow-up",
    "pascal_name": "TrayArrowUp"
  },
  {
    "name": "treasure-chest",
    "pascal_name": "TreasureChest"
  },
  {
    "name": "tree",
    "pascal_name": "Tree"
  },
  {
    "name": "tree-evergreen",
    "pascal_name": "TreeEvergreen"
  },
  {
    "name": "tree-palm",
    "pascal_name": "TreePalm"
  },
  {
    "name": "tree-structure",
    "pascal_name": "TreeStructure"
  },
  {
    "name": "tree-view",
    "pascal_name": "TreeView"
  },
  {
    "name": "trend-down",
    "pascal_name": "TrendDown"
  },
  {
    "name": "trend-up",
    "pascal_name": "TrendUp"
  },
  {
    "name": "triangle",
    "pascal_name": "Triangle"
  },
  {
    "name": "triangle-dashed",
    "pascal_name": "TriangleDashed"
  },
  {
    "name": "trolley",
    "pascal_name": "Trolley"
  },
  {
    "name": "trolley-suitcase",
    "pascal_name": "TrolleySuitcase"
  },
  {
    "name": "trophy",
    "pascal_name": "Trophy"
  },
  {
    "name": "truck",
    "pascal_name": "Truck"
  },
  {
    "name": "truck-trailer",
    "pascal_name": "TruckTrailer"
  },
  {
    "name": "tumblr-logo",
    "pascal_name": "TumblrLogo"
  },
  {
    "name": "twitch-logo",
    "pascal_name": "TwitchLogo"
  },
  {
    "name": "twitter-logo",
    "pascal_name": "TwitterLogo"
  },
  {
    "name": "umbrella",
    "pascal_name": "Umbrella"
  },
  {
    "name": "umbrella-simple",
    "pascal_name": "UmbrellaSimple"
  },
  {
    "name": "union",
    "pascal_name": "Union"
  },
  {
    "name": "unite",
    "pascal_name": "Unite"
  },
  {
    "name": "unite-square",
    "pascal_name": "UniteSquare"
  },
  {
    "name": "upload",
    "pascal_name": "Upload"
  },
  {
    "name": "upload-simple",
    "pascal_name": "UploadSimple"
  },
  {
    "name": "usb",
    "pascal_name": "Usb"
  },
  {
    "name": "user",
    "pascal_name": "User"
  },
  {
    "name": "user-check",
    "pascal_name": "UserCheck"
  },
  {
    "name": "user-circle",
    "pascal_name": "UserCircle"
  },
  {
    "name": "user-circle-check",
    "pascal_name": "UserCircleCheck"
  },
  {
    "name": "user-circle-dashed",
    "pascal_name": "UserCircleDashed"
  },
  {
    "name": "user-circle-gear",
    "pascal_name": "UserCircleGear"
  },
  {
    "name": "user-circle-minus",
    "pascal_name": "UserCircleMinus"
  },
  {
    "name": "user-circle-plus",
    "pascal_name": "UserCirclePlus"
  },
  {
    "name": "user-focus",
    "pascal_name": "UserFocus"
  },
  {
    "name": "user-gear",
    "pascal_name": "UserGear"
  },
  {
    "name": "user-list",
    "pascal_name": "UserList"
  },
  {
    "name": "user-minus",
    "pascal_name": "UserMinus"
  },
  {
    "name": "user-plus",
    "pascal_name": "UserPlus"
  },
  {
    "name": "user-rectangle",
    "pascal_name": "UserRectangle"
  },
  {
    "name": "user-sound",
    "pascal_name": "UserSound"
  },
  {
    "name": "user-square",
    "pascal_name": "UserSquare"
  },
  {
    "name": "user-switch",
    "pascal_name": "UserSwitch"
  },
  {
    "name": "users",
    "pascal_name": "Users"
  },
  {
    "name": "users-four",
    "pascal_name": "UsersFour"
  },
  {
    "name": "users-three",
    "pascal_name": "UsersThree"
  },
  {
    "name": "van",
    "pascal_name": "Van"
  },
  {
    "name": "vault",
    "pascal_name": "Vault"
  },
  {
    "name": "vector-three",
    "pascal_name": "VectorThree"
  },
  {
    "name": "vector-two",
    "pascal_name": "VectorTwo"
  },
  {
    "name": "vibrate",
    "pascal_name": "Vibrate"
  },
  {
    "name": "video",
    "pascal_name": "Video"
  },
  {
    "name": "video-camera",
    "pascal_name": "VideoCamera"
  },
  {
    "name": "video-camera-slash",
    "pascal_name": "VideoCameraSlash"
  },
  {
    "name": "video-conference",
    "pascal_name": "VideoConference"
  },
  {
    "name": "vignette",
    "pascal_name": "Vignette"
  },
  {
    "name": "vinyl-record",
    "pascal_name": "VinylRecord"
  },
  {
    "name": "virtual-reality",
    "pascal_name": "VirtualReality"
  },
  {
    "name": "virus",
    "pascal_name": "Virus"
  },
  {
    "name": "visor",
    "pascal_name": "Visor"
  },
  {
    "name": "voicemail",
    "pascal_name": "Voicemail"
  },
  {
    "name": "volleyball",
    "pascal_name": "Volleyball"
  },
  {
    "name": "wall",
    "pascal_name": "Wall"
  },
  {
    "name": "wallet",
    "pascal_name": "Wallet"
  },
  {
    "name": "warehouse",
    "pascal_name": "Warehouse"
  },
  {
    "name": "warning",
    "pascal_name": "Warning"
  },
  {
    "name": "warning-circle",
    "pascal_name": "WarningCircle"
  },
  {
    "name": "warning-diamond",
    "pascal_name": "WarningDiamond"
  },
  {
    "name": "warning-octagon",
    "pascal_name": "WarningOctagon"
  },
  {
    "name": "washing-machine",
    "pascal_name": "WashingMachine"
  },
  {
    "name": "watch",
    "pascal_name": "Watch"
  },
  {
    "name": "wave-sawtooth",
    "pascal_name": "WaveSawtooth"
  },
  {
    "name": "wave-sine",
    "pascal_name": "WaveSine"
  },
  {
    "name": "wave-square",
    "pascal_name": "WaveSquare"
  },
  {
    "name": "wave-triangle",
    "pascal_name": "WaveTriangle"
  },
  {
    "name": "waveform",
    "pascal_name": "Waveform"
  },
  {
    "name": "waveform-slash",
    "pascal_name": "WaveformSlash"
  },
  {
    "name": "waves",
    "pascal_name": "Waves"
  },
  {
    "name": "webcam",
    "pascal_name": "Webcam"
  },
  {
    "name": "webcam-slash",
    "pascal_name": "WebcamSlash"
  },
  {
    "name": "webhooks-logo",
    "pascal_name": "WebhooksLogo"
  },
  {
    "name": "wechat-logo",
    "pascal_name": "WechatLogo"
  },
  {
    "name": "whatsapp-logo",
    "pascal_name": "WhatsappLogo"
  },
  {
    "name": "wheelchair",
    "pascal_name": "Wheelchair"
  },
  {
    "name": "wheelchair-motion",
    "pascal_name": "WheelchairMotion"
  },
  {
    "name": "wifi-high",
    "pascal_name": "WifiHigh"
  },
  {
    "name": "wifi-low",
    "pascal_name": "WifiLow"
  },
  {
    "name": "wifi-medium",
    "pascal_name": "WifiMedium"
  },
  {
    "name": "wifi-none",
    "pascal_name": "WifiNone"
  },
  {
    "name": "wifi-slash",
    "pascal_name": "WifiSlash"
  },
  {
    "name": "wifi-x",
    "pascal_name": "WifiX"
  },
  {
    "name": "wind",
    "pascal_name": "Wind"
  },
  {
    "name": "windmill",
    "pascal_name": "Windmill"
  },
  {
    "name": "windows-logo",
    "pascal_name": "WindowsLogo"
  },
  {
    "name": "wine",
    "pascal_name": "Wine"
  },
  {
    "name": "wrench",
    "pascal_name": "Wrench"
  },
  {
    "name": "x",
    "pascal_name": "X"
  },
  {
    "name": "x-circle",
    "pascal_name": "XCircle"
  },
  {
    "name": "x-logo",
    "pascal_name": "XLogo"
  },
  {
    "name": "x-square",
    "pascal_name": "XSquare"
  },
  {
    "name": "yarn",
    "pascal_name": "Yarn"
  },
  {
    "name": "yin-yang",
    "pascal_name": "YinYang"
  },
  {
    "name": "youtube-logo",
    "pascal_name": "YoutubeLogo"
  }
];