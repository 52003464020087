import classNames from 'classnames';
import React, {useContext} from 'react';
import ClickableBlockOverlay from '../../../../../Poke/components/ClickableBlockOverlay';
import {
  BLOCK_RESOURCE_CENTER_TRIGGER,
  BLOCK_RESOURCE_CENTER_TRIGGER_ARROW,
} from '../../../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../../../Poke/context';
import './_Styles.scss';

const ResourceCenterTriggerArrow = () => {
  const {
    onBlockSelected,
    onContainerSelected,
    selectedBlock,
    inBuilder,
    blocks,
  } = useContext(PokeStateContext);

  const block = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_TRIGGER_ARROW
  );
  const triggerBlock = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_TRIGGER
  );

  if (block == null) {
    return <></>;
  }

  const {style} = block;
  const {
    fontSize,
    iconColor,
    backgroundColor,
    borderColor,
    borderRadius,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  } = style;

  return (
    <div
      className={classNames('block-resource-center-trigger-arrow', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_RESOURCE_CENTER_TRIGGER_ARROW,
        'in-builder': inBuilder === true,
      })}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();
          onBlockSelected(BLOCK_RESOURCE_CENTER_TRIGGER_ARROW);
          onContainerSelected(null);
        }
      }}
      style={{
        backgroundColor,
        ...(borderColor != null ? {border: `1px solid ${borderColor}`} : {}),
        borderRadius,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
      }}>
      <div
        className="icon"
        style={{
          width: fontSize,
          color: iconColor,
        }}>
        <i className="icon-chevron-bottom" style={{fontSize}} />
      </div>
      <ClickableBlockOverlay
        colorToUse={triggerBlock?.style?.backgroundColor}
      />
    </div>
  );
};

export default ResourceCenterTriggerArrow;
