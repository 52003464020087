import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_CHECKLIST_HEADER, STEP_TYPE_CHECKLIST} from 'services/steps';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import Height, {
  HEIGHT_TYPE_AUTO,
  HEIGHT_TYPE_CUSTOM,
} from '../../components/modals/Height';
import MediaLink from '../../components/modals/MediaLink';
import './_Styles.scss';

const ResourceCenterActionMedia = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedSubItemId,
    selectedBlockType,
  } = useContext(BuilderContext);

  const isChecklistStep = step?.type === STEP_TYPE_CHECKLIST;

  const checklistHeaderBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER
  );

  const block = step?.blocks.find(
    (b) =>
      b.type === selectedBlockType &&
      ((isChecklistStep !== true && b.parentBlockId == null) ||
        (selectedSubItemId == null &&
          [null, checklistHeaderBlock?.uid].includes(b.parentBlockId)) ||
        selectedSubItemId === b.parentBlockId)
  );

  const updateBlock = (updateObj, all = false) => {
    if (all !== true && block?.parentBlockId != null) {
      uptBlock(selectedBlockType, updateObj, {
        parentBlockId: block.parentBlockId,
      });
    } else {
      uptBlock(selectedBlockType, updateObj);
    }
  };

  if (block == null) {
    return <></>;
  }

  const {value, file, style} = block;
  const [type, fileUrl, altText, sizing] = value.split(';');

  return (
    <div className="block-settings resource-center-action-media">
      <Section title="Content">
        <SectionItem title="Media">
          <PopupSetting
            trigger={
              <ClickableInput
                key={fileUrl}
                value={
                  type === 'image' && file != null
                    ? file?.originalName
                    : fileUrl
                }
                erasable={
                  !!(type === 'video' && fileUrl) ||
                  !!(type === 'image' && fileUrl)
                }
                onErase={() => {
                  updateBlock({
                    value: `${type};;${altText};${sizing}`,
                    file: null,
                    fileId: null,
                  });
                }}
              />
            }
            title="Media"
            content={
              <MediaLink
                type={type}
                fileUrl={fileUrl}
                altText={altText}
                sizing={sizing}
                file={file}
                onChange={({value, file}) => {
                  updateBlock({value, file, fileId: file?.uid});
                }}
              />
            }
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section title="Style">
        <SectionItem title="Height">
          <PopupSetting
            trigger={
              <ClickableInput
                inputProps={{small: true}}
                value={style.height != null ? `${style.height}px` : 'Auto'}
              />
            }
            title="Height"
            content={
              <Height
                height={style.height}
                type={
                  style.height != null ? HEIGHT_TYPE_CUSTOM : HEIGHT_TYPE_AUTO
                }
                max={500}
                onChangeType={(type) => {
                  if (type === HEIGHT_TYPE_AUTO) {
                    updateBlock({
                      style: {...style, height: undefined},
                    });
                  } else {
                    updateBlock({
                      style: {...style, height: 200},
                    });
                  }
                }}
                onChange={({height}) =>
                  updateBlock({
                    style: {...style, height},
                  })
                }
              />
            }
          />
        </SectionItem>
      </Section>
    </div>
  );
};

export default ResourceCenterActionMedia;
