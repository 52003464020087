import Axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoint
const EP_SPACE_GET = '/space';
const EP_SPACE_GET_BY_ID = (spaceId) => `/space/${spaceId}`;
const EP_SPACE_CREATE = '/space';
const EP_SPACE_UPDATE = (spaceId) => `/space/${spaceId}`;
const EP_SPACE_DELETE = (spaceId) => `/space/${spaceId}`;
const EP_SPACE_ADD_EXPERIENCE_TO_SPACE = (spaceId) =>
  `/space/${spaceId}/evolutions`;
const EP_SPACE_PIN = (spaceId) => `/space/${spaceId}/pin`;
const EP_SPACE_UNPIN = (spaceId) => `/space/${spaceId}/unpin`;

// Method
export const getSpaces = (filter = {}) => {
  const {relations, contexts} = filter;
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_SPACE_GET, {
    params: {relations, contexts, projectId},
  }).then((response) => response.data);
};

export const getSpace = (spaceId, data = {}) => {
  const {relations} = data;

  return Axios.get(EP_SPACE_GET_BY_ID(spaceId), {
    params: {relations},
  }).then((response) => response.data);
};

export const createSpace = (data) => {
  const {name, color, icon, description, experienceIds} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_SPACE_CREATE, {
    name,
    color,
    icon,
    description,
    projectId,
    evolutionIds: experienceIds,
  }).then((response) => response.data);
};

export const updateSpace = (spaceId, data) => {
  const {name, color, icon, description} = data;

  return Axios.put(EP_SPACE_UPDATE(spaceId), {
    name,
    color,
    icon,
    description,
  }).then((response) => response.data);
};

export const deleteSpace = (spaceId) => {
  return Axios.delete(EP_SPACE_DELETE(spaceId)).then(
    (response) => response.data
  );
};

export const addExperienceToSpace = (spaceId, data) => {
  const {experienceIds} = data;

  return Axios.put(EP_SPACE_ADD_EXPERIENCE_TO_SPACE(spaceId), {
    evolutionIds: experienceIds,
  }).then((response) => response.data);
};

export const pinSpace = (spaceId) => {
  return Axios.put(EP_SPACE_PIN(spaceId)).then((response) => response.data);
};

export const unpinSpace = (spaceId) => {
  return Axios.put(EP_SPACE_UNPIN(spaceId)).then((response) => response.data);
};
