import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import classNames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import {Modal} from 'components/Modal';
import TextArea from 'components/TextArea';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ROUTE_SPACE_WITH_ID} from 'router/routes.const';
import {ModalAddExperienceContent} from 'scenes/Space/components/ModalAddExperience';
import {getRandomEmoji} from 'scenes/SuccessTracker/components/Event';
import {spaceService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ModalEditEnvironment');

const MODE_EDIT = 'edit';
const MODE_CREATE = 'create';
const MODE_ADD_EXPERIENCES = 'add-experiences';

const colors = [
  '#E6FDE8',
  '#DDEEFF',
  '#FDF2E6',
  '#ECE6FD',
  '#FFD7D7',
  '#FDF8C9',
  '#ECECEC',
];

const ModalEditSpace = ({isOpen, onRequestClose, space}) => {
  const history = useHistory();

  const [mode, setMode] = useState(
    space?.uid != null ? MODE_EDIT : MODE_CREATE
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState(space?.name || '');
  const [icon, setIcon] = useState(space?.icon || getRandomEmoji());
  const [color, setColor] = useState(space?.color || '#E6FDE8');
  const [description, setDescription] = useState(space?.description || '');
  const [experiencesToAdd, setExperiencesToAdd] = useState([]);

  const handleConfirm = async () => {
    setIsSubmitting(false);
    try {
      if (space?.uid != null) {
        await spaceService.updateSpace(space.uid, {
          name,
          icon,
          color,
          description,
        });
        toastSuccess(['Space updated successfully!'], {
          autoClose: 3000,
          toastId: 'changes-saved',
        });
        onRequestClose();
        return;
      } else {
        const createdSpace = await spaceService.createSpace({
          name,
          icon,
          color,
          description,
          experienceIds: experiencesToAdd,
        });
        toastSuccess(
          [
            'Space created successfully!',
            'You can now manage and publish experiences to this space.',
          ],
          {
            autoClose: 3000,
            toastId: 'changes-saved',
          }
        );
        onRequestClose();
        history.push(ROUTE_SPACE_WITH_ID(createdSpace.uid));
        return;
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to save space with error', code);
      return toastDanger([title, message], {actions});
    } finally {
      setIsSubmitting(false);
    }
  };

  const canSaveSpace = name.length > 0;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={classNames('modal-edit-space', {
        'mode-add-experiences': mode === MODE_ADD_EXPERIENCES,
      })}
      animationOnOpen="scale-in-center"
      title={
        mode === MODE_CREATE
          ? 'Create new space'
          : mode === MODE_ADD_EXPERIENCES
          ? `Add experiences to ${name}`
          : `Edit space ${space?.name}`
      }
      footer={
        <>
          <Button thin onClick={() => onRequestClose()} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            primary
            thin
            onClick={() => {
              if (mode === MODE_CREATE) {
                setMode(MODE_ADD_EXPERIENCES);
              } else {
                handleConfirm();
              }
            }}
            loading={isSubmitting}
            disabled={canSaveSpace !== true}>
            {mode === MODE_CREATE
              ? 'Next'
              : mode === MODE_ADD_EXPERIENCES
              ? 'Create space'
              : 'Save changes'}
          </Button>
        </>
      }>
      {mode === MODE_ADD_EXPERIENCES ? (
        <ModalAddExperienceContent
          space={space}
          experiencesToAdd={experiencesToAdd}
          setExperiencesToAdd={setExperiencesToAdd}
        />
      ) : (
        <div className="modal-edit-space-content">
          <div className="color-and-icon-wrapper">
            <Dropdown
              className="emoji-picker-dropdown"
              position="bottom left"
              offsetY={8}
              trigger={
                <div className="icon-wrapper" style={{backgroundColor: color}}>
                  {icon}
                </div>
              }
              contentStyle={{zIndex: 1002}}>
              <Picker
                data={data}
                onEmojiSelect={(emoji) => setIcon(emoji.native)}
              />
            </Dropdown>
            <div className="color-picker-wrapper">
              <div className="body-3">Colors</div>
              <div className="color-picker">
                {colors.map((c) => (
                  <div
                    key={c}
                    className={classNames('color', {
                      selected: c === color,
                    })}
                    style={{backgroundColor: c}}
                    onClick={() => setColor(c)}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="form-section">
            <div className="label body-3">Name</div>
            <InputGroup
              value={name || ''}
              onChange={(e) => setName(e.target.value)}
              placeholder="Example: Improve Onboarding, Announcements, etc"
            />
          </div>
          <div className="form-section">
            <div className="label body-3">Description (optional)</div>
            <TextArea
              className="input-description"
              value={description || ''}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe this Space to make it more understandable"
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalEditSpace;
