import {Icon} from '@iconify/react/dist/iconify';
import classNames from 'classnames';
import DefaultLoader from 'components/Loader';
import dayjs from 'dayjs';
import {htmlToText} from 'html-to-text';
import {object} from 'prop-types';
import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {evolutionAnalyticsService} from 'services';
import {
  BLOCK_TYPE_RESOURCE_CENTER_ACTION,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE,
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
} from 'services/steps';
import {
  RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
  RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
} from 'shared/front/components/ResourceCenter/constants';
import {Swaler} from 'swaler';
import UsersReachedDrawer from '../UsersReachedDrawer';
import './_Styles.scss';

const getActionName = (action) => {
  const {type} = action;
  if (type === STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE) {
    return 'Launch experience';
  } else if (type === STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO) {
    return 'Open url';
  } else if (type === STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE) {
    return 'Run js code';
  } else if (type === STEP_CONDITION_ACTION_TYPE_OPEN_POST) {
    return 'Open post';
  } else if (type === STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW) {
    return 'Open calendar';
  }
  return 'None';
};

const ResourceCenterActionItem = ({evolution, action, totalClicksCount}) => {
  const [showUsersModal, setShowUsersModal] = useState(false);

  const step = evolution?.steps?.[0];
  const {blocks = []} = step;

  const {clicksCount = 0, uniqueClicksCount = 0, actions = []} = action;

  const titleBlock = blocks.find(
    (b) =>
      b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE &&
      b.parentBlockId === action.uid
  );
  const iconBlock = blocks.find(
    (b) =>
      b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON &&
      b.parentBlockId === action.uid
  );

  const [title] = titleBlock?.value?.split('|-|');
  const name = htmlToText(title);

  const icon =
    iconBlock?.style?.iconSource === RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR ? (
      <Icon icon={`ph:${iconBlock?.style?.iconName}`} />
    ) : iconBlock?.style?.iconSource === RESOURCE_CENTER_ICON_SOURCE_TWEMOJI ? (
      <Icon icon={`twemoji:${iconBlock?.style?.iconName}`} />
    ) : null;

  const clicksPct =
    totalClicksCount > 0 ? (clicksCount / totalClicksCount) * 100 : 0;

  return (
    <div className="resource-center-action-activity-item">
      <div className="resource-center-action-item-content">
        <div className="resource-center-action-info">
          <div className="resource-center-action-name-wrapper">
            {icon && <div className="resource-center-action-icon">{icon}</div>}
            <div className="resource-center-action-name subtitle-3 n-800">
              {name}
            </div>
          </div>

          {clicksPct > 0 && (
            <div
              className="chart-wrapper"
              style={{
                width: `${clicksPct}%`,
              }}
            />
          )}
        </div>
        <div
          className="resource-center-action-action body-2 n-700"
          style={{justifySelf: 'right'}}>
          {actions?.length === 0
            ? 'No actions'
            : actions?.length === 1
            ? getActionName(actions[0])
            : `${actions?.length} actions`}
        </div>
        <div
          className="resource-center-action-clicks body-2 n-700"
          style={{justifySelf: 'right'}}>
          {clicksCount || '-'}
        </div>
        <div
          className={classNames('resource-center-action-users body-2 n-700', {
            clickable: uniqueClicksCount > 0,
          })}
          style={{justifySelf: 'right'}}
          onClick={() => {
            if (uniqueClicksCount > 0) {
              setShowUsersModal(true);
            }
          }}>
          {uniqueClicksCount || '-'}
        </div>
      </div>

      {showUsersModal && (
        <UsersReachedDrawer
          isOpen={showUsersModal}
          onRequestClose={() => setShowUsersModal(false)}
          block={action}
          evolution={evolution}
          title={`Users who clicked on "${name}"`}
        />
      )}
    </div>
  );
};

const propTypes = {
  evolution: object,
  startDate: object.isRequired,
  endDate: object.isRequired,
};

const logger = new Swaler('ResourceCenterActionActivity');

const ResourceCenterActionActivity = ({
  evolution = null,
  startDate,
  endDate,
}) => {
  const step = evolution?.steps?.[0];

  const actions = step?.blocks.filter(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION
  );

  const {
    data: analyticsHourlyByBlock = [],
    isLoading: isLoadingAnalyticHourlyByBlock,
  } = useQuery({
    queryKey: [
      'analyticsHourlyPerBlock',
      ...(actions?.map((c) => c.uid) || []),
      startDate,
      endDate,
    ],
    queryFn: () => {
      return evolutionAnalyticsService.getAnalyticsHourlyByBlock({
        startDate,
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        blockIds: actions?.map((c) => c.uid) || [],
      });
    },
    enabled: actions?.length > 0,
    onError: (err) => {
      logger.error(
        'Could not fetch actions analytics, failed with err',
        err.message
      );
    },
  });

  const actionsWithAnalytics = analyticsHourlyByBlock?.reduce((acc, cur) => {
    const index = acc.map((block) => block.uid).indexOf(cur.block_uid);
    if (index >= 0) {
      if (acc[index].clicksCount == null) {
        acc[index].clicksCount = parseInt(cur.sum_clicks_count, 10);
      } else {
        acc[index].clicksCount =
          acc[index].clicksCount + parseInt(cur.sum_clicks_count, 10);
      }
      if (acc[index].uniqueClicksCount == null) {
        acc[index].uniqueClicksCount = parseInt(
          cur.sum_unique_clicks_count,
          10
        );
      } else {
        acc[index].uniqueClicksCount =
          acc[index].uniqueClicksCount +
          parseInt(cur.sum_unique_clicks_count, 10);
      }
    }
    return acc;
  }, JSON.parse(JSON.stringify(actions || [])));

  actionsWithAnalytics.sort((a, b) => {
    const parentBlockIdA = a.parentBlockId;
    const parentBlockIdB = b.parentBlockId;

    const rootBlockA = step.blocks.find((b) => b.uid === parentBlockIdA) || a;
    const rootBlockB = step.blocks.find((b) => b.uid === parentBlockIdB) || b;

    const rootValueA = rootBlockA.value;
    const rootValueB = rootBlockB.value;

    const [_, rootIndexA] = rootValueA.split('|-|');
    const [__, rootIndexB] = rootValueB.split('|-|');

    if (rootIndexA !== rootIndexB) {
      return rootIndexA - rootIndexB;
    } else {
      const valueA = a.value;
      const valueB = b.value;

      const [_, indexA] = valueA.split('|-|');
      const [__, indexB] = valueB.split('|-|');

      return indexA - indexB;
    }
  });

  const totalClicksCount =
    evolution.analytics?.resourceCenterActionClickedCount || 0;

  return (
    <div className="resource-center-action-activity">
      {isLoadingAnalyticHourlyByBlock ? (
        <div className="loader-wrapper">
          <DefaultLoader />
        </div>
      ) : (
        <>
          <div className="resource-center-action-activity-header">
            <div>Block</div>
            <div style={{justifySelf: 'right'}}>Action</div>
            <div style={{justifySelf: 'right'}}>Clicks</div>
            <div style={{justifySelf: 'right'}}>Users</div>
          </div>
          <div className="resource-center-action-activity-content">
            {actionsWithAnalytics?.map((action, index) => {
              return (
                <ResourceCenterActionItem
                  index={index}
                  evolution={evolution}
                  action={action}
                  totalClicksCount={totalClicksCount}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

ResourceCenterActionActivity.propTypes = propTypes;

export default ResourceCenterActionActivity;
