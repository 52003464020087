import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import PixelPicker from '../../items/PixelPicker';
import './_Styles.scss';

const FontSizeItem = ({
  value,
  onChange,
  min = 8,
  max = 42,
  title = 'Font size',
}) => {
  return (
    <SectionItem title={title}>
      <PixelPicker
        small
        value={value}
        min={min}
        max={max}
        onChange={onChange}
      />
    </SectionItem>
  );
};

export default FontSizeItem;
