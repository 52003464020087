import Axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoint
const EP_ENVIRONMENT_GET = '/environment';
const EP_ENVIRONMENT_CREATE = '/environment';
const EP_ENVIRONMENT_UPDATE = (environmentId) =>
  `/environment/${environmentId}`;
const EP_ENVIRONMENT_DELETE = (environmentId) =>
  `/environment/${environmentId}`;

// Method
export const getEnvironments = (filter = {}) => {
  const {relations, contexts} = filter;
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_ENVIRONMENT_GET, {
    params: {relations, contexts, projectId},
  }).then((response) => response.data);
};

export const createEnvironment = (data) => {
  const {name, color, icon, domains, description} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_ENVIRONMENT_CREATE, {
    name,
    color,
    icon,
    domains,
    description,
    projectId,
  }).then((response) => response.data);
};

export const updateEnvironment = (environmentId, data) => {
  const {name, color, icon, domains, description} = data;

  return Axios.put(EP_ENVIRONMENT_UPDATE(environmentId), {
    name,
    color,
    icon,
    domains,
    description,
  }).then((response) => response.data);
};

export const deleteEnvironment = (environmentId) => {
  return Axios.delete(EP_ENVIRONMENT_DELETE(environmentId)).then(
    (response) => response.data
  );
};
