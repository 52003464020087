import classNames from 'classnames';
import DefaultLoader from 'components/Loader';
import * as dayjs from 'dayjs';
import {object, string} from 'prop-types';
import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {getSurveys} from 'services/survey';
import SurveyDetails from '../SurveyDetails';
import './_Styles.scss';

const propTypes = {
  jimer: object,
  defaultSurveyId: string,
};

const defaultProps = {
  jimer: {},
  defaultSurveyId: null,
};

const SurveyResponses = ({jimer, defaultSurveyId}) => {
  const [surveyDetailsId, setSurveyDetailsId] = useState(defaultSurveyId);

  const {data: surveys = [], isLoading} = useQuery({
    queryKey: ['surveys', jimer?.uid],
    queryFn: () => getSurveys({jimerId: jimer?.uid}),
    enabled: !!jimer?.uid,
    refetchOnWindowFocus: false,
  });

  // sort surveys by completedAt date or max date of responses array if completedAt is null
  const sortedSurveys = surveys.sort((a, b) => {
    const aResponses = a.responses.map((r) => r.createdAt);
    const bResponses = b.responses.map((r) => r.createdAt);

    aResponses.sort((a, b) => new Date(b) - new Date(a));
    bResponses.sort((a, b) => new Date(b) - new Date(a));

    const aMaxDate = aResponses[0];
    const bMaxDate = bResponses[0];

    const aDate = a.completedAt || aMaxDate;
    const bDate = b.completedAt || bMaxDate;

    return new Date(bDate) - new Date(aDate);
  });

  if (isLoading === true) {
    return (
      <div className="loader-wrapper">
        <DefaultLoader />
      </div>
    );
  }

  if (surveys.length === 0) {
    return (
      <div className="empty-state-wrapper">
        <div className="activity-empty-state">No responses found</div>
      </div>
    );
  }

  return (
    <div
      className={classNames('jimer-surveys', {
        'no-padding': surveyDetailsId != null,
      })}>
      {surveyDetailsId != null ? (
        <SurveyDetails
          surveyId={surveyDetailsId}
          onBack={() => setSurveyDetailsId(null)}
        />
      ) : (
        <div className="jimer-surveys-list">
          {sortedSurveys.map((survey) => {
            const {responses = [], evolution} = survey;
            const date =
              survey.completedAt ||
              responses
                .map((r) => r.createdAt)
                .sort((a, b) => new Date(b) - new Date(a))[0];
            return (
              <div
                className="jimer-survey"
                onClick={() => setSurveyDetailsId(survey.uid)}>
                <div className="jimer-survey-info">
                  <div className="jimer-survey-title">{evolution.title}</div>
                  <div className="jimer-survey-date">
                    {dayjs(date).fromNow()}
                  </div>
                </div>
                <i className="icon-arrow-right" />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

SurveyResponses.propTypes = propTypes;
SurveyResponses.defaultProps = defaultProps;

export default SurveyResponses;
