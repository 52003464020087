import {Menu, MenuItem} from 'components/Menu';
import {func, string} from 'prop-types';
import React from 'react';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_RESOURCE_CENTER,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import './_Styles.scss';

const propTypes = {
  type: string,
  useCase: string,
  onChange: func,
};

const defaultProps = {
  type: null,
  useCase: null,
  onChange: () => {},
};

const Sidebar = ({type, onChange}) => {
  return (
    <div className="modal-add-experience-sidebar">
      <div className="modal-add-experience-sidebar-content">
        <div className="menu-wrapper">
          <Menu>
            <MenuItem
              className="body-3 n-700"
              active={type == null}
              onClick={() => onChange(null)}>
              All
            </MenuItem>
            <MenuItem
              className="body-3 n-700"
              icon={<i className="isax isax-routing-2" />}
              active={type === EVOLUTION_TYPE_TOUR}
              onClick={() => onChange(EVOLUTION_TYPE_TOUR)}>
              Tours
            </MenuItem>
            <MenuItem
              className="body-3 n-700"
              icon={<i className="isax isax-note-2" />}
              active={type === EVOLUTION_TYPE_SURVEY}
              onClick={() => onChange(EVOLUTION_TYPE_SURVEY)}>
              Surveys
            </MenuItem>
            <MenuItem
              className="body-3 n-700"
              icon={<i className="icon-banner-default" />}
              active={type === EVOLUTION_TYPE_BANNER}
              onClick={() => onChange(EVOLUTION_TYPE_BANNER)}>
              Banners
            </MenuItem>
            <MenuItem
              className="body-3 n-700"
              icon={<i className="isax isax-notification-1" />}
              active={type === EVOLUTION_TYPE_HINT}
              onClick={() => onChange(EVOLUTION_TYPE_HINT)}>
              Hints
            </MenuItem>
            <MenuItem
              className="body-3 n-700"
              icon={<i className="isax isax-task-square" />}
              active={type === EVOLUTION_TYPE_CHECKLIST}
              onClick={() => onChange(EVOLUTION_TYPE_CHECKLIST)}>
              Checklists
            </MenuItem>
            <MenuItem
              className="body-3 n-700"
              icon={<i className="isax isax-directbox-notif" />}
              active={type === EVOLUTION_TYPE_RESOURCE_CENTER}
              onClick={() => onChange(EVOLUTION_TYPE_RESOURCE_CENTER)}>
              Resource centers
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
