import {loadIcons} from '@iconify/react';
import {useEffect, useState} from 'react';
import {twemojiIcons} from './twemoji.utils';

const twemojiIconsFormatted = twemojiIcons.map((icon) => {
  const value = icon.label
    .replace(/[:|\s]+/g, '-')
    .replace(/-+/g, '-')
    .replace(/[.""()!',]/g, '')
    .replace(/[éè]/g, 'e')
    .replace(/[àáâ]/g, 'a')
    .replace(/[ùú]/g, 'u')
    .replace(/ç/g, 'c')
    .replace(/&/g, 'and')
    .toLocaleLowerCase();

  return {
    ...icon,
    value,
    label: value
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
  };
});

const useTwemojiList = () => {
  const [twemojiIcons, setTwemojiIcons] = useState([]);

  useEffect(() => {
    loadIcons(
      twemojiIconsFormatted.map((emoji) => `twemoji:${emoji.value}`),
      (loaded) => {
        const availableIcons = loaded.map((icon) => icon.name);

        setTwemojiIcons(
          twemojiIconsFormatted.filter((emoji) =>
            availableIcons.includes(emoji.value)
          )
        );
      }
    );
  }, []);

  return twemojiIcons;
};

export default useTwemojiList;
