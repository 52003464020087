import Button from 'components/Button';
import Divider from 'components/Divider';
import React from 'react';
import './_Styles.scss';

const HelpSection = ({isDisconnecting, onDisconnect}) => {
  return (
    <div className="settings-card sse-help-section">
      <div className="section section-resources">
        <div className="subtitle-4">Resources</div>
        <div className="section-content">
          <Button
            iconLeft="isax isax-book"
            thin
            onClick={() =>
              window.open(
                'https://help.usejimo.com/help-center/integrations/server-side-events',
                '_blank',
                'noopener,noreferrer'
              )
            }>
            Documentation
          </Button>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="subtitle-4">How it works</div>
        <div className="section-content">
          <div className="how-it-works">
            This integration allows you to send data to your Jimo project from
            your own servers.
          </div>
        </div>
      </div>
      <Divider />
      <div className="section section-disconnect">
        <Button thin danger onClick={onDisconnect} loading={isDisconnecting}>
          Disconnect integration
        </Button>
      </div>
    </div>
  );
};

export default HelpSection;
