import classnames from 'classnames';
import React, {useContext} from 'react';
import {hasFlag} from '../../../../helpers/bitwise';
import {BLOCK_DISMISS_CROSS, isInteractiveBlock} from '../../constants/blocks';
import {F_SLOT_CURSOR, F_SLOT_HINT, F_SLOT_TOP_BAR} from '../../constants/poke';
import {PokeContext, PokeStateContext} from '../../context';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';
import {EVOLUTION_TYPE_SURVEY} from '../../constants/evolution';

export const BlockDismissCross = () => {
  const {poke} = useContext(PokeContext);
  const {
    experienceType,
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    onTriggerActions,
    completeSurvey,
    currentStepIndex,
  } = useContext(PokeStateContext);

  const block = currentStep.blocks.find((b) => b.type === BLOCK_DISMISS_CROSS);

  if (block == null) {
    return null;
  }

  const {
    fontSize,
    borderRadius,
    fontColor,
    primaryColor: background,
  } = block.style;

  const handleClick = () => {
    // Check here if we complete the survey or not (only if not interactive block and last step)
    if (experienceType === EVOLUTION_TYPE_SURVEY) {
      const hasInteractiveBlocks = currentStep.blocks.some((b) =>
        isInteractiveBlock(b.type)
      );
      const isLastStep = poke.steps.length === currentStepIndex + 1;

      if (hasInteractiveBlocks !== true && isLastStep === true) {
        return completeSurvey({
          callbackActions: block.actions || [],
          shouldClosePoke: true,
        });
      }
    }

    onTriggerActions(block.actions || []);
  };

  const isInCursor = hasFlag(F_SLOT_CURSOR, poke.boostFlags);

  return (
    <div
      className={classnames('poke-block-dismiss-cross', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_DISMISS_CROSS,
        'is-in-banner': hasFlag(F_SLOT_TOP_BAR, poke.boostFlags),
        'is-in-hint': hasFlag(F_SLOT_HINT, poke.boostFlags),
        'is-in-cursor': isInCursor,
      })}
      onClick={(e) => {
        e.stopPropagation();
        if (inBuilder === true) {
          onBlockSelected(BLOCK_DISMISS_CROSS);
        } else {
          handleClick();
        }
      }}
      style={{
        background,
        borderRadius,
      }}>
      <i
        style={{borderRadius, fontSize, color: fontColor}}
        className="icon-close"
      />
      <ClickableBlockOverlay />
    </div>
  );
};
