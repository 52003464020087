import {
  BLOCK_TYPE_RESOURCE_CENTER_ACTION,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_MEDIA,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_PARAGRAPH,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_MINIMIZE,
  BLOCK_TYPE_RESOURCE_CENTER_SUBTITLE,
  BLOCK_TYPE_RESOURCE_CENTER_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
} from 'services/steps';

/**
 * Ordered block types to map or compare against
 */
export const orderedBlockTypes = [
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
  BLOCK_TYPE_RESOURCE_CENTER_MINIMIZE,
  BLOCK_TYPE_RESOURCE_CENTER_SUBTITLE,
  BLOCK_TYPE_RESOURCE_CENTER_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_MEDIA,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_PARAGRAPH,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION,
];

/**
 * Returns an icon class for the given block type.
 */
export function getBlockIcon(type) {
  if (type === BLOCK_TYPE_RESOURCE_CENTER_ACTION) {
    return 'isax isax-flash-1';
  }
  if (type === BLOCK_TYPE_RESOURCE_CENTER_GROUP) {
    return 'isax isax-document';
  }
  if (type === BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST) {
    return 'isax isax-task-square';
  }
  // Fallback or further type checks
  return 'isax isax-document';
}

/**
 * Returns true if `testId` is an ancestor (or itself) of `selectedId`.
 *
 * We'll walk from the selectedId up the tree via .parentBlockId
 * until there's no parent or we hit `testId`.
 */
export function isBlockOrAncestorOfSelected(blocks, testId, selectedId) {
  if (!selectedId || !testId) return false;

  // If the block is the one selected, that qualifies as well
  if (testId === selectedId) return true;

  // Build a map to quickly find a block by uid
  const blockMap = new Map(blocks.map((b) => [b.uid, b]));

  // Walk upwards
  let currentId = selectedId;
  while (currentId) {
    const currentBlock = blockMap.get(currentId);
    if (!currentBlock) break;

    if (currentBlock.parentBlockId === testId) {
      return true;
    }

    currentId = currentBlock.parentBlockId;
  }

  return false;
}

/**
 * Utility function to reorder a list by moving the element
 * from `startIndex` to `endIndex`.
 */
export function reorderList(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}
