import classNames from 'classnames';
import Dropdown from 'components/Dropdown';
import React, {useState} from 'react';
import {
  RESOURCE_CENTER_ACTION_CTA_TYPE_ICON,
  RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT,
} from 'shared/front/components/ResourceCenter/constants';
import './_Styles.scss';

const types = [
  {
    name: 'Text CTA',
    value: RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT,
    icon: 'icon-label-icon',
    description: 'Direct call-to-action with a text',
  },
  {
    name: 'Icon CTA',
    value: RESOURCE_CENTER_ACTION_CTA_TYPE_ICON,
    icon: 'isax isax-arrow-right-2',
    description: 'Compact call-to-action with an icon',
  },
];

const DropdownTypeResourceCenterActionPicker = ({
  value,
  omit = [],
  onChange,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const selectedType = types.find((type) => type.value === value);

  return (
    <Dropdown
      open={showDropdown}
      className={classNames('dropdown-type-resource-center-action-picker')}
      position="bottom left"
      repositionOnResize={true}
      trigger={
        <div
          className="type-resource-center-action-picker-trigger"
          onClick={() => setShowDropdown(true)}>
          <div className="icon-wrapper">
            <i className={selectedType?.icon} />
          </div>
          <div className="title subtitle-4 n-800">{selectedType?.name}</div>
          <i className="icon-chevron-bottom" />
        </div>
      }>
      <div className="dropdown-menu">
        {types
          .filter((type) => omit.includes(type.value) === false)
          .map((type) => {
            const {name, value, icon, description} = type;

            return (
              <div
                className="dropdown-menu-item"
                onClick={() => {
                  onChange(value);
                  setShowDropdown(false);
                }}>
                <div className={classNames('icon-wrapper')}>
                  <i className={icon} />
                </div>
                <div className="content">
                  <div className="title subtitle-4 n-800">{name}</div>
                  <div className="description body-4 n-700">{description}</div>
                </div>
              </div>
            );
          })}
      </div>
    </Dropdown>
  );
};

export default DropdownTypeResourceCenterActionPicker;
