import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {useEffect, useState} from 'react';
import {CATEGORIES_ICONS, EVENTS} from 'services/webhooks';
import './_styles.scss';

export const ModalWebhookEvents = (props) => {
  const [events, setEvents] = useState(props.events);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setEvents(props.events);
  }, [props.isOpen]);

  const filteredEvents = Object.values(EVENTS).filter((event) => {
    return event.name.toLowerCase().includes(search.toLowerCase());
  });
  const groupByCategories = filteredEvents.reduce((acc, event) => {
    const existingCategoryIndex = acc.findIndex(
      (category) => category.name === event.category
    );

    if (existingCategoryIndex === -1) {
      return acc.concat({
        name: event.category,
        events: [event],
      });
    }
    acc[existingCategoryIndex].events.push(event);
    return acc;
  }, []);

  return (
    <Modal {...props} className="modal-webhook-events">
      <div className="modal-header">
        <div className="title-wrapper">
          <div className="subtitle-3">Choose events</div>
          <i className="icon-close" onClick={props.onRequestClose}></i>
        </div>
        <Input
          iconLeft="icon-search"
          placeholder="Search events..."
          small
          onChange={({target}) => setSearch(target.value)}
          value={search}
        />
      </div>
      <div
        className={classnames('events-list', {
          'is-empty': groupByCategories.length === 0,
        })}>
        {groupByCategories.length === 0 && (
          <div className="no-events body-3 n-500">No events found</div>
        )}
        {groupByCategories.map((category) => {
          return (
            <div className="event-category">
              <div className="category-title body-3 n-600">
                {category.name.toLowerCase()}
              </div>
              <div className="events-wrapper">
                {category.events
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((event) => {
                    const hasEvent = events.includes(event.key);

                    return (
                      <div
                        className="event"
                        onClick={() =>
                          setEvents(
                            hasEvent === true
                              ? events.filter((e) => e !== event.key)
                              : [...events, event.key]
                          )
                        }>
                        <div className="icon-wrapper">
                          <i className={CATEGORIES_ICONS[category.name]}></i>
                        </div>
                        <div className="event-name-wrapper">
                          <div className="event-name body-2">{event.name}</div>
                          <div className="event-description body-3 n-500">
                            {event.description}
                          </div>
                        </div>
                        {hasEvent ? (
                          <i className="icon-checkbox-a"></i>
                        ) : (
                          <i className="icon-checkbox-o"></i>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="modal-footer">
        <span className="subtitle-3">{events.length} events selected</span>
        <Button primary thin onClick={() => props.onSelectEvents(events)}>
          Add events
        </Button>
      </div>
    </Modal>
  );
};
