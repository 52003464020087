import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useContext, useEffect} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {F_STEP_HEIGHT_CUSTOM, getDefaultStepStyle} from 'services/steps';
import {
  RESOURCE_CENTER_TRIGGER_POSITION_LEFT,
  RESOURCE_CENTER_TRIGGER_POSITION_RIGHT,
} from 'shared/front/components/ResourceCenter/constants';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import Height, {
  HEIGHT_TYPE_AUTO,
  HEIGHT_TYPE_CUSTOM,
} from '../../components/modals/Height';
import Width from '../../components/modals/Width';
import ColorItem from '../../components/sectionItems/ColorItem';
import GapItem from '../../components/sectionItems/GapItem';
import OffsetItem from '../../components/sectionItems/OffsetItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import ShadowItem from '../../components/sectionItems/ShadowItem';
import './_Styles.scss';

const DISMISS_CROSS = 'cross';
const DISMISS_OUTSIDE = 'outside';
const DISMISS_HOVER_OUTSIDE = 'hover-outside';

const dismissOptions = [
  {label: 'Click the cross', value: [DISMISS_CROSS]},
  {label: 'Click outside', value: [DISMISS_OUTSIDE]},
  {
    label: 'Click cross or outside',
    value: [DISMISS_CROSS, DISMISS_OUTSIDE],
  },
  {label: "Can't be dismissed", value: []},
  {label: 'Hover outside', value: [DISMISS_HOVER_OUTSIDE]},
];

export const resourceCenterTriggerPositionOptions = [
  {label: 'Left', value: RESOURCE_CENTER_TRIGGER_POSITION_LEFT},
  {label: 'Right', value: RESOURCE_CENTER_TRIGGER_POSITION_RIGHT},
];

const ResourceCenter = ({updateStepStyle = false}) => {
  const {
    evolution: parentEvolution,
    setEvolution: setParentEvolution,
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    selectedStep,
    updateStep,
    selectedBlockType,
  } = useContext(BuilderContext);

  useEffect(() => {}, [selectedBlockType]);

  const handleThemeStyleChange = (changes) => {
    if (updateStepStyle === true) {
      updateStep(selectedStep?.uid, {
        style: {...selectedStep?.style, ...changes},
      });
    } else {
      if (evolution.uid === parentEvolution.uid) {
        setParentEvolution({
          ...parentEvolution,
          style: {...style, ...changes},
        });
      } else {
        setParentEvolution({
          ...parentEvolution,
          tourSteps: parentEvolution.tourSteps.map((tourStep) => {
            if (tourStep.uid === evolution.uid) {
              return {
                ...tourStep,
                style: {...tourStep.style, ...changes},
              };
            }
            return tourStep;
          }),
        });
      }
    }
  };

  const stepStyle = selectedStep?.style || getDefaultStepStyle();
  const flags = selectedStep?.stepFlags || 0;
  const wrapperStyle = evolution?.style || getDefaultStepStyle();
  const style = updateStepStyle === true ? stepStyle : wrapperStyle;

  return (
    <div className="block-settings resource-center">
      <Section title="Layout">
        <SectionItem title="Width">
          <PopupSetting
            trigger={
              <ClickableInput
                value={
                  stepStyle.width != null ? `${stepStyle.width}px` : 'Auto'
                }
              />
            }
            title="Width"
            content={
              <Width
                value={stepStyle.width}
                onChange={(value) =>
                  updateStep(selectedStep?.uid, {
                    style: {...stepStyle, width: value},
                  })
                }
              />
            }
          />
        </SectionItem>

        <SectionItem title="Height">
          <PopupSetting
            trigger={
              <ClickableInput
                value={
                  hasFlag(F_STEP_HEIGHT_CUSTOM, flags) === true
                    ? `${stepStyle.height}px`
                    : 'Auto'
                }
              />
            }
            title="Height"
            content={
              <Height
                height={stepStyle.height}
                type={
                  hasFlag(F_STEP_HEIGHT_CUSTOM, flags) === true
                    ? HEIGHT_TYPE_CUSTOM
                    : HEIGHT_TYPE_AUTO
                }
                onChangeType={(type) => {
                  if (type === HEIGHT_TYPE_AUTO) {
                    updateStep(selectedStep?.uid, {
                      stepFlags: removeFlag(F_STEP_HEIGHT_CUSTOM, flags),
                    });
                  } else {
                    updateStep(selectedStep?.uid, {
                      stepFlags: addFlag(F_STEP_HEIGHT_CUSTOM, flags),
                    });
                  }
                }}
                onChange={({height}) =>
                  updateStep(selectedStep?.uid, {
                    style: {...stepStyle, height},
                  })
                }
                min={300}
                max={1600}
              />
            }
          />
        </SectionItem>

        <PaddingItem
          value={{
            paddingTop: stepStyle.paddingTop ?? 16,
            paddingBottom: stepStyle.paddingBottom ?? 16,
            paddingLeft: stepStyle.paddingLeft ?? 16,
            paddingRight: stepStyle.paddingRight ?? 16,
          }}
          onChange={(value) => {
            updateStep(selectedStep?.uid, {
              style: {...stepStyle, ...value},
            });
          }}
        />

        <GapItem
          value={stepStyle.gap ?? 16}
          onChange={(value) =>
            updateStep(selectedStep?.uid, {
              style: {...stepStyle, gap: value},
            })
          }
        />
        <SectionItem title="Position">
          <RadioGroup
            options={resourceCenterTriggerPositionOptions}
            value={
              evolution?.style?.position ||
              RESOURCE_CENTER_TRIGGER_POSITION_RIGHT
            }
            onSelect={(value) =>
              setEvolution({
                ...evolution,
                style: {...evolution.style, position: value},
              })
            }
          />
        </SectionItem>
        <OffsetItem
          value={evolution?.style}
          onChange={(value) =>
            setEvolution({
              ...evolution,
              style: {
                ...evolution.style,
                ...value,
              },
            })
          }
        />
      </Section>
      <Divider />
      <Section title="Style">
        <RadiusItem
          value={style.borderRadius}
          onChange={(value) => handleThemeStyleChange({borderRadius: value})}
          max={50}
        />
        <ColorItem
          title="Background"
          value={style.backgroundColor || null}
          onChange={(value) => handleThemeStyleChange({backgroundColor: value})}
        />
        <ColorItem
          title="Border"
          value={style.borderColor || null}
          onChange={(value) => handleThemeStyleChange({borderColor: value})}
          colorPickerProps={{
            erasable: true,
          }}
        />
        <ShadowItem
          value={style.shadow}
          onChange={(value) => handleThemeStyleChange({shadow: value})}
        />
      </Section>
    </div>
  );
};

export default ResourceCenter;
