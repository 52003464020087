import classNames from 'classnames';
import {func} from 'prop-types';
import React from 'react';
import './_Styles.scss';
import ContextualAnnounce from './imgs/contextual-announce.svg';
import ImmerseAnnounce from './imgs/immerse-announce.svg';
import TipsAndTricks from './imgs/tips-and-tricks.svg';

const types = [
  {
    iconType: <i className="icon-slot-pop-in" />,
    labelType: 'Modal',
    image: <img src={ImmerseAnnounce} alt="Immerse Announce" />,
    boostFlags: 2,
  },
  {
    iconType: <i className="icon-banner-default" />,
    labelType: 'Banner',
    image: <img src={TipsAndTricks} alt="Tips & Tricks" />,
    boostFlags: 1,
  },
  {
    iconType: <i className="icon-slot-snippet" />,
    labelType: 'Snippet',
    image: <img src={ContextualAnnounce} alt="Contextual Announce" />,
    boostFlags: 4,
  },
];

const BoostTypeBlock = ({index, iconType, labelType, image, onClick}) => {
  return (
    <div
      key={index}
      className={classNames('boost-type-block', labelType.toLowerCase())}
      onClick={onClick}>
      <div className="type-header">
        <div className="type-wrapper">
          <div className="icon">{iconType}</div>
          <div className="type">{labelType}</div>
        </div>
        <div className="img-wrapper">{image}</div>
      </div>
    </div>
  );
};

const propTypes = {
  onBoostTypeClick: func,
};

const defaultProps = {
  onBoostTypeClick: () => {},
};

const PickBoostType = ({onBoostTypeClick}) => {
  return (
    <div className="pick-boost-type">
      <div className="boost-modal-content">
        <div className="boost-type-text">
          Choose how you want to boost your post :
        </div>
        <div className="boost-types">
          {types.map((type, index) => (
            <BoostTypeBlock
              index={index}
              {...type}
              onClick={() => onBoostTypeClick(type.boostFlags)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

PickBoostType.propTypes = propTypes;
PickBoostType.defaultProps = defaultProps;

export default PickBoostType;
