import {generalActions} from 'actions';
import classnames from 'classnames';
import Divider from 'components/Divider';
import {Menu, MenuItem, MenuSection} from 'components/Menu';
import {PermissionsSettings} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {
  ROUTE_LOGOUT,
  ROUTE_SETTINGS_BILLING,
  ROUTE_SETTINGS_ENVIRONMENTS,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_INSTALLATION,
  ROUTE_SETTINGS_INSTALLATION_IDENTIFICATION,
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_INTEGRATIONS_CRISP,
  ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT,
  ROUTE_SETTINGS_INTEGRATIONS_INTERCOM,
  ROUTE_SETTINGS_INTEGRATIONS_MIXPANEL,
  ROUTE_SETTINGS_INTEGRATIONS_POSTHOG,
  ROUTE_SETTINGS_INTEGRATIONS_SLACK,
  ROUTE_SETTINGS_INTEGRATIONS_TYPEFORM,
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK,
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_DETAILS,
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_SETUP,
  ROUTE_SETTINGS_NOTIFICATION,
  ROUTE_SETTINGS_PLAN,
  ROUTE_SETTINGS_POKE_CONTROL,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS_TEAM,
  ROUTE_SETTINGS_THEMES,
  ROUTE_SETTINGS_THEMES_ID,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import './_Styles.scss';

const NavbarSettings = (props) => {
  const {user, project} = props;

  const isActive = (paths) => {
    return [].concat(paths).includes(props.match.path);
  };

  if (project == null) {
    // Can be null when project has just been deleted and removed from REDUX general.projects
    return <></>;
  }
  return (
    <div className="jimo-navbar-settings">
      <div className="menu-wrapper">
        <Menu>
          <MenuSection>Project</MenuSection>
          {hasPermissions(PermissionsSettings.GENERAL_ACCESS) && (
            <Link
              to={{
                pathname: ROUTE_SETTINGS_GENERAL,
                state: {doSettingsAnimation: false},
              }}>
              <MenuItem
                active={isActive(ROUTE_SETTINGS_GENERAL)}
                className="n-700">
                <div
                  className="settings-project-logo"
                  style={
                    project.portalLogoUrl != null
                      ? {
                          backgroundImage: `url(${project.portalLogoUrl})`,
                        }
                      : {
                          backgroundColor: '#1260EB',
                        }
                  }>
                  {project.portalLogoUrl == null &&
                    project.name[0].toUpperCase()}
                </div>
                General
              </MenuItem>
            </Link>
          )}

          {hasPermissions(PermissionsSettings.THEME_ACCESS) && (
            <Link
              to={{
                pathname: ROUTE_SETTINGS_THEMES,
                state: {doSettingsAnimation: false},
              }}>
              <MenuItem
                className="body-3 n-700"
                icon={classnames(
                  'isax',
                  isActive([ROUTE_SETTINGS_THEMES, ROUTE_SETTINGS_THEMES_ID()])
                    ? 'isax-brush-25'
                    : 'isax-brush-4'
                )}
                active={isActive([
                  ROUTE_SETTINGS_THEMES,
                  ROUTE_SETTINGS_THEMES_ID(),
                ])}>
                Themes
              </MenuItem>
            </Link>
          )}

          {hasPermissions(PermissionsSettings.INSTALLATION_ACCESS) && (
            <Link
              to={{
                pathname: ROUTE_SETTINGS_INSTALLATION,
                state: {doSettingsAnimation: false},
              }}>
              <MenuItem
                className="body-3 n-700"
                icon={classnames(
                  'isax',
                  isActive([
                    ROUTE_SETTINGS_INSTALLATION,
                    ROUTE_SETTINGS_INSTALLATION_IDENTIFICATION,
                  ])
                    ? 'isax-simcard5'
                    : 'isax-simcard-1'
                )}
                active={isActive([
                  ROUTE_SETTINGS_INSTALLATION,
                  ROUTE_SETTINGS_INSTALLATION_IDENTIFICATION,
                ])}>
                Installation
              </MenuItem>
            </Link>
          )}

          {hasPermissions(PermissionsSettings.POKE_CONTROL_ACCESS) && (
            <Link
              to={{
                pathname: ROUTE_SETTINGS_POKE_CONTROL,
                state: {doSettingsAnimation: false},
              }}>
              <MenuItem
                className="body-3 n-700"
                icon={classnames(
                  'isax',
                  isActive([ROUTE_SETTINGS_POKE_CONTROL])
                    ? 'isax-mouse-square5'
                    : 'isax-mouse-square'
                )}
                active={isActive(ROUTE_SETTINGS_POKE_CONTROL)}>
                Rate Display Limit
              </MenuItem>
            </Link>
          )}
          {hasPermissions(PermissionsSettings.POKE_CONTROL_ACCESS) && (
            <Link
              to={{
                pathname: ROUTE_SETTINGS_ENVIRONMENTS,
                state: {doSettingsAnimation: false},
              }}>
              <MenuItem
                className="body-3 n-700"
                icon={classnames(
                  'isax',
                  isActive([ROUTE_SETTINGS_ENVIRONMENTS])
                    ? 'isax-global5'
                    : 'isax-global'
                )}
                active={isActive(ROUTE_SETTINGS_ENVIRONMENTS)}>
                Environments
              </MenuItem>
            </Link>
          )}

          {hasPermissions(PermissionsSettings.TEAM_ACCESS) && (
            <Link
              to={{
                pathname: ROUTE_SETTINGS_TEAM,
                state: {doSettingsAnimation: false},
              }}>
              <MenuItem
                className="body-3 n-700"
                icon={classnames(
                  'isax',
                  isActive([ROUTE_SETTINGS_TEAM])
                    ? 'isax-people5'
                    : 'isax-people'
                )}
                active={isActive(ROUTE_SETTINGS_TEAM)}>
                Team
              </MenuItem>
            </Link>
          )}

          {hasPermissions(PermissionsSettings.INTEGRATION_ACCESS) && (
            <Link
              to={{
                pathname: ROUTE_SETTINGS_INTEGRATIONS,
                state: {doSettingsAnimation: false},
              }}>
              <MenuItem
                className="body-3 n-700"
                icon={classnames(
                  'isax',
                  isActive([
                    ROUTE_SETTINGS_INTEGRATIONS,
                    ROUTE_SETTINGS_INTEGRATIONS_SLACK,
                    ROUTE_SETTINGS_INTEGRATIONS_TYPEFORM,
                    ROUTE_SETTINGS_INTEGRATIONS_INTERCOM,
                    ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT,
                    ROUTE_SETTINGS_INTEGRATIONS_POSTHOG,
                    ROUTE_SETTINGS_INTEGRATIONS_CRISP,
                    ROUTE_SETTINGS_INTEGRATIONS_MIXPANEL,
                    ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK,
                    ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_DETAILS(),
                    ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_SETUP(),
                  ])
                    ? 'isax-element-plus5'
                    : 'isax-element-plus'
                )}
                active={isActive([
                  ROUTE_SETTINGS_INTEGRATIONS,
                  ROUTE_SETTINGS_INTEGRATIONS_SLACK,
                  ROUTE_SETTINGS_INTEGRATIONS_TYPEFORM,
                  ROUTE_SETTINGS_INTEGRATIONS_INTERCOM,
                  ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT,
                  ROUTE_SETTINGS_INTEGRATIONS_POSTHOG,
                  ROUTE_SETTINGS_INTEGRATIONS_CRISP,
                  ROUTE_SETTINGS_INTEGRATIONS_MIXPANEL,
                  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK,
                  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_DETAILS(),
                  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_SETUP(),
                ])}>
                Integrations
              </MenuItem>
            </Link>
          )}
          {hasPermissions(PermissionsSettings.BILLING_ACCESS) && (
            <Link
              to={{
                pathname: ROUTE_SETTINGS_PLAN,
                state: {doSettingsAnimation: false},
              }}>
              <MenuItem
                className="body-3 n-700"
                icon={classnames(
                  'isax',
                  isActive([ROUTE_SETTINGS_PLAN])
                    ? 'isax-dollar-circle4'
                    : 'isax-dollar-circle'
                )}
                active={isActive([ROUTE_SETTINGS_PLAN])}>
                Plan
              </MenuItem>
            </Link>
          )}
          {hasPermissions(PermissionsSettings.BILLING_ACCESS) && (
            <Link
              to={{
                pathname: ROUTE_SETTINGS_BILLING,
                state: {doSettingsAnimation: false},
              }}>
              <MenuItem
                className="body-3 n-700"
                icon={classnames(
                  'isax',
                  isActive([ROUTE_SETTINGS_BILLING])
                    ? 'isax-card5'
                    : 'isax-card'
                )}
                active={isActive([ROUTE_SETTINGS_BILLING])}>
                Billing
              </MenuItem>
            </Link>
          )}
          <Divider dark />
          <MenuSection>Account</MenuSection>
          <Link
            to={{
              pathname: ROUTE_SETTINGS_PROFILE,
              state: {doSettingsAnimation: false},
            }}>
            <MenuItem
              className="body-3 n-700"
              active={isActive(ROUTE_SETTINGS_PROFILE)}>
              <div
                className="settings-user-avatar"
                style={
                  user.avatarUrl != null
                    ? {
                        backgroundImage: `url(${user.avatarUrl})`,
                      }
                    : {
                        backgroundColor: `var(--b-300)`,
                      }
                }>
                {user.avatarUrl == null && user.username[0]}
              </div>
              My account
            </MenuItem>
          </Link>
          <Link
            to={{
              pathname: ROUTE_SETTINGS_NOTIFICATION,
              state: {doSettingsAnimation: false},
            }}>
            <MenuItem
              className="body-3 n-700"
              icon={classnames(
                'isax',
                isActive([ROUTE_SETTINGS_NOTIFICATION])
                  ? 'isax-notification5'
                  : 'isax-notification'
              )}
              active={isActive(ROUTE_SETTINGS_NOTIFICATION)}>
              Notifications
            </MenuItem>
          </Link>
          <Divider dark />
          <a
            href="https://help.usejimo.com"
            target="_blank"
            rel="noopener noreferrer">
            <MenuItem
              className="body-3 n-700"
              icon="isax isax-book"
              isExternalLink>
              Documentation
            </MenuItem>
          </a>
          <MenuItem
            className="body-3 n-700"
            icon="isax isax-message-favorite"
            onClick={() => window.jimo.push(['do', 'widget:open'])}>
            Feedback
          </MenuItem>
          <Link
            to={{
              pathname: ROUTE_LOGOUT,
              state: {doSettingsAnimation: false},
            }}>
            <MenuItem icon="isax isax-logout" style={{color: '#E25C53'}}>
              Log out
            </MenuItem>
          </Link>
        </Menu>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  projects: generalSelector.getProjects(state),
  project: generalSelector.getProject(state),
  user: generalSelector.getUser(state),
  projectMember: generalSelector.getProjectMember(state),
});
const mapDispatchToProps = (dispatch) => ({
  setProjects: (projects) => dispatch(generalActions.setProjects(projects)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavbarSettings));
