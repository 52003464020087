export const EVOLUTION_TYPE_TOUR = 'TOUR';
export const EVOLUTION_TYPE_HINT = 'HINT';
export const EVOLUTION_TYPE_SURVEY = 'SURVEY';

export const SCROLL_OPTION_AUTO = 'auto';
export const SCROLL_OPTION_MANUAL = 'manual';

export const SCROLL_POSITION_TOP = 'top';
export const SCROLL_POSITION_CENTER = 'center';
export const SCROLL_POSITION_BOTTOM = 'bottom';
