import Button from 'components/Button';
import Divider from 'components/Divider';
import React from 'react';
import './_Styles.scss';

const HelpSection = ({isDisconnecting, onDisconnect}) => {
  return (
    <div className="settings-card salesforce-help-section">
      <div className="section section-resources">
        <div className="subtitle-4">Resources</div>
        <div className="section-content">
          <Button
            iconLeft="isax isax-book"
            thin
            onClick={() =>
              window.open(
                'https://help.usejimo.com/help-center/v/using-jimo/integrations/salesforce',
                '_blank',
                'noopener,noreferrer'
              )
            }>
            Documentation
          </Button>
          <Button
            iconLeft="isax isax-global"
            thin
            onClick={() =>
              window.open(
                'https://www.salesforce.com/',
                '_blank',
                'noopener,noreferrer'
              )
            }>
            Website
          </Button>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="subtitle-4">How it works</div>
        <div className="section-content">
          <div className="how-it-works">
            Salesforce contact properties are automatically synced with Jimo
            user properties. This means that you can use Salesforce properties
            to create segments in Jimo. The sync happens in near real time as
            changes are made in Salesforce. Only users seen by Jimo in your app
            will be synced.
          </div>
        </div>
      </div>
      <Divider />
      <div className="section section-disconnect">
        <Button thin danger onClick={onDisconnect} loading={isDisconnecting}>
          Disconnect integration
        </Button>
      </div>
    </div>
  );
};

export default HelpSection;
