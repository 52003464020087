import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import React, {useContext, useEffect, useRef} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT} from 'services/steps';
import {
  RESOURCE_CENTER_TRIGGER_TEXT_BEHAVIOR_ACTIVE_CHECKLIST_TITLE,
  RESOURCE_CENTER_TRIGGER_TEXT_BEHAVIOR_CUSTOM_TEXT,
} from 'shared/front/components/ResourceCenter/constants';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import FontWeightItem from '../../components/sectionItems/FontWeightItem';
import './_Styles.scss';

const behaviorOptions = [
  {
    label: (
      <>
        <span className="body-3 n-800">Active checklist title</span>
        <span className="body-4 n-700">
          Auto-replace the content of the user's current active checklist, if
          any.
        </span>
      </>
    ),
    value: RESOURCE_CENTER_TRIGGER_TEXT_BEHAVIOR_ACTIVE_CHECKLIST_TITLE,
  },
  {
    label: (
      <>
        <span className="body-3 n-800">Custom text</span>
        <span className="body-4 n-700">
          Always display exactly the text you enter here.
        </span>
      </>
    ),
    value: RESOURCE_CENTER_TRIGGER_TEXT_BEHAVIOR_CUSTOM_TEXT,
  },
];

const ResourceCenterTriggerText = () => {
  const {updateBlock: uptBlock, selectedStep: step} =
    useContext(BuilderContext);

  const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 300); // delay focus input of 300ms to match the animation and prevent whole page from scrolling
    return () => clearTimeout(timeout);
  }, []);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT, updateObj);
  };

  const block = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT
  );

  if (block == null) {
    return <></>;
  }

  const {value = '', style = {}} = block;
  const [behavior, text] = value.split('|-|');

  return (
    <div className="block-settings resource-center-trigger-text">
      <Section title="Content">
        <SectionItem title="Behavior">
          <SelectGroup
            isSearchable={false}
            classNamePrefix="behavior-trigger-text-select"
            options={behaviorOptions}
            value={behaviorOptions.find((o) => o.value === behavior)}
            onChange={(option) => {
              updateBlock({
                value: `${option.value}|-|${text}`,
              });
            }}
            menuPortalTarget={document.body}
          />
        </SectionItem>
        <SectionItem title="Text">
          <InputGroup
            className="button-content-input"
            value={text}
            onChange={({target}) =>
              updateBlock({
                value: `${behavior}|-|${target.value}`,
              })
            }
            ref={inputRef}
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section title="Style">
        <FontFamilyItem
          value={style.fontFamily}
          onChange={(value) =>
            updateBlock({
              style: {...style, fontFamily: value},
            })
          }
        />
        <SectionItem title="Font size">
          <PixelPicker
            small
            value={style.fontSize}
            min={8}
            max={42}
            onChange={(value) => {
              updateBlock({
                style: {...style, fontSize: value},
              });
            }}
          />
        </SectionItem>
        <FontWeightItem
          value={style.fontWeight}
          onChange={(value) =>
            updateBlock({
              style: {...style, fontWeight: value},
            })
          }
        />
        <SectionItem title="Text color">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Text color"
            value={style.fontColor}
            onChange={(value) =>
              updateBlock({
                style: {...style, fontColor: value},
              })
            }
          />
        </SectionItem>
      </Section>
      <Divider />
    </div>
  );
};

export default ResourceCenterTriggerText;
