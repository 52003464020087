import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {webhookService} from 'services';
import {Swaler} from 'swaler';

const logger = new Swaler('useWebhookUpdateStatus');

export const useWebhookUpdateStatus = ({onUpdated} = {}) => {
  const updateStatus = async (webhookId, status) => {
    try {
      const updatedWebhook = await webhookService.updateWebhook(webhookId, {
        status,
      });

      onUpdated(updatedWebhook);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('updating webhook status failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  return {
    updateStatus,
  };
};
