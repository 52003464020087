import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {authService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ModalChangePassword');

export default function ModalChangePassword(props) {
  const user = useSelector((state) => generalSelector.getUser(state));

  const [inputPassword, setInputPassword] = useState('');
  const [inputRePassword, setInputRePassword] = useState('');
  const [inputSecurityCode, setInputSecurityCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasToEnterSecurityCode, setHasToEnterSecurityCode] = useState(false);
  const [errorPasswordConstraint, setErrorPasswordConstraint] = useState(false);

  const handleInputChange = useCallback(({target}) => {
    switch (target.name) {
      case 'inputPassword':
        setInputPassword(target.value);
        break;
      case 'inputRePassword':
        setInputRePassword(target.value);
        break;
      case 'inputSecurityCode':
        setInputSecurityCode(target.value);
        break;
      default:
        break;
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputPassword.length < 8) {
      setErrorPasswordConstraint(true);
      return;
    }
    setIsSubmitting(true);
    if (!hasToEnterSecurityCode) {
      try {
        await authService.askForSecurityCode();
        setIsSubmitting(false);
        setHasToEnterSecurityCode(true);
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Asking for security code failed with error ', code);
        setIsSubmitting(false);
        return toastDanger([title, message], {actions});
      }
    } else {
      try {
        await authService.updatePassword(inputPassword, inputSecurityCode);
        toastSuccess('Password updated!', {toastId: 'password-updated'});
        setInputPassword('');
        setInputRePassword('');
        setInputSecurityCode('');
        setHasToEnterSecurityCode(false);
        setIsSubmitting(false);
        props.onRequestClose();
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        setInputSecurityCode('');
        setHasToEnterSecurityCode(false);
        setIsSubmitting(false);
        logger.error('Updating password failed with error ', code);
        setIsSubmitting(false);
        return toastDanger([title, message], {actions});
      }
    }
  };

  useEffect(() => {
    setErrorPasswordConstraint(false);
  }, [inputPassword]);

  const emailSplit = user.email.split('@');

  return (
    <Modal className="modal-change-password" {...props}>
      <form onSubmit={handleSubmit}>
        {errorPasswordConstraint && (
          <Alert
            danger
            title="Your password must at least 8 characters."
            icon="isax isax-shield-cross"></Alert>
        )}
        {hasToEnterSecurityCode === false && (
          <>
            <Input
              legend="New password"
              type="password"
              name="inputPassword"
              placeholder="New password"
              value={inputPassword}
              onChange={handleInputChange}
              disabled={hasToEnterSecurityCode}
              required
            />
            <Input
              legend="Repeat new password"
              type="password"
              name="inputRePassword"
              placeholder="Repeat new password"
              value={inputRePassword}
              onChange={handleInputChange}
              disabled={hasToEnterSecurityCode}
              required
            />
          </>
        )}
        {hasToEnterSecurityCode && (
          <>
            <Alert
              title="We've sent you a security code to your email"
              icon="isax isax-sms">
              <span className="body-4 n-700">
                We've just sent a security code to{' '}
                {emailSplit[0]
                  .split('')
                  .map((letter, index) => (index === 0 ? letter : '*'))
                  .join('')
                  .concat('@')
                  .concat(emailSplit[1])}
                . Please check your inbox and input the code to confirm your
                changes. If you don't see the email, kindly check your spam
                folder.
              </span>
            </Alert>
            <Input
              legend="Security code"
              name="inputSecurityCode"
              placeholder="Security code"
              value={inputSecurityCode}
              onChange={handleInputChange}
            />
          </>
        )}
        <div className="actions">
          <Button
            type="button"
            muted
            onClick={() => {
              props.onRequestClose();
              setInputSecurityCode('');
              setHasToEnterSecurityCode(false);
              setInputPassword('');
              setInputRePassword('');
            }}>
            Cancel
          </Button>
          <Button
            primary
            loading={isSubmitting}
            disabled={
              inputPassword !== inputRePassword ||
              inputPassword.length === 0 ||
              inputRePassword.length === 0
            }>
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
}
