import {EVENT_NEW_SEGMENT_CREATED} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {array, bool, func, object, string} from 'prop-types';
import {createContext, useEffect, useState} from 'react';
import {useQueries} from 'react-query';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_USERS_SEGMENT_WITH_ID} from 'router/routes.const';
import {JimoIconSmall} from 'scenes/Settings/scenes/Integrations/Customerio/scenes/Settings';
import {HubspotIcon} from 'scenes/Settings/scenes/Integrations/Hubspot/components/HubspotSettings/components/SettingsSetup';
import {SalesforceLogo} from 'scenes/Settings/scenes/Integrations/Salesforce';
import ModalCreateSegment from 'scenes/Users/components/ModalCreateSegment';
import {generalSelector} from 'selectors';
import {
  amplitudeService,
  customerioService,
  eventService,
  mixpanelService,
  posthogService,
  segmentService,
  segmentioService,
} from 'services';
import {CIO_EVENTS_PROPERTIES} from 'services/customerio';
import {EVENT_SOURCE_JIMO, EVENT_SOURCE_JIMO_API} from 'services/event';
import {
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_EDITOR,
  PROJECT_ROLE_MEMBER,
} from 'services/project';
import {
  CATEGORY_AMPLITUDE_EVENTS,
  CATEGORY_CIO_EVENTS,
  CATEGORY_CUSTOM_ATTRIBUTES,
  CATEGORY_JIMO_API_EVENTS,
  CATEGORY_MIXPANEL_EVENTS,
  CATEGORY_POSTHOG_EVENTS,
  CATEGORY_SEGMENTIO_EVENTS,
  CATEGORY_SEGMENTIO_FIELDS,
  CATEGORY_TRACKED_EVENTS,
  CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT,
  CUSTOM_ATTRIBUTE_SOURCE_JIMO,
  CUSTOM_ATTRIBUTE_SOURCE_SALESFORCE,
  CUSTOM_ATTRIBUTE_SOURCE_SEGMENT,
} from 'services/segment';
import {Swaler} from 'swaler';
import './_Styles.scss';
import Logic from './components/Logic';
import AmplitudeIconSvg from './icons/amplitude.svg';
import CustomerioIcon from './icons/cio.png';
import MixpanelIconSvg from './icons/mixpanel.svg';
import PosthogIconSvg from './icons/posthog.svg';
import SegmentioIcon from './icons/segmentio-icon-dark.svg';
import {CATEGORY_EXISTING_SEGMENT} from './select-attribute';
import {
  addAttributeToLogic,
  addLogicGroupToLogic,
  createAttribute,
  defaultBlocks,
  deleteAttributeFromLogic,
  deleteLogicGroupFromLogic,
  isSegmentationValid,
  updateLogicOperator,
} from './utils';

const logger = new Swaler('SegmentAttributesEditor');

export const MixpanelIcon = MixpanelIconSvg;
export const PosthogIcon = PosthogIconSvg;

export const SegmentAttributesEditorContext = createContext();

const propTypes = {
  segment: object,
  dropdownProps: object,
  withSegments: array,
  onSave: func,
  onLookup: func,
  onChange: func,
  onRemoveOnTheFly: func,
  onSelectExistingSegment: func,
  withGlobalBg: bool,
  hideDefaultActions: bool,
  hideAttributes: bool,
  addButtonLabel: string,
  lookupData: object,
  attributes: array,
  setAttributes: func,
  logic: object,
  setLogic: func,
};

const defaultProps = {
  dropdownProps: {},
  withSegments: [],
  segment: null,
  onSave: () => {},
  onLookup: () => {},
  onRemoveOnTheFly: null,
  onSelectExistingSegment: () => {},
  withGlobalBg: true,
  hideDefaultActions: false,
  hideAttributes: false,
  addButtonLabel: null,
  lookupData: null,
  attributes: [],
  setAttributes: () => {},
  logic: null,
  setLogic: () => {},
};

const SegmentAttributesEditor = ({
  segment,
  withSegments,
  dropdownProps,
  hideDefaultActions,
  hideAttributes,
  onSave,
  onLookup,
  onRemoveOnTheFly,
  onSelectExistingSegment,
  addButtonLabel,
  lookupData,
  attributes,
  setAttributes,
  logic,
  setLogic,
}) => {
  const history = useHistory();

  const project = useSelector(() => generalSelector.getProject());
  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );
  const [queriesDone, setQueriesDone] = useState([
    false,
    project.segmentio == null ? true : false,
    project.segmentio == null ? true : false,
  ]); // [tracked events, segmentio fields, segmentio events]

  let [
    {data: segmentioEvents = []},
    {data: trackedEvents = []},
    {data: customAttributes = []},
    {data: customerioEvents = []},
    {data: posthogEvents = []},
    {data: mixpanelEvents = []},
    {data: amplitudeEvents = []},
    {data: jimoApiEvents = []},
  ] = useQueries([
    {
      queryKey: 'segmentioEvents',
      queryFn: () => segmentioService.getSegmentioEvents(project.uid),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'trackedEvents',
      queryFn: () =>
        eventService.getEvents({
          sources: [EVENT_SOURCE_JIMO],
        }),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'customAttributes',
      queryFn: () => segmentService.getSegmentCustomAttributes(project.uid),
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setQueriesDone((queriesDone) => [true, queriesDone[1], queriesDone[2]]);
      },
    },
    {
      queryKey: 'cioEvents',
      queryFn: () =>
        customerioService.getCioEvents(project.uid).then((events) =>
          events.map((e) => ({
            ...e,
            properties: CIO_EVENTS_PROPERTIES,
          }))
        ),
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setQueriesDone((queriesDone) => [true, queriesDone[1], queriesDone[2]]);
      },
    },
    {
      queryKey: 'posthogEvent',
      queryFn: () => posthogService.getPosthogEvents(project.uid),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'mixpanelEvents',
      queryFn: () => mixpanelService.getMixpanelEvents(project.uid),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'amplitudeEvents',
      queryFn: () => amplitudeService.getAmplitudeEvents(project.uid),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'jimoApiEvents',
      queryFn: () =>
        eventService.getEvents({
          sources: [EVENT_SOURCE_JIMO_API],
          relations: ['properties'],
        }),
      refetchOnWindowFocus: false,
    },
  ]);

  segment = JSON.parse(JSON.stringify(segment));
  const segmentioFields = customAttributes.filter(
    (attribute) => attribute.source === CUSTOM_ATTRIBUTE_SOURCE_SEGMENT
  );
  const nonSegmentCustomAttributes = customAttributes.filter((attribute) =>
    [
      CUSTOM_ATTRIBUTE_SOURCE_JIMO,
      CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT,
      CUSTOM_ATTRIBUTE_SOURCE_SALESFORCE,
    ].includes(attribute.source)
  );

  const [loading, setLoading] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);

  useEffect(() => {
    if (
      segment?.temporary === true &&
      JSON.stringify(attributes) !== JSON.stringify(segment?.attributes || [])
    ) {
      setAttributes(segment.attributes || []);
    }
  }, [segment]);

  const handleAddAttribute = (attr, opts = null, groupId) => {
    const newAttribute = createAttribute(attr, opts || {});
    const attributesUpdated = attributes.concat(newAttribute);

    setAttributes(attributesUpdated);
    const updatedLogic = addAttributeToLogic(logic, newAttribute, groupId);
    setLogic(JSON.parse(JSON.stringify(updatedLogic)));
  };

  const handleAddLogicGroup = (groupId) => {
    const updatedLogic = addLogicGroupToLogic(logic, groupId);
    setLogic(JSON.parse(JSON.stringify(updatedLogic)));
  };

  const handleDeleteAttributeFromLogic = (attributeId) => {
    const updatedLogic = deleteAttributeFromLogic(logic, attributeId);
    setLogic(JSON.parse(JSON.stringify(updatedLogic)));
  };

  const handleDeleteLogicGroupFromLogic = (groupId) => {
    const updatedLogic = deleteLogicGroupFromLogic(logic, groupId);
    setLogic(JSON.parse(JSON.stringify(updatedLogic)));
  };

  const handleUpdateLogicOperator = (groupId, operator) => {
    const updatedLogic = updateLogicOperator(logic, groupId, operator);
    setLogic(JSON.parse(JSON.stringify(updatedLogic)));
  };

  const handleSave = async (segmentParam = segment) => {
    setLoading(true);
    try {
      const updatedSegment = await segmentService.updateSegmentAttributes(
        segmentParam.uid,
        {attributes, logic}
      );
      const savedAttributes = updatedSegment.attributes;

      onSave(JSON.parse(JSON.stringify(savedAttributes)));
      setAttributes(savedAttributes);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Saving attributes failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setLoading(false);
    }
  };

  const hasChanges = () =>
    JSON.stringify(lookupData?.attributes || segment?.attributes) !==
      JSON.stringify(attributes) ||
    JSON.stringify(lookupData?.logic || segment?.logic) !==
      JSON.stringify(logic);

  const canCreateSegment = [
    PROJECT_ROLE_ADMIN,
    PROJECT_ROLE_EDITOR,
    PROJECT_ROLE_MEMBER,
  ].includes(projectMember.role);

  const BLOCKS = [
    ...defaultBlocks,
    ...segmentioFields.map((e) => ({
      category: CATEGORY_SEGMENTIO_FIELDS,
      name: e.name,
      title: e.name,
      iconBackgroundColor: '#ECE6FD',
      icon: (
        <img
          className="icon-segmentio"
          src={SegmentioIcon}
          alt="segmentio-icon"
        />
      ),
      value: `SEGMENTIO_FIELDS-${e.name}`,
    })),
    ...segmentioEvents.map((e) => ({
      category: CATEGORY_SEGMENTIO_EVENTS,
      name: e.name,
      title: e.name,
      iconBackgroundColor: '#CEB1E5',
      icon: (
        <img
          className="icon-segmentio"
          src={SegmentioIcon}
          alt="segmentio-icon"
        />
      ),
      value: `SEGMENTIO_EVENTS-${e.name}`,
    })),
    ...trackedEvents.map((e) => ({
      category: CATEGORY_TRACKED_EVENTS,
      title: e.name,
      iconBackgroundColor: '#C4E9BF',
      icon: <i className="icon-pointer" />,
      value: `TRACKED_EVENTS-${e.uid}`,
      eventId: e.uid,
    })),
    ...withSegments.map((s) => ({
      ...s,
      category: CATEGORY_EXISTING_SEGMENT,
      title: s.name,
      description: '',
    })),
    ...nonSegmentCustomAttributes.map((a) => ({
      category: CATEGORY_CUSTOM_ATTRIBUTES,
      title: a.name,
      description: '',
      iconBackgroundColor: '#FFD7D7',
      icon:
        a.source === 'HUBSPOT' ? (
          <HubspotIcon />
        ) : a.source === 'SALESFORCE' ? (
          <SalesforceLogo />
        ) : (
          <i className="icon-pin" />
        ),
      value: `CUSTOM_ATTRIBUTES-${a.uid}`,
      customAttributeId: a.uid,
    })),
    ...customerioEvents.map((e) => ({
      category: CATEGORY_CIO_EVENTS,
      name: e.name,
      title: e.name,
      iconBackgroundColor: '#f5cd07',
      icon: (
        <img
          style={{filter: 'brightness(0)'}}
          width={16}
          height={12}
          className="icon-customerio"
          src={CustomerioIcon}
          alt="customerio-icon"
        />
      ),
      value: `CIO_EVENTS-${e.name}`,
    })),
    ...posthogEvents.map((e) => ({
      category: CATEGORY_POSTHOG_EVENTS,
      name: e.name,
      title: e.name,
      iconBackgroundColor: '#F03707',
      icon: (
        <img
          style={{filter: 'brightness(0)'}}
          width="100%"
          height="100%"
          className="icon-posthog"
          src={PosthogIconSvg}
          alt="posthog-icon"
        />
      ),
      value: `POSTHOG_EVENTS-${e.name}`,
    })),
    ...mixpanelEvents.map((e) => ({
      category: CATEGORY_MIXPANEL_EVENTS,
      name: e.name,
      title: e.name,
      iconBackgroundColor: '#d39bfc',
      icon: (
        <img
          style={{
            filter: 'brightness(0)',
            width: '60%',
            height: '60%',
          }}
          width="60%"
          height="60%"
          className="icon-mixpanel"
          src={MixpanelIconSvg}
          alt="mixpanel-icon"
        />
      ),
      value: `MIXPANEL_EVENTS-${e.name}`,
    })),
    ...amplitudeEvents.map((e) => ({
      category: CATEGORY_AMPLITUDE_EVENTS,
      name: e.name,
      title: e.name,
      iconBackgroundColor: '#85a4ff',
      icon: (
        <img
          style={{
            filter: 'brightness(0)',
            width: '60%',
            height: '60%',
          }}
          width="60%"
          height="60%"
          className="icon-amplitude"
          src={AmplitudeIconSvg}
          alt="amplitude-icon"
        />
      ),
      value: `AMPLITUDE_EVENTS-${e.name}`,
    })),
    ...jimoApiEvents.map((e) => ({
      category: CATEGORY_JIMO_API_EVENTS,
      name: e.name,
      title: e.name,
      iconBackgroundColor: '#C4E9BF',
      icon: (
        <img
          width="60%"
          height="60%"
          className="icon-jimo"
          src={JimoIconSmall}
          alt="jimo-icon"
        />
      ),
      value: `JIMO_API_EVENTS-${e.name}`,
    })),
  ];

  const {isValid} = isSegmentationValid(attributes, logic);

  let btnsActions = null;

  if (onRemoveOnTheFly != null) {
    btnsActions = (
      <Button onClick={onRemoveOnTheFly} className="btn-remove">
        Remove on the fly segment
      </Button>
    );
  } else {
    if (segment == null) {
      if (attributes.length > 0 && canCreateSegment === true) {
        btnsActions = (
          <>
            <Button
              onClick={() => setShowModalCreate(true)}
              disabled={hasChanges() === true}
              className="btn-save"
              loading={loading}>
              Save as...
            </Button>
            <Button
              primary
              reverted
              onClick={() => {
                onLookup(attributes, logic);
              }}
              disabled={hasChanges() === false || isValid === false}
              loading={loading}>
              Apply
            </Button>
          </>
        );
      }
    } else {
      if (canCreateSegment === true && hasChanges() === true) {
        btnsActions = (
          <>
            <Button
              onClick={() => {
                setAttributes(
                  lookupData?.attributes != null
                    ? lookupData.attributes
                    : segment?.attributes || []
                );
                setLogic(
                  lookupData?.logic != null
                    ? lookupData.logic
                    : segment?.logic || null
                );
              }}
              disabled={hasChanges() === false}
              loading={loading}>
              Cancel
            </Button>
            <Button
              primary
              reverted
              onClick={() => {
                onLookup(attributes, logic);
              }}
              disabled={hasChanges() === false || isValid === false}
              loading={loading}>
              Apply
            </Button>
          </>
        );
      }
    }
  }

  return (
    <SegmentAttributesEditorContext.Provider
      value={{
        attributes,
        setAttributes,
        segment,
        setLogic,
        logic,
        handleAddAttribute,
        handleAddLogicGroup,
        handleDeleteAttributeFromLogic,
        handleDeleteLogicGroupFromLogic,
        handleUpdateLogicOperator,
        segmentioFields,
        segmentioEvents,
        customerioEvents,
        posthogEvents,
        mixpanelEvents,
        amplitudeEvents,
        jimoApiEvents,
        trackedEvents,
        withSegments,
        hideAttributes,
        addButtonLabel,
        onSelectExistingSegment,
        dropdownProps,
        BLOCKS,
      }}>
      <div
        className={classnames('segment-attributes-editor', {
          'is-empty': attributes.length === 0,
        })}>
        <div
          className={classnames('list-attributes', {
            'is-empty': attributes.length <= 0,
          })}>
          {logic != null && <Logic logicGroup={logic} />}
        </div>
        {((hideDefaultActions === false && btnsActions != null) ||
          onRemoveOnTheFly != null) && (
          <>
            <Divider />
            <div className={classnames('btns-wrapper')}>{btnsActions}</div>
          </>
        )}
      </div>
      <ModalCreateSegment
        isOpen={showModalCreate}
        onRequestClose={() => setShowModalCreate(false)}
        onSegmentCreated={async (segment) => {
          await handleSave(segment);
          setShowModalCreate(false);
          amplitude.getInstance().logEvent(EVENT_NEW_SEGMENT_CREATED, {
            from: 'Save as button',
          });
          history.push(ROUTE_USERS_SEGMENT_WITH_ID(segment.uid));
        }}
      />
    </SegmentAttributesEditorContext.Provider>
  );
};

SegmentAttributesEditor.propTypes = propTypes;
SegmentAttributesEditor.defaultProps = defaultProps;

export default SegmentAttributesEditor;
