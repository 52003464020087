export const fontFamiliesOptions = [
  {
    native: true,
    label: 'Arial',
    value: 'Arial, sans-serif',
  },
  {
    native: true,
    label: 'Helvetica',
    value: 'Helvetica, sans-serif',
  },
  {
    native: true,
    label: 'Times New Roman',
    value: 'Times New Roman, serif',
  },
  {
    native: true,
    label: 'Times',
    value: 'Times, serif',
  },
  {
    native: true,
    label: 'Courier New',
    value: 'Courier New, monospace',
  },
  {
    native: true,
    label: 'Courier',
    value: 'Courier, monospace',
  },
  {
    native: true,
    label: 'Verdana',
    value: 'Verdana, sans-serif',
  },
  {
    native: true,
    label: 'Georgia',
    value: 'Georgia, serif',
  },
  {
    native: true,
    label: 'Palatino',
    value: 'Palatino, serif',
  },
  {
    native: true,
    label: 'Garamond',
    value: 'Garamond, serif',
  },
  {
    native: true,
    label: 'Bookman',
    value: 'Bookman, serif',
  },
  {
    native: true,
    label: 'Comic Sans MS',
    value: 'Comic Sans MS, cursive',
  },
  {
    native: true,
    label: 'Trebuchet MS',
    value: 'Trebuchet MS, sans-serif',
  },
  {
    native: true,
    label: 'Arial Black',
    value: 'Arial Black, sans-serif',
  },
  {
    native: true,
    label: 'Impact',
    value: 'Impact, sans-serif',
  },
  {
    native: true,
    label: 'Lucida Sans Unicode',
    value: 'Lucida Sans Unicode, sans-serif',
  },
  {
    native: true,
    label: 'Tahoma',
    value: 'Tahoma, sans-serif',
  },
  {
    native: true,
    label: 'Lucida Console',
    value: 'Lucida Console, monospace',
  },
  {
    label: 'ABeeZee',
    value: 'ABeeZee',
  },
  {
    label: 'Abel',
    value: 'Abel',
  },
  {
    label: 'Abhaya Libre',
    value: 'Abhaya Libre',
  },
  {
    label: 'Aboreto',
    value: 'Aboreto',
  },
  {
    label: 'Abril Fatface',
    value: 'Abril Fatface',
  },
  {
    label: 'Abyssinica SIL',
    value: 'Abyssinica SIL',
  },
  {
    label: 'Aclonica',
    value: 'Aclonica',
  },
  {
    label: 'Acme',
    value: 'Acme',
  },
  {
    label: 'Actor',
    value: 'Actor',
  },
  {
    label: 'Adamina',
    value: 'Adamina',
  },
  {
    label: 'ADLaM Display',
    value: 'ADLaM Display',
  },
  {
    label: 'Advent Pro',
    value: 'Advent Pro',
  },
  {
    label: 'Agdasima',
    value: 'Agdasima',
  },
  {
    label: 'Aguafina Script',
    value: 'Aguafina Script',
  },
  {
    label: 'Akatab',
    value: 'Akatab',
  },
  {
    label: 'Akaya Kanadaka',
    value: 'Akaya Kanadaka',
  },
  {
    label: 'Akaya Telivigala',
    value: 'Akaya Telivigala',
  },
  {
    label: 'Akronim',
    value: 'Akronim',
  },
  {
    label: 'Akshar',
    value: 'Akshar',
  },
  {
    label: 'Aladin',
    value: 'Aladin',
  },
  {
    label: 'Alata',
    value: 'Alata',
  },
  {
    label: 'Alatsi',
    value: 'Alatsi',
  },
  {
    label: 'Albert Sans',
    value: 'Albert Sans',
  },
  {
    label: 'Aldrich',
    value: 'Aldrich',
  },
  {
    label: 'Alef',
    value: 'Alef',
  },
  {
    label: 'Alegreya',
    value: 'Alegreya',
  },
  {
    label: 'Alegreya Sans',
    value: 'Alegreya Sans',
  },
  {
    label: 'Alegreya Sans SC',
    value: 'Alegreya Sans SC',
  },
  {
    label: 'Alegreya SC',
    value: 'Alegreya SC',
  },
  {
    label: 'Aleo',
    value: 'Aleo',
  },
  {
    label: 'Alex Brush',
    value: 'Alex Brush',
  },
  {
    label: 'Alexandria',
    value: 'Alexandria',
  },
  {
    label: 'Alfa Slab One',
    value: 'Alfa Slab One',
  },
  {
    label: 'Alice',
    value: 'Alice',
  },
  {
    label: 'Alike',
    value: 'Alike',
  },
  {
    label: 'Alike Angular',
    value: 'Alike Angular',
  },
  {
    label: 'Alkalami',
    value: 'Alkalami',
  },
  {
    label: 'Alkatra',
    value: 'Alkatra',
  },
  {
    label: 'Allan',
    value: 'Allan',
  },
  {
    label: 'Allerta',
    value: 'Allerta',
  },
  {
    label: 'Allerta Stencil',
    value: 'Allerta Stencil',
  },
  {
    label: 'Allison',
    value: 'Allison',
  },
  {
    label: 'Allura',
    value: 'Allura',
  },
  {
    label: 'Almarai',
    value: 'Almarai',
  },
  {
    label: 'Almendra',
    value: 'Almendra',
  },
  {
    label: 'Almendra Display',
    value: 'Almendra Display',
  },
  {
    label: 'Almendra SC',
    value: 'Almendra SC',
  },
  {
    label: 'Alumni Sans',
    value: 'Alumni Sans',
  },
  {
    label: 'Alumni Sans Collegiate One',
    value: 'Alumni Sans Collegiate One',
  },
  {
    label: 'Alumni Sans Inline One',
    value: 'Alumni Sans Inline One',
  },
  {
    label: 'Alumni Sans Pinstripe',
    value: 'Alumni Sans Pinstripe',
  },
  {
    label: 'Amarante',
    value: 'Amarante',
  },
  {
    label: 'Amaranth',
    value: 'Amaranth',
  },
  {
    label: 'Amatic SC',
    value: 'Amatic SC',
  },
  {
    label: 'Amethysta',
    value: 'Amethysta',
  },
  {
    label: 'Amiko',
    value: 'Amiko',
  },
  {
    label: 'Amiri',
    value: 'Amiri',
  },
  {
    label: 'Amiri Quran',
    value: 'Amiri Quran',
  },
  {
    label: 'Amita',
    value: 'Amita',
  },
  {
    label: 'Anaheim',
    value: 'Anaheim',
  },
  {
    label: 'Andada Pro',
    value: 'Andada Pro',
  },
  {
    label: 'Andika',
    value: 'Andika',
  },
  {
    label: 'Anek Bangla',
    value: 'Anek Bangla',
  },
  {
    label: 'Anek Devanagari',
    value: 'Anek Devanagari',
  },
  {
    label: 'Anek Gujarati',
    value: 'Anek Gujarati',
  },
  {
    label: 'Anek Gurmukhi',
    value: 'Anek Gurmukhi',
  },
  {
    label: 'Anek Kannada',
    value: 'Anek Kannada',
  },
  {
    label: 'Anek Latin',
    value: 'Anek Latin',
  },
  {
    label: 'Anek Malayalam',
    value: 'Anek Malayalam',
  },
  {
    label: 'Anek Odia',
    value: 'Anek Odia',
  },
  {
    label: 'Anek Tamil',
    value: 'Anek Tamil',
  },
  {
    label: 'Anek Telugu',
    value: 'Anek Telugu',
  },
  {
    label: 'Angkor',
    value: 'Angkor',
  },
  {
    label: 'Annie Use Your Telescope',
    value: 'Annie Use Your Telescope',
  },
  {
    label: 'Anonymous Pro',
    value: 'Anonymous Pro',
  },
  {
    label: 'Antic',
    value: 'Antic',
  },
  {
    label: 'Antic Didone',
    value: 'Antic Didone',
  },
  {
    label: 'Antic Slab',
    value: 'Antic Slab',
  },
  {
    label: 'Anton',
    value: 'Anton',
  },
  {
    label: 'Antonio',
    value: 'Antonio',
  },
  {
    label: 'Anuphan',
    value: 'Anuphan',
  },
  {
    label: 'Anybody',
    value: 'Anybody',
  },
  {
    label: 'Aoboshi One',
    value: 'Aoboshi One',
  },
  {
    label: 'AR One Sans',
    value: 'AR One Sans',
  },
  {
    label: 'Arapey',
    value: 'Arapey',
  },
  {
    label: 'Arbutus',
    value: 'Arbutus',
  },
  {
    label: 'Arbutus Slab',
    value: 'Arbutus Slab',
  },
  {
    label: 'Architects Daughter',
    value: 'Architects Daughter',
  },
  {
    label: 'Archivo',
    value: 'Archivo',
  },
  {
    label: 'Archivo Black',
    value: 'Archivo Black',
  },
  {
    label: 'Archivo Narrow',
    value: 'Archivo Narrow',
  },
  {
    label: 'Are You Serious',
    value: 'Are You Serious',
  },
  {
    label: 'Aref Ruqaa',
    value: 'Aref Ruqaa',
  },
  {
    label: 'Aref Ruqaa Ink',
    value: 'Aref Ruqaa Ink',
  },
  {
    label: 'Arima',
    value: 'Arima',
  },
  {
    label: 'Arima Madurai',
    value: 'Arima Madurai',
  },
  {
    label: 'Arimo',
    value: 'Arimo',
  },
  {
    label: 'Arizonia',
    value: 'Arizonia',
  },
  {
    label: 'Armata',
    value: 'Armata',
  },
  {
    label: 'Arsenal',
    value: 'Arsenal',
  },
  {
    label: 'Artifika',
    value: 'Artifika',
  },
  {
    label: 'Arvo',
    value: 'Arvo',
  },
  {
    label: 'Arya',
    value: 'Arya',
  },
  {
    label: 'Asap',
    value: 'Asap',
  },
  {
    label: 'Asap Condensed',
    value: 'Asap Condensed',
  },
  {
    label: 'Asar',
    value: 'Asar',
  },
  {
    label: 'Asset',
    value: 'Asset',
  },
  {
    label: 'Assistant',
    value: 'Assistant',
  },
  {
    label: 'Astloch',
    value: 'Astloch',
  },
  {
    label: 'Asul',
    value: 'Asul',
  },
  {
    label: 'Athiti',
    value: 'Athiti',
  },
  {
    label: 'Atkinson Hyperlegible',
    value: 'Atkinson Hyperlegible',
  },
  {
    label: 'Atma',
    value: 'Atma',
  },
  {
    label: 'Atomic Age',
    value: 'Atomic Age',
  },
  {
    label: 'Aubrey',
    value: 'Aubrey',
  },
  {
    label: 'Audiowide',
    value: 'Audiowide',
  },
  {
    label: 'Autour One',
    value: 'Autour One',
  },
  {
    label: 'Average',
    value: 'Average',
  },
  {
    label: 'Average Sans',
    value: 'Average Sans',
  },
  {
    label: 'Averia Gruesa Libre',
    value: 'Averia Gruesa Libre',
  },
  {
    label: 'Averia Libre',
    value: 'Averia Libre',
  },
  {
    label: 'Averia Sans Libre',
    value: 'Averia Sans Libre',
  },
  {
    label: 'Averia Serif Libre',
    value: 'Averia Serif Libre',
  },
  {
    label: 'Azeret Mono',
    value: 'Azeret Mono',
  },
  {
    label: 'B612',
    value: 'B612',
  },
  {
    label: 'B612 Mono',
    value: 'B612 Mono',
  },
  {
    label: 'Babylonica',
    value: 'Babylonica',
  },
  {
    label: 'Bacasime Antique',
    value: 'Bacasime Antique',
  },
  {
    label: 'Bad Script',
    value: 'Bad Script',
  },
  {
    label: 'Bagel Fat One',
    value: 'Bagel Fat One',
  },
  {
    label: 'Bahiana',
    value: 'Bahiana',
  },
  {
    label: 'Bahianita',
    value: 'Bahianita',
  },
  {
    label: 'Bai Jamjuree',
    value: 'Bai Jamjuree',
  },
  {
    label: 'Bakbak One',
    value: 'Bakbak One',
  },
  {
    label: 'Ballet',
    value: 'Ballet',
  },
  {
    label: 'Baloo 2',
    value: 'Baloo 2',
  },
  {
    label: 'Baloo Bhai 2',
    value: 'Baloo Bhai 2',
  },
  {
    label: 'Baloo Bhaijaan 2',
    value: 'Baloo Bhaijaan 2',
  },
  {
    label: 'Baloo Bhaina 2',
    value: 'Baloo Bhaina 2',
  },
  {
    label: 'Baloo Chettan 2',
    value: 'Baloo Chettan 2',
  },
  {
    label: 'Baloo Da 2',
    value: 'Baloo Da 2',
  },
  {
    label: 'Baloo Paaji 2',
    value: 'Baloo Paaji 2',
  },
  {
    label: 'Baloo Tamma 2',
    value: 'Baloo Tamma 2',
  },
  {
    label: 'Baloo Tammudu 2',
    value: 'Baloo Tammudu 2',
  },
  {
    label: 'Baloo Thambi 2',
    value: 'Baloo Thambi 2',
  },
  {
    label: 'Balsamiq Sans',
    value: 'Balsamiq Sans',
  },
  {
    label: 'Balthazar',
    value: 'Balthazar',
  },
  {
    label: 'Bangers',
    value: 'Bangers',
  },
  {
    label: 'Barlow',
    value: 'Barlow',
  },
  {
    label: 'Barlow Condensed',
    value: 'Barlow Condensed',
  },
  {
    label: 'Barlow Semi Condensed',
    value: 'Barlow Semi Condensed',
  },
  {
    label: 'Barriecito',
    value: 'Barriecito',
  },
  {
    label: 'Barrio',
    value: 'Barrio',
  },
  {
    label: 'Basic',
    value: 'Basic',
  },
  {
    label: 'Baskervville',
    value: 'Baskervville',
  },
  {
    label: 'Battambang',
    value: 'Battambang',
  },
  {
    label: 'Baumans',
    value: 'Baumans',
  },
  {
    label: 'Bayon',
    value: 'Bayon',
  },
  {
    label: 'Be Vietnam Pro',
    value: 'Be Vietnam Pro',
  },
  {
    label: 'Beau Rivage',
    value: 'Beau Rivage',
  },
  {
    label: 'Bebas Neue',
    value: 'Bebas Neue',
  },
  {
    label: 'Belanosima',
    value: 'Belanosima',
  },
  {
    label: 'Belgrano',
    value: 'Belgrano',
  },
  {
    label: 'Bellefair',
    value: 'Bellefair',
  },
  {
    label: 'Belleza',
    value: 'Belleza',
  },
  {
    label: 'Bellota',
    value: 'Bellota',
  },
  {
    label: 'Bellota Text',
    value: 'Bellota Text',
  },
  {
    label: 'BenchNine',
    value: 'BenchNine',
  },
  {
    label: 'Benne',
    value: 'Benne',
  },
  {
    label: 'Bentham',
    value: 'Bentham',
  },
  {
    label: 'Berkshire Swash',
    value: 'Berkshire Swash',
  },
  {
    label: 'Besley',
    value: 'Besley',
  },
  {
    label: 'Beth Ellen',
    value: 'Beth Ellen',
  },
  {
    label: 'Bevan',
    value: 'Bevan',
  },
  {
    label: 'BhuTuka Expanded One',
    value: 'BhuTuka Expanded One',
  },
  {
    label: 'Big Shoulders Display',
    value: 'Big Shoulders Display',
  },
  {
    label: 'Big Shoulders Inline Display',
    value: 'Big Shoulders Inline Display',
  },
  {
    label: 'Big Shoulders Inline Text',
    value: 'Big Shoulders Inline Text',
  },
  {
    label: 'Big Shoulders Stencil Display',
    value: 'Big Shoulders Stencil Display',
  },
  {
    label: 'Big Shoulders Stencil Text',
    value: 'Big Shoulders Stencil Text',
  },
  {
    label: 'Big Shoulders Text',
    value: 'Big Shoulders Text',
  },
  {
    label: 'Bigelow Rules',
    value: 'Bigelow Rules',
  },
  {
    label: 'Bigshot One',
    value: 'Bigshot One',
  },
  {
    label: 'Bilbo',
    value: 'Bilbo',
  },
  {
    label: 'Bilbo Swash Caps',
    value: 'Bilbo Swash Caps',
  },
  {
    label: 'BioRhyme',
    value: 'BioRhyme',
  },
  {
    label: 'BioRhyme Expanded',
    value: 'BioRhyme Expanded',
  },
  {
    label: 'Birthstone',
    value: 'Birthstone',
  },
  {
    label: 'Birthstone Bounce',
    value: 'Birthstone Bounce',
  },
  {
    label: 'Biryani',
    value: 'Biryani',
  },
  {
    label: 'Bitter',
    value: 'Bitter',
  },
  {
    label: 'BIZ UDGothic',
    value: 'BIZ UDGothic',
  },
  {
    label: 'BIZ UDMincho',
    value: 'BIZ UDMincho',
  },
  {
    label: 'BIZ UDPGothic',
    value: 'BIZ UDPGothic',
  },
  {
    label: 'BIZ UDPMincho',
    value: 'BIZ UDPMincho',
  },
  {
    label: 'Black And White Picture',
    value: 'Black And White Picture',
  },
  {
    label: 'Black Han Sans',
    value: 'Black Han Sans',
  },
  {
    label: 'Black Ops One',
    value: 'Black Ops One',
  },
  {
    label: 'Blaka',
    value: 'Blaka',
  },
  {
    label: 'Blaka Hollow',
    value: 'Blaka Hollow',
  },
  {
    label: 'Blaka Ink',
    value: 'Blaka Ink',
  },
  {
    label: 'Blinker',
    value: 'Blinker',
  },
  {
    label: 'Bodoni Moda',
    value: 'Bodoni Moda',
  },
  {
    label: 'Bokor',
    value: 'Bokor',
  },
  {
    label: 'Bona Nova',
    value: 'Bona Nova',
  },
  {
    label: 'Bonbon',
    value: 'Bonbon',
  },
  {
    label: 'Bonheur Royale',
    value: 'Bonheur Royale',
  },
  {
    label: 'Boogaloo',
    value: 'Boogaloo',
  },
  {
    label: 'Borel',
    value: 'Borel',
  },
  {
    label: 'Bowlby One',
    value: 'Bowlby One',
  },
  {
    label: 'Bowlby One SC',
    value: 'Bowlby One SC',
  },
  {
    label: 'Braah One',
    value: 'Braah One',
  },
  {
    label: 'Brawler',
    value: 'Brawler',
  },
  {
    label: 'Bree Serif',
    value: 'Bree Serif',
  },
  {
    label: 'Bricolage Grotesque',
    value: 'Bricolage Grotesque',
  },
  {
    label: 'Bruno Ace',
    value: 'Bruno Ace',
  },
  {
    label: 'Bruno Ace SC',
    value: 'Bruno Ace SC',
  },
  {
    label: 'Brygada 1918',
    value: 'Brygada 1918',
  },
  {
    label: 'Bubblegum Sans',
    value: 'Bubblegum Sans',
  },
  {
    label: 'Bubbler One',
    value: 'Bubbler One',
  },
  {
    label: 'Buda',
    value: 'Buda',
  },
  {
    label: 'Buenard',
    value: 'Buenard',
  },
  {
    label: 'Bungee',
    value: 'Bungee',
  },
  {
    label: 'Bungee Hairline',
    value: 'Bungee Hairline',
  },
  {
    label: 'Bungee Inline',
    value: 'Bungee Inline',
  },
  {
    label: 'Bungee Outline',
    value: 'Bungee Outline',
  },
  {
    label: 'Bungee Shade',
    value: 'Bungee Shade',
  },
  {
    label: 'Bungee Spice',
    value: 'Bungee Spice',
  },
  {
    label: 'Butcherman',
    value: 'Butcherman',
  },
  {
    label: 'Butterfly Kids',
    value: 'Butterfly Kids',
  },
  {
    label: 'Cabin',
    value: 'Cabin',
  },
  {
    label: 'Cabin Condensed',
    value: 'Cabin Condensed',
  },
  {
    label: 'Cabin Sketch',
    value: 'Cabin Sketch',
  },
  {
    label: 'Caesar Dressing',
    value: 'Caesar Dressing',
  },
  {
    label: 'Cagliostro',
    value: 'Cagliostro',
  },
  {
    label: 'Cairo',
    value: 'Cairo',
  },
  {
    label: 'Cairo Play',
    value: 'Cairo Play',
  },
  {
    label: 'Caladea',
    value: 'Caladea',
  },
  {
    label: 'Calistoga',
    value: 'Calistoga',
  },
  {
    label: 'Calligraffitti',
    value: 'Calligraffitti',
  },
  {
    label: 'Cambay',
    value: 'Cambay',
  },
  {
    label: 'Cambo',
    value: 'Cambo',
  },
  {
    label: 'Candal',
    value: 'Candal',
  },
  {
    label: 'Cantarell',
    value: 'Cantarell',
  },
  {
    label: 'Cantata One',
    value: 'Cantata One',
  },
  {
    label: 'Cantora One',
    value: 'Cantora One',
  },
  {
    label: 'Caprasimo',
    value: 'Caprasimo',
  },
  {
    label: 'Capriola',
    value: 'Capriola',
  },
  {
    label: 'Caramel',
    value: 'Caramel',
  },
  {
    label: 'Carattere',
    value: 'Carattere',
  },
  {
    label: 'Cardo',
    value: 'Cardo',
  },
  {
    label: 'Carlito',
    value: 'Carlito',
  },
  {
    label: 'Carme',
    value: 'Carme',
  },
  {
    label: 'Carrois Gothic',
    value: 'Carrois Gothic',
  },
  {
    label: 'Carrois Gothic SC',
    value: 'Carrois Gothic SC',
  },
  {
    label: 'Carter One',
    value: 'Carter One',
  },
  {
    label: 'Castoro',
    value: 'Castoro',
  },
  {
    label: 'Castoro Titling',
    value: 'Castoro Titling',
  },
  {
    label: 'Catamaran',
    value: 'Catamaran',
  },
  {
    label: 'Caudex',
    value: 'Caudex',
  },
  {
    label: 'Caveat',
    value: 'Caveat',
  },
  {
    label: 'Caveat Brush',
    value: 'Caveat Brush',
  },
  {
    label: 'Cedarville Cursive',
    value: 'Cedarville Cursive',
  },
  {
    label: 'Ceviche One',
    value: 'Ceviche One',
  },
  {
    label: 'Chakra Petch',
    value: 'Chakra Petch',
  },
  {
    label: 'Changa',
    value: 'Changa',
  },
  {
    label: 'Changa One',
    value: 'Changa One',
  },
  {
    label: 'Chango',
    value: 'Chango',
  },
  {
    label: 'Charis SIL',
    value: 'Charis SIL',
  },
  {
    label: 'Charm',
    value: 'Charm',
  },
  {
    label: 'Charmonman',
    value: 'Charmonman',
  },
  {
    label: 'Chathura',
    value: 'Chathura',
  },
  {
    label: 'Chau Philomene One',
    value: 'Chau Philomene One',
  },
  {
    label: 'Chela One',
    value: 'Chela One',
  },
  {
    label: 'Chelsea Market',
    value: 'Chelsea Market',
  },
  {
    label: 'Chenla',
    value: 'Chenla',
  },
  {
    label: 'Cherish',
    value: 'Cherish',
  },
  {
    label: 'Cherry Bomb One',
    value: 'Cherry Bomb One',
  },
  {
    label: 'Cherry Cream Soda',
    value: 'Cherry Cream Soda',
  },
  {
    label: 'Cherry Swash',
    value: 'Cherry Swash',
  },
  {
    label: 'Chewy',
    value: 'Chewy',
  },
  {
    label: 'Chicle',
    value: 'Chicle',
  },
  {
    label: 'Chilanka',
    value: 'Chilanka',
  },
  {
    label: 'Chivo',
    value: 'Chivo',
  },
  {
    label: 'Chivo Mono',
    value: 'Chivo Mono',
  },
  {
    label: 'Chokokutai',
    value: 'Chokokutai',
  },
  {
    label: 'Chonburi',
    value: 'Chonburi',
  },
  {
    label: 'Cinzel',
    value: 'Cinzel',
  },
  {
    label: 'Cinzel Decorative',
    value: 'Cinzel Decorative',
  },
  {
    label: 'Clicker Script',
    value: 'Clicker Script',
  },
  {
    label: 'Climate Crisis',
    value: 'Climate Crisis',
  },
  {
    label: 'Coda',
    value: 'Coda',
  },
  {
    label: 'Coda Caption',
    value: 'Coda Caption',
  },
  {
    label: 'Codystar',
    value: 'Codystar',
  },
  {
    label: 'Coiny',
    value: 'Coiny',
  },
  {
    label: 'Combo',
    value: 'Combo',
  },
  {
    label: 'Comfortaa',
    value: 'Comfortaa',
  },
  {
    label: 'Comforter',
    value: 'Comforter',
  },
  {
    label: 'Comforter Brush',
    value: 'Comforter Brush',
  },
  {
    label: 'Comic Neue',
    value: 'Comic Neue',
  },
  {
    label: 'Coming Soon',
    value: 'Coming Soon',
  },
  {
    label: 'Comme',
    value: 'Comme',
  },
  {
    label: 'Commissioner',
    value: 'Commissioner',
  },
  {
    label: 'Concert One',
    value: 'Concert One',
  },
  {
    label: 'Condiment',
    value: 'Condiment',
  },
  {
    label: 'Content',
    value: 'Content',
  },
  {
    label: 'Contrail One',
    value: 'Contrail One',
  },
  {
    label: 'Convergence',
    value: 'Convergence',
  },
  {
    label: 'Cookie',
    value: 'Cookie',
  },
  {
    label: 'Copse',
    value: 'Copse',
  },
  {
    label: 'Corben',
    value: 'Corben',
  },
  {
    label: 'Corinthia',
    value: 'Corinthia',
  },
  {
    label: 'Cormorant',
    value: 'Cormorant',
  },
  {
    label: 'Cormorant Garamond',
    value: 'Cormorant Garamond',
  },
  {
    label: 'Cormorant Infant',
    value: 'Cormorant Infant',
  },
  {
    label: 'Cormorant SC',
    value: 'Cormorant SC',
  },
  {
    label: 'Cormorant Unicase',
    value: 'Cormorant Unicase',
  },
  {
    label: 'Cormorant Upright',
    value: 'Cormorant Upright',
  },
  {
    label: 'Courgette',
    value: 'Courgette',
  },
  {
    label: 'Courier Prime',
    value: 'Courier Prime',
  },
  {
    label: 'Cousine',
    value: 'Cousine',
  },
  {
    label: 'Coustard',
    value: 'Coustard',
  },
  {
    label: 'Covered By Your Grace',
    value: 'Covered By Your Grace',
  },
  {
    label: 'Crafty Girls',
    value: 'Crafty Girls',
  },
  {
    label: 'Creepster',
    value: 'Creepster',
  },
  {
    label: 'Crete Round',
    value: 'Crete Round',
  },
  {
    label: 'Crimson Pro',
    value: 'Crimson Pro',
  },
  {
    label: 'Crimson Text',
    value: 'Crimson Text',
  },
  {
    label: 'Croissant One',
    value: 'Croissant One',
  },
  {
    label: 'Crushed',
    value: 'Crushed',
  },
  {
    label: 'Cuprum',
    value: 'Cuprum',
  },
  {
    label: 'Cute Font',
    value: 'Cute Font',
  },
  {
    label: 'Cutive',
    value: 'Cutive',
  },
  {
    label: 'Cutive Mono',
    value: 'Cutive Mono',
  },
  {
    label: 'Dai Banna SIL',
    value: 'Dai Banna SIL',
  },
  {
    label: 'Damion',
    value: 'Damion',
  },
  {
    label: 'Dancing Script',
    value: 'Dancing Script',
  },
  {
    label: 'Dangrek',
    value: 'Dangrek',
  },
  {
    label: 'Darker Grotesque',
    value: 'Darker Grotesque',
  },
  {
    label: 'Darumadrop One',
    value: 'Darumadrop One',
  },
  {
    label: 'David Libre',
    value: 'David Libre',
  },
  {
    label: 'Dawning of a New Day',
    value: 'Dawning of a New Day',
  },
  {
    label: 'Days One',
    value: 'Days One',
  },
  {
    label: 'Dekko',
    value: 'Dekko',
  },
  {
    label: 'Dela Gothic One',
    value: 'Dela Gothic One',
  },
  {
    label: 'Delicious Handrawn',
    value: 'Delicious Handrawn',
  },
  {
    label: 'Delius',
    value: 'Delius',
  },
  {
    label: 'Delius Swash Caps',
    value: 'Delius Swash Caps',
  },
  {
    label: 'Delius Unicase',
    value: 'Delius Unicase',
  },
  {
    label: 'Della Respira',
    value: 'Della Respira',
  },
  {
    label: 'Denk One',
    value: 'Denk One',
  },
  {
    label: 'Devonshire',
    value: 'Devonshire',
  },
  {
    label: 'Dhurjati',
    value: 'Dhurjati',
  },
  {
    label: 'Didact Gothic',
    value: 'Didact Gothic',
  },
  {
    label: 'Diphylleia',
    value: 'Diphylleia',
  },
  {
    label: 'Diplomata',
    value: 'Diplomata',
  },
  {
    label: 'Diplomata SC',
    value: 'Diplomata SC',
  },
  {
    label: 'DM Mono',
    value: 'DM Mono',
  },
  {
    label: 'DM Sans',
    value: 'DM Sans',
  },
  {
    label: 'DM Serif Display',
    value: 'DM Serif Display',
  },
  {
    label: 'DM Serif Text',
    value: 'DM Serif Text',
  },
  {
    label: 'Do Hyeon',
    value: 'Do Hyeon',
  },
  {
    label: 'Dokdo',
    value: 'Dokdo',
  },
  {
    label: 'Domine',
    value: 'Domine',
  },
  {
    label: 'Donegal One',
    value: 'Donegal One',
  },
  {
    label: 'Dongle',
    value: 'Dongle',
  },
  {
    label: 'Doppio One',
    value: 'Doppio One',
  },
  {
    label: 'Dorsa',
    value: 'Dorsa',
  },
  {
    label: 'Dosis',
    value: 'Dosis',
  },
  {
    label: 'DotGothic16',
    value: 'DotGothic16',
  },
  {
    label: 'Dr Sugiyama',
    value: 'Dr Sugiyama',
  },
  {
    label: 'Duru Sans',
    value: 'Duru Sans',
  },
  {
    label: 'Dynalight',
    value: 'Dynalight',
  },
  {
    label: 'DynaPuff',
    value: 'DynaPuff',
  },
  {
    label: 'Eagle Lake',
    value: 'Eagle Lake',
  },
  {
    label: 'East Sea Dokdo',
    value: 'East Sea Dokdo',
  },
  {
    label: 'Eater',
    value: 'Eater',
  },
  {
    label: 'EB Garamond',
    value: 'EB Garamond',
  },
  {
    label: 'Economica',
    value: 'Economica',
  },
  {
    label: 'Eczar',
    value: 'Eczar',
  },
  {
    label: 'Edu NSW ACT Foundation',
    value: 'Edu NSW ACT Foundation',
  },
  {
    label: 'Edu QLD Beginner',
    value: 'Edu QLD Beginner',
  },
  {
    label: 'Edu SA Beginner',
    value: 'Edu SA Beginner',
  },
  {
    label: 'Edu TAS Beginner',
    value: 'Edu TAS Beginner',
  },
  {
    label: 'Edu VIC WA NT Beginner',
    value: 'Edu VIC WA NT Beginner',
  },
  {
    label: 'El Messiri',
    value: 'El Messiri',
  },
  {
    label: 'Electrolize',
    value: 'Electrolize',
  },
  {
    label: 'Elsie',
    value: 'Elsie',
  },
  {
    label: 'Elsie Swash Caps',
    value: 'Elsie Swash Caps',
  },
  {
    label: 'Emblema One',
    value: 'Emblema One',
  },
  {
    label: 'Emilys Candy',
    value: 'Emilys Candy',
  },
  {
    label: 'Encode Sans',
    value: 'Encode Sans',
  },
  {
    label: 'Encode Sans Condensed',
    value: 'Encode Sans Condensed',
  },
  {
    label: 'Encode Sans Expanded',
    value: 'Encode Sans Expanded',
  },
  {
    label: 'Encode Sans SC',
    value: 'Encode Sans SC',
  },
  {
    label: 'Encode Sans Semi Condensed',
    value: 'Encode Sans Semi Condensed',
  },
  {
    label: 'Encode Sans Semi Expanded',
    value: 'Encode Sans Semi Expanded',
  },
  {
    label: 'Engagement',
    value: 'Engagement',
  },
  {
    label: 'Englebert',
    value: 'Englebert',
  },
  {
    label: 'Enriqueta',
    value: 'Enriqueta',
  },
  {
    label: 'Ephesis',
    value: 'Ephesis',
  },
  {
    label: 'Epilogue',
    value: 'Epilogue',
  },
  {
    label: 'Erica One',
    value: 'Erica One',
  },
  {
    label: 'Esteban',
    value: 'Esteban',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
  },
  {
    label: 'Euphoria Script',
    value: 'Euphoria Script',
  },
  {
    label: 'Ewert',
    value: 'Ewert',
  },
  {
    label: 'Exo',
    value: 'Exo',
  },
  {
    label: 'Exo 2',
    value: 'Exo 2',
  },
  {
    label: 'Expletus Sans',
    value: 'Expletus Sans',
  },
  {
    label: 'Explora',
    value: 'Explora',
  },
  {
    label: 'Fahkwang',
    value: 'Fahkwang',
  },
  {
    label: 'Familjen Grotesk',
    value: 'Familjen Grotesk',
  },
  {
    label: 'Fanwood Text',
    value: 'Fanwood Text',
  },
  {
    label: 'Farro',
    value: 'Farro',
  },
  {
    label: 'Farsan',
    value: 'Farsan',
  },
  {
    label: 'Fascinate',
    value: 'Fascinate',
  },
  {
    label: 'Fascinate Inline',
    value: 'Fascinate Inline',
  },
  {
    label: 'Faster One',
    value: 'Faster One',
  },
  {
    label: 'Fasthand',
    value: 'Fasthand',
  },
  {
    label: 'Fauna One',
    value: 'Fauna One',
  },
  {
    label: 'Faustina',
    value: 'Faustina',
  },
  {
    label: 'Federant',
    value: 'Federant',
  },
  {
    label: 'Federo',
    value: 'Federo',
  },
  {
    label: 'Felipa',
    value: 'Felipa',
  },
  {
    label: 'Fenix',
    value: 'Fenix',
  },
  {
    label: 'Festive',
    value: 'Festive',
  },
  {
    label: 'Figtree',
    value: 'Figtree',
  },
  {
    label: 'Finger Paint',
    value: 'Finger Paint',
  },
  {
    label: 'Finlandica',
    value: 'Finlandica',
  },
  {
    label: 'Fira Code',
    value: 'Fira Code',
  },
  {
    label: 'Fira Mono',
    value: 'Fira Mono',
  },
  {
    label: 'Fira Sans',
    value: 'Fira Sans',
  },
  {
    label: 'Fira Sans Condensed',
    value: 'Fira Sans Condensed',
  },
  {
    label: 'Fira Sans Extra Condensed',
    value: 'Fira Sans Extra Condensed',
  },
  {
    label: 'Fjalla One',
    value: 'Fjalla One',
  },
  {
    label: 'Fjord One',
    value: 'Fjord One',
  },
  {
    label: 'Flamenco',
    value: 'Flamenco',
  },
  {
    label: 'Flavors',
    value: 'Flavors',
  },
  {
    label: 'Fleur De Leah',
    value: 'Fleur De Leah',
  },
  {
    label: 'Flow Block',
    value: 'Flow Block',
  },
  {
    label: 'Flow Circular',
    value: 'Flow Circular',
  },
  {
    label: 'Flow Rounded',
    value: 'Flow Rounded',
  },
  {
    label: 'Foldit',
    value: 'Foldit',
  },
  {
    label: 'Fondamento',
    value: 'Fondamento',
  },
  {
    label: 'Fontdiner Swanky',
    value: 'Fontdiner Swanky',
  },
  {
    label: 'Forum',
    value: 'Forum',
  },
  {
    label: 'Fragment Mono',
    value: 'Fragment Mono',
  },
  {
    label: 'Francois One',
    value: 'Francois One',
  },
  {
    label: 'Frank Ruhl Libre',
    value: 'Frank Ruhl Libre',
  },
  {
    label: 'Fraunces',
    value: 'Fraunces',
  },
  {
    label: 'Freckle Face',
    value: 'Freckle Face',
  },
  {
    label: 'Fredericka the Great',
    value: 'Fredericka the Great',
  },
  {
    label: 'Fredoka',
    value: 'Fredoka',
  },
  {
    label: 'Freehand',
    value: 'Freehand',
  },
  {
    label: 'Fresca',
    value: 'Fresca',
  },
  {
    label: 'Frijole',
    value: 'Frijole',
  },
  {
    label: 'Fruktur',
    value: 'Fruktur',
  },
  {
    label: 'Fugaz One',
    value: 'Fugaz One',
  },
  {
    label: 'Fuggles',
    value: 'Fuggles',
  },
  {
    label: 'Fuzzy Bubbles',
    value: 'Fuzzy Bubbles',
  },
  {
    label: 'Gabarito',
    value: 'Gabarito',
  },
  {
    label: 'Gabriela',
    value: 'Gabriela',
  },
  {
    label: 'Gaegu',
    value: 'Gaegu',
  },
  {
    label: 'Gafata',
    value: 'Gafata',
  },
  {
    label: 'Geist',
    value: 'Geist',
  },
  {
    label: 'Gajraj One',
    value: 'Gajraj One',
  },
  {
    label: 'Galada',
    value: 'Galada',
  },
  {
    label: 'Galdeano',
    value: 'Galdeano',
  },
  {
    label: 'Galindo',
    value: 'Galindo',
  },
  {
    label: 'Gamja Flower',
    value: 'Gamja Flower',
  },
  {
    label: 'Gantari',
    value: 'Gantari',
  },
  {
    label: 'Gasoek One',
    value: 'Gasoek One',
  },
  {
    label: 'Gayathri',
    value: 'Gayathri',
  },
  {
    label: 'Gelasio',
    value: 'Gelasio',
  },
  {
    label: 'Gemunu Libre',
    value: 'Gemunu Libre',
  },
  {
    label: 'Genos',
    value: 'Genos',
  },
  {
    label: 'Gentium Book Plus',
    value: 'Gentium Book Plus',
  },
  {
    label: 'Gentium Plus',
    value: 'Gentium Plus',
  },
  {
    label: 'Geo',
    value: 'Geo',
  },
  {
    label: 'Geologica',
    value: 'Geologica',
  },
  {
    label: 'Georama',
    value: 'Georama',
  },
  {
    label: 'Geostar',
    value: 'Geostar',
  },
  {
    label: 'Geostar Fill',
    value: 'Geostar Fill',
  },
  {
    label: 'Germania One',
    value: 'Germania One',
  },
  {
    label: 'GFS Didot',
    value: 'GFS Didot',
  },
  {
    label: 'GFS Neohellenic',
    value: 'GFS Neohellenic',
  },
  {
    label: 'Gideon Roman',
    value: 'Gideon Roman',
  },
  {
    label: 'Gidugu',
    value: 'Gidugu',
  },
  {
    label: 'Gilda Display',
    value: 'Gilda Display',
  },
  {
    label: 'Girassol',
    value: 'Girassol',
  },
  {
    label: 'Give You Glory',
    value: 'Give You Glory',
  },
  {
    label: 'Glass Antiqua',
    value: 'Glass Antiqua',
  },
  {
    label: 'Glegoo',
    value: 'Glegoo',
  },
  {
    label: 'Gloock',
    value: 'Gloock',
  },
  {
    label: 'Gloria Hallelujah',
    value: 'Gloria Hallelujah',
  },
  {
    label: 'Glory',
    value: 'Glory',
  },
  {
    label: 'Gluten',
    value: 'Gluten',
  },
  {
    label: 'Goblin One',
    value: 'Goblin One',
  },
  {
    label: 'Gochi Hand',
    value: 'Gochi Hand',
  },
  {
    label: 'Goldman',
    value: 'Goldman',
  },
  {
    label: 'Golos Text',
    value: 'Golos Text',
  },
  {
    label: 'Gorditas',
    value: 'Gorditas',
  },
  {
    label: 'Gothic A1',
    value: 'Gothic A1',
  },
  {
    label: 'Gotu',
    value: 'Gotu',
  },
  {
    label: 'Goudy Bookletter 1911',
    value: 'Goudy Bookletter 1911',
  },
  {
    label: 'Gowun Batang',
    value: 'Gowun Batang',
  },
  {
    label: 'Gowun Dodum',
    value: 'Gowun Dodum',
  },
  {
    label: 'Graduate',
    value: 'Graduate',
  },
  {
    label: 'Grand Hotel',
    value: 'Grand Hotel',
  },
  {
    label: 'Grandiflora One',
    value: 'Grandiflora One',
  },
  {
    label: 'Grandstander',
    value: 'Grandstander',
  },
  {
    label: 'Grape Nuts',
    value: 'Grape Nuts',
  },
  {
    label: 'Gravitas One',
    value: 'Gravitas One',
  },
  {
    label: 'Great Vibes',
    value: 'Great Vibes',
  },
  {
    label: 'Grechen Fuemen',
    value: 'Grechen Fuemen',
  },
  {
    label: 'Grenze',
    value: 'Grenze',
  },
  {
    label: 'Grenze Gotisch',
    value: 'Grenze Gotisch',
  },
  {
    label: 'Grey Qo',
    value: 'Grey Qo',
  },
  {
    label: 'Griffy',
    value: 'Griffy',
  },
  {
    label: 'Gruppo',
    value: 'Gruppo',
  },
  {
    label: 'Gudea',
    value: 'Gudea',
  },
  {
    label: 'Gugi',
    value: 'Gugi',
  },
  {
    label: 'Gulzar',
    value: 'Gulzar',
  },
  {
    label: 'Gupter',
    value: 'Gupter',
  },
  {
    label: 'Gurajada',
    value: 'Gurajada',
  },
  {
    label: 'Gwendolyn',
    value: 'Gwendolyn',
  },
  {
    label: 'Habibi',
    value: 'Habibi',
  },
  {
    label: 'Hachi Maru Pop',
    value: 'Hachi Maru Pop',
  },
  {
    label: 'Hahmlet',
    value: 'Hahmlet',
  },
  {
    label: 'Halant',
    value: 'Halant',
  },
  {
    label: 'Hammersmith One',
    value: 'Hammersmith One',
  },
  {
    label: 'Hanalei',
    value: 'Hanalei',
  },
  {
    label: 'Hanalei Fill',
    value: 'Hanalei Fill',
  },
  {
    label: 'Handjet',
    value: 'Handjet',
  },
  {
    label: 'Handlee',
    value: 'Handlee',
  },
  {
    label: 'Hanken Grotesk',
    value: 'Hanken Grotesk',
  },
  {
    label: 'Hanuman',
    value: 'Hanuman',
  },
  {
    label: 'Happy Monkey',
    value: 'Happy Monkey',
  },
  {
    label: 'Harmattan',
    value: 'Harmattan',
  },
  {
    label: 'Headland One',
    value: 'Headland One',
  },
  {
    label: 'Heebo',
    value: 'Heebo',
  },
  {
    label: 'Henny Penny',
    value: 'Henny Penny',
  },
  {
    label: 'Hepta Slab',
    value: 'Hepta Slab',
  },
  {
    label: 'Herr Von Muellerhoff',
    value: 'Herr Von Muellerhoff',
  },
  {
    label: 'Hi Melody',
    value: 'Hi Melody',
  },
  {
    label: 'Hina Mincho',
    value: 'Hina Mincho',
  },
  {
    label: 'Hind',
    value: 'Hind',
  },
  {
    label: 'Hind Guntur',
    value: 'Hind Guntur',
  },
  {
    label: 'Hind Madurai',
    value: 'Hind Madurai',
  },
  {
    label: 'Hind Siliguri',
    value: 'Hind Siliguri',
  },
  {
    label: 'Hind Vadodara',
    value: 'Hind Vadodara',
  },
  {
    label: 'Holtwood One SC',
    value: 'Holtwood One SC',
  },
  {
    label: 'Homemade Apple',
    value: 'Homemade Apple',
  },
  {
    label: 'Homenaje',
    value: 'Homenaje',
  },
  {
    label: 'Hubballi',
    value: 'Hubballi',
  },
  {
    label: 'Hurricane',
    value: 'Hurricane',
  },
  {
    label: 'Ibarra Real Nova',
    value: 'Ibarra Real Nova',
  },
  {
    label: 'IBM Plex Mono',
    value: 'IBM Plex Mono',
  },
  {
    label: 'IBM Plex Sans',
    value: 'IBM Plex Sans',
  },
  {
    label: 'IBM Plex Sans Arabic',
    value: 'IBM Plex Sans Arabic',
  },
  {
    label: 'IBM Plex Sans Condensed',
    value: 'IBM Plex Sans Condensed',
  },
  {
    label: 'IBM Plex Sans Devanagari',
    value: 'IBM Plex Sans Devanagari',
  },
  {
    label: 'IBM Plex Sans Hebrew',
    value: 'IBM Plex Sans Hebrew',
  },
  {
    label: 'IBM Plex Sans JP',
    value: 'IBM Plex Sans JP',
  },
  {
    label: 'IBM Plex Sans KR',
    value: 'IBM Plex Sans KR',
  },
  {
    label: 'IBM Plex Sans Thai',
    value: 'IBM Plex Sans Thai',
  },
  {
    label: 'IBM Plex Sans Thai Looped',
    value: 'IBM Plex Sans Thai Looped',
  },
  {
    label: 'IBM Plex Serif',
    value: 'IBM Plex Serif',
  },
  {
    label: 'Iceberg',
    value: 'Iceberg',
  },
  {
    label: 'Iceland',
    value: 'Iceland',
  },
  {
    label: 'IM Fell Double Pica',
    value: 'IM Fell Double Pica',
  },
  {
    label: 'IM Fell Double Pica SC',
    value: 'IM Fell Double Pica SC',
  },
  {
    label: 'IM Fell DW Pica',
    value: 'IM Fell DW Pica',
  },
  {
    label: 'IM Fell DW Pica SC',
    value: 'IM Fell DW Pica SC',
  },
  {
    label: 'IM Fell English',
    value: 'IM Fell English',
  },
  {
    label: 'IM Fell English SC',
    value: 'IM Fell English SC',
  },
  {
    label: 'IM Fell French Canon',
    value: 'IM Fell French Canon',
  },
  {
    label: 'IM Fell French Canon SC',
    value: 'IM Fell French Canon SC',
  },
  {
    label: 'IM Fell Great Primer',
    value: 'IM Fell Great Primer',
  },
  {
    label: 'IM Fell Great Primer SC',
    value: 'IM Fell Great Primer SC',
  },
  {
    label: 'Imbue',
    value: 'Imbue',
  },
  {
    label: 'Imperial Script',
    value: 'Imperial Script',
  },
  {
    label: 'Imprima',
    value: 'Imprima',
  },
  {
    label: 'Inclusive Sans',
    value: 'Inclusive Sans',
  },
  {
    label: 'Inconsolata',
    value: 'Inconsolata',
  },
  {
    label: 'Inder',
    value: 'Inder',
  },
  {
    label: 'Indie Flower',
    value: 'Indie Flower',
  },
  {
    label: 'Ingrid Darling',
    value: 'Ingrid Darling',
  },
  {
    label: 'Inika',
    value: 'Inika',
  },
  {
    label: 'Inknut Antiqua',
    value: 'Inknut Antiqua',
  },
  {
    label: 'Inria Sans',
    value: 'Inria Sans',
  },
  {
    label: 'Inria Serif',
    value: 'Inria Serif',
  },
  {
    label: 'Inspiration',
    value: 'Inspiration',
  },
  {
    label: 'Instrument Sans',
    value: 'Instrument Sans',
  },
  {
    label: 'Instrument Serif',
    value: 'Instrument Serif',
  },
  {
    label: 'Inter',
    value: 'Inter',
  },
  {
    label: 'Inter Tight',
    value: 'Inter Tight',
  },
  {
    label: 'Irish Grover',
    value: 'Irish Grover',
  },
  {
    label: 'Island Moments',
    value: 'Island Moments',
  },
  {
    label: 'Istok Web',
    value: 'Istok Web',
  },
  {
    label: 'Italiana',
    value: 'Italiana',
  },
  {
    label: 'Italianno',
    value: 'Italianno',
  },
  {
    label: 'Itim',
    value: 'Itim',
  },
  {
    label: 'Jacques Francois',
    value: 'Jacques Francois',
  },
  {
    label: 'Jacques Francois Shadow',
    value: 'Jacques Francois Shadow',
  },
  {
    label: 'Jaldi',
    value: 'Jaldi',
  },
  {
    label: 'JetBrains Mono',
    value: 'JetBrains Mono',
  },
  {
    label: 'Jim Nightshade',
    value: 'Jim Nightshade',
  },
  {
    label: 'Joan',
    value: 'Joan',
  },
  {
    label: 'Jockey One',
    value: 'Jockey One',
  },
  {
    label: 'Jolly Lodger',
    value: 'Jolly Lodger',
  },
  {
    label: 'Jomhuria',
    value: 'Jomhuria',
  },
  {
    label: 'Jomolhari',
    value: 'Jomolhari',
  },
  {
    label: 'Josefin Sans',
    value: 'Josefin Sans',
  },
  {
    label: 'Josefin Slab',
    value: 'Josefin Slab',
  },
  {
    label: 'Jost',
    value: 'Jost',
  },
  {
    label: 'Joti One',
    value: 'Joti One',
  },
  {
    label: 'Jua',
    value: 'Jua',
  },
  {
    label: 'Judson',
    value: 'Judson',
  },
  {
    label: 'Julee',
    value: 'Julee',
  },
  {
    label: 'Julius Sans One',
    value: 'Julius Sans One',
  },
  {
    label: 'Junge',
    value: 'Junge',
  },
  {
    label: 'Jura',
    value: 'Jura',
  },
  {
    label: 'Just Another Hand',
    value: 'Just Another Hand',
  },
  {
    label: 'Just Me Again Down Here',
    value: 'Just Me Again Down Here',
  },
  {
    label: 'K2D',
    value: 'K2D',
  },
  {
    label: 'Kablammo',
    value: 'Kablammo',
  },
  {
    label: 'Kadwa',
    value: 'Kadwa',
  },
  {
    label: 'Kaisei Decol',
    value: 'Kaisei Decol',
  },
  {
    label: 'Kaisei HarunoUmi',
    value: 'Kaisei HarunoUmi',
  },
  {
    label: 'Kaisei Opti',
    value: 'Kaisei Opti',
  },
  {
    label: 'Kaisei Tokumin',
    value: 'Kaisei Tokumin',
  },
  {
    label: 'Kalam',
    value: 'Kalam',
  },
  {
    label: 'Kameron',
    value: 'Kameron',
  },
  {
    label: 'Kanit',
    value: 'Kanit',
  },
  {
    label: 'Kantumruy Pro',
    value: 'Kantumruy Pro',
  },
  {
    label: 'Karantina',
    value: 'Karantina',
  },
  {
    label: 'Karla',
    value: 'Karla',
  },
  {
    label: 'Karma',
    value: 'Karma',
  },
  {
    label: 'Katibeh',
    value: 'Katibeh',
  },
  {
    label: 'Kaushan Script',
    value: 'Kaushan Script',
  },
  {
    label: 'Kavivanar',
    value: 'Kavivanar',
  },
  {
    label: 'Kavoon',
    value: 'Kavoon',
  },
  {
    label: 'Kdam Thmor Pro',
    value: 'Kdam Thmor Pro',
  },
  {
    label: 'Keania One',
    value: 'Keania One',
  },
  {
    label: 'Kelly Slab',
    value: 'Kelly Slab',
  },
  {
    label: 'Kenia',
    value: 'Kenia',
  },
  {
    label: 'Khand',
    value: 'Khand',
  },
  {
    label: 'Khmer',
    value: 'Khmer',
  },
  {
    label: 'Khula',
    value: 'Khula',
  },
  {
    label: 'Kings',
    value: 'Kings',
  },
  {
    label: 'Kirang Haerang',
    value: 'Kirang Haerang',
  },
  {
    label: 'Kite One',
    value: 'Kite One',
  },
  {
    label: 'Kiwi Maru',
    value: 'Kiwi Maru',
  },
  {
    label: 'Klee One',
    value: 'Klee One',
  },
  {
    label: 'Knewave',
    value: 'Knewave',
  },
  {
    label: 'Kodchasan',
    value: 'Kodchasan',
  },
  {
    label: 'Koh Santepheap',
    value: 'Koh Santepheap',
  },
  {
    label: 'KoHo',
    value: 'KoHo',
  },
  {
    label: 'Kolker Brush',
    value: 'Kolker Brush',
  },
  {
    label: 'Konkhmer Sleokchher',
    value: 'Konkhmer Sleokchher',
  },
  {
    label: 'Kosugi',
    value: 'Kosugi',
  },
  {
    label: 'Kosugi Maru',
    value: 'Kosugi Maru',
  },
  {
    label: 'Kotta One',
    value: 'Kotta One',
  },
  {
    label: 'Koulen',
    value: 'Koulen',
  },
  {
    label: 'Kranky',
    value: 'Kranky',
  },
  {
    label: 'Kreon',
    value: 'Kreon',
  },
  {
    label: 'Kristi',
    value: 'Kristi',
  },
  {
    label: 'Krona One',
    value: 'Krona One',
  },
  {
    label: 'Krub',
    value: 'Krub',
  },
  {
    label: 'Kufam',
    value: 'Kufam',
  },
  {
    label: 'Kulim Park',
    value: 'Kulim Park',
  },
  {
    label: 'Kumar One',
    value: 'Kumar One',
  },
  {
    label: 'Kumar One Outline',
    value: 'Kumar One Outline',
  },
  {
    label: 'Kumbh Sans',
    value: 'Kumbh Sans',
  },
  {
    label: 'Kurale',
    value: 'Kurale',
  },
  {
    label: 'La Belle Aurore',
    value: 'La Belle Aurore',
  },
  {
    label: 'Labrada',
    value: 'Labrada',
  },
  {
    label: 'Lacquer',
    value: 'Lacquer',
  },
  {
    label: 'Laila',
    value: 'Laila',
  },
  {
    label: 'Lakki Reddy',
    value: 'Lakki Reddy',
  },
  {
    label: 'Lalezar',
    value: 'Lalezar',
  },
  {
    label: 'Lancelot',
    value: 'Lancelot',
  },
  {
    label: 'Langar',
    value: 'Langar',
  },
  {
    label: 'Lateef',
    value: 'Lateef',
  },
  {
    label: 'Lato',
    value: 'Lato',
  },
  {
    label: 'Lavishly Yours',
    value: 'Lavishly Yours',
  },
  {
    label: 'League Gothic',
    value: 'League Gothic',
  },
  {
    label: 'League Script',
    value: 'League Script',
  },
  {
    label: 'League Spartan',
    value: 'League Spartan',
  },
  {
    label: 'Leckerli One',
    value: 'Leckerli One',
  },
  {
    label: 'Ledger',
    value: 'Ledger',
  },
  {
    label: 'Lekton',
    value: 'Lekton',
  },
  {
    label: 'Lemon',
    value: 'Lemon',
  },
  {
    label: 'Lemonada',
    value: 'Lemonada',
  },
  {
    label: 'Lexend',
    value: 'Lexend',
  },
  {
    label: 'Lexend Deca',
    value: 'Lexend Deca',
  },
  {
    label: 'Lexend Exa',
    value: 'Lexend Exa',
  },
  {
    label: 'Lexend Giga',
    value: 'Lexend Giga',
  },
  {
    label: 'Lexend Mega',
    value: 'Lexend Mega',
  },
  {
    label: 'Lexend Peta',
    value: 'Lexend Peta',
  },
  {
    label: 'Lexend Tera',
    value: 'Lexend Tera',
  },
  {
    label: 'Lexend Zetta',
    value: 'Lexend Zetta',
  },
  {
    label: 'Libre Barcode 128',
    value: 'Libre Barcode 128',
  },
  {
    label: 'Libre Barcode 128 Text',
    value: 'Libre Barcode 128 Text',
  },
  {
    label: 'Libre Barcode 39',
    value: 'Libre Barcode 39',
  },
  {
    label: 'Libre Barcode 39 Extended',
    value: 'Libre Barcode 39 Extended',
  },
  {
    label: 'Libre Barcode 39 Extended Text',
    value: 'Libre Barcode 39 Extended Text',
  },
  {
    label: 'Libre Barcode 39 Text',
    value: 'Libre Barcode 39 Text',
  },
  {
    label: 'Libre Barcode EAN13 Text',
    value: 'Libre Barcode EAN13 Text',
  },
  {
    label: 'Libre Baskerville',
    value: 'Libre Baskerville',
  },
  {
    label: 'Libre Bodoni',
    value: 'Libre Bodoni',
  },
  {
    label: 'Libre Caslon Display',
    value: 'Libre Caslon Display',
  },
  {
    label: 'Libre Caslon Text',
    value: 'Libre Caslon Text',
  },
  {
    label: 'Libre Franklin',
    value: 'Libre Franklin',
  },
  {
    label: 'Licorice',
    value: 'Licorice',
  },
  {
    label: 'Life Savers',
    value: 'Life Savers',
  },
  {
    label: 'Lilita One',
    value: 'Lilita One',
  },
  {
    label: 'Lily Script One',
    value: 'Lily Script One',
  },
  {
    label: 'Limelight',
    value: 'Limelight',
  },
  {
    label: 'Linden Hill',
    value: 'Linden Hill',
  },
  {
    label: 'Lisu Bosa',
    value: 'Lisu Bosa',
  },
  {
    label: 'Literata',
    value: 'Literata',
  },
  {
    label: 'Liu Jian Mao Cao',
    value: 'Liu Jian Mao Cao',
  },
  {
    label: 'Livvic',
    value: 'Livvic',
  },
  {
    label: 'Lobster',
    value: 'Lobster',
  },
  {
    label: 'Lobster Two',
    value: 'Lobster Two',
  },
  {
    label: 'Londrina Outline',
    value: 'Londrina Outline',
  },
  {
    label: 'Londrina Shadow',
    value: 'Londrina Shadow',
  },
  {
    label: 'Londrina Sketch',
    value: 'Londrina Sketch',
  },
  {
    label: 'Londrina Solid',
    value: 'Londrina Solid',
  },
  {
    label: 'Long Cang',
    value: 'Long Cang',
  },
  {
    label: 'Lora',
    value: 'Lora',
  },
  {
    label: 'Love Light',
    value: 'Love Light',
  },
  {
    label: 'Love Ya Like A Sister',
    value: 'Love Ya Like A Sister',
  },
  {
    label: 'Loved by the King',
    value: 'Loved by the King',
  },
  {
    label: 'Lovers Quarrel',
    value: 'Lovers Quarrel',
  },
  {
    label: 'Luckiest Guy',
    value: 'Luckiest Guy',
  },
  {
    label: 'Lugrasimo',
    value: 'Lugrasimo',
  },
  {
    label: 'Lumanosimo',
    value: 'Lumanosimo',
  },
  {
    label: 'Lunasima',
    value: 'Lunasima',
  },
  {
    label: 'Lusitana',
    value: 'Lusitana',
  },
  {
    label: 'Lustria',
    value: 'Lustria',
  },
  {
    label: 'Luxurious Roman',
    value: 'Luxurious Roman',
  },
  {
    label: 'Luxurious Script',
    value: 'Luxurious Script',
  },
  {
    label: 'M PLUS 1',
    value: 'M PLUS 1',
  },
  {
    label: 'M PLUS 1 Code',
    value: 'M PLUS 1 Code',
  },
  {
    label: 'M PLUS 1p',
    value: 'M PLUS 1p',
  },
  {
    label: 'M PLUS 2',
    value: 'M PLUS 2',
  },
  {
    label: 'M PLUS Code Latin',
    value: 'M PLUS Code Latin',
  },
  {
    label: 'M PLUS Rounded 1c',
    value: 'M PLUS Rounded 1c',
  },
  {
    label: 'Ma Shan Zheng',
    value: 'Ma Shan Zheng',
  },
  {
    label: 'Macondo',
    value: 'Macondo',
  },
  {
    label: 'Macondo Swash Caps',
    value: 'Macondo Swash Caps',
  },
  {
    label: 'Mada',
    value: 'Mada',
  },
  {
    label: 'Magra',
    value: 'Magra',
  },
  {
    label: 'Maiden Orange',
    value: 'Maiden Orange',
  },
  {
    label: 'Maitree',
    value: 'Maitree',
  },
  {
    label: 'Major Mono Display',
    value: 'Major Mono Display',
  },
  {
    label: 'Mako',
    value: 'Mako',
  },
  {
    label: 'Mali',
    value: 'Mali',
  },
  {
    label: 'Mallanna',
    value: 'Mallanna',
  },
  {
    label: 'Mandali',
    value: 'Mandali',
  },
  {
    label: 'Manjari',
    value: 'Manjari',
  },
  {
    label: 'Manrope',
    value: 'Manrope',
  },
  {
    label: 'Mansalva',
    value: 'Mansalva',
  },
  {
    label: 'Manuale',
    value: 'Manuale',
  },
  {
    label: 'Marcellus',
    value: 'Marcellus',
  },
  {
    label: 'Marcellus SC',
    value: 'Marcellus SC',
  },
  {
    label: 'Marck Script',
    value: 'Marck Script',
  },
  {
    label: 'Margarine',
    value: 'Margarine',
  },
  {
    label: 'Marhey',
    value: 'Marhey',
  },
  {
    label: 'Markazi Text',
    value: 'Markazi Text',
  },
  {
    label: 'Marko One',
    value: 'Marko One',
  },
  {
    label: 'Marmelad',
    value: 'Marmelad',
  },
  {
    label: 'Martel',
    value: 'Martel',
  },
  {
    label: 'Martel Sans',
    value: 'Martel Sans',
  },
  {
    label: 'Martian Mono',
    value: 'Martian Mono',
  },
  {
    label: 'Marvel',
    value: 'Marvel',
  },
  {
    label: 'Mate',
    value: 'Mate',
  },
  {
    label: 'Mate SC',
    value: 'Mate SC',
  },
  {
    label: 'Material Icons',
    value: 'Material Icons',
  },
  {
    label: 'Material Icons Outlined',
    value: 'Material Icons Outlined',
  },
  {
    label: 'Material Icons Round',
    value: 'Material Icons Round',
  },
  {
    label: 'Material Icons Sharp',
    value: 'Material Icons Sharp',
  },
  {
    label: 'Material Icons Two Tone',
    value: 'Material Icons Two Tone',
  },
  {
    label: 'Material Symbols Outlined',
    value: 'Material Symbols Outlined',
  },
  {
    label: 'Material Symbols Rounded',
    value: 'Material Symbols Rounded',
  },
  {
    label: 'Material Symbols Sharp',
    value: 'Material Symbols Sharp',
  },
  {
    label: 'Maven Pro',
    value: 'Maven Pro',
  },
  {
    label: 'McLaren',
    value: 'McLaren',
  },
  {
    label: 'Mea Culpa',
    value: 'Mea Culpa',
  },
  {
    label: 'Meddon',
    value: 'Meddon',
  },
  {
    label: 'MedievalSharp',
    value: 'MedievalSharp',
  },
  {
    label: 'Medula One',
    value: 'Medula One',
  },
  {
    label: 'Meera Inimai',
    value: 'Meera Inimai',
  },
  {
    label: 'Megrim',
    value: 'Megrim',
  },
  {
    label: 'Meie Script',
    value: 'Meie Script',
  },
  {
    label: 'Meow Script',
    value: 'Meow Script',
  },
  {
    label: 'Merienda',
    value: 'Merienda',
  },
  {
    label: 'Merriweather',
    value: 'Merriweather',
  },
  {
    label: 'Merriweather Sans',
    value: 'Merriweather Sans',
  },
  {
    label: 'Metal',
    value: 'Metal',
  },
  {
    label: 'Metal Mania',
    value: 'Metal Mania',
  },
  {
    label: 'Metamorphous',
    value: 'Metamorphous',
  },
  {
    label: 'Metrophobic',
    value: 'Metrophobic',
  },
  {
    label: 'Michroma',
    value: 'Michroma',
  },
  {
    label: 'Milonga',
    value: 'Milonga',
  },
  {
    label: 'Miltonian',
    value: 'Miltonian',
  },
  {
    label: 'Miltonian Tattoo',
    value: 'Miltonian Tattoo',
  },
  {
    label: 'Mina',
    value: 'Mina',
  },
  {
    label: 'Mingzat',
    value: 'Mingzat',
  },
  {
    label: 'Miniver',
    value: 'Miniver',
  },
  {
    label: 'Miriam Libre',
    value: 'Miriam Libre',
  },
  {
    label: 'Mirza',
    value: 'Mirza',
  },
  {
    label: 'Miss Fajardose',
    value: 'Miss Fajardose',
  },
  {
    label: 'Mitr',
    value: 'Mitr',
  },
  {
    label: 'Mochiy Pop One',
    value: 'Mochiy Pop One',
  },
  {
    label: 'Mochiy Pop P One',
    value: 'Mochiy Pop P One',
  },
  {
    label: 'Modak',
    value: 'Modak',
  },
  {
    label: 'Modern Antiqua',
    value: 'Modern Antiqua',
  },
  {
    label: 'Mogra',
    value: 'Mogra',
  },
  {
    label: 'Mohave',
    value: 'Mohave',
  },
  {
    label: 'Moirai One',
    value: 'Moirai One',
  },
  {
    label: 'Molengo',
    value: 'Molengo',
  },
  {
    label: 'Molle',
    value: 'Molle',
  },
  {
    label: 'Monda',
    value: 'Monda',
  },
  {
    label: 'Monofett',
    value: 'Monofett',
  },
  {
    label: 'Monomaniac One',
    value: 'Monomaniac One',
  },
  {
    label: 'Monoton',
    value: 'Monoton',
  },
  {
    label: 'Monsieur La Doulaise',
    value: 'Monsieur La Doulaise',
  },
  {
    label: 'Montaga',
    value: 'Montaga',
  },
  {
    label: 'Montagu Slab',
    value: 'Montagu Slab',
  },
  {
    label: 'MonteCarlo',
    value: 'MonteCarlo',
  },
  {
    label: 'Montez',
    value: 'Montez',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'Montserrat Alternates',
    value: 'Montserrat Alternates',
  },
  {
    label: 'Montserrat Subrayada',
    value: 'Montserrat Subrayada',
  },
  {
    label: 'Moo Lah Lah',
    value: 'Moo Lah Lah',
  },
  {
    label: 'Mooli',
    value: 'Mooli',
  },
  {
    label: 'Moon Dance',
    value: 'Moon Dance',
  },
  {
    label: 'Moul',
    value: 'Moul',
  },
  {
    label: 'Moulpali',
    value: 'Moulpali',
  },
  {
    label: 'Mountains of Christmas',
    value: 'Mountains of Christmas',
  },
  {
    label: 'Mouse Memoirs',
    value: 'Mouse Memoirs',
  },
  {
    label: 'Mr Bedfort',
    value: 'Mr Bedfort',
  },
  {
    label: 'Mr Dafoe',
    value: 'Mr Dafoe',
  },
  {
    label: 'Mr De Haviland',
    value: 'Mr De Haviland',
  },
  {
    label: 'Mrs Saint Delafield',
    value: 'Mrs Saint Delafield',
  },
  {
    label: 'Mrs Sheppards',
    value: 'Mrs Sheppards',
  },
  {
    label: 'Ms Madi',
    value: 'Ms Madi',
  },
  {
    label: 'Mukta',
    value: 'Mukta',
  },
  {
    label: 'Mukta Mahee',
    value: 'Mukta Mahee',
  },
  {
    label: 'Mukta Malar',
    value: 'Mukta Malar',
  },
  {
    label: 'Mukta Vaani',
    value: 'Mukta Vaani',
  },
  {
    label: 'Mulish',
    value: 'Mulish',
  },
  {
    label: 'Murecho',
    value: 'Murecho',
  },
  {
    label: 'MuseoModerno',
    value: 'MuseoModerno',
  },
  {
    label: 'My Soul',
    value: 'My Soul',
  },
  {
    label: 'Mynerve',
    value: 'Mynerve',
  },
  {
    label: 'Mystery Quest',
    value: 'Mystery Quest',
  },
  {
    label: 'Nabla',
    value: 'Nabla',
  },
  {
    label: 'Nanum Brush Script',
    value: 'Nanum Brush Script',
  },
  {
    label: 'Nanum Gothic',
    value: 'Nanum Gothic',
  },
  {
    label: 'Nanum Gothic Coding',
    value: 'Nanum Gothic Coding',
  },
  {
    label: 'Nanum Myeongjo',
    value: 'Nanum Myeongjo',
  },
  {
    label: 'Nanum Pen Script',
    value: 'Nanum Pen Script',
  },
  {
    label: 'Narnoor',
    value: 'Narnoor',
  },
  {
    label: 'Neonderthaw',
    value: 'Neonderthaw',
  },
  {
    label: 'Nerko One',
    value: 'Nerko One',
  },
  {
    label: 'Neucha',
    value: 'Neucha',
  },
  {
    label: 'Neuton',
    value: 'Neuton',
  },
  {
    label: 'New Rocker',
    value: 'New Rocker',
  },
  {
    label: 'New Tegomin',
    value: 'New Tegomin',
  },
  {
    label: 'News Cycle',
    value: 'News Cycle',
  },
  {
    label: 'Newsreader',
    value: 'Newsreader',
  },
  {
    label: 'Niconne',
    value: 'Niconne',
  },
  {
    label: 'Niramit',
    value: 'Niramit',
  },
  {
    label: 'Nixie One',
    value: 'Nixie One',
  },
  {
    label: 'Nobile',
    value: 'Nobile',
  },
  {
    label: 'Nokora',
    value: 'Nokora',
  },
  {
    label: 'Norican',
    value: 'Norican',
  },
  {
    label: 'Nosifer',
    value: 'Nosifer',
  },
  {
    label: 'Notable',
    value: 'Notable',
  },
  {
    label: 'Nothing You Could Do',
    value: 'Nothing You Could Do',
  },
  {
    label: 'Noticia Text',
    value: 'Noticia Text',
  },
  {
    label: 'Noto Color Emoji',
    value: 'Noto Color Emoji',
  },
  {
    label: 'Noto Emoji',
    value: 'Noto Emoji',
  },
  {
    label: 'Noto Kufi Arabic',
    value: 'Noto Kufi Arabic',
  },
  {
    label: 'Noto Music',
    value: 'Noto Music',
  },
  {
    label: 'Noto Naskh Arabic',
    value: 'Noto Naskh Arabic',
  },
  {
    label: 'Noto Nastaliq Urdu',
    value: 'Noto Nastaliq Urdu',
  },
  {
    label: 'Noto Rashi Hebrew',
    value: 'Noto Rashi Hebrew',
  },
  {
    label: 'Noto Sans',
    value: 'Noto Sans',
  },
  {
    label: 'Noto Sans Adlam',
    value: 'Noto Sans Adlam',
  },
  {
    label: 'Noto Sans Adlam Unjoined',
    value: 'Noto Sans Adlam Unjoined',
  },
  {
    label: 'Noto Sans Anatolian Hieroglyphs',
    value: 'Noto Sans Anatolian Hieroglyphs',
  },
  {
    label: 'Noto Sans Arabic',
    value: 'Noto Sans Arabic',
  },
  {
    label: 'Noto Sans Armenian',
    value: 'Noto Sans Armenian',
  },
  {
    label: 'Noto Sans Avestan',
    value: 'Noto Sans Avestan',
  },
  {
    label: 'Noto Sans Balinese',
    value: 'Noto Sans Balinese',
  },
  {
    label: 'Noto Sans Bamum',
    value: 'Noto Sans Bamum',
  },
  {
    label: 'Noto Sans Bassa Vah',
    value: 'Noto Sans Bassa Vah',
  },
  {
    label: 'Noto Sans Batak',
    value: 'Noto Sans Batak',
  },
  {
    label: 'Noto Sans Bengali',
    value: 'Noto Sans Bengali',
  },
  {
    label: 'Noto Sans Bhaiksuki',
    value: 'Noto Sans Bhaiksuki',
  },
  {
    label: 'Noto Sans Brahmi',
    value: 'Noto Sans Brahmi',
  },
  {
    label: 'Noto Sans Buginese',
    value: 'Noto Sans Buginese',
  },
  {
    label: 'Noto Sans Buhid',
    value: 'Noto Sans Buhid',
  },
  {
    label: 'Noto Sans Canadian Aboriginal',
    value: 'Noto Sans Canadian Aboriginal',
  },
  {
    label: 'Noto Sans Carian',
    value: 'Noto Sans Carian',
  },
  {
    label: 'Noto Sans Caucasian Albanian',
    value: 'Noto Sans Caucasian Albanian',
  },
  {
    label: 'Noto Sans Chakma',
    value: 'Noto Sans Chakma',
  },
  {
    label: 'Noto Sans Cham',
    value: 'Noto Sans Cham',
  },
  {
    label: 'Noto Sans Cherokee',
    value: 'Noto Sans Cherokee',
  },
  {
    label: 'Noto Sans Chorasmian',
    value: 'Noto Sans Chorasmian',
  },
  {
    label: 'Noto Sans Coptic',
    value: 'Noto Sans Coptic',
  },
  {
    label: 'Noto Sans Cuneiform',
    value: 'Noto Sans Cuneiform',
  },
  {
    label: 'Noto Sans Cypriot',
    value: 'Noto Sans Cypriot',
  },
  {
    label: 'Noto Sans Cypro Minoan',
    value: 'Noto Sans Cypro Minoan',
  },
  {
    label: 'Noto Sans Deseret',
    value: 'Noto Sans Deseret',
  },
  {
    label: 'Noto Sans Devanagari',
    value: 'Noto Sans Devanagari',
  },
  {
    label: 'Noto Sans Display',
    value: 'Noto Sans Display',
  },
  {
    label: 'Noto Sans Duployan',
    value: 'Noto Sans Duployan',
  },
  {
    label: 'Noto Sans Egyptian Hieroglyphs',
    value: 'Noto Sans Egyptian Hieroglyphs',
  },
  {
    label: 'Noto Sans Elbasan',
    value: 'Noto Sans Elbasan',
  },
  {
    label: 'Noto Sans Elymaic',
    value: 'Noto Sans Elymaic',
  },
  {
    label: 'Noto Sans Ethiopic',
    value: 'Noto Sans Ethiopic',
  },
  {
    label: 'Noto Sans Georgian',
    value: 'Noto Sans Georgian',
  },
  {
    label: 'Noto Sans Glagolitic',
    value: 'Noto Sans Glagolitic',
  },
  {
    label: 'Noto Sans Gothic',
    value: 'Noto Sans Gothic',
  },
  {
    label: 'Noto Sans Grantha',
    value: 'Noto Sans Grantha',
  },
  {
    label: 'Noto Sans Gujarati',
    value: 'Noto Sans Gujarati',
  },
  {
    label: 'Noto Sans Gunjala Gondi',
    value: 'Noto Sans Gunjala Gondi',
  },
  {
    label: 'Noto Sans Gurmukhi',
    value: 'Noto Sans Gurmukhi',
  },
  {
    label: 'Noto Sans Hanifi Rohingya',
    value: 'Noto Sans Hanifi Rohingya',
  },
  {
    label: 'Noto Sans Hanunoo',
    value: 'Noto Sans Hanunoo',
  },
  {
    label: 'Noto Sans Hatran',
    value: 'Noto Sans Hatran',
  },
  {
    label: 'Noto Sans Hebrew',
    value: 'Noto Sans Hebrew',
  },
  {
    label: 'Noto Sans HK',
    value: 'Noto Sans HK',
  },
  {
    label: 'Noto Sans Imperial Aramaic',
    value: 'Noto Sans Imperial Aramaic',
  },
  {
    label: 'Noto Sans Indic Siyaq Numbers',
    value: 'Noto Sans Indic Siyaq Numbers',
  },
  {
    label: 'Noto Sans Inscriptional Pahlavi',
    value: 'Noto Sans Inscriptional Pahlavi',
  },
  {
    label: 'Noto Sans Inscriptional Parthian',
    value: 'Noto Sans Inscriptional Parthian',
  },
  {
    label: 'Noto Sans Javanese',
    value: 'Noto Sans Javanese',
  },
  {
    label: 'Noto Sans JP',
    value: 'Noto Sans JP',
  },
  {
    label: 'Noto Sans Kaithi',
    value: 'Noto Sans Kaithi',
  },
  {
    label: 'Noto Sans Kannada',
    value: 'Noto Sans Kannada',
  },
  {
    label: 'Noto Sans Kayah Li',
    value: 'Noto Sans Kayah Li',
  },
  {
    label: 'Noto Sans Kharoshthi',
    value: 'Noto Sans Kharoshthi',
  },
  {
    label: 'Noto Sans Khmer',
    value: 'Noto Sans Khmer',
  },
  {
    label: 'Noto Sans Khojki',
    value: 'Noto Sans Khojki',
  },
  {
    label: 'Noto Sans Khudawadi',
    value: 'Noto Sans Khudawadi',
  },
  {
    label: 'Noto Sans KR',
    value: 'Noto Sans KR',
  },
  {
    label: 'Noto Sans Lao',
    value: 'Noto Sans Lao',
  },
  {
    label: 'Noto Sans Lao Looped',
    value: 'Noto Sans Lao Looped',
  },
  {
    label: 'Noto Sans Lepcha',
    value: 'Noto Sans Lepcha',
  },
  {
    label: 'Noto Sans Limbu',
    value: 'Noto Sans Limbu',
  },
  {
    label: 'Noto Sans Linear A',
    value: 'Noto Sans Linear A',
  },
  {
    label: 'Noto Sans Linear B',
    value: 'Noto Sans Linear B',
  },
  {
    label: 'Noto Sans Lisu',
    value: 'Noto Sans Lisu',
  },
  {
    label: 'Noto Sans Lycian',
    value: 'Noto Sans Lycian',
  },
  {
    label: 'Noto Sans Lydian',
    value: 'Noto Sans Lydian',
  },
  {
    label: 'Noto Sans Mahajani',
    value: 'Noto Sans Mahajani',
  },
  {
    label: 'Noto Sans Malayalam',
    value: 'Noto Sans Malayalam',
  },
  {
    label: 'Noto Sans Mandaic',
    value: 'Noto Sans Mandaic',
  },
  {
    label: 'Noto Sans Manichaean',
    value: 'Noto Sans Manichaean',
  },
  {
    label: 'Noto Sans Marchen',
    value: 'Noto Sans Marchen',
  },
  {
    label: 'Noto Sans Masaram Gondi',
    value: 'Noto Sans Masaram Gondi',
  },
  {
    label: 'Noto Sans Math',
    value: 'Noto Sans Math',
  },
  {
    label: 'Noto Sans Mayan Numerals',
    value: 'Noto Sans Mayan Numerals',
  },
  {
    label: 'Noto Sans Medefaidrin',
    value: 'Noto Sans Medefaidrin',
  },
  {
    label: 'Noto Sans Meetei Mayek',
    value: 'Noto Sans Meetei Mayek',
  },
  {
    label: 'Noto Sans Mende Kikakui',
    value: 'Noto Sans Mende Kikakui',
  },
  {
    label: 'Noto Sans Meroitic',
    value: 'Noto Sans Meroitic',
  },
  {
    label: 'Noto Sans Miao',
    value: 'Noto Sans Miao',
  },
  {
    label: 'Noto Sans Modi',
    value: 'Noto Sans Modi',
  },
  {
    label: 'Noto Sans Mongolian',
    value: 'Noto Sans Mongolian',
  },
  {
    label: 'Noto Sans Mono',
    value: 'Noto Sans Mono',
  },
  {
    label: 'Noto Sans Mro',
    value: 'Noto Sans Mro',
  },
  {
    label: 'Noto Sans Multani',
    value: 'Noto Sans Multani',
  },
  {
    label: 'Noto Sans Myanmar',
    value: 'Noto Sans Myanmar',
  },
  {
    label: 'Noto Sans Nabataean',
    value: 'Noto Sans Nabataean',
  },
  {
    label: 'Noto Sans Nag Mundari',
    value: 'Noto Sans Nag Mundari',
  },
  {
    label: 'Noto Sans Nandinagari',
    value: 'Noto Sans Nandinagari',
  },
  {
    label: 'Noto Sans New Tai Lue',
    value: 'Noto Sans New Tai Lue',
  },
  {
    label: 'Noto Sans Newa',
    value: 'Noto Sans Newa',
  },
  {
    label: 'Noto Sans NKo',
    value: 'Noto Sans NKo',
  },
  {
    label: 'Noto Sans NKo Unjoined',
    value: 'Noto Sans NKo Unjoined',
  },
  {
    label: 'Noto Sans Nushu',
    value: 'Noto Sans Nushu',
  },
  {
    label: 'Noto Sans Ogham',
    value: 'Noto Sans Ogham',
  },
  {
    label: 'Noto Sans Ol Chiki',
    value: 'Noto Sans Ol Chiki',
  },
  {
    label: 'Noto Sans Old Hungarian',
    value: 'Noto Sans Old Hungarian',
  },
  {
    label: 'Noto Sans Old Italic',
    value: 'Noto Sans Old Italic',
  },
  {
    label: 'Noto Sans Old North Arabian',
    value: 'Noto Sans Old North Arabian',
  },
  {
    label: 'Noto Sans Old Permic',
    value: 'Noto Sans Old Permic',
  },
  {
    label: 'Noto Sans Old Persian',
    value: 'Noto Sans Old Persian',
  },
  {
    label: 'Noto Sans Old Sogdian',
    value: 'Noto Sans Old Sogdian',
  },
  {
    label: 'Noto Sans Old South Arabian',
    value: 'Noto Sans Old South Arabian',
  },
  {
    label: 'Noto Sans Old Turkic',
    value: 'Noto Sans Old Turkic',
  },
  {
    label: 'Noto Sans Oriya',
    value: 'Noto Sans Oriya',
  },
  {
    label: 'Noto Sans Osage',
    value: 'Noto Sans Osage',
  },
  {
    label: 'Noto Sans Osmanya',
    value: 'Noto Sans Osmanya',
  },
  {
    label: 'Noto Sans Pahawh Hmong',
    value: 'Noto Sans Pahawh Hmong',
  },
  {
    label: 'Noto Sans Palmyrene',
    value: 'Noto Sans Palmyrene',
  },
  {
    label: 'Noto Sans Pau Cin Hau',
    value: 'Noto Sans Pau Cin Hau',
  },
  {
    label: 'Noto Sans Phags Pa',
    value: 'Noto Sans Phags Pa',
  },
  {
    label: 'Noto Sans Phoenician',
    value: 'Noto Sans Phoenician',
  },
  {
    label: 'Noto Sans Psalter Pahlavi',
    value: 'Noto Sans Psalter Pahlavi',
  },
  {
    label: 'Noto Sans Rejang',
    value: 'Noto Sans Rejang',
  },
  {
    label: 'Noto Sans Runic',
    value: 'Noto Sans Runic',
  },
  {
    label: 'Noto Sans Samaritan',
    value: 'Noto Sans Samaritan',
  },
  {
    label: 'Noto Sans Saurashtra',
    value: 'Noto Sans Saurashtra',
  },
  {
    label: 'Noto Sans SC',
    value: 'Noto Sans SC',
  },
  {
    label: 'Noto Sans Sharada',
    value: 'Noto Sans Sharada',
  },
  {
    label: 'Noto Sans Shavian',
    value: 'Noto Sans Shavian',
  },
  {
    label: 'Noto Sans Siddham',
    value: 'Noto Sans Siddham',
  },
  {
    label: 'Noto Sans SignWriting',
    value: 'Noto Sans SignWriting',
  },
  {
    label: 'Noto Sans Sinhala',
    value: 'Noto Sans Sinhala',
  },
  {
    label: 'Noto Sans Sogdian',
    value: 'Noto Sans Sogdian',
  },
  {
    label: 'Noto Sans Sora Sompeng',
    value: 'Noto Sans Sora Sompeng',
  },
  {
    label: 'Noto Sans Soyombo',
    value: 'Noto Sans Soyombo',
  },
  {
    label: 'Noto Sans Sundanese',
    value: 'Noto Sans Sundanese',
  },
  {
    label: 'Noto Sans Syloti Nagri',
    value: 'Noto Sans Syloti Nagri',
  },
  {
    label: 'Noto Sans Symbols',
    value: 'Noto Sans Symbols',
  },
  {
    label: 'Noto Sans Symbols 2',
    value: 'Noto Sans Symbols 2',
  },
  {
    label: 'Noto Sans Syriac',
    value: 'Noto Sans Syriac',
  },
  {
    label: 'Noto Sans Syriac Eastern',
    value: 'Noto Sans Syriac Eastern',
  },
  {
    label: 'Noto Sans Tagalog',
    value: 'Noto Sans Tagalog',
  },
  {
    label: 'Noto Sans Tagbanwa',
    value: 'Noto Sans Tagbanwa',
  },
  {
    label: 'Noto Sans Tai Le',
    value: 'Noto Sans Tai Le',
  },
  {
    label: 'Noto Sans Tai Tham',
    value: 'Noto Sans Tai Tham',
  },
  {
    label: 'Noto Sans Tai Viet',
    value: 'Noto Sans Tai Viet',
  },
  {
    label: 'Noto Sans Takri',
    value: 'Noto Sans Takri',
  },
  {
    label: 'Noto Sans Tamil',
    value: 'Noto Sans Tamil',
  },
  {
    label: 'Noto Sans Tamil Supplement',
    value: 'Noto Sans Tamil Supplement',
  },
  {
    label: 'Noto Sans Tangsa',
    value: 'Noto Sans Tangsa',
  },
  {
    label: 'Noto Sans TC',
    value: 'Noto Sans TC',
  },
  {
    label: 'Noto Sans Telugu',
    value: 'Noto Sans Telugu',
  },
  {
    label: 'Noto Sans Thaana',
    value: 'Noto Sans Thaana',
  },
  {
    label: 'Noto Sans Thai',
    value: 'Noto Sans Thai',
  },
  {
    label: 'Noto Sans Thai Looped',
    value: 'Noto Sans Thai Looped',
  },
  {
    label: 'Noto Sans Tifinagh',
    value: 'Noto Sans Tifinagh',
  },
  {
    label: 'Noto Sans Tirhuta',
    value: 'Noto Sans Tirhuta',
  },
  {
    label: 'Noto Sans Ugaritic',
    value: 'Noto Sans Ugaritic',
  },
  {
    label: 'Noto Sans Vai',
    value: 'Noto Sans Vai',
  },
  {
    label: 'Noto Sans Vithkuqi',
    value: 'Noto Sans Vithkuqi',
  },
  {
    label: 'Noto Sans Wancho',
    value: 'Noto Sans Wancho',
  },
  {
    label: 'Noto Sans Warang Citi',
    value: 'Noto Sans Warang Citi',
  },
  {
    label: 'Noto Sans Yi',
    value: 'Noto Sans Yi',
  },
  {
    label: 'Noto Sans Zanabazar Square',
    value: 'Noto Sans Zanabazar Square',
  },
  {
    label: 'Noto Serif',
    value: 'Noto Serif',
  },
  {
    label: 'Noto Serif Ahom',
    value: 'Noto Serif Ahom',
  },
  {
    label: 'Noto Serif Armenian',
    value: 'Noto Serif Armenian',
  },
  {
    label: 'Noto Serif Balinese',
    value: 'Noto Serif Balinese',
  },
  {
    label: 'Noto Serif Bengali',
    value: 'Noto Serif Bengali',
  },
  {
    label: 'Noto Serif Devanagari',
    value: 'Noto Serif Devanagari',
  },
  {
    label: 'Noto Serif Display',
    value: 'Noto Serif Display',
  },
  {
    label: 'Noto Serif Dogra',
    value: 'Noto Serif Dogra',
  },
  {
    label: 'Noto Serif Ethiopic',
    value: 'Noto Serif Ethiopic',
  },
  {
    label: 'Noto Serif Georgian',
    value: 'Noto Serif Georgian',
  },
  {
    label: 'Noto Serif Grantha',
    value: 'Noto Serif Grantha',
  },
  {
    label: 'Noto Serif Gujarati',
    value: 'Noto Serif Gujarati',
  },
  {
    label: 'Noto Serif Gurmukhi',
    value: 'Noto Serif Gurmukhi',
  },
  {
    label: 'Noto Serif Hebrew',
    value: 'Noto Serif Hebrew',
  },
  {
    label: 'Noto Serif HK',
    value: 'Noto Serif HK',
  },
  {
    label: 'Noto Serif JP',
    value: 'Noto Serif JP',
  },
  {
    label: 'Noto Serif Kannada',
    value: 'Noto Serif Kannada',
  },
  {
    label: 'Noto Serif Khitan Small Script',
    value: 'Noto Serif Khitan Small Script',
  },
  {
    label: 'Noto Serif Khmer',
    value: 'Noto Serif Khmer',
  },
  {
    label: 'Noto Serif Khojki',
    value: 'Noto Serif Khojki',
  },
  {
    label: 'Noto Serif KR',
    value: 'Noto Serif KR',
  },
  {
    label: 'Noto Serif Lao',
    value: 'Noto Serif Lao',
  },
  {
    label: 'Noto Serif Makasar',
    value: 'Noto Serif Makasar',
  },
  {
    label: 'Noto Serif Malayalam',
    value: 'Noto Serif Malayalam',
  },
  {
    label: 'Noto Serif Myanmar',
    value: 'Noto Serif Myanmar',
  },
  {
    label: 'Noto Serif NP Hmong',
    value: 'Noto Serif NP Hmong',
  },
  {
    label: 'Noto Serif Oriya',
    value: 'Noto Serif Oriya',
  },
  {
    label: 'Noto Serif Ottoman Siyaq',
    value: 'Noto Serif Ottoman Siyaq',
  },
  {
    label: 'Noto Serif SC',
    value: 'Noto Serif SC',
  },
  {
    label: 'Noto Serif Sinhala',
    value: 'Noto Serif Sinhala',
  },
  {
    label: 'Noto Serif Tamil',
    value: 'Noto Serif Tamil',
  },
  {
    label: 'Noto Serif Tangut',
    value: 'Noto Serif Tangut',
  },
  {
    label: 'Noto Serif TC',
    value: 'Noto Serif TC',
  },
  {
    label: 'Noto Serif Telugu',
    value: 'Noto Serif Telugu',
  },
  {
    label: 'Noto Serif Thai',
    value: 'Noto Serif Thai',
  },
  {
    label: 'Noto Serif Tibetan',
    value: 'Noto Serif Tibetan',
  },
  {
    label: 'Noto Serif Toto',
    value: 'Noto Serif Toto',
  },
  {
    label: 'Noto Serif Vithkuqi',
    value: 'Noto Serif Vithkuqi',
  },
  {
    label: 'Noto Serif Yezidi',
    value: 'Noto Serif Yezidi',
  },
  {
    label: 'Noto Traditional Nushu',
    value: 'Noto Traditional Nushu',
  },
  {
    label: 'Nova Cut',
    value: 'Nova Cut',
  },
  {
    label: 'Nova Flat',
    value: 'Nova Flat',
  },
  {
    label: 'Nova Mono',
    value: 'Nova Mono',
  },
  {
    label: 'Nova Oval',
    value: 'Nova Oval',
  },
  {
    label: 'Nova Round',
    value: 'Nova Round',
  },
  {
    label: 'Nova Script',
    value: 'Nova Script',
  },
  {
    label: 'Nova Slim',
    value: 'Nova Slim',
  },
  {
    label: 'Nova Square',
    value: 'Nova Square',
  },
  {
    label: 'NTR',
    value: 'NTR',
  },
  {
    label: 'Numans',
    value: 'Numans',
  },
  {
    label: 'Nunito',
    value: 'Nunito',
  },
  {
    label: 'Nunito Sans',
    value: 'Nunito Sans',
  },
  {
    label: 'Nuosu SIL',
    value: 'Nuosu SIL',
  },
  {
    label: 'Odibee Sans',
    value: 'Odibee Sans',
  },
  {
    label: 'Odor Mean Chey',
    value: 'Odor Mean Chey',
  },
  {
    label: 'Offside',
    value: 'Offside',
  },
  {
    label: 'Oi',
    value: 'Oi',
  },
  {
    label: 'Old Standard TT',
    value: 'Old Standard TT',
  },
  {
    label: 'Oldenburg',
    value: 'Oldenburg',
  },
  {
    label: 'Ole',
    value: 'Ole',
  },
  {
    label: 'Oleo Script',
    value: 'Oleo Script',
  },
  {
    label: 'Oleo Script Swash Caps',
    value: 'Oleo Script Swash Caps',
  },
  {
    label: 'Onest',
    value: 'Onest',
  },
  {
    label: 'Oooh Baby',
    value: 'Oooh Baby',
  },
  {
    label: 'Open Sans',
    value: 'Open Sans',
  },
  {
    label: 'Oranienbaum',
    value: 'Oranienbaum',
  },
  {
    label: 'Orbit',
    value: 'Orbit',
  },
  {
    label: 'Orbitron',
    value: 'Orbitron',
  },
  {
    label: 'Oregano',
    value: 'Oregano',
  },
  {
    label: 'Orelega One',
    value: 'Orelega One',
  },
  {
    label: 'Orienta',
    value: 'Orienta',
  },
  {
    label: 'Original Surfer',
    value: 'Original Surfer',
  },
  {
    label: 'Oswald',
    value: 'Oswald',
  },
  {
    label: 'Outfit',
    value: 'Outfit',
  },
  {
    label: 'Over the Rainbow',
    value: 'Over the Rainbow',
  },
  {
    label: 'Overlock',
    value: 'Overlock',
  },
  {
    label: 'Overlock SC',
    value: 'Overlock SC',
  },
  {
    label: 'Overpass',
    value: 'Overpass',
  },
  {
    label: 'Overpass Mono',
    value: 'Overpass Mono',
  },
  {
    label: 'Ovo',
    value: 'Ovo',
  },
  {
    label: 'Oxanium',
    value: 'Oxanium',
  },
  {
    label: 'Oxygen',
    value: 'Oxygen',
  },
  {
    label: 'Oxygen Mono',
    value: 'Oxygen Mono',
  },
  {
    label: 'Pacifico',
    value: 'Pacifico',
  },
  {
    label: 'Padauk',
    value: 'Padauk',
  },
  {
    label: 'Padyakke Expanded One',
    value: 'Padyakke Expanded One',
  },
  {
    label: 'Palanquin',
    value: 'Palanquin',
  },
  {
    label: 'Palanquin Dark',
    value: 'Palanquin Dark',
  },
  {
    label: 'Palette Mosaic',
    value: 'Palette Mosaic',
  },
  {
    label: 'Pangolin',
    value: 'Pangolin',
  },
  {
    label: 'Paprika',
    value: 'Paprika',
  },
  {
    label: 'Parisienne',
    value: 'Parisienne',
  },
  {
    label: 'Passero One',
    value: 'Passero One',
  },
  {
    label: 'Passion One',
    value: 'Passion One',
  },
  {
    label: 'Passions Conflict',
    value: 'Passions Conflict',
  },
  {
    label: 'Pathway Extreme',
    value: 'Pathway Extreme',
  },
  {
    label: 'Pathway Gothic One',
    value: 'Pathway Gothic One',
  },
  {
    label: 'Patrick Hand',
    value: 'Patrick Hand',
  },
  {
    label: 'Patrick Hand SC',
    value: 'Patrick Hand SC',
  },
  {
    label: 'Pattaya',
    value: 'Pattaya',
  },
  {
    label: 'Patua One',
    value: 'Patua One',
  },
  {
    label: 'Pavanam',
    value: 'Pavanam',
  },
  {
    label: 'Paytone One',
    value: 'Paytone One',
  },
  {
    label: 'Peddana',
    value: 'Peddana',
  },
  {
    label: 'Peralta',
    value: 'Peralta',
  },
  {
    label: 'Permanent Marker',
    value: 'Permanent Marker',
  },
  {
    label: 'Petemoss',
    value: 'Petemoss',
  },
  {
    label: 'Petit Formal Script',
    value: 'Petit Formal Script',
  },
  {
    label: 'Petrona',
    value: 'Petrona',
  },
  {
    label: 'Philosopher',
    value: 'Philosopher',
  },
  {
    label: 'Phudu',
    value: 'Phudu',
  },
  {
    label: 'Piazzolla',
    value: 'Piazzolla',
  },
  {
    label: 'Piedra',
    value: 'Piedra',
  },
  {
    label: 'Pinyon Script',
    value: 'Pinyon Script',
  },
  {
    label: 'Pirata One',
    value: 'Pirata One',
  },
  {
    label: 'Pixelify Sans',
    value: 'Pixelify Sans',
  },
  {
    label: 'Plaster',
    value: 'Plaster',
  },
  {
    label: 'Play',
    value: 'Play',
  },
  {
    label: 'Playball',
    value: 'Playball',
  },
  {
    label: 'Playfair',
    value: 'Playfair',
  },
  {
    label: 'Playfair Display',
    value: 'Playfair Display',
  },
  {
    label: 'Playfair Display SC',
    value: 'Playfair Display SC',
  },
  {
    label: 'Playpen Sans',
    value: 'Playpen Sans',
  },
  {
    label: 'Plus Jakarta Sans',
    value: 'Plus Jakarta Sans',
  },
  {
    label: 'Podkova',
    value: 'Podkova',
  },
  {
    label: 'Poiret One',
    value: 'Poiret One',
  },
  {
    label: 'Poller One',
    value: 'Poller One',
  },
  {
    label: 'Poltawski Nowy',
    value: 'Poltawski Nowy',
  },
  {
    label: 'Poly',
    value: 'Poly',
  },
  {
    label: 'Pompiere',
    value: 'Pompiere',
  },
  {
    label: 'Pontano Sans',
    value: 'Pontano Sans',
  },
  {
    label: 'Poor Story',
    value: 'Poor Story',
  },
  {
    label: 'Poppins',
    value: 'Poppins',
  },
  {
    label: 'Port Lligat Sans',
    value: 'Port Lligat Sans',
  },
  {
    label: 'Port Lligat Slab',
    value: 'Port Lligat Slab',
  },
  {
    label: 'Potta One',
    value: 'Potta One',
  },
  {
    label: 'Pragati Narrow',
    value: 'Pragati Narrow',
  },
  {
    label: 'Praise',
    value: 'Praise',
  },
  {
    label: 'Prata',
    value: 'Prata',
  },
  {
    label: 'Preahvihear',
    value: 'Preahvihear',
  },
  {
    label: 'Press Start 2P',
    value: 'Press Start 2P',
  },
  {
    label: 'Pridi',
    value: 'Pridi',
  },
  {
    label: 'Princess Sofia',
    value: 'Princess Sofia',
  },
  {
    label: 'Prociono',
    value: 'Prociono',
  },
  {
    label: 'Prompt',
    value: 'Prompt',
  },
  {
    label: 'Prosto One',
    value: 'Prosto One',
  },
  {
    label: 'Proza Libre',
    value: 'Proza Libre',
  },
  {
    label: 'PT Mono',
    value: 'PT Mono',
  },
  {
    label: 'PT Sans',
    value: 'PT Sans',
  },
  {
    label: 'PT Sans Caption',
    value: 'PT Sans Caption',
  },
  {
    label: 'PT Sans Narrow',
    value: 'PT Sans Narrow',
  },
  {
    label: 'PT Serif',
    value: 'PT Serif',
  },
  {
    label: 'PT Serif Caption',
    value: 'PT Serif Caption',
  },
  {
    label: 'Public Sans',
    value: 'Public Sans',
  },
  {
    label: 'Puppies Play',
    value: 'Puppies Play',
  },
  {
    label: 'Puritan',
    value: 'Puritan',
  },
  {
    label: 'Purple Purse',
    value: 'Purple Purse',
  },
  {
    label: 'Qahiri',
    value: 'Qahiri',
  },
  {
    label: 'Quando',
    value: 'Quando',
  },
  {
    label: 'Quantico',
    value: 'Quantico',
  },
  {
    label: 'Quattrocento',
    value: 'Quattrocento',
  },
  {
    label: 'Quattrocento Sans',
    value: 'Quattrocento Sans',
  },
  {
    label: 'Questrial',
    value: 'Questrial',
  },
  {
    label: 'Quicksand',
    value: 'Quicksand',
  },
  {
    label: 'Quintessential',
    value: 'Quintessential',
  },
  {
    label: 'Qwigley',
    value: 'Qwigley',
  },
  {
    label: 'Qwitcher Grypen',
    value: 'Qwitcher Grypen',
  },
  {
    label: 'Racing Sans One',
    value: 'Racing Sans One',
  },
  {
    label: 'Radio Canada',
    value: 'Radio Canada',
  },
  {
    label: 'Radley',
    value: 'Radley',
  },
  {
    label: 'Rajdhani',
    value: 'Rajdhani',
  },
  {
    label: 'Rakkas',
    value: 'Rakkas',
  },
  {
    label: 'Raleway',
    value: 'Raleway',
  },
  {
    label: 'Raleway Dots',
    value: 'Raleway Dots',
  },
  {
    label: 'Ramabhadra',
    value: 'Ramabhadra',
  },
  {
    label: 'Ramaraja',
    value: 'Ramaraja',
  },
  {
    label: 'Rambla',
    value: 'Rambla',
  },
  {
    label: 'Rammetto One',
    value: 'Rammetto One',
  },
  {
    label: 'Rampart One',
    value: 'Rampart One',
  },
  {
    label: 'Ranchers',
    value: 'Ranchers',
  },
  {
    label: 'Rancho',
    value: 'Rancho',
  },
  {
    label: 'Ranga',
    value: 'Ranga',
  },
  {
    label: 'Rasa',
    value: 'Rasa',
  },
  {
    label: 'Rationale',
    value: 'Rationale',
  },
  {
    label: 'Ravi Prakash',
    value: 'Ravi Prakash',
  },
  {
    label: 'Readex Pro',
    value: 'Readex Pro',
  },
  {
    label: 'Recursive',
    value: 'Recursive',
  },
  {
    label: 'Red Hat Display',
    value: 'Red Hat Display',
  },
  {
    label: 'Red Hat Mono',
    value: 'Red Hat Mono',
  },
  {
    label: 'Red Hat Text',
    value: 'Red Hat Text',
  },
  {
    label: 'Red Rose',
    value: 'Red Rose',
  },
  {
    label: 'Redacted',
    value: 'Redacted',
  },
  {
    label: 'Redacted Script',
    value: 'Redacted Script',
  },
  {
    label: 'Redressed',
    value: 'Redressed',
  },
  {
    label: 'Reem Kufi',
    value: 'Reem Kufi',
  },
  {
    label: 'Reem Kufi Fun',
    value: 'Reem Kufi Fun',
  },
  {
    label: 'Reem Kufi Ink',
    value: 'Reem Kufi Ink',
  },
  {
    label: 'Reenie Beanie',
    value: 'Reenie Beanie',
  },
  {
    label: 'Reggae One',
    value: 'Reggae One',
  },
  {
    label: 'REM',
    value: 'REM',
  },
  {
    label: 'Revalia',
    value: 'Revalia',
  },
  {
    label: 'Rhodium Libre',
    value: 'Rhodium Libre',
  },
  {
    label: 'Ribeye',
    value: 'Ribeye',
  },
  {
    label: 'Ribeye Marrow',
    value: 'Ribeye Marrow',
  },
  {
    label: 'Righteous',
    value: 'Righteous',
  },
  {
    label: 'Risque',
    value: 'Risque',
  },
  {
    label: 'Road Rage',
    value: 'Road Rage',
  },
  {
    label: 'Roboto',
    value: 'Roboto',
  },
  {
    label: 'Roboto Condensed',
    value: 'Roboto Condensed',
  },
  {
    label: 'Roboto Flex',
    value: 'Roboto Flex',
  },
  {
    label: 'Roboto Mono',
    value: 'Roboto Mono',
  },
  {
    label: 'Roboto Serif',
    value: 'Roboto Serif',
  },
  {
    label: 'Roboto Slab',
    value: 'Roboto Slab',
  },
  {
    label: 'Rochester',
    value: 'Rochester',
  },
  {
    label: 'Rock 3D',
    value: 'Rock 3D',
  },
  {
    label: 'Rock Salt',
    value: 'Rock Salt',
  },
  {
    label: 'RocknRoll One',
    value: 'RocknRoll One',
  },
  {
    label: 'Rokkitt',
    value: 'Rokkitt',
  },
  {
    label: 'Romanesco',
    value: 'Romanesco',
  },
  {
    label: 'Ropa Sans',
    value: 'Ropa Sans',
  },
  {
    label: 'Rosario',
    value: 'Rosario',
  },
  {
    label: 'Rosarivo',
    value: 'Rosarivo',
  },
  {
    label: 'Rouge Script',
    value: 'Rouge Script',
  },
  {
    label: 'Rowdies',
    value: 'Rowdies',
  },
  {
    label: 'Rozha One',
    value: 'Rozha One',
  },
  {
    label: 'Rubik',
    value: 'Rubik',
  },
  {
    label: 'Rubik 80s Fade',
    value: 'Rubik 80s Fade',
  },
  {
    label: 'Rubik Beastly',
    value: 'Rubik Beastly',
  },
  {
    label: 'Rubik Bubbles',
    value: 'Rubik Bubbles',
  },
  {
    label: 'Rubik Burned',
    value: 'Rubik Burned',
  },
  {
    label: 'Rubik Dirt',
    value: 'Rubik Dirt',
  },
  {
    label: 'Rubik Distressed',
    value: 'Rubik Distressed',
  },
  {
    label: 'Rubik Gemstones',
    value: 'Rubik Gemstones',
  },
  {
    label: 'Rubik Glitch',
    value: 'Rubik Glitch',
  },
  {
    label: 'Rubik Iso',
    value: 'Rubik Iso',
  },
  {
    label: 'Rubik Marker Hatch',
    value: 'Rubik Marker Hatch',
  },
  {
    label: 'Rubik Maze',
    value: 'Rubik Maze',
  },
  {
    label: 'Rubik Microbe',
    value: 'Rubik Microbe',
  },
  {
    label: 'Rubik Mono One',
    value: 'Rubik Mono One',
  },
  {
    label: 'Rubik Moonrocks',
    value: 'Rubik Moonrocks',
  },
  {
    label: 'Rubik Pixels',
    value: 'Rubik Pixels',
  },
  {
    label: 'Rubik Puddles',
    value: 'Rubik Puddles',
  },
  {
    label: 'Rubik Spray Paint',
    value: 'Rubik Spray Paint',
  },
  {
    label: 'Rubik Storm',
    value: 'Rubik Storm',
  },
  {
    label: 'Rubik Vinyl',
    value: 'Rubik Vinyl',
  },
  {
    label: 'Rubik Wet Paint',
    value: 'Rubik Wet Paint',
  },
  {
    label: 'Ruda',
    value: 'Ruda',
  },
  {
    label: 'Rufina',
    value: 'Rufina',
  },
  {
    label: 'Ruge Boogie',
    value: 'Ruge Boogie',
  },
  {
    label: 'Ruluko',
    value: 'Ruluko',
  },
  {
    label: 'Rum Raisin',
    value: 'Rum Raisin',
  },
  {
    label: 'Ruslan Display',
    value: 'Ruslan Display',
  },
  {
    label: 'Russo One',
    value: 'Russo One',
  },
  {
    label: 'Ruthie',
    value: 'Ruthie',
  },
  {
    label: 'Ruwudu',
    value: 'Ruwudu',
  },
  {
    label: 'Rye',
    value: 'Rye',
  },
  {
    label: 'Sacramento',
    value: 'Sacramento',
  },
  {
    label: 'Sahitya',
    value: 'Sahitya',
  },
  {
    label: 'Sail',
    value: 'Sail',
  },
  {
    label: 'Saira',
    value: 'Saira',
  },
  {
    label: 'Saira Condensed',
    value: 'Saira Condensed',
  },
  {
    label: 'Saira Extra Condensed',
    value: 'Saira Extra Condensed',
  },
  {
    label: 'Saira Semi Condensed',
    value: 'Saira Semi Condensed',
  },
  {
    label: 'Saira Stencil One',
    value: 'Saira Stencil One',
  },
  {
    label: 'Salsa',
    value: 'Salsa',
  },
  {
    label: 'Sanchez',
    value: 'Sanchez',
  },
  {
    label: 'Sancreek',
    value: 'Sancreek',
  },
  {
    label: 'Sansita',
    value: 'Sansita',
  },
  {
    label: 'Sansita Swashed',
    value: 'Sansita Swashed',
  },
  {
    label: 'Sarabun',
    value: 'Sarabun',
  },
  {
    label: 'Sarala',
    value: 'Sarala',
  },
  {
    label: 'Sarina',
    value: 'Sarina',
  },
  {
    label: 'Sarpanch',
    value: 'Sarpanch',
  },
  {
    label: 'Sassy Frass',
    value: 'Sassy Frass',
  },
  {
    label: 'Satisfy',
    value: 'Satisfy',
  },
  {
    label: 'Sawarabi Gothic',
    value: 'Sawarabi Gothic',
  },
  {
    label: 'Sawarabi Mincho',
    value: 'Sawarabi Mincho',
  },
  {
    label: 'Scada',
    value: 'Scada',
  },
  {
    label: 'Scheherazade New',
    value: 'Scheherazade New',
  },
  {
    label: 'Schibsted Grotesk',
    value: 'Schibsted Grotesk',
  },
  {
    label: 'Schoolbell',
    value: 'Schoolbell',
  },
  {
    label: 'Scope One',
    value: 'Scope One',
  },
  {
    label: 'Seaweed Script',
    value: 'Seaweed Script',
  },
  {
    label: 'Secular One',
    value: 'Secular One',
  },
  {
    label: 'Sedgwick Ave',
    value: 'Sedgwick Ave',
  },
  {
    label: 'Sedgwick Ave Display',
    value: 'Sedgwick Ave Display',
  },
  {
    label: 'Sen',
    value: 'Sen',
  },
  {
    label: 'Send Flowers',
    value: 'Send Flowers',
  },
  {
    label: 'Sevillana',
    value: 'Sevillana',
  },
  {
    label: 'Seymour One',
    value: 'Seymour One',
  },
  {
    label: 'Shadows Into Light',
    value: 'Shadows Into Light',
  },
  {
    label: 'Shadows Into Light Two',
    value: 'Shadows Into Light Two',
  },
  {
    label: 'Shalimar',
    value: 'Shalimar',
  },
  {
    label: 'Shantell Sans',
    value: 'Shantell Sans',
  },
  {
    label: 'Shanti',
    value: 'Shanti',
  },
  {
    label: 'Share',
    value: 'Share',
  },
  {
    label: 'Share Tech',
    value: 'Share Tech',
  },
  {
    label: 'Share Tech Mono',
    value: 'Share Tech Mono',
  },
  {
    label: 'Shippori Antique',
    value: 'Shippori Antique',
  },
  {
    label: 'Shippori Antique B1',
    value: 'Shippori Antique B1',
  },
  {
    label: 'Shippori Mincho',
    value: 'Shippori Mincho',
  },
  {
    label: 'Shippori Mincho B1',
    value: 'Shippori Mincho B1',
  },
  {
    label: 'Shizuru',
    value: 'Shizuru',
  },
  {
    label: 'Shojumaru',
    value: 'Shojumaru',
  },
  {
    label: 'Short Stack',
    value: 'Short Stack',
  },
  {
    label: 'Shrikhand',
    value: 'Shrikhand',
  },
  {
    label: 'Siemreap',
    value: 'Siemreap',
  },
  {
    label: 'Sigmar',
    value: 'Sigmar',
  },
  {
    label: 'Sigmar One',
    value: 'Sigmar One',
  },
  {
    label: 'Signika',
    value: 'Signika',
  },
  {
    label: 'Signika Negative',
    value: 'Signika Negative',
  },
  {
    label: 'Silkscreen',
    value: 'Silkscreen',
  },
  {
    label: 'Simonetta',
    value: 'Simonetta',
  },
  {
    label: 'Single Day',
    value: 'Single Day',
  },
  {
    label: 'Sintony',
    value: 'Sintony',
  },
  {
    label: 'Sirin Stencil',
    value: 'Sirin Stencil',
  },
  {
    label: 'Six Caps',
    value: 'Six Caps',
  },
  {
    label: 'Skranji',
    value: 'Skranji',
  },
  {
    label: 'Slabo 13px',
    value: 'Slabo 13px',
  },
  {
    label: 'Slabo 27px',
    value: 'Slabo 27px',
  },
  {
    label: 'Slackey',
    value: 'Slackey',
  },
  {
    label: 'Slackside One',
    value: 'Slackside One',
  },
  {
    label: 'Smokum',
    value: 'Smokum',
  },
  {
    label: 'Smooch',
    value: 'Smooch',
  },
  {
    label: 'Smooch Sans',
    value: 'Smooch Sans',
  },
  {
    label: 'Smythe',
    value: 'Smythe',
  },
  {
    label: 'Sniglet',
    value: 'Sniglet',
  },
  {
    label: 'Snippet',
    value: 'Snippet',
  },
  {
    label: 'Snowburst One',
    value: 'Snowburst One',
  },
  {
    label: 'Sofadi One',
    value: 'Sofadi One',
  },
  {
    label: 'Sofia',
    value: 'Sofia',
  },
  {
    label: 'Sofia Sans',
    value: 'Sofia Sans',
  },
  {
    label: 'Sofia Sans Condensed',
    value: 'Sofia Sans Condensed',
  },
  {
    label: 'Sofia Sans Extra Condensed',
    value: 'Sofia Sans Extra Condensed',
  },
  {
    label: 'Sofia Sans Semi Condensed',
    value: 'Sofia Sans Semi Condensed',
  },
  {
    label: 'Solitreo',
    value: 'Solitreo',
  },
  {
    label: 'Solway',
    value: 'Solway',
  },
  {
    label: 'Sometype Mono',
    value: 'Sometype Mono',
  },
  {
    label: 'Song Myung',
    value: 'Song Myung',
  },
  {
    label: 'Sono',
    value: 'Sono',
  },
  {
    label: 'Sonsie One',
    value: 'Sonsie One',
  },
  {
    label: 'Sora',
    value: 'Sora',
  },
  {
    label: 'Sorts Mill Goudy',
    value: 'Sorts Mill Goudy',
  },
  {
    label: 'Source Code Pro',
    value: 'Source Code Pro',
  },
  {
    label: 'Source Sans 3',
    value: 'Source Sans 3',
  },
  {
    label: 'Source Serif 4',
    value: 'Source Serif 4',
  },
  {
    label: 'Space Grotesk',
    value: 'Space Grotesk',
  },
  {
    label: 'Space Mono',
    value: 'Space Mono',
  },
  {
    label: 'Special Elite',
    value: 'Special Elite',
  },
  {
    label: 'Spectral',
    value: 'Spectral',
  },
  {
    label: 'Spectral SC',
    value: 'Spectral SC',
  },
  {
    label: 'Spicy Rice',
    value: 'Spicy Rice',
  },
  {
    label: 'Spinnaker',
    value: 'Spinnaker',
  },
  {
    label: 'Spirax',
    value: 'Spirax',
  },
  {
    label: 'Splash',
    value: 'Splash',
  },
  {
    label: 'Spline Sans',
    value: 'Spline Sans',
  },
  {
    label: 'Spline Sans Mono',
    value: 'Spline Sans Mono',
  },
  {
    label: 'Squada One',
    value: 'Squada One',
  },
  {
    label: 'Square Peg',
    value: 'Square Peg',
  },
  {
    label: 'Sree Krushnadevaraya',
    value: 'Sree Krushnadevaraya',
  },
  {
    label: 'Sriracha',
    value: 'Sriracha',
  },
  {
    label: 'Srisakdi',
    value: 'Srisakdi',
  },
  {
    label: 'Staatliches',
    value: 'Staatliches',
  },
  {
    label: 'Stalemate',
    value: 'Stalemate',
  },
  {
    label: 'Stalinist One',
    value: 'Stalinist One',
  },
  {
    label: 'Stardos Stencil',
    value: 'Stardos Stencil',
  },
  {
    label: 'Stick',
    value: 'Stick',
  },
  {
    label: 'Stick No Bills',
    value: 'Stick No Bills',
  },
  {
    label: 'Stint Ultra Condensed',
    value: 'Stint Ultra Condensed',
  },
  {
    label: 'Stint Ultra Expanded',
    value: 'Stint Ultra Expanded',
  },
  {
    label: 'STIX Two Text',
    value: 'STIX Two Text',
  },
  {
    label: 'Stoke',
    value: 'Stoke',
  },
  {
    label: 'Strait',
    value: 'Strait',
  },
  {
    label: 'Style Script',
    value: 'Style Script',
  },
  {
    label: 'Stylish',
    value: 'Stylish',
  },
  {
    label: 'Sue Ellen Francisco',
    value: 'Sue Ellen Francisco',
  },
  {
    label: 'Suez One',
    value: 'Suez One',
  },
  {
    label: 'Sulphur Point',
    value: 'Sulphur Point',
  },
  {
    label: 'Sumana',
    value: 'Sumana',
  },
  {
    label: 'Sunflower',
    value: 'Sunflower',
  },
  {
    label: 'Sunshiney',
    value: 'Sunshiney',
  },
  {
    label: 'Supermercado One',
    value: 'Supermercado One',
  },
  {
    label: 'Sura',
    value: 'Sura',
  },
  {
    label: 'Suranna',
    value: 'Suranna',
  },
  {
    label: 'Suravaram',
    value: 'Suravaram',
  },
  {
    label: 'Suwannaphum',
    value: 'Suwannaphum',
  },
  {
    label: 'Swanky and Moo Moo',
    value: 'Swanky and Moo Moo',
  },
  {
    label: 'Syncopate',
    value: 'Syncopate',
  },
  {
    label: 'Syne',
    value: 'Syne',
  },
  {
    label: 'Syne Mono',
    value: 'Syne Mono',
  },
  {
    label: 'Syne Tactile',
    value: 'Syne Tactile',
  },
  {
    label: 'Tai Heritage Pro',
    value: 'Tai Heritage Pro',
  },
  {
    label: 'Tajawal',
    value: 'Tajawal',
  },
  {
    label: 'Tangerine',
    value: 'Tangerine',
  },
  {
    label: 'Tapestry',
    value: 'Tapestry',
  },
  {
    label: 'Taprom',
    value: 'Taprom',
  },
  {
    label: 'Tauri',
    value: 'Tauri',
  },
  {
    label: 'Taviraj',
    value: 'Taviraj',
  },
  {
    label: 'Teko',
    value: 'Teko',
  },
  {
    label: 'Tektur',
    value: 'Tektur',
  },
  {
    label: 'Telex',
    value: 'Telex',
  },
  {
    label: 'Tenali Ramakrishna',
    value: 'Tenali Ramakrishna',
  },
  {
    label: 'Tenor Sans',
    value: 'Tenor Sans',
  },
  {
    label: 'Text Me One',
    value: 'Text Me One',
  },
  {
    label: 'Texturina',
    value: 'Texturina',
  },
  {
    label: 'Thasadith',
    value: 'Thasadith',
  },
  {
    label: 'The Girl Next Door',
    value: 'The Girl Next Door',
  },
  {
    label: 'The Nautigal',
    value: 'The Nautigal',
  },
  {
    label: 'Tienne',
    value: 'Tienne',
  },
  {
    label: 'Tillana',
    value: 'Tillana',
  },
  {
    label: 'Tilt Neon',
    value: 'Tilt Neon',
  },
  {
    label: 'Tilt Prism',
    value: 'Tilt Prism',
  },
  {
    label: 'Tilt Warp',
    value: 'Tilt Warp',
  },
  {
    label: 'Timmana',
    value: 'Timmana',
  },
  {
    label: 'Tinos',
    value: 'Tinos',
  },
  {
    label: 'Tiro Bangla',
    value: 'Tiro Bangla',
  },
  {
    label: 'Tiro Devanagari Hindi',
    value: 'Tiro Devanagari Hindi',
  },
  {
    label: 'Tiro Devanagari Marathi',
    value: 'Tiro Devanagari Marathi',
  },
  {
    label: 'Tiro Devanagari Sanskrit',
    value: 'Tiro Devanagari Sanskrit',
  },
  {
    label: 'Tiro Gurmukhi',
    value: 'Tiro Gurmukhi',
  },
  {
    label: 'Tiro Kannada',
    value: 'Tiro Kannada',
  },
  {
    label: 'Tiro Tamil',
    value: 'Tiro Tamil',
  },
  {
    label: 'Tiro Telugu',
    value: 'Tiro Telugu',
  },
  {
    label: 'Titan One',
    value: 'Titan One',
  },
  {
    label: 'Titillium Web',
    value: 'Titillium Web',
  },
  {
    label: 'Tomorrow',
    value: 'Tomorrow',
  },
  {
    label: 'Tourney',
    value: 'Tourney',
  },
  {
    label: 'Trade Winds',
    value: 'Trade Winds',
  },
  {
    label: 'Train One',
    value: 'Train One',
  },
  {
    label: 'Trirong',
    value: 'Trirong',
  },
  {
    label: 'Trispace',
    value: 'Trispace',
  },
  {
    label: 'Trocchi',
    value: 'Trocchi',
  },
  {
    label: 'Trochut',
    value: 'Trochut',
  },
  {
    label: 'Truculenta',
    value: 'Truculenta',
  },
  {
    label: 'Trykker',
    value: 'Trykker',
  },
  {
    label: 'Tsukimi Rounded',
    value: 'Tsukimi Rounded',
  },
  {
    label: 'Tulpen One',
    value: 'Tulpen One',
  },
  {
    label: 'Turret Road',
    value: 'Turret Road',
  },
  {
    label: 'Twinkle Star',
    value: 'Twinkle Star',
  },
  {
    label: 'Ubuntu',
    value: 'Ubuntu',
  },
  {
    label: 'Ubuntu Condensed',
    value: 'Ubuntu Condensed',
  },
  {
    label: 'Ubuntu Mono',
    value: 'Ubuntu Mono',
  },
  {
    label: 'Uchen',
    value: 'Uchen',
  },
  {
    label: 'Ultra',
    value: 'Ultra',
  },
  {
    label: 'Unbounded',
    value: 'Unbounded',
  },
  {
    label: 'Uncial Antiqua',
    value: 'Uncial Antiqua',
  },
  {
    label: 'Underdog',
    value: 'Underdog',
  },
  {
    label: 'Unica One',
    value: 'Unica One',
  },
  {
    label: 'UnifrakturCook',
    value: 'UnifrakturCook',
  },
  {
    label: 'UnifrakturMaguntia',
    value: 'UnifrakturMaguntia',
  },
  {
    label: 'Unkempt',
    value: 'Unkempt',
  },
  {
    label: 'Unlock',
    value: 'Unlock',
  },
  {
    label: 'Unna',
    value: 'Unna',
  },
  {
    label: 'Updock',
    value: 'Updock',
  },
  {
    label: 'Urbanist',
    value: 'Urbanist',
  },
  {
    label: 'Vampiro One',
    value: 'Vampiro One',
  },
  {
    label: 'Varela',
    value: 'Varela',
  },
  {
    label: 'Varela Round',
    value: 'Varela Round',
  },
  {
    label: 'Varta',
    value: 'Varta',
  },
  {
    label: 'Vast Shadow',
    value: 'Vast Shadow',
  },
  {
    label: 'Vazirmatn',
    value: 'Vazirmatn',
  },
  {
    label: 'Vesper Libre',
    value: 'Vesper Libre',
  },
  {
    label: 'Viaoda Libre',
    value: 'Viaoda Libre',
  },
  {
    label: 'Vibes',
    value: 'Vibes',
  },
  {
    label: 'Vibur',
    value: 'Vibur',
  },
  {
    label: 'Victor Mono',
    value: 'Victor Mono',
  },
  {
    label: 'Vidaloka',
    value: 'Vidaloka',
  },
  {
    label: 'Viga',
    value: 'Viga',
  },
  {
    label: 'Vina Sans',
    value: 'Vina Sans',
  },
  {
    label: 'Voces',
    value: 'Voces',
  },
  {
    label: 'Volkhov',
    value: 'Volkhov',
  },
  {
    label: 'Vollkorn',
    value: 'Vollkorn',
  },
  {
    label: 'Vollkorn SC',
    value: 'Vollkorn SC',
  },
  {
    label: 'Voltaire',
    value: 'Voltaire',
  },
  {
    label: 'VT323',
    value: 'VT323',
  },
  {
    label: 'Vujahday Script',
    value: 'Vujahday Script',
  },
  {
    label: 'Waiting for the Sunrise',
    value: 'Waiting for the Sunrise',
  },
  {
    label: 'Wallpoet',
    value: 'Wallpoet',
  },
  {
    label: 'Walter Turncoat',
    value: 'Walter Turncoat',
  },
  {
    label: 'Warnes',
    value: 'Warnes',
  },
  {
    label: 'Water Brush',
    value: 'Water Brush',
  },
  {
    label: 'Waterfall',
    value: 'Waterfall',
  },
  {
    label: 'Wavefont',
    value: 'Wavefont',
  },
  {
    label: 'Wellfleet',
    value: 'Wellfleet',
  },
  {
    label: 'Wendy One',
    value: 'Wendy One',
  },
  {
    label: 'Whisper',
    value: 'Whisper',
  },
  {
    label: 'WindSong',
    value: 'WindSong',
  },
  {
    label: 'Wire One',
    value: 'Wire One',
  },
  {
    label: 'Wix Madefor Display',
    value: 'Wix Madefor Display',
  },
  {
    label: 'Wix Madefor Text',
    value: 'Wix Madefor Text',
  },
  {
    label: 'Work Sans',
    value: 'Work Sans',
  },
  {
    label: 'Xanh Mono',
    value: 'Xanh Mono',
  },
  {
    label: 'Yaldevi',
    value: 'Yaldevi',
  },
  {
    label: 'Yanone Kaffeesatz',
    value: 'Yanone Kaffeesatz',
  },
  {
    label: 'Yantramanav',
    value: 'Yantramanav',
  },
  {
    label: 'Yatra One',
    value: 'Yatra One',
  },
  {
    label: 'Yellowtail',
    value: 'Yellowtail',
  },
  {
    label: 'Yeon Sung',
    value: 'Yeon Sung',
  },
  {
    label: 'Yeseva One',
    value: 'Yeseva One',
  },
  {
    label: 'Yesteryear',
    value: 'Yesteryear',
  },
  {
    label: 'Yomogi',
    value: 'Yomogi',
  },
  {
    label: 'Young Serif',
    value: 'Young Serif',
  },
  {
    label: 'Yrsa',
    value: 'Yrsa',
  },
  {
    label: 'Ysabeau',
    value: 'Ysabeau',
  },
  {
    label: 'Ysabeau Infant',
    value: 'Ysabeau Infant',
  },
  {
    label: 'Ysabeau Office',
    value: 'Ysabeau Office',
  },
  {
    label: 'Ysabeau SC',
    value: 'Ysabeau SC',
  },
  {
    label: 'Yuji Boku',
    value: 'Yuji Boku',
  },
  {
    label: 'Yuji Hentaigana Akari',
    value: 'Yuji Hentaigana Akari',
  },
  {
    label: 'Yuji Hentaigana Akebono',
    value: 'Yuji Hentaigana Akebono',
  },
  {
    label: 'Yuji Mai',
    value: 'Yuji Mai',
  },
  {
    label: 'Yuji Syuku',
    value: 'Yuji Syuku',
  },
  {
    label: 'Yusei Magic',
    value: 'Yusei Magic',
  },
  {
    label: 'ZCOOL KuaiLe',
    value: 'ZCOOL KuaiLe',
  },
  {
    label: 'ZCOOL QingKe HuangYou',
    value: 'ZCOOL QingKe HuangYou',
  },
  {
    label: 'ZCOOL XiaoWei',
    value: 'ZCOOL XiaoWei',
  },
  {
    label: 'Zen Antique',
    value: 'Zen Antique',
  },
  {
    label: 'Zen Antique Soft',
    value: 'Zen Antique Soft',
  },
  {
    label: 'Zen Dots',
    value: 'Zen Dots',
  },
  {
    label: 'Zen Kaku Gothic Antique',
    value: 'Zen Kaku Gothic Antique',
  },
  {
    label: 'Zen Kaku Gothic New',
    value: 'Zen Kaku Gothic New',
  },
  {
    label: 'Zen Kurenaido',
    value: 'Zen Kurenaido',
  },
  {
    label: 'Zen Loop',
    value: 'Zen Loop',
  },
  {
    label: 'Zen Maru Gothic',
    value: 'Zen Maru Gothic',
  },
  {
    label: 'Zen Old Mincho',
    value: 'Zen Old Mincho',
  },
  {
    label: 'Zen Tokyo Zoo',
    value: 'Zen Tokyo Zoo',
  },
  {
    label: 'Zeyada',
    value: 'Zeyada',
  },
  {
    label: 'Zhi Mang Xing',
    value: 'Zhi Mang Xing',
  },
  {
    label: 'Zilla Slab',
    value: 'Zilla Slab',
  },
  {
    label: 'Zilla Slab Highlight',
    value: 'Zilla Slab Highlight',
  },
];
