import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {useContext} from 'react';
import ColorItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/ColorItem';
import FontFamilyItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/FontFamilyItem';
import Section from 'scenes/PokeBuilder/components/Section';
import './_Styles.scss';
import {WidgetContentToggler} from './components/ContentToggler';

const Navbar = () => {
  const {
    project,
    setProject,
    changelogTheme: theme,
    setChangelogTheme: setTheme,
  } = useContext(BuilderContext);

  return (
    <div className="block-settings changelog-navbar">
      <Section title="Content">
        <WidgetContentToggler
          contentFlags={project.widgetContentFlags}
          sectionOrder={project.widgetSectionOrder || 'feed;roadmap;feedback'}
          onChange={(widgetContentFlags) => {
            setProject({
              ...project,
              widgetContentFlags,
            });
          }}
          onSectionOrderChange={(widgetSectionOrder) => {
            setProject({
              ...project,
              widgetSectionOrder,
            });
          }}
        />
      </Section>
      <Divider />
      <Section title="Style">
        <FontFamilyItem
          title="Font family"
          value={theme?.navbar?.fontFamily}
          onChange={(value) => {
            setTheme({
              ...theme,
              navbar: {
                ...theme.navbar,
                fontFamily: value,
              },
            });
          }}
        />
        <ColorItem
          title="Color"
          value={theme?.navbar?.fontColor}
          onChange={(value) => {
            setTheme({
              ...theme,
              navbar: {
                ...theme.navbar,
                fontColor: value,
              },
            });
          }}
        />
      </Section>
    </div>
  );
};

export default Navbar;
