import './_Styles.scss';

const EmptyComments =
  'https://assets.usejimo.com/empty-state/empty_comments.svg';
const EmptyCta = 'https://assets.usejimo.com/empty-state/empty_cta.svg';
const EmptyGoal = 'https://assets.usejimo.com/empty-state/empty_goal.svg';
const EmptyIssues = 'https://assets.usejimo.com/empty-state/empty_issues.svg';
const EmptyPosts = 'https://assets.usejimo.com/empty-state/empty_posts.svg';
const EmptyProgressTriggers =
  'https://assets.usejimo.com/empty-state/empty_progress_triggers.svg';
const EmptyRequests =
  'https://assets.usejimo.com/empty-state/empty_requests.svg';
const EmptyResponses =
  'https://assets.usejimo.com/empty-state/empty_responses.svg';
const EmptyResults = 'https://assets.usejimo.com/empty-state/empty_results.svg';
const EmptyTriggers =
  'https://assets.usejimo.com/empty-state/empty_triggers.svg';
const EmptyUsersReached =
  'https://assets.usejimo.com/empty-state/empty_users_reached.svg';

export const EmptyStateImgs = {
  EmptyUsersReached,
  EmptyResults,
  EmptyIssues,
  EmptyResponses,
  EmptyPosts,
  EmptyCta,
  EmptyRequests,
  EmptyGoal,
  EmptyTriggers,
  EmptyComments,
  EmptyProgressTriggers,
};

export const EmptyStateBlock = ({img, title, description}) => (
  <div className="empty-state-block">
    <div className="img-wrapper">
      <img src={img} alt="empty state" />
    </div>
    <div className="empty-state-content">
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </div>
  </div>
);
