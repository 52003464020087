import React, {useState} from 'react';
import ModalCreatePoke, {
  MODE_PREVIEW,
} from 'scenes/Pushes/components/ModalCreatePoke';
import {TemplateBlock} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal';
import Sidebar from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/components/Sidebar';
import {templates} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import './_Styles.scss';

const groups = [
  {
    title: 'Tours & Modals',
    type: EVOLUTION_TYPE_TOUR,
    icon: <i className="isax isax-routing-2" />,
  },
  {
    title: 'Surveys',
    type: EVOLUTION_TYPE_SURVEY,
    icon: <i className="isax isax-note-2" />,
  },
  {
    title: 'Banners',
    type: EVOLUTION_TYPE_BANNER,
    icon: <i className="icon-banner-default" />,
  },
  {
    title: 'Hints',
    type: EVOLUTION_TYPE_HINT,
    icon: <i className="isax isax-notification-1" />,
  },
];

const Templates = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const filteredTemplates = templates().filter((t) => {
    if (selectedType != null && t.experienceType !== selectedType) {
      return false;
    }

    if (selectedUseCase != null && t.tags.includes(selectedUseCase) === false) {
      return false;
    }

    return true;
  });

  let title, subtitle;

  if (selectedType === EVOLUTION_TYPE_TOUR) {
    title = 'Tours';
    subtitle = 'Show your users around';
  } else if (selectedType === EVOLUTION_TYPE_SURVEY) {
    title = 'Surveys';
    subtitle = 'Collect feedback from your users';
  } else if (selectedType === EVOLUTION_TYPE_BANNER) {
    title = 'Banners';
    subtitle = 'Display important information';
  } else if (selectedType === EVOLUTION_TYPE_HINT) {
    title = 'Hints';
    subtitle = 'Highlight important elements';
  } else if (selectedUseCase != null) {
    title = selectedUseCase;
    subtitle = `All ${selectedUseCase.toLowerCase()} templates`;
  } else {
    title = 'All templates';
    subtitle = 'All templates';
  }

  return (
    <div className="s-templates">
      <Sidebar
        type={selectedType}
        useCase={selectedUseCase}
        onChange={({type = null, useCase = null}) => {
          setSelectedType(type);
          setSelectedUseCase(useCase);
        }}
      />
      <div className="templates-content-wrapper">
        <div className="template-picker-wrapper">
          <div className="template-picker-header">
            <div className="title title-3">{title}</div>
            <div className="subtitle body-3 n-500">{subtitle}</div>
          </div>
          {selectedType == null && selectedUseCase == null ? (
            <div className="groups-wrapper">
              {groups.map((group) => {
                const groupTemplates = filteredTemplates.filter(
                  (t) => t.experienceType === group.type
                );

                return (
                  <div className="group" key={group.title}>
                    <div className="group-title subtitle-2 n-700">
                      {group.icon}
                      <span>{group.title}</span>
                    </div>
                    <div className="templates-wrapper">
                      {groupTemplates.map((template) => (
                        <TemplateBlock
                          key={template.title}
                          {...template}
                          onClick={() => setSelectedTemplate(template)}
                        />
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="templates-wrapper">
              {filteredTemplates.map((template) => {
                return (
                  <TemplateBlock
                    key={template.title}
                    {...template}
                    onClick={() => setSelectedTemplate(template)}
                    from="templates_page"
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      {selectedTemplate != null && (
        <ModalCreatePoke
          isOpen={selectedTemplate != null}
          onRequestClose={() => {
            setSelectedTemplate(null);
          }}
          type={selectedTemplate.experienceType}
          defaultMode={MODE_PREVIEW}
          defaultTemplate={selectedTemplate}
          resetDefault={() => {
            setSelectedTemplate(null);
          }}
          onBack={() => {
            setSelectedTemplate(null);
          }}
        />
      )}
    </div>
  );
};

export default Templates;
