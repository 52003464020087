import classNames from 'classnames';
import {htmlToText} from 'html-to-text';
import React, {useContext, useEffect} from 'react';
import {CHECKLIST_TRIGGER_TYPE_FLOAT} from '../../../../../Checklist/constants';
import {CHECKLIST_STEP_TYPE_CHECKLIST} from '../../../../../Checklist/utils';
import ClickableBlockOverlay from '../../../../../Poke/components/ClickableBlockOverlay';
import {
  BLOCK_CHECKLIST_HEADER_TITLE,
  BLOCK_CHECKLIST_TRIGGER,
  BLOCK_RESOURCE_CENTER_TRIGGER,
  BLOCK_RESOURCE_CENTER_TRIGGER_TEXT,
} from '../../../../../Poke/constants/blocks';
import {PokeContext, PokeStateContext} from '../../../../../Poke/context';
import {RESOURCE_CENTER_TRIGGER_TEXT_BEHAVIOR_ACTIVE_CHECKLIST_TITLE} from '../../../../constants';
import './_Styles.scss';

const ResourceCenterTriggerText = () => {
  const {embeddedChecklist} = useContext(PokeContext);
  const {
    onBlockSelected,
    onContainerSelected,
    selectedBlock,
    inBuilder,
    blocks,
    language,
    addFontFamily,
  } = useContext(PokeStateContext);

  const block = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_TRIGGER_TEXT
  );
  const triggerBlock = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_TRIGGER
  );

  const {style, value = '', translations = []} = block || {};
  const [behavior, text] = value.split('|-|');
  const {fontSize, fontWeight, fontColor, fontFamily} = style || {};

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  if (block == null) {
    return <></>;
  }

  const translation = translations.find((t) => t.language === language);

  let displayText = translation?.value || text;

  if (
    inBuilder !== true &&
    behavior === RESOURCE_CENTER_TRIGGER_TEXT_BEHAVIOR_ACTIVE_CHECKLIST_TITLE &&
    embeddedChecklist != null
  ) {
    const checklistStep = embeddedChecklist?.steps?.find(
      (s) => s.type === CHECKLIST_STEP_TYPE_CHECKLIST
    );
    const titleBlock = checklistStep?.blocks?.find(
      (b) => b.type === BLOCK_CHECKLIST_HEADER_TITLE
    );
    const triggerBlock = checklistStep?.blocks?.find(
      (b) => b.type === BLOCK_CHECKLIST_TRIGGER
    );

    const value = titleBlock?.value || '';
    const [title] = value?.split('|-|');

    displayText = htmlToText(title);

    if (
      triggerBlock?.style?.type === CHECKLIST_TRIGGER_TYPE_FLOAT &&
      !!triggerBlock?.value
    ) {
      displayText = triggerBlock?.value;
    }
  }

  return (
    <div
      className={classNames('block-resource-center-trigger-text', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_RESOURCE_CENTER_TRIGGER_TEXT,
        'in-builder': inBuilder === true,
      })}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();
          onBlockSelected(BLOCK_RESOURCE_CENTER_TRIGGER_TEXT);
          onContainerSelected(null);
        }
      }}
      style={{
        fontWeight,
        fontSize,
        color: fontColor,
        fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
      }}>
      {displayText}
      <ClickableBlockOverlay
        colorToUse={triggerBlock?.style?.backgroundColor}
      />
    </div>
  );
};

export default ResourceCenterTriggerText;
