import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {useFileUpload} from 'hooks/useFileUpload';
import {func, string} from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {ElementSelector} from 'scenes/SuccessTracker/components/Event/components/EventConditions/components/EditDropdown/components/ElementSelector';
import {BLOCK_TYPE_HINT} from 'services/steps';
import {Swaler} from 'swaler';
import {HINT_TYPE_HIDDEN} from '../../../blocks/Hint';
import PopupSetting from '../../PopupSetting';
import ClickableInput from '../../items/ClickableInput';
import PixelPicker from '../../items/PixelPicker';
import ZIndex from '../ZIndex';
import './_Styles.scss';
import HintAlignment from './components/HintAlignment';

const propTypes = {
  value: string,
  onChange: func,
  setCloseOnDocumentClick: func,
};

const defaultProps = {
  value: {},
  onChange: () => {},
  setCloseOnDocumentClick: () => {},
};

export const positionsArr = [
  {position: 'top-left', value: 1},
  {position: 'top', value: 2},
  {position: 'top-right', value: 4},
  {position: 'right', value: 8},
  {position: 'bottom-right', value: 16},
  {position: 'bottom', value: 32},
  {position: 'bottom-left', value: 64},
  {position: 'left', value: 128},
  {position: 'right-top', value: 512},
  {position: 'right-bottom', value: 1024},
  {position: 'left-bottom', value: 2048},
  {position: 'left-top', value: 4096},
];

const logger = new Swaler('HintPosition');

const HintPosition = ({
  evolution,
  setEvolution,
  setCloseOnDocumentClick = () => {},
}) => {
  const {upload} = useFileUpload({logger});

  const {
    evolution: parentEvolution,
    isInApp,
    messenger,
    selectedStepId,
    updateBlock: uptBlock,
    selectedStep: step,
  } = useContext(BuilderContext) || {};

  const {goToEditInApp = () => {}, stopInAppEditing = () => {}} =
    useContext(InAppBuilderContext) || {};

  const [showSelectElementModal, setShowSelectElementModal] = useState(false);

  const parentEvolutionRef = useRef();
  const evolutionRef = useRef();

  useEffect(() => {
    setCloseOnDocumentClick(!showSelectElementModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSelectElementModal]);

  useEffect(() => {
    parentEvolutionRef.current = parentEvolution;
  }, [parentEvolution]);

  useEffect(() => {
    evolutionRef.current = evolution;
  }, [evolution]);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_HINT, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_HINT);

  const {style = {}} = block || {};
  const {offsetX, offsetY, type} = style;

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    const response = await fetch(file);
    const blob = await response.blob();
    const uploadableFile = new File([blob], 'upload.png', {type: 'image/png'});
    const uploadedFile = await upload({file: uploadableFile});

    return uploadedFile;
  };

  const handleElementSelected = async (data) => {
    // set loading state
    setEvolution({
      ...evolution,
      ...data.evolution,
      isUploading: true,
    });

    const file = await uploadFile(data.image);

    setEvolution({
      ...evolution,
      ...data.evolution,
      isUploading: false,
      ...(file != null
        ? {
            file,
          }
        : {
            file: null,
          }),
    });
  };

  return (
    <>
      <div className="section-item target-picker">
        <ElementSelector
          data={{
            querySelector: evolution?.boostedQueryselector,
            querySelectors: evolution?.querySelectors,
            file: evolution?.file,
            querySelectorManual: evolution?.querySelectorManual,
          }}
          onChange={(data) => {
            setEvolution({
              ...evolution,
              boostedQueryselector: data.querySelector,
              file: data.file,
              querySelectorManual: data.querySelectorManual,
              querySelectors: data.querySelectors,
            });
          }}
          onModalOpen={(isOpen) => setShowSelectElementModal(isOpen)}
          withElementsCount={isInApp}
          onSelectElement={
            isInApp
              ? messenger.sendSelectTargetElement
              : () =>
                  goToEditInApp({
                    data: {
                      title: 'Select target element',
                    },
                    handshakeData: {
                      type: 'SET_MODE',
                      data: {
                        mode: 'ELEMENT_SELECTOR_BUILDER',
                        onlyInput: false,
                        evolution: parentEvolution,
                        stepId: selectedStepId,
                      },
                    },
                    onChildCommunication: (message) => {
                      if (message?.type === 'TARGET_ELEMENT_SELECTED') {
                        handleElementSelected(message.data);
                        stopInAppEditing();
                      }
                      if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
                        stopInAppEditing();
                      }
                    },
                  })
          }
          isEditingStep
        />
      </div>
      <Divider />
      {type !== HINT_TYPE_HIDDEN && (
        <>
          <HintAlignment
            style={style}
            onChange={(style) =>
              updateBlock({
                style: {
                  ...style,
                },
              })
            }
          />
          <SectionItem title="Offset X">
            <PixelPicker
              value={offsetX}
              min={-400}
              max={400}
              label=""
              onChange={(value) =>
                updateBlock({
                  style: {
                    ...style,
                    offsetX: value,
                  },
                })
              }
            />
          </SectionItem>
          <SectionItem title="Offset Y">
            <PixelPicker
              value={offsetY}
              min={-400}
              max={400}
              label=""
              onChange={(value) =>
                updateBlock({
                  style: {
                    ...style,
                    offsetY: value,
                  },
                })
              }
            />
          </SectionItem>
        </>
      )}
      <div className="section-item z-index">
        <div className="section-item-title">Z-index</div>
        <div className="section-item-content">
          <PopupSetting
            trigger={
              <ClickableInput
                className="position-z-index"
                value={
                  evolution.boostedZIndex != null
                    ? `${evolution.boostedZIndex}`
                    : 'Auto'
                }
                leftLabel={
                  <div className="icon-wrapper">
                    <i className="icon-duplicate" />
                  </div>
                }
              />
            }
            title="Z-index"
            content={
              <ZIndex
                value={evolution.boostedZIndex}
                onChange={(value) =>
                  setEvolution({
                    ...evolution,
                    boostedZIndex: value,
                  })
                }
                setIsOpen={(v) => setCloseOnDocumentClick(!v)}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

HintPosition.propTypes = propTypes;
HintPosition.defaultProps = defaultProps;

export default HintPosition;
