import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {array, func} from 'prop-types';
import React, {useContext} from 'react';
import {
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_RESOURCE_CENTER,
  EVOLUTION_TYPE_SURVEY,
  F_BOOST_SLOT_NAVIGATION,
} from 'services/evolution';
import {BLOCK_TYPE_CHECKLIST_TASK_ITEM} from 'services/steps';
import {blocksObj} from '../Navigator';
import {MODE_NAVIGATOR, MODE_TRIGGERS} from '../PokeBuilderSidebar';
import './_Styles.scss';

const propTypes = {
  issues: array,
  onClose: func,
};

const defaultProps = {
  issues: [],
  onClose: () => {},
};

const TroubleShootingMenu = ({issues, onClose}) => {
  const {
    evolution,
    setSelectedTourStepId,
    setSelectedStepId,
    setSelectedBlockType,
    setMode,
    setSelectedSubItemId,
  } = useContext(BuilderContext);

  const isChecklist = evolution.type === EVOLUTION_TYPE_CHECKLIST;
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;
  const isResourceCenter = evolution.type === EVOLUTION_TYPE_RESOURCE_CENTER;
  const steps =
    isSurvey || isChecklist || isResourceCenter
      ? evolution.steps.filter((s) => s.removed !== true)
      : evolution.tourSteps
          .map((ts) =>
            ts.steps.map((s) => ({
              ...s,
              tourStepIndex: parseInt(ts.tourStepInfo.split(';')[0], 10),
            }))
          )
          .flat()
          .filter((s) => s.removed !== true);
  const blocks = steps.map((s) => s.blocks || []).flat();

  const issuesCount = issues.reduce((acc, issue) => {
    return acc + issue.issues.length;
  }, 0);

  return (
    <div className="troubleshooting-menu">
      <div className="troubleshooting-menu-header">
        <div className="troubleshooting-menu-title">
          {issuesCount} issue{issuesCount > 1 ? 's' : ''} detected
        </div>
        <i className="icon-close" onClick={onClose} />
      </div>
      <Divider />
      <div className="issues-list">
        {issues.map((e, i) => {
          if (!e.issues?.length > 0) {
            return null;
          }
          return (
            <div>
              {e.issues?.map((issue) => {
                let poke = null,
                  step = null;

                if (e.tourStepId != null) {
                  poke = evolution?.tourSteps?.find(
                    (ts) => ts.uid === e.tourStepId
                  );
                  if (e.stepId != null) {
                    step =
                      poke?.steps?.find((s) => s.uid === e.stepId) ||
                      poke?.steps
                        .map((s) => s.prototypes?.[0]?.steps)
                        .flat()
                        .find((s) => s?.uid === e.stepId);
                  }
                }

                const {iconClassName, name} =
                  blocksObj.find((b) => b.type === issue.blockType) ?? {};

                const isNavigationStep =
                  hasFlag(F_BOOST_SLOT_NAVIGATION, poke?.boostFlags) === true;

                return (
                  <div
                    className="issue"
                    onClick={() => {
                      if (e.tourStepId != null || e.stepId != null) {
                        setSelectedTourStepId(e.tourStepId);
                        setSelectedStepId(e.stepId);
                        setSelectedBlockType(issue.blockType);

                        if (issue.blockId != null) {
                          const block = blocks.find(
                            (b) => b.uid === issue.blockId
                          );

                          if (block?.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM) {
                            setSelectedSubItemId(block.uid);
                          } else if (issue.parentBlockId) {
                            setSelectedSubItemId(issue.parentBlockId);
                          } else if (block?.parentBlockId) {
                            setSelectedSubItemId(block.parentBlockId);
                          }
                        }

                        if (issue.triggerId != null) {
                          setMode(MODE_TRIGGERS);
                        } else {
                          setMode(MODE_NAVIGATOR);
                        }
                      }
                      onClose();
                    }}>
                    <div className="issue-icon">
                      <i
                        className={
                          iconClassName || 'icon-exclamation-triangle-o'
                        }
                      />
                    </div>
                    <div className="issue-content">
                      <div className="issue-title">
                        {issue?.issues?.join(', ')}
                      </div>

                      <div className="issue-location">
                        {step != null
                          ? step?.name || 'Unnamed step'
                          : isNavigationStep
                          ? 'Navigation step'
                          : 'Poke'}{' '}
                        &bull;{' '}
                        {issue.triggerId != null
                          ? 'Progress triggers'
                          : name || 'Step'}
                      </div>
                    </div>
                    <i className="icon-arrow-right" />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

TroubleShootingMenu.propTypes = propTypes;
TroubleShootingMenu.defaultProps = defaultProps;

export default TroubleShootingMenu;
