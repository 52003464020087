import {Modal} from 'components/Modal';
import {bool, func} from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Poke} from 'scenes/Poke';
import {getPokeRoute} from 'scenes/PokeAudience/components/AudienceHeader';
import './_Styles.scss';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
};

const ExperienceDrawer = ({isOpen, onRequestClose, experience, ...rest}) => {
  const history = useHistory();

  const route = getPokeRoute(experience.type);

  return (
    <Modal
      className="experience-drawer fade-in-right"
      overlayClassName="experience-drawer-overlay"
      isOpen={isOpen}
      {...rest}
      onRequestClose={onRequestClose}>
      <Poke evolutionId={experience.uid} />
      <div className="experience-drawer-detached-actions">
        <div className="btn" onClick={onRequestClose}>
          <i className="icon-close" />
        </div>
        <div
          className="btn"
          onClick={() => {
            history.push(route(experience.uid));
          }}>
          <i className="isax isax-export-3" />
        </div>
      </div>
    </Modal>
  );
};

ExperienceDrawer.propTypes = propTypes;

export default ExperienceDrawer;
