import {Icon as IconifyIcon, loadIcons} from '@iconify/react';
import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import DefaultLoader from 'components/Loader';
import RadioGroup from 'components/RadioGroup';
import {useFileUpload} from 'hooks/useFileUpload';
import {func, object} from 'prop-types';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList as List} from 'react-window';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {fileService} from 'services';
import {BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON} from 'services/steps';
import {
  RESOURCE_CENTER_ICON_SOURCE_CUSTOM,
  RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
  RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
} from 'shared/front/components/ResourceCenter/constants';
import {Swaler} from 'swaler';
import './_Styles.scss';
import {phosphorIcons} from './phosphor.utils';
import useTwemojiList from './useTwemojiList';

const logger = new Swaler('HintIcons');

const propTypes = {
  style: object,
  file: object,
  onChange: func,
};

const iconSourceOptions = [
  {label: 'Phosphor', value: RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR},
  {label: 'Twemoji', value: RESOURCE_CENTER_ICON_SOURCE_TWEMOJI},
  {label: 'Custom', value: RESOURCE_CENTER_ICON_SOURCE_CUSTOM},
];

const ICON_SIZE = 120; // Approximate size of each icon item
const ROW_HEIGHT = ICON_SIZE + 16; // Item height + padding

const Icon = ({icon, url, label, onClick, selected}) => {
  return (
    <div
      className={classNames('item-icon-wrapper', {
        selected: selected,
      })}
      onClick={onClick}>
      <div className="item-icon">
        {icon && React.cloneElement(icon, {className: 'phosphor-icon'})}
        {url && <img src={url} alt="" />}
      </div>
      {label && <div className="label body-4 n-700">{label}</div>}
    </div>
  );
};

const PhosphorRow = ({data, index, style}) => {
  const itemsPerRow = 3;
  const startIndex = index * itemsPerRow;
  const endIndex = Math.min(startIndex + itemsPerRow, data.items.length);
  const itemWidth = `${100 / itemsPerRow}%`;

  return (
    <div style={style} className="phosphor-row">
      {data.items.slice(startIndex, endIndex).map((iconItem) => (
        <div
          key={iconItem.name}
          style={{width: itemWidth, display: 'inline-block'}}>
          <Icon
            icon={
              <IconifyIcon
                icon={`ph:${iconItem.name}`}
                className="phosphor-icon"
                width={32}
                height={32}
              />
            }
            label={iconItem.name
              ?.split('-')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
            onClick={() =>
              data.onChange({
                style: {
                  ...data.style,
                  iconSource: RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
                  iconUrl: null,
                  iconName: iconItem.name,
                },
                file: null,
              })
            }
            selected={data.style.iconName === iconItem.name}
          />
        </div>
      ))}
    </div>
  );
};

const TwemojiRow = ({data, index, style}) => {
  const itemsPerRow = 3;
  const startIndex = index * itemsPerRow;
  const endIndex = Math.min(startIndex + itemsPerRow, data.items.length);
  const itemWidth = `${100 / itemsPerRow}%`;

  return (
    <div style={style} className="twemoji-row">
      {data.items.slice(startIndex, endIndex).map((item) => {
        const {value, label} = item;
        return (
          <div key={value} style={{width: itemWidth, display: 'inline-block'}}>
            <Icon
              icon={
                <IconifyIcon
                  icon={`twemoji:${value}`}
                  className="twemoji-icon"
                  width={32}
                  height={32}
                />
              }
              label={label}
              onClick={() =>
                data.onChange({
                  style: {
                    ...data.style,
                    iconSource: RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
                    iconUrl: null,
                    iconName: value,
                  },
                  file: null,
                })
              }
              selected={data.style.iconName === value}
            />
          </div>
        );
      })}
    </div>
  );
};

const ResourceCenterIconPicker = ({style, file, onChange}) => {
  const {upload} = useFileUpload({logger});

  const [isUploading, setIsUploading] = useState(false);
  const [source, setSource] = useState(
    style.iconSource || RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR
  );
  const [search, setSearch] = useState('');

  const refInputFile = useRef();
  const fileType = BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON;

  useEffect(() => {
    loadIcons([
      ...phosphorIcons.map((icon) => `ph:${icon.name}`),
      ...twemojiIcons.map((icon) => `twemoji:${icon.label}`),
    ]);
  }, []);

  const twemojiIcons = useTwemojiList();

  const {
    data: recentFiles,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['recentFiles', fileType],
    queryFn: () => fileService.getFiles({type: fileType, limit: 10}),
    refetchOnWindowFocus: false,
  });

  const handleFileChange = async ({target}) => {
    if (!target.files || target.files.length === 0) {
      return;
    }
    setIsUploading(true);
    const file = await upload({
      file: target.files[0],
      blockType: fileType,
    });
    setIsUploading(false);

    if (file != null) {
      onChange({
        style: {
          ...style,
          iconUrl: file.publicUrl,
        },
        file,
      });
    }
  };

  const customList =
    recentFiles?.map((file) => ({
      url: file.publicUrl,
      file: file,
    })) || [];

  const filteredPhosphorIconList = useMemo(() => {
    if (!search) {
      return phosphorIcons;
    }

    const searchTerms = search.toLowerCase().split(/\s+/);

    return phosphorIcons.filter((item) => {
      const searchText = item.name?.replace(/-/g, ' ').toLowerCase();
      return searchTerms.every((term) => searchText.includes(term));
    });
  }, [search]);

  const filteredTwemojiList = useMemo(() => {
    if (!search) {
      return twemojiIcons;
    }

    const searchTerms = search.toLowerCase().split(/\s+/);

    return twemojiIcons.filter((item) => {
      const searchText = item.label.toLowerCase();
      return searchTerms.every((term) => searchText.includes(term));
    });
  }, [search, twemojiIcons]);

  const searchPlaceholder =
    source === RESOURCE_CENTER_ICON_SOURCE_TWEMOJI
      ? 'Search Twemoji icons...'
      : 'Search Phosphor icons...';

  return (
    <>
      <SectionItem>
        <RadioGroup
          value={source}
          options={iconSourceOptions}
          onSelect={(v) => setSource(v)}
        />
      </SectionItem>
      {source !== RESOURCE_CENTER_ICON_SOURCE_CUSTOM && (
        <InputGroup
          className="search-input"
          value={search}
          onChange={({target}) => setSearch(target.value)}
          placeholder={searchPlaceholder}
          labelTextLeft={<i className="icon-search n-500" />}
        />
      )}
      <Divider />
      <div className="picker-icon-content">
        {source === RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR && (
          <div className="phosphor-list">
            <AutoSizer>
              {({height, width}) => {
                const itemsPerRow = 3;
                const rowCount = Math.ceil(
                  filteredPhosphorIconList.length / itemsPerRow
                );

                return (
                  <List
                    height={height}
                    width={width}
                    itemCount={rowCount}
                    itemSize={ROW_HEIGHT}
                    itemData={{
                      items: filteredPhosphorIconList,
                      style,
                      onChange,
                      containerWidth: width,
                    }}>
                    {PhosphorRow}
                  </List>
                );
              }}
            </AutoSizer>
          </div>
        )}
        {source === RESOURCE_CENTER_ICON_SOURCE_TWEMOJI && (
          <div className="twemoji-list">
            <AutoSizer>
              {({height, width}) => {
                const itemsPerRow = 3;
                const rowCount = Math.ceil(
                  filteredTwemojiList.length / itemsPerRow
                );

                return (
                  <List
                    height={height}
                    width={width}
                    itemCount={rowCount}
                    itemSize={ROW_HEIGHT}
                    itemData={{
                      items: filteredTwemojiList,
                      style,
                      onChange,
                      containerWidth: width,
                    }}>
                    {TwemojiRow}
                  </List>
                );
              }}
            </AutoSizer>
          </div>
        )}
        {source === RESOURCE_CENTER_ICON_SOURCE_CUSTOM && (
          <>
            {isLoading ? (
              <div className="loading-wrapper">
                <DefaultLoader />
              </div>
            ) : customList.length === 0 ? (
              <div className="empty-state-wrapper body-3 n-700">
                Upload icons from your computer to use them in Jimo (PNG, JPG,
                and SVG)
              </div>
            ) : (
              <div className="custom-list">
                {customList.map((icon) => (
                  <Icon
                    key={icon.url}
                    url={icon.url}
                    onClick={() =>
                      onChange({
                        style: {
                          ...style,
                          iconSource: RESOURCE_CENTER_ICON_SOURCE_CUSTOM,
                          iconName: null,
                          iconUrl: icon.url,
                        },
                        file: icon.file,
                      })
                    }
                    selected={style.iconUrl === icon.url}
                  />
                ))}
              </div>
            )}
            <Divider />
            <div className="action-wrapper">
              <input
                ref={refInputFile}
                type="file"
                accept="image/svg+xml, image/png, image/jpeg"
                style={{display: 'none'}}
                onChange={handleFileChange}
              />
              <Button
                thin
                iconLeft="isax isax-document-upload"
                onClick={() => refInputFile.current.click()}
                disabled={isUploading}>
                Upload
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

ResourceCenterIconPicker.propTypes = propTypes;

export default ResourceCenterIconPicker;
