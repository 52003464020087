import RadioGroup from 'components/RadioGroup';
import ColorItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/ColorItem';
import FontFamilyItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/FontFamilyItem';
import RadiusItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/RadiusItem';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';

const alignOptions = [
  {label: <i className="icon-align-b-l" />, value: 'left'},
  {label: <i className="icon-align-b-c" />, value: 'center'},
  {label: <i className="icon-align-b-r" />, value: 'right'},
];

const ChangelogCtaStyle = ({style, setStyle}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      ...style,
      ...newStyle,
    });
  };

  return (
    <>
      <ColorItem
        title="Background"
        value={style.backgroundColor}
        onChange={(value) => updateStyle({backgroundColor: value})}
      />
      <ColorItem
        title="Border"
        value={style.borderColor}
        onChange={(value) => updateStyle({borderColor: value})}
      />
      <RadiusItem
        title="Radius"
        value={style.borderRadius}
        onChange={(value) => updateStyle({borderRadius: value})}
        max={50}
      />

      <SectionItem title="Position">
        <RadioGroup
          value={style.align}
          options={alignOptions}
          onSelect={(value) =>
            updateStyle({
              ...style,
              align: value,
            })
          }
        />
      </SectionItem>
      <ColorItem
        title="Text color"
        value={style.fontColor}
        onChange={(value) => updateStyle({fontColor: value})}
      />

      <FontFamilyItem
        title="Font family"
        value={style.fontFamily}
        onChange={(value) => updateStyle({fontFamily: value})}
      />
    </>
  );
};

export default ChangelogCtaStyle;
