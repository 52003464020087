import classNames from 'classnames';
import {array, object} from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import SurveyDetails from 'scenes/Users/components/ModalJimerProfile/components/SurveyDetails';
import './_Styles.scss';
import TimelineItem from './components/TimelineItem';
import {groupItemsByDate} from './utils';

const propTypes = {
  jimer: object.isRequired,
  items: array,
};

const Timeline = ({jimer, items}) => {
  const [surveyDetailsId, setSurveyDetailsId] = useState(null);
  const groupedItems = groupItemsByDate(items);
  const timelineRef = useRef(null);
  const traitRef = useRef(null);
  const firstDotRef = useRef(null);
  const lastDotRef = useRef(null);

  // Effect to set the correct height of the timeline trait
  useEffect(() => {
    if (
      timelineRef.current &&
      traitRef.current &&
      firstDotRef.current &&
      lastDotRef.current &&
      groupedItems.length > 0
    ) {
      const timelineElement = timelineRef.current;
      const traitElement = traitRef.current;
      const firstDot = firstDotRef.current;
      const lastDot = lastDotRef.current;

      // Get the positions relative to the timeline container
      const firstDotRect = firstDot.getBoundingClientRect();
      const lastDotRect = lastDot.getBoundingClientRect();
      const timelineRect = timelineElement.getBoundingClientRect();

      // Calculate the distance between the first and last dot centers
      const firstDotCenter =
        firstDotRect.top + firstDotRect.height / 2 - timelineRect.top;
      const lastDotCenter =
        lastDotRect.top + lastDotRect.height / 2 - timelineRect.top;

      // Set the height and position to connect centers of first and last dots
      const height = lastDotCenter - firstDotCenter;
      traitElement.style.height = `${height}px`;
      traitElement.style.top = `${firstDotCenter}px`;
    }
  }, [groupedItems, surveyDetailsId]);

  return (
    <div
      className={classNames('timeline-wrapper', {
        'no-padding': surveyDetailsId != null,
      })}>
      {surveyDetailsId != null ? (
        <SurveyDetails
          surveyId={surveyDetailsId}
          onBack={() => setSurveyDetailsId(null)}
        />
      ) : (
        <div className="timeline" ref={timelineRef}>
          {groupedItems.length > 0 && (
            <div className="timeline-trait" ref={traitRef} />
          )}
          {groupedItems.map((group, groupIndex) => (
            <div key={groupIndex} className="timeline-group">
              <div className="timeline-date-header">
                <div
                  className="timeline-dot"
                  ref={
                    groupIndex === 0
                      ? firstDotRef
                      : groupIndex === groupedItems.length - 1
                      ? lastDotRef
                      : null
                  }
                />
                <div className="timeline-date-label subtitle-3 n-800">
                  {group.dateLabel}
                </div>
              </div>
              <div className="timeline-group-items">
                {group.items.map((item) => (
                  <TimelineItem
                    key={item.uid}
                    jimer={jimer}
                    item={item}
                    items={items}
                    setSurveyView={setSurveyDetailsId}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Timeline.propTypes = propTypes;

export default Timeline;
