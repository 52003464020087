import classnames from 'classnames';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {fileService} from 'services';
import {Swaler} from 'swaler';
import './_styles.scss';

const logger = new Swaler('RecentFiles');

export const RecentFiles = ({
  type = null,
  currentFileUrl = null,
  size = 6,
  hasTinyThumbnails = false,
  onSelect = () => {},
  onDelete = () => {},
}) => {
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);

  const {data: recentFiles, refetch} = useQuery({
    queryKey: ['recentFiles', type],
    queryFn: () => fileService.getFiles({type}),
    refetchOnWindowFocus: false,
  });

  const handleDelete = async () => {
    const file = showDeleteConfirm;

    setDeleting(true);
    try {
      await fileService.deletePublicFile(file.uid);
      await refetch();
      if (currentFileUrl === file.publicUrl) {
        onDelete();
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to delete file', code);
      toastDanger([title, message], {actions});
      return null;
    } finally {
      setDeleting(false);
      setShowDeleteConfirm(null);
    }
  };

  const recentFilesSized = Array(size).fill(null);

  for (let i = 0; i < recentFilesSized?.length; i++) {
    recentFilesSized[i] = recentFiles?.[i];
  }

  return (
    <div
      className={classnames('recent-uploads-wrapper', {
        'has-tiny-thumbnails': hasTinyThumbnails,
      })}>
      {showDeleteConfirm != null && (
        <div className="delete-recent-file-wrapper">
          <div className="text-wrapper body-4">
            Are you sure? If this file is used in other experiences, it will
            still be deleted.
          </div>
          <div className="actions-wrapper">
            <Button
              disabled={deleting}
              text="No"
              onClick={() => setShowDeleteConfirm(null)}
              thin
            />
            <Button
              text="Yes, delete"
              onClick={handleDelete}
              primary
              loading={deleting}
              danger
              thin
            />
          </div>
        </div>
      )}
      <div className="recent-label">Or select from your last uploads</div>
      <div className="recent-uploads-list">
        {recentFilesSized?.map((file) => {
          if (file == null) {
            return (
              <div className="recent-upload placeholder">
                <div className="recent-upload-placeholder"></div>
              </div>
            );
          }

          const isSelected = currentFileUrl === file.publicUrl;

          return (
            <div
              className={classnames('recent-upload', {
                selected: isSelected,
              })}
              onClick={() => onSelect(file)}>
              <img src={file.publicUrl} alt="Media" />
              <div
                className="icon-wrapper"
                onClick={() => setShowDeleteConfirm(file)}>
                <i className="isax isax-trash"></i>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
