import dayjs from 'dayjs';
import {hasFlag} from 'helpers/bitwise';
import {PokePreview, PokeType} from 'scenes/Pushes/components/PushesTable';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  F_OPTION_SHOW_ON_PORTAL,
  F_OPTION_V2,
} from 'services/evolution';
import './_Styles.scss';

const SpaceExperiencePreview = ({experience}) => {
  const {title, isDraft, state, lastStepChangeAt, expiresAt, tags, type} =
    experience;

  experience.tourSteps?.forEach((s) => {
    const [tourIndexOrder] = (s.tourStepInfo || '0;0;0').split(';');
    s.tourIndexOrder = parseInt(tourIndexOrder, 10);
  });
  experience.tourSteps?.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);

  const isLive =
    isDraft !== true && (state === EVOLUTION_STATE_LIVE || state == null);
  const isPaused = isDraft !== true && state === EVOLUTION_STATE_PAUSED;
  const isScheduled =
    isDraft !== true && dayjs(lastStepChangeAt).isAfter(dayjs());
  const isExpired =
    isDraft !== true && expiresAt != null && dayjs(expiresAt).isBefore(dayjs());
  const isOldVersionExperience =
    hasFlag(F_OPTION_V2, experience.optionsFlags) !== true;

  const previewPoke =
    experience?.tourSteps?.filter((ts) =>
      ts.steps?.some((s) => s.removed !== true)
    )[0] || experience;
  const isBoosted =
    hasFlag(F_OPTION_SHOW_ON_PORTAL, experience.optionsFlags) === true;

  previewPoke.steps = previewPoke.steps?.sort(
    (a, b) => a.indexOrder - b.indexOrder
  );

  return (
    <div className="space-experience-preview">
      <PokePreview
        poke={experience}
        previewPoke={previewPoke}
        isBoosted={isBoosted}
        isOldVersionPoke={isOldVersionExperience}
        isExpired={isExpired}
        isScheduled={isScheduled}
        isLive={isLive}
        isPaused={isPaused}
      />
      <div className="infos">
        <div className="experience-title-wrapper">
          <div className="experience-title subtitle-4 n-800">{title}</div>
          <PokeType type={type} />
        </div>
      </div>
    </div>
  );
};

export default SpaceExperiencePreview;
