import {useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min';
import {
  ROUTE_BUILDER_AUDIENCE,
  ROUTE_BUILDER_CHANNEL,
  ROUTE_BUILDER_CREATE,
  ROUTE_BUILDER_EDIT,
  ROUTE_BUILDER_TRACKER,
  ROUTE_LIGHTWEIGHT_ELEMENT_SELECTOR_BUILDER,
  ROUTE_LIGHTWEIGHT_EXTENSION_BUILDER,
  ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID,
  ROUTE_LIGHTWEIGHT_TRACKER_BUILDER_WITH_ID,
  ROUTE_POKE_BUILDER_FROM_TYPE,
} from 'router/routes.const';

export const startCrispThread = (message) => {
  window.$crisp.push(['do', 'chat:show']);
  window.$crisp.push(['do', 'chat:open']);
  window.$crisp.push(['set', 'message:text', [].concat(message)]);
};

export const CrispTrigger = () => {
  const match = useRouteMatch();

  if (
    [
      ROUTE_BUILDER_CREATE(),
      ROUTE_BUILDER_CHANNEL(),
      ROUTE_BUILDER_AUDIENCE(),
      ROUTE_BUILDER_EDIT(),
      ROUTE_POKE_BUILDER_FROM_TYPE(),
      ROUTE_LIGHTWEIGHT_ELEMENT_SELECTOR_BUILDER,
      ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID(),
      ROUTE_LIGHTWEIGHT_EXTENSION_BUILDER,
      ROUTE_LIGHTWEIGHT_TRACKER_BUILDER_WITH_ID(),
      ROUTE_BUILDER_TRACKER(),
    ].includes(match.path)
  ) {
    window.$crisp.push(['do', 'chat:hide']);
  } else {
    window.$crisp.push(['do', 'chat:show']);
  }
};
