import Button from 'components/Button';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_SETTINGS_INTEGRATIONS_INTERCOM} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {intercomService} from 'services';
import {Swaler} from 'swaler';
import IntercomLogo from '../imgs/intercom.png';
import './_Styles.scss';

const logger = new Swaler('SettingsIntegrationsIntercom');

const Intercom = () => {
  const history = useHistory();

  const project = useSelector(() => generalSelector.getProject());

  const {intercom} = project;
  const intercomActive = !!intercom?.access_token;

  const [isIntercomLoading, setIsIntercomLoading] = useState(false);

  const handleConnectIntercom = async () => {
    setIsIntercomLoading(true);
    const url = await intercomService.generateUrl(project.uid);

    window.open(url, '_self', 'noopener,noreferrer');
  };

  return (
    <div className="settings-card integration-intercom">
      <div className="logo-action-wrapper">
        <img
          className="logo intercom-logo"
          src={IntercomLogo}
          alt="intercom-logo"
        />
        {!intercomActive ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isIntercomLoading}
            onClick={handleConnectIntercom}>
            Enable
          </Button>
        ) : (
          <>
            <Button
              iconLeft="isax isax-setting-2"
              thin
              onClick={() =>
                history.push(ROUTE_SETTINGS_INTEGRATIONS_INTERCOM)
              }>
              Configure
            </Button>
          </>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Intercom{' '}
          {intercomActive === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Enabled
            </div>
          )}
        </div>
        <div className="body-3">
          Use intercom to allow your users to access Jimo within the messenger
        </div>
      </div>
    </div>
  );
};

export default Intercom;
