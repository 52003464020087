import classNames from 'classnames';
import React, {useContext} from 'react';
import isURL from 'validator/lib/isURL';
import {hasFlag} from '../../../../../../helpers/bitwise';
import {
  F_BLOCK_ZOOM_ON_MEDIA_CLICK,
  getEmbedUrl,
  MEDIA_SCALE_TYPE_FILL,
  MEDIA_SCALE_TYPE_FIT,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  parseMediaValue,
} from '../../../../../Poke/components/BlockMedia';
import ClickableBlockOverlay from '../../../../../Poke/components/ClickableBlockOverlay';
import {BLOCK_RESOURCE_CENTER_ACTION_MEDIA} from '../../../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../../../Poke/context';
import './_Styles.scss';

const ResourceCenterActionMedia = ({block}) => {
  const {
    onBlockSelected,
    onContainerSelected,
    selectedBlock,
    selectedContainerId,
    inBuilder,
    onImageClick = () => {},
    language,
  } = useContext(PokeStateContext);

  const value = parseMediaValue(block.value);
  const {height = 200, ...restStyle} = block.style ?? {};

  const hasZoomOnMediaClick = hasFlag(F_BLOCK_ZOOM_ON_MEDIA_CLICK, block.flags);

  const defaultImageUrl =
    'https://dummyimage.com/640x360/dedede/aaa&text=media';

  const translation = block.translations?.find((t) => t.language === language);
  const valueUrl = translation?.value || value.url;

  const renderImage = () => {
    return (
      <>
        {value.scaleType === MEDIA_SCALE_TYPE_FILL && (
          <div
            className="media-file"
            style={{
              ...restStyle,
              backgroundImage: `url(${valueUrl || defaultImageUrl})`,
              backgroundSize:
                value.scaleType === MEDIA_SCALE_TYPE_FIT ? 'contain' : 'cover',
            }}
          />
        )}
        {value.scaleType === MEDIA_SCALE_TYPE_FIT && (
          <img
            src={valueUrl}
            alt={value.altText}
            style={{
              ...restStyle,
            }}
          />
        )}
        {hasZoomOnMediaClick === true && <i className="icon-search" />}
      </>
    );
  };
  const renderVideo = () => {
    const {player, url} = getEmbedUrl(valueUrl);

    if (player == null) {
      if (isURL(url || '') === true) {
        return (
          <iframe
            src={url}
            title="embedded video"
            allow="fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              ...restStyle,
            }}
          />
        );
      }
      return <></>;
    }
    return player === 'vimeo' ? (
      <div
        style={{
          position: 'relative',
          height: '100%',
        }}>
        <iframe
          src={url}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            ...restStyle,
          }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="embedded video"
        />
      </div>
    ) : (
      <iframe
        src={url}
        title="embedded video"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{
          ...restStyle,
        }}
      />
    );
  };

  return (
    <div
      className={classNames('resource-center-action-media', {
        'poke-block-clickable': inBuilder === true,
        'is-image': value.type === MEDIA_TYPE_IMAGE,
        'is-video': value.type === MEDIA_TYPE_VIDEO,
        'is-fit': value.scaleType === MEDIA_SCALE_TYPE_FIT,
        selected:
          selectedBlock === BLOCK_RESOURCE_CENTER_ACTION_MEDIA &&
          selectedContainerId === block.parentBlockId,
        'is-clickable':
          value.type === MEDIA_TYPE_IMAGE && hasZoomOnMediaClick === true,
      })}
      style={{height}}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();
          onBlockSelected(BLOCK_RESOURCE_CENTER_ACTION_MEDIA);
          onContainerSelected(block.parentBlockId);
          return;
        }

        if (value.type === MEDIA_TYPE_IMAGE && hasZoomOnMediaClick === true) {
          onImageClick(valueUrl);
        }
      }}>
      {value.type === MEDIA_TYPE_IMAGE && renderImage()}
      {value.type === MEDIA_TYPE_VIDEO && renderVideo()}

      <ClickableBlockOverlay withoutOverflow />
    </div>
  );
};

export default ResourceCenterActionMedia;
