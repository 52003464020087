import dayjs from 'dayjs';
import {object} from 'prop-types';
import React from 'react';
import './_Styles.scss';

const propTypes = {
  jimer: object,
};

const InfoBarItem = ({icon, title, value}) => {
  return (
    <div className="info-bar-item">
      <div className="info-bar-item-icon">
        <i className={icon} />
      </div>
      <div className="info-bar-item-content">
        <div className="info-bar-item-title body-3 n-600">{title}</div>
        <div className="info-bar-item-value subtitle-3 n-800">{value}</div>
      </div>
    </div>
  );
};
const InfoBar = ({jimer = {}}) => {
  const {createdAt, lastActivityAt, analytics} = jimer;

  const experiencesSeenCount =
    (analytics?.uniqueTourStartedCount || 0) +
    (analytics?.checklistStartedCount || 0) +
    (analytics?.uniqueHintGroupSeenCount || 0) +
    (analytics?.uniqueResourceCenterOpenedCount || 0) +
    (analytics?.uniqueSurveyStartedCount || 0) +
    (analytics?.uniqueBannerSeenCount || 0);

  return (
    <div className="info-bar">
      <InfoBarItem
        icon="isax isax-medal-star5"
        title="First seen"
        value={dayjs(createdAt).format('DD MMM YYYY')}
      />
      <InfoBarItem
        icon="isax isax-message-notif5"
        title="Last seen"
        value={dayjs(lastActivityAt).format('DD MMM YYYY')}
      />
      <InfoBarItem
        icon="isax isax-mouse-circle"
        title="Experiences seen"
        value={experiencesSeenCount}
      />
    </div>
  );
};

InfoBar.propTypes = propTypes;

export default InfoBar;
