import classNames from 'classnames';
import React, {useContext, useEffect} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {
  BLOCK_CHECKLIST_HEADER_PROGRESS,
  BLOCK_CHECKLIST_TASK_ITEM,
} from '../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../Poke/context';
import './_Styles.scss';

const CHECKLIST_HEADER_PROGRESS_BAR = 'bar';
const CHECKLIST_HEADER_PROGRESS_STEPS = 'steps';

const ChecklistProgress = () => {
  const {
    addFontFamily,
    blocks,
    onBlockSelected,
    onTaskSelected,
    selectedBlock,
    inBuilder,
    completedTasks,
  } = useContext(PokeStateContext);

  const block = blocks.find((b) => b.type === BLOCK_CHECKLIST_HEADER_PROGRESS);
  const taskItemBlocks = blocks.filter(
    (b) => b.type === BLOCK_CHECKLIST_TASK_ITEM
  );

  const completedSteps =
    inBuilder === true
      ? 1
      : taskItemBlocks.filter((b) => completedTasks.includes(b.uid)).length;
  const taskCount = blocks.filter(
    (b) => b.type === BLOCK_CHECKLIST_TASK_ITEM
  ).length;

  const {value, style} = block;
  const {fontFamily, fontSize, primaryColor, secondaryColor} = style;

  useEffect(() => {
    addFontFamily(fontFamily);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontFamily]);

  const isBar = value === CHECKLIST_HEADER_PROGRESS_BAR;
  const isSteps = value === CHECKLIST_HEADER_PROGRESS_STEPS;

  const pct = parseInt((completedSteps / taskCount) * 100);

  return (
    <div
      className={classNames('checklist-progress', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_CHECKLIST_HEADER_PROGRESS,
      })}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();

          onBlockSelected(BLOCK_CHECKLIST_HEADER_PROGRESS);
          onTaskSelected(null);
        }
      }}>
      {isBar && (
        <div
          className="progress-bar-container"
          style={{
            backgroundColor: secondaryColor,
          }}>
          <div
            className="progress-bar"
            style={{
              width: `${pct}%`,
              backgroundColor: primaryColor,
            }}
          />
        </div>
      )}
      {isSteps && (
        <div className="progress-steps">
          {Array.from({length: taskCount}).map((_, index) => (
            <div
              key={index}
              className={classNames('progress-step', {
                completed: index < completedSteps,
              })}
              style={{
                background: `linear-gradient(to left, ${secondaryColor} 50%, ${primaryColor} 50%) right`,
                backgroundSize: '200%',
              }}
            />
          ))}
        </div>
      )}
      <span
        className="progress-count"
        style={{
          fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
          fontSize,
          color: primaryColor,
        }}>
        {isBar && `${pct}%`}
        {isSteps && (
          <>
            {completedSteps} of {taskCount}
          </>
        )}
      </span>

      <ClickableBlockOverlay />
    </div>
  );
};

export default ChecklistProgress;
