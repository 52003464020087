import {InteractiveOption} from 'components/InteractiveOption';
import Label from 'components/Label';
import SharableLinkModal from 'components/SharableLinkModal';
import {addFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext, useState} from 'react';
import {AudienceContext, SECTION_TRIGGER} from 'scenes/PokeAudience';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import {
  F_OPTION_IGNORE_RATE_LIMITING,
  PAGE_ACTIVE_OPERATOR_EVERYWHERE,
  TRIGGER_TYPE_DEFAULT,
  TRIGGER_TYPE_EVENT,
  TRIGGER_TYPE_LEAVE_PAGE,
  TRIGGER_TYPE_MANUAL,
  TRIGGER_TYPE_NONE,
  TRIGGER_TYPE_URL,
} from 'services/evolution';
import './_Styles.scss';
import EventModal from './components/EventModal';
import ManualTriggerModal from './components/ManualTriggerModal';
import PageOpeningModal from './components/PageOpeningModal';

export const getTriggerTitle = (evolution) => {
  const triggerMode = evolution?.triggerType || TRIGGER_TYPE_DEFAULT;

  if (triggerMode === TRIGGER_TYPE_DEFAULT) {
    return `On page opening${
      ', after ' + (evolution.boostedDelay || 0) / 1000 + ' seconds'
    }`;
  } else if (triggerMode === TRIGGER_TYPE_LEAVE_PAGE) {
    return 'On page leaving';
  } else if (triggerMode === TRIGGER_TYPE_EVENT) {
    if (evolution?.event != null) {
      return `On event: ${evolution.event.name || 'Unnamed event'}`;
    } else if (evolution?.onTheFlyEvent != null) {
      return 'On event: on the fly event';
    } else {
      return 'On event: not set';
    }
  } else if (triggerMode === TRIGGER_TYPE_MANUAL) {
    return 'On manual trigger';
  } else if (triggerMode === TRIGGER_TYPE_URL) {
    return 'On shared URL';
  } else if (triggerMode === TRIGGER_TYPE_NONE) {
    return 'No automatic trigger';
  }
};

const propTypes = {};

const Trigger = () => {
  const {evolution, setEvolution} = useContext(AudienceContext);

  const [manualTriggerModalOpen, setManualTriggerModalOpen] = useState(false);
  const [pageOpeningModalOpen, setPageOpeningModalOpen] = useState(false);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [sharableLinkModalOpen, setSharableLinkModalOpen] = useState(false);

  const triggerMode = evolution?.triggerType || TRIGGER_TYPE_DEFAULT;

  const title = getTriggerTitle(evolution);

  return (
    <AudienceSection
      className="experience-trigger"
      section={SECTION_TRIGGER}
      title="Show on"
      subtitle={
        <Label secondary size="small" type="neutral">
          {title}
        </Label>
      }
      icon={<i className="isax isax-eye" />}>
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_DEFAULT}
        iconComponent={
          <>
            <i className="icon default-icon isax isax-eye" />
            <i className="icon active-hovered-icon isax isax-setting-2" />
          </>
        }
        title={`On page opening${
          ', after ' + (evolution.boostedDelay || 0) / 1000 + ' seconds'
        }`}
        description="Everytime the user arrive on selected pages"
        onClick={() => {
          setEvolution({
            ...evolution,
            triggerType: TRIGGER_TYPE_DEFAULT,
            optionsFlags: removeFlag(
              F_OPTION_IGNORE_RATE_LIMITING,
              evolution.optionsFlags
            ),
          });
          setPageOpeningModalOpen(true);
        }}
      />
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_LEAVE_PAGE}
        icon="isax isax-logout"
        title="On page leaving"
        description="When the user is about to leave the page"
        onClick={() =>
          setEvolution({
            ...evolution,
            triggerType: TRIGGER_TYPE_LEAVE_PAGE,
            optionsFlags: removeFlag(
              F_OPTION_IGNORE_RATE_LIMITING,
              evolution.optionsFlags
            ),
          })
        }
      />
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_EVENT}
        iconComponent={
          <>
            <i className="icon default-icon isax isax-mouse-circle" />
            <i className="icon active-hovered-icon isax isax-setting-2" />
          </>
        }
        title={`On event${
          triggerMode === TRIGGER_TYPE_EVENT
            ? evolution.event != null
              ? `: ${evolution.event.name || 'Unnamed event'}`
              : evolution.onTheFlyEvent != null
              ? `: on the fly event`
              : ''
            : ''
        }`}
        description={<>When the user triggers selected event</>}
        onClick={() => {
          setEventModalOpen(true);
        }}
      />
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_MANUAL}
        iconComponent={
          <>
            <i className="icon default-icon isax isax-document-code" />
            <i className="icon active-hovered-icon isax isax-setting-2" />
          </>
        }
        title="On manual trigger"
        description="Manually trigger the experience using our web SDK"
        onClick={() => {
          setEvolution({
            ...evolution,
            triggerType: TRIGGER_TYPE_MANUAL,
            ...(evolution.triggerType !== TRIGGER_TYPE_MANUAL
              ? {
                  optionsFlags: addFlag(
                    F_OPTION_IGNORE_RATE_LIMITING,
                    evolution.optionsFlags
                  ),
                }
              : {}),
          });
          setManualTriggerModalOpen(true);
        }}
      />
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_URL}
        iconComponent={
          <>
            <i className="icon default-icon isax isax-link-2" />
            <i className="icon active-hovered-icon isax isax-setting-2" />
          </>
        }
        title="On shared URL"
        description="Share a link to trigger the experience"
        onClick={() => {
          setEvolution({
            ...evolution,
            triggerType: TRIGGER_TYPE_URL,
            ...(evolution.triggerType !== TRIGGER_TYPE_URL
              ? {
                  optionsFlags: addFlag(
                    F_OPTION_IGNORE_RATE_LIMITING,
                    evolution.optionsFlags
                  ),
                }
              : {}),
            boostedActiveOperator: PAGE_ACTIVE_OPERATOR_EVERYWHERE,
            segments: [],
            onTheFlySegment: null,
          });
          setSharableLinkModalOpen(true);
        }}
      />
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_NONE}
        icon="isax isax-flash-14"
        title="No automatic trigger"
        description="Triggered by other experiences actions, never on its own"
        onClick={() => {
          setEvolution({
            ...evolution,
            triggerType: TRIGGER_TYPE_NONE,
            optionsFlags: addFlag(
              F_OPTION_IGNORE_RATE_LIMITING,
              evolution.optionsFlags
            ),
            boostedActiveOperator: PAGE_ACTIVE_OPERATOR_EVERYWHERE,
            segments: [],
            onTheFlySegment: null,
          });
        }}
      />
      <PageOpeningModal
        isOpen={pageOpeningModalOpen}
        onRequestClose={() => setPageOpeningModalOpen(false)}
      />
      {eventModalOpen && (
        <EventModal
          isOpen={eventModalOpen}
          onRequestClose={() => setEventModalOpen(false)}
        />
      )}
      <ManualTriggerModal
        isOpen={manualTriggerModalOpen}
        onRequestClose={() => setManualTriggerModalOpen(false)}
      />
      <SharableLinkModal
        isOpen={sharableLinkModalOpen}
        onRequestClose={() => setSharableLinkModalOpen(false)}
        evolution={evolution}
      />
    </AudienceSection>
  );
};

Trigger.propTypes = propTypes;

export default Trigger;
