import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import Shadow from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Shadow';
import ColorItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/ColorItem';
import RadiusItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/RadiusItem';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';

const ChangelogCardsStyle = ({style, setStyle}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      ...style,
      ...newStyle,
    });
  };

  return (
    <>
      <ColorItem
        title={
          ['gradient', 'shape'].includes(style.background?.type)
            ? 'Color 1'
            : 'Color'
        }
        value={style.backgroundColor}
        onChange={(v) => updateStyle({backgroundColor: v})}
      />
      <SectionItem title="Shadow" contentClassName="shadow-picker">
        <PopupSetting
          trigger={
            <ClickableInput
              value={style.shadow?.color}
              leftLabel={
                <span
                  className="preview-shadow-color"
                  style={{background: style.shadow?.color || '#FFFFFF'}}
                />
              }
            />
          }
          title="Shadow"
          content={
            <Shadow
              value={style.shadow}
              onChange={(value) => updateStyle({shadow: value})}
            />
          }
        />
      </SectionItem>
      <RadiusItem
        title="Radius"
        value={style.borderRadius}
        onChange={(value) => updateStyle({borderRadius: value})}
        max={50}
      />
    </>
  );
};

export default ChangelogCardsStyle;
