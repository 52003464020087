/* eslint-disable import/no-anonymous-default-export */
import {
  MISC_SET_COLLAPSE_TRACKING_ONBOARDING,
  MISC_SET_DEFAULT_VIEW_LAYOUT,
  MISC_SET_HIDE_MOBILE_OVERLAY,
  MISC_SET_HIDE_TRIAL_ENDED_MODAL,
  MISC_SET_INVITATION_CODE,
  MISC_SET_NAVBAR_SPACES_EXPANDED,
} from 'actions/misc';
import {VIEW_LAYOUT_ROW_LARGE} from 'services/evolution-list-view';

const defaultMiscState = {
  hideMobileOverlay: false,
  hideTrialEndedModal: false,
  collapseTrackerOnboarding: false,
  invitationCode: null,
  defaultViewLayout: VIEW_LAYOUT_ROW_LARGE,
  navbarSpacesExpanded: true,
};

export default (state = defaultMiscState, action) => {
  switch (action.type) {
    case MISC_SET_HIDE_MOBILE_OVERLAY: {
      return {
        ...state,
        hideMobileOverlay: action.isHidden,
      };
    }
    case MISC_SET_HIDE_TRIAL_ENDED_MODAL: {
      return {
        ...state,
        hideTrialEndedModal: action.isHidden,
      };
    }
    case MISC_SET_COLLAPSE_TRACKING_ONBOARDING: {
      return {
        ...state,
        collapseTrackerOnboarding: action.isCollapsed,
      };
    }
    case MISC_SET_INVITATION_CODE: {
      return {
        ...state,
        invitationCode: action.code,
      };
    }
    case MISC_SET_DEFAULT_VIEW_LAYOUT: {
      return {
        ...state,
        defaultViewLayout: action.layout,
      };
    }
    case MISC_SET_NAVBAR_SPACES_EXPANDED: {
      return {
        ...state,
        navbarSpacesExpanded: action.isExpanded,
      };
    }
    default:
      return state;
  }
};
