import Button from 'components/Button';
import {PermissionsSpace} from 'constants/permissions';
import {crispHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {dataSelector, generalSelector} from 'selectors';
import {spaceService} from 'services';
import {
  PLAN_BASIC_ID,
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import ModalEditSpace from '../ModalEditSpace';
import './_Styles.scss';

const SpacesHeader = () => {
  const {update} = useUpdateSubscription();

  const project = useSelector((state) => generalSelector.getProject(state));
  const projectSubscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const plan = plans.find((p) => p.uid === projectSubscription.plan);

  const canCreateSpace = hasPermissions(PermissionsSpace.CREATE_EDIT);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const {isLoading, data: spaces} = useQuery({
    queryKey: 'spaces',
    queryFn: () => spaceService.getSpaces(),
    initialData: [],
  });

  return (
    <div className="spaces-header">
      <div className="title-3">Spaces</div>
      <div className="actions-wrapper">
        {canCreateSpace && (
          <div className="btns-wrapper">
            <Button
              reverted
              primary
              className="new-tracker-btn"
              iconLeft="icon-plus"
              disabled={isLoading}
              onClick={() => {
                if (
                  spaces?.length < project.thresholdSpaces ||
                  project.thresholdSpaces === -1
                ) {
                  return setShowCreateModal(true);
                }
                if (PLAN_BASIC_ID === projectSubscription.plan) {
                  return update({
                    planId: PLAN_STARTUP_ID,
                    title: 'Upgrade to create space',
                  });
                }
                if (
                  project.thresholdSpaces === plan.spaces &&
                  [PLAN_STARTUP_ID].includes(projectSubscription.plan)
                ) {
                  return update({
                    planId: PLAN_GROWTH_ID,
                    title: 'Need more spaces?',
                    description: 'Upgrade and create up to 10 spaces!',
                  });
                }
                if (spaces.length >= project.thresholdSpaces) {
                  return crispHelpers.startCrispThread(
                    "I'd like to increase my spaces limit!"
                  );
                }
              }}>
              New space
            </Button>
          </div>
        )}
      </div>

      {showCreateModal === true && (
        <ModalEditSpace
          isOpen={showCreateModal}
          onRequestClose={() => setShowCreateModal(false)}
        />
      )}
    </div>
  );
};

export default SpacesHeader;
