import Axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoint
const EP_SURVEY_GET_RESPONSE_JIMERS = '/survey/responses';
const EP_SURVEY_GET_SURVEY = (surveyId) => `/survey/${surveyId}`;
const EP_SURVEY_GET_SURVEYS = '/survey';
const EP_SURVEY_DELETE_SURVEY = (surveyId) => `/survey/${surveyId}`;

// Method
export const getResponseJimers = ({optionId, responseId, values, stepId}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_SURVEY_GET_RESPONSE_JIMERS, {
    params: {projectId, optionId, responseId, values, stepId},
  }).then((response) => response.data);
};

export const getSurvey = ({surveyId}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_SURVEY_GET_SURVEY(surveyId), {
    params: {projectId},
  }).then((response) => response.data);
};

export const getSurveys = ({jimerId}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_SURVEY_GET_SURVEYS, {
    params: {projectId, jimerId},
  }).then((response) => response.data);
};

export const deleteSurvey = (surveyId) => {
  return Axios.delete(EP_SURVEY_DELETE_SURVEY(surveyId)).then(
    (response) => response.data
  );
};
