import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import {RecentFiles} from 'components/RecentFiles';
import {useFileUpload} from 'hooks/useFileUpload';
import {any, func, string} from 'prop-types';
import React, {useRef, useState} from 'react';
import {BLOCK_TYPE_USER} from 'services/steps';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ProfileLink');

const propTypes = {
  fileUrl: string,
  altText: string,
  file: any,
  onChange: func,
};

const defaultProps = {
  fileUrl: null,
  altText: null,
  file: null,
  onChange: () => {},
};

const ProfileLink = ({fileUrl, altText, file, onChange}) => {
  const {upload} = useFileUpload({logger});

  const [isUploading, setIsUploading] = useState(false);

  const refInputFile = useRef();

  const handleSelectRecentFile = (file) => {
    onChange({
      value: `${file.publicUrl};${altText}`,
      file,
    });
  };

  const handleFileChange = async ({target}) => {
    if (!target.files || target.files.length === 0) {
      return;
    }
    setIsUploading(true);
    const file = await upload({
      file: target.files[0],
      blockType: BLOCK_TYPE_USER,
    });
    setIsUploading(false);

    if (file != null) {
      onChange({
        value: `${file.publicUrl};${altText}`,
        file,
      });
    }
  };

  const handleDeleteFile = async () => {
    onChange({
      value: `;${altText}`,
      file: null,
    });
  };

  return (
    <div className="profile-link">
      <div className="section-item profile-media">
        <div className="image-input-wrapper">
          <input
            ref={refInputFile}
            type="file"
            style={{display: 'none'}}
            onChange={handleFileChange}
          />
          {fileUrl != null && file != null ? (
            <div className="file-wrapper">
              <div className="file-preview-name-wrapper">
                <img src={fileUrl} alt="Media" width={60} height={60} />
                {file.originalName.length > 10
                  ? file.originalName.slice(0, 10) + '...'
                  : file.originalName}
              </div>
              <Button
                iconRight="icon-trash"
                className="btn-delete"
                onClick={handleDeleteFile}
                danger
                thin>
                Delete
              </Button>
            </div>
          ) : (
            <div
              className={classNames('btn-file', {
                'is-uploading': isUploading === true,
              })}
              onClick={() => refInputFile.current.click()}>
              {isUploading === true ? (
                <>
                  <DefaultLoader width={14} /> uploading your image...
                </>
              ) : (
                <>
                  <i className="icon-upload-file"></i> Upload
                </>
              )}
            </div>
          )}
        </div>
        <div className="profile-size-information">50x50px (recommended)</div>
      </div>
      <Divider />
      <RecentFiles
        type={BLOCK_TYPE_USER}
        currentFileUrl={fileUrl}
        size={12}
        hasTinyThumbnails
        onSelect={handleSelectRecentFile}
        onDelete={handleDeleteFile}
      />
    </div>
  );
};

ProfileLink.propTypes = propTypes;
ProfileLink.defaultProps = defaultProps;

export default ProfileLink;
