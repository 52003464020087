import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import {BLOCK_TYPE_RESOURCE_CENTER_MINIMIZE} from 'services/steps';
import ColorItem from '../../components/sectionItems/ColorItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import ShadowItem from '../../components/sectionItems/ShadowItem';
import './_Styles.scss';

const ResourceCenterMinimize = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_RESOURCE_CENTER_MINIMIZE, updateObj);
  };

  const block = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_MINIMIZE
  );

  if (block == null) {
    return <></>;
  }

  const {style} = block;

  return (
    <div className="block-settings resource-center-minimize">
      <Section title="Layout">
        <ResourceCenterMinimizeStyle style={style} updateStyle={updateBlock} />
      </Section>
    </div>
  );
};

export const ResourceCenterMinimizeStyle = ({style, updateStyle}) => {
  return (
    <>
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) =>
          updateStyle({
            style: {...style, borderRadius: value},
          })
        }
      />
      <ColorItem
        title="Icon color"
        value={style.iconColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, iconColor: value},
          })
        }
      />
      <ColorItem
        title="Background"
        value={style.backgroundColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, backgroundColor: value},
          })
        }
      />
      <ColorItem
        title="Border"
        value={style.borderColor}
        onChange={(value) =>
          updateStyle({style: {...style, borderColor: value}})
        }
        colorPickerProps={{
          erasable: true,
        }}
      />
      <ShadowItem
        value={style.shadow}
        onChange={(value) => updateStyle({style: {...style, shadow: value}})}
      />
    </>
  );
};

export default ResourceCenterMinimize;
