import classnames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, {useContext} from 'react';
import {
  BLOCK_TITLE,
  BLOCK_USER,
} from 'shared/front/components/Poke/constants/blocks';
import {PostContext} from 'shared/front/components/Post/context';
import './_styles.scss';

dayjs.extend(relativeTime);

const PostDetailBlockTitle = () => {
  const {post, theme, language, hideDate} = useContext(PostContext);

  const step = post.steps[0] || {};
  const block = step.blocks.find((b) => b.type === BLOCK_TITLE);
  const {value} = block;
  const translation = block.translations?.find((t) => t.language === language);
  const showDate =
    step?.blocks.find((b) => [BLOCK_USER].includes(b.type)) == null &&
    !hideDate;
  const {text} = theme ?? {};
  const {titleFontColor, titleFontFamily} = text ?? {};

  return (
    <div
      className={classnames('post-detail-block-title')}
      style={{
        ...(titleFontColor != null
          ? {
              color: titleFontColor,
            }
          : {}),
        ...(titleFontFamily != null
          ? {
              fontFamily: `${titleFontFamily}, ui-sans-serif, system-ui, sans-serif`,
            }
          : {}),
      }}>
      {translation != null ? translation.value : value}
      {showDate === true && (
        <div
          className="timestamp"
          title={dayjs(post?.lastStepChangeAt || new Date()).format(
            'HH:mm - DD/MM/YYYY'
          )}>
          {dayjs(post?.lastStepChangeAt || new Date()).fromNow()}
        </div>
      )}
    </div>
  );
};

export default PostDetailBlockTitle;
