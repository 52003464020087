import {uptProject} from 'actions/general';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS_AMPLITUDE} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {amplitudeService} from 'services';
import {PLAN_GROWTH_ID, PLAN_SCALE_ID} from 'services/subscription';
import {Swaler} from 'swaler';
import AmplitudeLogo from '../imgs/amplitude.svg';
import './_Styles.scss';

const logger = new Swaler('SettingsIntegrationsAmplitude');

const Amplitude = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {update} = useUpdateSubscription();

  const project = useSelector((state) => generalSelector.getProject(state));
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSetupAmplitude = async () => {
    if ([PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(subscription.plan) === false) {
      return update({
        planId: PLAN_GROWTH_ID,
        title: 'Enable Amplitude',
        description: 'Unlock Amplitude integration with our Growth plan',
      });
    }

    setIsLoading(true);
    try {
      const amplitude = await amplitudeService.setup(project.uid);

      updateProject({
        ...project,
        amplitude,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to setup amplitude integration, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  const hasAmplitudeSetup = project.amplitude != null;

  return (
    <div className="settings-card integration-amplitude">
      <div className="logo-action-wrapper">
        <img
          className="logo amplitude-logo"
          src={AmplitudeLogo}
          alt="amplitude-logo"
        />
        {hasAmplitudeSetup === false ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isLoading}
            onClick={handleSetupAmplitude}>
            Enable
          </Button>
        ) : (
          <Button
            thin
            iconLeft="isax isax-setting-2"
            onClick={() => history.push(ROUTE_SETTINGS_INTEGRATIONS_AMPLITUDE)}>
            Configure
          </Button>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Amplitude
          {hasAmplitudeSetup === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Enabled
            </div>
          )}
        </div>
        <div className="body-3">
          Send Jimo analytics in Amplitude and personalize user experiences in
          Jimo based on Amplitude events.
        </div>
      </div>
    </div>
  );
};

export default Amplitude;
