import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import SegmentIcon from 'components/SegmentIcon';
import {default as qs} from 'query-string';
import React, {useContext} from 'react';
import {useQuery} from 'react-query';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import EventConditions from 'scenes/SuccessTracker/components/Event/components/EventConditions';
import {buildLogic} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {TrackerBuilderContext} from 'scenes/TrackerBuilder';
import {eventService} from 'services';
import {
  EVENT_CONDITION_TYPE_DELAY,
  EVENT_SOURCE_AMPLITUDE,
  EVENT_SOURCE_MIXPANEL,
  EVENT_SOURCE_POSTHOG,
  EVENT_SOURCE_SEGMENT,
} from 'services/event';
import {TRACKER_TYPE_FEATURE_USAGE, TRACKER_TYPE_GOAL} from 'services/tracker';
import EventsList from '../ModalAddStepEvent/components/EventsList';
import './_Styles.scss';
import IconAmplitude from './imgs/amplitude.svg';
import IconMixpanel from './imgs/mixpanel.svg';
import IconPosthog from './imgs/posthog.svg';

export const EventItem = ({event, className, onDelete = () => {}}) => {
  const isSegment = event?.source === EVENT_SOURCE_SEGMENT;
  const isPosthog = event?.source === EVENT_SOURCE_POSTHOG;
  const isMixpanel = event?.source === EVENT_SOURCE_MIXPANEL;
  const isAmplitude = event?.source === EVENT_SOURCE_AMPLITUDE;

  return (
    <div className={classNames('tracker-event-item', className)}>
      {isSegment ? (
        <div className={classNames('segment-icon-wrapper')}>
          <SegmentIcon />
        </div>
      ) : isPosthog === true ? (
        <div className="posthog-icon-wrapper">
          <img src={IconPosthog} alt="Posthog" />
        </div>
      ) : isMixpanel === true ? (
        <div className="mixpanel-icon-wrapper">
          <img src={IconMixpanel} alt="Mixpanel" />
        </div>
      ) : isAmplitude === true ? (
        <div className="amplitude-icon-wrapper">
          <img src={IconAmplitude} alt="Amplitude" />
        </div>
      ) : event?.icon ? (
        <div className="event-icon-wrapper">{event.icon}</div>
      ) : null}

      <div className="content">
        <div className="event-name body-2 n-800">
          {event.name || 'Unnamed event'}
        </div>
      </div>
      <div className="actions-wrapper">
        <Button
          iconOnly
          iconLeft="isax isax-trash"
          danger
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete(event);
          }}
        />
      </div>
    </div>
  );
};

const TrackerBuilderSingleEvent = ({
  conditions,
  setConditions,
  logic,
  setLogic,
  compact = false,
}) => {
  const {tracker, setTracker} = useContext(TrackerBuilderContext);
  const location = useLocation();

  const queryString = qs.parse(location.search);

  const {events = [], onTheFlyEvent} = tracker || {};

  const {data: jimoEvents = [], isLoading} = useQuery({
    queryKey: 'trackedEvents',
    queryFn: () =>
      eventService.getEvents({
        relations: ['conditions'],
      }),
    onSuccess: (events) => {
      if (queryString.eventId) {
        const event = events.find((e) => e.uid === queryString.eventId);
        if (event) {
          setTracker({
            ...tracker,
            events: [event],
          });
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  const event = events[0];

  const isFeatureUsageTracker = tracker.type === TRACKER_TYPE_FEATURE_USAGE;
  const isGoalTracker = tracker.type === TRACKER_TYPE_GOAL;

  let title, description;

  if (isFeatureUsageTracker === true) {
    title = 'Feature usage event';
    description =
      "Successful adoption indicates users have embraced your feature's purpose. Capture it with defining events. Select one.";
  } else if (isGoalTracker === true) {
    title = 'Goal event';
    description =
      'Users reaching a goal is a key metric for your business. Capture it with defining events. Select one.';
  }

  return (
    <div className="feature-usage-event-selector-wrapper">
      <div className="feature-usage-event-selector">
        <div className="event-selector-header">
          <div className="event-selector-title">{title}</div>
          <div className="event-selector-description">{description}</div>
        </div>
        {onTheFlyEvent != null ? (
          <>
            <EventConditions
              conditions={conditions}
              setConditions={setConditions}
              logic={logic}
              setLogic={setLogic}
              borderless
              compact={compact}
              omitTypes={[EVENT_CONDITION_TYPE_DELAY]}
            />
            <Button
              className="remove-event-btn"
              thin
              iconRight="isax isax-trash"
              onClick={() => {
                setTracker({
                  ...tracker,
                  onTheFlyEvent: null,
                });
              }}>
              Remove on the fly event
            </Button>
          </>
        ) : event != null ? (
          <div className="event-list">
            <div className="event-wrapper" key={event.uid}>
              <EventItem
                event={event}
                className="selected-event"
                onDelete={() => {
                  setTracker({
                    ...tracker,
                    events: events.filter((e) => e.uid !== event.uid),
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <Divider />
            <EventsList
              events={jimoEvents.filter(
                (event) => event.source !== EVENT_SOURCE_SEGMENT
              )}
              segmentioEvents={jimoEvents.filter(
                (event) => event.source === EVENT_SOURCE_SEGMENT
              )}
              onCreateOnTheFlyEvent={() => {
                setTracker({
                  ...tracker,
                  onTheFlyEvent: {
                    temporary: true,
                    conditions: [],
                    logic: buildLogic(),
                  },
                  events: [],
                });
              }}
              onSelectEvent={(event) => {
                setTracker({
                  ...tracker,
                  events: [...events, event],
                });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TrackerBuilderSingleEvent;
