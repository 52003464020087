import classNames from 'classnames';
import React, {useContext, useEffect, useRef, useState} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {
  BLOCK_RESOURCE_CENTER_TRIGGER,
  BLOCK_RESOURCE_CENTER_TRIGGER_ARROW,
  BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
  BLOCK_RESOURCE_CENTER_TRIGGER_ICON,
  BLOCK_RESOURCE_CENTER_TRIGGER_TEXT,
} from '../../../Poke/constants/blocks';
import {PokeContext, PokeStateContext} from '../../../Poke/context';
import {
  RESOURCE_CENTER_TRIGGER_POSITION_LEFT,
  RESOURCE_CENTER_TRIGGER_POSITION_RIGHT,
  RESOURCE_CENTER_TRIGGER_TYPE_COMPACT,
  RESOURCE_CENTER_TRIGGER_TYPE_EXPANDED,
} from '../../constants';
import './_Styles.scss';
import ResourceCenterTriggerArrow from './components/ResourceCenterTriggerArrow';
import ResourceCenterTriggerChecklistProgress from './components/ResourceCenterTriggerChecklistProgress';
import ResourceCenterTriggerIcon from './components/ResourceCenterTriggerIcon';
import ResourceCenterTriggerText from './components/ResourceCenterTriggerText';
import {useElementSize} from './hooks/UseElementSize';

const ResourceCenterTrigger = () => {
  const {poke} = useContext(PokeContext);
  const {
    blocks,
    inBuilder,
    onBlockSelected,
    onContainerSelected,
    isMinimized,
    setIsMinimized,
    selectedBlock,
    hasAnimationActive,
  } = useContext(PokeStateContext);

  const triggerBlock = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_TRIGGER
  );

  const {style: {position = RESOURCE_CENTER_TRIGGER_POSITION_RIGHT} = {}} =
    poke ?? {};
  const {style} = triggerBlock ?? {};
  const {
    gap,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    borderRadius,
    backgroundColor,
    borderColor,
    shadow,
  } = style ?? {};

  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = `${x}px ${y}px ${blur}px ${color}`;

  const [isHovered, setIsHovered] = useState(false);
  const [overflow, setOverflow] = useState('hidden');

  const checklistProgressRef = useRef(null);
  const triggerHiddenContentContainerRef = useRef(null);
  const triggerIconWrapperRef = useRef(null);
  const triggerRef = useRef(null);
  const timeoutRef = useRef(null);

  const triggerIconWrapperSize = useElementSize(triggerIconWrapperRef);
  const triggerHiddenContentContainerSize = useElementSize(
    triggerHiddenContentContainerRef
  );
  const checklistProgressSize = useElementSize(checklistProgressRef);
  const triggerSize = useElementSize(triggerRef);

  const {width: triggerIconWrapperWidth} = triggerIconWrapperSize;
  const {width: triggerHiddenContentContainerWidth} =
    triggerHiddenContentContainerSize;
  const {width: checklistProgressWidth} = checklistProgressSize;
  const {height: triggerHeight} = triggerSize;

  const isCompact =
    style?.type === RESOURCE_CENTER_TRIGGER_TYPE_COMPACT &&
    [
      BLOCK_RESOURCE_CENTER_TRIGGER_ICON,
      BLOCK_RESOURCE_CENTER_TRIGGER_TEXT,
      BLOCK_RESOURCE_CENTER_TRIGGER_ARROW,
    ].includes(selectedBlock) !== true;
  const isExpanded = style?.type === RESOURCE_CENTER_TRIGGER_TYPE_EXPANDED;

  const hasChecklistProgress =
    selectedBlock === BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS;

  useEffect(() => {
    if (inBuilder === true) {
      if (isExpanded === true) {
        setOverflow('visible');
      } else {
        clearTimeout(timeoutRef.current);
        if (isCompact === true && isHovered === false) {
          setOverflow('hidden');
          timeoutRef.current = setTimeout(() => {
            setOverflow('visible');
          }, 700);
        } else {
          timeoutRef.current = setTimeout(() => {
            setOverflow('visible');
          }, 700);
        }
      }
    } else {
      setOverflow('hidden');
    }
  }, [isCompact, isHovered, isExpanded, inBuilder]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const hasTriggerHiddenContent = blocks.some(
    (b) =>
      b.type === BLOCK_RESOURCE_CENTER_TRIGGER_TEXT ||
      b.type === BLOCK_RESOURCE_CENTER_TRIGGER_ARROW
  );

  let width = triggerIconWrapperWidth;

  if (hasTriggerHiddenContent === true) {
    width += triggerHiddenContentContainerWidth + paddingRight + gap;
  }

  if (
    isCompact === true &&
    isHovered === false &&
    [
      BLOCK_RESOURCE_CENTER_TRIGGER_ARROW,
      BLOCK_RESOURCE_CENTER_TRIGGER_TEXT,
    ].includes(selectedBlock) === false
  ) {
    width =
      triggerIconWrapperWidth +
      (hasChecklistProgress === true
        ? checklistProgressWidth + paddingRight
        : 0);
  }

  if (isExpanded === true) {
    width =
      hasChecklistProgress === true && isHovered === false
        ? triggerIconWrapperWidth + checklistProgressWidth + gap + paddingRight
        : triggerIconWrapperWidth +
          (hasTriggerHiddenContent === true
            ? triggerHiddenContentContainerWidth + paddingRight + gap
            : 0);
  }

  return (
    <div
      ref={triggerRef}
      className={classNames('block-resource-center-trigger', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_RESOURCE_CENTER_TRIGGER,
        'is-left': position === RESOURCE_CENTER_TRIGGER_POSITION_LEFT,
        'is-right': position === RESOURCE_CENTER_TRIGGER_POSITION_RIGHT,
        'is-mini': isMinimized === true,
        'is-compact': isCompact,
        'is-expanded': isExpanded,
        'has-checklist-progress': hasChecklistProgress,
        'disable-animations': hasAnimationActive === false,
      })}
      style={{
        paddingLeft,
        paddingRight,
        borderRadius,
        backgroundColor,
        ...(borderColor != null && {border: `1px solid ${borderColor}`}),
        boxShadow,

        minWidth: triggerIconWrapperWidth,
        width,
      }}
      onClick={(e) => {
        e.stopPropagation();

        if (inBuilder === true) {
          onBlockSelected(BLOCK_RESOURCE_CENTER_TRIGGER);
          onContainerSelected(null);
          return;
        }

        setIsMinimized(!isMinimized);
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}>
      <div
        className="block-resource-center-trigger-content"
        style={{
          gap,
          overflow,
        }}>
        <div
          ref={triggerIconWrapperRef}
          className="block-resource-center-trigger-icon-wrapper"
          style={{
            paddingTop,
            paddingBottom,
          }}>
          <ResourceCenterTriggerIcon />
        </div>
        <div className="trigger-hidden-content-container-wrapper">
          <div
            ref={triggerHiddenContentContainerRef}
            className="trigger-hidden-content-container"
            style={{
              gap,
              paddingTop,
              paddingBottom,
            }}>
            <ResourceCenterTriggerText />
            <ResourceCenterTriggerArrow />
          </div>
        </div>
        <ResourceCenterTriggerChecklistProgress
          innerRef={checklistProgressRef}
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
          gap={gap}
          triggerHeight={triggerHeight}
        />
      </div>

      <ClickableBlockOverlay />
    </div>
  );
};

export default ResourceCenterTrigger;
