import {EVENT_VIEW_PUSH_ANALYTICS} from 'amplitude';
import amplitude from 'amplitude-js';
import classNames from 'classnames';
import DateSelectionDropdown from 'components/DateSelectionDropdown';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import DefaultLoader from 'components/Loader';
import PokeBlock from 'components/PokeBlock';
import dayjs from 'dayjs';
import {object} from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_RESOURCE_CENTER,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {Swaler} from 'swaler';
import './_Styles.scss';
import ChecklistTaskActivity from './components/ChecklistTaskActivity';
import CompletionChart from './components/CompletionChart';
import CtaActivity from './components/CtaActivity';
import GoalChart from './components/GoalChart';
import HintActivity from './components/HintActivity';
import ResourceCenterActionActivity from './components/ResourceCenterActionActivity';
import StatsRow from './components/StatsRow';
import StepsActivity from './components/StepsActivity';
import SubmissionsChart from './components/SubmissionsChart';
import ViewsChart from './components/ViewsChart';

const logger = new Swaler('Statistics');

export const generateDatesArray = (startDate, endDate, frequency = 'day') => {
  const arr = [dayjs(startDate).startOf(frequency)];
  do {
    arr.push(dayjs(arr[arr.length - 1]).add(1, frequency));
  } while (dayjs(arr[arr.length - 1]).isBefore(dayjs(endDate)));
  return arr;
};

const propTypes = {
  evolution: object.isRequired,
};

const defaultProps = {};

export const TAB_STEPS = 'STEPS';
export const TAB_GOAL = 'GOAL';
export const TAB_CHART = 'CHART';
export const TAB_CTA = 'CTA';
export const TAB_SUBMISSIONS = 'SUBMISSIONS';
export const TAB_COMPLETION = 'COMPLETION';
export const TAB_HINTS = 'HINTS';
export const TAB_CHECKLIST_TASKS = 'CHECKLIST_TASKS';
export const TAB_RESOURCE_CENTER_ACTIONS = 'RESOURCE_CENTER_ACTIONS';

export const PokeStatisticsContext = React.createContext();

const Statistics = ({evolution}) => {
  const isTour = evolution?.type === EVOLUTION_TYPE_TOUR;
  const isBanner = evolution?.type === EVOLUTION_TYPE_BANNER;
  const isChecklist = evolution?.type === EVOLUTION_TYPE_CHECKLIST;
  const isResourceCenter = evolution?.type === EVOLUTION_TYPE_RESOURCE_CENTER;

  const isSingleStepPoke =
    evolution.steps.length === 1 ||
    (evolution.tourSteps?.length === 1 &&
      evolution.tourSteps[0]?.steps?.length === 1);
  const isDraft = evolution.isDraft === true;

  const [activeTab, setActiveTab] = useState(
    isResourceCenter
      ? TAB_RESOURCE_CENTER_ACTIONS
      : isChecklist
      ? TAB_CHECKLIST_TASKS
      : isTour && isSingleStepPoke !== true
      ? TAB_STEPS
      : isTour || isBanner
      ? TAB_GOAL
      : TAB_CHART
  );
  const [tabData, setTabData] = useState(null);
  const [timeRange, setTimeRange] = useState([
    dayjs(evolution.createdAt).subtract(1, 'day').toDate(),
    dayjs().toDate(),
  ]);
  const [timeFrame, setTimeFrame] = useState(null);
  const [customDate, setCustomDate] = useState(null);

  const [startDate, endDate] = timeRange;

  const {data: evolutionWithAnalytics, isLoading: isLoadingAnalytics} =
    useQuery({
      queryKey: ['analytics', 'withSteps', evolution.uid],
      queryFn: () =>
        evolutionService.getEvolutionById(evolution.uid, {
          relations: ['analytics', 'tourSteps', 'steps'],
        }),
      onError: (err) => {
        logger.error('Could not fetch analytics, failed with err', err.message);
      },
    });

  useEffect(() => {
    amplitude.getInstance().logEvent(EVENT_VIEW_PUSH_ANALYTICS);
  }, []);

  const isLoading = isLoadingAnalytics;

  if (isLoading) {
    return (
      <div className="poke-statistics-loading">
        <DefaultLoader />
      </div>
    );
  }

  const {analytics} = evolutionWithAnalytics;

  return (
    <PokeStatisticsContext.Provider
      value={{
        evolution,
        timeRange,
        timeFrame,
        customDate,
      }}>
      <PokeBlock
        className="poke-statistics"
        title={
          <>
            Experience statistics
            {isDraft !== true && (
              <div className="chart-date-picker">
                <DateSelectionDropdown
                  minDate={evolution.createdAt}
                  startDate={startDate}
                  endDate={endDate}
                  setTimeRange={setTimeRange}
                  setTimeFrame={setTimeFrame}
                  setCustomDate={setCustomDate}
                />
              </div>
            )}
          </>
        }>
        {isDraft === true ? (
          <EmptyStateBlock
            img={EmptyStateImgs.EmptyResponses}
            title="No statistics yet"
            description="As your experience gets deployed live, you'll find the data right here."
          />
        ) : (
          <div className="left-statistics-wrapper">
            <StatsRow
              analytics={analytics}
              evolution={evolution}
              onTabClick={(tab, tabData = null) => {
                setActiveTab(tab);
                setTabData(tabData);
              }}
              selectedTab={activeTab}
              isSingleStepPoke={isSingleStepPoke}
              tabData={tabData}
            />
            <div className="wrapper1">
              <div className="analytics-wrapper">
                <div
                  className={classNames('main-wrapper', {
                    'is-tab-step': activeTab === TAB_STEPS,
                  })}>
                  {activeTab === TAB_STEPS && (
                    <StepsActivity
                      evolution={evolution}
                      timeRange={timeRange}
                      timeFrame={timeFrame}
                      customDate={customDate}
                    />
                  )}
                  {activeTab === TAB_GOAL && (
                    <GoalChart evolution={evolution} timeRange={timeRange} />
                  )}
                  {activeTab === TAB_CHART && (
                    <ViewsChart
                      evolution={evolutionWithAnalytics}
                      timeRange={timeRange}
                      {...tabData}
                    />
                  )}
                  {activeTab === TAB_CTA && (
                    <CtaActivity
                      evolution={evolution}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  )}
                  {activeTab === TAB_SUBMISSIONS && (
                    <SubmissionsChart
                      evolution={evolution}
                      timeRange={timeRange}
                    />
                  )}
                  {activeTab === TAB_COMPLETION && (
                    <CompletionChart
                      evolution={evolution}
                      timeRange={timeRange}
                    />
                  )}
                  {activeTab === TAB_HINTS && (
                    <HintActivity
                      evolution={evolution}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  )}
                  {activeTab === TAB_CHECKLIST_TASKS && (
                    <ChecklistTaskActivity
                      evolution={evolutionWithAnalytics}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  )}
                  {activeTab === TAB_RESOURCE_CENTER_ACTIONS && (
                    <ResourceCenterActionActivity
                      evolution={evolutionWithAnalytics}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </PokeBlock>
    </PokeStatisticsContext.Provider>
  );
};

Statistics.propTypes = propTypes;
Statistics.defaultProps = defaultProps;

export default React.memo(Statistics);
