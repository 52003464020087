import classNames from 'classnames';
import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {func, object} from 'prop-types';
import {
  positionsArr,
  progressOnClickOptions,
  scrollOptions,
  scrollPositionOptions,
} from 'scenes/PokeBuilder/components/BlockEditor/blocks/PinnedElement';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';
import {
  pointerOptions,
  positionOptions,
} from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Position';
import ZIndex from 'scenes/PokeBuilder/components/BlockEditor/components/modals/ZIndex';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ColorItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/ColorItem';
import EffectsSection from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/EffectsSection';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {ElementSelector} from 'scenes/SuccessTracker/components/Event/components/EventConditions/components/EditDropdown/components/ElementSelector';
import {
  F_BOOST_SLOT_CURSOR,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_TOOLTIP,
  F_OPTION_POKE_CARD_WITH_POINTER,
  F_OPTION_PROGRESS_ON_TARGET_CLICK,
} from 'services/evolution';
import {
  SCROLL_OPTION_AUTO,
  SCROLL_OPTION_MANUAL,
  SCROLL_POSITION_CENTER,
} from 'shared/front/components/Poke/constants/evolution';
import './_Styles.scss';

const propTypes = {
  evolution: object,
  setEvolution: func,
  messenger: object,
};

const PinnedElementEditor = ({evolution, setEvolution, messenger, image}) => {
  const isAuto = evolution?.boostedPositionFlags === 0;
  const isCustom = evolution?.boostedPositionFlags === 256;
  const isFixed = isAuto !== true && isCustom !== true;

  const isHotspot = hasFlag(F_BOOST_SLOT_DOT, evolution.boostFlags);
  const isTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution.boostFlags);
  const isCursor = hasFlag(F_BOOST_SLOT_CURSOR, evolution.boostFlags);

  const [offsetX, offsetY, offsetCardX = 0, offsetCardY = 0] = (
    evolution?.boostedPositionOffsets || '0;0;0;0'
  ).split(';');

  const selectedAlignment = positionsArr.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  const hasPointer = hasFlag(
    F_OPTION_POKE_CARD_WITH_POINTER,
    evolution.optionsFlags
  );

  const style = evolution?.style ?? {};

  const handleStyleChange = (changes) => {
    setEvolution({
      ...evolution,
      style: {...style, ...changes},
    });
  };

  const hasProgressOnClick = hasFlag(
    F_OPTION_PROGRESS_ON_TARGET_CLICK,
    evolution.optionsFlags
  );

  return (
    <div className="block-settings pinned-element-editor">
      <div className="section-item target-picker">
        <ElementSelector
          data={{
            querySelector: evolution?.boostedQueryselector,
            querySelectors: evolution?.querySelectors,
            file: evolution?.file,
            querySelectorManual: evolution?.querySelectorManual,
          }}
          onChange={(data) => {
            setEvolution({
              ...evolution,
              boostedQueryselector: data.querySelector,
              file: data.file,
              querySelectorManual: data.querySelectorManual,
            });
          }}
          withElementsCount
          onSelectElement={messenger.sendSelectTargetElement}
          image={image}
        />
      </div>
      <Divider />
      {(isTooltip || isCursor) && (
        <>
          <Section title="Behavior">
            <SectionItem title="Progress on click">
              <RadioGroup
                value={hasProgressOnClick}
                options={progressOnClickOptions}
                onSelect={(v) => {
                  setEvolution({
                    ...evolution,
                    optionsFlags: v
                      ? addFlag(
                          F_OPTION_PROGRESS_ON_TARGET_CLICK,
                          evolution.optionsFlags
                        )
                      : removeFlag(
                          F_OPTION_PROGRESS_ON_TARGET_CLICK,
                          evolution.optionsFlags
                        ),
                  });
                }}
              />
            </SectionItem>
            <SectionItem title="Scroll to target">
              <RadioGroup
                value={evolution.options?.scrollToTarget ?? SCROLL_OPTION_AUTO}
                options={scrollOptions}
                onSelect={(v) => {
                  setEvolution({
                    ...evolution,
                    options: {
                      ...evolution.options,
                      scrollToTarget: v,
                    },
                  });
                }}
              />
            </SectionItem>
            {evolution.options?.scrollToTarget === SCROLL_OPTION_MANUAL && (
              <SectionItem title="Scroll position">
                <RadioGroup
                  value={
                    evolution.options?.scrollPosition ?? SCROLL_POSITION_CENTER
                  }
                  options={scrollPositionOptions}
                  onSelect={(v) => {
                    setEvolution({
                      ...evolution,
                      options: {
                        ...evolution.options,
                        scrollPosition: v,
                      },
                    });
                  }}
                />
              </SectionItem>
            )}
          </Section>
          <Divider />
        </>
      )}

      <Section title="Position">
        {(isTooltip || isHotspot) && (
          <>
            <SectionItem>
              <RadioGroup
                value={isAuto ? 'auto' : isFixed ? 'fixed' : 'custom'}
                options={positionOptions.filter((o) => {
                  if (isHotspot === true) {
                    return o.value !== 'auto';
                  }
                  return true;
                })}
                onSelect={(v) => {
                  if (v === 'auto') {
                    setEvolution({...evolution, boostedPositionFlags: 0});
                  } else if (v === 'fixed') {
                    setEvolution({...evolution, boostedPositionFlags: 1});
                  } else {
                    setEvolution({
                      ...evolution,
                      boostedPositionFlags: 256,
                      ...(evolution.boostedPositionFlags !== 256
                        ? {boostedPositionOffsets: '0;0;0;110'}
                        : {}),
                    });
                  }
                }}
              />
            </SectionItem>
            {isCustom && (
              <>
                {isHotspot ? (
                  <>
                    <SectionItem title="Hotspot offset X">
                      <PixelPicker
                        value={offsetX}
                        min={-400}
                        max={400}
                        onChange={(value) => {
                          messenger.sendForceHotspotView(evolution.uid, true);

                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${value};${offsetY};${offsetCardX};${offsetCardY}`,
                          });
                        }}
                      />
                    </SectionItem>
                    <SectionItem title="Hotspot offset Y">
                      <PixelPicker
                        value={offsetY}
                        min={-400}
                        max={400}
                        onChange={(value) => {
                          messenger.sendForceHotspotView(evolution.uid, true);

                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${offsetX};${value};${offsetCardX};${offsetCardY}`,
                          });
                        }}
                      />
                    </SectionItem>
                    <SectionItem title="Card offset X">
                      <PixelPicker
                        value={offsetCardX}
                        min={-400}
                        max={400}
                        onChange={(value) => {
                          messenger.sendForceHotspotView(evolution.uid, false);

                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${offsetX};${offsetY};${value};${offsetCardY}`,
                          });
                        }}
                      />
                    </SectionItem>
                    <SectionItem title="Card offset Y">
                      <PixelPicker
                        value={offsetCardY}
                        min={-400}
                        max={400}
                        onChange={(value) => {
                          messenger.sendForceHotspotView(evolution.uid, false);

                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${offsetX};${offsetY};${offsetCardX};${value}`,
                          });
                        }}
                      />
                    </SectionItem>
                  </>
                ) : (
                  <>
                    <SectionItem title="Offset X">
                      <PixelPicker
                        value={offsetX}
                        min={-400}
                        max={400}
                        onChange={(value) =>
                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${value};${offsetY}`,
                          })
                        }
                      />
                    </SectionItem>

                    <SectionItem title="Offset Y">
                      <PixelPicker
                        value={offsetY}
                        min={-400}
                        max={400}
                        onChange={(value) =>
                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${offsetX};${value}`,
                          })
                        }
                      />
                    </SectionItem>
                  </>
                )}
              </>
            )}
            {isFixed && (
              <div className="alignment-figure-wrapper">
                <div className="alignment-figure">
                  <div className="alignment-label">
                    {selectedAlignment != null ? (
                      <>Always show from {selectedAlignment.position}</>
                    ) : (
                      <>Select a position</>
                    )}
                  </div>
                  {positionsArr.map((o) => {
                    if (isHotspot && o.value > 128) {
                      return <></>;
                    }
                    return (
                      <div
                        className={classNames('position-dot', o.position, {
                          selected: evolution?.boostedPositionFlags === o.value,
                        })}
                        onClick={() =>
                          setEvolution({
                            ...evolution,
                            boostedPositionFlags: o.value,
                          })
                        }
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
        <SectionItem title="Z-index">
          <PopupSetting
            trigger={
              <ClickableInput
                className="position-z-index"
                value={
                  evolution.boostedZIndex != null
                    ? `${evolution.boostedZIndex}`
                    : 'Auto'
                }
                leftLabel={
                  <div className="icon-wrapper">
                    <i className="icon-duplicate" />
                  </div>
                }
              />
            }
            title="Z-index"
            content={
              <ZIndex
                value={evolution.boostedZIndex}
                onChange={(value) =>
                  setEvolution({
                    ...evolution,
                    boostedZIndex: value,
                  })
                }
              />
            }
          />
        </SectionItem>
      </Section>
      <Divider />
      {isTooltip && (
        <>
          <Section title="Style">
            <ColorItem
              title="Target border"
              value={style.targetBorderColor}
              onChange={(value) =>
                handleStyleChange({targetBorderColor: value})
              }
              erasable={style.targetBorderColor != null}
            />

            <SectionItem title="Pointer">
              <RadioGroup
                value={hasPointer}
                options={pointerOptions}
                onSelect={(v) => {
                  if (v === true) {
                    setEvolution({
                      ...evolution,
                      optionsFlags: addFlag(
                        F_OPTION_POKE_CARD_WITH_POINTER,
                        evolution.optionsFlags
                      ),
                    });
                  } else {
                    setEvolution({
                      ...evolution,
                      optionsFlags: removeFlag(
                        F_OPTION_POKE_CARD_WITH_POINTER,
                        evolution.optionsFlags
                      ),
                    });
                  }
                }}
              />
            </SectionItem>
            {hasPointer === true && (
              <ColorItem
                title="Pointer color"
                value={style.pointerColor || style.background?.primaryColor}
                onChange={(value) =>
                  handleStyleChange({
                    pointerColor: value,
                  })
                }
                colorPickerProps={{
                  erasable: style.pointerColor != null,
                }}
              />
            )}
          </Section>
          <Divider />
        </>
      )}
      <EffectsSection
        style={style}
        onChange={(newStyle) => handleStyleChange(newStyle)}
      />
    </div>
  );
};

PinnedElementEditor.propTypes = propTypes;

export default PinnedElementEditor;
