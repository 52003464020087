import {Icon} from '@iconify/react';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON} from 'services/steps';
import {
  RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
  RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
} from 'shared/front/components/ResourceCenter/constants';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import './_Styles.scss';
import ResourceCenterIconPicker from './components/ResourceCenterIconPicker';

const ResourceCenterActionIcon = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedSubItemId,
  } = useContext(BuilderContext);

  const block = step?.blocks.find(
    (b) =>
      b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON &&
      b.parentBlockId === selectedSubItemId
  );

  const updateBlock = (updateObj, all = false) => {
    if (all !== true && block?.parentBlockId != null) {
      uptBlock(BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON, updateObj, {
        parentBlockId: block.parentBlockId,
      });
    } else {
      uptBlock(BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON, updateObj);
    }
  };

  if (block == null) {
    return <></>;
  }

  const {style = {}, file} = block;

  const {iconSource, iconName, iconUrl} = style;

  const icon =
    iconSource === RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR ? (
      <Icon icon={`ph:${iconName}`} />
    ) : iconSource === RESOURCE_CENTER_ICON_SOURCE_TWEMOJI ? (
      <Icon icon={`twemoji:${iconName}`} />
    ) : null;

  return (
    <div className="block-settings resource-center-action-icon">
      <Section
        title={
          <>
            <i className="isax isax-main-component5 p-500" />
            Layout
          </>
        }
        titleExtra={
          <>
            <div className="body-4 n-700">For every</div>
            <div className="block-group subtitle-4 n-800">
              <div className="icon-wrapper">
                <i className="icon-question-circle" />
              </div>
              Action Icon
            </div>
          </>
        }>
        <PaddingItem
          value={{
            paddingTop: style.paddingTop ?? style.padding,
            paddingBottom: style.paddingBottom ?? style.padding,
            paddingLeft: style.paddingLeft ?? style.padding,
            paddingRight: style.paddingRight ?? style.padding,
          }}
          onChange={(value) => updateBlock({style: {...value}}, true)}
        />
        <RadiusItem
          value={style.borderRadius}
          onChange={(value) =>
            updateBlock(
              {
                style: {borderRadius: value},
              },
              true
            )
          }
        />
        <FontSizeItem
          title="Icon size"
          value={style.fontSize}
          onChange={(value) =>
            updateBlock(
              {
                style: {fontSize: value},
              },
              true
            )
          }
        />
      </Section>
      <Divider />
      <Section title="Style">
        <SectionItem title="Icon" sectionItemClassName="item-icon-picker">
          <PopupSetting
            className="picker-icon-popup"
            trigger={
              <ClickableInput
                value={
                  [
                    RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
                    RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
                  ].includes(iconSource)
                    ? iconName
                        ?.split('-')
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(' ')
                    : iconUrl != null
                    ? 'Custom Icon'
                    : null
                }
                leftLabel={
                  <span className="preview-icon">
                    {[
                      RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
                      RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
                    ].includes(iconSource) ? (
                      icon
                    ) : iconUrl ? (
                      <img src={iconUrl} alt="" />
                    ) : (
                      <></>
                    )}
                  </span>
                }
                inputProps={{
                  placeholder: 'Select an icon',
                }}
              />
            }
            title="Icon"
            content={
              <ResourceCenterIconPicker
                style={style}
                file={file}
                type={BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON}
                onChange={({style: updatedStyle, file}) => {
                  updateBlock({
                    style: {
                      ...updatedStyle,
                    },
                    file,
                  });
                }}
              />
            }
          />
        </SectionItem>
        <ResourceCenterActionIconStyle
          style={style}
          updateStyle={updateBlock}
          hideIconColor={iconSource === RESOURCE_CENTER_ICON_SOURCE_TWEMOJI}
        />
      </Section>
    </div>
  );
};

export const ResourceCenterActionIconStyle = ({
  style,
  updateStyle,
  hideIconColor = false,
}) => {
  return (
    <>
      {!hideIconColor && (
        <ColorItem
          title="Icon color"
          value={style.iconColor}
          onChange={(value) =>
            updateStyle({
              style: {iconColor: value},
            })
          }
        />
      )}
      <ColorItem
        title="Background"
        value={style.backgroundColor}
        onChange={(value) =>
          updateStyle({
            style: {backgroundColor: value},
          })
        }
      />
      <ColorItem
        title="Border"
        value={style.borderColor}
        onChange={(value) =>
          updateStyle({
            style: {borderColor: value},
          })
        }
      />
    </>
  );
};

export default ResourceCenterActionIcon;
