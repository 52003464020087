import dayjs from 'dayjs';

// Helper function to determine date label
const getDateLabel = (date) => {
  const today = dayjs().startOf('day');
  const yesterday = today.subtract(1, 'day');
  const itemDate = dayjs(date).startOf('day');

  if (itemDate.isSame(today)) {
    return 'Today';
  } else if (itemDate.isSame(yesterday)) {
    return 'Yesterday';
  } else {
    return dayjs(date).format('dddd, D MMM YYYY');
  }
};

// Helper function to group items by date
export const groupItemsByDate = (items) => {
  const filteredItems = items.filter((item) => {
    if (
      [
        'PORTAL_WIDGET',
        'COMMENT',
        'VOTE',
        'CREATE_JIMER',
        'SURVEY_STARTED',
        'BANNER_SEEN',
        'CHECKLIST_STARTED',
        'CHECKLIST_COMPLETED',
        'CHECKLIST_TASK_COMPLETED',
        'RESOURCE_CENTER_OPENED',
        'RESOURCE_CENTER_ACTION_CLICKED',
        'HINT_SEEN',
      ].includes(item.type)
    ) {
      return true;
    }
    if (item.type === 'TOUR') {
      const tourStartedEvent = item.subEvents.find(
        (subEvent) => subEvent.type === 'TOUR_STARTED'
      );

      if (tourStartedEvent != null) {
        const evolution = tourStartedEvent.evolution;

        const tourCompletedEvent = items.find(
          (item) =>
            item.subEvents != null &&
            item.subEvents.some((s) => s.type === 'TOUR_COMPLETED')
        );

        if (tourCompletedEvent != null) {
          return true;
        } else if (evolution?.tourSteps?.length > 1) {
          return true;
        }
      }
    }
    return false;
  });

  const groupedItems = {};

  // Only process if we have items
  if (!filteredItems || filteredItems.length === 0) {
    return [];
  }

  filteredItems.forEach((item) => {
    const dateLabel = getDateLabel(item.createdAt);
    if (!groupedItems[dateLabel]) {
      groupedItems[dateLabel] = [];
    }
    groupedItems[dateLabel].push(item);
  });

  // Filter out any empty groups and convert to array of objects
  return Object.entries(groupedItems)
    .filter(([_, groupItems]) => groupItems && groupItems.length > 0)
    .map(([dateLabel, items]) => ({
      dateLabel,
      items,
    }));
};
