import React, {useContext} from 'react';
import {
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_STEPPER,
} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {BlockCta} from '../BlockCta';
import {BlockStepper} from '../BlockStepper';
import './_styles.scss';

const LayoutBottom = () => {
  const {currentStep, isHint} = useContext(PokeStateContext);

  const shouldShowBlockCta = currentStep.blocks.some(
    (b) =>
      [BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA].includes(b.type) &&
      b.removed !== true
  );
  const blockStepper = currentStep.blocks.find((b) => b.type === BLOCK_STEPPER);
  const shouldShowBlockStepper =
    blockStepper != null && blockStepper.style.position === 'bottom';

  if (shouldShowBlockCta === false && shouldShowBlockStepper === false) {
    return <></>;
  }

  return (
    <div
      className="poke-layout-bottom"
      style={{
        gap: `${currentStep?.style?.gap ?? 16}px`,
        paddingBottom: `${
          currentStep?.style?.paddingBottom ?? (isHint ? 16 : 24)
        }px`,
        paddingLeft: `${
          currentStep?.style?.paddingLeft ?? (isHint ? 16 : 24)
        }px`,
        paddingRight: `${
          currentStep?.style?.paddingRight ?? (isHint ? 16 : 24)
        }px`,
      }}>
      {shouldShowBlockCta && <BlockCta />}
      {shouldShowBlockStepper && <BlockStepper />}
    </div>
  );
};

export default LayoutBottom;
