import classnames from 'classnames';
import Button from 'components/Button';
import React from 'react';
import './_Styles.scss';

export const Pagination = (props) => {
  const {page, take, total, onChange, isLoading, label = 'users'} = props;
  const classNames = classnames('users-pagination');

  const handleClick = (newPage) => {
    return onChange(newPage);
  };
  const canGoBackward = () => {
    return page > 0;
  };
  const canGoForward = () => {
    const totalPage = Math.ceil(total / take);

    return totalPage !== 1 && page + 1 < totalPage;
  };

  return (
    <div className={classNames}>
      <Button
        muted
        rounded={false}
        onClick={() => handleClick(page - 1)}
        disabled={canGoBackward() === false || isLoading}>
        <i className="icon-chevron-left"></i>
      </Button>
      {total === 0 ? (
        'No users found'
      ) : (
        <>
          {page * take + 1}-{Math.min(page * take + take, total)} of {total}{' '}
          {label}
        </>
      )}
      <Button
        muted
        rounded={false}
        onClick={() => handleClick(page + 1)}
        disabled={canGoForward() === false || isLoading}>
        <i className="icon-chevron-right"></i>
      </Button>
    </div>
  );
};
