import Store from 'store/configure';

export const getInvitationCode = (store = Store().getState()) => {
  return store.misc.invitationCode;
};
export const getDefaultViewLayout = (store = Store().getState()) => {
  return store.misc.defaultViewLayout;
};
export const getNavbarSpacesExpanded = (store = Store().getState()) => {
  return store.misc.navbarSpacesExpanded;
};
