import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_RESOURCE_CENTER_TRIGGER} from 'services/steps';
import ColorItem from '../../components/sectionItems/ColorItem';
import GapItem from '../../components/sectionItems/GapItem';
import OffsetItem from '../../components/sectionItems/OffsetItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import ShadowItem from '../../components/sectionItems/ShadowItem';
import {resourceCenterTriggerPositionOptions} from '../ResourceCenter';
import './_Styles.scss';
import DropdownTypeResourceCenterTriggerPicker from './components/DropdownTypePicker';

const ResourceCenterTrigger = () => {
  const {
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    updateBlock: uptBlock,
    selectedStep: step,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_RESOURCE_CENTER_TRIGGER, updateObj);
  };

  const block = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_TRIGGER
  );

  if (block == null) {
    return <></>;
  }

  const {style} = block;

  return (
    <div className="block-settings resource-center-trigger">
      <Section title="Type">
        <div className="section-item resource-center-trigger-type-picker">
          <DropdownTypeResourceCenterTriggerPicker
            value={style.type}
            onChange={(value) =>
              updateBlock({
                style: {...style, type: value},
              })
            }
          />
        </div>
      </Section>
      <Divider />
      <Section title="Position">
        <SectionItem title="Position">
          <RadioGroup
            options={resourceCenterTriggerPositionOptions}
            value={evolution?.style?.position}
            onSelect={(value) =>
              setEvolution({
                ...evolution,
                style: {...evolution.style, position: value},
              })
            }
          />
        </SectionItem>
        <OffsetItem
          value={evolution?.style}
          onChange={(value) =>
            setEvolution({
              ...evolution,
              style: {
                ...evolution.style,
                ...value,
              },
            })
          }
        />
      </Section>
      <Divider />
      <Section title="Layout">
        <GapItem
          value={style.gap}
          onChange={(value) =>
            updateBlock({
              style: {...style, gap: value},
            })
          }
        />
        <PaddingItem
          value={{
            paddingTop: style.paddingTop,
            paddingBottom: style.paddingBottom,
            paddingLeft: style.paddingLeft,
            paddingRight: style.paddingRight,
          }}
          onChange={(value) =>
            updateBlock({
              style: {...style, ...value},
            })
          }
        />
      </Section>
      <Divider />
      <Section title="Style">
        <RadiusItem
          value={style.borderRadius || 0}
          onChange={(value) =>
            updateBlock({
              style: {
                ...style,
                borderRadius: value,
              },
            })
          }
          max={50}
        />
        <ColorItem
          title="Background"
          value={style.backgroundColor || null}
          onChange={(value) =>
            updateBlock({
              style: {
                ...style,
                backgroundColor: value,
              },
            })
          }
        />
        <ColorItem
          title="Border"
          value={style.borderColor || null}
          onChange={(value) =>
            updateBlock({
              style: {
                ...style,
                borderColor: value,
              },
            })
          }
        />
        <ShadowItem
          value={style.shadow}
          onChange={(value) =>
            updateBlock({
              style: {...style, shadow: value},
            })
          }
        />
      </Section>
      <Divider />
    </div>
  );
};

export default ResourceCenterTrigger;
