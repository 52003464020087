export const twemojiIcons = [
  {
    label: 'grinning face',
  },
  {
    label: 'grinning face with big eyes',
  },
  {
    label: 'grinning face with smiling eyes',
  },
  {
    label: 'beaming face with smiling eyes',
  },
  {
    label: 'grinning squinting face',
  },
  {
    label: 'grinning face with sweat',
  },
  {
    label: 'rolling on the floor laughing',
  },
  {
    label: 'face with tears of joy',
  },
  {
    label: 'slightly smiling face',
  },
  {
    label: 'upside-down face',
  },
  {
    label: 'melting face',
  },
  {
    label: 'winking face',
  },
  {
    label: 'smiling face with smiling eyes',
  },
  {
    label: 'smiling face with halo',
  },
  {
    label: 'smiling face with hearts',
  },
  {
    label: 'smiling face with heart-eyes',
  },
  {
    label: 'star-struck',
  },
  {
    label: 'face blowing a kiss',
  },
  {
    label: 'kissing face',
  },
  {
    label: 'smiling face',
  },
  {
    label: 'kissing face with closed eyes',
  },
  {
    label: 'kissing face with smiling eyes',
  },
  {
    label: 'smiling face with tear',
  },
  {
    label: 'face savoring food',
  },
  {
    label: 'face with tongue',
  },
  {
    label: 'winking face with tongue',
  },
  {
    label: 'zany face',
  },
  {
    label: 'squinting face with tongue',
  },
  {
    label: 'money-mouth face',
  },
  {
    label: 'smiling face with open hands',
  },
  {
    label: 'face with hand over mouth',
  },
  {
    label: 'face with open eyes and hand over mouth',
  },
  {
    label: 'face with peeking eye',
  },
  {
    label: 'shushing face',
  },
  {
    label: 'thinking face',
  },
  {
    label: 'saluting face',
  },
  {
    label: 'zipper-mouth face',
  },
  {
    label: 'face with raised eyebrow',
  },
  {
    label: 'neutral face',
  },
  {
    label: 'expressionless face',
  },
  {
    label: 'face without mouth',
  },
  {
    label: 'dotted line face',
  },
  {
    label: 'face in clouds',
  },
  {
    label: 'smirking face',
  },
  {
    label: 'unamused face',
  },
  {
    label: 'face with rolling eyes',
  },
  {
    label: 'grimacing face',
  },
  {
    label: 'face exhaling',
  },
  {
    label: 'lying face',
  },
  {
    label: 'shaking face',
  },
  {
    label: 'head shaking horizontally',
  },
  {
    label: 'head shaking vertically',
  },
  {
    label: 'relieved face',
  },
  {
    label: 'pensive face',
  },
  {
    label: 'sleepy face',
  },
  {
    label: 'drooling face',
  },
  {
    label: 'sleeping face',
  },
  {
    label: 'face with bags under eyes',
  },
  {
    label: 'face with medical mask',
  },
  {
    label: 'face with thermometer',
  },
  {
    label: 'face with head-bandage',
  },
  {
    label: 'nauseated face',
  },
  {
    label: 'face vomiting',
  },
  {
    label: 'sneezing face',
  },
  {
    label: 'hot face',
  },
  {
    label: 'cold face',
  },
  {
    label: 'woozy face',
  },
  {
    label: 'face with crossed-out eyes',
  },
  {
    label: 'face with spiral eyes',
  },
  {
    label: 'exploding head',
  },
  {
    label: 'cowboy hat face',
  },
  {
    label: 'partying face',
  },
  {
    label: 'disguised face',
  },
  {
    label: 'smiling face with sunglasses',
  },
  {
    label: 'nerd face',
  },
  {
    label: 'face with monocle',
  },
  {
    label: 'confused face',
  },
  {
    label: 'face with diagonal mouth',
  },
  {
    label: 'worried face',
  },
  {
    label: 'slightly frowning face',
  },
  {
    label: 'frowning face',
  },
  {
    label: 'face with open mouth',
  },
  {
    label: 'hushed face',
  },
  {
    label: 'astonished face',
  },
  {
    label: 'flushed face',
  },
  {
    label: 'pleading face',
  },
  {
    label: 'face holding back tears',
  },
  {
    label: 'frowning face with open mouth',
  },
  {
    label: 'anguished face',
  },
  {
    label: 'fearful face',
  },
  {
    label: 'anxious face with sweat',
  },
  {
    label: 'sad but relieved face',
  },
  {
    label: 'crying face',
  },
  {
    label: 'loudly crying face',
  },
  {
    label: 'face screaming in fear',
  },
  {
    label: 'confounded face',
  },
  {
    label: 'persevering face',
  },
  {
    label: 'disappointed face',
  },
  {
    label: 'downcast face with sweat',
  },
  {
    label: 'weary face',
  },
  {
    label: 'tired face',
  },
  {
    label: 'yawning face',
  },
  {
    label: 'face with steam from nose',
  },
  {
    label: 'enraged face',
  },
  {
    label: 'angry face',
  },
  {
    label: 'face with symbols on mouth',
  },
  {
    label: 'smiling face with horns',
  },
  {
    label: 'angry face with horns',
  },
  {
    label: 'skull',
  },
  {
    label: 'skull and crossbones',
  },
  {
    label: 'pile of poo',
  },
  {
    label: 'clown face',
  },
  {
    label: 'ogre',
  },
  {
    label: 'goblin',
  },
  {
    label: 'ghost',
  },
  {
    label: 'alien',
  },
  {
    label: 'alien monster',
  },
  {
    label: 'robot',
  },
  {
    label: 'grinning cat',
  },
  {
    label: 'grinning cat with smiling eyes',
  },
  {
    label: 'cat with tears of joy',
  },
  {
    label: 'smiling cat with heart-eyes',
  },
  {
    label: 'cat with wry smile',
  },
  {
    label: 'kissing cat',
  },
  {
    label: 'weary cat',
  },
  {
    label: 'crying cat',
  },
  {
    label: 'pouting cat',
  },
  {
    label: 'see-no-evil monkey',
  },
  {
    label: 'hear-no-evil monkey',
  },
  {
    label: 'speak-no-evil monkey',
  },
  {
    label: 'love letter',
  },
  {
    label: 'heart with arrow',
  },
  {
    label: 'heart with ribbon',
  },
  {
    label: 'sparkling heart',
  },
  {
    label: 'growing heart',
  },
  {
    label: 'beating heart',
  },
  {
    label: 'revolving hearts',
  },
  {
    label: 'two hearts',
  },
  {
    label: 'heart decoration',
  },
  {
    label: 'heart exclamation',
  },
  {
    label: 'broken heart',
  },
  {
    label: 'heart on fire',
  },
  {
    label: 'mending heart',
  },
  {
    label: 'red heart',
  },
  {
    label: 'pink heart',
  },
  {
    label: 'orange heart',
  },
  {
    label: 'yellow heart',
  },
  {
    label: 'green heart',
  },
  {
    label: 'blue heart',
  },
  {
    label: 'light blue heart',
  },
  {
    label: 'purple heart',
  },
  {
    label: 'brown heart',
  },
  {
    label: 'black heart',
  },
  {
    label: 'grey heart',
  },
  {
    label: 'white heart',
  },
  {
    label: 'kiss mark',
  },
  {
    label: 'hundred points',
  },
  {
    label: 'anger symbol',
  },
  {
    label: 'collision',
  },
  {
    label: 'dizzy',
  },
  {
    label: 'sweat droplets',
  },
  {
    label: 'dashing away',
  },
  {
    label: 'hole',
  },
  {
    label: 'speech balloon',
  },
  {
    label: 'eye in speech bubble',
  },
  {
    label: 'left speech bubble',
  },
  {
    label: 'right anger bubble',
  },
  {
    label: 'thought balloon',
  },
  {
    label: 'ZZZ',
  },
  {
    label: 'waving hand',
  },
  {
    label: 'waving hand: medium-light skin tone',
  },
  {
    label: 'waving hand: medium skin tone',
  },
  {
    label: 'waving hand: medium-dark skin tone',
  },
  {
    label: 'waving hand: dark skin tone',
  },
  {
    label: 'raised back of hand',
  },
  {
    label: 'raised back of hand: medium-light skin tone',
  },
  {
    label: 'raised back of hand: medium skin tone',
  },
  {
    label: 'raised back of hand: medium-dark skin tone',
  },
  {
    label: 'raised back of hand: dark skin tone',
  },
  {
    label: 'hand with fingers splayed',
  },
  {
    label: 'hand with fingers splayed: medium-light skin tone',
  },
  {
    label: 'hand with fingers splayed: medium skin tone',
  },
  {
    label: 'hand with fingers splayed: medium-dark skin tone',
  },
  {
    label: 'hand with fingers splayed: dark skin tone',
  },
  {
    label: 'raised hand',
  },
  {
    label: 'raised hand: medium-light skin tone',
  },
  {
    label: 'raised hand: medium skin tone',
  },
  {
    label: 'raised hand: medium-dark skin tone',
  },
  {
    label: 'raised hand: dark skin tone',
  },
  {
    label: 'vulcan salute',
  },
  {
    label: 'vulcan salute: medium-light skin tone',
  },
  {
    label: 'vulcan salute: medium skin tone',
  },
  {
    label: 'vulcan salute: medium-dark skin tone',
  },
  {
    label: 'vulcan salute: dark skin tone',
  },
  {
    label: 'rightwards hand',
  },
  {
    label: 'rightwards hand: medium-light skin tone',
  },
  {
    label: 'rightwards hand: medium skin tone',
  },
  {
    label: 'rightwards hand: medium-dark skin tone',
  },
  {
    label: 'rightwards hand: dark skin tone',
  },
  {
    label: 'leftwards hand',
  },
  {
    label: 'leftwards hand: medium-light skin tone',
  },
  {
    label: 'leftwards hand: medium skin tone',
  },
  {
    label: 'leftwards hand: medium-dark skin tone',
  },
  {
    label: 'leftwards hand: dark skin tone',
  },
  {
    label: 'palm down hand',
  },
  {
    label: 'palm down hand: medium-light skin tone',
  },
  {
    label: 'palm down hand: medium skin tone',
  },
  {
    label: 'palm down hand: medium-dark skin tone',
  },
  {
    label: 'palm down hand: dark skin tone',
  },
  {
    label: 'palm up hand',
  },
  {
    label: 'palm up hand: medium-light skin tone',
  },
  {
    label: 'palm up hand: medium skin tone',
  },
  {
    label: 'palm up hand: medium-dark skin tone',
  },
  {
    label: 'palm up hand: dark skin tone',
  },
  {
    label: 'leftwards pushing hand',
  },
  {
    label: 'leftwards pushing hand: medium-light skin tone',
  },
  {
    label: 'leftwards pushing hand: medium skin tone',
  },
  {
    label: 'leftwards pushing hand: medium-dark skin tone',
  },
  {
    label: 'leftwards pushing hand: dark skin tone',
  },
  {
    label: 'rightwards pushing hand',
  },
  {
    label: 'rightwards pushing hand: medium-light skin tone',
  },
  {
    label: 'rightwards pushing hand: medium skin tone',
  },
  {
    label: 'rightwards pushing hand: medium-dark skin tone',
  },
  {
    label: 'rightwards pushing hand: dark skin tone',
  },
  {
    label: 'OK hand',
  },
  {
    label: 'OK hand: medium-light skin tone',
  },
  {
    label: 'OK hand: medium skin tone',
  },
  {
    label: 'OK hand: medium-dark skin tone',
  },
  {
    label: 'OK hand: dark skin tone',
  },
  {
    label: 'pinched fingers',
  },
  {
    label: 'pinched fingers: medium-light skin tone',
  },
  {
    label: 'pinched fingers: medium skin tone',
  },
  {
    label: 'pinched fingers: medium-dark skin tone',
  },
  {
    label: 'pinched fingers: dark skin tone',
  },
  {
    label: 'pinching hand',
  },
  {
    label: 'pinching hand: medium-light skin tone',
  },
  {
    label: 'pinching hand: medium skin tone',
  },
  {
    label: 'pinching hand: medium-dark skin tone',
  },
  {
    label: 'pinching hand: dark skin tone',
  },
  {
    label: 'victory hand',
  },
  {
    label: 'victory hand: medium-light skin tone',
  },
  {
    label: 'victory hand: medium skin tone',
  },
  {
    label: 'victory hand: medium-dark skin tone',
  },
  {
    label: 'victory hand: dark skin tone',
  },
  {
    label: 'crossed fingers',
  },
  {
    label: 'crossed fingers: medium-light skin tone',
  },
  {
    label: 'crossed fingers: medium skin tone',
  },
  {
    label: 'crossed fingers: medium-dark skin tone',
  },
  {
    label: 'crossed fingers: dark skin tone',
  },
  {
    label: 'hand with index finger and thumb crossed',
  },
  {
    label: 'hand with index finger and thumb crossed: medium skin tone',
  },
  {
    label: 'hand with index finger and thumb crossed: dark skin tone',
  },
  {
    label: 'love-you gesture',
  },
  {
    label: 'love-you gesture: medium-light skin tone',
  },
  {
    label: 'love-you gesture: medium skin tone',
  },
  {
    label: 'love-you gesture: medium-dark skin tone',
  },
  {
    label: 'love-you gesture: dark skin tone',
  },
  {
    label: 'sign of the horns',
  },
  {
    label: 'sign of the horns: medium-light skin tone',
  },
  {
    label: 'sign of the horns: medium skin tone',
  },
  {
    label: 'sign of the horns: medium-dark skin tone',
  },
  {
    label: 'sign of the horns: dark skin tone',
  },
  {
    label: 'call me hand',
  },
  {
    label: 'call me hand: medium-light skin tone',
  },
  {
    label: 'call me hand: medium skin tone',
  },
  {
    label: 'call me hand: medium-dark skin tone',
  },
  {
    label: 'call me hand: dark skin tone',
  },
  {
    label: 'backhand index pointing left',
  },
  {
    label: 'backhand index pointing left: medium-light skin tone',
  },
  {
    label: 'backhand index pointing left: medium skin tone',
  },
  {
    label: 'backhand index pointing left: medium-dark skin tone',
  },
  {
    label: 'backhand index pointing left: dark skin tone',
  },
  {
    label: 'backhand index pointing right',
  },
  {
    label: 'backhand index pointing right: medium-light skin tone',
  },
  {
    label: 'backhand index pointing right: medium skin tone',
  },
  {
    label: 'backhand index pointing right: medium-dark skin tone',
  },
  {
    label: 'backhand index pointing right: dark skin tone',
  },
  {
    label: 'backhand index pointing up',
  },
  {
    label: 'backhand index pointing up: medium-light skin tone',
  },
  {
    label: 'backhand index pointing up: medium skin tone',
  },
  {
    label: 'backhand index pointing up: medium-dark skin tone',
  },
  {
    label: 'backhand index pointing up: dark skin tone',
  },
  {
    label: 'middle finger',
  },
  {
    label: 'middle finger: medium-light skin tone',
  },
  {
    label: 'middle finger: medium skin tone',
  },
  {
    label: 'middle finger: medium-dark skin tone',
  },
  {
    label: 'middle finger: dark skin tone',
  },
  {
    label: 'backhand index pointing down',
  },
  {
    label: 'backhand index pointing down: medium-light skin tone',
  },
  {
    label: 'backhand index pointing down: medium skin tone',
  },
  {
    label: 'backhand index pointing down: medium-dark skin tone',
  },
  {
    label: 'backhand index pointing down: dark skin tone',
  },
  {
    label: 'index pointing up',
  },
  {
    label: 'index pointing up: medium-light skin tone',
  },
  {
    label: 'index pointing up: medium skin tone',
  },
  {
    label: 'index pointing up: medium-dark skin tone',
  },
  {
    label: 'index pointing up: dark skin tone',
  },
  {
    label: 'index pointing at the viewer',
  },
  {
    label: 'index pointing at the viewer: medium-light skin tone',
  },
  {
    label: 'index pointing at the viewer: medium skin tone',
  },
  {
    label: 'index pointing at the viewer: medium-dark skin tone',
  },
  {
    label: 'index pointing at the viewer: dark skin tone',
  },
  {
    label: 'thumbs up',
  },
  {
    label: 'thumbs up: medium-light skin tone',
  },
  {
    label: 'thumbs up: medium skin tone',
  },
  {
    label: 'thumbs up: medium-dark skin tone',
  },
  {
    label: 'thumbs up: dark skin tone',
  },
  {
    label: 'thumbs down',
  },
  {
    label: 'thumbs down: medium-light skin tone',
  },
  {
    label: 'thumbs down: medium skin tone',
  },
  {
    label: 'thumbs down: medium-dark skin tone',
  },
  {
    label: 'thumbs down: dark skin tone',
  },
  {
    label: 'raised fist',
  },
  {
    label: 'raised fist: medium-light skin tone',
  },
  {
    label: 'raised fist: medium skin tone',
  },
  {
    label: 'raised fist: medium-dark skin tone',
  },
  {
    label: 'raised fist: dark skin tone',
  },
  {
    label: 'oncoming fist',
  },
  {
    label: 'oncoming fist: medium-light skin tone',
  },
  {
    label: 'oncoming fist: medium skin tone',
  },
  {
    label: 'oncoming fist: medium-dark skin tone',
  },
  {
    label: 'oncoming fist: dark skin tone',
  },
  {
    label: 'left-facing fist',
  },
  {
    label: 'left-facing fist: medium-light skin tone',
  },
  {
    label: 'left-facing fist: medium skin tone',
  },
  {
    label: 'left-facing fist: medium-dark skin tone',
  },
  {
    label: 'left-facing fist: dark skin tone',
  },
  {
    label: 'right-facing fist',
  },
  {
    label: 'right-facing fist: medium-light skin tone',
  },
  {
    label: 'right-facing fist: medium skin tone',
  },
  {
    label: 'right-facing fist: medium-dark skin tone',
  },
  {
    label: 'right-facing fist: dark skin tone',
  },
  {
    label: 'clapping hands',
  },
  {
    label: 'clapping hands: medium-light skin tone',
  },
  {
    label: 'clapping hands: medium skin tone',
  },
  {
    label: 'clapping hands: medium-dark skin tone',
  },
  {
    label: 'clapping hands: dark skin tone',
  },
  {
    label: 'raising hands',
  },
  {
    label: 'raising hands: medium-light skin tone',
  },
  {
    label: 'raising hands: medium skin tone',
  },
  {
    label: 'raising hands: medium-dark skin tone',
  },
  {
    label: 'raising hands: dark skin tone',
  },
  {
    label: 'heart hands',
  },
  {
    label: 'heart hands: medium-light skin tone',
  },
  {
    label: 'heart hands: medium skin tone',
  },
  {
    label: 'heart hands: medium-dark skin tone',
  },
  {
    label: 'heart hands: dark skin tone',
  },
  {
    label: 'open hands',
  },
  {
    label: 'open hands: medium-light skin tone',
  },
  {
    label: 'open hands: medium skin tone',
  },
  {
    label: 'open hands: medium-dark skin tone',
  },
  {
    label: 'open hands: dark skin tone',
  },
  {
    label: 'palms up together',
  },
  {
    label: 'palms up together: medium-light skin tone',
  },
  {
    label: 'palms up together: medium skin tone',
  },
  {
    label: 'palms up together: medium-dark skin tone',
  },
  {
    label: 'palms up together: dark skin tone',
  },
  {
    label: 'handshake',
  },
  {
    label: 'handshake: medium-light skin tone',
  },
  {
    label: 'handshake: medium skin tone',
  },
  {
    label: 'handshake: medium-dark skin tone',
  },
  {
    label: 'handshake: dark skin tone',
  },
  {
    label: 'handshake: light skin tone, medium-light skin tone',
  },
  {
    label: 'handshake: light skin tone, medium skin tone',
  },
  {
    label: 'handshake: light skin tone, medium-dark skin tone',
  },
  {
    label: 'handshake: light skin tone, dark skin tone',
  },
  {
    label: 'handshake: medium-light skin tone, light skin tone',
  },
  {
    label: 'handshake: medium-light skin tone, medium skin tone',
  },
  {
    label: 'handshake: medium-light skin tone, medium-dark skin tone',
  },
  {
    label: 'handshake: medium-light skin tone, dark skin tone',
  },
  {
    label: 'handshake: medium skin tone, light skin tone',
  },
  {
    label: 'handshake: medium skin tone, medium-light skin tone',
  },
  {
    label: 'handshake: medium skin tone, medium-dark skin tone',
  },
  {
    label: 'handshake: medium skin tone, dark skin tone',
  },
  {
    label: 'handshake: medium-dark skin tone, light skin tone',
  },
  {
    label: 'handshake: medium-dark skin tone, medium-light skin tone',
  },
  {
    label: 'handshake: medium-dark skin tone, medium skin tone',
  },
  {
    label: 'handshake: medium-dark skin tone, dark skin tone',
  },
  {
    label: 'handshake: dark skin tone, light skin tone',
  },
  {
    label: 'handshake: dark skin tone, medium-light skin tone',
  },
  {
    label: 'handshake: dark skin tone, medium skin tone',
  },
  {
    label: 'handshake: dark skin tone, medium-dark skin tone',
  },
  {
    label: 'folded hands',
  },
  {
    label: 'folded hands: medium-light skin tone',
  },
  {
    label: 'folded hands: medium skin tone',
  },
  {
    label: 'folded hands: medium-dark skin tone',
  },
  {
    label: 'folded hands: dark skin tone',
  },
  {
    label: 'writing hand',
  },
  {
    label: 'writing hand: medium-light skin tone',
  },
  {
    label: 'writing hand: medium skin tone',
  },
  {
    label: 'writing hand: medium-dark skin tone',
  },
  {
    label: 'writing hand: dark skin tone',
  },
  {
    label: 'nail polish',
  },
  {
    label: 'nail polish: medium-light skin tone',
  },
  {
    label: 'nail polish: medium skin tone',
  },
  {
    label: 'nail polish: medium-dark skin tone',
  },
  {
    label: 'nail polish: dark skin tone',
  },
  {
    label: 'selfie',
  },
  {
    label: 'selfie: medium-light skin tone',
  },
  {
    label: 'selfie: medium skin tone',
  },
  {
    label: 'selfie: medium-dark skin tone',
  },
  {
    label: 'selfie: dark skin tone',
  },
  {
    label: 'flexed biceps',
  },
  {
    label: 'flexed biceps: medium-light skin tone',
  },
  {
    label: 'flexed biceps: medium skin tone',
  },
  {
    label: 'flexed biceps: medium-dark skin tone',
  },
  {
    label: 'flexed biceps: dark skin tone',
  },
  {
    label: 'mechanical arm',
  },
  {
    label: 'mechanical leg',
  },
  {
    label: 'leg',
  },
  {
    label: 'leg: medium-light skin tone',
  },
  {
    label: 'leg: medium skin tone',
  },
  {
    label: 'leg: medium-dark skin tone',
  },
  {
    label: 'leg: dark skin tone',
  },
  {
    label: 'foot',
  },
  {
    label: 'foot: medium-light skin tone',
  },
  {
    label: 'foot: medium skin tone',
  },
  {
    label: 'foot: medium-dark skin tone',
  },
  {
    label: 'foot: dark skin tone',
  },
  {
    label: 'ear',
  },
  {
    label: 'ear: medium-light skin tone',
  },
  {
    label: 'ear: medium skin tone',
  },
  {
    label: 'ear: medium-dark skin tone',
  },
  {
    label: 'ear: dark skin tone',
  },
  {
    label: 'ear with hearing aid',
  },
  {
    label: 'ear with hearing aid: medium-light skin tone',
  },
  {
    label: 'ear with hearing aid: medium skin tone',
  },
  {
    label: 'ear with hearing aid: medium-dark skin tone',
  },
  {
    label: 'ear with hearing aid: dark skin tone',
  },
  {
    label: 'nose',
  },
  {
    label: 'nose: medium-light skin tone',
  },
  {
    label: 'nose: medium skin tone',
  },
  {
    label: 'nose: medium-dark skin tone',
  },
  {
    label: 'nose: dark skin tone',
  },
  {
    label: 'brain',
  },
  {
    label: 'anatomical heart',
  },
  {
    label: 'lungs',
  },
  {
    label: 'tooth',
  },
  {
    label: 'bone',
  },
  {
    label: 'eyes',
  },
  {
    label: 'eye',
  },
  {
    label: 'tongue',
  },
  {
    label: 'mouth',
  },
  {
    label: 'biting lip',
  },
  {
    label: 'baby',
  },
  {
    label: 'baby: medium-light skin tone',
  },
  {
    label: 'baby: medium skin tone',
  },
  {
    label: 'baby: medium-dark skin tone',
  },
  {
    label: 'baby: dark skin tone',
  },
  {
    label: 'child',
  },
  {
    label: 'child: medium-light skin tone',
  },
  {
    label: 'child: medium skin tone',
  },
  {
    label: 'child: medium-dark skin tone',
  },
  {
    label: 'child: dark skin tone',
  },
  {
    label: 'boy',
  },
  {
    label: 'boy: medium-light skin tone',
  },
  {
    label: 'boy: medium skin tone',
  },
  {
    label: 'boy: medium-dark skin tone',
  },
  {
    label: 'boy: dark skin tone',
  },
  {
    label: 'girl',
  },
  {
    label: 'girl: medium-light skin tone',
  },
  {
    label: 'girl: medium skin tone',
  },
  {
    label: 'girl: medium-dark skin tone',
  },
  {
    label: 'girl: dark skin tone',
  },
  {
    label: 'person',
  },
  {
    label: 'person: medium-light skin tone',
  },
  {
    label: 'person: medium skin tone',
  },
  {
    label: 'person: medium-dark skin tone',
  },
  {
    label: 'person: dark skin tone',
  },
  {
    label: 'person: blond hair',
  },
  {
    label: 'person: medium-light skin tone, blond hair',
  },
  {
    label: 'person: medium skin tone, blond hair',
  },
  {
    label: 'person: medium-dark skin tone, blond hair',
  },
  {
    label: 'person: dark skin tone, blond hair',
  },
  {
    label: 'man',
  },
  {
    label: 'man: medium-light skin tone',
  },
  {
    label: 'man: medium skin tone',
  },
  {
    label: 'man: medium-dark skin tone',
  },
  {
    label: 'man: dark skin tone',
  },
  {
    label: 'person: beard',
  },
  {
    label: 'person: medium-light skin tone, beard',
  },
  {
    label: 'person: medium skin tone, beard',
  },
  {
    label: 'person: medium-dark skin tone, beard',
  },
  {
    label: 'person: dark skin tone, beard',
  },
  {
    label: 'man: beard',
  },
  {
    label: 'man: medium-light skin tone, beard',
  },
  {
    label: 'man: medium skin tone, beard',
  },
  {
    label: 'man: medium-dark skin tone, beard',
  },
  {
    label: 'man: dark skin tone, beard',
  },
  {
    label: 'woman: beard',
  },
  {
    label: 'woman: medium-light skin tone, beard',
  },
  {
    label: 'woman: medium skin tone, beard',
  },
  {
    label: 'woman: medium-dark skin tone, beard',
  },
  {
    label: 'woman: dark skin tone, beard',
  },
  {
    label: 'man: red hair',
  },
  {
    label: 'man: medium-light skin tone, red hair',
  },
  {
    label: 'man: medium skin tone, red hair',
  },
  {
    label: 'man: medium-dark skin tone, red hair',
  },
  {
    label: 'man: dark skin tone, red hair',
  },
  {
    label: 'man: curly hair',
  },
  {
    label: 'man: medium-light skin tone, curly hair',
  },
  {
    label: 'man: medium skin tone, curly hair',
  },
  {
    label: 'man: medium-dark skin tone, curly hair',
  },
  {
    label: 'man: dark skin tone, curly hair',
  },
  {
    label: 'man: white hair',
  },
  {
    label: 'man: medium-light skin tone, white hair',
  },
  {
    label: 'man: medium skin tone, white hair',
  },
  {
    label: 'man: medium-dark skin tone, white hair',
  },
  {
    label: 'man: dark skin tone, white hair',
  },
  {
    label: 'man: bald',
  },
  {
    label: 'man: medium-light skin tone, bald',
  },
  {
    label: 'man: medium skin tone, bald',
  },
  {
    label: 'man: medium-dark skin tone, bald',
  },
  {
    label: 'man: dark skin tone, bald',
  },
  {
    label: 'woman',
  },
  {
    label: 'woman: medium-light skin tone',
  },
  {
    label: 'woman: medium skin tone',
  },
  {
    label: 'woman: medium-dark skin tone',
  },
  {
    label: 'woman: dark skin tone',
  },
  {
    label: 'woman: red hair',
  },
  {
    label: 'woman: medium-light skin tone, red hair',
  },
  {
    label: 'woman: medium skin tone, red hair',
  },
  {
    label: 'woman: medium-dark skin tone, red hair',
  },
  {
    label: 'woman: dark skin tone, red hair',
  },
  {
    label: 'person: red hair',
  },
  {
    label: 'person: medium-light skin tone, red hair',
  },
  {
    label: 'person: medium skin tone, red hair',
  },
  {
    label: 'person: medium-dark skin tone, red hair',
  },
  {
    label: 'person: dark skin tone, red hair',
  },
  {
    label: 'woman: curly hair',
  },
  {
    label: 'woman: medium-light skin tone, curly hair',
  },
  {
    label: 'woman: medium skin tone, curly hair',
  },
  {
    label: 'woman: medium-dark skin tone, curly hair',
  },
  {
    label: 'woman: dark skin tone, curly hair',
  },
  {
    label: 'person: curly hair',
  },
  {
    label: 'person: medium-light skin tone, curly hair',
  },
  {
    label: 'person: medium skin tone, curly hair',
  },
  {
    label: 'person: medium-dark skin tone, curly hair',
  },
  {
    label: 'person: dark skin tone, curly hair',
  },
  {
    label: 'woman: white hair',
  },
  {
    label: 'woman: medium-light skin tone, white hair',
  },
  {
    label: 'woman: medium skin tone, white hair',
  },
  {
    label: 'woman: medium-dark skin tone, white hair',
  },
  {
    label: 'woman: dark skin tone, white hair',
  },
  {
    label: 'person: white hair',
  },
  {
    label: 'person: medium-light skin tone, white hair',
  },
  {
    label: 'person: medium skin tone, white hair',
  },
  {
    label: 'person: medium-dark skin tone, white hair',
  },
  {
    label: 'person: dark skin tone, white hair',
  },
  {
    label: 'woman: bald',
  },
  {
    label: 'woman: medium-light skin tone, bald',
  },
  {
    label: 'woman: medium skin tone, bald',
  },
  {
    label: 'woman: medium-dark skin tone, bald',
  },
  {
    label: 'woman: dark skin tone, bald',
  },
  {
    label: 'person: bald',
  },
  {
    label: 'person: medium-light skin tone, bald',
  },
  {
    label: 'person: medium skin tone, bald',
  },
  {
    label: 'person: medium-dark skin tone, bald',
  },
  {
    label: 'person: dark skin tone, bald',
  },
  {
    label: 'woman: blond hair',
  },
  {
    label: 'woman: medium-light skin tone, blond hair',
  },
  {
    label: 'woman: medium skin tone, blond hair',
  },
  {
    label: 'woman: medium-dark skin tone, blond hair',
  },
  {
    label: 'woman: dark skin tone, blond hair',
  },
  {
    label: 'man: blond hair',
  },
  {
    label: 'man: medium-light skin tone, blond hair',
  },
  {
    label: 'man: medium skin tone, blond hair',
  },
  {
    label: 'man: medium-dark skin tone, blond hair',
  },
  {
    label: 'man: dark skin tone, blond hair',
  },
  {
    label: 'older person',
  },
  {
    label: 'older person: medium-light skin tone',
  },
  {
    label: 'older person: medium skin tone',
  },
  {
    label: 'older person: medium-dark skin tone',
  },
  {
    label: 'older person: dark skin tone',
  },
  {
    label: 'old man',
  },
  {
    label: 'old man: medium-light skin tone',
  },
  {
    label: 'old man: medium skin tone',
  },
  {
    label: 'old man: medium-dark skin tone',
  },
  {
    label: 'old man: dark skin tone',
  },
  {
    label: 'old woman',
  },
  {
    label: 'old woman: medium-light skin tone',
  },
  {
    label: 'old woman: medium skin tone',
  },
  {
    label: 'old woman: medium-dark skin tone',
  },
  {
    label: 'old woman: dark skin tone',
  },
  {
    label: 'person frowning',
  },
  {
    label: 'person frowning: medium-light skin tone',
  },
  {
    label: 'person frowning: medium skin tone',
  },
  {
    label: 'person frowning: medium-dark skin tone',
  },
  {
    label: 'person frowning: dark skin tone',
  },
  {
    label: 'man frowning',
  },
  {
    label: 'man frowning: medium-light skin tone',
  },
  {
    label: 'man frowning: medium skin tone',
  },
  {
    label: 'man frowning: medium-dark skin tone',
  },
  {
    label: 'man frowning: dark skin tone',
  },
  {
    label: 'woman frowning',
  },
  {
    label: 'woman frowning: medium-light skin tone',
  },
  {
    label: 'woman frowning: medium skin tone',
  },
  {
    label: 'woman frowning: medium-dark skin tone',
  },
  {
    label: 'woman frowning: dark skin tone',
  },
  {
    label: 'person pouting',
  },
  {
    label: 'person pouting: medium-light skin tone',
  },
  {
    label: 'person pouting: medium skin tone',
  },
  {
    label: 'person pouting: medium-dark skin tone',
  },
  {
    label: 'person pouting: dark skin tone',
  },
  {
    label: 'man pouting',
  },
  {
    label: 'man pouting: medium-light skin tone',
  },
  {
    label: 'man pouting: medium skin tone',
  },
  {
    label: 'man pouting: medium-dark skin tone',
  },
  {
    label: 'man pouting: dark skin tone',
  },
  {
    label: 'woman pouting',
  },
  {
    label: 'woman pouting: medium-light skin tone',
  },
  {
    label: 'woman pouting: medium skin tone',
  },
  {
    label: 'woman pouting: medium-dark skin tone',
  },
  {
    label: 'woman pouting: dark skin tone',
  },
  {
    label: 'person gesturing NO',
  },
  {
    label: 'person gesturing NO: medium-light skin tone',
  },
  {
    label: 'person gesturing NO: medium skin tone',
  },
  {
    label: 'person gesturing NO: medium-dark skin tone',
  },
  {
    label: 'person gesturing NO: dark skin tone',
  },
  {
    label: 'man gesturing NO',
  },
  {
    label: 'man gesturing NO: medium-light skin tone',
  },
  {
    label: 'man gesturing NO: medium skin tone',
  },
  {
    label: 'man gesturing NO: medium-dark skin tone',
  },
  {
    label: 'man gesturing NO: dark skin tone',
  },
  {
    label: 'woman gesturing NO',
  },
  {
    label: 'woman gesturing NO: medium-light skin tone',
  },
  {
    label: 'woman gesturing NO: medium skin tone',
  },
  {
    label: 'woman gesturing NO: medium-dark skin tone',
  },
  {
    label: 'woman gesturing NO: dark skin tone',
  },
  {
    label: 'person gesturing OK',
  },
  {
    label: 'person gesturing OK: medium-light skin tone',
  },
  {
    label: 'person gesturing OK: medium skin tone',
  },
  {
    label: 'person gesturing OK: medium-dark skin tone',
  },
  {
    label: 'person gesturing OK: dark skin tone',
  },
  {
    label: 'man gesturing OK',
  },
  {
    label: 'man gesturing OK: medium-light skin tone',
  },
  {
    label: 'man gesturing OK: medium skin tone',
  },
  {
    label: 'man gesturing OK: medium-dark skin tone',
  },
  {
    label: 'man gesturing OK: dark skin tone',
  },
  {
    label: 'woman gesturing OK',
  },
  {
    label: 'woman gesturing OK: medium-light skin tone',
  },
  {
    label: 'woman gesturing OK: medium skin tone',
  },
  {
    label: 'woman gesturing OK: medium-dark skin tone',
  },
  {
    label: 'woman gesturing OK: dark skin tone',
  },
  {
    label: 'person tipping hand',
  },
  {
    label: 'person tipping hand: medium-light skin tone',
  },
  {
    label: 'person tipping hand: medium skin tone',
  },
  {
    label: 'person tipping hand: medium-dark skin tone',
  },
  {
    label: 'person tipping hand: dark skin tone',
  },
  {
    label: 'man tipping hand',
  },
  {
    label: 'man tipping hand: medium-light skin tone',
  },
  {
    label: 'man tipping hand: medium skin tone',
  },
  {
    label: 'man tipping hand: medium-dark skin tone',
  },
  {
    label: 'man tipping hand: dark skin tone',
  },
  {
    label: 'woman tipping hand',
  },
  {
    label: 'woman tipping hand: medium-light skin tone',
  },
  {
    label: 'woman tipping hand: medium skin tone',
  },
  {
    label: 'woman tipping hand: medium-dark skin tone',
  },
  {
    label: 'woman tipping hand: dark skin tone',
  },
  {
    label: 'person raising hand',
  },
  {
    label: 'person raising hand: medium-light skin tone',
  },
  {
    label: 'person raising hand: medium skin tone',
  },
  {
    label: 'person raising hand: medium-dark skin tone',
  },
  {
    label: 'person raising hand: dark skin tone',
  },
  {
    label: 'man raising hand',
  },
  {
    label: 'man raising hand: medium-light skin tone',
  },
  {
    label: 'man raising hand: medium skin tone',
  },
  {
    label: 'man raising hand: medium-dark skin tone',
  },
  {
    label: 'man raising hand: dark skin tone',
  },
  {
    label: 'woman raising hand',
  },
  {
    label: 'woman raising hand: medium-light skin tone',
  },
  {
    label: 'woman raising hand: medium skin tone',
  },
  {
    label: 'woman raising hand: medium-dark skin tone',
  },
  {
    label: 'woman raising hand: dark skin tone',
  },
  {
    label: 'deaf person',
  },
  {
    label: 'deaf person: medium-light skin tone',
  },
  {
    label: 'deaf person: medium skin tone',
  },
  {
    label: 'deaf person: medium-dark skin tone',
  },
  {
    label: 'deaf person: dark skin tone',
  },
  {
    label: 'deaf man',
  },
  {
    label: 'deaf man: medium-light skin tone',
  },
  {
    label: 'deaf man: medium skin tone',
  },
  {
    label: 'deaf man: medium-dark skin tone',
  },
  {
    label: 'deaf man: dark skin tone',
  },
  {
    label: 'deaf woman',
  },
  {
    label: 'deaf woman: medium-light skin tone',
  },
  {
    label: 'deaf woman: medium skin tone',
  },
  {
    label: 'deaf woman: medium-dark skin tone',
  },
  {
    label: 'deaf woman: dark skin tone',
  },
  {
    label: 'person bowing',
  },
  {
    label: 'person bowing: medium-light skin tone',
  },
  {
    label: 'person bowing: medium skin tone',
  },
  {
    label: 'person bowing: medium-dark skin tone',
  },
  {
    label: 'person bowing: dark skin tone',
  },
  {
    label: 'man bowing',
  },
  {
    label: 'man bowing: medium-light skin tone',
  },
  {
    label: 'man bowing: medium skin tone',
  },
  {
    label: 'man bowing: medium-dark skin tone',
  },
  {
    label: 'man bowing: dark skin tone',
  },
  {
    label: 'woman bowing',
  },
  {
    label: 'woman bowing: medium-light skin tone',
  },
  {
    label: 'woman bowing: medium skin tone',
  },
  {
    label: 'woman bowing: medium-dark skin tone',
  },
  {
    label: 'woman bowing: dark skin tone',
  },
  {
    label: 'person facepalming',
  },
  {
    label: 'person facepalming: medium-light skin tone',
  },
  {
    label: 'person facepalming: medium skin tone',
  },
  {
    label: 'person facepalming: medium-dark skin tone',
  },
  {
    label: 'person facepalming: dark skin tone',
  },
  {
    label: 'man facepalming',
  },
  {
    label: 'man facepalming: medium-light skin tone',
  },
  {
    label: 'man facepalming: medium skin tone',
  },
  {
    label: 'man facepalming: medium-dark skin tone',
  },
  {
    label: 'man facepalming: dark skin tone',
  },
  {
    label: 'woman facepalming',
  },
  {
    label: 'woman facepalming: medium-light skin tone',
  },
  {
    label: 'woman facepalming: medium skin tone',
  },
  {
    label: 'woman facepalming: medium-dark skin tone',
  },
  {
    label: 'woman facepalming: dark skin tone',
  },
  {
    label: 'person shrugging',
  },
  {
    label: 'person shrugging: medium-light skin tone',
  },
  {
    label: 'person shrugging: medium skin tone',
  },
  {
    label: 'person shrugging: medium-dark skin tone',
  },
  {
    label: 'person shrugging: dark skin tone',
  },
  {
    label: 'man shrugging',
  },
  {
    label: 'man shrugging: medium-light skin tone',
  },
  {
    label: 'man shrugging: medium skin tone',
  },
  {
    label: 'man shrugging: medium-dark skin tone',
  },
  {
    label: 'man shrugging: dark skin tone',
  },
  {
    label: 'woman shrugging',
  },
  {
    label: 'woman shrugging: medium-light skin tone',
  },
  {
    label: 'woman shrugging: medium skin tone',
  },
  {
    label: 'woman shrugging: medium-dark skin tone',
  },
  {
    label: 'woman shrugging: dark skin tone',
  },
  {
    label: 'health worker',
  },
  {
    label: 'health worker: medium-light skin tone',
  },
  {
    label: 'health worker: medium skin tone',
  },
  {
    label: 'health worker: medium-dark skin tone',
  },
  {
    label: 'health worker: dark skin tone',
  },
  {
    label: 'man health worker',
  },
  {
    label: 'man health worker: medium-light skin tone',
  },
  {
    label: 'man health worker: medium skin tone',
  },
  {
    label: 'man health worker: medium-dark skin tone',
  },
  {
    label: 'man health worker: dark skin tone',
  },
  {
    label: 'woman health worker',
  },
  {
    label: 'woman health worker: medium-light skin tone',
  },
  {
    label: 'woman health worker: medium skin tone',
  },
  {
    label: 'woman health worker: medium-dark skin tone',
  },
  {
    label: 'woman health worker: dark skin tone',
  },
  {
    label: 'student',
  },
  {
    label: 'student: medium-light skin tone',
  },
  {
    label: 'student: medium skin tone',
  },
  {
    label: 'student: medium-dark skin tone',
  },
  {
    label: 'student: dark skin tone',
  },
  {
    label: 'man student',
  },
  {
    label: 'man student: medium-light skin tone',
  },
  {
    label: 'man student: medium skin tone',
  },
  {
    label: 'man student: medium-dark skin tone',
  },
  {
    label: 'man student: dark skin tone',
  },
  {
    label: 'woman student',
  },
  {
    label: 'woman student: medium-light skin tone',
  },
  {
    label: 'woman student: medium skin tone',
  },
  {
    label: 'woman student: medium-dark skin tone',
  },
  {
    label: 'woman student: dark skin tone',
  },
  {
    label: 'teacher',
  },
  {
    label: 'teacher: medium-light skin tone',
  },
  {
    label: 'teacher: medium skin tone',
  },
  {
    label: 'teacher: medium-dark skin tone',
  },
  {
    label: 'teacher: dark skin tone',
  },
  {
    label: 'man teacher',
  },
  {
    label: 'man teacher: medium-light skin tone',
  },
  {
    label: 'man teacher: medium skin tone',
  },
  {
    label: 'man teacher: medium-dark skin tone',
  },
  {
    label: 'man teacher: dark skin tone',
  },
  {
    label: 'woman teacher',
  },
  {
    label: 'woman teacher: medium-light skin tone',
  },
  {
    label: 'woman teacher: medium skin tone',
  },
  {
    label: 'woman teacher: medium-dark skin tone',
  },
  {
    label: 'woman teacher: dark skin tone',
  },
  {
    label: 'judge',
  },
  {
    label: 'judge: medium-light skin tone',
  },
  {
    label: 'judge: medium skin tone',
  },
  {
    label: 'judge: medium-dark skin tone',
  },
  {
    label: 'judge: dark skin tone',
  },
  {
    label: 'man judge',
  },
  {
    label: 'man judge: medium-light skin tone',
  },
  {
    label: 'man judge: medium skin tone',
  },
  {
    label: 'man judge: medium-dark skin tone',
  },
  {
    label: 'man judge: dark skin tone',
  },
  {
    label: 'woman judge',
  },
  {
    label: 'woman judge: medium-light skin tone',
  },
  {
    label: 'woman judge: medium skin tone',
  },
  {
    label: 'woman judge: medium-dark skin tone',
  },
  {
    label: 'woman judge: dark skin tone',
  },
  {
    label: 'farmer',
  },
  {
    label: 'farmer: medium-light skin tone',
  },
  {
    label: 'farmer: medium skin tone',
  },
  {
    label: 'farmer: medium-dark skin tone',
  },
  {
    label: 'farmer: dark skin tone',
  },
  {
    label: 'man farmer',
  },
  {
    label: 'man farmer: medium-light skin tone',
  },
  {
    label: 'man farmer: medium skin tone',
  },
  {
    label: 'man farmer: medium-dark skin tone',
  },
  {
    label: 'man farmer: dark skin tone',
  },
  {
    label: 'woman farmer',
  },
  {
    label: 'woman farmer: medium-light skin tone',
  },
  {
    label: 'woman farmer: medium skin tone',
  },
  {
    label: 'woman farmer: medium-dark skin tone',
  },
  {
    label: 'woman farmer: dark skin tone',
  },
  {
    label: 'cook',
  },
  {
    label: 'cook: medium-light skin tone',
  },
  {
    label: 'cook: medium skin tone',
  },
  {
    label: 'cook: medium-dark skin tone',
  },
  {
    label: 'cook: dark skin tone',
  },
  {
    label: 'man cook',
  },
  {
    label: 'man cook: medium-light skin tone',
  },
  {
    label: 'man cook: medium skin tone',
  },
  {
    label: 'man cook: medium-dark skin tone',
  },
  {
    label: 'man cook: dark skin tone',
  },
  {
    label: 'woman cook',
  },
  {
    label: 'woman cook: medium-light skin tone',
  },
  {
    label: 'woman cook: medium skin tone',
  },
  {
    label: 'woman cook: medium-dark skin tone',
  },
  {
    label: 'woman cook: dark skin tone',
  },
  {
    label: 'mechanic',
  },
  {
    label: 'mechanic: medium-light skin tone',
  },
  {
    label: 'mechanic: medium skin tone',
  },
  {
    label: 'mechanic: medium-dark skin tone',
  },
  {
    label: 'mechanic: dark skin tone',
  },
  {
    label: 'man mechanic',
  },
  {
    label: 'man mechanic: medium-light skin tone',
  },
  {
    label: 'man mechanic: medium skin tone',
  },
  {
    label: 'man mechanic: medium-dark skin tone',
  },
  {
    label: 'man mechanic: dark skin tone',
  },
  {
    label: 'woman mechanic',
  },
  {
    label: 'woman mechanic: medium-light skin tone',
  },
  {
    label: 'woman mechanic: medium skin tone',
  },
  {
    label: 'woman mechanic: medium-dark skin tone',
  },
  {
    label: 'woman mechanic: dark skin tone',
  },
  {
    label: 'factory worker',
  },
  {
    label: 'factory worker: medium-light skin tone',
  },
  {
    label: 'factory worker: medium skin tone',
  },
  {
    label: 'factory worker: medium-dark skin tone',
  },
  {
    label: 'factory worker: dark skin tone',
  },
  {
    label: 'man factory worker',
  },
  {
    label: 'man factory worker: medium-light skin tone',
  },
  {
    label: 'man factory worker: medium skin tone',
  },
  {
    label: 'man factory worker: medium-dark skin tone',
  },
  {
    label: 'man factory worker: dark skin tone',
  },
  {
    label: 'woman factory worker',
  },
  {
    label: 'woman factory worker: medium-light skin tone',
  },
  {
    label: 'woman factory worker: medium skin tone',
  },
  {
    label: 'woman factory worker: medium-dark skin tone',
  },
  {
    label: 'woman factory worker: dark skin tone',
  },
  {
    label: 'office worker',
  },
  {
    label: 'office worker: medium-light skin tone',
  },
  {
    label: 'office worker: medium skin tone',
  },
  {
    label: 'office worker: medium-dark skin tone',
  },
  {
    label: 'office worker: dark skin tone',
  },
  {
    label: 'man office worker',
  },
  {
    label: 'man office worker: medium-light skin tone',
  },
  {
    label: 'man office worker: medium skin tone',
  },
  {
    label: 'man office worker: medium-dark skin tone',
  },
  {
    label: 'man office worker: dark skin tone',
  },
  {
    label: 'woman office worker',
  },
  {
    label: 'woman office worker: medium-light skin tone',
  },
  {
    label: 'woman office worker: medium skin tone',
  },
  {
    label: 'woman office worker: medium-dark skin tone',
  },
  {
    label: 'woman office worker: dark skin tone',
  },
  {
    label: 'scientist',
  },
  {
    label: 'scientist: medium-light skin tone',
  },
  {
    label: 'scientist: medium skin tone',
  },
  {
    label: 'scientist: medium-dark skin tone',
  },
  {
    label: 'scientist: dark skin tone',
  },
  {
    label: 'man scientist',
  },
  {
    label: 'man scientist: medium-light skin tone',
  },
  {
    label: 'man scientist: medium skin tone',
  },
  {
    label: 'man scientist: medium-dark skin tone',
  },
  {
    label: 'man scientist: dark skin tone',
  },
  {
    label: 'woman scientist',
  },
  {
    label: 'woman scientist: medium-light skin tone',
  },
  {
    label: 'woman scientist: medium skin tone',
  },
  {
    label: 'woman scientist: medium-dark skin tone',
  },
  {
    label: 'woman scientist: dark skin tone',
  },
  {
    label: 'technologist',
  },
  {
    label: 'technologist: medium-light skin tone',
  },
  {
    label: 'technologist: medium skin tone',
  },
  {
    label: 'technologist: medium-dark skin tone',
  },
  {
    label: 'technologist: dark skin tone',
  },
  {
    label: 'man technologist',
  },
  {
    label: 'man technologist: medium-light skin tone',
  },
  {
    label: 'man technologist: medium skin tone',
  },
  {
    label: 'man technologist: medium-dark skin tone',
  },
  {
    label: 'man technologist: dark skin tone',
  },
  {
    label: 'woman technologist',
  },
  {
    label: 'woman technologist: medium-light skin tone',
  },
  {
    label: 'woman technologist: medium skin tone',
  },
  {
    label: 'woman technologist: medium-dark skin tone',
  },
  {
    label: 'woman technologist: dark skin tone',
  },
  {
    label: 'singer',
  },
  {
    label: 'singer: medium-light skin tone',
  },
  {
    label: 'singer: medium skin tone',
  },
  {
    label: 'singer: medium-dark skin tone',
  },
  {
    label: 'singer: dark skin tone',
  },
  {
    label: 'man singer',
  },
  {
    label: 'man singer: medium-light skin tone',
  },
  {
    label: 'man singer: medium skin tone',
  },
  {
    label: 'man singer: medium-dark skin tone',
  },
  {
    label: 'man singer: dark skin tone',
  },
  {
    label: 'woman singer',
  },
  {
    label: 'woman singer: medium-light skin tone',
  },
  {
    label: 'woman singer: medium skin tone',
  },
  {
    label: 'woman singer: medium-dark skin tone',
  },
  {
    label: 'woman singer: dark skin tone',
  },
  {
    label: 'artist',
  },
  {
    label: 'artist: medium-light skin tone',
  },
  {
    label: 'artist: medium skin tone',
  },
  {
    label: 'artist: medium-dark skin tone',
  },
  {
    label: 'artist: dark skin tone',
  },
  {
    label: 'man artist',
  },
  {
    label: 'man artist: medium-light skin tone',
  },
  {
    label: 'man artist: medium skin tone',
  },
  {
    label: 'man artist: medium-dark skin tone',
  },
  {
    label: 'man artist: dark skin tone',
  },
  {
    label: 'woman artist',
  },
  {
    label: 'woman artist: medium-light skin tone',
  },
  {
    label: 'woman artist: medium skin tone',
  },
  {
    label: 'woman artist: medium-dark skin tone',
  },
  {
    label: 'woman artist: dark skin tone',
  },
  {
    label: 'pilot',
  },
  {
    label: 'pilot: medium-light skin tone',
  },
  {
    label: 'pilot: medium skin tone',
  },
  {
    label: 'pilot: medium-dark skin tone',
  },
  {
    label: 'pilot: dark skin tone',
  },
  {
    label: 'man pilot',
  },
  {
    label: 'man pilot: medium-light skin tone',
  },
  {
    label: 'man pilot: medium skin tone',
  },
  {
    label: 'man pilot: medium-dark skin tone',
  },
  {
    label: 'man pilot: dark skin tone',
  },
  {
    label: 'woman pilot',
  },
  {
    label: 'woman pilot: medium-light skin tone',
  },
  {
    label: 'woman pilot: medium skin tone',
  },
  {
    label: 'woman pilot: medium-dark skin tone',
  },
  {
    label: 'woman pilot: dark skin tone',
  },
  {
    label: 'astronaut',
  },
  {
    label: 'astronaut: medium-light skin tone',
  },
  {
    label: 'astronaut: medium skin tone',
  },
  {
    label: 'astronaut: medium-dark skin tone',
  },
  {
    label: 'astronaut: dark skin tone',
  },
  {
    label: 'man astronaut',
  },
  {
    label: 'man astronaut: medium-light skin tone',
  },
  {
    label: 'man astronaut: medium skin tone',
  },
  {
    label: 'man astronaut: medium-dark skin tone',
  },
  {
    label: 'man astronaut: dark skin tone',
  },
  {
    label: 'woman astronaut',
  },
  {
    label: 'woman astronaut: medium-light skin tone',
  },
  {
    label: 'woman astronaut: medium skin tone',
  },
  {
    label: 'woman astronaut: medium-dark skin tone',
  },
  {
    label: 'woman astronaut: dark skin tone',
  },
  {
    label: 'firefighter',
  },
  {
    label: 'firefighter: medium-light skin tone',
  },
  {
    label: 'firefighter: medium skin tone',
  },
  {
    label: 'firefighter: medium-dark skin tone',
  },
  {
    label: 'firefighter: dark skin tone',
  },
  {
    label: 'man firefighter',
  },
  {
    label: 'man firefighter: medium-light skin tone',
  },
  {
    label: 'man firefighter: medium skin tone',
  },
  {
    label: 'man firefighter: medium-dark skin tone',
  },
  {
    label: 'man firefighter: dark skin tone',
  },
  {
    label: 'woman firefighter',
  },
  {
    label: 'woman firefighter: medium-light skin tone',
  },
  {
    label: 'woman firefighter: medium skin tone',
  },
  {
    label: 'woman firefighter: medium-dark skin tone',
  },
  {
    label: 'woman firefighter: dark skin tone',
  },
  {
    label: 'police officer',
  },
  {
    label: 'police officer: medium-light skin tone',
  },
  {
    label: 'police officer: medium skin tone',
  },
  {
    label: 'police officer: medium-dark skin tone',
  },
  {
    label: 'police officer: dark skin tone',
  },
  {
    label: 'man police officer',
  },
  {
    label: 'man police officer: medium-light skin tone',
  },
  {
    label: 'man police officer: medium skin tone',
  },
  {
    label: 'man police officer: medium-dark skin tone',
  },
  {
    label: 'man police officer: dark skin tone',
  },
  {
    label: 'woman police officer',
  },
  {
    label: 'woman police officer: medium-light skin tone',
  },
  {
    label: 'woman police officer: medium skin tone',
  },
  {
    label: 'woman police officer: medium-dark skin tone',
  },
  {
    label: 'woman police officer: dark skin tone',
  },
  {
    label: 'detective',
  },
  {
    label: 'detective: medium-light skin tone',
  },
  {
    label: 'detective: medium skin tone',
  },
  {
    label: 'detective: medium-dark skin tone',
  },
  {
    label: 'detective: dark skin tone',
  },
  {
    label: 'man detective',
  },
  {
    label: 'man detective: medium-light skin tone',
  },
  {
    label: 'man detective: medium skin tone',
  },
  {
    label: 'man detective: medium-dark skin tone',
  },
  {
    label: 'man detective: dark skin tone',
  },
  {
    label: 'woman detective',
  },
  {
    label: 'woman detective: medium-light skin tone',
  },
  {
    label: 'woman detective: medium skin tone',
  },
  {
    label: 'woman detective: medium-dark skin tone',
  },
  {
    label: 'woman detective: dark skin tone',
  },
  {
    label: 'guard',
  },
  {
    label: 'guard: medium-light skin tone',
  },
  {
    label: 'guard: medium skin tone',
  },
  {
    label: 'guard: medium-dark skin tone',
  },
  {
    label: 'guard: dark skin tone',
  },
  {
    label: 'man guard',
  },
  {
    label: 'man guard: medium-light skin tone',
  },
  {
    label: 'man guard: medium skin tone',
  },
  {
    label: 'man guard: medium-dark skin tone',
  },
  {
    label: 'man guard: dark skin tone',
  },
  {
    label: 'woman guard',
  },
  {
    label: 'woman guard: medium-light skin tone',
  },
  {
    label: 'woman guard: medium skin tone',
  },
  {
    label: 'woman guard: medium-dark skin tone',
  },
  {
    label: 'woman guard: dark skin tone',
  },
  {
    label: 'ninja',
  },
  {
    label: 'ninja: medium-light skin tone',
  },
  {
    label: 'ninja: medium skin tone',
  },
  {
    label: 'ninja: medium-dark skin tone',
  },
  {
    label: 'ninja: dark skin tone',
  },
  {
    label: 'construction worker',
  },
  {
    label: 'construction worker: medium-light skin tone',
  },
  {
    label: 'construction worker: medium skin tone',
  },
  {
    label: 'construction worker: medium-dark skin tone',
  },
  {
    label: 'construction worker: dark skin tone',
  },
  {
    label: 'man construction worker',
  },
  {
    label: 'man construction worker: medium-light skin tone',
  },
  {
    label: 'man construction worker: medium skin tone',
  },
  {
    label: 'man construction worker: medium-dark skin tone',
  },
  {
    label: 'man construction worker: dark skin tone',
  },
  {
    label: 'woman construction worker',
  },
  {
    label: 'woman construction worker: medium-light skin tone',
  },
  {
    label: 'woman construction worker: medium skin tone',
  },
  {
    label: 'woman construction worker: medium-dark skin tone',
  },
  {
    label: 'woman construction worker: dark skin tone',
  },
  {
    label: 'person with crown',
  },
  {
    label: 'person with crown: medium-light skin tone',
  },
  {
    label: 'person with crown: medium skin tone',
  },
  {
    label: 'person with crown: medium-dark skin tone',
  },
  {
    label: 'person with crown: dark skin tone',
  },
  {
    label: 'prince',
  },
  {
    label: 'prince: medium-light skin tone',
  },
  {
    label: 'prince: medium skin tone',
  },
  {
    label: 'prince: medium-dark skin tone',
  },
  {
    label: 'prince: dark skin tone',
  },
  {
    label: 'princess',
  },
  {
    label: 'princess: medium-light skin tone',
  },
  {
    label: 'princess: medium skin tone',
  },
  {
    label: 'princess: medium-dark skin tone',
  },
  {
    label: 'princess: dark skin tone',
  },
  {
    label: 'person wearing turban',
  },
  {
    label: 'person wearing turban: medium-light skin tone',
  },
  {
    label: 'person wearing turban: medium skin tone',
  },
  {
    label: 'person wearing turban: medium-dark skin tone',
  },
  {
    label: 'person wearing turban: dark skin tone',
  },
  {
    label: 'man wearing turban',
  },
  {
    label: 'man wearing turban: medium-light skin tone',
  },
  {
    label: 'man wearing turban: medium skin tone',
  },
  {
    label: 'man wearing turban: medium-dark skin tone',
  },
  {
    label: 'man wearing turban: dark skin tone',
  },
  {
    label: 'woman wearing turban',
  },
  {
    label: 'woman wearing turban: medium-light skin tone',
  },
  {
    label: 'woman wearing turban: medium skin tone',
  },
  {
    label: 'woman wearing turban: medium-dark skin tone',
  },
  {
    label: 'woman wearing turban: dark skin tone',
  },
  {
    label: 'person with skullcap',
  },
  {
    label: 'person with skullcap: medium-light skin tone',
  },
  {
    label: 'person with skullcap: medium skin tone',
  },
  {
    label: 'person with skullcap: medium-dark skin tone',
  },
  {
    label: 'person with skullcap: dark skin tone',
  },
  {
    label: 'woman with headscarf',
  },
  {
    label: 'woman with headscarf: medium-light skin tone',
  },
  {
    label: 'woman with headscarf: medium skin tone',
  },
  {
    label: 'woman with headscarf: medium-dark skin tone',
  },
  {
    label: 'woman with headscarf: dark skin tone',
  },
  {
    label: 'person in tuxedo',
  },
  {
    label: 'person in tuxedo: medium-light skin tone',
  },
  {
    label: 'person in tuxedo: medium skin tone',
  },
  {
    label: 'person in tuxedo: medium-dark skin tone',
  },
  {
    label: 'person in tuxedo: dark skin tone',
  },
  {
    label: 'man in tuxedo',
  },
  {
    label: 'man in tuxedo: medium-light skin tone',
  },
  {
    label: 'man in tuxedo: medium skin tone',
  },
  {
    label: 'man in tuxedo: medium-dark skin tone',
  },
  {
    label: 'man in tuxedo: dark skin tone',
  },
  {
    label: 'woman in tuxedo',
  },
  {
    label: 'woman in tuxedo: medium-light skin tone',
  },
  {
    label: 'woman in tuxedo: medium skin tone',
  },
  {
    label: 'woman in tuxedo: medium-dark skin tone',
  },
  {
    label: 'woman in tuxedo: dark skin tone',
  },
  {
    label: 'person with veil',
  },
  {
    label: 'person with veil: medium-light skin tone',
  },
  {
    label: 'person with veil: medium skin tone',
  },
  {
    label: 'person with veil: medium-dark skin tone',
  },
  {
    label: 'person with veil: dark skin tone',
  },
  {
    label: 'man with veil',
  },
  {
    label: 'man with veil: medium-light skin tone',
  },
  {
    label: 'man with veil: medium skin tone',
  },
  {
    label: 'man with veil: medium-dark skin tone',
  },
  {
    label: 'man with veil: dark skin tone',
  },
  {
    label: 'woman with veil',
  },
  {
    label: 'woman with veil: medium-light skin tone',
  },
  {
    label: 'woman with veil: medium skin tone',
  },
  {
    label: 'woman with veil: medium-dark skin tone',
  },
  {
    label: 'woman with veil: dark skin tone',
  },
  {
    label: 'pregnant woman',
  },
  {
    label: 'pregnant woman: medium-light skin tone',
  },
  {
    label: 'pregnant woman: medium skin tone',
  },
  {
    label: 'pregnant woman: medium-dark skin tone',
  },
  {
    label: 'pregnant woman: dark skin tone',
  },
  {
    label: 'pregnant man',
  },
  {
    label: 'pregnant man: medium-light skin tone',
  },
  {
    label: 'pregnant man: medium skin tone',
  },
  {
    label: 'pregnant man: medium-dark skin tone',
  },
  {
    label: 'pregnant man: dark skin tone',
  },
  {
    label: 'pregnant person',
  },
  {
    label: 'pregnant person: medium-light skin tone',
  },
  {
    label: 'pregnant person: medium skin tone',
  },
  {
    label: 'pregnant person: medium-dark skin tone',
  },
  {
    label: 'pregnant person: dark skin tone',
  },
  {
    label: 'breast-feeding',
  },
  {
    label: 'breast-feeding: medium-light skin tone',
  },
  {
    label: 'breast-feeding: medium skin tone',
  },
  {
    label: 'breast-feeding: medium-dark skin tone',
  },
  {
    label: 'breast-feeding: dark skin tone',
  },
  {
    label: 'woman feeding baby',
  },
  {
    label: 'woman feeding baby: medium-light skin tone',
  },
  {
    label: 'woman feeding baby: medium skin tone',
  },
  {
    label: 'woman feeding baby: medium-dark skin tone',
  },
  {
    label: 'woman feeding baby: dark skin tone',
  },
  {
    label: 'man feeding baby',
  },
  {
    label: 'man feeding baby: medium-light skin tone',
  },
  {
    label: 'man feeding baby: medium skin tone',
  },
  {
    label: 'man feeding baby: medium-dark skin tone',
  },
  {
    label: 'man feeding baby: dark skin tone',
  },
  {
    label: 'person feeding baby',
  },
  {
    label: 'person feeding baby: medium-light skin tone',
  },
  {
    label: 'person feeding baby: medium skin tone',
  },
  {
    label: 'person feeding baby: medium-dark skin tone',
  },
  {
    label: 'person feeding baby: dark skin tone',
  },
  {
    label: 'baby angel',
  },
  {
    label: 'baby angel: medium-light skin tone',
  },
  {
    label: 'baby angel: medium skin tone',
  },
  {
    label: 'baby angel: medium-dark skin tone',
  },
  {
    label: 'baby angel: dark skin tone',
  },
  {
    label: 'Santa Claus',
  },
  {
    label: 'Santa Claus: medium-light skin tone',
  },
  {
    label: 'Santa Claus: medium skin tone',
  },
  {
    label: 'Santa Claus: medium-dark skin tone',
  },
  {
    label: 'Santa Claus: dark skin tone',
  },
  {
    label: 'Mrs. Claus',
  },
  {
    label: 'Mrs. Claus: medium-light skin tone',
  },
  {
    label: 'Mrs. Claus: medium skin tone',
  },
  {
    label: 'Mrs. Claus: medium-dark skin tone',
  },
  {
    label: 'Mrs. Claus: dark skin tone',
  },
  {
    label: 'Mx Claus',
  },
  {
    label: 'Mx Claus: medium-light skin tone',
  },
  {
    label: 'Mx Claus: medium skin tone',
  },
  {
    label: 'Mx Claus: medium-dark skin tone',
  },
  {
    label: 'Mx Claus: dark skin tone',
  },
  {
    label: 'superhero',
  },
  {
    label: 'superhero: medium-light skin tone',
  },
  {
    label: 'superhero: medium skin tone',
  },
  {
    label: 'superhero: medium-dark skin tone',
  },
  {
    label: 'superhero: dark skin tone',
  },
  {
    label: 'man superhero',
  },
  {
    label: 'man superhero: medium-light skin tone',
  },
  {
    label: 'man superhero: medium skin tone',
  },
  {
    label: 'man superhero: medium-dark skin tone',
  },
  {
    label: 'man superhero: dark skin tone',
  },
  {
    label: 'woman superhero',
  },
  {
    label: 'woman superhero: medium-light skin tone',
  },
  {
    label: 'woman superhero: medium skin tone',
  },
  {
    label: 'woman superhero: medium-dark skin tone',
  },
  {
    label: 'woman superhero: dark skin tone',
  },
  {
    label: 'supervillain',
  },
  {
    label: 'supervillain: medium-light skin tone',
  },
  {
    label: 'supervillain: medium skin tone',
  },
  {
    label: 'supervillain: medium-dark skin tone',
  },
  {
    label: 'supervillain: dark skin tone',
  },
  {
    label: 'man supervillain',
  },
  {
    label: 'man supervillain: medium-light skin tone',
  },
  {
    label: 'man supervillain: medium skin tone',
  },
  {
    label: 'man supervillain: medium-dark skin tone',
  },
  {
    label: 'man supervillain: dark skin tone',
  },
  {
    label: 'woman supervillain',
  },
  {
    label: 'woman supervillain: medium-light skin tone',
  },
  {
    label: 'woman supervillain: medium skin tone',
  },
  {
    label: 'woman supervillain: medium-dark skin tone',
  },
  {
    label: 'woman supervillain: dark skin tone',
  },
  {
    label: 'mage',
  },
  {
    label: 'mage: medium-light skin tone',
  },
  {
    label: 'mage: medium skin tone',
  },
  {
    label: 'mage: medium-dark skin tone',
  },
  {
    label: 'mage: dark skin tone',
  },
  {
    label: 'man mage',
  },
  {
    label: 'man mage: medium-light skin tone',
  },
  {
    label: 'man mage: medium skin tone',
  },
  {
    label: 'man mage: medium-dark skin tone',
  },
  {
    label: 'man mage: dark skin tone',
  },
  {
    label: 'woman mage',
  },
  {
    label: 'woman mage: medium-light skin tone',
  },
  {
    label: 'woman mage: medium skin tone',
  },
  {
    label: 'woman mage: medium-dark skin tone',
  },
  {
    label: 'woman mage: dark skin tone',
  },
  {
    label: 'fairy',
  },
  {
    label: 'fairy: medium-light skin tone',
  },
  {
    label: 'fairy: medium skin tone',
  },
  {
    label: 'fairy: medium-dark skin tone',
  },
  {
    label: 'fairy: dark skin tone',
  },
  {
    label: 'man fairy',
  },
  {
    label: 'man fairy: medium-light skin tone',
  },
  {
    label: 'man fairy: medium skin tone',
  },
  {
    label: 'man fairy: medium-dark skin tone',
  },
  {
    label: 'man fairy: dark skin tone',
  },
  {
    label: 'woman fairy',
  },
  {
    label: 'woman fairy: medium-light skin tone',
  },
  {
    label: 'woman fairy: medium skin tone',
  },
  {
    label: 'woman fairy: medium-dark skin tone',
  },
  {
    label: 'woman fairy: dark skin tone',
  },
  {
    label: 'vampire',
  },
  {
    label: 'vampire: medium-light skin tone',
  },
  {
    label: 'vampire: medium skin tone',
  },
  {
    label: 'vampire: medium-dark skin tone',
  },
  {
    label: 'vampire: dark skin tone',
  },
  {
    label: 'man vampire',
  },
  {
    label: 'man vampire: medium-light skin tone',
  },
  {
    label: 'man vampire: medium skin tone',
  },
  {
    label: 'man vampire: medium-dark skin tone',
  },
  {
    label: 'man vampire: dark skin tone',
  },
  {
    label: 'woman vampire',
  },
  {
    label: 'woman vampire: medium-light skin tone',
  },
  {
    label: 'woman vampire: medium skin tone',
  },
  {
    label: 'woman vampire: medium-dark skin tone',
  },
  {
    label: 'woman vampire: dark skin tone',
  },
  {
    label: 'merperson',
  },
  {
    label: 'merperson: medium-light skin tone',
  },
  {
    label: 'merperson: medium skin tone',
  },
  {
    label: 'merperson: medium-dark skin tone',
  },
  {
    label: 'merperson: dark skin tone',
  },
  {
    label: 'merman',
  },
  {
    label: 'merman: medium-light skin tone',
  },
  {
    label: 'merman: medium skin tone',
  },
  {
    label: 'merman: medium-dark skin tone',
  },
  {
    label: 'merman: dark skin tone',
  },
  {
    label: 'mermaid',
  },
  {
    label: 'mermaid: medium-light skin tone',
  },
  {
    label: 'mermaid: medium skin tone',
  },
  {
    label: 'mermaid: medium-dark skin tone',
  },
  {
    label: 'mermaid: dark skin tone',
  },
  {
    label: 'elf',
  },
  {
    label: 'elf: medium-light skin tone',
  },
  {
    label: 'elf: medium skin tone',
  },
  {
    label: 'elf: medium-dark skin tone',
  },
  {
    label: 'elf: dark skin tone',
  },
  {
    label: 'man elf',
  },
  {
    label: 'man elf: medium-light skin tone',
  },
  {
    label: 'man elf: medium skin tone',
  },
  {
    label: 'man elf: medium-dark skin tone',
  },
  {
    label: 'man elf: dark skin tone',
  },
  {
    label: 'woman elf',
  },
  {
    label: 'woman elf: medium-light skin tone',
  },
  {
    label: 'woman elf: medium skin tone',
  },
  {
    label: 'woman elf: medium-dark skin tone',
  },
  {
    label: 'woman elf: dark skin tone',
  },
  {
    label: 'genie',
  },
  {
    label: 'man genie',
  },
  {
    label: 'woman genie',
  },
  {
    label: 'zombie',
  },
  {
    label: 'man zombie',
  },
  {
    label: 'woman zombie',
  },
  {
    label: 'troll',
  },
  {
    label: 'person getting massage',
  },
  {
    label: 'person getting massage: medium-light skin tone',
  },
  {
    label: 'person getting massage: medium skin tone',
  },
  {
    label: 'person getting massage: medium-dark skin tone',
  },
  {
    label: 'person getting massage: dark skin tone',
  },
  {
    label: 'man getting massage',
  },
  {
    label: 'man getting massage: medium-light skin tone',
  },
  {
    label: 'man getting massage: medium skin tone',
  },
  {
    label: 'man getting massage: medium-dark skin tone',
  },
  {
    label: 'man getting massage: dark skin tone',
  },
  {
    label: 'woman getting massage',
  },
  {
    label: 'woman getting massage: medium-light skin tone',
  },
  {
    label: 'woman getting massage: medium skin tone',
  },
  {
    label: 'woman getting massage: medium-dark skin tone',
  },
  {
    label: 'woman getting massage: dark skin tone',
  },
  {
    label: 'person getting haircut',
  },
  {
    label: 'person getting haircut: medium-light skin tone',
  },
  {
    label: 'person getting haircut: medium skin tone',
  },
  {
    label: 'person getting haircut: medium-dark skin tone',
  },
  {
    label: 'person getting haircut: dark skin tone',
  },
  {
    label: 'man getting haircut',
  },
  {
    label: 'man getting haircut: medium-light skin tone',
  },
  {
    label: 'man getting haircut: medium skin tone',
  },
  {
    label: 'man getting haircut: medium-dark skin tone',
  },
  {
    label: 'man getting haircut: dark skin tone',
  },
  {
    label: 'woman getting haircut',
  },
  {
    label: 'woman getting haircut: medium-light skin tone',
  },
  {
    label: 'woman getting haircut: medium skin tone',
  },
  {
    label: 'woman getting haircut: medium-dark skin tone',
  },
  {
    label: 'woman getting haircut: dark skin tone',
  },
  {
    label: 'person walking',
  },
  {
    label: 'person walking: medium-light skin tone',
  },
  {
    label: 'person walking: medium skin tone',
  },
  {
    label: 'person walking: medium-dark skin tone',
  },
  {
    label: 'person walking: dark skin tone',
  },
  {
    label: 'man walking',
  },
  {
    label: 'man walking: medium-light skin tone',
  },
  {
    label: 'man walking: medium skin tone',
  },
  {
    label: 'man walking: medium-dark skin tone',
  },
  {
    label: 'man walking: dark skin tone',
  },
  {
    label: 'woman walking',
  },
  {
    label: 'woman walking: medium-light skin tone',
  },
  {
    label: 'woman walking: medium skin tone',
  },
  {
    label: 'woman walking: medium-dark skin tone',
  },
  {
    label: 'woman walking: dark skin tone',
  },
  {
    label: 'person walking facing right',
  },
  {
    label: 'person walking facing right',
  },
  {
    label: 'person walking facing right',
  },
  {
    label: 'person walking facing right',
  },
  {
    label: 'person walking facing right',
  },
  {
    label: 'woman walking facing right',
  },
  {
    label: 'woman walking facing right',
  },
  {
    label: 'woman walking facing right',
  },
  {
    label: 'woman walking facing right',
  },
  {
    label: 'woman walking facing right',
  },
  {
    label: 'man walking facing right',
  },
  {
    label: 'man walking facing right',
  },
  {
    label: 'man walking facing right',
  },
  {
    label: 'man walking facing right',
  },
  {
    label: 'man walking facing right',
  },
  {
    label: 'person standing',
  },
  {
    label: 'person standing: medium-light skin tone',
  },
  {
    label: 'person standing: medium skin tone',
  },
  {
    label: 'person standing: medium-dark skin tone',
  },
  {
    label: 'person standing: dark skin tone',
  },
  {
    label: 'man standing',
  },
  {
    label: 'man standing: medium-light skin tone',
  },
  {
    label: 'man standing: medium skin tone',
  },
  {
    label: 'man standing: medium-dark skin tone',
  },
  {
    label: 'man standing: dark skin tone',
  },
  {
    label: 'woman standing',
  },
  {
    label: 'woman standing: medium-light skin tone',
  },
  {
    label: 'woman standing: medium skin tone',
  },
  {
    label: 'woman standing: medium-dark skin tone',
  },
  {
    label: 'woman standing: dark skin tone',
  },
  {
    label: 'person kneeling',
  },
  {
    label: 'person kneeling: medium-light skin tone',
  },
  {
    label: 'person kneeling: medium skin tone',
  },
  {
    label: 'person kneeling: medium-dark skin tone',
  },
  {
    label: 'person kneeling: dark skin tone',
  },
  {
    label: 'man kneeling',
  },
  {
    label: 'man kneeling: medium-light skin tone',
  },
  {
    label: 'man kneeling: medium skin tone',
  },
  {
    label: 'man kneeling: medium-dark skin tone',
  },
  {
    label: 'man kneeling: dark skin tone',
  },
  {
    label: 'woman kneeling',
  },
  {
    label: 'woman kneeling: medium-light skin tone',
  },
  {
    label: 'woman kneeling: medium skin tone',
  },
  {
    label: 'woman kneeling: medium-dark skin tone',
  },
  {
    label: 'woman kneeling: dark skin tone',
  },
  {
    label: 'person kneeling facing right',
  },
  {
    label: 'person kneeling facing right',
  },
  {
    label: 'person kneeling facing right',
  },
  {
    label: 'person kneeling facing right',
  },
  {
    label: 'person kneeling facing right',
  },
  {
    label: 'woman kneeling facing right',
  },
  {
    label: 'woman kneeling facing right',
  },
  {
    label: 'woman kneeling facing right',
  },
  {
    label: 'woman kneeling facing right',
  },
  {
    label: 'woman kneeling facing right',
  },
  {
    label: 'man kneeling facing right',
  },
  {
    label: 'man kneeling facing right',
  },
  {
    label: 'man kneeling facing right',
  },
  {
    label: 'man kneeling facing right',
  },
  {
    label: 'man kneeling facing right',
  },
  {
    label: 'person with white cane',
  },
  {
    label: 'person with white cane: medium-light skin tone',
  },
  {
    label: 'person with white cane: medium skin tone',
  },
  {
    label: 'person with white cane: medium-dark skin tone',
  },
  {
    label: 'person with white cane: dark skin tone',
  },
  {
    label: 'person with white cane facing right',
  },
  {
    label: 'person with white cane facing right',
  },
  {
    label: 'person with white cane facing right',
  },
  {
    label: 'person with white cane facing right',
  },
  {
    label: 'person with white cane facing right',
  },
  {
    label: 'man with white cane',
  },
  {
    label: 'man with white cane: medium-light skin tone',
  },
  {
    label: 'man with white cane: medium skin tone',
  },
  {
    label: 'man with white cane: medium-dark skin tone',
  },
  {
    label: 'man with white cane: dark skin tone',
  },
  {
    label: 'man with white cane facing right',
  },
  {
    label: 'man with white cane facing right',
  },
  {
    label: 'man with white cane facing right',
  },
  {
    label: 'man with white cane facing right',
  },
  {
    label: 'man with white cane facing right',
  },
  {
    label: 'woman with white cane',
  },
  {
    label: 'woman with white cane: medium-light skin tone',
  },
  {
    label: 'woman with white cane: medium skin tone',
  },
  {
    label: 'woman with white cane: medium-dark skin tone',
  },
  {
    label: 'woman with white cane: dark skin tone',
  },
  {
    label: 'woman with white cane facing right',
  },
  {
    label: 'woman with white cane facing right',
  },
  {
    label: 'woman with white cane facing right',
  },
  {
    label: 'woman with white cane facing right',
  },
  {
    label: 'woman with white cane facing right',
  },
  {
    label: 'person in motorized wheelchair',
  },
  {
    label: 'person in motorized wheelchair: medium-light skin tone',
  },
  {
    label: 'person in motorized wheelchair: medium skin tone',
  },
  {
    label: 'person in motorized wheelchair: medium-dark skin tone',
  },
  {
    label: 'person in motorized wheelchair: dark skin tone',
  },
  {
    label: 'person in motorized wheelchair facing right',
  },
  {
    label: 'person in motorized wheelchair facing right',
  },
  {
    label: 'person in motorized wheelchair facing right',
  },
  {
    label: 'person in motorized wheelchair facing right',
  },
  {
    label: 'person in motorized wheelchair facing right',
  },
  {
    label: 'man in motorized wheelchair',
  },
  {
    label: 'man in motorized wheelchair: medium-light skin tone',
  },
  {
    label: 'man in motorized wheelchair: medium skin tone',
  },
  {
    label: 'man in motorized wheelchair: medium-dark skin tone',
  },
  {
    label: 'man in motorized wheelchair: dark skin tone',
  },
  {
    label: 'man in motorized wheelchair facing right',
  },
  {
    label: 'man in motorized wheelchair facing right',
  },
  {
    label: 'man in motorized wheelchair facing right',
  },
  {
    label: 'man in motorized wheelchair facing right',
  },
  {
    label: 'man in motorized wheelchair facing right',
  },
  {
    label: 'woman in motorized wheelchair',
  },
  {
    label: 'woman in motorized wheelchair: medium-light skin tone',
  },
  {
    label: 'woman in motorized wheelchair: medium skin tone',
  },
  {
    label: 'woman in motorized wheelchair: medium-dark skin tone',
  },
  {
    label: 'woman in motorized wheelchair: dark skin tone',
  },
  {
    label: 'woman in motorized wheelchair facing right',
  },
  {
    label: 'woman in motorized wheelchair facing right',
  },
  {
    label: 'woman in motorized wheelchair facing right',
  },
  {
    label: 'woman in motorized wheelchair facing right',
  },
  {
    label: 'woman in motorized wheelchair facing right',
  },
  {
    label: 'person in manual wheelchair',
  },
  {
    label: 'person in manual wheelchair: medium-light skin tone',
  },
  {
    label: 'person in manual wheelchair: medium skin tone',
  },
  {
    label: 'person in manual wheelchair: medium-dark skin tone',
  },
  {
    label: 'person in manual wheelchair: dark skin tone',
  },
  {
    label: 'person in manual wheelchair facing right',
  },
  {
    label: 'person in manual wheelchair facing right',
  },
  {
    label: 'person in manual wheelchair facing right',
  },
  {
    label: 'person in manual wheelchair facing right',
  },
  {
    label: 'person in manual wheelchair facing right',
  },
  {
    label: 'man in manual wheelchair',
  },
  {
    label: 'man in manual wheelchair: medium-light skin tone',
  },
  {
    label: 'man in manual wheelchair: medium skin tone',
  },
  {
    label: 'man in manual wheelchair: medium-dark skin tone',
  },
  {
    label: 'man in manual wheelchair: dark skin tone',
  },
  {
    label: 'man in manual wheelchair facing right',
  },
  {
    label: 'man in manual wheelchair facing right',
  },
  {
    label: 'man in manual wheelchair facing right',
  },
  {
    label: 'man in manual wheelchair facing right',
  },
  {
    label: 'man in manual wheelchair facing right',
  },
  {
    label: 'woman in manual wheelchair',
  },
  {
    label: 'woman in manual wheelchair: medium-light skin tone',
  },
  {
    label: 'woman in manual wheelchair: medium skin tone',
  },
  {
    label: 'woman in manual wheelchair: medium-dark skin tone',
  },
  {
    label: 'woman in manual wheelchair: dark skin tone',
  },
  {
    label: 'woman in manual wheelchair facing right',
  },
  {
    label: 'woman in manual wheelchair facing right',
  },
  {
    label: 'woman in manual wheelchair facing right',
  },
  {
    label: 'woman in manual wheelchair facing right',
  },
  {
    label: 'woman in manual wheelchair facing right',
  },
  {
    label: 'person running',
  },
  {
    label: 'person running: medium-light skin tone',
  },
  {
    label: 'person running: medium skin tone',
  },
  {
    label: 'person running: medium-dark skin tone',
  },
  {
    label: 'person running: dark skin tone',
  },
  {
    label: 'man running',
  },
  {
    label: 'man running: medium-light skin tone',
  },
  {
    label: 'man running: medium skin tone',
  },
  {
    label: 'man running: medium-dark skin tone',
  },
  {
    label: 'man running: dark skin tone',
  },
  {
    label: 'woman running',
  },
  {
    label: 'woman running: medium-light skin tone',
  },
  {
    label: 'woman running: medium skin tone',
  },
  {
    label: 'woman running: medium-dark skin tone',
  },
  {
    label: 'woman running: dark skin tone',
  },
  {
    label: 'person running facing right',
  },
  {
    label: 'person running facing right',
  },
  {
    label: 'person running facing right',
  },
  {
    label: 'person running facing right',
  },
  {
    label: 'person running facing right',
  },
  {
    label: 'woman running facing right',
  },
  {
    label: 'woman running facing right',
  },
  {
    label: 'woman running facing right',
  },
  {
    label: 'woman running facing right',
  },
  {
    label: 'woman running facing right',
  },
  {
    label: 'man running facing right',
  },
  {
    label: 'man running facing right',
  },
  {
    label: 'man running facing right',
  },
  {
    label: 'man running facing right',
  },
  {
    label: 'man running facing right',
  },
  {
    label: 'woman dancing',
  },
  {
    label: 'woman dancing: medium-light skin tone',
  },
  {
    label: 'woman dancing: medium skin tone',
  },
  {
    label: 'woman dancing: medium-dark skin tone',
  },
  {
    label: 'woman dancing: dark skin tone',
  },
  {
    label: 'man dancing',
  },
  {
    label: 'man dancing: medium-light skin tone',
  },
  {
    label: 'man dancing: medium skin tone',
  },
  {
    label: 'man dancing: medium-dark skin tone',
  },
  {
    label: 'man dancing: dark skin tone',
  },
  {
    label: 'person in suit levitating',
  },
  {
    label: 'person in suit levitating: medium-light skin tone',
  },
  {
    label: 'person in suit levitating: medium skin tone',
  },
  {
    label: 'person in suit levitating: medium-dark skin tone',
  },
  {
    label: 'person in suit levitating: dark skin tone',
  },
  {
    label: 'people with bunny ears',
  },
  {
    label: 'men with bunny ears',
  },
  {
    label: 'women with bunny ears',
  },
  {
    label: 'person in steamy room',
  },
  {
    label: 'person in steamy room: medium-light skin tone',
  },
  {
    label: 'person in steamy room: medium skin tone',
  },
  {
    label: 'person in steamy room: medium-dark skin tone',
  },
  {
    label: 'person in steamy room: dark skin tone',
  },
  {
    label: 'man in steamy room',
  },
  {
    label: 'man in steamy room: medium-light skin tone',
  },
  {
    label: 'man in steamy room: medium skin tone',
  },
  {
    label: 'man in steamy room: medium-dark skin tone',
  },
  {
    label: 'man in steamy room: dark skin tone',
  },
  {
    label: 'woman in steamy room',
  },
  {
    label: 'woman in steamy room: medium-light skin tone',
  },
  {
    label: 'woman in steamy room: medium skin tone',
  },
  {
    label: 'woman in steamy room: medium-dark skin tone',
  },
  {
    label: 'woman in steamy room: dark skin tone',
  },
  {
    label: 'person climbing',
  },
  {
    label: 'person climbing: medium-light skin tone',
  },
  {
    label: 'person climbing: medium skin tone',
  },
  {
    label: 'person climbing: medium-dark skin tone',
  },
  {
    label: 'person climbing: dark skin tone',
  },
  {
    label: 'man climbing',
  },
  {
    label: 'man climbing: medium-light skin tone',
  },
  {
    label: 'man climbing: medium skin tone',
  },
  {
    label: 'man climbing: medium-dark skin tone',
  },
  {
    label: 'man climbing: dark skin tone',
  },
  {
    label: 'woman climbing',
  },
  {
    label: 'woman climbing: medium-light skin tone',
  },
  {
    label: 'woman climbing: medium skin tone',
  },
  {
    label: 'woman climbing: medium-dark skin tone',
  },
  {
    label: 'woman climbing: dark skin tone',
  },
  {
    label: 'person fencing',
  },
  {
    label: 'horse racing',
  },
  {
    label: 'horse racing: medium-light skin tone',
  },
  {
    label: 'horse racing: medium skin tone',
  },
  {
    label: 'horse racing: medium-dark skin tone',
  },
  {
    label: 'horse racing: dark skin tone',
  },
  {
    label: 'skier',
  },
  {
    label: 'snowboarder',
  },
  {
    label: 'snowboarder: medium-light skin tone',
  },
  {
    label: 'snowboarder: medium skin tone',
  },
  {
    label: 'snowboarder: medium-dark skin tone',
  },
  {
    label: 'snowboarder: dark skin tone',
  },
  {
    label: 'person golfing',
  },
  {
    label: 'person golfing: medium-light skin tone',
  },
  {
    label: 'person golfing: medium skin tone',
  },
  {
    label: 'person golfing: medium-dark skin tone',
  },
  {
    label: 'person golfing: dark skin tone',
  },
  {
    label: 'man golfing',
  },
  {
    label: 'man golfing: medium-light skin tone',
  },
  {
    label: 'man golfing: medium skin tone',
  },
  {
    label: 'man golfing: medium-dark skin tone',
  },
  {
    label: 'man golfing: dark skin tone',
  },
  {
    label: 'woman golfing',
  },
  {
    label: 'woman golfing: medium-light skin tone',
  },
  {
    label: 'woman golfing: medium skin tone',
  },
  {
    label: 'woman golfing: medium-dark skin tone',
  },
  {
    label: 'woman golfing: dark skin tone',
  },
  {
    label: 'person surfing',
  },
  {
    label: 'person surfing: medium-light skin tone',
  },
  {
    label: 'person surfing: medium skin tone',
  },
  {
    label: 'person surfing: medium-dark skin tone',
  },
  {
    label: 'person surfing: dark skin tone',
  },
  {
    label: 'man surfing',
  },
  {
    label: 'man surfing: medium-light skin tone',
  },
  {
    label: 'man surfing: medium skin tone',
  },
  {
    label: 'man surfing: medium-dark skin tone',
  },
  {
    label: 'man surfing: dark skin tone',
  },
  {
    label: 'woman surfing',
  },
  {
    label: 'woman surfing: medium-light skin tone',
  },
  {
    label: 'woman surfing: medium skin tone',
  },
  {
    label: 'woman surfing: medium-dark skin tone',
  },
  {
    label: 'woman surfing: dark skin tone',
  },
  {
    label: 'person rowing boat',
  },
  {
    label: 'person rowing boat: medium-light skin tone',
  },
  {
    label: 'person rowing boat: medium skin tone',
  },
  {
    label: 'person rowing boat: medium-dark skin tone',
  },
  {
    label: 'person rowing boat: dark skin tone',
  },
  {
    label: 'man rowing boat',
  },
  {
    label: 'man rowing boat: medium-light skin tone',
  },
  {
    label: 'man rowing boat: medium skin tone',
  },
  {
    label: 'man rowing boat: medium-dark skin tone',
  },
  {
    label: 'man rowing boat: dark skin tone',
  },
  {
    label: 'woman rowing boat',
  },
  {
    label: 'woman rowing boat: medium-light skin tone',
  },
  {
    label: 'woman rowing boat: medium skin tone',
  },
  {
    label: 'woman rowing boat: medium-dark skin tone',
  },
  {
    label: 'woman rowing boat: dark skin tone',
  },
  {
    label: 'person swimming',
  },
  {
    label: 'person swimming: medium-light skin tone',
  },
  {
    label: 'person swimming: medium skin tone',
  },
  {
    label: 'person swimming: medium-dark skin tone',
  },
  {
    label: 'person swimming: dark skin tone',
  },
  {
    label: 'man swimming',
  },
  {
    label: 'man swimming: medium-light skin tone',
  },
  {
    label: 'man swimming: medium skin tone',
  },
  {
    label: 'man swimming: medium-dark skin tone',
  },
  {
    label: 'man swimming: dark skin tone',
  },
  {
    label: 'woman swimming',
  },
  {
    label: 'woman swimming: medium-light skin tone',
  },
  {
    label: 'woman swimming: medium skin tone',
  },
  {
    label: 'woman swimming: medium-dark skin tone',
  },
  {
    label: 'woman swimming: dark skin tone',
  },
  {
    label: 'person bouncing ball',
  },
  {
    label: 'person bouncing ball: medium-light skin tone',
  },
  {
    label: 'person bouncing ball: medium skin tone',
  },
  {
    label: 'person bouncing ball: medium-dark skin tone',
  },
  {
    label: 'person bouncing ball: dark skin tone',
  },
  {
    label: 'man bouncing ball',
  },
  {
    label: 'man bouncing ball: medium-light skin tone',
  },
  {
    label: 'man bouncing ball: medium skin tone',
  },
  {
    label: 'man bouncing ball: medium-dark skin tone',
  },
  {
    label: 'man bouncing ball: dark skin tone',
  },
  {
    label: 'woman bouncing ball',
  },
  {
    label: 'woman bouncing ball: medium-light skin tone',
  },
  {
    label: 'woman bouncing ball: medium skin tone',
  },
  {
    label: 'woman bouncing ball: medium-dark skin tone',
  },
  {
    label: 'woman bouncing ball: dark skin tone',
  },
  {
    label: 'person lifting weights',
  },
  {
    label: 'person lifting weights: medium-light skin tone',
  },
  {
    label: 'person lifting weights: medium skin tone',
  },
  {
    label: 'person lifting weights: medium-dark skin tone',
  },
  {
    label: 'person lifting weights: dark skin tone',
  },
  {
    label: 'man lifting weights',
  },
  {
    label: 'man lifting weights: medium-light skin tone',
  },
  {
    label: 'man lifting weights: medium skin tone',
  },
  {
    label: 'man lifting weights: medium-dark skin tone',
  },
  {
    label: 'man lifting weights: dark skin tone',
  },
  {
    label: 'woman lifting weights',
  },
  {
    label: 'woman lifting weights: medium-light skin tone',
  },
  {
    label: 'woman lifting weights: medium skin tone',
  },
  {
    label: 'woman lifting weights: medium-dark skin tone',
  },
  {
    label: 'woman lifting weights: dark skin tone',
  },
  {
    label: 'person biking',
  },
  {
    label: 'person biking: medium-light skin tone',
  },
  {
    label: 'person biking: medium skin tone',
  },
  {
    label: 'person biking: medium-dark skin tone',
  },
  {
    label: 'person biking: dark skin tone',
  },
  {
    label: 'man biking',
  },
  {
    label: 'man biking: medium-light skin tone',
  },
  {
    label: 'man biking: medium skin tone',
  },
  {
    label: 'man biking: medium-dark skin tone',
  },
  {
    label: 'man biking: dark skin tone',
  },
  {
    label: 'woman biking',
  },
  {
    label: 'woman biking: medium-light skin tone',
  },
  {
    label: 'woman biking: medium skin tone',
  },
  {
    label: 'woman biking: medium-dark skin tone',
  },
  {
    label: 'woman biking: dark skin tone',
  },
  {
    label: 'person mountain biking',
  },
  {
    label: 'person mountain biking: medium-light skin tone',
  },
  {
    label: 'person mountain biking: medium skin tone',
  },
  {
    label: 'person mountain biking: medium-dark skin tone',
  },
  {
    label: 'person mountain biking: dark skin tone',
  },
  {
    label: 'man mountain biking',
  },
  {
    label: 'man mountain biking: medium-light skin tone',
  },
  {
    label: 'man mountain biking: medium skin tone',
  },
  {
    label: 'man mountain biking: medium-dark skin tone',
  },
  {
    label: 'man mountain biking: dark skin tone',
  },
  {
    label: 'woman mountain biking',
  },
  {
    label: 'woman mountain biking: medium-light skin tone',
  },
  {
    label: 'woman mountain biking: medium skin tone',
  },
  {
    label: 'woman mountain biking: medium-dark skin tone',
  },
  {
    label: 'woman mountain biking: dark skin tone',
  },
  {
    label: 'person cartwheeling',
  },
  {
    label: 'person cartwheeling: medium-light skin tone',
  },
  {
    label: 'person cartwheeling: medium skin tone',
  },
  {
    label: 'person cartwheeling: medium-dark skin tone',
  },
  {
    label: 'person cartwheeling: dark skin tone',
  },
  {
    label: 'man cartwheeling',
  },
  {
    label: 'man cartwheeling: medium-light skin tone',
  },
  {
    label: 'man cartwheeling: medium skin tone',
  },
  {
    label: 'man cartwheeling: medium-dark skin tone',
  },
  {
    label: 'man cartwheeling: dark skin tone',
  },
  {
    label: 'woman cartwheeling',
  },
  {
    label: 'woman cartwheeling: medium-light skin tone',
  },
  {
    label: 'woman cartwheeling: medium skin tone',
  },
  {
    label: 'woman cartwheeling: medium-dark skin tone',
  },
  {
    label: 'woman cartwheeling: dark skin tone',
  },
  {
    label: 'people wrestling',
  },
  {
    label: 'men wrestling',
  },
  {
    label: 'women wrestling',
  },
  {
    label: 'person playing water polo',
  },
  {
    label: 'person playing water polo: medium-light skin tone',
  },
  {
    label: 'person playing water polo: medium skin tone',
  },
  {
    label: 'person playing water polo: medium-dark skin tone',
  },
  {
    label: 'person playing water polo: dark skin tone',
  },
  {
    label: 'man playing water polo',
  },
  {
    label: 'man playing water polo: medium-light skin tone',
  },
  {
    label: 'man playing water polo: medium skin tone',
  },
  {
    label: 'man playing water polo: medium-dark skin tone',
  },
  {
    label: 'man playing water polo: dark skin tone',
  },
  {
    label: 'woman playing water polo',
  },
  {
    label: 'woman playing water polo: medium-light skin tone',
  },
  {
    label: 'woman playing water polo: medium skin tone',
  },
  {
    label: 'woman playing water polo: medium-dark skin tone',
  },
  {
    label: 'woman playing water polo: dark skin tone',
  },
  {
    label: 'person playing handball',
  },
  {
    label: 'person playing handball: medium-light skin tone',
  },
  {
    label: 'person playing handball: medium skin tone',
  },
  {
    label: 'person playing handball: medium-dark skin tone',
  },
  {
    label: 'person playing handball: dark skin tone',
  },
  {
    label: 'man playing handball',
  },
  {
    label: 'man playing handball: medium-light skin tone',
  },
  {
    label: 'man playing handball: medium skin tone',
  },
  {
    label: 'man playing handball: medium-dark skin tone',
  },
  {
    label: 'man playing handball: dark skin tone',
  },
  {
    label: 'woman playing handball',
  },
  {
    label: 'woman playing handball: medium-light skin tone',
  },
  {
    label: 'woman playing handball: medium skin tone',
  },
  {
    label: 'woman playing handball: medium-dark skin tone',
  },
  {
    label: 'woman playing handball: dark skin tone',
  },
  {
    label: 'person juggling',
  },
  {
    label: 'person juggling: medium-light skin tone',
  },
  {
    label: 'person juggling: medium skin tone',
  },
  {
    label: 'person juggling: medium-dark skin tone',
  },
  {
    label: 'person juggling: dark skin tone',
  },
  {
    label: 'man juggling',
  },
  {
    label: 'man juggling: medium-light skin tone',
  },
  {
    label: 'man juggling: medium skin tone',
  },
  {
    label: 'man juggling: medium-dark skin tone',
  },
  {
    label: 'man juggling: dark skin tone',
  },
  {
    label: 'woman juggling',
  },
  {
    label: 'woman juggling: medium-light skin tone',
  },
  {
    label: 'woman juggling: medium skin tone',
  },
  {
    label: 'woman juggling: medium-dark skin tone',
  },
  {
    label: 'woman juggling: dark skin tone',
  },
  {
    label: 'person in lotus position',
  },
  {
    label: 'person in lotus position: medium-light skin tone',
  },
  {
    label: 'person in lotus position: medium skin tone',
  },
  {
    label: 'person in lotus position: medium-dark skin tone',
  },
  {
    label: 'person in lotus position: dark skin tone',
  },
  {
    label: 'man in lotus position',
  },
  {
    label: 'man in lotus position: medium-light skin tone',
  },
  {
    label: 'man in lotus position: medium skin tone',
  },
  {
    label: 'man in lotus position: medium-dark skin tone',
  },
  {
    label: 'man in lotus position: dark skin tone',
  },
  {
    label: 'woman in lotus position',
  },
  {
    label: 'woman in lotus position: medium-light skin tone',
  },
  {
    label: 'woman in lotus position: medium skin tone',
  },
  {
    label: 'woman in lotus position: medium-dark skin tone',
  },
  {
    label: 'woman in lotus position: dark skin tone',
  },
  {
    label: 'person taking bath',
  },
  {
    label: 'person taking bath: medium-light skin tone',
  },
  {
    label: 'person taking bath: medium skin tone',
  },
  {
    label: 'person taking bath: medium-dark skin tone',
  },
  {
    label: 'person taking bath: dark skin tone',
  },
  {
    label: 'person in bed',
  },
  {
    label: 'person in bed: medium-light skin tone',
  },
  {
    label: 'person in bed: medium skin tone',
  },
  {
    label: 'person in bed: medium-dark skin tone',
  },
  {
    label: 'person in bed: dark skin tone',
  },
  {
    label: 'people holding hands',
  },
  {
    label: 'people holding hands: light skin tone, medium-light skin tone',
  },
  {
    label: 'people holding hands: light skin tone, medium skin tone',
  },
  {
    label: 'people holding hands: light skin tone, medium-dark skin tone',
  },
  {
    label: 'people holding hands: light skin tone, dark skin tone',
  },
  {
    label: 'people holding hands: medium-light skin tone, light skin tone',
  },
  {
    label: 'people holding hands: medium-light skin tone',
  },
  {
    label: 'people holding hands: medium-light skin tone, medium skin tone',
  },
  {
    label: 'people holding hands: medium-light skin tone, dark skin tone',
  },
  {
    label: 'people holding hands: medium skin tone, light skin tone',
  },
  {
    label: 'people holding hands: medium skin tone, medium-light skin tone',
  },
  {
    label: 'people holding hands: medium skin tone',
  },
  {
    label: 'people holding hands: medium skin tone, medium-dark skin tone',
  },
  {
    label: 'people holding hands: medium skin tone, dark skin tone',
  },
  {
    label: 'people holding hands: medium-dark skin tone, light skin tone',
  },
  {
    label: 'people holding hands: medium-dark skin tone, medium skin tone',
  },
  {
    label: 'people holding hands: medium-dark skin tone',
  },
  {
    label: 'people holding hands: medium-dark skin tone, dark skin tone',
  },
  {
    label: 'people holding hands: dark skin tone, light skin tone',
  },
  {
    label: 'people holding hands: dark skin tone, medium-light skin tone',
  },
  {
    label: 'people holding hands: dark skin tone, medium skin tone',
  },
  {
    label: 'people holding hands: dark skin tone, medium-dark skin tone',
  },
  {
    label: 'people holding hands: dark skin tone',
  },
  {
    label: 'women holding hands',
  },
  {
    label: 'women holding hands: medium-light skin tone',
  },
  {
    label: 'women holding hands: medium skin tone',
  },
  {
    label: 'women holding hands: medium-dark skin tone',
  },
  {
    label: 'women holding hands: dark skin tone',
  },
  {
    label: 'women holding hands: light skin tone, medium-light skin tone',
  },
  {
    label: 'women holding hands: light skin tone, medium skin tone',
  },
  {
    label: 'women holding hands: light skin tone, medium-dark skin tone',
  },
  {
    label: 'women holding hands: light skin tone, dark skin tone',
  },
  {
    label: 'women holding hands: medium-light skin tone, light skin tone',
  },
  {
    label: 'women holding hands: medium-light skin tone, medium skin tone',
  },
  {
    label: 'women holding hands: medium-light skin tone, dark skin tone',
  },
  {
    label: 'women holding hands: medium skin tone, light skin tone',
  },
  {
    label: 'women holding hands: medium skin tone, medium-light skin tone',
  },
  {
    label: 'women holding hands: medium skin tone, medium-dark skin tone',
  },
  {
    label: 'women holding hands: medium skin tone, dark skin tone',
  },
  {
    label: 'women holding hands: medium-dark skin tone, light skin tone',
  },
  {
    label: 'women holding hands: medium-dark skin tone, medium skin tone',
  },
  {
    label: 'women holding hands: medium-dark skin tone, dark skin tone',
  },
  {
    label: 'women holding hands: dark skin tone, light skin tone',
  },
  {
    label: 'women holding hands: dark skin tone, medium-light skin tone',
  },
  {
    label: 'women holding hands: dark skin tone, medium skin tone',
  },
  {
    label: 'women holding hands: dark skin tone, medium-dark skin tone',
  },
  {
    label: 'woman and man holding hands',
  },
  {
    label: 'woman and man holding hands: medium-light skin tone',
  },
  {
    label: 'woman and man holding hands: medium skin tone',
  },
  {
    label: 'woman and man holding hands: medium-dark skin tone',
  },
  {
    label: 'woman and man holding hands: dark skin tone',
  },
  {
    label: 'woman and man holding hands: light skin tone, medium skin tone',
  },
  {
    label: 'woman and man holding hands: light skin tone, dark skin tone',
  },
  {
    label: 'woman and man holding hands: medium skin tone, light skin tone',
  },
  {
    label: 'woman and man holding hands: medium skin tone, dark skin tone',
  },
  {
    label: 'woman and man holding hands: dark skin tone, light skin tone',
  },
  {
    label: 'woman and man holding hands: dark skin tone, medium skin tone',
  },
  {
    label: 'men holding hands',
  },
  {
    label: 'men holding hands: medium-light skin tone',
  },
  {
    label: 'men holding hands: medium skin tone',
  },
  {
    label: 'men holding hands: medium-dark skin tone',
  },
  {
    label: 'men holding hands: dark skin tone',
  },
  {
    label: 'men holding hands: light skin tone, medium-light skin tone',
  },
  {
    label: 'men holding hands: light skin tone, medium skin tone',
  },
  {
    label: 'men holding hands: light skin tone, medium-dark skin tone',
  },
  {
    label: 'men holding hands: light skin tone, dark skin tone',
  },
  {
    label: 'men holding hands: medium-light skin tone, light skin tone',
  },
  {
    label: 'men holding hands: medium-light skin tone, medium skin tone',
  },
  {
    label: 'men holding hands: medium-light skin tone, dark skin tone',
  },
  {
    label: 'men holding hands: medium skin tone, light skin tone',
  },
  {
    label: 'men holding hands: medium skin tone, medium-light skin tone',
  },
  {
    label: 'men holding hands: medium skin tone, medium-dark skin tone',
  },
  {
    label: 'men holding hands: medium skin tone, dark skin tone',
  },
  {
    label: 'men holding hands: medium-dark skin tone, light skin tone',
  },
  {
    label: 'men holding hands: medium-dark skin tone, medium skin tone',
  },
  {
    label: 'men holding hands: medium-dark skin tone, dark skin tone',
  },
  {
    label: 'men holding hands: dark skin tone, light skin tone',
  },
  {
    label: 'men holding hands: dark skin tone, medium-light skin tone',
  },
  {
    label: 'men holding hands: dark skin tone, medium skin tone',
  },
  {
    label: 'men holding hands: dark skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss',
  },
  {
    label: 'kiss: medium-light skin tone',
  },
  {
    label: 'kiss: medium skin tone',
  },
  {
    label: 'kiss: medium-dark skin tone',
  },
  {
    label: 'kiss: dark skin tone',
  },
  {
    label: 'kiss: person, person, light skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: person, person, light skin tone, medium skin tone',
  },
  {
    label: 'kiss: person, person, light skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: person, person, light skin tone, dark skin tone',
  },
  {
    label: 'kiss: person, person, medium-light skin tone, light skin tone',
  },
  {
    label: 'kiss: person, person, medium-light skin tone, medium skin tone',
  },
  {
    label: 'kiss: person, person, medium-light skin tone, dark skin tone',
  },
  {
    label: 'kiss: person, person, medium skin tone, light skin tone',
  },
  {
    label: 'kiss: person, person, medium skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: person, person, medium skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: person, person, medium skin tone, dark skin tone',
  },
  {
    label: 'kiss: person, person, medium-dark skin tone, light skin tone',
  },
  {
    label: 'kiss: person, person, medium-dark skin tone, medium skin tone',
  },
  {
    label: 'kiss: person, person, medium-dark skin tone, dark skin tone',
  },
  {
    label: 'kiss: person, person, dark skin tone, light skin tone',
  },
  {
    label: 'kiss: person, person, dark skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: person, person, dark skin tone, medium skin tone',
  },
  {
    label: 'kiss: person, person, dark skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: woman, man',
  },
  {
    label: 'kiss: woman, man, light skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: woman, man, light skin tone, medium skin tone',
  },
  {
    label: 'kiss: woman, man, light skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: woman, man, light skin tone, dark skin tone',
  },
  {
    label: 'kiss: woman, man, medium-light skin tone, light skin tone',
  },
  {
    label: 'kiss: woman, man, medium-light skin tone',
  },
  {
    label: 'kiss: woman, man, medium-light skin tone, medium skin tone',
  },
  {
    label: 'kiss: woman, man, medium-light skin tone, dark skin tone',
  },
  {
    label: 'kiss: woman, man, medium skin tone, light skin tone',
  },
  {
    label: 'kiss: woman, man, medium skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: woman, man, medium skin tone',
  },
  {
    label: 'kiss: woman, man, medium skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: woman, man, medium skin tone, dark skin tone',
  },
  {
    label: 'kiss: woman, man, medium-dark skin tone, light skin tone',
  },
  {
    label: 'kiss: woman, man, medium-dark skin tone, medium skin tone',
  },
  {
    label: 'kiss: woman, man, medium-dark skin tone',
  },
  {
    label: 'kiss: woman, man, medium-dark skin tone, dark skin tone',
  },
  {
    label: 'kiss: woman, man, dark skin tone, light skin tone',
  },
  {
    label: 'kiss: woman, man, dark skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: woman, man, dark skin tone, medium skin tone',
  },
  {
    label: 'kiss: woman, man, dark skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: woman, man, dark skin tone',
  },
  {
    label: 'kiss: man, man',
  },
  {
    label: 'kiss: man, man, light skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: man, man, light skin tone, medium skin tone',
  },
  {
    label: 'kiss: man, man, light skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: man, man, light skin tone, dark skin tone',
  },
  {
    label: 'kiss: man, man, medium-light skin tone, light skin tone',
  },
  {
    label: 'kiss: man, man, medium-light skin tone',
  },
  {
    label: 'kiss: man, man, medium-light skin tone, medium skin tone',
  },
  {
    label: 'kiss: man, man, medium-light skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: man, man, medium-light skin tone, dark skin tone',
  },
  {
    label: 'kiss: man, man, medium skin tone, light skin tone',
  },
  {
    label: 'kiss: man, man, medium skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: man, man, medium skin tone',
  },
  {
    label: 'kiss: man, man, medium skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: man, man, medium skin tone, dark skin tone',
  },
  {
    label: 'kiss: man, man, medium-dark skin tone, light skin tone',
  },
  {
    label: 'kiss: man, man, medium-dark skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: man, man, medium-dark skin tone, medium skin tone',
  },
  {
    label: 'kiss: man, man, medium-dark skin tone',
  },
  {
    label: 'kiss: man, man, medium-dark skin tone, dark skin tone',
  },
  {
    label: 'kiss: man, man, dark skin tone, light skin tone',
  },
  {
    label: 'kiss: man, man, dark skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: man, man, dark skin tone, medium skin tone',
  },
  {
    label: 'kiss: man, man, dark skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: man, man, dark skin tone',
  },
  {
    label: 'kiss: woman, woman',
  },
  {
    label: 'kiss: woman, woman, light skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: woman, woman, light skin tone, medium skin tone',
  },
  {
    label: 'kiss: woman, woman, light skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: woman, woman, light skin tone, dark skin tone',
  },
  {
    label: 'kiss: woman, woman, medium-light skin tone, light skin tone',
  },
  {
    label: 'kiss: woman, woman, medium-light skin tone',
  },
  {
    label: 'kiss: woman, woman, medium-light skin tone, medium skin tone',
  },
  {
    label: 'kiss: woman, woman, medium-light skin tone, dark skin tone',
  },
  {
    label: 'kiss: woman, woman, medium skin tone, light skin tone',
  },
  {
    label: 'kiss: woman, woman, medium skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: woman, woman, medium skin tone',
  },
  {
    label: 'kiss: woman, woman, medium skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: woman, woman, medium skin tone, dark skin tone',
  },
  {
    label: 'kiss: woman, woman, medium-dark skin tone, light skin tone',
  },
  {
    label: 'kiss: woman, woman, medium-dark skin tone, medium skin tone',
  },
  {
    label: 'kiss: woman, woman, medium-dark skin tone',
  },
  {
    label: 'kiss: woman, woman, medium-dark skin tone, dark skin tone',
  },
  {
    label: 'kiss: woman, woman, dark skin tone, light skin tone',
  },
  {
    label: 'kiss: woman, woman, dark skin tone, medium-light skin tone',
  },
  {
    label: 'kiss: woman, woman, dark skin tone, medium skin tone',
  },
  {
    label: 'kiss: woman, woman, dark skin tone, medium-dark skin tone',
  },
  {
    label: 'kiss: woman, woman, dark skin tone',
  },
  {
    label: 'couple with heart',
  },
  {
    label: 'couple with heart: medium-light skin tone',
  },
  {
    label: 'couple with heart: medium skin tone',
  },
  {
    label: 'couple with heart: medium-dark skin tone',
  },
  {
    label: 'couple with heart: dark skin tone',
  },
  {
    label: 'couple with heart: woman, man',
  },
  {
    label: 'couple with heart: woman, man, light skin tone, dark skin tone',
  },
  {
    label: 'couple with heart: woman, man, medium-light skin tone',
  },
  {
    label: 'couple with heart: woman, man, medium skin tone',
  },
  {
    label: 'couple with heart: woman, man, medium-dark skin tone',
  },
  {
    label: 'couple with heart: woman, man, dark skin tone, light skin tone',
  },
  {
    label: 'couple with heart: woman, man, dark skin tone',
  },
  {
    label: 'couple with heart: man, man',
  },
  {
    label: 'couple with heart: man, man, light skin tone, medium skin tone',
  },
  {
    label: 'couple with heart: man, man, light skin tone, dark skin tone',
  },
  {
    label: 'couple with heart: man, man, medium-light skin tone',
  },
  {
    label: 'couple with heart: man, man, medium skin tone, light skin tone',
  },
  {
    label: 'couple with heart: man, man, medium skin tone',
  },
  {
    label: 'couple with heart: man, man, medium skin tone, dark skin tone',
  },
  {
    label: 'couple with heart: man, man, medium-dark skin tone',
  },
  {
    label: 'couple with heart: man, man, dark skin tone, light skin tone',
  },
  {
    label: 'couple with heart: man, man, dark skin tone, medium skin tone',
  },
  {
    label: 'couple with heart: man, man, dark skin tone',
  },
  {
    label: 'couple with heart: woman, woman',
  },
  {
    label: 'couple with heart: woman, woman, medium-light skin tone',
  },
  {
    label: 'couple with heart: woman, woman, medium skin tone',
  },
  {
    label: 'couple with heart: woman, woman, medium-dark skin tone',
  },
  {
    label: 'couple with heart: woman, woman, dark skin tone',
  },
  {
    label: 'family: man, woman, boy',
  },
  {
    label: 'family: man, woman, girl',
  },
  {
    label: 'family: man, woman, girl, boy',
  },
  {
    label: 'family: man, woman, boy, boy',
  },
  {
    label: 'family: man, woman, girl, girl',
  },
  {
    label: 'family: man, man, boy',
  },
  {
    label: 'family: man, man, girl',
  },
  {
    label: 'family: man, man, girl, boy',
  },
  {
    label: 'family: man, man, boy, boy',
  },
  {
    label: 'family: man, man, girl, girl',
  },
  {
    label: 'family: woman, woman, boy',
  },
  {
    label: 'family: woman, woman, girl',
  },
  {
    label: 'family: woman, woman, girl, boy',
  },
  {
    label: 'family: woman, woman, boy, boy',
  },
  {
    label: 'family: woman, woman, girl, girl',
  },
  {
    label: 'family: man, boy',
  },
  {
    label: 'family: man, boy, boy',
  },
  {
    label: 'family: man, girl',
  },
  {
    label: 'family: man, girl, boy',
  },
  {
    label: 'family: man, girl, girl',
  },
  {
    label: 'family: woman, boy',
  },
  {
    label: 'family: woman, boy, boy',
  },
  {
    label: 'family: woman, girl',
  },
  {
    label: 'family: woman, girl, boy',
  },
  {
    label: 'family: woman, girl, girl',
  },
  {
    label: 'speaking head',
  },
  {
    label: 'bust in silhouette',
  },
  {
    label: 'busts in silhouette',
  },
  {
    label: 'people hugging',
  },
  {
    label: 'family',
  },
  {
    label: 'family: adult, adult, child',
  },
  {
    label: 'family: adult, adult, child, child',
  },
  {
    label: 'family: adult, child',
  },
  {
    label: 'family: adult, child, child',
  },
  {
    label: 'footprints',
  },
  {
    label: 'fingerprint',
  },
  {
    label: 'light skin tone',
  },
  {
    label: 'medium-light skin tone',
  },
  {
    label: 'medium skin tone',
  },
  {
    label: 'medium-dark skin tone',
  },
  {
    label: 'dark skin tone',
  },
  {
    label: 'red hair',
  },
  {
    label: 'curly hair',
  },
  {
    label: 'white hair',
  },
  {
    label: 'bald',
  },
  {
    label: 'monkey face',
  },
  {
    label: 'monkey',
  },
  {
    label: 'gorilla',
  },
  {
    label: 'orangutan',
  },
  {
    label: 'dog face',
  },
  {
    label: 'dog',
  },
  {
    label: 'guide dog',
  },
  {
    label: 'service dog',
  },
  {
    label: 'poodle',
  },
  {
    label: 'wolf',
  },
  {
    label: 'fox',
  },
  {
    label: 'raccoon',
  },
  {
    label: 'cat face',
  },
  {
    label: 'cat',
  },
  {
    label: 'black cat',
  },
  {
    label: 'lion',
  },
  {
    label: 'tiger face',
  },
  {
    label: 'tiger',
  },
  {
    label: 'leopard',
  },
  {
    label: 'horse face',
  },
  {
    label: 'moose',
  },
  {
    label: 'donkey',
  },
  {
    label: 'horse',
  },
  {
    label: 'unicorn',
  },
  {
    label: 'zebra',
  },
  {
    label: 'deer',
  },
  {
    label: 'bison',
  },
  {
    label: 'cow face',
  },
  {
    label: 'ox',
  },
  {
    label: 'water buffalo',
  },
  {
    label: 'cow',
  },
  {
    label: 'pig face',
  },
  {
    label: 'pig',
  },
  {
    label: 'boar',
  },
  {
    label: 'pig nose',
  },
  {
    label: 'ram',
  },
  {
    label: 'ewe',
  },
  {
    label: 'goat',
  },
  {
    label: 'camel',
  },
  {
    label: 'two-hump camel',
  },
  {
    label: 'llama',
  },
  {
    label: 'giraffe',
  },
  {
    label: 'elephant',
  },
  {
    label: 'mammoth',
  },
  {
    label: 'rhinoceros',
  },
  {
    label: 'hippopotamus',
  },
  {
    label: 'mouse face',
  },
  {
    label: 'mouse',
  },
  {
    label: 'rat',
  },
  {
    label: 'hamster',
  },
  {
    label: 'rabbit face',
  },
  {
    label: 'rabbit',
  },
  {
    label: 'chipmunk',
  },
  {
    label: 'beaver',
  },
  {
    label: 'hedgehog',
  },
  {
    label: 'bat',
  },
  {
    label: 'bear',
  },
  {
    label: 'polar bear',
  },
  {
    label: 'koala',
  },
  {
    label: 'panda',
  },
  {
    label: 'sloth',
  },
  {
    label: 'otter',
  },
  {
    label: 'skunk',
  },
  {
    label: 'kangaroo',
  },
  {
    label: 'badger',
  },
  {
    label: 'paw prints',
  },
  {
    label: 'turkey',
  },
  {
    label: 'chicken',
  },
  {
    label: 'rooster',
  },
  {
    label: 'hatching chick',
  },
  {
    label: 'baby chick',
  },
  {
    label: 'front-facing baby chick',
  },
  {
    label: 'bird',
  },
  {
    label: 'penguin',
  },
  {
    label: 'dove',
  },
  {
    label: 'eagle',
  },
  {
    label: 'duck',
  },
  {
    label: 'swan',
  },
  {
    label: 'owl',
  },
  {
    label: 'dodo',
  },
  {
    label: 'feather',
  },
  {
    label: 'flamingo',
  },
  {
    label: 'peacock',
  },
  {
    label: 'parrot',
  },
  {
    label: 'wing',
  },
  {
    label: 'black bird',
  },
  {
    label: 'goose',
  },
  {
    label: 'phoenix',
  },
  {
    label: 'frog',
  },
  {
    label: 'crocodile',
  },
  {
    label: 'turtle',
  },
  {
    label: 'lizard',
  },
  {
    label: 'snake',
  },
  {
    label: 'dragon face',
  },
  {
    label: 'dragon',
  },
  {
    label: 'sauropod',
  },
  {
    label: 'T-Rex',
  },
  {
    label: 'spouting whale',
  },
  {
    label: 'whale',
  },
  {
    label: 'dolphin',
  },
  {
    label: 'seal',
  },
  {
    label: 'fish',
  },
  {
    label: 'tropical fish',
  },
  {
    label: 'blowfish',
  },
  {
    label: 'shark',
  },
  {
    label: 'octopus',
  },
  {
    label: 'spiral shell',
  },
  {
    label: 'coral',
  },
  {
    label: 'jellyfish',
  },
  {
    label: 'crab',
  },
  {
    label: 'lobster',
  },
  {
    label: 'shrimp',
  },
  {
    label: 'squid',
  },
  {
    label: 'oyster',
  },
  {
    label: 'snail',
  },
  {
    label: 'butterfly',
  },
  {
    label: 'bug',
  },
  {
    label: 'ant',
  },
  {
    label: 'honeybee',
  },
  {
    label: 'beetle',
  },
  {
    label: 'lady beetle',
  },
  {
    label: 'cricket',
  },
  {
    label: 'cockroach',
  },
  {
    label: 'spider',
  },
  {
    label: 'spider web',
  },
  {
    label: 'scorpion',
  },
  {
    label: 'mosquito',
  },
  {
    label: 'fly',
  },
  {
    label: 'worm',
  },
  {
    label: 'microbe',
  },
  {
    label: 'bouquet',
  },
  {
    label: 'cherry blossom',
  },
  {
    label: 'white flower',
  },
  {
    label: 'lotus',
  },
  {
    label: 'rosette',
  },
  {
    label: 'rose',
  },
  {
    label: 'wilted flower',
  },
  {
    label: 'hibiscus',
  },
  {
    label: 'sunflower',
  },
  {
    label: 'blossom',
  },
  {
    label: 'tulip',
  },
  {
    label: 'hyacinth',
  },
  {
    label: 'seedling',
  },
  {
    label: 'potted plant',
  },
  {
    label: 'evergreen tree',
  },
  {
    label: 'deciduous tree',
  },
  {
    label: 'palm tree',
  },
  {
    label: 'cactus',
  },
  {
    label: 'sheaf of rice',
  },
  {
    label: 'herb',
  },
  {
    label: 'shamrock',
  },
  {
    label: 'four leaf clover',
  },
  {
    label: 'maple leaf',
  },
  {
    label: 'fallen leaf',
  },
  {
    label: 'leaf fluttering in wind',
  },
  {
    label: 'empty nest',
  },
  {
    label: 'nest with eggs',
  },
  {
    label: 'mushroom',
  },
  {
    label: 'leafless tree',
  },
  {
    label: 'grapes',
  },
  {
    label: 'melon',
  },
  {
    label: 'watermelon',
  },
  {
    label: 'tangerine',
  },
  {
    label: 'lemon',
  },
  {
    label: 'lime',
  },
  {
    label: 'banana',
  },
  {
    label: 'pineapple',
  },
  {
    label: 'mango',
  },
  {
    label: 'red apple',
  },
  {
    label: 'green apple',
  },
  {
    label: 'pear',
  },
  {
    label: 'peach',
  },
  {
    label: 'cherries',
  },
  {
    label: 'strawberry',
  },
  {
    label: 'blueberries',
  },
  {
    label: 'kiwi fruit',
  },
  {
    label: 'tomato',
  },
  {
    label: 'olive',
  },
  {
    label: 'coconut',
  },
  {
    label: 'avocado',
  },
  {
    label: 'eggplant',
  },
  {
    label: 'potato',
  },
  {
    label: 'carrot',
  },
  {
    label: 'ear of corn',
  },
  {
    label: 'hot pepper',
  },
  {
    label: 'bell pepper',
  },
  {
    label: 'cucumber',
  },
  {
    label: 'leafy green',
  },
  {
    label: 'broccoli',
  },
  {
    label: 'garlic',
  },
  {
    label: 'onion',
  },
  {
    label: 'peanuts',
  },
  {
    label: 'beans',
  },
  {
    label: 'chestnut',
  },
  {
    label: 'ginger root',
  },
  {
    label: 'pea pod',
  },
  {
    label: 'brown mushroom',
  },
  {
    label: 'root vegetable',
  },
  {
    label: 'bread',
  },
  {
    label: 'croissant',
  },
  {
    label: 'baguette bread',
  },
  {
    label: 'flatbread',
  },
  {
    label: 'pretzel',
  },
  {
    label: 'bagel',
  },
  {
    label: 'pancakes',
  },
  {
    label: 'waffle',
  },
  {
    label: 'cheese wedge',
  },
  {
    label: 'meat on bone',
  },
  {
    label: 'poultry leg',
  },
  {
    label: 'cut of meat',
  },
  {
    label: 'bacon',
  },
  {
    label: 'hamburger',
  },
  {
    label: 'french fries',
  },
  {
    label: 'pizza',
  },
  {
    label: 'hot dog',
  },
  {
    label: 'sandwich',
  },
  {
    label: 'taco',
  },
  {
    label: 'burrito',
  },
  {
    label: 'tamale',
  },
  {
    label: 'stuffed flatbread',
  },
  {
    label: 'falafel',
  },
  {
    label: 'egg',
  },
  {
    label: 'cooking',
  },
  {
    label: 'shallow pan of food',
  },
  {
    label: 'pot of food',
  },
  {
    label: 'fondue',
  },
  {
    label: 'bowl with spoon',
  },
  {
    label: 'green salad',
  },
  {
    label: 'popcorn',
  },
  {
    label: 'butter',
  },
  {
    label: 'salt',
  },
  {
    label: 'canned food',
  },
  {
    label: 'bento box',
  },
  {
    label: 'rice cracker',
  },
  {
    label: 'rice ball',
  },
  {
    label: 'cooked rice',
  },
  {
    label: 'curry rice',
  },
  {
    label: 'steaming bowl',
  },
  {
    label: 'spaghetti',
  },
  {
    label: 'roasted sweet potato',
  },
  {
    label: 'oden',
  },
  {
    label: 'sushi',
  },
  {
    label: 'fried shrimp',
  },
  {
    label: 'fish cake with swirl',
  },
  {
    label: 'moon cake',
  },
  {
    label: 'dango',
  },
  {
    label: 'dumpling',
  },
  {
    label: 'fortune cookie',
  },
  {
    label: 'takeout box',
  },
  {
    label: 'soft ice cream',
  },
  {
    label: 'shaved ice',
  },
  {
    label: 'ice cream',
  },
  {
    label: 'doughnut',
  },
  {
    label: 'cookie',
  },
  {
    label: 'birthday cake',
  },
  {
    label: 'shortcake',
  },
  {
    label: 'cupcake',
  },
  {
    label: 'pie',
  },
  {
    label: 'chocolate bar',
  },
  {
    label: 'candy',
  },
  {
    label: 'lollipop',
  },
  {
    label: 'custard',
  },
  {
    label: 'honey pot',
  },
  {
    label: 'baby bottle',
  },
  {
    label: 'glass of milk',
  },
  {
    label: 'hot beverage',
  },
  {
    label: 'teapot',
  },
  {
    label: 'teacup without handle',
  },
  {
    label: 'sake',
  },
  {
    label: 'bottle with popping cork',
  },
  {
    label: 'wine glass',
  },
  {
    label: 'cocktail glass',
  },
  {
    label: 'tropical drink',
  },
  {
    label: 'beer mug',
  },
  {
    label: 'clinking beer mugs',
  },
  {
    label: 'clinking glasses',
  },
  {
    label: 'tumbler glass',
  },
  {
    label: 'pouring liquid',
  },
  {
    label: 'cup with straw',
  },
  {
    label: 'bubble tea',
  },
  {
    label: 'beverage box',
  },
  {
    label: 'mate',
  },
  {
    label: 'ice',
  },
  {
    label: 'chopsticks',
  },
  {
    label: 'fork and knife with plate',
  },
  {
    label: 'fork and knife',
  },
  {
    label: 'spoon',
  },
  {
    label: 'kitchen knife',
  },
  {
    label: 'jar',
  },
  {
    label: 'amphora',
  },
  {
    label: 'globe showing Europe-Africa',
  },
  {
    label: 'globe showing Americas',
  },
  {
    label: 'globe showing Asia-Australia',
  },
  {
    label: 'globe with meridians',
  },
  {
    label: 'world map',
  },
  {
    label: 'map of Japan',
  },
  {
    label: 'compass',
  },
  {
    label: 'snow-capped mountain',
  },
  {
    label: 'mountain',
  },
  {
    label: 'volcano',
  },
  {
    label: 'mount fuji',
  },
  {
    label: 'camping',
  },
  {
    label: 'beach with umbrella',
  },
  {
    label: 'desert',
  },
  {
    label: 'desert island',
  },
  {
    label: 'national park',
  },
  {
    label: 'stadium',
  },
  {
    label: 'classical building',
  },
  {
    label: 'building construction',
  },
  {
    label: 'brick',
  },
  {
    label: 'rock',
  },
  {
    label: 'wood',
  },
  {
    label: 'hut',
  },
  {
    label: 'houses',
  },
  {
    label: 'derelict house',
  },
  {
    label: 'house',
  },
  {
    label: 'house with garden',
  },
  {
    label: 'office building',
  },
  {
    label: 'Japanese post office',
  },
  {
    label: 'post office',
  },
  {
    label: 'hospital',
  },
  {
    label: 'bank',
  },
  {
    label: 'hotel',
  },
  {
    label: 'love hotel',
  },
  {
    label: 'convenience store',
  },
  {
    label: 'school',
  },
  {
    label: 'department store',
  },
  {
    label: 'factory',
  },
  {
    label: 'Japanese castle',
  },
  {
    label: 'castle',
  },
  {
    label: 'wedding',
  },
  {
    label: 'Tokyo tower',
  },
  {
    label: 'Statue of Liberty',
  },
  {
    label: 'church',
  },
  {
    label: 'mosque',
  },
  {
    label: 'hindu temple',
  },
  {
    label: 'synagogue',
  },
  {
    label: 'shinto shrine',
  },
  {
    label: 'kaaba',
  },
  {
    label: 'fountain',
  },
  {
    label: 'tent',
  },
  {
    label: 'foggy',
  },
  {
    label: 'night with stars',
  },
  {
    label: 'cityscape',
  },
  {
    label: 'sunrise over mountains',
  },
  {
    label: 'sunrise',
  },
  {
    label: 'cityscape at dusk',
  },
  {
    label: 'sunset',
  },
  {
    label: 'bridge at night',
  },
  {
    label: 'hot springs',
  },
  {
    label: 'carousel horse',
  },
  {
    label: 'playground slide',
  },
  {
    label: 'ferris wheel',
  },
  {
    label: 'roller coaster',
  },
  {
    label: 'barber pole',
  },
  {
    label: 'circus tent',
  },
  {
    label: 'locomotive',
  },
  {
    label: 'railway car',
  },
  {
    label: 'high-speed train',
  },
  {
    label: 'bullet train',
  },
  {
    label: 'train',
  },
  {
    label: 'metro',
  },
  {
    label: 'light rail',
  },
  {
    label: 'station',
  },
  {
    label: 'tram',
  },
  {
    label: 'monorail',
  },
  {
    label: 'mountain railway',
  },
  {
    label: 'tram car',
  },
  {
    label: 'bus',
  },
  {
    label: 'oncoming bus',
  },
  {
    label: 'trolleybus',
  },
  {
    label: 'minibus',
  },
  {
    label: 'ambulance',
  },
  {
    label: 'fire engine',
  },
  {
    label: 'police car',
  },
  {
    label: 'oncoming police car',
  },
  {
    label: 'taxi',
  },
  {
    label: 'oncoming taxi',
  },
  {
    label: 'automobile',
  },
  {
    label: 'oncoming automobile',
  },
  {
    label: 'sport utility vehicle',
  },
  {
    label: 'pickup truck',
  },
  {
    label: 'delivery truck',
  },
  {
    label: 'articulated lorry',
  },
  {
    label: 'tractor',
  },
  {
    label: 'racing car',
  },
  {
    label: 'motorcycle',
  },
  {
    label: 'motor scooter',
  },
  {
    label: 'manual wheelchair',
  },
  {
    label: 'motorized wheelchair',
  },
  {
    label: 'auto rickshaw',
  },
  {
    label: 'bicycle',
  },
  {
    label: 'kick scooter',
  },
  {
    label: 'skateboard',
  },
  {
    label: 'roller skate',
  },
  {
    label: 'bus stop',
  },
  {
    label: 'motorway',
  },
  {
    label: 'railway track',
  },
  {
    label: 'oil drum',
  },
  {
    label: 'fuel pump',
  },
  {
    label: 'wheel',
  },
  {
    label: 'police car light',
  },
  {
    label: 'horizontal traffic light',
  },
  {
    label: 'vertical traffic light',
  },
  {
    label: 'stop sign',
  },
  {
    label: 'construction',
  },
  {
    label: 'anchor',
  },
  {
    label: 'ring buoy',
  },
  {
    label: 'sailboat',
  },
  {
    label: 'canoe',
  },
  {
    label: 'speedboat',
  },
  {
    label: 'passenger ship',
  },
  {
    label: 'ferry',
  },
  {
    label: 'motor boat',
  },
  {
    label: 'ship',
  },
  {
    label: 'airplane',
  },
  {
    label: 'small airplane',
  },
  {
    label: 'airplane departure',
  },
  {
    label: 'airplane arrival',
  },
  {
    label: 'parachute',
  },
  {
    label: 'seat',
  },
  {
    label: 'helicopter',
  },
  {
    label: 'suspension railway',
  },
  {
    label: 'mountain cableway',
  },
  {
    label: 'aerial tramway',
  },
  {
    label: 'satellite',
  },
  {
    label: 'rocket',
  },
  {
    label: 'flying saucer',
  },
  {
    label: 'bellhop bell',
  },
  {
    label: 'luggage',
  },
  {
    label: 'hourglass done',
  },
  {
    label: 'hourglass not done',
  },
  {
    label: 'watch',
  },
  {
    label: 'alarm clock',
  },
  {
    label: 'stopwatch',
  },
  {
    label: 'timer clock',
  },
  {
    label: 'mantelpiece clock',
  },
  {
    label: 'twelve o’clock',
  },
  {
    label: 'twelve-thirty',
  },
  {
    label: 'one o’clock',
  },
  {
    label: 'one-thirty',
  },
  {
    label: 'two o’clock',
  },
  {
    label: 'two-thirty',
  },
  {
    label: 'three o’clock',
  },
  {
    label: 'three-thirty',
  },
  {
    label: 'four o’clock',
  },
  {
    label: 'four-thirty',
  },
  {
    label: 'five o’clock',
  },
  {
    label: 'five-thirty',
  },
  {
    label: 'six o’clock',
  },
  {
    label: 'six-thirty',
  },
  {
    label: 'seven o’clock',
  },
  {
    label: 'seven-thirty',
  },
  {
    label: 'eight o’clock',
  },
  {
    label: 'eight-thirty',
  },
  {
    label: 'nine o’clock',
  },
  {
    label: 'nine-thirty',
  },
  {
    label: 'ten o’clock',
  },
  {
    label: 'ten-thirty',
  },
  {
    label: 'eleven o’clock',
  },
  {
    label: 'eleven-thirty',
  },
  {
    label: 'new moon',
  },
  {
    label: 'waxing crescent moon',
  },
  {
    label: 'first quarter moon',
  },
  {
    label: 'waxing gibbous moon',
  },
  {
    label: 'full moon',
  },
  {
    label: 'waning gibbous moon',
  },
  {
    label: 'last quarter moon',
  },
  {
    label: 'waning crescent moon',
  },
  {
    label: 'crescent moon',
  },
  {
    label: 'new moon face',
  },
  {
    label: 'first quarter moon face',
  },
  {
    label: 'last quarter moon face',
  },
  {
    label: 'thermometer',
  },
  {
    label: 'sun',
  },
  {
    label: 'full moon face',
  },
  {
    label: 'sun with face',
  },
  {
    label: 'ringed planet',
  },
  {
    label: 'star',
  },
  {
    label: 'glowing star',
  },
  {
    label: 'shooting star',
  },
  {
    label: 'milky way',
  },
  {
    label: 'cloud',
  },
  {
    label: 'sun behind cloud',
  },
  {
    label: 'cloud with lightning and rain',
  },
  {
    label: 'sun behind small cloud',
  },
  {
    label: 'sun behind large cloud',
  },
  {
    label: 'sun behind rain cloud',
  },
  {
    label: 'cloud with rain',
  },
  {
    label: 'cloud with snow',
  },
  {
    label: 'cloud with lightning',
  },
  {
    label: 'tornado',
  },
  {
    label: 'fog',
  },
  {
    label: 'wind face',
  },
  {
    label: 'cyclone',
  },
  {
    label: 'rainbow',
  },
  {
    label: 'closed umbrella',
  },
  {
    label: 'umbrella',
  },
  {
    label: 'umbrella with rain drops',
  },
  {
    label: 'umbrella on ground',
  },
  {
    label: 'high voltage',
  },
  {
    label: 'snowflake',
  },
  {
    label: 'snowman',
  },
  {
    label: 'snowman without snow',
  },
  {
    label: 'comet',
  },
  {
    label: 'fire',
  },
  {
    label: 'droplet',
  },
  {
    label: 'water wave',
  },
  {
    label: 'jack-o-lantern',
  },
  {
    label: 'Christmas tree',
  },
  {
    label: 'fireworks',
  },
  {
    label: 'sparkler',
  },
  {
    label: 'firecracker',
  },
  {
    label: 'sparkles',
  },
  {
    label: 'balloon',
  },
  {
    label: 'party popper',
  },
  {
    label: 'confetti ball',
  },
  {
    label: 'tanabata tree',
  },
  {
    label: 'pine decoration',
  },
  {
    label: 'Japanese dolls',
  },
  {
    label: 'carp streamer',
  },
  {
    label: 'wind chime',
  },
  {
    label: 'moon viewing ceremony',
  },
  {
    label: 'red envelope',
  },
  {
    label: 'ribbon',
  },
  {
    label: 'wrapped gift',
  },
  {
    label: 'reminder ribbon',
  },
  {
    label: 'admission tickets',
  },
  {
    label: 'ticket',
  },
  {
    label: 'military medal',
  },
  {
    label: 'trophy',
  },
  {
    label: 'sports medal',
  },
  {
    label: '1st place medal',
  },
  {
    label: '2nd place medal',
  },
  {
    label: '3rd place medal',
  },
  {
    label: 'soccer ball',
  },
  {
    label: 'baseball',
  },
  {
    label: 'softball',
  },
  {
    label: 'basketball',
  },
  {
    label: 'volleyball',
  },
  {
    label: 'american football',
  },
  {
    label: 'rugby football',
  },
  {
    label: 'tennis',
  },
  {
    label: 'flying disc',
  },
  {
    label: 'bowling',
  },
  {
    label: 'cricket game',
  },
  {
    label: 'field hockey',
  },
  {
    label: 'ice hockey',
  },
  {
    label: 'lacrosse',
  },
  {
    label: 'ping pong',
  },
  {
    label: 'badminton',
  },
  {
    label: 'boxing glove',
  },
  {
    label: 'martial arts uniform',
  },
  {
    label: 'goal net',
  },
  {
    label: 'flag in hole',
  },
  {
    label: 'ice skate',
  },
  {
    label: 'fishing pole',
  },
  {
    label: 'diving mask',
  },
  {
    label: 'running shirt',
  },
  {
    label: 'skis',
  },
  {
    label: 'sled',
  },
  {
    label: 'curling stone',
  },
  {
    label: 'bullseye',
  },
  {
    label: 'yo-yo',
  },
  {
    label: 'kite',
  },
  {
    label: 'water pistol',
  },
  {
    label: 'pool 8 ball',
  },
  {
    label: 'crystal ball',
  },
  {
    label: 'magic wand',
  },
  {
    label: 'video game',
  },
  {
    label: 'joystick',
  },
  {
    label: 'slot machine',
  },
  {
    label: 'game die',
  },
  {
    label: 'puzzle piece',
  },
  {
    label: 'teddy bear',
  },
  {
    label: 'piñata',
  },
  {
    label: 'mirror ball',
  },
  {
    label: 'nesting dolls',
  },
  {
    label: 'spade suit',
  },
  {
    label: 'heart suit',
  },
  {
    label: 'diamond suit',
  },
  {
    label: 'club suit',
  },
  {
    label: 'chess pawn',
  },
  {
    label: 'joker',
  },
  {
    label: 'mahjong red dragon',
  },
  {
    label: 'flower playing cards',
  },
  {
    label: 'performing arts',
  },
  {
    label: 'framed picture',
  },
  {
    label: 'artist palette',
  },
  {
    label: 'thread',
  },
  {
    label: 'sewing needle',
  },
  {
    label: 'yarn',
  },
  {
    label: 'knot',
  },
  {
    label: 'glasses',
  },
  {
    label: 'sunglasses',
  },
  {
    label: 'goggles',
  },
  {
    label: 'lab coat',
  },
  {
    label: 'safety vest',
  },
  {
    label: 'necktie',
  },
  {
    label: 't-shirt',
  },
  {
    label: 'jeans',
  },
  {
    label: 'scarf',
  },
  {
    label: 'gloves',
  },
  {
    label: 'coat',
  },
  {
    label: 'socks',
  },
  {
    label: 'dress',
  },
  {
    label: 'kimono',
  },
  {
    label: 'sari',
  },
  {
    label: 'one-piece swimsuit',
  },
  {
    label: 'briefs',
  },
  {
    label: 'shorts',
  },
  {
    label: 'bikini',
  },
  {
    label: 'woman’s clothes',
  },
  {
    label: 'folding hand fan',
  },
  {
    label: 'purse',
  },
  {
    label: 'handbag',
  },
  {
    label: 'clutch bag',
  },
  {
    label: 'shopping bags',
  },
  {
    label: 'backpack',
  },
  {
    label: 'thong sandal',
  },
  {
    label: 'man’s shoe',
  },
  {
    label: 'running shoe',
  },
  {
    label: 'hiking boot',
  },
  {
    label: 'flat shoe',
  },
  {
    label: 'high-heeled shoe',
  },
  {
    label: 'woman’s sandal',
  },
  {
    label: 'ballet shoes',
  },
  {
    label: 'woman’s boot',
  },
  {
    label: 'hair pick',
  },
  {
    label: 'crown',
  },
  {
    label: 'woman’s hat',
  },
  {
    label: 'top hat',
  },
  {
    label: 'graduation cap',
  },
  {
    label: 'billed cap',
  },
  {
    label: 'military helmet',
  },
  {
    label: 'rescue worker’s helmet',
  },
  {
    label: 'prayer beads',
  },
  {
    label: 'lipstick',
  },
  {
    label: 'ring',
  },
  {
    label: 'gem stone',
  },
  {
    label: 'muted speaker',
  },
  {
    label: 'speaker low volume',
  },
  {
    label: 'speaker medium volume',
  },
  {
    label: 'speaker high volume',
  },
  {
    label: 'loudspeaker',
  },
  {
    label: 'megaphone',
  },
  {
    label: 'postal horn',
  },
  {
    label: 'bell',
  },
  {
    label: 'bell with slash',
  },
  {
    label: 'musical score',
  },
  {
    label: 'musical note',
  },
  {
    label: 'musical notes',
  },
  {
    label: 'studio microphone',
  },
  {
    label: 'level slider',
  },
  {
    label: 'control knobs',
  },
  {
    label: 'microphone',
  },
  {
    label: 'headphone',
  },
  {
    label: 'radio',
  },
  {
    label: 'saxophone',
  },
  {
    label: 'accordion',
  },
  {
    label: 'guitar',
  },
  {
    label: 'musical keyboard',
  },
  {
    label: 'trumpet',
  },
  {
    label: 'violin',
  },
  {
    label: 'banjo',
  },
  {
    label: 'drum',
  },
  {
    label: 'long drum',
  },
  {
    label: 'maracas',
  },
  {
    label: 'flute',
  },
  {
    label: 'harp',
  },
  {
    label: 'mobile phone',
  },
  {
    label: 'mobile phone with arrow',
  },
  {
    label: 'telephone',
  },
  {
    label: 'telephone receiver',
  },
  {
    label: 'pager',
  },
  {
    label: 'fax machine',
  },
  {
    label: 'battery',
  },
  {
    label: 'low battery',
  },
  {
    label: 'electric plug',
  },
  {
    label: 'laptop',
  },
  {
    label: 'desktop computer',
  },
  {
    label: 'printer',
  },
  {
    label: 'keyboard',
  },
  {
    label: 'computer mouse',
  },
  {
    label: 'trackball',
  },
  {
    label: 'computer disk',
  },
  {
    label: 'floppy disk',
  },
  {
    label: 'optical disk',
  },
  {
    label: 'dvd',
  },
  {
    label: 'abacus',
  },
  {
    label: 'movie camera',
  },
  {
    label: 'film frames',
  },
  {
    label: 'film projector',
  },
  {
    label: 'clapper board',
  },
  {
    label: 'television',
  },
  {
    label: 'camera',
  },
  {
    label: 'camera with flash',
  },
  {
    label: 'video camera',
  },
  {
    label: 'videocassette',
  },
  {
    label: 'magnifying glass tilted left',
  },
  {
    label: 'magnifying glass tilted right',
  },
  {
    label: 'candle',
  },
  {
    label: 'light bulb',
  },
  {
    label: 'flashlight',
  },
  {
    label: 'red paper lantern',
  },
  {
    label: 'diya lamp',
  },
  {
    label: 'notebook with decorative cover',
  },
  {
    label: 'closed book',
  },
  {
    label: 'open book',
  },
  {
    label: 'green book',
  },
  {
    label: 'blue book',
  },
  {
    label: 'orange book',
  },
  {
    label: 'books',
  },
  {
    label: 'notebook',
  },
  {
    label: 'ledger',
  },
  {
    label: 'page with curl',
  },
  {
    label: 'scroll',
  },
  {
    label: 'page facing up',
  },
  {
    label: 'newspaper',
  },
  {
    label: 'rolled-up newspaper',
  },
  {
    label: 'bookmark tabs',
  },
  {
    label: 'bookmark',
  },
  {
    label: 'label',
  },
  {
    label: 'money bag',
  },
  {
    label: 'coin',
  },
  {
    label: 'yen banknote',
  },
  {
    label: 'dollar banknote',
  },
  {
    label: 'euro banknote',
  },
  {
    label: 'pound banknote',
  },
  {
    label: 'money with wings',
  },
  {
    label: 'credit card',
  },
  {
    label: 'receipt',
  },
  {
    label: 'chart increasing with yen',
  },
  {
    label: 'envelope',
  },
  {
    label: 'e-mail',
  },
  {
    label: 'incoming envelope',
  },
  {
    label: 'envelope with arrow',
  },
  {
    label: 'outbox tray',
  },
  {
    label: 'inbox tray',
  },
  {
    label: 'package',
  },
  {
    label: 'closed mailbox with raised flag',
  },
  {
    label: 'closed mailbox with lowered flag',
  },
  {
    label: 'open mailbox with raised flag',
  },
  {
    label: 'open mailbox with lowered flag',
  },
  {
    label: 'postbox',
  },
  {
    label: 'ballot box with ballot',
  },
  {
    label: 'pencil',
  },
  {
    label: 'black nib',
  },
  {
    label: 'fountain pen',
  },
  {
    label: 'pen',
  },
  {
    label: 'paintbrush',
  },
  {
    label: 'crayon',
  },
  {
    label: 'memo',
  },
  {
    label: 'briefcase',
  },
  {
    label: 'file folder',
  },
  {
    label: 'open file folder',
  },
  {
    label: 'card index dividers',
  },
  {
    label: 'calendar',
  },
  {
    label: 'tear-off calendar',
  },
  {
    label: 'spiral notepad',
  },
  {
    label: 'spiral calendar',
  },
  {
    label: 'card index',
  },
  {
    label: 'chart increasing',
  },
  {
    label: 'chart decreasing',
  },
  {
    label: 'bar chart',
  },
  {
    label: 'clipboard',
  },
  {
    label: 'pushpin',
  },
  {
    label: 'round pushpin',
  },
  {
    label: 'paperclip',
  },
  {
    label: 'linked paperclips',
  },
  {
    label: 'straight ruler',
  },
  {
    label: 'triangular ruler',
  },
  {
    label: 'scissors',
  },
  {
    label: 'card file box',
  },
  {
    label: 'file cabinet',
  },
  {
    label: 'wastebasket',
  },
  {
    label: 'locked',
  },
  {
    label: 'unlocked',
  },
  {
    label: 'locked with pen',
  },
  {
    label: 'locked with key',
  },
  {
    label: 'key',
  },
  {
    label: 'old key',
  },
  {
    label: 'hammer',
  },
  {
    label: 'axe',
  },
  {
    label: 'pick',
  },
  {
    label: 'hammer and pick',
  },
  {
    label: 'hammer and wrench',
  },
  {
    label: 'dagger',
  },
  {
    label: 'crossed swords',
  },
  {
    label: 'bomb',
  },
  {
    label: 'boomerang',
  },
  {
    label: 'bow and arrow',
  },
  {
    label: 'shield',
  },
  {
    label: 'carpentry saw',
  },
  {
    label: 'wrench',
  },
  {
    label: 'screwdriver',
  },
  {
    label: 'nut and bolt',
  },
  {
    label: 'gear',
  },
  {
    label: 'clamp',
  },
  {
    label: 'balance scale',
  },
  {
    label: 'white cane',
  },
  {
    label: 'link',
  },
  {
    label: 'broken chain',
  },
  {
    label: 'chains',
  },
  {
    label: 'hook',
  },
  {
    label: 'toolbox',
  },
  {
    label: 'magnet',
  },
  {
    label: 'ladder',
  },
  {
    label: 'shovel',
  },
  {
    label: 'alembic',
  },
  {
    label: 'test tube',
  },
  {
    label: 'petri dish',
  },
  {
    label: 'dna',
  },
  {
    label: 'microscope',
  },
  {
    label: 'telescope',
  },
  {
    label: 'satellite antenna',
  },
  {
    label: 'syringe',
  },
  {
    label: 'drop of blood',
  },
  {
    label: 'pill',
  },
  {
    label: 'adhesive bandage',
  },
  {
    label: 'crutch',
  },
  {
    label: 'stethoscope',
  },
  {
    label: 'x-ray',
  },
  {
    label: 'door',
  },
  {
    label: 'elevator',
  },
  {
    label: 'mirror',
  },
  {
    label: 'window',
  },
  {
    label: 'bed',
  },
  {
    label: 'couch and lamp',
  },
  {
    label: 'chair',
  },
  {
    label: 'toilet',
  },
  {
    label: 'plunger',
  },
  {
    label: 'shower',
  },
  {
    label: 'bathtub',
  },
  {
    label: 'mouse trap',
  },
  {
    label: 'razor',
  },
  {
    label: 'lotion bottle',
  },
  {
    label: 'safety pin',
  },
  {
    label: 'broom',
  },
  {
    label: 'basket',
  },
  {
    label: 'roll of paper',
  },
  {
    label: 'bucket',
  },
  {
    label: 'soap',
  },
  {
    label: 'bubbles',
  },
  {
    label: 'toothbrush',
  },
  {
    label: 'sponge',
  },
  {
    label: 'fire extinguisher',
  },
  {
    label: 'shopping cart',
  },
  {
    label: 'cigarette',
  },
  {
    label: 'coffin',
  },
  {
    label: 'headstone',
  },
  {
    label: 'funeral urn',
  },
  {
    label: 'nazar amulet',
  },
  {
    label: 'hamsa',
  },
  {
    label: 'moai',
  },
  {
    label: 'placard',
  },
  {
    label: 'identification card',
  },
  {
    label: 'ATM sign',
  },
  {
    label: 'litter in bin sign',
  },
  {
    label: 'potable water',
  },
  {
    label: 'wheelchair symbol',
  },
  {
    label: 'men’s room',
  },
  {
    label: 'women’s room',
  },
  {
    label: 'restroom',
  },
  {
    label: 'baby symbol',
  },
  {
    label: 'water closet',
  },
  {
    label: 'passport control',
  },
  {
    label: 'customs',
  },
  {
    label: 'baggage claim',
  },
  {
    label: 'left luggage',
  },
  {
    label: 'warning',
  },
  {
    label: 'children crossing',
  },
  {
    label: 'no entry',
  },
  {
    label: 'prohibited',
  },
  {
    label: 'no bicycles',
  },
  {
    label: 'no smoking',
  },
  {
    label: 'no littering',
  },
  {
    label: 'non-potable water',
  },
  {
    label: 'no pedestrians',
  },
  {
    label: 'no mobile phones',
  },
  {
    label: 'no one under eighteen',
  },
  {
    label: 'radioactive',
  },
  {
    label: 'biohazard',
  },
  {
    label: 'up arrow',
  },
  {
    label: 'up-right arrow',
  },
  {
    label: 'right arrow',
  },
  {
    label: 'down-right arrow',
  },
  {
    label: 'down arrow',
  },
  {
    label: 'down-left arrow',
  },
  {
    label: 'left arrow',
  },
  {
    label: 'up-left arrow',
  },
  {
    label: 'up-down arrow',
  },
  {
    label: 'left-right arrow',
  },
  {
    label: 'right arrow curving left',
  },
  {
    label: 'left arrow curving right',
  },
  {
    label: 'right arrow curving up',
  },
  {
    label: 'right arrow curving down',
  },
  {
    label: 'clockwise vertical arrows',
  },
  {
    label: 'counterclockwise arrows button',
  },
  {
    label: 'BACK arrow',
  },
  {
    label: 'END arrow',
  },
  {
    label: 'ON! arrow',
  },
  {
    label: 'SOON arrow',
  },
  {
    label: 'TOP arrow',
  },
  {
    label: 'place of worship',
  },
  {
    label: 'atom symbol',
  },
  {
    label: 'om',
  },
  {
    label: 'star of David',
  },
  {
    label: 'wheel of dharma',
  },
  {
    label: 'yin yang',
  },
  {
    label: 'latin cross',
  },
  {
    label: 'orthodox cross',
  },
  {
    label: 'star and crescent',
  },
  {
    label: 'peace symbol',
  },
  {
    label: 'menorah',
  },
  {
    label: 'dotted six-pointed star',
  },
  {
    label: 'khanda',
  },
  {
    label: 'Aries',
  },
  {
    label: 'Taurus',
  },
  {
    label: 'Gemini',
  },
  {
    label: 'Cancer',
  },
  {
    label: 'Leo',
  },
  {
    label: 'Virgo',
  },
  {
    label: 'Libra',
  },
  {
    label: 'Scorpio',
  },
  {
    label: 'Sagittarius',
  },
  {
    label: 'Capricorn',
  },
  {
    label: 'Aquarius',
  },
  {
    label: 'Pisces',
  },
  {
    label: 'Ophiuchus',
  },
  {
    label: 'shuffle tracks button',
  },
  {
    label: 'repeat button',
  },
  {
    label: 'repeat single button',
  },
  {
    label: 'play button',
  },
  {
    label: 'fast-forward button',
  },
  {
    label: 'next track button',
  },
  {
    label: 'play or pause button',
  },
  {
    label: 'reverse button',
  },
  {
    label: 'fast reverse button',
  },
  {
    label: 'last track button',
  },
  {
    label: 'upwards button',
  },
  {
    label: 'fast up button',
  },
  {
    label: 'downwards button',
  },
  {
    label: 'fast down button',
  },
  {
    label: 'pause button',
  },
  {
    label: 'stop button',
  },
  {
    label: 'record button',
  },
  {
    label: 'eject button',
  },
  {
    label: 'cinema',
  },
  {
    label: 'dim button',
  },
  {
    label: 'bright button',
  },
  {
    label: 'antenna bars',
  },
  {
    label: 'wireless',
  },
  {
    label: 'vibration mode',
  },
  {
    label: 'mobile phone off',
  },
  {
    label: 'female sign',
  },
  {
    label: 'male sign',
  },
  {
    label: 'transgender symbol',
  },
  {
    label: 'multiply',
  },
  {
    label: 'plus',
  },
  {
    label: 'minus',
  },
  {
    label: 'divide',
  },
  {
    label: 'heavy equals sign',
  },
  {
    label: 'infinity',
  },
  {
    label: 'double exclamation mark',
  },
  {
    label: 'exclamation question mark',
  },
  {
    label: 'red question mark',
  },
  {
    label: 'white question mark',
  },
  {
    label: 'white exclamation mark',
  },
  {
    label: 'red exclamation mark',
  },
  {
    label: 'wavy dash',
  },
  {
    label: 'currency exchange',
  },
  {
    label: 'heavy dollar sign',
  },
  {
    label: 'medical symbol',
  },
  {
    label: 'recycling symbol',
  },
  {
    label: 'fleur-de-lis',
  },
  {
    label: 'trident emblem',
  },
  {
    label: 'name badge',
  },
  {
    label: 'Japanese symbol for beginner',
  },
  {
    label: 'hollow red circle',
  },
  {
    label: 'check mark button',
  },
  {
    label: 'check box with check',
  },
  {
    label: 'check mark',
  },
  {
    label: 'cross mark',
  },
  {
    label: 'cross mark button',
  },
  {
    label: 'curly loop',
  },
  {
    label: 'double curly loop',
  },
  {
    label: 'part alternation mark',
  },
  {
    label: 'eight-spoked asterisk',
  },
  {
    label: 'eight-pointed star',
  },
  {
    label: 'sparkle',
  },
  {
    label: 'copyright',
  },
  {
    label: 'registered',
  },
  {
    label: 'trade mark',
  },
  {
    label: 'splatter',
  },
  {
    label: 'keycap: pound',
  },
  {
    label: 'keycap: asterisk',
  },
  {
    label: 'keycap: 0',
  },
  {
    label: 'keycap: 1',
  },
  {
    label: 'keycap: 2',
  },
  {
    label: 'keycap: 3',
  },
  {
    label: 'keycap: 4',
  },
  {
    label: 'keycap: 5',
  },
  {
    label: 'keycap: 6',
  },
  {
    label: 'keycap: 7',
  },
  {
    label: 'keycap: 8',
  },
  {
    label: 'keycap: 9',
  },
  {
    label: 'keycap: 10',
  },
  {
    label: 'input latin uppercase',
  },
  {
    label: 'input latin lowercase',
  },
  {
    label: 'input numbers',
  },
  {
    label: 'input symbols',
  },
  {
    label: 'input latin letters',
  },
  {
    label: 'A button (blood type)',
  },
  {
    label: 'AB button (blood type)',
  },
  {
    label: 'B button (blood type)',
  },
  {
    label: 'CL button',
  },
  {
    label: 'COOL button',
  },
  {
    label: 'FREE button',
  },
  {
    label: 'information',
  },
  {
    label: 'ID button',
  },
  {
    label: 'circled M',
  },
  {
    label: 'NEW button',
  },
  {
    label: 'NG button',
  },
  {
    label: 'O button (blood type)',
  },
  {
    label: 'OK button',
  },
  {
    label: 'P button',
  },
  {
    label: 'SOS button',
  },
  {
    label: 'UP! button',
  },
  {
    label: 'VS button',
  },
  {
    label: 'Japanese “here” button',
  },
  {
    label: 'Japanese “service charge” button',
  },
  {
    label: 'Japanese “monthly amount” button',
  },
  {
    label: 'Japanese “not free of charge” button',
  },
  {
    label: 'Japanese “reserved” button',
  },
  {
    label: 'Japanese “bargain” button',
  },
  {
    label: 'Japanese “discount” button',
  },
  {
    label: 'Japanese “free of charge” button',
  },
  {
    label: 'Japanese “prohibited” button',
  },
  {
    label: 'Japanese “acceptable” button',
  },
  {
    label: 'Japanese “application” button',
  },
  {
    label: 'Japanese “passing grade” button',
  },
  {
    label: 'Japanese “vacancy” button',
  },
  {
    label: 'Japanese “congratulations” button',
  },
  {
    label: 'Japanese “secret” button',
  },
  {
    label: 'Japanese “open for business” button',
  },
  {
    label: 'Japanese “no vacancy” button',
  },
  {
    label: 'red circle',
  },
  {
    label: 'orange circle',
  },
  {
    label: 'yellow circle',
  },
  {
    label: 'green circle',
  },
  {
    label: 'blue circle',
  },
  {
    label: 'purple circle',
  },
  {
    label: 'brown circle',
  },
  {
    label: 'black circle',
  },
  {
    label: 'white circle',
  },
  {
    label: 'red square',
  },
  {
    label: 'orange square',
  },
  {
    label: 'yellow square',
  },
  {
    label: 'green square',
  },
  {
    label: 'blue square',
  },
  {
    label: 'purple square',
  },
  {
    label: 'brown square',
  },
  {
    label: 'black large square',
  },
  {
    label: 'white large square',
  },
  {
    label: 'black medium square',
  },
  {
    label: 'white medium square',
  },
  {
    label: 'black medium-small square',
  },
  {
    label: 'white medium-small square',
  },
  {
    label: 'black small square',
  },
  {
    label: 'white small square',
  },
  {
    label: 'large orange diamond',
  },
  {
    label: 'large blue diamond',
  },
  {
    label: 'small orange diamond',
  },
  {
    label: 'small blue diamond',
  },
  {
    label: 'red triangle pointed up',
  },
  {
    label: 'red triangle pointed down',
  },
  {
    label: 'diamond with a dot',
  },
  {
    label: 'radio button',
  },
  {
    label: 'white square button',
  },
  {
    label: 'black square button',
  },
  {
    label: 'chequered flag',
  },
  {
    label: 'triangular flag',
  },
  {
    label: 'crossed flags',
  },
  {
    label: 'black flag',
  },
  {
    label: 'white flag',
  },
  {
    label: 'rainbow flag',
  },
  {
    label: 'transgender flag',
  },
  {
    label: 'pirate flag',
  },
  {
    label: 'flag: Ascension Island',
  },
  {
    label: 'flag: Andorra',
  },
  {
    label: 'flag: United Arab Emirates',
  },
  {
    label: 'flag: Afghanistan',
  },
  {
    label: 'flag: Antigua & Barbuda',
  },
  {
    label: 'flag: Anguilla',
  },
  {
    label: 'flag: Albania',
  },
  {
    label: 'flag: Armenia',
  },
  {
    label: 'flag: Angola',
  },
  {
    label: 'flag: Antarctica',
  },
  {
    label: 'flag: Argentina',
  },
  {
    label: 'flag: American Samoa',
  },
  {
    label: 'flag: Austria',
  },
  {
    label: 'flag: Australia',
  },
  {
    label: 'flag: Aruba',
  },
  {
    label: 'flag: Åland Islands',
  },
  {
    label: 'flag: Azerbaijan',
  },
  {
    label: 'flag: Bosnia & Herzegovina',
  },
  {
    label: 'flag: Barbados',
  },
  {
    label: 'flag: Bangladesh',
  },
  {
    label: 'flag: Belgium',
  },
  {
    label: 'flag: Burkina Faso',
  },
  {
    label: 'flag: Bulgaria',
  },
  {
    label: 'flag: Bahrain',
  },
  {
    label: 'flag: Burundi',
  },
  {
    label: 'flag: Benin',
  },
  {
    label: 'flag: St. Barthélemy',
  },
  {
    label: 'flag: Bermuda',
  },
  {
    label: 'flag: Brunei',
  },
  {
    label: 'flag: Bolivia',
  },
  {
    label: 'flag: Caribbean Netherlands',
  },
  {
    label: 'flag: Brazil',
  },
  {
    label: 'flag: Bahamas',
  },
  {
    label: 'flag: Bhutan',
  },
  {
    label: 'flag: Bouvet Island',
  },
  {
    label: 'flag: Botswana',
  },
  {
    label: 'flag: Belarus',
  },
  {
    label: 'flag: Belize',
  },
  {
    label: 'flag: Canada',
  },
  {
    label: 'flag: Cocos (Keeling) Islands',
  },
  {
    label: 'flag: Congo - Kinshasa',
  },
  {
    label: 'flag: Central African Republic',
  },
  {
    label: 'flag: Congo - Brazzaville',
  },
  {
    label: 'flag: Switzerland',
  },
  {
    label: 'flag: Côte d’Ivoire',
  },
  {
    label: 'flag: Cook Islands',
  },
  {
    label: 'flag: Chile',
  },
  {
    label: 'flag: Cameroon',
  },
  {
    label: 'flag: China',
  },
  {
    label: 'flag: Colombia',
  },
  {
    label: 'flag: Clipperton Island',
  },
  {
    label: 'flag: Sark',
  },
  {
    label: 'flag: Costa Rica',
  },
  {
    label: 'flag: Cuba',
  },
  {
    label: 'flag: Cape Verde',
  },
  {
    label: 'flag: Curaçao',
  },
  {
    label: 'flag: Christmas Island',
  },
  {
    label: 'flag: Cyprus',
  },
  {
    label: 'flag: Czechia',
  },
  {
    label: 'flag: Germany',
  },
  {
    label: 'flag: Diego Garcia',
  },
  {
    label: 'flag: Djibouti',
  },
  {
    label: 'flag: Denmark',
  },
  {
    label: 'flag: Dominica',
  },
  {
    label: 'flag: Dominican Republic',
  },
  {
    label: 'flag: Algeria',
  },
  {
    label: 'flag: Ceuta & Melilla',
  },
  {
    label: 'flag: Ecuador',
  },
  {
    label: 'flag: Estonia',
  },
  {
    label: 'flag: Egypt',
  },
  {
    label: 'flag: Western Sahara',
  },
  {
    label: 'flag: Eritrea',
  },
  {
    label: 'flag: Spain',
  },
  {
    label: 'flag: Ethiopia',
  },
  {
    label: 'flag: European Union',
  },
  {
    label: 'flag: Finland',
  },
  {
    label: 'flag: Fiji',
  },
  {
    label: 'flag: Falkland Islands',
  },
  {
    label: 'flag: Micronesia',
  },
  {
    label: 'flag: Faroe Islands',
  },
  {
    label: 'flag: France',
  },
  {
    label: 'flag: Gabon',
  },
  {
    label: 'flag: United Kingdom',
  },
  {
    label: 'flag: Grenada',
  },
  {
    label: 'flag: Georgia',
  },
  {
    label: 'flag: French Guiana',
  },
  {
    label: 'flag: Guernsey',
  },
  {
    label: 'flag: Ghana',
  },
  {
    label: 'flag: Gibraltar',
  },
  {
    label: 'flag: Greenland',
  },
  {
    label: 'flag: Gambia',
  },
  {
    label: 'flag: Guinea',
  },
  {
    label: 'flag: Guadeloupe',
  },
  {
    label: 'flag: Equatorial Guinea',
  },
  {
    label: 'flag: Greece',
  },
  {
    label: 'flag: South Georgia & South Sandwich Islands',
  },
  {
    label: 'flag: Guatemala',
  },
  {
    label: 'flag: Guam',
  },
  {
    label: 'flag: Guinea-Bissau',
  },
  {
    label: 'flag: Guyana',
  },
  {
    label: 'flag: Hong Kong SAR China',
  },
  {
    label: 'flag: Heard & McDonald Islands',
  },
  {
    label: 'flag: Honduras',
  },
  {
    label: 'flag: Croatia',
  },
  {
    label: 'flag: Haiti',
  },
  {
    label: 'flag: Hungary',
  },
  {
    label: 'flag: Canary Islands',
  },
  {
    label: 'flag: Indonesia',
  },
  {
    label: 'flag: Ireland',
  },
  {
    label: 'flag: Israel',
  },
  {
    label: 'flag: Isle of Man',
  },
  {
    label: 'flag: India',
  },
  {
    label: 'flag: British Indian Ocean Territory',
  },
  {
    label: 'flag: Iraq',
  },
  {
    label: 'flag: Iran',
  },
  {
    label: 'flag: Iceland',
  },
  {
    label: 'flag: Italy',
  },
  {
    label: 'flag: Jersey',
  },
  {
    label: 'flag: Jamaica',
  },
  {
    label: 'flag: Jordan',
  },
  {
    label: 'flag: Japan',
  },
  {
    label: 'flag: Kenya',
  },
  {
    label: 'flag: Kyrgyzstan',
  },
  {
    label: 'flag: Cambodia',
  },
  {
    label: 'flag: Kiribati',
  },
  {
    label: 'flag: Comoros',
  },
  {
    label: 'flag: St. Kitts & Nevis',
  },
  {
    label: 'flag: North Korea',
  },
  {
    label: 'flag: South Korea',
  },
  {
    label: 'flag: Kuwait',
  },
  {
    label: 'flag: Cayman Islands',
  },
  {
    label: 'flag: Kazakhstan',
  },
  {
    label: 'flag: Laos',
  },
  {
    label: 'flag: Lebanon',
  },
  {
    label: 'flag: St. Lucia',
  },
  {
    label: 'flag: Liechtenstein',
  },
  {
    label: 'flag: Sri Lanka',
  },
  {
    label: 'flag: Liberia',
  },
  {
    label: 'flag: Lesotho',
  },
  {
    label: 'flag: Lithuania',
  },
  {
    label: 'flag: Luxembourg',
  },
  {
    label: 'flag: Latvia',
  },
  {
    label: 'flag: Libya',
  },
  {
    label: 'flag: Morocco',
  },
  {
    label: 'flag: Monaco',
  },
  {
    label: 'flag: Moldova',
  },
  {
    label: 'flag: Montenegro',
  },
  {
    label: 'flag: St. Martin',
  },
  {
    label: 'flag: Madagascar',
  },
  {
    label: 'flag: Marshall Islands',
  },
  {
    label: 'flag: North Macedonia',
  },
  {
    label: 'flag: Mali',
  },
  {
    label: 'flag: Myanmar (Burma)',
  },
  {
    label: 'flag: Mongolia',
  },
  {
    label: 'flag: Macao SAR China',
  },
  {
    label: 'flag: Northern Mariana Islands',
  },
  {
    label: 'flag: Martinique',
  },
  {
    label: 'flag: Mauritania',
  },
  {
    label: 'flag: Montserrat',
  },
  {
    label: 'flag: Malta',
  },
  {
    label: 'flag: Mauritius',
  },
  {
    label: 'flag: Maldives',
  },
  {
    label: 'flag: Malawi',
  },
  {
    label: 'flag: Mexico',
  },
  {
    label: 'flag: Malaysia',
  },
  {
    label: 'flag: Mozambique',
  },
  {
    label: 'flag: Namibia',
  },
  {
    label: 'flag: New Caledonia',
  },
  {
    label: 'flag: Niger',
  },
  {
    label: 'flag: Norfolk Island',
  },
  {
    label: 'flag: Nigeria',
  },
  {
    label: 'flag: Nicaragua',
  },
  {
    label: 'flag: Netherlands',
  },
  {
    label: 'flag: Norway',
  },
  {
    label: 'flag: Nepal',
  },
  {
    label: 'flag: Nauru',
  },
  {
    label: 'flag: Niue',
  },
  {
    label: 'flag: New Zealand',
  },
  {
    label: 'flag: Oman',
  },
  {
    label: 'flag: Panama',
  },
  {
    label: 'flag: Peru',
  },
  {
    label: 'flag: French Polynesia',
  },
  {
    label: 'flag: Papua New Guinea',
  },
  {
    label: 'flag: Philippines',
  },
  {
    label: 'flag: Pakistan',
  },
  {
    label: 'flag: Poland',
  },
  {
    label: 'flag: St. Pierre & Miquelon',
  },
  {
    label: 'flag: Pitcairn Islands',
  },
  {
    label: 'flag: Puerto Rico',
  },
  {
    label: 'flag: Palestinian Territories',
  },
  {
    label: 'flag: Portugal',
  },
  {
    label: 'flag: Palau',
  },
  {
    label: 'flag: Paraguay',
  },
  {
    label: 'flag: Qatar',
  },
  {
    label: 'flag: Réunion',
  },
  {
    label: 'flag: Romania',
  },
  {
    label: 'flag: Serbia',
  },
  {
    label: 'flag: Russia',
  },
  {
    label: 'flag: Rwanda',
  },
  {
    label: 'flag: Saudi Arabia',
  },
  {
    label: 'flag: Solomon Islands',
  },
  {
    label: 'flag: Seychelles',
  },
  {
    label: 'flag: Sudan',
  },
  {
    label: 'flag: Sweden',
  },
  {
    label: 'flag: Singapore',
  },
  {
    label: 'flag: St. Helena',
  },
  {
    label: 'flag: Slovenia',
  },
  {
    label: 'flag: Svalbard & Jan Mayen',
  },
  {
    label: 'flag: Slovakia',
  },
  {
    label: 'flag: Sierra Leone',
  },
  {
    label: 'flag: San Marino',
  },
  {
    label: 'flag: Senegal',
  },
  {
    label: 'flag: Somalia',
  },
  {
    label: 'flag: Suriname',
  },
  {
    label: 'flag: South Sudan',
  },
  {
    label: 'flag: São Tomé & Príncipe',
  },
  {
    label: 'flag: El Salvador',
  },
  {
    label: 'flag: Sint Maarten',
  },
  {
    label: 'flag: Syria',
  },
  {
    label: 'flag: Eswatini',
  },
  {
    label: 'flag: Tristan da Cunha',
  },
  {
    label: 'flag: Turks & Caicos Islands',
  },
  {
    label: 'flag: Chad',
  },
  {
    label: 'flag: French Southern Territories',
  },
  {
    label: 'flag: Togo',
  },
  {
    label: 'flag: Thailand',
  },
  {
    label: 'flag: Tajikistan',
  },
  {
    label: 'flag: Tokelau',
  },
  {
    label: 'flag: Timor-Leste',
  },
  {
    label: 'flag: Turkmenistan',
  },
  {
    label: 'flag: Tunisia',
  },
  {
    label: 'flag: Tonga',
  },
  {
    label: 'flag: Türkiye',
  },
  {
    label: 'flag: Trinidad & Tobago',
  },
  {
    label: 'flag: Tuvalu',
  },
  {
    label: 'flag: Taiwan',
  },
  {
    label: 'flag: Tanzania',
  },
  {
    label: 'flag: Ukraine',
  },
  {
    label: 'flag: Uganda',
  },
  {
    label: 'flag: U.S. Outlying Islands',
  },
  {
    label: 'flag: United Nations',
  },
  {
    label: 'flag: United States',
  },
  {
    label: 'flag: Uruguay',
  },
  {
    label: 'flag: Uzbekistan',
  },
  {
    label: 'flag: Vatican City',
  },
  {
    label: 'flag: St. Vincent & Grenadines',
  },
  {
    label: 'flag: Venezuela',
  },
  {
    label: 'flag: British Virgin Islands',
  },
  {
    label: 'flag: U.S. Virgin Islands',
  },
  {
    label: 'flag: Vietnam',
  },
  {
    label: 'flag: Vanuatu',
  },
  {
    label: 'flag: Wallis & Futuna',
  },
  {
    label: 'flag: Samoa',
  },
  {
    label: 'flag: Kosovo',
  },
  {
    label: 'flag: Yemen',
  },
  {
    label: 'flag: Mayotte',
  },
  {
    label: 'flag: South Africa',
  },
  {
    label: 'flag: Zambia',
  },
  {
    label: 'flag: Zimbabwe',
  },
  {
    label: 'flag: England',
  },
  {
    label: 'flag: Scotland',
  },
  {
    label: 'flag: Wales',
  },
];
