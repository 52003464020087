import Avatar from 'components/Avatar';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import Tabs from 'components/Tabs';
import Timeline from 'components/Timeline';
import {toastSuccess} from 'components/Toaster';
import {PermissionsPeople} from 'constants/permissions';
import copy from 'copy-to-clipboard';
import {hasPermissions} from 'helpers/permission';
import {hasUsernameGenerated} from 'helpers/utils';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {getJimer, getJimerActivity} from 'services/jimer';
import UserTagSelector from '../UserTagSelector';
import './_Styles.scss';
import Attributes from './components/Attributes';
import InfoBar from './components/InfoBar';
import Segments from './components/Segments';
import SurveyResponses from './components/SurveyResponses';

const JimerBanner =
  'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/jimer/jimer-profile-background.svg';

export const TAB_USER_DATA = (
  <div className="tab-item">
    <i className="isax isax-profile-circle" />
    Data
  </div>
);
export const TAB_ACTIVITY = (
  <div className="tab-item">
    <i className="isax isax-map" />
    Activity
  </div>
);
export const TAB_SURVEY_RESPONSE = <div>Survey responses</div>;

const ModalJimerProfile = ({isOpen, onRequestClose, jimerId}) => {
  return (
    <Modal
      large
      className="modal-jimer-profile fade-in-right"
      animationOnOpen="fade-in"
      isOpen={isOpen}
      onRequestClose={onRequestClose}>
      <JimerProfile jimerId={jimerId} />
    </Modal>
  );
};

export const JimerProfile = ({
  jimerId,
  onBack = null,
  defaultTab,
  defaultSurveyId,
}) => {
  const [tab, setTab] = useState(defaultTab || TAB_USER_DATA);

  const {data: jimer, isLoading: isLoadingJimer} = useQuery({
    queryKey: ['jimer', jimerId],
    queryFn: () =>
      getJimer(jimerId, {
        relations: ['analytics', 'segments', 'attributes', 'tags'],
      }),
    enabled: !!jimerId,
    refetchOnWindowFocus: false,
  });

  const {data: jimerActivity, isLoading: isLoadingJimerActivity} = useQuery({
    queryKey: ['jimerActivity', jimerId],
    queryFn: () => getJimerActivity(jimerId),
    enabled: !!jimerId,
    refetchOnWindowFocus: false,
  });

  if (jimerId == null) {
    return <></>;
  }

  const username =
    hasUsernameGenerated(jimer?.username) && jimer?.externalUsername
      ? jimer?.externalUsername
      : jimer?.username;

  const isAnonymous =
    hasUsernameGenerated(username) === true &&
    !jimer.identifyToken &&
    !jimer.externalUid;

  if (isLoadingJimer) {
    return (
      <div className="jimer-profile-wrapper">
        <div className="loader-wrapper">
          <DefaultLoader />
        </div>
      </div>
    );
  }

  const createJimerItem = {
    type: 'CREATE_JIMER',
    createdAt: jimer?.createdAt,
  };

  return (
    <div className="jimer-profile-wrapper">
      <div className="jimer-profile">
        <div className="jimer-banner">
          <img
            className="jimer-banner-img"
            src={JimerBanner}
            alt="jimer banner"
          />
          <Avatar jimer={jimer} />
        </div>
        <div className="jimer-tags-wrapper">
          <UserTagSelector
            jimer={jimer}
            maxTags={5}
            readOnly={hasPermissions(PermissionsPeople.USER_EDIT) === false}
            emptyTrigger={<Button iconLeft="isax isax-tag">Tag user</Button>}
          />
        </div>
        <div className="jimer-details-wrapper">
          {onBack != null && (
            <Button
              className="back-btn"
              iconOnly
              iconLeft="icon-chevron-left"
              onClick={onBack}
            />
          )}

          <div className="jimer-details">
            <div className="username subtitle-2 n-800">
              {isAnonymous ? (
                'Anonymous'
              ) : !!username ? (
                username
              ) : (
                <em>username</em>
              )}
            </div>
            <div className="id-wrapper body-3 n-600">
              {isAnonymous === true
                ? username
                : jimer.identifyToken || jimer.externalUid
                ? jimer.identifyToken || jimer.externalUid
                : null}
              {isAnonymous !== true &&
                (jimer.identifyToken || jimer.externalUid) && (
                  <i
                    className="isax isax-copy"
                    onClick={() => {
                      copy(jimer.identifyToken || jimer.externalUid);
                      toastSuccess('ID copied', {toastId: 'id-copied'});
                    }}
                  />
                )}
            </div>
          </div>
        </div>
        <>
          <Tabs key={tab} defaultTab={tab} onTabChange={setTab}>
            <div label={TAB_USER_DATA} />
            <div label={TAB_ACTIVITY} />
            <div label={TAB_SURVEY_RESPONSE} />
          </Tabs>
          <div className="tab-content">
            {tab === TAB_USER_DATA ? (
              <div className="tab-data-wrapper">
                <InfoBar jimer={jimer} />
                <Segments jimer={jimer} />
                <Attributes jimer={jimer} />
              </div>
            ) : tab === TAB_ACTIVITY ? (
              <>
                {isLoadingJimerActivity ? (
                  <DefaultLoader />
                ) : (
                  <Timeline
                    jimer={jimer}
                    items={[...jimerActivity, createJimerItem]}
                  />
                )}
              </>
            ) : tab === TAB_SURVEY_RESPONSE ? (
              <SurveyResponses
                jimer={jimer}
                defaultSurveyId={defaultSurveyId}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default ModalJimerProfile;
