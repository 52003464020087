import classNames from 'classnames';
import DefaultLoader from 'components/Loader';
import {useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import {spaceService} from 'services';
import './_Styles.scss';
import SpaceExperiences from './components/SpaceExperiences';
import SpaceHeader from './components/SpaceHeader';

const Space = () => {
  const match = useRouteMatch();
  const spaceId = match.params.spaceId;

  const [shouldRefetchExperiences, setShouldRefetchExperiences] =
    useState(false);

  const contentRef = useRef();

  const {
    isLoading,
    data: space,
    refetch: refetchSpace,
  } = useQuery({
    queryKey: ['space', spaceId],
    queryFn: () =>
      spaceService.getSpace(spaceId, {relations: ['evolutions', 'author']}),
    refetchOnWindowFocus: false,
  });

  const refetchAll = () => {
    refetchSpace();
    setShouldRefetchExperiences(true);
  };

  return (
    <div
      className={classNames('s-space', 'fade-in', {
        'is-loading': isLoading,
      })}>
      {isLoading || space == null ? null : (
        <SpaceHeader
          space={space}
          refetchAll={refetchAll}
          refetchSpace={refetchSpace}
        />
      )}
      <div className="space-content-wrapper" ref={contentRef}>
        <div className="space-content">
          {space == null && isLoading !== true ? (
            <div className="space-not-found">Space not found</div>
          ) : isLoading && space == null ? (
            <div className="loader-wrapper">
              <DefaultLoader />
            </div>
          ) : (
            <SpaceExperiences
              space={space}
              refetchSpace={refetchSpace}
              shouldRefetchExperiences={shouldRefetchExperiences}
              setShouldRefetchExperiences={setShouldRefetchExperiences}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Space;
