import {default as classnames} from 'classnames';
import {BuilderContext} from 'contextes/builder';
import {GlobalContext} from 'contextes/Global';
import React, {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {
  BLOCK_TYPE_CHECKLIST_TRIGGER,
  STEP_TYPE_CHECKLIST,
} from 'services/steps';
import Checklist from 'shared/front/components/Checklist';
import ClickableBlockOverlay from 'shared/front/components/Poke/components/ClickableBlockOverlay';
import {PokeContext} from 'shared/front/components/Poke/context';
import JimoLabel from '../PokeRender/components/JimoLabel';
import {getSnippetPositioningStyle} from '../PokeRender/utils';
import './_styles.scss';

const ChecklistRender = () => {
  const {addFontFamily} = useContext(GlobalContext);
  const {
    controlledEvolution: poke,
    setSelectedBlockType,
    selectedBlockType,
    selectedSubItemId,
    setSelectedSubItemId,
    selectedStep,
    selectedLanguage,
  } = useContext(BuilderContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    if (selectedBlockType === BLOCK_TYPE_CHECKLIST_TRIGGER) {
      setIsMinimized(true);
    } else {
      setIsMinimized(false);
    }
  }, [selectedBlockType]);

  const handleSelectedBlockType = (type) => {
    setSelectedBlockType(type);
  };

  const handleSelectedTask = (taskId) => {
    setSelectedSubItemId(taskId);
  };

  const {boostedPositionFlags, style: pokeStyle = {}, steps = []} = poke ?? {};
  const {offsetX = 0, offsetY = 0} = pokeStyle ?? {};

  const stepIndex = steps.findIndex((step) => step.uid === selectedStep?.uid);

  const isChecklistStep = selectedStep?.type === STEP_TYPE_CHECKLIST;
  const {style} = selectedStep;
  const {backgroundColor} = style ?? {};

  return (
    <PokeContext.Provider
      value={{
        poke: {
          ...poke,
          steps: poke?.steps?.sort((a, b) => a.inderOrder - b.indexOrder),
        },
        currentStepIndex: stepIndex,
      }}>
      <div
        className="checklist-render-container"
        onClick={() => {
          setSelectedBlockType(null);
          setSelectedSubItemId(null);
        }}>
        <div className="checklist-render-overlay" />
        <div
          className={classnames('checklist-wrapper', {
            selected: selectedBlockType == null,
          })}
          style={{
            ...getSnippetPositioningStyle(
              boostedPositionFlags,
              1,
              offsetX,
              offsetY
            ),
          }}>
          <div
            className={classnames('checklist-render-wrapper', {
              selected: selectedBlockType == null,
            })}
            style={{
              height: 'auto',
            }}>
            <div
              className={classnames('checklist-render', {
                'is-checklist-step': isChecklistStep,
              })}
              style={{
                width: 'auto',
                height: 'auto',
                position: 'relative',
              }}>
              <Checklist
                inBuilder
                JimoLabel={
                  project.whiteLabeling === false
                    ? () => <JimoLabel backgroundColor={backgroundColor} />
                    : undefined
                }
                onBlockSelected={handleSelectedBlockType}
                selectedBlock={selectedBlockType}
                onTaskSelected={handleSelectedTask}
                selectedTaskId={selectedSubItemId}
                language={selectedLanguage}
                addFontFamily={addFontFamily}
                isMinimized={isMinimized}
              />
            </div>
            <ClickableBlockOverlay isNotCardElem />
          </div>
        </div>
      </div>
    </PokeContext.Provider>
  );
};

export default ChecklistRender;
