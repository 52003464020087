import classNames from 'classnames';
import Button from 'components/Button';
import {PermissionsPoke} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import randomstring from 'randomstring';
import {useEffect, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {
  ROUTE_BANNERS,
  ROUTE_CHECKLISTS,
  ROUTE_HINTS,
  ROUTE_RESOURCE_CENTERS,
  ROUTE_SURVEYS,
  ROUTE_TOURS,
} from 'router/routes.const';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_RESOURCE_CENTER,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {templates} from '../ModalCreatePoke/components/TemplatesModal/templates';
import './_Styles.scss';
import BgEmptyState from './imgs/bg-emptystate.svg';

const TemplateCard = ({image, title, onClick}) => {
  return (
    <div
      key={randomstring.generate(3)}
      className={classNames('template-card')}
      onClick={onClick}>
      <div className="template-header">
        <div className="img-wrapper">
          <img src={image} alt={title} />
        </div>
      </div>
      <div className="content">
        <div className="title">{title}</div>
      </div>
    </div>
  );
};

const EmptyState = ({onCreateNewClick, onTemplateClick}) => {
  const match = useRouteMatch();

  let title, type, arcadeUrl;

  if (match.path === ROUTE_TOURS) {
    title = 'Tours & Modals';
    type = EVOLUTION_TYPE_TOUR;
    arcadeUrl = 'https://demo.arcade.software/CEus7jtdZT534XjW5fDK?embed';
  } else if (match.path === ROUTE_SURVEYS) {
    title = 'Surveys';
    type = EVOLUTION_TYPE_SURVEY;
    arcadeUrl = 'https://demo.arcade.software/PNO3xQZGKj1P9E1qZq9D?embed';
  } else if (match.path === ROUTE_BANNERS) {
    title = 'Banners';
    type = EVOLUTION_TYPE_BANNER;
    arcadeUrl = 'https://demo.arcade.software/SXXcILnFVpm80WU24FIK?embed';
  } else if (match.path === ROUTE_HINTS) {
    title = 'Hints';
    type = EVOLUTION_TYPE_HINT;
    arcadeUrl = 'https://demo.arcade.software/JxnMTPjH7iADeagiIPiS?embed';
  } else if (match.path === ROUTE_CHECKLISTS) {
    title = 'Checklists';
    type = EVOLUTION_TYPE_CHECKLIST;
    arcadeUrl = 'https://demo.arcade.software/JxnMTPjH7iADeagiIPiS?embed';
  } else if (match.path === ROUTE_RESOURCE_CENTERS) {
    title = 'Resource Centers';
    type = EVOLUTION_TYPE_RESOURCE_CENTER;
    arcadeUrl = 'https://demo.arcade.software/JxnMTPjH7iADeagiIPiS?embed';
  }

  const [canScrollToRight, setCanScrollToRight] = useState(true);
  const [canScrollToLeft, setCanScrollToLeft] = useState(false);
  const [isWatchingDemo, setIsWatchingDemo] = useState(false);

  const templatesListRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = templatesListRef.current;

      if (element) {
        const scrollableWidth = element.scrollWidth;
        const clientWidth = element.clientWidth;
        const scrollLeft = element.scrollLeft;

        // Check if you can scroll to the right
        if (scrollableWidth - clientWidth - 2 > scrollLeft) {
          setCanScrollToRight(true);
        } else {
          setCanScrollToRight(false);
        }

        // Check if you can scroll to the left
        if (scrollLeft > 0) {
          setCanScrollToLeft(true);
        } else {
          setCanScrollToLeft(false);
        }
      }
    };

    handleScroll();

    const scrollableDiv = templatesListRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scroll = (scrollOffset) => {
    templatesListRef.current.scrollLeft += scrollOffset;
  };

  const filteredTemplates = templates().filter((t) => {
    return t.experienceType === type;
  });
  const canCreate = hasPermissions(PermissionsPoke.CREATE_EDIT);

  return (
    <div className="pokes-empty-state-wrapper">
      <div
        className="section arcade-wrapper"
        style={{
          backgroundImage: `url(${BgEmptyState})`,
        }}>
        <div className="section-header">
          <div className="section-title">Watch our interactive demo</div>
        </div>
        <div className="section-content">
          <div
            style={{
              position: 'relative',
              paddingBottom: 'calc(61.85270425776754% + 41px)',
              height: 0,
              margin: 'auto',
              maxWidth: '600px', // Adjust the max-width as needed
            }}>
            <iframe
              className={classNames('arcade-iframe', {active: isWatchingDemo})}
              src={arcadeUrl}
              title="Experiences · Jimo"
              frameBorder={0}
              loading="lazy"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
            />
            {isWatchingDemo === false && (
              <div
                className="arcade-overlay"
                style={{
                  width: '600px',
                  height: `${600 / 1.6167441860465117 + 38}px`,
                }}
                onClick={() => setIsWatchingDemo(true)}>
                <div className="arcade-overlay-content">
                  <div className="icon-wrapper">
                    <i className="icon-play" />
                  </div>
                  <div className="title">Watch our interactive demo</div>
                </div>
              </div>
            )}
            {isWatchingDemo === true && (
              <div
                className="arcade-overlay-close"
                onClick={() => setIsWatchingDemo(false)}>
                <i className="icon-close" />
              </div>
            )}
          </div>
        </div>
      </div>
      {canCreate === true && (
        <div className="section create-wrapper">
          <div className="section-header">
            <div className="section-title">
              Create your first {title?.slice(0, -1)}
            </div>
            <div className="templates-navigator">
              <Button
                disabled={canScrollToLeft === false}
                cta
                onClick={() => scroll(-400)}
                iconOnly
                iconRight="icon-chevron-left"
              />
              <Button
                disabled={canScrollToRight === false}
                cta
                onClick={() => scroll(400)}
                iconOnly
                iconRight="icon-chevron-right"
              />
            </div>
          </div>
          <div className="section-content">
            <div
              className="create-from-scratch create-experience-btn-for-targeting"
              onClick={() => onCreateNewClick()}>
              <div className="icon-wrapper">
                <i className="icon-plus" />
              </div>
              <div className="title">Create {title?.slice(0, -1)}</div>
            </div>
            <div className="templates-list" ref={templatesListRef}>
              {filteredTemplates.map((t) => (
                <TemplateCard
                  {...t}
                  onClick={() => {
                    onTemplateClick(t);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmptyState;
