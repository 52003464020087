import {BuilderContext} from 'contextes/builder';
import React, {useContext, useEffect} from 'react';
import './_Styles.scss';

const propTypes = {};

const ResourceCenterStepsManager = () => {
  const {evolution, selectedStepId, setSelectedStepId} =
    useContext(BuilderContext);

  useEffect(() => {
    if (selectedStepId == null) {
      setSelectedStepId(evolution.steps[0]?.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

ResourceCenterStepsManager.propTypes = propTypes;

export default ResourceCenterStepsManager;
