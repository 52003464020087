import classNames from 'classnames';
import React, {useContext} from 'react';
import {CHECKLIST_STEP_TYPE_CHECKLIST} from '../../../../../Checklist/utils';
import ClickableBlockOverlay from '../../../../../Poke/components/ClickableBlockOverlay';
import {
  BLOCK_CHECKLIST_TASK_ITEM,
  BLOCK_RESOURCE_CENTER_TRIGGER,
  BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
} from '../../../../../Poke/constants/blocks';
import {PokeContext, PokeStateContext} from '../../../../../Poke/context';
import {
  RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_COUNT,
  RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_PERCENTAGE,
} from '../../../../constants';
import './_Styles.scss';

const ResourceCenterTriggerChecklistProgress = ({
  innerRef,
  paddingTop,
  paddingBottom,
  gap,
  triggerHeight,
}) => {
  const {embeddedChecklist} = useContext(PokeContext);
  const {
    onBlockSelected,
    onContainerSelected,
    selectedBlock,
    inBuilder,
    blocks,

    completedTasks = [],
  } = useContext(PokeStateContext);

  const block = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS
  );
  const triggerBlock = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_TRIGGER
  );

  if (block == null) {
    return <></>;
  }

  const {style} = block;
  const {type, color} = style;

  const checklistStep = embeddedChecklist?.steps?.find(
    (s) => s.type === CHECKLIST_STEP_TYPE_CHECKLIST
  );
  const checklistBlocks = checklistStep?.blocks ?? [];
  const taskItemBlocks = checklistBlocks.filter(
    (b) => b.type === BLOCK_CHECKLIST_TASK_ITEM
  );
  const completedSteps =
    inBuilder === true
      ? 1
      : taskItemBlocks.filter((b) => completedTasks.includes(b.uid)).length;
  const taskCount =
    inBuilder === true
      ? 5
      : blocks.filter((b) => b.type === BLOCK_CHECKLIST_TASK_ITEM).length;
  const tasksLeft = taskCount - completedSteps;

  return (
    <div
      ref={innerRef}
      className={classNames(
        'block-resource-center-trigger-checklist-progress-wrapper'
      )}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();
          onBlockSelected(BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS);
          onContainerSelected(null);
        }
      }}
      style={{
        color,
        paddingTop,
        paddingBottom,
        gap,
      }}>
      <div
        className="divider"
        style={{
          backgroundColor: color,
          height: triggerHeight,
        }}
      />
      <div
        className={classNames(
          'block-resource-center-trigger-checklist-progress',
          {
            'poke-block-clickable': inBuilder === true,
            selected:
              selectedBlock ===
              BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
          }
        )}>
        <i className="icon-tick-square" />
        {type === RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_COUNT && (
          <div className="count-progress">
            {completedSteps}/{taskCount}
          </div>
        )}
        {type ===
          RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_PERCENTAGE && (
          <div className="percentage-progress">
            {Math.round((completedSteps / taskCount) * 100)}%
          </div>
        )}
        <ClickableBlockOverlay
          colorToUse={triggerBlock?.style?.backgroundColor}
        />
      </div>
    </div>
  );
};

export default ResourceCenterTriggerChecklistProgress;
