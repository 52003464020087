import {hasFlag} from 'helpers/bitwise';
import {useSelector} from 'react-redux';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {F_EXTRA_CAN_SETUP_SEGMENT_INTEGRATION} from 'services/project';
import './_Styles.scss';
import Amplitude from './Amplitude';
import {Subnav} from './components/Subnav';
import Crisp from './Crisp';
import Hubspot from './Hubspot';
import Intercom from './Intercom';
import Mixpanel from './Mixpanel';
import Posthog from './Posthog';
import Salesforce from './Salesforce';
import Segment from './Segment';
import Slack from './Slack';
import Sse from './Sse';
import Zapier from './Zapier';

const Integrations = () => {
  const project = useSelector(() => generalSelector.getProject());

  const {segmentio} = project;
  const segmentActive = !!segmentio?.apiKey;

  return (
    <SettingsBody className="s-settings-integrations">
      <div className="title-3">Integrations</div>
      <Subnav />

      <div className="integrations-list">
        <Intercom />
        {(segmentActive === true ||
          hasFlag(
            F_EXTRA_CAN_SETUP_SEGMENT_INTEGRATION,
            project.extraFlags
          )) && <Segment />}
        <Zapier />
        {/* <Customerio /> */}
        <Hubspot />
        <Salesforce />
        <Slack />
        <Crisp />
        <Posthog />
        <Mixpanel />
        <Amplitude />
        <Sse />
      </div>
    </SettingsBody>
  );
};

export default Integrations;
