import Button from 'components/Button';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS_SALESFORCE} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {salesforceService} from 'services';
import {PLAN_GROWTH_ID, PLAN_SCALE_ID} from 'services/subscription';
import './_Styles.scss';
import SalesforceSettings from './components/SalesforceSettings';

const Logo = 'https://assets.usejimo.com/logos/salesforce.svg';

export const SalesforceLogo = ({props = {}}) => {
  return (
    <img
      className="salesforce-logo"
      src={Logo}
      alt="salesforce-logo"
      {...props}
    />
  );
};

const Salesforce = () => {
  const {update} = useUpdateSubscription();
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject(state));
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const {salesforce} = project;
  const salesforceActive =
    salesforce != null && salesforce.instance_url != null;

  const [isSalesforceLoading, setIsSalesforceLoading] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleAddToSalesforce = async () => {
    if ([PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(subscription.plan) === false) {
      return update({
        planId: PLAN_GROWTH_ID,
        title: 'Connect HubSpot',
        description: 'Unlock HubSpot integration with our Growth plan',
      });
    }
    setIsSalesforceLoading(true);
    const url = await salesforceService.generateUrl(project.uid);
    window.open(url, '_self', 'noopener,noreferrer');
  };

  return (
    <div className="settings-card integration-salesforce">
      <div className="logo-action-wrapper">
        <img className="logo" src={Logo} alt="salesforce-logo" />
        {!salesforceActive ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isSalesforceLoading}
            onClick={handleAddToSalesforce}>
            Enable
          </Button>
        ) : (
          <Button
            thin
            iconLeft="isax isax-setting-2"
            onClick={() =>
              history.push(ROUTE_SETTINGS_INTEGRATIONS_SALESFORCE)
            }>
            Configure
          </Button>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Salesforce{' '}
          {salesforceActive === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Enabled
            </div>
          )}
        </div>
        <div className="body-3">Connect Jimo to your Salesforce workspace</div>
      </div>
      {isSettingsOpen === true && (
        <SalesforceSettings
          isOpen={isSettingsOpen}
          onRequestClose={() => setIsSettingsOpen(false)}
        />
      )}
    </div>
  );
};

export default Salesforce;
