import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import {ModalConfirm} from 'components/Modal';
import {BuilderContext} from 'contextes/builder';
import {AVAILABLE_LANGUAGES} from 'helpers/languages';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {array, bool, func, object, string} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {
  PLAN_BASIC_ID,
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import './_Styles.scss';
import {findLanguages} from './utils';

const propTypes = {
  evolution: object,
  selectedLanguage: string,
  disableAddButton: bool,
  onAddLanguage: func.isRequired,
  onSelectLanguage: func.isRequired,
  onRemoveLanguage: func.isRequired,
  defaultLanguages: array,
  languages: array,
  setLanguages: func,
  hideDefaultLanguage: bool,
};

const LanguageSelector = ({
  evolution = null,
  selectedLanguage = null,
  disableAddButton = false,
  onAddLanguage = () => {},
  onSelectLanguage = () => {},
  onRemoveLanguage = () => {},
  defaultLanguages = [],
  languages = [],
  setLanguages = () => {},
  hideDefaultLanguage = false,
}) => {
  const {isInApp = false, setInAppForegroundForce = () => {}} =
    useContext(BuilderContext) || {};
  const {update} = useUpdateSubscription();

  const projectSubscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const [activeLanguage, setActiveLanguage] = useState(
    selectedLanguage || null
  );
  const [languageToDelete, setLanguageToDelete] = useState(null);

  const refDropdown = useRef();

  useEffect(() => {
    if (evolution != null) {
      setLanguages(findLanguages(evolution));
    }
  }, []);

  useEffect(() => {
    if (defaultLanguages?.length > 0) {
      setLanguages(
        defaultLanguages?.map((languageCode) =>
          AVAILABLE_LANGUAGES.find((l) => l.code === languageCode)
        )
      );
    }
  }, [defaultLanguages]);
  // For in-app builder, we need to bring it to the foreground to click on the confirm delete modale
  useEffect(() => {
    if (isInApp === false) {
      return;
    }
    setInAppForegroundForce(languageToDelete != null);
  }, [languageToDelete]);

  const handleAddLanguage = (language) => {
    refDropdown.current.close();

    if (
      languages.length >= 1 &&
      [PLAN_BASIC_ID, PLAN_STARTUP_ID].includes(projectSubscription.plan)
    ) {
      return update({
        planId: PLAN_GROWTH_ID,
        title: 'Unlimited Multiple Languages',
        description: 'Publish your experiences in every languages you need.',
      });
    }
    if (languages.find((l) => l.code === language.code) == null) {
      setLanguages(languages.concat(language));
    }
    handleSelectLanguage(language.code);
    onAddLanguage(language.code);
  };
  const handleDelete = (languageCode) => {
    setLanguages(languages.filter((l) => l.code !== languageCode));
    handleSelectLanguage(null);
    onRemoveLanguage(languageCode);
    setLanguageToDelete(null);
  };
  const handleSelectLanguage = (languageCode) => {
    setActiveLanguage(languageCode);
    onSelectLanguage(languageCode);
  };

  return (
    <div className="language-selector">
      <div
        className={classnames('language-list', {
          empty: languages.length === 0 && hideDefaultLanguage === true,
        })}>
        {hideDefaultLanguage !== true && (
          <div
            onClick={() => handleSelectLanguage(null)}
            className={classnames('language', {
              active: activeLanguage == null,
            })}>
            Default
          </div>
        )}
        {languages.map((l) => {
          return (
            <div
              key={l.code}
              onClick={() => handleSelectLanguage(l.code)}
              className={classnames('language', {
                active: activeLanguage === l.code,
              })}>
              {l.label}
              {activeLanguage === l.code && (
                <i
                  className="icon-trash"
                  onClick={(e) => {
                    e.stopPropagation();
                    setLanguageToDelete(l.code);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      {disableAddButton === false && (
        <Dropdown
          className="dropdown-add-languages"
          innerRef={refDropdown}
          position={'bottom'}
          offsetY={7}
          trigger={
            <Button className="add-translation-btn" iconLeft="icon-plus" thin>
              Add a translation
            </Button>
          }>
          <Menu>
            {AVAILABLE_LANGUAGES.filter(
              (al) => languages.find((l) => l.code === al.code) == null
            )
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((l) => (
                <MenuItem
                  className="dropdown-language-item"
                  onClick={() => handleAddLanguage(l)}
                  icon={`flag-icon ${l.icon}`}
                  key={l.code}>
                  {l.label}
                </MenuItem>
              ))}
          </Menu>
        </Dropdown>
      )}
      <ModalConfirm
        className="delete-language-modal"
        title="Delete translations?"
        isOpen={languageToDelete != null}
        onConfirm={() => handleDelete(languageToDelete)}
        onCancel={() => setLanguageToDelete(null)}
        cancelText="Cancel"
        confirmText="Delete"
        cancelBtnProps={{
          cta: false,
        }}
        confirmBtnProps={{
          danger: true,
          primary: false,
          cta: false,
        }}>
        <div className="content">
          Are you sure you want to remove this language?
        </div>
      </ModalConfirm>
    </div>
  );
};

LanguageSelector.propTypes = propTypes;

export default LanguageSelector;
