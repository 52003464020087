import {generalActions} from 'actions';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SPACES} from 'router/routes.const';
import ModalEditSpace from 'scenes/Spaces/component/ModalEditSpace';
import {projectService, spaceService} from 'services';
import {Swaler} from 'swaler';
import ModalDeleteSpace from '../ModalDeleteSpace';
import './_Styles.scss';

const logger = new Swaler('DropdownSpaceActions');

const DropdownSpaceActions = ({space, refetchSpace = () => {}}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isEditingSpace, setIsEditingSpace] = useState(false);
  const [isDeletingSpace, setIsDeletingSpace] = useState(false);

  const handlePinSpace = async () => {
    try {
      await spaceService.pinSpace(space.uid);
      refetchSpace();

      const updatedProject = await projectService.getProject();
      dispatch(generalActions.uptProject(updatedProject));

      toastSuccess('Space pinned successfully');
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to pin space with error', code);
      return toastDanger([title, message], {actions});
    }
  };

  const handleUnpinSpace = async () => {
    try {
      await spaceService.unpinSpace(space.uid);
      refetchSpace();

      const updatedProject = await projectService.getProject();
      dispatch(generalActions.uptProject(updatedProject));

      toastSuccess('Space unpinned successfully');
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to unpin space with error', code);
      return toastDanger([title, message], {actions});
    }
  };

  const handleDeleteSpace = async () => {
    try {
      await spaceService.deleteSpace(space.uid);
      toastSuccess('Space deleted successfully');
      history.push(ROUTE_SPACES);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to delete space with error', code);
      return toastDanger([title, message], {actions});
    }
  };
  return (
    <>
      <Dropdown
        className="dropdown-space-actions"
        triggerClassName="menu-dropdown-trigger"
        trigger={
          <Button thin iconOnly>
            <i className="icon-horizontal-menu"></i>
          </Button>
        }
        position="bottom right"
        offsetY={8}>
        <Menu>
          {space.indexOrder === -1 ? (
            <MenuItem onClick={handlePinSpace}>
              <i className="isax isax-heart" />
              Pin to sidebar
            </MenuItem>
          ) : (
            <MenuItem onClick={handleUnpinSpace}>
              <i className="isax isax-heart-slash" />
              Unpin from sidebar
            </MenuItem>
          )}
          <MenuItem onClick={() => setIsEditingSpace(true)}>
            <i className="isax isax-edit-2" />
            Edit details
          </MenuItem>
          <MenuItem className="r-400" onClick={() => setIsDeletingSpace(true)}>
            <i className="isax isax-trash" />
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>

      {isEditingSpace && (
        <ModalEditSpace
          isOpen={isEditingSpace}
          onRequestClose={() => {
            setIsEditingSpace(false);
            refetchSpace();
          }}
          space={space}
        />
      )}
      {isDeletingSpace && (
        <ModalDeleteSpace
          isOpen={isDeletingSpace}
          onRequestClose={() => setIsDeletingSpace(false)}
          onConfirm={handleDeleteSpace}
        />
      )}
    </>
  );
};

export default DropdownSpaceActions;
