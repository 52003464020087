import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import PopupSetting from '../../PopupSetting';
import ClickableInput from '../../items/ClickableInput';
import Shadow from '../../modals/Shadow';
import './_Styles.scss';

const ShadowItem = ({value, onChange}) => {
  return (
    <SectionItem title="Shadow" contentClassName="shadow-picker">
      <PopupSetting
        trigger={
          <ClickableInput
            value={value?.color}
            leftLabel={
              <span
                className="preview-shadow-color"
                style={{background: value?.color || '#FFFFFF'}}
              />
            }
            erasable={true}
            onErase={() =>
              onChange({
                shadow: {color: null, opacity: 0, x: 0, y: 0, blur: 0},
              })
            }
          />
        }
        title="Shadow"
        content={<Shadow value={value} onChange={onChange} />}
      />
    </SectionItem>
  );
};

export default ShadowItem;
