import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {crispHelpers} from 'helpers';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  Link,
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  ROUTE_JOIN,
  ROUTE_JOIN_REGISTER,
  ROUTE_LOGIN,
  ROUTE_LOGIN_WITH_CODE,
  ROUTE_LOGIN_WITH_JOINING,
  ROUTE_LOGOUT,
  ROUTE_ONBOARDING_DETAILS_STEP_1_1,
  ROUTE_ONBOARDING_DETAILS_STEP_1_2,
  ROUTE_ONBOARDING_DETAILS_STEP_2_1,
  ROUTE_ONBOARDING_DETAILS_STEP_2_2,
  ROUTE_ONBOARDING_DETAILS_STEP_2_3,
  ROUTE_ONBOARDING_DETAILS_STEP_3_1,
  ROUTE_RECOVERY_PASSWORD,
  ROUTE_REGISTER,
  ROUTE_REGISTER_EMAIL_SENT,
  ROUTE_REGISTER_ONBOARDED,
  ROUTE_REGISTER_SETUP_PASSWORD,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_INSTALLATION,
  ROUTE_SETTINGS_PLAN,
  ROUTE_SETTINGS_TEAM,
  ROUTE_SWITCH_PROJECT,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {canLaunchTrialExtension} from 'services/project';
import {
  F_GROWTH_TRIAL_USED,
  F_HAS_BEEN_EXTENDED,
  F_IS_OFFERED_BY_JIMO,
  F_IS_PAYING,
  F_IS_TRIALING,
  F_STARTUP_TRIAL_USED,
  hasSaasMantraPlan,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ModalExtensionTrial');

const ModalExtensionTrial = () => {
  const location = useLocation();
  const history = useHistory();

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const canExtend =
    hasFlag(F_HAS_BEEN_EXTENDED, subscription?.extraFlags) === false;
  const canLaunchExtension = canLaunchTrialExtension();

  const [forceReRender, setForceReRender] = useState(false);

  // In case the user deletes the modal using the Devtool, it will reappear when the user navigates to another page
  useEffect(() => {
    setForceReRender(true);
    setTimeout(() => {
      setForceReRender(false);
    }, 100);
  }, [location.key]);

  if (forceReRender === true) {
    return <></>;
  }

  if (
    subscription == null ||
    hasSaasMantraPlan() === true ||
    hasFlag(F_IS_OFFERED_BY_JIMO, subscription.extraFlags) ||
    hasFlag(F_IS_TRIALING, subscription.extraFlags) ||
    hasFlag(F_IS_PAYING, subscription.extraFlags) ||
    hasFlags(
      [F_STARTUP_TRIAL_USED, F_GROWTH_TRIAL_USED],
      subscription.extraFlags,
      true
    ) === false
  ) {
    return <></>;
  }
  if (
    [
      ROUTE_SETTINGS_INSTALLATION,
      ROUTE_SETTINGS_PLAN,
      ROUTE_SWITCH_PROJECT(),
      ROUTE_REGISTER,
      ROUTE_REGISTER_ONBOARDED,
      ROUTE_REGISTER_EMAIL_SENT,
      ROUTE_REGISTER_SETUP_PASSWORD,
      ROUTE_LOGIN,
      ROUTE_LOGIN_WITH_CODE,
      ROUTE_LOGIN_WITH_JOINING(),
      ROUTE_LOGOUT,
      ROUTE_ONBOARDING_DETAILS_STEP_1_1,
      ROUTE_ONBOARDING_DETAILS_STEP_1_2,
      ROUTE_ONBOARDING_DETAILS_STEP_2_1,
      ROUTE_ONBOARDING_DETAILS_STEP_2_2,
      ROUTE_ONBOARDING_DETAILS_STEP_2_3,
      ROUTE_ONBOARDING_DETAILS_STEP_3_1,
      ROUTE_JOIN(),
      ROUTE_JOIN_REGISTER(),
      ROUTE_RECOVERY_PASSWORD,
      ROUTE_SETTINGS_GENERAL,
      ROUTE_SETTINGS_TEAM,
    ].includes(location.pathname)
  ) {
    return <></>;
  }

  return (
    <Modal
      className="modal-extension-trial"
      animationOnOpen="fade-in"
      overlayClassName="modal-extension-trial__overlay"
      isOpen={true}>
      <div className="main-wrapper">
        <div className="main">
          <div className="title-3 n-800">
            {canLaunchExtension === true ? (
              <>Ready for your trial extension?</>
            ) : canExtend === true ? (
              <>Still want to explore Jimo?</>
            ) : (
              <>Still want to use Jimo?</>
            )}
          </div>
          <p className="body-2 n-700">
            {canLaunchExtension === true ? (
              <>
                Your additional trial of 14 days is ready to be launched! Feel
                free to start whenever you're ready.
              </>
            ) : canExtend === true ? (
              <>
                Your free trial has ended, but you can extend it for an
                additional 14 days by installing our SDK.
              </>
            ) : (
              <>Your free trial has ended! Upgrade to continue using Jimo.</>
            )}
          </p>
          {canLaunchExtension === true ? (
            <Link to={ROUTE_SETTINGS_PLAN}>
              <Button primary>Start my trial extension</Button>
            </Link>
          ) : canExtend === true ? (
            <Link to={ROUTE_SETTINGS_INSTALLATION}>
              <Button primary>Install SDK & Extend Trial</Button>
            </Link>
          ) : (
            <Link to={ROUTE_SETTINGS_PLAN}>
              <Button primary>Choose a plan</Button>
            </Link>
          )}
        </div>
      </div>
      {canExtend === true && canLaunchExtension === false && (
        <p className="body-2 n-700">
          Or... Ready to take the next step with Jimo? Choose a plan or get in
          touch with us to continue your journey.
        </p>
      )}
      <div className="actions">
        <Button
          muted
          onClick={() =>
            crispHelpers.startCrispThread(
              canExtend === true
                ? 'My trial has ended, what are the next steps? :-)'
                : "I'd like to continue using Jimo, what are the next steps? :-)"
            )
          }>
          Contact sales
        </Button>
        {canExtend === true && canLaunchExtension === false && (
          <Button muted onClick={() => history.push(ROUTE_SETTINGS_PLAN)}>
            Choose plan
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ModalExtensionTrial;
