import classnames from 'classnames';
import colorContrast from 'font-color-contrast';
import React, {useContext, useEffect, useState} from 'react';
import {
  BLOCK_OPEN_QUESTION,
  getDefaultResponseForBlock,
} from '../../constants/blocks';
import {PokeContext, PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {hex2Rgb} from '../../utils/hex-2-rgb';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const parseOpenQuestionValue = (value) => {
  const [placeholder] = value.split(';');

  return {
    placeholder,
  };
};

export const getOpacityFromHex = (hex) => {
  // Ensure the hex code is valid and includes an alpha value
  if (hex.length === 9) {
    // Extract the alpha value (last two characters)
    const alphaHex = hex.slice(-2);

    // Convert the alpha value from hexadecimal to decimal
    const alphaDecimal = parseInt(alphaHex, 16);

    // Calculate the opacity percentage
    const opacityPercentage = alphaDecimal / 255;

    return opacityPercentage;
  } else {
    return null;
  }
};

export const BlockOpenQuestion = () => {
  const {poke} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    response = getDefaultResponseForBlock(BLOCK_OPEN_QUESTION),
    updateResponse,
    language,
    addFontFamily,
  } = useContext(PokeStateContext);

  const [focused, setFocused] = useState(false);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_OPEN_QUESTION,
  });

  const block = currentStep.blocks.find((b) => b.type === BLOCK_OPEN_QUESTION);
  const {placeholder} = parseOpenQuestionValue(block.value);
  const {primaryColor, fontFamily = 'Inter', ...restStyle} = block.style || {};

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const {style} = poke;
  const {background} = style ?? {};
  const borderColor = colorContrast(
    background?.primaryColor != null
      ? background.primaryColor.length > 7
        ? background?.primaryColor?.slice(
            0,
            background?.primaryColor?.length - 2
          )
        : background?.primaryColor
      : '#FFFFFF'
  );
  const primaryOpacity = getOpacityFromHex(primaryColor);
  const normalizedTextareaBackground = colorContrast(
    primaryColor != null && primaryOpacity != null && primaryOpacity > 0.5
      ? primaryColor.slice(0, primaryColor.length - 2)
      : '#FFFFFF'
  );
  const textColor =
    normalizedTextareaBackground === '#000000' ? '#000000' : '#FFFFFF';

  const translation = block.translations?.find((t) => t.language === language);

  const handleResponse = (value) => {
    updateResponse(value);
  };

  return (
    <div
      className={classnames('poke-block-open-question', {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        selected: selectedBlock === BLOCK_OPEN_QUESTION,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_OPEN_QUESTION);
      }}>
      <textarea
        style={{
          ...restStyle,
          fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
          backgroundColor: primaryColor,
          borderColor: `rgba(${hex2Rgb(borderColor).join(',')},${
            focused === true ? '0.2' : '0.1'
          })`,
          color: textColor,
        }}
        placeholder={translation != null ? translation.value : placeholder}
        value={response}
        onChange={({target}) => handleResponse(target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}></textarea>
      <ClickableBlockOverlay withoutBottomOverflow />
    </div>
  );
};
