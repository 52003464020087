import BtnCopy from 'components/ButtonCopy';
import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import TextArea from 'components/TextArea';
import Tooltip from 'components/Tooltip';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useEffect, useRef} from 'react';
import ReactSyntaxHighlighter from 'react-syntax-highlighter';
import {tomorrowNightEighties} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import IconPicker, {
  HINT_ICON_BUILT_IN,
  triggerIconsList,
} from 'scenes/PokeBuilder/components/BlockEditor/components/modals/IconPicker';
import Shadow from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Shadow';
import ColorItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/ColorItem';
import FontFamilyItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/FontFamilyItem';
import PaddingItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/PaddingItem';
import RadiusItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/RadiusItem';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {
  F_PAGE_FEED,
  TRIGGER_BADGE,
  TRIGGER_CUSTOM,
  TRIGGER_DEFAULT,
  TRIGGER_NONE,
  TRIGGER_SIDE_BADGE,
  defaultChangelogStyle,
} from 'services/project';
import EmojiBell from 'shared/front/components/Misc/EmojiBell';
import './_Styles.scss';

export const CHANGELONG_TRIGGER_SHOW_ALWAYS = 'CHANGELONG_TRIGGER_SHOW_ALWAYS';
export const CHANGELONG_TRIGGER_SHOW_IF_UNREAD =
  'CHANGELONG_TRIGGER_SHOW_IF_UNREAD';

export const triggerOptions = [
  {
    label: (
      <>
        <i className="isax isax-eye-slash" />
        Disable
      </>
    ),
    value: TRIGGER_NONE,
  },
  {
    label: (
      <>
        <i className="icon-float-trigger" />
        Float
      </>
    ),
    value: TRIGGER_DEFAULT,
  },
  {
    label: (
      <>
        <div className="trigger-pulsating">1</div> Pulsating
      </>
    ),
    value: TRIGGER_BADGE,
  },
  {
    label: (
      <>
        <i className="icon-side-badge" />
        Side badge
      </>
    ),
    value: TRIGGER_SIDE_BADGE,
  },
  {
    label: <>Custom</>,
    value: TRIGGER_CUSTOM,
  },
];

const showOptions = [
  {
    label: (
      <>
        <span className="body-3 n-800">Always</span>
        <span className="body-4 n-700">Trigger is always displayed.</span>
      </>
    ),
    value: CHANGELONG_TRIGGER_SHOW_ALWAYS,
  },
  {
    label: (
      <>
        <span className="body-3 n-800">Only if unread posts</span>
        <span className="body-4 n-700">
          Trigger will only be displayed when there are unread posts.
        </span>
      </>
    ),
    value: CHANGELONG_TRIGGER_SHOW_IF_UNREAD,
  },
];

const Trigger = () => {
  const {
    changelogTheme: theme,
    setChangelogTheme: setTheme,
    project,
    setProject,
  } = useContext(BuilderContext);

  const iconWrapperRef = useRef(null);

  const style = theme?.trigger || {
    ...defaultChangelogStyle.trigger,
    backgroundColor: project.widgetThemeColor,
  };

  const updateStyle = (updatedStyle) => {
    const newTheme = {
      ...theme,
      trigger: {
        ...style,
        ...updatedStyle,
      },
    };
    setTheme(newTheme);
  };

  const {fileTriggerIcon} = project;
  const selectedIcon = triggerIconsList.find(
    (i) => i.value === style.iconName || null
  );

  useEffect(() => {
    const svg = iconWrapperRef.current?.querySelector('svg');
    if (svg) {
      const paths = svg.querySelectorAll('path');
      paths.forEach((path) => {
        path.style.fill = 'black';
      });
    }
  }, [style.iconColor, selectedIcon, iconWrapperRef.current]);

  return (
    <div className="block-settings changelog-trigger">
      <div className="section">
        <div className="section-title">General</div>
        <div className="section-content">
          <SectionItem title="Type" sectionItemClassName="change-trigger">
            <SelectGroup
              isSearchable={false}
              classNamePrefix="changelog-trigger-select"
              options={triggerOptions}
              value={triggerOptions.find(
                (o) => o.value === project.triggerMode
              )}
              onChange={(option) => {
                setProject({
                  ...project,
                  triggerMode: option.value,
                });
              }}
              menuPortalTarget={document.body}
            />
          </SectionItem>

          {[TRIGGER_DEFAULT, TRIGGER_SIDE_BADGE].includes(
            project.triggerMode
          ) && (
            <>
              <SectionItem
                title="Show"
                sectionItemClassName="changelog-trigger-show">
                <SelectGroup
                  isSearchable={false}
                  classNamePrefix="changelog-trigger-show-select"
                  options={showOptions}
                  value={showOptions.find(
                    (o) =>
                      o.value ===
                      (project.changelogSettings?.trigger?.show ??
                        CHANGELONG_TRIGGER_SHOW_ALWAYS)
                  )}
                  onChange={(option) => {
                    setProject({
                      ...project,
                      changelogSettings: {
                        ...(project.changelogSettings ?? {}),
                        trigger: {
                          ...(project.changelogSettings?.trigger ?? {}),
                          show: option.value,
                        },
                      },
                    });
                  }}
                  menuPortalTarget={document.body}
                />
              </SectionItem>
              <SectionItem title="Title" sectionItemClassName="trigger-title">
                <TextArea
                  value={project.widgetTriggerMessage}
                  placeholder={
                    hasFlag(F_PAGE_FEED, project.widgetContentFlags) === true
                      ? `What's new on ${project.name}?`
                      : `Help us improve ${project.name}`
                  }
                  onChange={({target}) => {
                    setProject({
                      ...project,
                      widgetTriggerMessage: target.value,
                    });
                  }}
                  className="title-content-input trigger-title-content-input"
                />
              </SectionItem>
            </>
          )}
          {[TRIGGER_BADGE].includes(project.triggerMode) && (
            <div className="section-item code-snippet">
              <div className="copy-row">
                <div className="title">
                  Trigger snippet
                  <Tooltip
                    offsetY={10}
                    dark
                    trigger={<i className="icon-info-circle-o" />}>
                    Paste this snippet in your app to display the trigger
                  </Tooltip>
                </div>
                <BtnCopy
                  text="Copy"
                  textToCopy={`<div class="jimo-widget">What's new?</div>`}
                />
              </div>
              <div className="code-row">
                <ReactSyntaxHighlighter
                  language="javascript"
                  style={tomorrowNightEighties}>
                  {`<div class="jimo-widget">What's new?</div>`}
                </ReactSyntaxHighlighter>
              </div>
            </div>
          )}
          {project.triggerMode === TRIGGER_CUSTOM && (
            <div
              className="section-item custom-trigger subtitle-4 b-400"
              onClick={() => {
                window.open(
                  'https://help.usejimo.com/help-center/v/using-jimo/changelog/changelog/positionning-trigger#custom',
                  '_blank',
                  'noopener noreferrer'
                );
              }}>
              How to set up a Custom Trigger
              <i className="isax isax-export-3" />
            </div>
          )}
        </div>
      </div>
      {[TRIGGER_DEFAULT, TRIGGER_SIDE_BADGE].includes(project.triggerMode) && (
        <>
          <Divider />
          <div className="section">
            <div className="section-title">Style</div>
            <div className="section-content">
              <SectionItem title="Shadow" sectionItemClassName="shadow-picker">
                <PopupSetting
                  trigger={
                    <ClickableInput
                      value={style.shadow?.color}
                      leftLabel={
                        <span
                          className="preview-shadow-color"
                          style={{
                            background: style.shadow?.color || '#FFFFFF',
                          }}
                        />
                      }
                    />
                  }
                  title="Shadow"
                  content={
                    <Shadow
                      value={style.shadow}
                      onChange={(value) => {
                        updateStyle({shadow: value});
                      }}
                    />
                  }
                />
              </SectionItem>
              <RadiusItem
                value={style.borderRadius}
                onChange={(value) => {
                  updateStyle({borderRadius: value});
                }}
                max={50}
              />
              <PaddingItem
                value={{
                  paddingTop: style.paddingTop ?? 10,
                  paddingBottom: style.paddingBottom ?? 10,
                  paddingLeft: style.paddingLeft ?? 16,
                  paddingRight: style.paddingRight ?? 16,
                }}
                onChange={(value) => {
                  updateStyle({
                    paddingTop: value.paddingTop,
                    paddingBottom: value.paddingBottom,
                    paddingLeft: value.paddingLeft,
                    paddingRight: value.paddingRight,
                  });
                }}
              />
              <ColorItem
                title="Background"
                value={style.backgroundColor}
                onChange={(v) => {
                  updateStyle({backgroundColor: v});
                }}
              />
            </div>
          </div>
          <Divider />
          <div className="section">
            <div className="section-title">Text</div>
            <div className="section-content">
              <ColorItem
                title="Color"
                value={style.fontColor}
                onChange={(v) => {
                  updateStyle({fontColor: v});
                }}
              />
              <FontFamilyItem
                value={style.fontFamily}
                onChange={(v) => {
                  updateStyle({fontFamily: v});
                }}
              />
            </div>
          </div>
          {TRIGGER_DEFAULT === project.triggerMode && (
            <>
              <Divider />
              <div className="section">
                <div className="section-title">Icon</div>
                <div className="section-content">
                  <ColorItem
                    title="Color"
                    value={style.iconColor}
                    onChange={(v) => {
                      updateStyle({iconColor: v});
                    }}
                  />
                  <SectionItem
                    title="Icon"
                    sectionItemClassName="item-icon-picker">
                    <PopupSetting
                      className="picker-icon-popup"
                      trigger={
                        <ClickableInput
                          value={
                            style.iconSource === HINT_ICON_BUILT_IN
                              ? selectedIcon?.label
                              : style.iconUrl != null
                              ? 'Custom Icon'
                              : null
                          }
                          leftLabel={
                            <span className="preview-icon" ref={iconWrapperRef}>
                              {style.iconSource === HINT_ICON_BUILT_IN ? (
                                selectedIcon?.icon
                              ) : style.iconUrl ? (
                                <img src={style.iconUrl} alt="" />
                              ) : (
                                <EmojiBell />
                              )}
                            </span>
                          }
                          inputProps={{
                            placeholder: 'Select an icon',
                          }}
                        />
                      }
                      title="Trigger icons"
                      content={
                        <IconPicker
                          style={style}
                          file={fileTriggerIcon}
                          type="trigger"
                          onChange={({style: updatedStyle, file}) => {
                            updateStyle({
                              ...style,
                              ...updatedStyle,
                            });
                            setProject({
                              ...project,
                              fileTriggerIcon: file,
                            });
                          }}
                        />
                      }
                    />
                  </SectionItem>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Trigger;
