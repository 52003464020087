import {ModalConfirm} from 'components/Modal';
import './_Styles.scss';

const ModalDeleteSpace = ({isOpen, onRequestClose, onConfirm, ...props}) => {
  const icon = 'isax isax-trash';
  const title = 'Delete space?';
  const content =
    'Are you sure you want to delete this space? This action cannot be undone.';

  return (
    <ModalConfirm
      {...props}
      className="modal-delete-space"
      title={
        <>
          <div className="icon-wrapper">
            <i className={icon} />
          </div>
          <span className="title-4 n-800">{title}</span>
        </>
      }
      closable={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onConfirm={onConfirm}
      onCancel={onRequestClose}
      cancelText="Cancel"
      confirmText="Delete"
      cancelBtnProps={{
        cta: false,
      }}
      confirmBtnProps={{
        danger: true,
        primary: true,
        cta: false,
      }}>
      <div className="content n-700">{content}</div>
    </ModalConfirm>
  );
};

export default ModalDeleteSpace;
