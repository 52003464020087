import {generalActions} from 'actions';
import Alert from 'components/Alert';
import Button from 'components/Button';
import {toastInfo} from 'components/Toaster';
import React, {useEffect} from 'react';
import {useMutation} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {zapierService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import HelpSection from './components/HelpSection';
import SettingsSetup from './components/SettingsSetup';

const logger = new Swaler('ZapierSettings');

const ZapierSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject());

  const {zapier} = project;

  const {mutate: deleteZapier, isLoading: isLoadingZapierDelete} = useMutation(
    () => zapierService.deleteZapier(zapier?.uid),
    {
      onError: (err) => {
        logger.error(err);
      },
      onSuccess: () => {
        toastInfo([
          'Integration disconnected',
          'Your integration with Zapier has been disconnected!',
        ]);
        history.push(ROUTE_SETTINGS_INTEGRATIONS);
        dispatch(generalActions.uptProject({zapier: null}));
      },
    }
  );

  useEffect(() => {
    if (zapier == null) {
      history.push(ROUTE_SETTINGS_INTEGRATIONS);
      toastInfo([
        'Integration not connected',
        "Zapier integration isn't connected yet. Please connect the integration first.",
      ]);
    }
  }, [zapier]);

  return (
    <SettingsBody className="zapier-settings">
      <div className="zapier-settings-header">
        <div className="left-side">
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={async () => {
              history.push(ROUTE_SETTINGS_INTEGRATIONS);
            }}
          />
          <div className="title-3">Zapier Integration</div>
        </div>
        <div className="right-side">
          <Button
            thin
            primary
            iconRight="icon-arrow-right"
            onClick={() =>
              window.open(
                'https://zapier.com/developer/public-invite/179212/d5095cc310e87041d91a62dc053956d4/',
                '_blank',
                'noopener noreferrer'
              )
            }>
            Enable Jimo in Zapier
          </Button>
        </div>
      </div>
      <div className="zapier-settings-content">
        <div className="left-side">
          <SettingsSetup zapier={zapier} />
        </div>
        <div className="right-side">
          <Alert info title="Integration in BETA" icon="isax isax-info-circle">
            Since Jimo is flag as BETA in Zapier, you will need to enable Jimo
            manually by clicking on <b>Enable Jimo in Zapier</b> to start
            building zap with Jimo.
          </Alert>
          <HelpSection
            isDisconnecting={isLoadingZapierDelete}
            onDisconnect={() => {
              deleteZapier();
            }}
          />
        </div>
      </div>
    </SettingsBody>
  );
};

export default ZapierSettings;
