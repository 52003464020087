import classnames from 'classnames';
import Alert from 'components/Alert';
import Button from 'components/Button';
import {Modal} from 'components/Modal';
import SelectGroup from 'components/Select';
import TextArea from 'components/TextArea';
import {GlobalContext} from 'contextes/Global';
import {useContext, useState} from 'react';
import {projectService} from 'services';
import {isPaying, isTrying} from 'services/project';
import './_styles.scss';

const OPTIONS = [
  {label: 'I went for a competitor', value: 'ALTERNATIVE'},
  {label: 'We are cutting costs', value: 'NO_BUDGET'},
  {label: "It's too expensive", value: 'TOO_EXPENSIVE'},
  {label: 'Some features are missing', value: 'MISSING_FEATURES'},
];

export const ModalFeedbackCancelPlan = () => {
  const {modalFeedbackCancelPlan, setModalFeedbackCancelPlan} =
    useContext(GlobalContext);

  const [reason, setReason] = useState(null);
  const [details, setDetails] = useState('');
  const [canceling, setCanceling] = useState(false);

  const handleRequestClose = () => {
    setReason(null);
    setDetails('');
    modalFeedbackCancelPlan.onCancel();
  };
  const handleSubmit = async () => {
    setCanceling(true);
    await projectService.createChurn({
      reason: reason.value,
      details,
      isTrial: isTrying(),
    });
    modalFeedbackCancelPlan.onSubmit();
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={handleRequestClose}
      className={classnames('modal-feedback-cancel-plan')}>
      <div className="modal-header subtitle-2">
        Cancel plan
        <Button iconOnly onClick={handleRequestClose}>
          <i className="icon-close"></i>
        </Button>
      </div>
      <div className="modal-body">
        <Alert
          warning
          icon="isax isax-info-circle5"
          title={
            isPaying()
              ? 'You will lose your current plan forever'
              : 'You will lose your 14 days free trial forever'
          }>
          {isPaying() ? (
            <>
              Canceling your current plan is an irreversible decision. If you
              have any questions, feel free to reach out to{' '}
              <b>support@usejimo.com</b> for assistance.
            </>
          ) : (
            <>
              After ending this free trial, you cannot initiate another free
              trial for different plans. This action is irreversible. You can
              reach out to <b>support@usejimo.com</b> for assistance.
            </>
          )}
        </Alert>
        <SelectGroup
          legend="Tell us why you decided to cancel your plan"
          placeholder="Choose an option"
          value={reason}
          options={OPTIONS}
          onChange={(value) => setReason(value)}
        />
        {reason != null && (
          <TextArea
            legend="Please explain your reason"
            value={details}
            onChange={({target}) => setDetails(target.value)}
          />
        )}
      </div>
      <div className="modal-footer">
        <Button onClick={handleRequestClose}>Nevermind</Button>
        <Button
          danger
          primary
          loading={canceling}
          disabled={reason == null || details.length === 0}
          onClick={handleSubmit}>
          Cancel my plan
        </Button>
      </div>
    </Modal>
  );
};
