import classNames from 'classnames';
import Button from 'components/Button';
import SelectGroup from 'components/Select';
import React, {useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {components} from 'react-select';
import {JimoJLetterLogo} from 'scenes/Settings/scenes/Integrations/Hubspot/components/HubspotSettings/components/SettingsSetup';
import {salesforceService, segmentService} from 'services';
import {
  SALESFORCE_OBJECT_TYPE_ACCOUNT,
  SALESFORCE_OBJECT_TYPE_CONTACT,
  SALESFORCE_OBJECT_TYPE_LEAD,
  SALESFORCE_OBJECT_TYPE_OPPORTUNITY,
} from 'services/salesforce';
import {CUSTOM_ATTRIBUTE_SOURCE_SALESFORCE} from 'services/segment';
import {Swaler} from 'swaler';
import {v4 as uuidv4} from 'uuid';
import {SalesforceLogo} from '../../../..';
import './_Styles.scss';

const MATCHING_OPTION_EMAIL = 'EMAIL';
const MATCHING_OPTION_USER_ID = 'USER_ID';

const SYNCED_FIELD_SIDE_SALESFORCE_TO_JIMO = 'SALESFORCE_TO_JIMO';
const SYNCED_FIELD_SIDE_JIMO_TO_SALESFORCE = 'JIMO_TO_SALESFORCE';

const matchingOptions = [
  {
    value: MATCHING_OPTION_EMAIL,
    label: 'Email',
  },
  {
    value: MATCHING_OPTION_USER_ID,
    label: 'User ID (set through the Jimo SDK)',
  },
];

const Option = (props) => {
  const {data} = props;
  return (
    <components.Option {...props}>
      {data?.value?.object === SALESFORCE_OBJECT_TYPE_CONTACT && (
        <div className="object-type-tag">Contact</div>
      )}
      {data?.value?.object === SALESFORCE_OBJECT_TYPE_LEAD && (
        <div className="object-type-tag">Lead</div>
      )}
      {data?.value?.object === SALESFORCE_OBJECT_TYPE_ACCOUNT && (
        <div className="object-type-tag">Account</div>
      )}
      {data?.value?.object === SALESFORCE_OBJECT_TYPE_OPPORTUNITY && (
        <div className="object-type-tag">Opportunity</div>
      )}
      {props.data.label}
    </components.Option>
  );
};
const Control = ({children, ...rest}) => {
  const {isSalesforce, isJimo} = rest;
  return (
    <components.Control {...rest}>
      <div className="icon-wrapper">
        {isJimo === true && <JimoJLetterLogo />}
        {isSalesforce === true && <SalesforceLogo />}
      </div>
      {children}
    </components.Control>
  );
};
const SingleValue = ({children, ...props}) => {
  const {data} = props;
  return (
    <components.SingleValue {...props}>
      {data?.value?.object === SALESFORCE_OBJECT_TYPE_CONTACT && (
        <div className="object-type-tag">Contact</div>
      )}
      {data?.value?.object === SALESFORCE_OBJECT_TYPE_LEAD && (
        <div className="object-type-tag">Lead</div>
      )}
      {data?.value?.object === SALESFORCE_OBJECT_TYPE_ACCOUNT && (
        <div className="object-type-tag">Account</div>
      )}
      {data?.value?.object === SALESFORCE_OBJECT_TYPE_OPPORTUNITY && (
        <div className="object-type-tag">Opportunity</div>
      )}
      {children}
    </components.SingleValue>
  );
};

const CreatableCustomAttribute = ({
  className,
  options,
  value,
  onChange,
  onCreate,
  ...rest
}) => {
  const [inputSearch, setInputSearch] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleChange = (value) => {
    onChange(value.value);
    setMenuIsOpen(false);
  };
  const handlePressEnter = async () => {
    const type = Object.values(options).find((o) =>
      o.label.includes(inputSearch)
    );

    if (inputSearch != null && type == null) {
      const created = await onCreate(inputSearch);
      handleChange(created);
    }
  };

  return (
    <SelectGroup
      small
      menuIsOpen={menuIsOpen}
      options={options}
      placeholder="Select a property"
      value={value}
      className={classNames('select-custom-types', className)}
      noOptionsMessage={() => 'Press enter to create'}
      onChange={handleChange}
      onInputChange={(str) => setInputSearch(str)}
      onPressEnter={handlePressEnter}
      onMenuOpen={(value) => setMenuIsOpen(value)}
      {...rest}
    />
  );
};

const logger = new Swaler('SalesforceSettingsSetup');

const SettingsSetup = ({salesforce, setSalesforce}) => {
  const [customAttributes, setCustomAttributes] = useState([]);

  const customAttributesRef = useRef(customAttributes);

  const {
    data: properties = [],
    refetch: refetchSalesforceProperties,
    isLoading: isLoadingSalesforceProperties,
  } = useQuery({
    queryKey: 'salesforceProperties',
    queryFn: async () => {
      const data = await salesforceService.getProperties(salesforce?.uid);

      return [
        ...data.contact?.map((f) => ({
          ...f,
          objectType: SALESFORCE_OBJECT_TYPE_CONTACT,
        })),
        ...data.lead?.map((f) => ({
          ...f,
          objectType: SALESFORCE_OBJECT_TYPE_LEAD,
        })),
        ...data.account?.map((f) => ({
          ...f,
          objectType: SALESFORCE_OBJECT_TYPE_ACCOUNT,
        })),
        ...data.opportunity?.map((f) => ({
          ...f,
          objectType: SALESFORCE_OBJECT_TYPE_OPPORTUNITY,
        })),
      ];
    },
    enabled: !!salesforce?.uid,
    onError: (err) => {
      logger.error(err);
    },
  });

  const {isLoading: isLoadingAttributes, refetch: refetchAttributes} = useQuery(
    {
      queryKey: 'customAttributes',
      queryFn: async () => {
        const customAttributes =
          await segmentService.getSegmentCustomAttributes();
        setCustomAttributes(customAttributes);
      },
      onError: (err) => {
        logger.error(err);
      },
      cacheTime: 0,
    }
  );

  useEffect(() => {
    customAttributesRef.current = customAttributes;
  }, [customAttributes]);

  const updateSyncedField = (syncedFieldId, update) => {
    const syncedFields = salesforce?.syncedFields.map((syncedField) => {
      if (syncedField.uid === syncedFieldId) {
        return {
          ...syncedField,
          ...update,
        };
      }
      return syncedField;
    });
    setSalesforce({
      ...salesforce,
      syncedFields,
    });
  };

  const addSyncedField = (side) => {
    const syncedFields = [
      ...salesforce?.syncedFields,
      {
        uid: uuidv4(),
        side,
        salesforceObject: SALESFORCE_OBJECT_TYPE_CONTACT,
        salesforceField: null,
        jimoField: null,
      },
    ];

    setSalesforce({
      ...salesforce,
      syncedFields,
    });
  };

  properties.sort((a, b) => {
    if (a.objectType === b.objectType) {
      return a.label.localeCompare(b.label);
    }

    return 0;
  });

  const salesforceOptionsFrom = properties
    ?.filter(
      (o) =>
        [
          'string',
          'number',
          'datetime',
          'date',
          'bool',
          'phone_number',
          'enumeration',
          'email',
          'url',
          'textarea',
          'picklist',
          'multipicklist',
          'reference',
          'currency',
          'percent',
          'id',
          'time',
          'int',
          'double',
          'long',
          'combobox',
          'encryptedstring',
        ].includes(o.type)
      // excluded types are
      // 'location',
      // 'time',
      // 'base64',
      // 'int',
      // 'double',
      // 'long',
      // 'anyType',
      // 'calculated',
      // 'masterrecord',
      // 'complexvalue',
      // 'junctionidlist',
    )
    .map((property) => ({
      value: {
        name: property.name,
        object: property.objectType,
      },
      label: property.label,
    }));
  const jimoOptionsTo = customAttributes
    ?.filter((o) => o.source === CUSTOM_ATTRIBUTE_SOURCE_SALESFORCE)
    .map((attribute) => ({
      value: attribute.uid,
      label: attribute.name,
    }));

  const syncedFieldsFromSalesforce = salesforce?.syncedFields.filter(
    (s) => s.side === SYNCED_FIELD_SIDE_SALESFORCE_TO_JIMO
  );

  const alreadyUsedSalesforceFields = salesforceOptionsFrom?.filter((o) =>
    syncedFieldsFromSalesforce?.some(
      (s) =>
        s.salesforceField === o.value.name &&
        s.salesforceObject === o.value.object
    )
  );

  const alreadyUsedJimoFields = jimoOptionsTo?.filter((o) =>
    syncedFieldsFromSalesforce?.some((s) => s.jimoField?.uid === o.value)
  );

  return (
    <div className="salesforce-settings-setup">
      <div className="settings-card salesforce-match-field">
        <div className="card-header">
          <div className="subtitle-3">Matched by</div>
          <div className="body-3 n-700">
            The selected field will be used to match users between Salesforce
            and Jimo. The selected field must be unique for each user.
          </div>
        </div>
        <div className="card-body">
          <SelectGroup
            isLoading={isLoadingSalesforceProperties}
            options={salesforceOptionsFrom.filter(
              (o) => o.value.object === SALESFORCE_OBJECT_TYPE_CONTACT
            )}
            placeholder="Select a property"
            onChange={(option) =>
              setSalesforce({
                ...salesforce,
                matchingFieldSalesforce: option?.value.name,
              })
            }
            value={salesforceOptionsFrom?.find(
              (o) => o.value.name === salesforce?.matchingFieldSalesforce
            )}
            components={{
              Option: Option,
              Control: (props) => <Control {...props} isSalesforce />,
              SingleValue: SingleValue,
            }}
          />
          <i className="icon-pause-rounded n-700" />
          <SelectGroup
            options={matchingOptions}
            placeholder="Select a property"
            onChange={(option) =>
              setSalesforce({
                ...salesforce,
                matchingFieldJimo: option?.value,
              })
            }
            value={matchingOptions.find(
              (o) => o.value === salesforce?.matchingFieldJimo
            )}
            components={{
              Option: Option,
              Control: (props) => <Control {...props} isJimo />,
              SingleValue: SingleValue,
            }}
          />
        </div>
      </div>

      <div className="settings-card salesforce-synced-fields">
        <div className="card-header">
          <div className="left-side">
            <div className="subtitle-3">Synced fields</div>
            <div className="body-3 n-700">
              Fields that will be synced in near real-time from Salesforce to
              Jimo. Only users observed by Jimo in your app will be synced.
            </div>
          </div>
          <div className="right-side">
            <Button
              iconLeft="icon-plus"
              thin
              onClick={() =>
                addSyncedField(SYNCED_FIELD_SIDE_SALESFORCE_TO_JIMO)
              }>
              Add
            </Button>
          </div>
        </div>
        {syncedFieldsFromSalesforce.length > 0 && (
          <div className="card-body">
            {syncedFieldsFromSalesforce
              ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((syncedField) => {
                const {salesforceField, salesforceObject, jimoField} =
                  syncedField;
                const selectedSalesforceField = salesforceOptionsFrom?.find(
                  (o) =>
                    o.value.name === salesforceField &&
                    o.value.object === salesforceObject
                );
                const selectedJimoField = jimoOptionsTo?.find(
                  (o) => o.value === jimoField?.uid
                );

                return (
                  <div className="synced-field">
                    <SelectGroup
                      small
                      isLoading={isLoadingSalesforceProperties}
                      options={salesforceOptionsFrom.filter(
                        (o) =>
                          !alreadyUsedSalesforceFields?.some(
                            (f) =>
                              f.value.name === o.value.name &&
                              f.value.object === o.value.object
                          ) ||
                          (selectedSalesforceField?.value?.name ===
                            o.value.name &&
                            selectedSalesforceField?.value?.object ===
                              o.value.object)
                      )}
                      placeholder="Select a property"
                      onChange={(value) => {
                        updateSyncedField(syncedField.uid, {
                          salesforceObject: value.value?.object,
                          salesforceField: value.value?.name,
                        });
                      }}
                      value={selectedSalesforceField}
                      components={{
                        Option: Option,
                        Control: (props) => <Control {...props} isSalesforce />,
                        SingleValue: SingleValue,
                      }}
                    />
                    <i className="icon-forward n-700" />
                    <CreatableCustomAttribute
                      isLoading={isLoadingAttributes}
                      options={jimoOptionsTo.filter(
                        (o) =>
                          !alreadyUsedJimoFields?.some(
                            (f) => f.value === o.value
                          ) || selectedJimoField?.value === o.value
                      )}
                      onChange={(value) => {
                        const jimoField = customAttributesRef.current?.find(
                          (o) => o.uid === value
                        );
                        updateSyncedField(syncedField.uid, {
                          jimoField,
                        });
                      }}
                      onCreate={async (value) => {
                        const customAttribute =
                          await segmentService.createCustomAttribute({
                            attributeName: value,
                            source: CUSTOM_ATTRIBUTE_SOURCE_SALESFORCE,
                          });
                        await refetchAttributes();
                        return {
                          label: customAttribute.name,
                          value: customAttribute.uid,
                        };
                      }}
                      value={selectedJimoField}
                      components={{
                        Option: Option,
                        Control: (props) => <Control {...props} isJimo />,
                        SingleValue: SingleValue,
                      }}
                    />
                    <Button
                      iconOnly
                      danger
                      onClick={() => {
                        const syncedFields = salesforce?.syncedFields.filter(
                          (field) => field.uid !== syncedField.uid
                        );

                        setSalesforce({
                          ...salesforce,
                          syncedFields,
                        });
                      }}>
                      <i className="icon-trash"></i>
                    </Button>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsSetup;
