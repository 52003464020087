import Axios from 'axios';

export const HUBSPOT_OBJECT_TYPE_CONTACT = 'CONTACT';
export const HUBSPOT_OBJECT_TYPE_COMPANY = 'COMPANY';
export const HUBSPOT_OBJECT_TYPE_DEAL = 'DEAL';

// Endpoint
const EP_HUBSPOT_GET_GENERATE_URL = '/hubspot/generate-url';
const EP_HUBSPOT_POST_EXCHANGE_CODE = '/hubspot/exchange-code';
const EP_HUBSPOT_GET = (hubspotId) => `/hubspot/${hubspotId}`;
const EP_HUBSPOT_UPDATE = (hubspotId) => `/hubspot/${hubspotId}`;
const EP_HUBSPOT_REVOKE_TOKEN = (hubspotId) => `/hubspot/revoke/${hubspotId}`;
const EP_HUBSPOT_FULL_SYNC = `/hubspot/sync`;

const EP_HUBSPOT_PROPERTIES_GET = (hubspotId) =>
  `/hubspot/api/${hubspotId}/properties`;
const EP_HUBSPOT_PROPERTY_POST = '/hubspot/api/property';

// Method
export const generateUrl = (projectId) => {
  return Axios.post(EP_HUBSPOT_GET_GENERATE_URL, null, {
    params: {projectId},
  }).then((response) => response.data);
};

export const exchangeCodeForToken = (projectId, {code, state}) => {
  return Axios.post(
    EP_HUBSPOT_POST_EXCHANGE_CODE,
    {
      code,
      state,
    },
    {params: {projectId}}
  ).then((response) => response.data);
};

export const getHubspot = (hubspotId) =>
  Axios.get(EP_HUBSPOT_GET(hubspotId)).then((response) => response.data);

export const updateHubspot = (hubspotId, data) =>
  Axios.put(EP_HUBSPOT_UPDATE(hubspotId), data).then(
    (response) => response.data
  );

export const revokeToken = (hubspotId) =>
  Axios.post(EP_HUBSPOT_REVOKE_TOKEN(hubspotId)).then(
    (response) => response.data
  );

export const runFullSync = (hubspotId) => {
  return Axios.post(EP_HUBSPOT_FULL_SYNC, {
    hubspotId,
  }).then((response) => response.data);
};
// API endpoint
export const getProperties = (hubspotId) => {
  return Axios.get(EP_HUBSPOT_PROPERTIES_GET(hubspotId)).then(
    (response) => response.data
  );
};
