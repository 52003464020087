import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {toastDanger} from 'components/Toaster';
import {randomInt} from 'helpers/utils';
import {number, object} from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {jimerService, tagService} from 'services';
import {TAG_CONTEXT_USER} from 'services/tag';
import {Swaler} from 'swaler';

import {errorHelpers} from 'helpers';
import {useEffectAfterMount} from 'hooks/utils/useEffectAfterMount';
import './_Styles.scss';

const COLORS = [
  {
    name: 'Yellow',
    color: '#AD9C01',
  },
  {
    name: 'Blue',
    color: '#012ABB',
  },
  {
    name: 'Red',
    color: '#BB0139',
  },
  {
    name: 'Purple',
    color: '#B701BB',
  },
  {
    name: 'Gray',
    color: '#373737',
  },
];

const logger = new Swaler('UserTagSelector');

const propTypes = {
  jimer: object.isRequired,
  maxTags: number,
};

const defaultProps = {
  maxTags: null,
};

const UserTagSelector = ({jimer, maxTags, readOnly = false, emptyTrigger}) => {
  // Fetch tags
  const {
    data: tags = [],
    refetch: refetchTags,
    isLoading: isLoadingTags,
  } = useQuery({
    queryKey: ['tags', 'users'],
    queryFn: () => tagService.getTags({contexts: [TAG_CONTEXT_USER]}),
    refetchOnWindowFocus: false,
  });

  const [search, setSearch] = useState('');
  const [tagName, setTagName] = useState('');
  const [defaultNewTagColor, setDefaultNewTagColor] = useState(
    COLORS[randomInt(1, COLORS.length) - 1]
  );
  const [jimerTags, setJimerTags] = useState(jimer?.tags || []);
  const [preventDropdownClose, setPreventDropdownClose] = useState(false);

  const refDropdownTagSettings = useRef([]);
  const refInputTagName = useRef([]);

  const handleUpdateName = async (tag) => {
    try {
      await tagService.updateTag(tag.uid, {
        name: tagName,
      });
      refetchTags();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating tag name failed with err : ', code);
      toastDanger([title, message], {actions});
    }
  };
  const handleUpdateColor = async (tag, color) => {
    try {
      await tagService.updateTag(tag.uid, {
        color,
      });
      refetchTags();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating tag color failed with err : ', code);
      toastDanger([title, message], {actions});
    }
  };
  const handleCreateTag = async (name) => {
    try {
      const createdTag = await tagService.createTag({
        name,
        color: defaultNewTagColor.color,
        context: TAG_CONTEXT_USER,
      });
      setSearch('');
      await refetchTags();
      setDefaultNewTagColor(COLORS[randomInt(1, COLORS.length) - 1]);
      assignTag(createdTag);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Create tag failed with err : ', code);
      toastDanger([title, message], {actions});
    }
  };
  const handleDeleteTag = async (tag) => {
    try {
      await tagService.deleteTag(tag.uid);
      unassignTag(tag);
      refetchTags();
      refDropdownTagSettings.current.forEach((dp) => dp.close());
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Delete tag failed with err : ', code);
      toastDanger([title, message], {actions});
    }
  };
  const handleUpdateUserTags = async (updatedTags) => {
    try {
      await jimerService.updateJimerTags(jimer.uid, updatedTags);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating jimer tags failed with err : ', code);
      toastDanger([title, message], {actions});
    }
  };

  const assignTag = (tag) => {
    return setJimerTags((jimerTags) => jimerTags.concat(tag));
  };
  const unassignTag = (tag) => {
    return setJimerTags((jimerTags) =>
      jimerTags.filter((pt) => pt.uid !== tag.uid)
    );
  };

  useEffect(() => {
    refDropdownTagSettings.current = refDropdownTagSettings.current.slice(
      0,
      tags.length
    );
  }, [tags]);
  useEffect(() => {
    refInputTagName.current = refInputTagName.current.slice(0, tags.length);
  }, [tags]);
  useEffectAfterMount(() => {
    handleUpdateUserTags(jimerTags);
  }, [jimerTags]);

  const tagsToShow = maxTags != null ? jimerTags.slice(0, maxTags) : jimerTags;
  const hiddenTagsCount = maxTags != null ? jimerTags.length - maxTags : 0;

  const trigger =
    jimerTags.length === 0 ? (
      <>
        {readOnly === false && (emptyTrigger || <Button thin>add tag</Button>)}
      </>
    ) : (
      <div
        className={classnames('tags-list-wrapper')}
        onClick={(e) => {
          if (readOnly === true) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}>
        <div className="tags-list">
          {tagsToShow
            .map((pt) => tags.find((t) => t.uid === pt.uid))
            .filter((pt) => pt !== undefined)
            .map((t) => (
              <div
                className={classnames('item-tag')}
                style={{
                  border: `1px solid ${t.color}`,
                  backgroundColor: `${t.color}20`,
                  color: t.color,
                }}>
                {t.name}
              </div>
            ))}
          {hiddenTagsCount > 0 && <>+{hiddenTagsCount}</>}
        </div>

        <i className="icon-chevron-bottom"></i>
      </div>
    );
  const filteredTags = tags.filter((t) =>
    t.name.toLowerCase().includes(search.toLowerCase())
  );

  if (isLoadingTags === true) {
    return <></>;
  }
  return (
    <div
      className={classnames('jimer-tag-selector', {
        'read-only': readOnly,
      })}>
      <Dropdown
        position="right"
        closeOnDocumentClick={preventDropdownClose === false}
        offsetY={8}
        trigger={trigger}
        className="dp-jimer-tag-selector"
        onOpen={() => {
          setDefaultNewTagColor(COLORS[randomInt(1, COLORS.length) - 1]);
        }}
        onClose={() => {
          setSearch('');
        }}>
        <div className="tag-selector-search-wrapper">
          {jimerTags
            .map((pt) => tags.find((t) => t.uid === pt.uid))
            .filter((pt) => pt !== undefined)
            .map((t) => (
              <div
                className="tag-selected"
                style={{
                  border: `1px solid ${t.color}`,
                  backgroundColor: `${t.color}20`,
                  color: t.color,
                }}>
                {t.name}
                <i className="icon-close" onClick={() => unassignTag(t)}></i>
              </div>
            ))}
          <Input
            value={search}
            onChange={({target}) => setSearch(target.value)}
            placeholder="Search or create a tag..."
            onPressEnter={() => {
              if (filteredTags.length === 0) {
                handleCreateTag(search);
              } else if (filteredTags.length === 1) {
                assignTag(filteredTags[0]);
              } else {
                return;
              }
              setSearch('');
            }}
          />
        </div>
        <div className="tags-list-wrapper">
          <div className="list-header">Select a tag or create one</div>
          <div className="list">
            {filteredTags.length > 0 ? (
              filteredTags.map((t, index) => {
                const hasTag =
                  jimerTags.findIndex((pt) => pt.uid === t.uid) > -1;

                return (
                  <div
                    className="list-item"
                    onClick={() => {
                      hasTag === true ? unassignTag(t) : assignTag(t);
                    }}>
                    <div
                      className={classnames('item-tag', {
                        'is-selected': hasTag,
                      })}
                      style={{
                        border: `1px solid ${t.color}`,
                        backgroundColor: `${t.color}20`,
                        color: t.color,
                      }}>
                      {t.name}
                    </div>
                    <Dropdown
                      innerRef={(el) =>
                        (refDropdownTagSettings.current[index] = el)
                      }
                      className="dp-jimer-tag-settings"
                      triggerClassName="dp-trigger-tag-settings"
                      position="right top"
                      offsetX={18}
                      offsetY={-14}
                      trigger={
                        <Button iconOnly>
                          <i className="icon-horizontal-menu"></i>
                        </Button>
                      }
                      onOpen={() => {
                        setTagName(t.name);
                        setPreventDropdownClose(true);
                      }}
                      onClose={() => {
                        setPreventDropdownClose(false);
                      }}>
                      <div className="tag-name-wrapper">
                        <Input
                          ref={(el) => (refInputTagName.current[index] = el)}
                          value={tagName}
                          onChange={({target}) => setTagName(target.value)}
                          onBlur={() => handleUpdateName(t)}
                          onPressEnter={() =>
                            refInputTagName.current[index]?.blur()
                          }></Input>
                        <Button
                          iconOnly
                          danger
                          onClick={() => handleDeleteTag(t)}>
                          <i className="icon-trash"></i>
                        </Button>
                      </div>
                      <div className="list-color-wrapper">
                        <div className="list-header">Colors</div>
                        <div className="list">
                          {COLORS.map((c) => (
                            <div
                              className="item-color"
                              onClick={() => handleUpdateColor(t, c.color)}>
                              <div className="color-infos">
                                <div
                                  className="color-preview"
                                  style={{
                                    border: `1px solid ${c.color}`,
                                    backgroundColor: `${c.color}10`,
                                    color: c.color,
                                  }}></div>
                                {c.name}
                              </div>
                              {t.color === c.color && (
                                <i className="icon-tick"></i>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                );
              })
            ) : search.length > 0 ? (
              <div
                className="new-tag-wrapper"
                onClick={() => handleCreateTag(search)}>
                Create{' '}
                <div
                  className="new-tag"
                  style={{
                    border: `1px solid ${defaultNewTagColor.color}`,
                    backgroundColor: `${defaultNewTagColor.color}20`,
                    color: defaultNewTagColor.color,
                  }}>
                  {search}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

UserTagSelector.propTypes = propTypes;
UserTagSelector.defaultProps = defaultProps;

export default UserTagSelector;
