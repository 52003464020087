import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {useContext, useState} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {DropdownAddAction} from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/TriggerActions';
import {
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  STEP_CONDITION_ACTION_TYPE_SNOOZE,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import ClickableInput from '../../components/items/ClickableInput';
import PixelPicker from '../../components/items/PixelPicker';
import ColorItem from '../../components/sectionItems/ColorItem';
import GapItem from '../../components/sectionItems/GapItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import ShadowItem from '../../components/sectionItems/ShadowItem';
import ButtonAction from '../Button/components/ButtonAction';
import './_Styles.scss';

const ResourceCenterAction = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedBlockType,
    selectedStep,
    updateStep,
    selectedSubItemId,
  } = useContext(BuilderContext);

  const [newlyAddedAction, setNewlyAddedAction] = useState(null);

  const block = step?.blocks.find(
    (b) => b.type === selectedBlockType && b.uid === selectedSubItemId
  );

  const updateBlock = (updateObj, all = false) => {
    if (all !== true) {
      uptBlock(selectedBlockType, updateObj, {
        blockId: block.uid,
      });
    } else {
      uptBlock(selectedBlockType, updateObj);
    }
  };

  const handleAddAction = (type, opts = {}) => {
    const newAction = {
      ...opts,
      uid: uuidv4(),
      type,
    };

    updateBlock({
      actions: [...actions, newAction],
    });
    setNewlyAddedAction(newAction);
  };

  if (block == null) {
    return <></>;
  }

  const {style, actions = []} = block;

  const authorizedActions = [
    STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
    STEP_CONDITION_ACTION_TYPE_OPEN_POST,
    STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
    STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  ].filter((a) => actions.map((action) => action.type).includes(a) !== true);

  const updateSharedAction = (updatedAction) => {
    updateStep(selectedStep.uid, {
      blocks: selectedStep?.blocks?.map((block) => ({
        ...block,
        actions: block?.actions?.map((_action) =>
          _action.type === updatedAction.type
            ? {..._action, value: updatedAction.value}
            : _action
        ),
      })),
      triggers: selectedStep?.triggers?.map((t) => ({
        ...t,
        actions: t.actions.map((ta) =>
          ta.type === updatedAction.type
            ? {...ta, value: updatedAction.value}
            : ta
        ),
      })),
    });
  };

  return (
    <div className="block-settings resource-center-action">
      <Section title="Behavior">
        <SectionItem title="Actions" contentClassName="action-picker">
          <div className="actions-wrapper">
            {actions.map((action) => {
              return (
                <ButtonAction
                  key={action.uid}
                  action={action}
                  setAction={(updatedAction) => {
                    if (
                      updatedAction.type === STEP_CONDITION_ACTION_TYPE_SNOOZE
                    ) {
                      updateSharedAction(updatedAction);
                    } else {
                      updateBlock({
                        actions: actions.map((_action) =>
                          _action.uid === action.uid ? updatedAction : _action
                        ),
                      });
                    }
                  }}
                  onDelete={() => {
                    updateBlock({
                      actions: actions.filter(
                        (_action) => _action.uid !== action.uid
                      ),
                    });
                  }}
                  defaultOpen={newlyAddedAction?.uid === action.uid}
                />
              );
            })}
            <DropdownAddAction
              className="add-action-element-button"
              authorizedActions={authorizedActions}
              usedActionTypes={actions.map((action) => action.type)}
              onAddAction={handleAddAction}
              position="left top"
              offsetY={-4}
              offsetX={8}
              trigger={
                <ClickableInput
                  className="add-action-element-placeholder-btn"
                  inputProps={{placeholder: 'Add action...'}}
                  leftLabel={
                    <div className="action-icon-wrapper">
                      <i className="isax isax-flash-15" />
                    </div>
                  }
                />
              }
            />
          </div>
        </SectionItem>
      </Section>
      <Divider />
      <Section
        title={
          <>
            <i className="isax isax-main-component5 p-500" />
            Styles
          </>
        }
        titleExtra={
          <>
            <div className="body-4 n-700">For every</div>
            <div className="block-group subtitle-4 n-800">
              <div className="icon-wrapper">
                <i className="isax isax-flash-15" />
              </div>
              Action
            </div>
          </>
        }
      />
      <Divider />
      <Section title="Layout">
        <ResourceCenterActionStyle
          style={style}
          updateStyle={(updateObj) => updateBlock(updateObj, true)}
        />
      </Section>
      <Divider />
      <Section title="Hover effect">
        <ResourceCenterActionHoverEffect
          style={style}
          updateStyle={(updateObj) => updateBlock(updateObj, true)}
        />
      </Section>
    </div>
  );
};

export const ResourceCenterActionStyle = ({style, updateStyle}) => {
  return (
    <>
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) =>
          updateStyle({
            style: {
              borderRadius: value,
            },
          })
        }
      />
      <GapItem
        value={style.gap ?? 16}
        onChange={(value) =>
          updateStyle({
            style: {gap: value},
          })
        }
      />
      <PaddingItem
        value={{
          paddingTop: style.paddingTop ?? 16,
          paddingBottom: style.paddingBottom ?? 16,
          paddingLeft: style.paddingLeft ?? 16,
          paddingRight: style.paddingRight ?? 16,
        }}
        onChange={(value) => {
          updateStyle({
            style: {
              ...value,
            },
          });
        }}
      />
      <ColorItem
        title="Background"
        value={style.backgroundColor}
        onChange={(value) =>
          updateStyle({
            style: {backgroundColor: value},
          })
        }
      />
      <ColorItem
        title="Border"
        value={style.borderColor}
        onChange={(value) =>
          updateStyle({
            style: {borderColor: value},
          })
        }
        colorPickerProps={{erasable: true}}
      />
      <ShadowItem
        value={style.shadow}
        onChange={(value) =>
          updateStyle({
            style: {shadow: value},
          })
        }
      />
    </>
  );
};

export const ResourceCenterActionHoverEffect = ({style, updateStyle}) => {
  return (
    <>
      <ColorItem
        title="Background"
        value={style.hoverBackgroundColor}
        onChange={(value) =>
          updateStyle({
            style: {hoverBackgroundColor: value},
          })
        }
      />
      <ColorItem
        title="Border"
        value={style.hoverBorderColor}
        onChange={(value) =>
          updateStyle({
            style: {hoverBorderColor: value},
          })
        }
        colorPickerProps={{erasable: true}}
      />
      <ShadowItem
        value={style.hoverShadow}
        onChange={(value) =>
          updateStyle({
            style: {hoverShadow: value},
          })
        }
      />
      <SectionItem title="Scale">
        <PixelPicker
          small
          value={style.hoverScale ?? 1}
          min={1}
          max={1.3}
          onChange={(value) => {
            updateStyle({
              style: {...style, hoverScale: value},
            });
          }}
          step={0.01}
          label="x"
        />
      </SectionItem>
    </>
  );
};

export default ResourceCenterAction;
