import classNames from 'classnames';
import {stringToColour} from 'components/Charts/utils';
import {object} from 'prop-types';
import React from 'react';
import {shadeColor} from '../../utils';
import './_Styles.scss';

const propTypes = {
  jimer: object,
};

export const JimerSection = ({className = '', title, children}) => {
  return (
    <div className={classNames('jimer-section', className)}>
      <div className="section-title subtitle-3 n-800">{title}</div>
      {children}
    </div>
  );
};
const Segments = ({jimer = {}}) => {
  const {segments = []} = jimer;

  return (
    <JimerSection title="User segments">
      <div className="jimer-segments-list">
        {segments
          .filter((s) => s.temporary !== true)
          .map((segment) => (
            <span
              key={segment.uid}
              className="segment"
              style={{
                backgroundColor: `${shadeColor(
                  stringToColour(segment.uid),
                  50
                )}33`,
                color: shadeColor(stringToColour(segment.uid), -50),
              }}>
              {segment.name}
            </span>
          ))}
      </div>
    </JimerSection>
  );
};

Segments.propTypes = propTypes;

export default Segments;
