import Axios from 'axios';
import {generalSelector} from 'selectors';

export const F_AMPLITUDE_DATA_JIMO_2_AMPLITUDE = 1;
export const F_AMPLITUDE_DATA_AMPLITUDE_2_JIMO = 2;

// Endpoint
const EP_AMPLITUDE_SETUP = '/amplitude/setup';
const EP_AMPLITUDE_SETUP_UPDATE = '/amplitude/setup';
const EP_AMPLITUDE_REMOVE = '/amplitude/setup';
const EP_AMPLITUDE_EVENTS = '/amplitude/events';

// Method
export const setup = (projectId) => {
  return Axios.post(
    EP_AMPLITUDE_SETUP,
    {},
    {
      params: {projectId},
    }
  ).then((response) => response.data);
};

export const remove = (projectId) => {
  return Axios.delete(EP_AMPLITUDE_REMOVE, {
    params: {projectId},
  }).then((response) => response.data);
};

export const getAmplitudeEvents = (
  projectId = generalSelector.getProject().uid
) => {
  return Axios.get(EP_AMPLITUDE_EVENTS, {params: {projectId}}).then(
    (response) => response.data
  );
};

export const update = (data, projectId = generalSelector.getProject().uid) => {
  const {flags} = data;

  return Axios.put(
    EP_AMPLITUDE_SETUP_UPDATE,
    {flags},
    {params: {projectId}}
  ).then((response) => response.data);
};
