export const RESOURCE_CENTER_GROUP_TYPE_STACK = 'stack';
export const RESOURCE_CENTER_GROUP_TYPE_GRID = 'grid';

export const RESOURCE_CENTER_ACTION_CTA_TYPE_ICON = 'icon';
export const RESOURCE_CENTER_ACTION_CTA_TYPE_TEXT = 'text';

export const RESOURCE_CENTER_TRIGGER_TYPE_COMPACT = 'compact';
export const RESOURCE_CENTER_TRIGGER_TYPE_EXPANDED = 'expanded';

export const RESOURCE_CENTER_TRIGGER_TEXT_BEHAVIOR_ACTIVE_CHECKLIST_TITLE =
  'active-checklist-title';
export const RESOURCE_CENTER_TRIGGER_TEXT_BEHAVIOR_CUSTOM_TEXT = 'custom-text';

export const RESOURCE_CENTER_TRIGGER_POSITION_LEFT = 'left';
export const RESOURCE_CENTER_TRIGGER_POSITION_RIGHT = 'right';

export const RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_ACTIVE_CHECKLIST =
  'active-checklist';
export const RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_SELECTED_CHECKLIST =
  'selected-checklist';

export const RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_COUNT = 'count';
export const RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_PERCENTAGE =
  'percentage';

export const RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR = 'phosphor';
export const RESOURCE_CENTER_ICON_SOURCE_TWEMOJI = 'twemoji';
export const RESOURCE_CENTER_ICON_SOURCE_CUSTOM = 'custom';
