import Axios from 'axios';
import {generalSelector} from 'selectors';

export const STATUS_LIVE = 'LIVE';
export const STATUS_PAUSED = 'PAUSED';

export const TRIGGER_STATUS_FAIL = 'FAIL';
export const TRIGGER_STATUS_SUCCESS = 'SUCCESS';

export const CATEGORIES = {
  TOUR: 'TOUR',
  SURVEY: 'SURVEY',
  CHECKLIST: 'CHECKLIST',
  HINT: 'HINT',
  BANNER: 'BANNER',
  POST: 'POST',
  PROJECT: 'PROJECT',
};

export const CATEGORIES_ICONS = {
  [CATEGORIES.TOUR]: 'isax isax-routing-2',
  [CATEGORIES.SURVEY]: 'isax isax-note-2',
  [CATEGORIES.CHECKLIST]: 'isax isax-task-square',
  [CATEGORIES.HINT]: 'isax isax-notification-1',
  [CATEGORIES.BANNER]: 'icon-banner-default',
  [CATEGORIES.POST]: 'isax isax-slider-vertical-1',
  [CATEGORIES.PROJECT]: 'isax isax-setting-2',
};

export const EVENTS = {
  // Tours
  TOUR_CREATED: {
    category: CATEGORIES.TOUR,
    key: 'tour.created',
    name: 'Tour created',
    description: 'A member of your team created a tour',
  },
  TOUR_PUBLISHED: {
    category: CATEGORIES.TOUR,
    key: 'tour.published',
    name: 'Tour published',
    description: 'A member of your team has published a tour',
  },
  TOUR_DELETED: {
    category: CATEGORIES.TOUR,
    key: 'tour.deleted',
    name: 'Tour deleted',
    description: 'A member of your team has deleted a tour',
  },
  TOUR_STATUS_CHANGED: {
    category: CATEGORIES.TOUR,
    key: 'tour.status-changed',
    name: 'Tour status changed',
    description: 'A member of your team has paused or put live a tour',
  },
  TOUR_STARTED: {
    category: CATEGORIES.TOUR,
    key: 'tour.started',
    name: 'Tour started',
    description: 'A user started a tour',
  },
  TOUR_COMPLETED: {
    category: CATEGORIES.TOUR,
    key: 'tour.completed',
    name: 'Tour completed',
    description: 'A user completed a tour',
  },

  // Surveys
  SURVEY_CREATED: {
    category: CATEGORIES.SURVEY,
    key: 'survey.created',
    name: 'Survey created',
    description: 'A member of your team created a survey',
  },
  SURVEY_PUBLISHED: {
    category: CATEGORIES.SURVEY,
    key: 'survey.published',
    name: 'Survey published',
    description: 'A member of your team has published a survey',
  },
  SURVEY_DELETED: {
    category: CATEGORIES.SURVEY,
    key: 'survey.deleted',
    name: 'Survey deleted',
    description: 'A member of your team has deleted a survey',
  },
  SURVEY_STATUS_CHANGED: {
    category: CATEGORIES.SURVEY,
    key: 'survey.status-changed',
    name: 'Survey status changed',
    description: 'A member of your team has paused or put live a survey',
  },
  SURVEY_STARTED: {
    category: CATEGORIES.SURVEY,
    key: 'survey.started',
    name: 'Survey started',
    description: 'A user started a survey',
  },
  SURVEY_COMPLETED: {
    category: CATEGORIES.SURVEY,
    key: 'survey.completed',
    name: 'Survey completed',
    description: 'A user completed a survey',
  },
  SURVEY_STEP_ANSWERED: {
    category: CATEGORIES.SURVEY,
    key: 'survey.step.answered',
    name: 'Survey step answered',
    description: 'A user answered a step in a survey',
  },

  // Checklist
  CHECKLIST_CREATED: {
    category: CATEGORIES.CHECKLIST,
    key: 'checklist.created',
    name: 'Checklist created',
    description: 'A member of your team created a checklist',
  },
  CHECKLIST_PUBLISHED: {
    category: CATEGORIES.CHECKLIST,
    key: 'checklist.published',
    name: 'Checklist published',
    description: 'A member of your team has published a checklist',
  },
  CHECKLIST_DELETED: {
    category: CATEGORIES.CHECKLIST,
    key: 'checklist.deleted',
    name: 'Checklist deleted',
    description: 'A member of your team has deleted a checklist',
  },
  CHECKLIST_STATUS_CHANGED: {
    category: CATEGORIES.CHECKLIST,
    key: 'checklist.status-changed',
    name: 'Checklist status changed',
    description: 'A member of your team has paused or put live a checklist',
  },
  CHECKLIST_STARTED: {
    category: CATEGORIES.CHECKLIST,
    key: 'checklist.started',
    name: 'Checklist started',
    description: 'A user started a checklist',
  },
  CHECKLIST_COMPLETED: {
    category: CATEGORIES.CHECKLIST,
    key: 'checklist.completed',
    name: 'Checklist completed',
    description: 'A user completed a checklist',
  },
  CHECKLIST_DISMISSED: {
    category: CATEGORIES.CHECKLIST,
    key: 'checklist.dismissed',
    name: 'Checklist dismissed',
    description: 'A user dismissed a checklist',
  },
  CHECKLIST_TASK_COMPLETED: {
    category: CATEGORIES.CHECKLIST,
    key: 'checklist.task.completed',
    name: 'Checklist task completed',
    description: 'A user completed a task in a checklist',
  },
  CHECKLIST_TASK_SKIPPED: {
    category: CATEGORIES.CHECKLIST,
    key: 'checklist.task.skipped',
    name: 'Checklist task skipped',
    description: 'A user skipped a task in a checklist',
  },

  // Hints
  HINT_CREATED: {
    category: CATEGORIES.HINT,
    key: 'hint.created',
    name: 'Hint created',
    description: 'A member of your team created a hint',
  },
  HINT_PUBLISHED: {
    category: CATEGORIES.HINT,
    key: 'hint.published',
    name: 'Hint published',
    description: 'A member of your team has published a hint',
  },
  HINT_DELETED: {
    category: CATEGORIES.HINT,
    key: 'hint.deleted',
    name: 'Hint deleted',
    description: 'A member of your team has deleted a hint',
  },
  HINT_STATUS_CHANGED: {
    category: CATEGORIES.HINT,
    key: 'hint.status-changed',
    name: 'Hint status changed',
    description: 'A member of your team has paused or put live a hint',
  },
  HINT_SEEN: {
    category: CATEGORIES.HINT,
    key: 'hint.seen',
    name: 'Hint seen',
    description: 'A user saw a hint',
  },

  // Banner
  BANNER_CREATED: {
    category: CATEGORIES.BANNER,
    key: 'banner.created',
    name: 'Banner created',
    description: 'A member of your team created a banner',
  },
  BANNER_PUBLISHED: {
    category: CATEGORIES.BANNER,
    key: 'banner.published',
    name: 'Banner published',
    description: 'A member of your team has published a banner',
  },
  BANNER_DELETED: {
    category: CATEGORIES.BANNER,
    key: 'banner.deleted',
    name: 'Banner deleted',
    description: 'A member of your team has deleted a banner',
  },
  BANNER_STATUS_CHANGED: {
    category: CATEGORIES.BANNER,
    key: 'banner.status-changed',
    name: 'Banner status changed',
    description: 'A member of your team has paused or put live a banner',
  },
  BANNER_SEEN: {
    category: CATEGORIES.BANNER,
    key: 'banner.seen',
    name: 'Banner seen',
    description: 'A user saw a banner',
  },

  // Post
  POST_CREATED: {
    category: CATEGORIES.POST,
    key: 'post.created',
    name: 'Post created',
    description: 'A member of your team created a changelog post',
  },
  POST_PUBLISHED: {
    category: CATEGORIES.POST,
    key: 'post.published',
    name: 'Post published',
    description: 'A member of your team has published a changelog post',
  },
  POST_DELETED: {
    category: CATEGORIES.POST,
    key: 'post.deleted',
    name: 'Post deleted',
    description: 'A member of your team has deleted a changelog post',
  },
  POST_LIKED: {
    category: CATEGORIES.POST,
    key: 'post.liked',
    name: 'Post liked',
    description: 'A user liked one of your changelog post',
  },
  POST_COMMENTED: {
    category: CATEGORIES.POST,
    key: 'post.commented',
    name: 'Post commented',
    description: 'A user commented one of your changelog post',
  },

  // Project
  // PROJECT_MAU_REACHED: {
  //   category: CATEGORIES.PROJECT,
  //   key: 'project.mau.reached',
  //   name: 'MAU limit reached',
  //   description: 'Your project reached the Monthly Active Users (MAU) limit',
  // },
};

const EP_WEBHOOK_CREATE = '/webhook';
const EP_WEBHOOK_GET = '/webhook';
const EP_WEBHOOK_DELETE = '/webhook';
const EP_WEBHOOK_UPDATE = '/webhook';
const EP_WEBHOOK_RETRY = (webhookId, triggerId) =>
  `/webhook/${webhookId}/trigger/${triggerId}/retry`;

export const createWebhook = async (data) => {
  const projectId = generalSelector.getProject().uid;
  const {icon, name, description, endpoint, events} = data;

  return Axios.post(EP_WEBHOOK_CREATE, {
    icon,
    name,
    description,
    endpoint,
    events,
    projectId,
  }).then((response) => response.data);
};

export const getWebhooks = async () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_WEBHOOK_GET, {
    params: {projectId},
  }).then((response) => response.data);
};

export const deleteWebhook = async (webhookId) => {
  return Axios.delete(`${EP_WEBHOOK_DELETE}/${webhookId}`).then(
    (response) => response.data
  );
};

export const updateWebhook = async (webhookId, data) => {
  return Axios.put(`${EP_WEBHOOK_UPDATE}/${webhookId}`, data).then(
    (response) => response.data
  );
};

export const fetchWebhook = async (webhookId, options) => {
  const {withTriggers = undefined} = options ?? {};

  return Axios.get(`${EP_WEBHOOK_GET}/${webhookId}`, {
    params: {withTriggers},
  }).then((response) => response.data);
};

export const retryTrigger = async (webhookId, triggerId) => {
  return Axios.post(EP_WEBHOOK_RETRY(webhookId, triggerId)).then(
    (response) => response.data
  );
};
