/**
 * IMPORTANT : All env variables supposed to be use in this app, must be prefixed with REACT_APP_
 * See https://create-react-app.dev/docs/adding-custom-environment-variables/
 */

/**
 * IMPORTANT 2 : Use this class instead of process.env
 */

class Env {
  get NODE_ENV() {
    return (
      process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV || 'development'
    );
  }
  get GOOGLE_OAUTH_CLI() {
    return process.env.REACT_APP_GOOGLE_OAUTH_CLI;
  }
  get CDN_URL() {
    return process.env.REACT_APP_CDN_URL;
  }
  get API_URL() {
    return process.env.REACT_APP_API_URL;
  }
  get STORMWIND_URL() {
    return process.env.REACT_APP_STORMWIND_URL;
  }
  get SENTRY_DSN() {
    return process.env.REACT_APP_SENTRY_DSN;
  }
  get STRIPE_PK() {
    return process.env.REACT_APP_STRIPE_PK;
  }

  get JIMO_PROJECT_ID() {
    return process.env.REACT_APP_JIMO_PROJECT_ID;
  }

  get CRISP_PROJECT_ID() {
    return process.env.REACT_APP_CRISP_PROJECT_ID;
  }

  get AMPLITUDE_KEY() {
    return process.env.REACT_APP_AMPLITUDE_KEY;
  }

  get APP_VERSION() {
    return process.env.REACT_APP_VERSION;
  }
  get GOOGLE_GTM_CLI() {
    return process.env.REACT_APP_GOOGLE_GTM_CLI;
  }

  get SALESFORCE_KARABOR_BASE_URL() {
    return process.env.REACT_APP_SALESFORCE_KARABOR_BASE_URL;
  }

  /**
   * @NOTE Remove this when backend will be running permanently
   */
  get STAGE_AVAILABILITY_RANGE_START() {
    return process.env.REACT_APP_STAGE_AVAILABILITY_RANGE_START != null
      ? parseInt(process.env.REACT_APP_STAGE_AVAILABILITY_RANGE_START)
      : null;
  }
  get STAGE_AVAILABILITY_RANGE_END() {
    return process.env.REACT_APP_STAGE_AVAILABILITY_RANGE_END != null
      ? parseInt(process.env.REACT_APP_STAGE_AVAILABILITY_RANGE_END)
      : null;
  }
  get STAGE_AVAILABILITY_MODE_ENABLED() {
    const value = process.env.REACT_APP_STAGE_AVAILABILITY_MODE_ENABLED;

    return value != null && value === 'true' ? true : false;
  }
}

window.dumpEnv = () => console.log(Environment);

export const Environment = new Env();
