import InputGroup from 'components/Input';
import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import './_Styles.scss';

const MIN_OFFSET = -1000;
const MAX_OFFSET = 1000;

const OffsetItem = ({value, onChange}) => {
  const {offsetX = 0, offsetY = 0} = value ?? {};

  return (
    <SectionItem title="Offset" sectionItemClassName="offset-item">
      <InputGroup
        small
        value={offsetX}
        type="number"
        labelTextLeft={
          <div className="icon-wrapper" style={{transform: 'rotate(45deg)'}}>
            <i className="isax isax-maximize-4" />
          </div>
        }
        min={MIN_OFFSET}
        max={MAX_OFFSET}
        onChange={(e) =>
          onChange({
            offsetX: parseInt(e.target.value, 10),
            offsetY,
          })
        }
      />
      <InputGroup
        small
        value={offsetY}
        type="number"
        labelTextLeft={
          <div className="icon-wrapper" style={{transform: 'rotate(-45deg)'}}>
            <i className="isax isax-maximize-4" />
          </div>
        }
        min={MIN_OFFSET}
        max={MAX_OFFSET}
        onChange={(e) =>
          onChange({
            offsetX,
            offsetY: parseInt(e.target.value, 10),
          })
        }
      />
    </SectionItem>
  );
};

export default OffsetItem;
