import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';
import Background from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Background';
import Shadow from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Shadow';
import ColorItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/ColorItem';
import RadiusItem from 'scenes/PokeBuilder/components/BlockEditor/components/sectionItems/RadiusItem';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {F_BOOST_SLOT_TOP_BAR} from 'services/evolution';

const StepStyle = ({poke, style, setStyle}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      ...style,
      ...newStyle,
    });
  };

  const isBanner = poke.boostFlags === F_BOOST_SLOT_TOP_BAR;

  const backgroundColor =
    style.background?.type === 'gradient'
      ? `linear-gradient(213.36deg, ${style.background.primaryColor}, ${style.background.secondaryColor})`
      : style.background?.primaryColor || '#FFFFFF';

  return (
    <>
      <SectionItem title="Shadow" contentClassName="shadow-picker">
        <PopupSetting
          trigger={
            <ClickableInput
              inputProps={{small: true}}
              value={style.shadow?.color}
              leftLabel={
                <span
                  className="preview-shadow-color"
                  style={{background: style.shadow?.color || '#FFFFFF'}}
                />
              }
            />
          }
          title="Shadow"
          content={
            <Shadow
              value={style.shadow}
              onChange={(value) => updateStyle({shadow: value})}
            />
          }
        />
      </SectionItem>
      {isBanner === true && (
        <SectionItem title="Margin">
          <PixelPicker
            small
            value={style.margin}
            min={0}
            max={24}
            onChange={(value) => updateStyle({margin: value})}
          />
        </SectionItem>
      )}
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) => updateStyle({borderRadius: value})}
      />
      <SectionItem title="Background" contentClassName="background-picker">
        <PopupSetting
          trigger={
            <ClickableInput
              inputProps={{small: true}}
              value={
                style.background?.type === 'gradient'
                  ? 'Gradient'
                  : style.background?.primaryColor
              }
              leftLabel={
                <span
                  className="preview-background-color"
                  style={{
                    background: backgroundColor,
                    ...(style.background?.type === 'gradient'
                      ? {border: 0}
                      : {}),
                  }}
                />
              }
            />
          }
          title="Background"
          content={
            <Background
              value={style.background}
              onChange={(value) => updateStyle({background: value})}
            />
          }
        />
      </SectionItem>
      <ColorItem
        title="Border"
        value={style.borderColor || null}
        onChange={(value) => updateStyle({borderColor: value})}
        colorPickerProps={{erasable: true}}
      />

      {isBanner !== true && (
        <ColorItem
          title="Overlay"
          value={style.overlay}
          onChange={(value) => updateStyle({overlay: value})}
          colorPickerProps={{erasable: true}}
        />
      )}
    </>
  );
};

export default StepStyle;
