import classNames from 'classnames';
import Alert from 'components/Alert';
import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import Toggle from 'components/Toggle';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useContext, useEffect} from 'react';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {MODE_TRIGGERS} from 'scenes/PokeBuilder/components/PokeBuilderSidebar';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_CURSOR,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_HINT,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
  F_OPTION_DISMISS_ON_OUTSIDE_HOVER,
  F_OPTION_DOT_ALWAYS_SHOW,
  F_OPTION_DOT_SHOW_ON_HOVER,
  F_OPTION_HOTSPOT_PERSISTENT,
  F_OPTION_SHOW_ON_TARGET_HOVER,
  F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
  F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
} from 'services/evolution';
import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CURSOR,
  BLOCK_TYPE_DISMISS_CROSS,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_TARGET,
  BLOCK_TYPE_USER,
  BLOCK_TYPE_VISUAL_CUE,
  F_STEP_DISCOVERY_STEP_REQUIRED,
  F_STEP_HEIGHT_CUSTOM,
  getDefaultStepStyle,
} from 'services/steps';
import {BLOCK_DISMISS_CROSS} from 'shared/front/components/Poke/constants/blocks';
import {F_SLOT_CURSOR} from 'shared/front/components/Poke/constants/poke';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import Background from '../../components/modals/Background';
import GroupAnimations, {
  animationsIn,
  animationsOut,
} from '../../components/modals/GroupAnimations';
import Height, {
  HEIGHT_TYPE_AUTO,
  HEIGHT_TYPE_CUSTOM,
} from '../../components/modals/Height';
import Position, {positionsArr} from '../../components/modals/Position';
import Width from '../../components/modals/Width';
import BannerAlignmentItem from '../../components/sectionItems/BannerAlignmentItem';
import ColorItem from '../../components/sectionItems/ColorItem';
import GapItem from '../../components/sectionItems/GapItem';
import MarginItem from '../../components/sectionItems/MarginItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import ShadowItem from '../../components/sectionItems/ShadowItem';
import './_Styles.scss';
import DropdownTypePicker from './components/DropdownTypePicker';

const DISMISS_CROSS = 'cross';
const DISMISS_OUTSIDE = 'outside';
const DISMISS_HOVER_OUTSIDE = 'hover-outside';

const dismissOptions = [
  {label: 'Click the cross', value: [DISMISS_CROSS]},
  {label: 'Click outside', value: [DISMISS_OUTSIDE]},
  {
    label: 'Click cross or outside',
    value: [DISMISS_CROSS, DISMISS_OUTSIDE],
  },
  {label: "Can't be dismissed", value: []},
  {label: 'Hover outside', value: [DISMISS_HOVER_OUTSIDE]},
];

const showOptions = [
  {label: 'On click', value: 'click'},
  {label: 'On hover', value: 'hover'},
  {label: 'On target hover', value: 'target-hover'},
  {label: 'Always open', value: 'always-open'},
];

const animationElementsOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const bannerAutoPushElementDownOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const skipIfTargetNotFoundOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const Group = ({updateStepStyle = false}) => {
  const {
    evolution: parentEvolution,
    setEvolution: setParentEvolution,
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    selectedStep,
    updateStep,
    isInApp,
    setIsEditingTargetElement,
    setMode,
    selectedBlockType,
  } = useContext(BuilderContext);

  useEffect(() => {}, [selectedBlockType]);

  const handleThemeStyleChange = (changes) => {
    if (updateStepStyle === true) {
      updateStep(selectedStep?.uid, {
        style: {...selectedStep?.style, ...changes},
      });
    } else {
      if (evolution.uid === parentEvolution.uid) {
        setParentEvolution({
          ...parentEvolution,
          style: {...style, ...changes},
        });
      } else {
        setParentEvolution({
          ...parentEvolution,
          tourSteps: parentEvolution.tourSteps.map((tourStep) => {
            if (tourStep.uid === evolution.uid) {
              return {
                ...tourStep,
                style: {...tourStep.style, ...changes},
              };
            }
            return tourStep;
          }),
        });
      }
    }
  };

  const {type} = parentEvolution;

  const isBannerStep = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags);
  const isHotspotStep = hasFlag(F_BOOST_SLOT_DOT, evolution.boostFlags);
  const isTooltipStep = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution.boostFlags);
  const isHintStep = hasFlag(F_BOOST_SLOT_HINT, evolution.boostFlags);
  const isSnippetStep = hasFlag(F_BOOST_SLOT_SNIPPET, evolution.boostFlags);
  const isCursorStep = hasFlag(F_BOOST_SLOT_CURSOR, evolution.boostFlags);
  const isChecklist = evolution?.type === EVOLUTION_TYPE_CHECKLIST;

  const stepStyle = selectedStep?.style || getDefaultStepStyle();
  const flags = selectedStep?.stepFlags || 0;
  const wrapperStyle = evolution?.style || getDefaultStepStyle();
  const style = updateStepStyle === true ? stepStyle : wrapperStyle;

  const backgroundColor =
    style.background?.type === 'gradient'
      ? `linear-gradient(213.36deg, ${style.background.primaryColor}, ${style.background.secondaryColor})`
      : style.background?.primaryColor || '#FFFFFF';

  const animationIn =
    wrapperStyle.animationIn != null
      ? animationsIn.find((a) => a.value === wrapperStyle.animationIn)
      : null;
  const animationOut =
    wrapperStyle.animationOut != null
      ? animationsOut.find((a) => a.value === wrapperStyle.animationOut)
      : null;

  const hasInteractivityBlock =
    selectedStep?.blocks.find(
      (b) =>
        [
          BLOCK_TYPE_CHOICE,
          BLOCK_TYPE_NPS,
          BLOCK_TYPE_OPEN_QUESTION,
          BLOCK_TYPE_OPINION,
          BLOCK_TYPE_SLIDER,
        ].includes(b.type) === true
    ) != null;

  const selectedAlignment = positionsArr.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  return (
    // use selectedBlockType key here to force re-render when selectedBlockType changes and open position picker
    <div className="block-settings group" key={selectedBlockType}>
      <>
        {isBannerStep === false && isHintStep === false && (
          <>
            <Section title="Type">
              <div className="section-item step-type-picker">
                <DropdownTypePicker
                  omit={type === EVOLUTION_TYPE_SURVEY ? [F_SLOT_CURSOR] : []}
                  value={evolution.boostFlags}
                  onChange={(value) => {
                    if (value === evolution.boostFlags) {
                      return;
                    }

                    setEvolution({
                      ...evolution,
                      boostFlags: value,
                      ...(value !== evolution.boostFlags
                        ? value === F_BOOST_SLOT_DOT
                          ? {boostedPositionFlags: 1}
                          : value === F_BOOST_SLOT_TOOLTIP
                          ? {boostedPositionFlags: 0}
                          : value === F_BOOST_SLOT_CURSOR
                          ? {
                              steps: [
                                {
                                  ...selectedStep,
                                  blocks: [
                                    ...selectedStep?.blocks?.filter((b) => {
                                      if (value === F_BOOST_SLOT_CURSOR) {
                                        return (
                                          [
                                            BLOCK_TYPE_CURSOR,
                                            BLOCK_TYPE_USER,
                                            BLOCK_TYPE_BODY,
                                            BLOCK_TYPE_MEDIA,
                                            BLOCK_TYPE_DISMISS_CROSS,
                                            BLOCK_TYPE_VISUAL_CUE,
                                            BLOCK_TYPE_ANIMATION,
                                          ].includes(b.type) === true
                                        );
                                      }
                                      return true;
                                    }),
                                    ...(selectedStep?.blocks.some(
                                      (b) => b.type === BLOCK_TYPE_CURSOR
                                    )
                                      ? []
                                      : [
                                          getDefaultBlockFromType(
                                            BLOCK_TYPE_CURSOR
                                          ),
                                        ]),
                                  ],
                                },
                              ],
                            }
                          : {}
                        : {}),
                    });
                  }}
                />
              </div>
            </Section>
            <Divider />
            {(isHotspotStep === true ||
              isTooltipStep === true ||
              isSnippetStep) && (
              <>
                <Section title="General">
                  {isSnippetStep === true && (
                    <SectionItem
                      title="Position"
                      contentClassName="snippet-position-picker">
                      <div className="alignment-figure-wrapper">
                        <div className="alignment-figure">
                          <div className="alignment-label">
                            {selectedAlignment != null ? (
                              <>Always show from {selectedAlignment.position}</>
                            ) : (
                              <>Select a position</>
                            )}
                          </div>
                          {positionsArr.map((o) => {
                            if (o.value >= 128 || o.value === 8) {
                              return <></>;
                            }
                            return (
                              <div
                                className={classNames(
                                  'position-dot',
                                  o.position,
                                  {
                                    selected:
                                      evolution?.boostedPositionFlags ===
                                      o.value,
                                  }
                                )}
                                onClick={() =>
                                  setEvolution({
                                    ...evolution,
                                    boostedPositionFlags: o.value,
                                  })
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                    </SectionItem>
                  )}
                  {(isHotspotStep || isTooltipStep) && (
                    <SectionItem
                      title="Position"
                      contentClassName="position-picker">
                      <>
                        {isInApp === true ? (
                          <ClickableInput
                            value={
                              evolution.querySelectorManual?.elementText !=
                                null ||
                              evolution.querySelectorManual?.cssSelector != null
                                ? 'Manual selector'
                                : evolution.boostedQueryselector ||
                                  'Select an element'
                            }
                            leftLabel={
                              <div className="position-icon-wrapper">
                                <i className="icon-target" />
                              </div>
                            }
                            onClick={() => {
                              setIsEditingTargetElement(true);
                            }}
                          />
                        ) : (
                          <PopupSetting
                            trigger={
                              <ClickableInput
                                value={
                                  evolution.querySelectorManual?.elementText !=
                                    null ||
                                  evolution.querySelectorManual?.cssSelector !=
                                    null
                                    ? 'Manual selector'
                                    : evolution.boostedQueryselector ||
                                      'Select an element'
                                }
                                leftLabel={
                                  <div className="position-icon-wrapper">
                                    <i className="icon-target" />
                                  </div>
                                }
                              />
                            }
                            title="Position"
                            large
                            content={
                              <Position
                                evolution={evolution}
                                setEvolution={setEvolution}
                              />
                            }
                            invalid={
                              evolution.querySelectorManual == null &&
                              !evolution.boostedQueryselector
                            }
                            dropdownProps={{
                              isOpen: selectedBlockType === BLOCK_TYPE_TARGET,
                            }}
                          />
                        )}
                      </>
                    </SectionItem>
                  )}
                </Section>
                <Divider />
              </>
            )}
          </>
        )}

        <Section title="Behavior">
          {type === EVOLUTION_TYPE_SURVEY && hasInteractivityBlock && (
            <SectionItem title="Answer required">
              <Toggle
                checked={hasFlag(F_STEP_DISCOVERY_STEP_REQUIRED, flags)}
                onChange={({target}) => {
                  if (target.checked) {
                    updateStep(selectedStep?.uid, {
                      stepFlags: addFlag(F_STEP_DISCOVERY_STEP_REQUIRED, flags),
                    });
                  } else {
                    updateStep(selectedStep?.uid, {
                      stepFlags: removeFlag(
                        F_STEP_DISCOVERY_STEP_REQUIRED,
                        flags
                      ),
                    });
                  }
                }}
              />
            </SectionItem>
          )}
          {type === EVOLUTION_TYPE_TOUR &&
            (isTooltipStep === true ||
              isHotspotStep === true ||
              isCursorStep === true) && (
              <SectionItem title="Skip if target element not found">
                <RadioGroup
                  value={hasFlag(
                    F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
                    evolution.optionsFlags
                  )}
                  options={skipIfTargetNotFoundOptions}
                  onSelect={(value) =>
                    setEvolution({
                      ...evolution,
                      optionsFlags:
                        value === true
                          ? addFlag(
                              F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
                              evolution.optionsFlags
                            )
                          : removeFlag(
                              F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
                              evolution.optionsFlags
                            ),
                    })
                  }
                />
              </SectionItem>
            )}
          {(isHotspotStep === true || isHintStep === true) && (
            <SectionItem title="To show">
              <SelectGroup
                isSearchable={false}
                options={showOptions.filter((o) => {
                  if (isHotspotStep === true) {
                    return o.value !== 'target-hover';
                  }
                  if (isHintStep === true) {
                    return o.value !== 'always-open';
                  }
                  return true;
                })}
                value={
                  hasFlag(F_OPTION_DOT_SHOW_ON_HOVER, evolution.optionsFlags)
                    ? showOptions.find((o) => o.value === 'hover')
                    : hasFlag(
                        F_OPTION_SHOW_ON_TARGET_HOVER,
                        evolution.optionsFlags
                      )
                    ? showOptions.find((o) => o.value === 'target-hover')
                    : hasFlag(F_OPTION_DOT_ALWAYS_SHOW, evolution.optionsFlags)
                    ? showOptions.find((o) => o.value === 'always-open')
                    : showOptions.find((o) => o.value === 'click')
                }
                onChange={(option) => {
                  let updatedFlags = evolution.optionsFlags;

                  if (option.value === 'click') {
                    updatedFlags = removeFlag(
                      F_OPTION_DOT_SHOW_ON_HOVER,
                      updatedFlags
                    );
                    updatedFlags = removeFlag(
                      F_OPTION_SHOW_ON_TARGET_HOVER,
                      updatedFlags
                    );
                    updatedFlags = removeFlag(
                      F_OPTION_DOT_ALWAYS_SHOW,
                      updatedFlags
                    );
                  } else if (option.value === 'hover') {
                    updatedFlags = addFlag(
                      F_OPTION_DOT_SHOW_ON_HOVER,
                      evolution.optionsFlags
                    );
                    updatedFlags = removeFlag(
                      F_OPTION_SHOW_ON_TARGET_HOVER,
                      updatedFlags
                    );
                    updatedFlags = removeFlag(
                      F_OPTION_DOT_ALWAYS_SHOW,
                      updatedFlags
                    );
                  } else if (option.value === 'always-open') {
                    updatedFlags = removeFlag(
                      F_OPTION_DOT_SHOW_ON_HOVER,
                      evolution.optionsFlags
                    );
                    updatedFlags = removeFlag(
                      F_OPTION_SHOW_ON_TARGET_HOVER,
                      updatedFlags
                    );
                    updatedFlags = addFlag(
                      F_OPTION_DOT_ALWAYS_SHOW,
                      updatedFlags
                    );
                    updatedFlags = addFlag(
                      F_OPTION_HOTSPOT_PERSISTENT,
                      updatedFlags
                    );
                  } else {
                    updatedFlags = removeFlag(
                      F_OPTION_DOT_SHOW_ON_HOVER,
                      evolution.optionsFlags
                    );
                    updatedFlags = addFlag(
                      F_OPTION_SHOW_ON_TARGET_HOVER,
                      updatedFlags
                    );
                    updatedFlags = removeFlag(
                      F_OPTION_DOT_ALWAYS_SHOW,
                      updatedFlags
                    );
                  }

                  setEvolution({
                    ...evolution,
                    optionsFlags: updatedFlags,
                  });
                }}
              />
            </SectionItem>
          )}
          <SectionItem title="To dismiss">
            <SelectGroup
              isSearchable={false}
              className="dismiss-option-select"
              classNamePrefix="dismiss-option-select-component"
              options={dismissOptions.filter((o) => {
                if (isBannerStep === true) {
                  return (
                    o.value.length === 0 ||
                    (o.value.length === 1 && o.value.includes(DISMISS_CROSS))
                  );
                }
                if (isHintStep !== true) {
                  if (o.value.includes(DISMISS_HOVER_OUTSIDE) === true) {
                    return false;
                  }
                }
                return true;
              })}
              value={(() => {
                const hasCrossDismiss = selectedStep.blocks.some(
                  (b) => b.type === BLOCK_DISMISS_CROSS
                );
                const hasOutsideDismiss = hasFlag(
                  F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
                  evolution.optionsFlags
                );
                const hasHoverOutsideDismiss = hasFlag(
                  F_OPTION_DISMISS_ON_OUTSIDE_HOVER,
                  evolution.optionsFlags
                );

                const value = []
                  .concat(hasCrossDismiss === true ? DISMISS_CROSS : [])
                  .concat(hasOutsideDismiss === true ? DISMISS_OUTSIDE : [])
                  .concat(
                    hasHoverOutsideDismiss === true ? DISMISS_HOVER_OUTSIDE : []
                  );

                return dismissOptions.find(
                  (o) => JSON.stringify(o.value) === JSON.stringify(value)
                );
              })()}
              onChange={(option) => {
                let optionsFlags = evolution.optionsFlags;
                const hasCrossDismiss = selectedStep.blocks.some(
                  (b) => b.type === BLOCK_DISMISS_CROSS
                );

                if (option.value.includes(DISMISS_OUTSIDE) === true) {
                  optionsFlags = addFlag(
                    F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
                    optionsFlags
                  );
                } else {
                  optionsFlags = removeFlag(
                    F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
                    optionsFlags
                  );
                }
                if (option.value.includes(DISMISS_HOVER_OUTSIDE) === true) {
                  optionsFlags = addFlag(
                    F_OPTION_DISMISS_ON_OUTSIDE_HOVER,
                    optionsFlags
                  );
                } else {
                  optionsFlags = removeFlag(
                    F_OPTION_DISMISS_ON_OUTSIDE_HOVER,
                    optionsFlags
                  );
                }

                const updateCrossBlockForStep = (step) => {
                  let blocks = [];
                  if (option.value.includes(DISMISS_CROSS)) {
                    blocks = [
                      ...(step?.blocks || []),
                      ...(hasCrossDismiss === true
                        ? []
                        : [
                            {
                              ...getDefaultBlockFromType(
                                BLOCK_DISMISS_CROSS,
                                evolution.theme
                              ),
                              evolution,
                            },
                          ]),
                    ];
                  } else {
                    blocks = [
                      ...(step?.blocks?.filter(
                        (b) => b.type !== BLOCK_DISMISS_CROSS
                      ) || []),
                    ];
                  }
                  return blocks;
                };

                setEvolution({
                  ...evolution,
                  optionsFlags,
                  steps: evolution.steps.map((s) => {
                    if (s.uid === selectedStep.uid) {
                      return {
                        ...s,
                        blocks: updateCrossBlockForStep(s),
                      };
                    }
                    return s;
                  }),
                });
              }}
            />
          </SectionItem>
          {isBannerStep === true && (
            <SectionItem title="Move all fixed elements down">
              <RadioGroup
                value={
                  !hasFlag(
                    F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                    evolution.optionsFlags
                  )
                }
                options={bannerAutoPushElementDownOptions}
                onSelect={(value) => {
                  const updatedOptionsFlags =
                    !value === true
                      ? addFlag(
                          F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                          evolution.optionsFlags
                        )
                      : removeFlag(
                          F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                          evolution.optionsFlags
                        );

                  setEvolution({
                    ...evolution,
                    optionsFlags: updatedOptionsFlags,
                  });
                }}
              />
            </SectionItem>
          )}
          {type === EVOLUTION_TYPE_TOUR && (
            <>
              <SectionItem
                title="Progress triggers"
                sectionItemClassName="triggers">
                <ClickableInput
                  className={classNames('triggers-btn', {
                    'with-triggers': selectedStep?.triggers?.length > 0,
                  })}
                  onClick={() => setMode(MODE_TRIGGERS)}
                  leftLabel={<i className="isax isax-hierarchy-3" />}
                  value={
                    selectedStep?.triggers?.length > 0
                      ? `${selectedStep?.triggers?.length} trigger${
                          selectedStep?.triggers?.length > 1 ? 's' : ''
                        }`
                      : null
                  }
                />
              </SectionItem>
            </>
          )}
        </Section>
        <Divider />
      </>

      <Section title="Layout">
        {isBannerStep === true && (
          <BannerAlignmentItem
            value={stepStyle.alignment ?? 'center'}
            onChange={(value) =>
              updateStep(selectedStep?.uid, {
                style: {...stepStyle, alignment: value},
              })
            }
          />
        )}
        {isBannerStep !== true && (
          <SectionItem title="Width">
            <PopupSetting
              trigger={
                <ClickableInput
                  value={
                    stepStyle.width != null ? `${stepStyle.width}px` : 'Auto'
                  }
                />
              }
              title="Width"
              content={
                <Width
                  value={stepStyle.width}
                  onChange={(value) =>
                    updateStep(selectedStep?.uid, {
                      style: {...stepStyle, width: value},
                    })
                  }
                />
              }
            />
          </SectionItem>
        )}

        {isChecklist !== true && (
          <SectionItem title="Height">
            <PopupSetting
              trigger={
                <ClickableInput
                  value={
                    hasFlag(F_STEP_HEIGHT_CUSTOM, flags) === true
                      ? `${stepStyle.height}px`
                      : 'Auto'
                  }
                />
              }
              title="Height"
              content={
                <Height
                  height={stepStyle.height}
                  type={
                    hasFlag(F_STEP_HEIGHT_CUSTOM, flags) === true
                      ? HEIGHT_TYPE_CUSTOM
                      : HEIGHT_TYPE_AUTO
                  }
                  onChangeType={(type) => {
                    if (type === HEIGHT_TYPE_AUTO) {
                      updateStep(selectedStep?.uid, {
                        stepFlags: removeFlag(F_STEP_HEIGHT_CUSTOM, flags),
                      });
                    } else {
                      updateStep(selectedStep?.uid, {
                        stepFlags: addFlag(F_STEP_HEIGHT_CUSTOM, flags),
                      });
                    }
                  }}
                  onChange={({height}) =>
                    updateStep(selectedStep?.uid, {
                      style: {...stepStyle, height},
                    })
                  }
                  min={isBannerStep === true ? 30 : undefined}
                  max={isBannerStep === true ? 100 : undefined}
                />
              }
            />
          </SectionItem>
        )}
        <PaddingItem
          value={{
            paddingTop:
              stepStyle.paddingTop ?? (isHintStep ? 16 : isBannerStep ? 8 : 24),
            paddingBottom:
              stepStyle.paddingBottom ??
              (isHintStep ? 16 : isBannerStep ? 8 : 24),
            paddingLeft:
              stepStyle.paddingLeft ??
              (isHintStep ? 16 : isBannerStep ? 0 : 24),
            paddingRight:
              stepStyle.paddingRight ??
              (isHintStep ? 16 : isBannerStep ? 0 : 24),
          }}
          onChange={(value) => {
            updateStep(selectedStep?.uid, {
              style: {...stepStyle, ...value},
            });
          }}
        />
        {isBannerStep === true && (
          <MarginItem
            value={{
              marginTop: style.marginTop ?? 0,
              marginBottom: style.marginBottom ?? 0,
              marginLeft: style.marginLeft ?? 0,
              marginRight: style.marginRight ?? 0,
            }}
            onChange={(value) => {
              handleThemeStyleChange(value);
            }}
          />
        )}
        <GapItem
          value={stepStyle.gap ?? 16}
          onChange={(value) =>
            updateStep(selectedStep?.uid, {
              style: {...stepStyle, gap: value},
            })
          }
          isHorizontal={isBannerStep === true}
        />
      </Section>
      <Divider />
      {isHintStep === true && (
        <>
          <Section title="Tooltip positioning">
            <div className="alignment-figure-wrapper">
              <div className="alignment-figure">
                <div className="alignment-label">
                  {selectedAlignment != null ? (
                    <>Always show from {selectedAlignment.position}</>
                  ) : (
                    <>Select a position</>
                  )}
                </div>
                {positionsArr.map((o) => {
                  return (
                    <div
                      className={classNames('position-dot', o.position, {
                        selected: evolution?.boostedPositionFlags === o.value,
                      })}
                      onClick={() =>
                        setEvolution({
                          ...evolution,
                          boostedPositionFlags: o.value,
                        })
                      }
                    />
                  );
                })}
              </div>
            </div>
            <GapItem
              value={style.hintOffset}
              onChange={(value) => handleThemeStyleChange({hintOffset: value})}
              max={50}
            />
          </Section>
          <Divider />
        </>
      )}
      <Section title="Style">
        <ShadowItem
          value={style.shadow}
          onChange={(value) => handleThemeStyleChange({shadow: value})}
        />
        <RadiusItem
          value={style.borderRadius}
          onChange={(value) => handleThemeStyleChange({borderRadius: value})}
          max={50}
        />
        <SectionItem title="Background" contentClassName="background-picker">
          <PopupSetting
            trigger={
              <ClickableInput
                value={
                  style.background?.type === 'gradient'
                    ? 'Gradient'
                    : style.background?.primaryColor
                }
                leftLabel={
                  <span
                    className="preview-background-color"
                    style={{
                      background: backgroundColor,
                      ...(style.background?.type === 'gradient'
                        ? {border: 0}
                        : {}),
                    }}
                  />
                }
              />
            }
            title="Background"
            content={
              <Background
                value={style.background}
                onChange={(value) =>
                  handleThemeStyleChange({background: value})
                }
              />
            }
          />
        </SectionItem>
        <ColorItem
          title="Border"
          value={style.borderColor || null}
          onChange={(value) => handleThemeStyleChange({borderColor: value})}
          colorPickerProps={{
            erasable: true,
          }}
        />

        {isBannerStep !== true &&
          isHintStep !== true &&
          isCursorStep !== true && (
            <SectionItem title="Overlay">
              <ColorPickerInput
                title="Overlay"
                value={style.overlay}
                onChange={(value) => handleThemeStyleChange({overlay: value})}
                erasable
                inputProps={{
                  onClick: () => {
                    if (style.overlay == null) {
                      handleThemeStyleChange({overlay: '#00000029'});
                    }
                  },
                }}
              />
            </SectionItem>
          )}
      </Section>
      <Divider />
      {isHintStep === false && (
        <Section title="Animations">
          {isInApp === true && (
            <Alert className="alert-animation-in-app-builder" info>
              You can preview animations by launching a preview from the{' '}
              <i className="icon-play-o"></i> button at the top right.
            </Alert>
          )}
          <SectionItem title="Elements">
            <RadioGroup
              value={wrapperStyle.withElementsAnimations || false}
              options={animationElementsOptions}
              onSelect={(value) => {
                setEvolution({
                  ...evolution,
                  style: {...wrapperStyle, withElementsAnimations: value},
                });
              }}
            />
          </SectionItem>
          {isBannerStep === false && isChecklist === false && (
            <SectionItem title="In" contentClassName="animation-in-picker">
              <PopupSetting
                trigger={
                  <ClickableInput
                    value={animationIn?.name}
                    leftLabel={
                      <div className="animation-icon-wrapper">
                        {animationIn?.icon != null ? (
                          <i className={animationIn.icon} />
                        ) : (
                          <span
                            className="animation-icon"
                            style={{background: '#FFFFFF'}}
                          />
                        )}
                      </div>
                    }
                    erasable={animationIn != null}
                    onErase={() =>
                      setEvolution({
                        ...evolution,
                        style: {...wrapperStyle, animationIn: null},
                      })
                    }
                  />
                }
                title="Animations-in"
                content={
                  <GroupAnimations
                    value={wrapperStyle.animationIn}
                    onChange={(value) =>
                      setEvolution({
                        ...evolution,
                        style: {...wrapperStyle, animationIn: value},
                      })
                    }
                    type="in"
                  />
                }
              />
            </SectionItem>
          )}
          {isBannerStep === false && (
            <SectionItem title="Out" contentClassName="animation-out-picker">
              <PopupSetting
                trigger={
                  <ClickableInput
                    value={animationOut?.name}
                    leftLabel={
                      <div className="animation-icon-wrapper">
                        {animationOut?.icon != null ? (
                          <i className={animationOut.icon} />
                        ) : (
                          <span
                            className="animation-icon"
                            style={{background: '#FFFFFF'}}
                          />
                        )}
                      </div>
                    }
                    erasable={animationOut != null}
                    onErase={() =>
                      setEvolution({
                        ...evolution,
                        style: {...wrapperStyle, animationOut: null},
                      })
                    }
                  />
                }
                title="Animations-out"
                content={
                  <GroupAnimations
                    value={wrapperStyle.animationOut}
                    onChange={(value) =>
                      setEvolution({
                        ...evolution,
                        style: {...wrapperStyle, animationOut: value},
                      })
                    }
                    type="out"
                  />
                }
              />
            </SectionItem>
          )}
        </Section>
      )}
    </div>
  );
};

export default Group;
