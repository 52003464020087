import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import DefaultLoader from 'components/Loader';
import TextArea from 'components/TextArea';
import {toastDanger, toastSuccess, toastWarning} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useEffect, useRef, useState} from 'react';
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK,
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_DETAILS,
} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import Saver from 'scenes/Settings/components/Saver';
import {webhookService} from 'services';
import {CATEGORIES_ICONS, EVENTS} from 'services/webhooks';
import {Swaler} from 'swaler';
import {ModalWebhookEvents} from '../../components/ModalWebhookEvents';
import './_styles.scss';

const logger = new Swaler('SettingsWebhookSetup');
const initialState = {
  name: '',
  icon: '📢',
  description: '',
  endpoint: '',
  events: [],
};

export const SettingsWebhookSetup = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [webhook, setWebhook] = useState(initialState);
  const [modalEventsOpen, setModalEventsOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const {webhookId} = match.params;

  const refWebhook = useRef(null);

  const handleSelectEvents = (events) => {
    setModalEventsOpen(false);
    setWebhook({...webhook, events});
  };

  const fetchWebhook = async () => {
    const fetchedWebhook = await webhookService.fetchWebhook(webhookId);
    const webhook = {
      ...fetchedWebhook,
      events: fetchedWebhook.events.split(';'),
    };

    refWebhook.current = webhook;
    setWebhook(webhook);
  };
  const handleSave = async () => {
    setError(null);
    if (webhook.name.length === 0) return setError('name');
    if (webhook.endpoint.length === 0) return setError('endpoint');
    if (webhook.events.length === 0)
      return toastWarning([
        'Add at least 1 event to save webhook',
        'You need to add event before saving the webhook to ensure it functions correctly.',
      ]);
    setSaving(true);

    try {
      if (webhookId === 'new') {
        await webhookService.createWebhook(webhook);

        toastSuccess([
          'Webhook created successfully',
          'Your webhook has been set up and is ready to use your app',
        ]);
        return history.push(ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK);
      } else {
        await webhookService.updateWebhook(webhookId, webhook);
        refWebhook.current = webhook;
        toastSuccess('Changes saved!');
        return history.push({
          pathname: ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_DETAILS(webhookId),
          state: {bypassSaver: true},
        });
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('saving webhook failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setSaving(false);
    }
  };
  const hasChanges = () => {
    return (
      webhookId !== 'new' &&
      JSON.stringify(refWebhook.current) !== JSON.stringify(webhook)
    );
  };
  const handleResetChanges = () => {
    setWebhook(refWebhook.current);
  };

  useEffect(() => {
    if (webhookId !== 'new') fetchWebhook();
  }, []);

  return (
    <SettingsBody className="s-settings-webhook-setup">
      <div className="webhook-setup-header">
        <div className="left-wrapper">
          <Button
            onClick={() => history.push(ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK)}>
            <i className="icon-chevron-left"></i>
          </Button>
          <div className="title-4">
            {webhookId !== 'new' ? 'Edit webhook' : 'New webhook'}
          </div>
        </div>
        <div className="right-wrapper">
          {/* <a
            href="http://help.usejim.com"
            target="_blank"
            rel="noopener noreferrer"
            className="subtitle-4">
            <i className="isax isax-book-1"></i>Testing your webhook
          </a> */}
          {webhookId === 'new' && (
            <Button
              primary
              thin
              onClick={handleSave}
              loading={saving}
              disabled={
                webhook.name.length === 0 || webhook.endpoint.length === 0
              }>
              Create Webhook
            </Button>
          )}
        </div>
      </div>
      {webhookId !== 'new' && webhook.uid == null ? (
        <div className="webhook-loading">
          <DefaultLoader width="32px" />
        </div>
      ) : (
        <>
          <div className="settings-card card-webhook-details">
            <Input
              legend="Webhook Name"
              danger={error === 'name'}
              helper={error === 'name' ? 'Name is required' : undefined}
              labelTextLeft={
                <Dropdown
                  className="emoji-picker-dropdown"
                  position="bottom left"
                  offsetY={8}
                  trigger={
                    <div
                      className="icon-wrapper"
                      style={{background: '#FFFFFF'}}>
                      {webhook.icon}
                    </div>
                  }>
                  <Picker
                    data={data}
                    onEmojiSelect={(emoji) =>
                      setWebhook({...webhook, icon: emoji.native})
                    }
                  />
                </Dropdown>
              }
              className="input-name"
              value={webhook.name}
              onChange={({target}) =>
                setWebhook({...webhook, name: target.value})
              }
              placeholder="Name"
            />
            <TextArea
              legend="Webhook Description (optional)"
              className="input-description"
              value={webhook.description}
              onChange={({target}) =>
                setWebhook({...webhook, description: target.value})
              }
              placeholder="Name"
            />
            <Input
              legend="Your Endpoint URL"
              iconLeft="isax isax-global"
              className="input-url"
              value={webhook.endpoint}
              onChange={({target}) =>
                setWebhook({...webhook, endpoint: target.value})
              }
              placeholder="https://company.com/webhooks/jimo"
              type="url"
              helper="The destination URL that Jimo will send the webhook data payload whenever one of the selected events happens."
            />
          </div>
          <div className="settings-card card-webhook-events">
            <div className="card-events-header">
              <div className="title-wrapper">
                <div className="subtitle-3">Events</div>
                <div className="body-4 n-700">
                  Jimo events that you can choose to send to your webhook.{' '}
                  <a
                    href="https://help.usejimo.com/help-center/analyze/success-tracker/events"
                    target="_blank"
                    rel="noopener noreferrer">
                    See the payload for each event{' '}
                    <i className="isax isax-export-3"></i>
                  </a>
                </div>
              </div>
              <Button
                iconLeft="icon-plus"
                thin
                onClick={() => setModalEventsOpen(true)}>
                Add event
              </Button>
            </div>
            {webhook.events.length > 0 && (
              <div className="card-events-list">
                <div className="events-header">
                  <span className="body-3 n-500">Name</span>
                  <span className="body-3 n-500">Type</span>
                </div>
                {webhook.events
                  .sort((a, b) => a.localeCompare(b))
                  .map((eventKey) =>
                    Object.values(EVENTS).find(
                      (event) => event.key === eventKey
                    )
                  )
                  .map((event) => {
                    return (
                      <div className="event-item">
                        <div className="event-icon-name-wrapper">
                          <div className="icon-wrapper">
                            <i className={CATEGORIES_ICONS[event.category]}></i>
                          </div>
                          <div className="event-name-wrapper">
                            <div className="event-name body-2">
                              {event.name}
                            </div>
                            <div className="event-description body-3 n-500">
                              {event.description}
                            </div>
                          </div>
                        </div>
                        <span className="event-category body-2">
                          {event.category.toLowerCase()}
                        </span>
                        <Button
                          danger
                          className="btn-event-delete"
                          thin
                          onClick={() =>
                            setWebhook({
                              ...webhook,
                              events: webhook.events.filter(
                                (e) => e !== event.key
                              ),
                            })
                          }>
                          <i className="isax isax-trash"></i>
                        </Button>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <ModalWebhookEvents
            isOpen={modalEventsOpen}
            events={webhook.events}
            onSelectEvents={(events) => handleSelectEvents(events)}
            onRequestClose={() => setModalEventsOpen(false)}
          />
          <Saver
            onSave={handleSave}
            onCancel={handleResetChanges}
            isOpen={hasChanges()}
            isSaving={saving}></Saver>
        </>
      )}
    </SettingsBody>
  );
};
